import styled from 'styled-components';
import themes from '../../../config/themes';

export const ContentWrapper = styled.div`
  background: ${themes.main.colors.white};
  box-shadow: 0px 0px 8px rgba(236, 215, 192, 0.8);
  margin-top: 39px;
  margin-left: 76px;
  margin-right: 76px;
  padding: 30px;
  @media ${themes.main.breakpoints.mobile} {
    display: none;
  }
`;

export const MobileContentWrapper = styled.div`
  @media ${themes.main.breakpoints.pc} {
    display: none;
  }
  @media ${themes.main.breakpoints.tablet} {
    display: none;
  }
`;

export const ListOptionWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;

  .optionWrapper:hover {
    background: ${themes.main.colors.hoverui};
    color: ${themes.main.colors.primaryButton1};
  }
`;

export const MobileListOptionWrapper = styled.div`
  .optionWrapper:hover {
    background: ${themes.main.colors.hoverui};
  }
`;

export const OptionWrapper = styled.div`
  margin: 30px;
  background: ${themes.main.colors.ui01};
  border-radius: 8px;
  padding: 30px;
  text-align: center;
  width: 297.33px;
  height: 160px;
  cursor: pointer;

  svg {
    width: 100%;
    margin-bottom: 15px;
  }

  span {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
  }
`;

export const ContentHeaders = styled.span`
  font-weight: 500;
  font-size: 20px;
`;

export const MobileOptionWrapper = styled.div`
  margin: 13px 16px 8px 16px;
  background: ${themes.main.colors.ui01};
  border-radius: 8px;
  padding: 21px;
  display: flex;
  flex-wrap: nowrap;
  cursor: pointer;

  svg {
    width: 23.23px;
    height: 32px;
    margin-left: 24px;
  }

  span {
    line-height: 35px;
    padding-left: 31px;
    font-weight: 400;
    font-size: 12px;
  }
`;

export const MobileContentHeaders = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  margin-top: 25px;
  margin-left: 21px;
`;
