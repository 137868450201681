import axios from 'axios';

import HttpAdapterAxiosImpl from '../interface/adapters/HttpAdapterAxiosImpl';

import AuthServiceRestImpl from '../interface/services/AuthServiceRestImpl';
import GroupRepositoryImpl from '../interface/repositories/GroupRepositoryImpl';
import LoginFieldRepositoryImpl from '../interface/repositories/LoginFieldRepositoryImpl';
import MemberRepositoryImpl from '../interface/repositories/MemberRepositoryImpl';
import CompanyRepositoryImpl from '../interface/repositories/CompanyRepositoryImpl';
import EntryRepositoryImpl from '../interface/repositories/EntryRepositoryImpl';
import RecordRepositoryImpl from '../interface/repositories/RecordRepositoryImpl';

import authUseCases from '../usecases/modules/auth';
import groupUseCase from '../usecases/modules/group';
import loginFieldUSeCase from '../usecases/modules/loginFields';
import memberUseCase from '../usecases/modules/member';
import companyUseCase from '../usecases/modules/company';
import entryUseCase from '../usecases/modules/entry';
import fetchRankingListUseCase from '../usecases/modules/ranking/';
import recordUseCases from '../usecases/modules/record';
import notificationUseCases from '../usecases/modules/notification';

import urls from '../constants/urls';
import RankingRepositoryImpl from '../interface/repositories/RankingRepositoryImpl';
import NotificationRepositoryImpl from './../interface/repositories/NotificationRepositoryImpl';

const {
  auth: authUrls,
  group: groupUrls,
  loginField: loginFieldUrls,
  member: memberUrls,
  company: companyUrls,
  entry: entryUrls,
  rankings: rankingUrls,
  notification: notificationUrls,
} = urls;

const httpAdapter = new HttpAdapterAxiosImpl(axios);

const authService = new AuthServiceRestImpl(httpAdapter, authUrls);

const groupRepo = new GroupRepositoryImpl(httpAdapter, groupUrls);
const loginFieldRepo = new LoginFieldRepositoryImpl(
  httpAdapter,
  loginFieldUrls,
);
const memberRepo = new MemberRepositoryImpl(httpAdapter, memberUrls);
const companyRepo = new CompanyRepositoryImpl(httpAdapter, companyUrls);
const entryRepo = new EntryRepositoryImpl(httpAdapter, entryUrls);
const rankingRepo = new RankingRepositoryImpl(httpAdapter, rankingUrls);
const recordRepo = new RecordRepositoryImpl(httpAdapter, entryUrls);
const notifRepo = new NotificationRepositoryImpl(httpAdapter, notificationUrls);

const authInteractor = authUseCases({ authService });
const groupInteractor = groupUseCase({ groupRepo });
const loginFieldInteractor = loginFieldUSeCase({ loginFieldRepo });
const memberInteractor = memberUseCase({ memberRepo });
const companyInteractor = companyUseCase({ companyRepo });
const entryInteractor = entryUseCase({ entryRepo });
const rankingInteractor = fetchRankingListUseCase({ rankingRepo });
const recordInteractor = recordUseCases({ recordRepo });
const notificationInteractor = notificationUseCases({ notifRepo });

export const dependencies = {
  authInteractor,
  httpAdapter,
  groupInteractor,
  loginFieldInteractor,
  memberInteractor,
  companyInteractor,
  entryInteractor,
  rankingInteractor,
  recordInteractor,
  notificationInteractor,
};

export type Dependencies = typeof dependencies;
