import React from 'react';

type Props = {
  [key: string]: string | number | Function;
};

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="63"
      height="62"
      viewBox="0 0 63 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <rect x="43" width="18" height="57.7244" fill="#F5E7D8" />
      <rect x="41" y="7.48251" width="13" height="50.2416" fill="#FF714F" />
      <rect x="16" y="19.2413" width="17" height="37.4139" fill="#F5E7D8" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M56.25 57.1898V4.27576H38.25V57.1898H29.25V23.5172H11.25V57.1898H4.5V33.1379H0V57.1898C0 59.8465 2.01472 62.0001 4.5 62.0001H63V57.1898H56.25ZM24.75 57.1896H15.75V28.3274H24.75V57.1896ZM51.75 57.1895H42.75V9.0859H51.75V57.1895Z"
        fill="#3B1F34"
      />
    </svg>
  );
};

export default Component;
