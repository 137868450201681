import { ValidateMemberRole } from '../../../domain/entities/member';
import MemberRepository from '../../ports/MemberRepository';

export type validateMemberRoleChangeableUseCase = (
  companyId: number,
  memberId: number,
) => Promise<ValidateMemberRole>;

export const buildValidateMemberRoleChangeable = (dependencies: {
  memberRepo: MemberRepository;
}): validateMemberRoleChangeableUseCase => {
  const { memberRepo } = dependencies;

  const validateMemberRoleChangeable: validateMemberRoleChangeableUseCase = async (
    companyId,
    memberId,
  ) => memberRepo.validateMemberRoleChangeable(companyId, memberId);

  return validateMemberRoleChangeable;
};
