import React, { ChangeEvent, useState } from 'react';
import * as Elements from './elements';
import words from '../../../constants/words';
import { Tooltip } from '../../atoms/Tooltip';
import { Button } from '../../atoms/Button';
import { TransactionHistory } from '../../organisms/TransactionHistory';
import { RecordField } from '../../../domain/entities/record';
import { RejectApplicationModal } from '../RejectApplicationModal';
import data from '../../../constants/data';
import NumberFormat from 'react-number-format';
import { EntryLogProps } from '../../molecules/TransactionHistoryEntry/TransactionHistoryEntry';
import { ImageDisplay } from '../../atoms/ImageDisplay';

export type Props = {
  backOnPress: () => void;
  approveOnPress: () => void;
  onSubmitRejectApplication: (reason?: string) => void;
  histories: EntryLogProps[];
  customFields: RecordField[] | undefined;
  name: string | undefined;
  group: string | undefined;
  applicationDate: Date | undefined;
  salesDate: Date | undefined;
  notes: string | undefined;
  status: 'unapproved' | 'approved' | 'rejected' | 'withdraw' | '';
  mainApprover: string | undefined;
  subApprovers: { value: string; label: string }[];
  profileImageSrc: string | undefined;
  isLoadingProfImage: boolean;
};

const Component = ({
  backOnPress,
  approveOnPress,
  onSubmitRejectApplication,
  histories,
  customFields,
  name,
  group,
  applicationDate,
  salesDate,
  notes,
  mainApprover,
  subApprovers,
  status,
  profileImageSrc,
  isLoadingProfImage,
}: Props): React.ReactElement => {
  const [openApproveTooltip, setOpenApproveTooltip] = useState(false);
  const [openApproveTooltipMobile, setOpenApproveTooltipMobile] = useState(
    false,
  );
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [reasonValue, setReasonValue] = useState('');

  const onChangeRejectReasonInput = (event: ChangeEvent<Element>) => {
    const target = event.target as HTMLInputElement;
    setReasonValue(target.value);
  };

  const rejectOnPress = () => {
    setOpenApproveTooltip(false);
    setOpenRejectModal(true);
  };

  return (
    <Elements.BackgroundTemplate>
      <Elements.Content status={status}>
        <Elements.Column>
          <Elements.ProfileImageContainer>
            <ImageDisplay
              imageSrc={profileImageSrc}
              isLoading={isLoadingProfImage}
            />
          </Elements.ProfileImageContainer>
          <Elements.StatusCont isStatusRightCol={true}>
            <Elements.Text>{words.status}</Elements.Text>
            <Elements.Label status={status}>
              {data.approvalStatus.find(x => x.id === status)?.name}
            </Elements.Label>
          </Elements.StatusCont>
          <Elements.TextInputContainer isColumn1={true}>
            <Elements.StyledTextInput
              id="name"
              label={words.name}
              disabled={true}
              value={name}
            />
          </Elements.TextInputContainer>
          <Elements.TextInputContainer isColumn1={true}>
            <Elements.StyledTextInput
              id="group"
              label={words.group}
              disabled={true}
              value={group}
            />
          </Elements.TextInputContainer>
          <Elements.TextInputContainer isColumn1={true} isDate={true}>
            <Elements.DatePickersContainer>
              <Elements.DatePickerContainer>
                <Elements.StyledDatePicker
                  label={words.applicationDate}
                  value={applicationDate}
                  datePickerType="single"
                  disabled
                />
              </Elements.DatePickerContainer>
              <Elements.DatePickerContainer>
                <Elements.StyledDatePicker
                  label={words.salesDate}
                  value={salesDate}
                  datePickerType="single"
                  disabled
                />
              </Elements.DatePickerContainer>
            </Elements.DatePickersContainer>
          </Elements.TextInputContainer>
          {customFields?.map((customField, index) => {
            const fieldLabel = `${customField.name}`;
            const decimalValue = customField.value_decimal
              ? parseFloat(`${customField.value_decimal}`)
              : '';
            const charValue = customField.value_char
              ? customField.value_char
              : '';
            return (
              <Elements.TextInputContainer isColumn1={true} key={index}>
                {customField.value_decimal ? (
                  <NumberFormat
                    id={`${index}`}
                    disabled={true}
                    label={fieldLabel}
                    value={decimalValue.toLocaleString()}
                    customInput={Elements.StyledTextInput}
                    maxLength={255}
                    thousandSeparator
                    hasStarIndex={index === 0}
                  />
                ) : (
                  <Elements.StyledTextInput
                    id={`${index}`}
                    disabled={true}
                    label={fieldLabel}
                    value={charValue}
                    maxLength={255}
                    hasStarIndex={index === 0}
                  />
                )}
              </Elements.TextInputContainer>
            );
          })}
          <Elements.TextInputContainer isColumn1={true} isNotesOnLeftCol={true}>
            <Elements.StyledTextInput
              id="notes"
              label={words.notes}
              disabled={true}
              value={notes}
            />
          </Elements.TextInputContainer>
        </Elements.Column>
        <Elements.Column>
          <Elements.ButtonsContainer>
            <Elements.ButtonContainer>
              <Elements.StyledButton
                title={words.back}
                onPress={() => {
                  backOnPress();
                  setOpenApproveTooltip(false);
                }}
                theme={'light'}
              />
            </Elements.ButtonContainer>
            {status === 'unapproved' ? (
              <Tooltip
                message={words.tooltipApproveMessage}
                isOpen={openApproveTooltip}
                triggerElement={
                  <Elements.ButtonContainer>
                    <Elements.StyledButton
                      title={words.approve}
                      onPress={() => setOpenApproveTooltip(true)}
                      theme={'primary'}
                    />
                  </Elements.ButtonContainer>
                }
                align="end"
                direction="bottom"
                menuOffset={{ top: 5, left: 35 }}
                autoOrientation
                onChange={(ev, { open }) => {
                  if (!open) {
                    setOpenApproveTooltip(false);
                  }
                }}>
                <div className="bx--tooltip__footer">
                  <Elements.StyledLink
                    onClick={() => setOpenApproveTooltip(false)}>
                    {words.cancel}
                  </Elements.StyledLink>
                  <Button
                    title={words.recordAppTooltipConfirm}
                    onPress={approveOnPress}
                    style={{ fontSize: '14px' }}
                  />
                </div>
              </Tooltip>
            ) : null}
            {status === 'rejected' ? null : (
              <Elements.ButtonContainer>
                <Elements.StyledButton
                  title={words.reject}
                  onPress={rejectOnPress}
                  theme={'secondary'}
                />
              </Elements.ButtonContainer>
            )}
          </Elements.ButtonsContainer>
          <Elements.StatusCont isStatusLeftCol={true}>
            <Elements.Text>{words.status}</Elements.Text>
            <Elements.Label status={status}>
              {data.approvalStatus.find(x => x.id === status)?.name}
            </Elements.Label>
          </Elements.StatusCont>
          <Elements.TextInputContainer isColumn1={false}>
            <Elements.MultiSelectContainer>
              <div style={{ cursor: 'not-allowed' }}>
                <Elements.StyledDropdown
                  label={words.mainApproverRequired}
                  isMulti={false}
                  value={[
                    { value: mainApprover || '', label: mainApprover || '' },
                  ]}
                  isDisabled={true}
                  onChange={() => {}}
                  options={[]}
                />
              </div>
            </Elements.MultiSelectContainer>
          </Elements.TextInputContainer>
          <Elements.TextInputContainer isColumn1={false}>
            <Elements.MultiSelectContainer>
              <div style={{ cursor: 'not-allowed' }}>
                <Elements.StyledDropdown
                  label={words.subApproversOptional}
                  isMulti={true}
                  defaultValue={subApprovers}
                  isDisabled={true}
                  onChange={() => {}}
                  options={[]}
                  hasXIcon={true}
                />
              </div>
            </Elements.MultiSelectContainer>
          </Elements.TextInputContainer>
          <Elements.TextInputContainer
            isColumn1={false}
            isNotesOnRightCol={true}>
            <Elements.StyledTextInput
              id="notes"
              label={words.notes}
              disabled={true}
              value={notes}
            />
          </Elements.TextInputContainer>
          <Elements.TransactionCont>
            <Elements.HistContainer>
              <Elements.Label>{words.transactionHistory}</Elements.Label>
            </Elements.HistContainer>
            <Elements.HistContainer>
              <TransactionHistory histories={histories} />
            </Elements.HistContainer>
          </Elements.TransactionCont>
          <Elements.ButtonsContainerMobile isRejected={status === 'rejected'}>
            {status === 'unapproved' ? (
              <Tooltip
                message={words.tooltipApproveMessage}
                isOpen={openApproveTooltipMobile}
                triggerElement={
                  <Elements.ButtonContainerMobile>
                    <Elements.StyledButton
                      title={words.approve}
                      onPress={() => setOpenApproveTooltipMobile(true)}
                      theme={'primary'}
                    />
                  </Elements.ButtonContainerMobile>
                }
                align={'center'}
                direction={'top'}
                onChange={(ev, { open }) => {
                  if (!open) {
                    setOpenApproveTooltipMobile(false);
                  }
                }}>
                <div className="bx--tooltip__footer">
                  <Elements.StyledLink
                    onClick={() => setOpenApproveTooltipMobile(false)}>
                    {words.cancel}
                  </Elements.StyledLink>
                  <Button
                    title={words.recordAppTooltipConfirm}
                    onPress={approveOnPress}
                    style={{ fontSize: '14px' }}
                  />
                </div>
              </Tooltip>
            ) : null}
            {status === 'rejected' ? null : (
              <Elements.ButtonContainerMobile>
                <Elements.StyledButton
                  title={words.reject}
                  onPress={rejectOnPress}
                  theme={'secondary'}
                />
              </Elements.ButtonContainerMobile>
            )}
          </Elements.ButtonsContainerMobile>
        </Elements.Column>
        <RejectApplicationModal
          isOpen={openRejectModal}
          reasonValue={reasonValue}
          onClose={() => {
            setOpenRejectModal(false);
            setReasonValue('');
          }}
          onSubmitRejectApplication={() =>
            onSubmitRejectApplication(reasonValue)
          }
          onChangeRejectReasonInput={onChangeRejectReasonInput}
        />
        <Elements.GlobalStyle />
      </Elements.Content>
    </Elements.BackgroundTemplate>
  );
};

export default Component;
