import React, { useEffect, useState } from 'react';
import {
  DataTableHeader,
  DenormalizedRow,
  DataTableRow,
  TooltipOnChangeEvent,
} from 'carbon-components-react';
import { Plus, Info } from '../../atoms/Icons';
import { Dropdown } from '../../molecules/Dropdown';
import { NotPaginatedDataTable } from '../../organisms/DataTable';
import { MemberMultiSelect } from '../../organisms/MultiSelectDropdown';

import {
  TitleHeaderDiv,
  HeaderText,
  ButtonsDiv,
  DataTableContainer,
  DataTableWrapper,
  InfoWrapper,
  InfoText,
  ToolBar,
  Filters,
  FieldText,
  DropdownWrapper,
  StyledButton,
  StyledHeaderButton,
  SearchWrapper,
} from './elements';
import words, { languageUsed } from '../../../constants/words';
import { DropdownSchema } from './types';
import { Group } from '../../../domain/entities/group';
import { Member } from '../../../domain/entities/member';
import { theme } from '../../../config';
import { Tooltip } from '../../atoms/Tooltip';
import { AnchorTag } from '../../atoms/AnchorTag';
import styled, { createGlobalStyle } from 'styled-components';
import { Button } from '../../atoms/Button';

export const GlobalStyle = createGlobalStyle`
  .tooltip {
    &&&&& {
      min-width: 256px;
      max-width: 256px;
    }
  }
`;

const StyledTooltip = styled(Tooltip)``;

const StyledAnchorTag = styled(AnchorTag)`
  &&&& {
    div {
      color: ${theme.colors.anchorTagModal};
      @media ${theme.breakpoints.mobile} {
        padding-left: 10px;
      }
    }
  }
`;

const ActionButtons = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1.5em;
`;

const StyledTable = styled(NotPaginatedDataTable)`
  &&& {
    tbody tr td:nth-child(5) {
      width: 15%;
    }
  }
`;

export type IOptions = {
  value: string;
  label: string;
};

export type Props = {
  groupList: Group[];
  titleHeader?: string;
  showRemoveDiv?: boolean | null;
  filters: { groupFilter?: Group | undefined };
  filterTableRowsBy: { selectedItem: string };
  addedRows: Member[];
  memberList: Member[];
  editedOptions: Member[];
  addClicked?: boolean;
  isLoadingMembers?: boolean;
  isLoadingMemberTable?: boolean;
  editedHeader: DataTableHeader[];
  selectedMultiSelect: { value: string; label: string }[];
  onClickCancel: () => void;
  onClickSave: () => void;
  selectedRows?: (rows: DenormalizedRow[]) => void;
  handleRemoveMembers: () => void;
  handleRemoveMember: (row: any) => void;
  onChangeGroupFilter: ({ selectedItem }: { selectedItem: Group }) => void;
  onChangeTableGroupFilter: (item: DropdownSchema) => void;
  handleMultiSelect: (selectedRows: any) => void;
  handleAddMembers: () => void;
  handleSetFilterTableRowsBy: (filterBy: Object) => void;
  hasDataChanges?: boolean;
  allMemberList?: any;
};

const Component = ({
  onClickCancel,
  onClickSave,
  groupList,
  titleHeader,
  showRemoveDiv,
  selectedRows,
  handleRemoveMembers,
  handleRemoveMember,
  onChangeGroupFilter,
  onChangeTableGroupFilter,
  filters,
  filterTableRowsBy,
  addedRows,
  memberList,
  editedOptions,
  handleMultiSelect,
  addClicked,
  isLoadingMembers,
  handleAddMembers,
  editedHeader,
  selectedMultiSelect,
  handleSetFilterTableRowsBy,
  isLoadingMemberTable = false,
  hasDataChanges,
  allMemberList,
}: Props): React.ReactElement => {
  const [groupFilters, setGroupFilters] = useState<string[]>([]);
  const [tableDataRows, setTableDataRows] = useState<DataTableRow[]>([]);
  const [allSelectedState, setAllSelectedState] = useState<Object | any>({});
  const [optionSelected, setOptionSelected] = useState<IOptions[] | null>(null);
  const [toggleRemoveDiv, setToggleRemoveDiv] = useState<
    boolean | null | undefined
  >(null);
  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);

  const mapMembersData = (members: Object[]) => {
    return members.map(
      (member: any, index: any) =>
        ({
          ...member,
          id: member.id.toString(),
          groupName: member.group ? member.group.name || '' : words.noGroup,
          no: (index + 1).toString(),
        } as DataTableRow),
    );
  };

  useEffect(() => {
    if (addedRows && addedRows.length > 0) {
      const groupList = addedRows.map((item: any) => {
        return item.group ? item.group.name : words.noGroup;
      });

      if (groupList && groupList.length > 0) {
        setGroupFilters(groupList);
      }

      const dataRows = mapMembersData(addedRows);

      if (dataRows && dataRows.length > 0) {
        setTableDataRows(dataRows);
      }

      if (filterTableRowsBy.selectedItem !== words.rankingEditMemberAll) {
        const filteredRows = dataRows.filter((row: any) => {
          return row.groupName === filterTableRowsBy.selectedItem;
        });

        if (filteredRows && filteredRows.length > 0) {
          const reMappedRows = mapMembersData(filteredRows);
          setTableDataRows(reMappedRows);
        }
      }
    } else {
      setTableDataRows([]);
    }
    if (showRemoveDiv !== toggleRemoveDiv) {
      setToggleRemoveDiv(showRemoveDiv);
    }
  }, [addedRows, showRemoveDiv, filterTableRowsBy]);

  const handleMultiSelectValue = (selectedRows: any) => {
    handleMultiSelect(selectedRows);
  };

  const memberGroupList = groupList.filter(a =>
    Array.from(new Set(groupFilters)).some(b => a.name === b),
  );

  const memberGroupListFilter = tableDataRows.some(
    (member: any) => member?.group === null,
  )
    ? ([
        {
          id: 0,
          name: words.unassignedGroup,
          members_count: 0,
          createdAt: new Date(),
          approvers: [],
        },
      ] as Group[]).concat(memberGroupList)
    : memberGroupList;

  const handleClickAddMembers = () => {
    handleAddMembers();
    setOptionSelected(null);
  };

  const groupMemberList = () => {
    const groupMemberList: any = {};
    groupList.map((group: any) => {
      if (group.id == 0 && group.name === words.dropdownAll) {
        groupMemberList[group.name] = allMemberList;
      } else {
        groupMemberList[group.name] = allMemberList?.filter((member: any) => {
          return member.group_id === group.id;
        });
      }
    });
    return groupMemberList;
  };

  return (
    <>
      <GlobalStyle />
      {titleHeader && (
        <TitleHeaderDiv>
          <HeaderText>{titleHeader}</HeaderText>
          <ActionButtons>
            <StyledTooltip
              message={words.cancelEditPeriodTooltip}
              direction="bottom"
              tooltipClassName="tooltip"
              onChange={(
                ev: TooltipOnChangeEvent<HTMLDivElement>,
                { open },
              ) => {
                if (!open) {
                  setIsTooltipOpen(open);
                }
              }}
              isOpen={isTooltipOpen}
              triggerElement={
                <ButtonsDiv>
                  <StyledHeaderButton
                    onPress={() => {
                      if (hasDataChanges) {
                        setIsTooltipOpen(true);
                      } else {
                        onClickCancel();
                      }
                    }}
                    theme="light"
                    title={words.cancel}
                  />
                </ButtonsDiv>
              }>
              <div className="bx--tooltip__footer">
                <StyledAnchorTag
                  onPress={() => setIsTooltipOpen(false)}
                  title={words.cancel}
                />
                <Button
                  onPress={onClickCancel}
                  style={{
                    fontSize: languageUsed === 'en' ? '16px' : '13px',
                    width: languageUsed === 'en' ? 'auto' : '117px',
                    height: languageUsed === 'en' ? 'auto' : '32px',
                  }}
                  title={words.proceedCancelEditPeriod}
                />
              </div>
            </StyledTooltip>
            <StyledHeaderButton
              onPress={() => onClickSave()}
              theme="primary"
              disabled={!!!tableDataRows.length || !hasDataChanges}
              title={words.saveRankingMember}
            />
          </ActionButtons>
        </TitleHeaderDiv>
      )}
      <DataTableContainer>
        <DataTableWrapper>
          <InfoWrapper>
            <Info />
            <InfoText>{words.addMemberInfo}</InfoText>
          </InfoWrapper>
          {!showRemoveDiv && (
            <ToolBar>
              <Filters>
                <DropdownWrapper>
                  <FieldText>{words.selectGroup}</FieldText>
                  <Dropdown
                    backgroundColor={theme.colors.dropdownColor1}
                    styles={{ width: '100%' }}
                    id={'group-filter'}
                    label={words.groupFilterMemberRanking}
                    items={groupList}
                    onChange={onChangeGroupFilter}
                    dropdownProps={{
                      selectedItem: filters.groupFilter,
                      itemToString: (item: Group) => item.name,
                    }}
                  />
                </DropdownWrapper>
                <SearchWrapper>
                  <FieldText>{words.selectMembers}</FieldText>
                  <MemberMultiSelect
                    options={
                      filters.groupFilter === undefined
                        ? []
                        : addedRows.length < 1
                        ? memberList.map(function(member) {
                            return {
                              value: member.id.toString(),
                              label: member.name,
                            } as IOptions;
                          })
                        : editedOptions.map(function(member) {
                            return {
                              value: member.id.toString(),
                              label: member.name,
                            } as IOptions;
                          })
                    }
                    onChange={handleMultiSelectValue}
                    placeholder={words.searchMember}
                    addButtonClicked={addClicked}
                    isLoading={isLoadingMembers}
                    currentGroup={
                      filters.groupFilter === undefined
                        ? undefined
                        : filters.groupFilter.name
                    }
                    tableRows={addedRows}
                    value={optionSelected}
                    allSelectedValue={allSelectedState}
                    onChangeValue={optionSelected =>
                      setOptionSelected(optionSelected)
                    }
                    onChangeSelectedValue={allSelected => {
                      setAllSelectedState(allSelected);
                    }}
                    showRemoveDiv={toggleRemoveDiv}
                    groupMemberList={groupMemberList()}
                  />
                </SearchWrapper>
              </Filters>
              <StyledButton
                onPress={handleClickAddMembers}
                theme="tertiary"
                title={words.addMemberRanking}
                icon={Plus}
                disabled={
                  selectedMultiSelect.length < 1 ||
                  selectedMultiSelect[0].value === ''
                }
              />
            </ToolBar>
          )}
          <StyledTable
            filterGroupList={memberGroupListFilter.map(a => a.name)}
            headers={editedHeader}
            rows={tableDataRows}
            isSelectable
            onSelectRows={selectedRows}
            onClickRemove={handleRemoveMember}
            toggleRemoveDiv={showRemoveDiv}
            onRemoveMembers={handleRemoveMembers}
            filterTableRowsBy={filterTableRowsBy}
            onChangeTableGroupFilter={onChangeTableGroupFilter}
            isLoading={isLoadingMemberTable}
          />
        </DataTableWrapper>
      </DataTableContainer>
    </>
  );
};

export default Component;
