import React, { ChangeEvent, ReactElement, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import {
  TextInput,
  Button,
  ButtonDefaultProps,
  PasswordInput,
} from 'carbon-components-react';

import { Copy, UnverifiedEmailInfo } from '../Icons';
import { HoverTooltip, Tooltip } from '../../atoms/Tooltip';
import { theme } from '../../../config';
import words from '../../../constants/words';
import { IsBasisStar } from '../Icons';
import { languageUsed } from '../../../constants/words';
import { useWindowDimensions } from '../../../utils/screenDimension';
import { MOBILE_MAX_SCREEN } from '../../../constants/breakpoints';

const StyledTextInput = styled(TextInput).attrs((props: object) => ({
  ...props,
}))`
  border: 1px solid ${theme.colors.disabledButton2};
  border-radius: 8px;
  font-size: 13px;
  margin-bottom: 20px;
  transition: all 0s ease-in-out;
  &:focus {
    border-color: ${props => !props.invalid && '#FF714F'};
    box-shadow: ${props => !props.invalid && '0 0 0 1px #FF714F'};
    outline: none;
  }
  &:disabled {
    border: 1px solid ${theme.colors.disabledToggle2};
    background: ${theme.colors.disabledButton1};
    opacity: unset;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
  }
  &.bx--text-input--invalid {
    outline: none !important;
    border-color: ${theme.colors.errorColor};
    box-shadow: 0 0 0 1px ${theme.colors.errorColor};
    &:focus {
      border-color: ${theme.colors.errorColor};
      box-shadow: 0 0 0 1px ${theme.colors.errorColor};
    }
    margin-bottom: 0px;
    padding-right: 16px;
  }
  &.bx--text-input:disabled {
    color: ${theme.colors.ui04};
    -webkit-text-fill-color: ${theme.colors.ui04};
  }
  @media ${theme.breakpoints.mobile} {
    height: 32px;
  }
`;

const StyledPassInput = styled(PasswordInput).attrs((props: object) => ({
  ...props,
}))`
  border: 1px solid ${theme.colors.disabledButton2};
  border-radius: 8px;
  font-size: 13px;
  margin-bottom: 20px;
  transition: all 0s ease-in-out;

  &&& + button {
    height: calc(100% - 19px);
    outline: none !important;
  }
  &:focus {
    border-color: ${props => !props.invalid && '#FF714F'};
    box-shadow: ${props => !props.invalid && '0 0 0 1px #FF714F'};
    outline: none;
  }
  &:disabled {
    border: 1px solid ${theme.colors.disabledToggle2};
    background: ${theme.colors.disabledButton1};
  }
  &.bx--text-input--invalid {
    outline: none !important;
    border-color: ${theme.colors.errorColor};
    box-shadow: 0 0 0 1px ${theme.colors.errorColor};
    &:focus {
      border-color: ${theme.colors.errorColor};
      box-shadow: 0 0 0 1px ${theme.colors.errorColor};
    }
    &&& + button {
      height: 100%;
    }
    margin-bottom: 0px;
    padding-right: 16px;
  }
  @media ${theme.breakpoints.mobile} {
    height: 32px;
  }
`;

const TextInputContainer = styled.div`
  position: relative;
  flex: 1;
`;

const InputIconButton = styled(Button as React.FC<ButtonDefaultProps>)`
  &:hover {
    background: none;
  }
  &:focus {
    outline: none;
    border: none;
    box-shadow: none;
  }
  outline: none;
  border: none;
  box-shadow: none;
`;

const StyledTooltip = styled(Tooltip)`
  position: absolute;
  right: 0px;
  top: 18px;
  width: 40px;
  padding: 0;
  align-items: center;
  justify-content: center;
  display: flex;

  &.copy-text-input-tooltip-with-invalid {
    right: 30px;
  }
`;

const FieldTooltip = styled(Tooltip)`
  & > div {
    width: 100%;
  }
`;

const Label = styled.text`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.32px;
  color: ${theme.colors.text02};
  margin-left: 5px;
`;

const StarTooltip = styled(Tooltip)``;

const GlobalStyle = createGlobalStyle`
  & {
    .star-tooltip {
      width: ${languageUsed === 'en' ? '120px' : '140px'};
      padding-top: 2px;
      padding-bottom: 2px;
      pointer-events: none;
      .bx--tooltip__caret {
        margin-top: -10px;
      }
    }
    @media ${theme.breakpoints.mobile} {
      .star-tooltip {
        padding: 4px 19px !important;
        z-index: 900;
        .bx--tooltip__caret {
          margin-top: 3px;
        }
      }
    }
  }
`;

const HoverTooltipWrapper = styled.div`
  position: absolute;
  top: 36px;
  right: 13px;
`;

export type TextInputProps = {
  onChange?: (event: ChangeEvent) => void;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  value?: string | number;
  placeholder?: string;
  type?: 'text' | 'password';
  label?: string | ReactElement;
  id: string;
  readOnly?: boolean;
  showPasswordLabel?: string;
  hidePasswordLabel?: string;
  disabled?: boolean;
  invalid?: boolean;
  invalidText?: string | ReactElement;
  onCopyPress?: Function;
  onCopyBlur?: React.FocusEventHandler<HTMLButtonElement>;
  maxLength?: number;
  defaultValue?: string;
  className?: string;
  fieldTooltipMessage?: string;
  fieldTooltipMenuOffset?: {
    top?: number | undefined;
    left?: number | undefined;
  };
  showCopyIfInvalid?: boolean;
  copyTooltipMenuOffset?: {
    top?: number | undefined;
    left?: number | undefined;
  };
  copyTooltipMessage?: string;
  copyTooltipDirection?: 'left' | 'right' | 'top' | 'bottom';
  autoComplete?: 'on' | 'off';
  hasStarIndex?: boolean;
  showUnverifiedCompanyEmail?: boolean;
};

const Component = ({
  value,
  placeholder = '',
  type = 'text',
  readOnly = false,
  onChange = () => {},
  onBlur,
  label = '',
  showPasswordLabel = '',
  hidePasswordLabel = '',
  disabled = false,
  invalid = false,
  invalidText = '',
  maxLength,
  defaultValue,
  id,
  onCopyPress,
  onCopyBlur,
  className,
  fieldTooltipMessage,
  fieldTooltipMenuOffset,
  showCopyIfInvalid,
  copyTooltipMenuOffset,
  copyTooltipMessage = words.copied,
  copyTooltipDirection = 'right',
  autoComplete,
  hasStarIndex = false,
  showUnverifiedCompanyEmail = false,
}: TextInputProps): React.ReactElement => {
  const { width } = useWindowDimensions();
  const [passType, setPassType] = useState(type);
  const [starIndexTooltip, setStarIndexTooltip] = useState(false);

  const togglePasswordVisibility = (): void => {
    setPassType(passType === 'password' ? 'text' : 'password');
  };

  const topOffset = width <= MOBILE_MAX_SCREEN ? -8 : 3;

  return type === 'password' ? (
    <StyledPassInput
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      placeholder={placeholder}
      type={type}
      id={id}
      labelText={label}
      readOnly={readOnly}
      showPasswordLabel={showPasswordLabel}
      hidePasswordLabel={hidePasswordLabel}
      onTogglePasswordVisibility={togglePasswordVisibility}
      disabled={disabled}
      light={!disabled}
      invalid={invalid}
      invalidText={invalidText}
      tooltipAlignment="end"
      className={className}
      autoComplete={autoComplete}
    />
  ) : (
    <TextInputContainer className={className}>
      {fieldTooltipMessage ? (
        <FieldTooltip
          message={fieldTooltipMessage}
          tooltipClassName="text-input-field-tooltip-message"
          menuOffset={fieldTooltipMenuOffset}
          triggerElement={
            <StyledTextInput
              defaultValue={defaultValue}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              placeholder={placeholder}
              type={type}
              id={id}
              labelText={label}
              readOnly={readOnly}
              disabled={disabled}
              light={!disabled}
              invalid={invalid}
              invalidText={invalidText}
              maxLength={maxLength}
              autoComplete={autoComplete}
            />
          }
        />
      ) : (
        <>
          {hasStarIndex ? (
            <div style={{ display: 'flex' }}>
              <StarTooltip
                message={words.rankingIndex}
                direction={width <= MOBILE_MAX_SCREEN ? 'right' : 'left'}
                isOpen={starIndexTooltip}
                onChange={(ev, { open }) => {
                  if (!open) {
                    setStarIndexTooltip(false);
                  }
                }}
                tooltipClassName="star-tooltip"
                triggerElement={
                  <div
                    style={{ marginBottom: '8px' }}
                    onClick={() => setStarIndexTooltip(true)}>
                    <div
                      style={{ float: 'left', marginTop: '1px' }}
                      onMouseEnter={() => setStarIndexTooltip(true)}
                      onMouseLeave={() => setStarIndexTooltip(false)}>
                      <IsBasisStar />
                    </div>
                  </div>
                }
                menuOffset={{ top: topOffset, left: 0 }}
              />
              <Label>{label}</Label>
            </div>
          ) : null}
          <StyledTextInput
            defaultValue={defaultValue}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            placeholder={placeholder}
            type={type}
            id={id}
            labelText={hasStarIndex ? false : label}
            readOnly={readOnly}
            disabled={disabled}
            light={!disabled}
            invalid={invalid}
            invalidText={invalidText}
            maxLength={maxLength}
            autoComplete={autoComplete}
          />
        </>
      )}
      {onCopyPress &&
      (showCopyIfInvalid || !invalid) &&
      !showUnverifiedCompanyEmail ? (
        <StyledTooltip
          direction={copyTooltipDirection}
          message={copyTooltipMessage}
          autoOrientation
          className={`text-input-copy-tooltip${
            showCopyIfInvalid && invalid
              ? ' copy-text-input-tooltip-with-invalid'
              : ''
          }`}
          tooltipClassName="copy-text-input-tooltip"
          menuOffset={copyTooltipMenuOffset}
          triggerElement={
            <InputIconButton
              kind="ghost"
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              onClick={onCopyPress}
              onBlur={onCopyBlur}>
              <Copy />
            </InputIconButton>
          }
          defaultOpen={true}
        />
      ) : null}
      {showUnverifiedCompanyEmail ? (
        <HoverTooltipWrapper>
          <HoverTooltip
            triggerNode={<UnverifiedEmailInfo />}
            direction={'right'}
            autoOrientation={true}>
            <span>{words.unverifiedEmailAddress}</span>
          </HoverTooltip>
        </HoverTooltipWrapper>
      ) : null}
      <GlobalStyle />
    </TextInputContainer>
  );
};

export default Component;
