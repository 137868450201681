import React from 'react';
import styled from 'styled-components';
import { theme } from '../../../config';
import { Button, ButtonDefaultProps } from 'carbon-components-react';

const themes = {
  primary: {
    enabled: {
      backgroundColor: theme.colors.primaryButton1,
      color: theme.colors.white,
      borderColor: theme.colors.primaryButton2,
    },
    disabled: {
      backgroundColor: theme.colors.disabledButton1,
      color: theme.colors.disabledButton2,
      borderColor: theme.colors.disabledButton2,
    },
    hover: {
      color: theme.colors.primaryButtonHover,
      fontColor: '',
    },
    active: {
      color: theme.colors.primaryButton2,
    },
    focus: {
      borderColor: '',
    },
  },
  secondary: {
    enabled: {
      backgroundColor: theme.colors.secondaryButton,
      color: theme.colors.white,
      borderColor: '',
    },
    disabled: {
      backgroundColor: theme.colors.disabledButton1,
      color: theme.colors.disabledButton2,
      borderColor: theme.colors.disabledButton2,
    },
    hover: {
      color: theme.colors.secondaryButtonHover,
      fontColor: '',
    },
    active: {
      color: theme.colors.secondaryButtonActive,
    },
    focus: {
      borderColor: theme.colors.primaryButton1,
    },
  },
  tertiary: {
    enabled: {
      backgroundColor: theme.colors.interactive02,
      color: theme.colors.white,
      borderColor: '',
    },
    disabled: {
      backgroundColor: theme.colors.disabledButton1,
      color: theme.colors.disabledButton2,
      borderColor: theme.colors.disabledButton2,
    },
    hover: {
      color: theme.colors.tertiaryButtonHover,
      fontColor: '',
    },
    active: {
      color: theme.colors.iconNotifHover,
    },
    focus: {
      borderColor: theme.colors.primaryButton1,
    },
  },
  light: {
    enabled: {
      backgroundColor: 'transparent',
      color: theme.colors.primaryButton1,
      borderColor: theme.colors.primaryButton1,
    },
    disabled: {
      backgroundColor: theme.colors.disabledButton1,
      color: theme.colors.disabledButton2,
      borderColor: theme.colors.disabledButton2,
    },
    hover: {
      color: theme.colors.hoverui,
      fontColor: theme.colors.primaryButtonHover,
    },
    active: {
      color: theme.colors.disabled02,
    },
    focus: {
      borderColor: '',
    },
  },
};

const StyledButton = styled(Button as React.FC<ButtonDefaultProps>)<{
  $buttonTheme: 'primary' | 'secondary' | 'tertiary' | 'light';
}>`
   {
    padding: 13px;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    display: block;
    width: 100%;
    max-width: inherit;
    border-style: solid;
    border-width: 3px;
    text-align: center;
    background-color: transparent;
    min-height: inherit;
  }
  @media ${theme.breakpoints.mobile} {
    padding: 0;
    height: 38px;
  }
  &:hover {
    background-color: ${props =>
      props.disabled ? null : themes[props.$buttonTheme].hover.color};
    border-color: ${props =>
      props.$buttonTheme == 'primary' || props.disabled
        ? null
        : themes[props.$buttonTheme].hover.color};
    color: ${props =>
      props.disabled ? null : themes[props.$buttonTheme].hover.fontColor};
  }
  &:active {
    background-color: ${props =>
      props.disabled ? null : themes[props.$buttonTheme].active.color};
    border-color: ${props =>
      props.disabled ? null : themes[props.$buttonTheme].active.color};
  }
  &:focus {
    border-color: ${props =>
      props.disabled ? null : themes[props.$buttonTheme].focus.borderColor};
    box-shadow: none;
  }
`;

const StyledIconButton = styled(Button as React.FC<ButtonDefaultProps>)<{
  $buttonTheme: 'primary' | 'secondary' | 'tertiary' | 'light';
}>`
  padding: 13px;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  display: block;
  width: 100%;
  max-width: inherit;
  border-style: solid;
  border-width: 3px;
  &:hover {
    background-color: ${props =>
      props.disabled ? null : themes[props.$buttonTheme].hover.color};
    border-color: ${props =>
      props.$buttonTheme == 'primary' || props.disabled
        ? null
        : themes[props.$buttonTheme].hover.color};
  }
  &:active {
    background-color: ${props =>
      props.disabled ? null : themes[props.$buttonTheme].active.color};
    border-color: ${props =>
      props.disabled ? null : themes[props.$buttonTheme].active.color};
  }
  &:focus {
    box-shadow: inset 0 0 0 1px ${theme.colors.white};
  }
`;

export type ButtonProps = {
  theme?: 'primary' | 'secondary' | 'tertiary' | 'light';
  onPress: React.MouseEventHandler<HTMLButtonElement>;
  title?: string;
  style?: Object;
  disabled?: boolean;
  icon?: Object;
  className?: string;
};

const Component = ({
  theme = 'primary',
  disabled,
  title,
  style,
  icon,
  onPress,
  className,
}: ButtonProps): React.ReactElement => {
  const buttonTheme = themes[theme][disabled ? 'disabled' : 'enabled'];
  const buttonStyle = {
    backgroundColor: buttonTheme.backgroundColor,
    borderColor: buttonTheme.borderColor,
    color: buttonTheme.color,
    fontSize: '16px',
    ...style,
  };

  return !icon ? (
    <StyledButton
      style={buttonStyle}
      onClick={onPress}
      disabled={disabled}
      className={className}
      $buttonTheme={theme}>
      {title}
    </StyledButton>
  ) : (
    <StyledIconButton
      isExpressive
      style={buttonStyle}
      onClick={onPress}
      disabled={disabled}
      renderIcon={icon}
      className={className}
      $buttonTheme={theme}>
      {title}
    </StyledIconButton>
  );
};

export default Component;
