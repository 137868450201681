import * as Yup from 'yup';
import words from '../../../constants/words';

export const RecordApplicationSchema = Yup.object().shape({
  applicationDate: Yup.date().required(words.required),
  salesDate: Yup.date().required(words.required),
  customFields: Yup.array().of(
    Yup.object().shape({
      valueForValidation: Yup.string().when(['is_required', 'is_basis'], {
        is: (is_required: boolean, is_basis: boolean) =>
          is_required || is_basis,
        then: Yup.string().required(words.required),
      }),
    }),
  ),
  mainApprover: Yup.object().required(words.required),
});
