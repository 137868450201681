import React, { useCallback } from 'react';
import styled from 'styled-components';
import { FormikErrors } from 'formik';

import words from '../../../constants/words';
import { theme } from '../../../config';
import data, { Role } from '../../../constants/data';

import { Group } from '../../../domain/entities/group';

import { TextInput } from '../../atoms/TextInput';
import { Dropdown } from '../../molecules/Dropdown';
import { UserRoles } from '../../../domain/entities/member';

const ContentContainer = styled.div`
  margin-bottom: 21px;

  .bx--dropdown__wrapper.bx--list-box__wrapper {
    padding-bottom: 19px;
  }

  & > div:first-child {
    margin-top: 30px;
  }

  & > div:not(:first-child) {
    margin-top: 25px;
  }

  .bx--list-box__label {
    font-size: 13px;
  }
`;

const IdsContainer = styled.div`
  display: flex;

  & > div:first-child {
    margin-right: 8px;
  }

  & > div:last-child {
    margin-left: 8px;
  }
`;

export type SetMemberFormType = {
  memberName: string;
  memberGroupId: number;
  memberRoleId: UserRoles;
  memberUserId?: number;
  memberEmail?: string;
  memberFields?: { name: string; value: string }[];
};

export type Props = SetMemberFormType & {
  groupList: Group[];
  companyId: number;
  onChangeMemberName: (value: string) => void;
  onChangeMemberGroup: (value: number) => void;
  onChangeMemberRole: (value: string) => void;
  errors: FormikErrors<SetMemberFormType>;
  isEdit?: boolean;
  currentEditorRole: UserRoles;
};

const Component = ({
  memberName,
  memberGroupId,
  memberRoleId,
  groupList,
  companyId,
  memberUserId,
  memberEmail,
  memberFields,
  onChangeMemberName,
  onChangeMemberGroup,
  onChangeMemberRole,
  isEdit = false,
  currentEditorRole,
}: Props): React.ReactElement => {
  const dropdownStyles = {
    width: '100%',
  };

  const handleChangeMemberName = (event: React.ChangeEvent<Element>) => {
    onChangeMemberName((event.target as HTMLInputElement).value.trimStart());
  };

  const handleChangeMemberGroup = useCallback(
    ({ selectedItem }: { selectedItem: { id: number } }) => {
      onChangeMemberGroup(selectedItem.id);
    },
    [],
  );

  const handleChangeMemberRole = useCallback(
    ({ selectedItem }: { selectedItem: Role }) => {
      onChangeMemberRole(selectedItem.id);
    },
    [],
  );

  const selectRolesList = (role: UserRoles): Role[] => {
    switch (role) {
      case 'admin':
        return data.memberRoles;
      case 'approver':
        return data.approverOnlyRoles;
      case 'member':
        return data.memberOnlyRoles;
      default:
        return data.memberOnlyRoles;
    }
  };

  return (
    <ContentContainer>
      <TextInput
        id="member-name"
        label={`${words.name} ${isEdit ? '' : words.requiredMark}`}
        disabled={isEdit}
        value={memberName}
        onChange={handleChangeMemberName}
      />
      <Dropdown
        id="member-group"
        label=""
        titleText={`${words.group} ${isEdit ? '' : words.requiredMark}`}
        styles={dropdownStyles}
        items={groupList}
        onChange={handleChangeMemberGroup}
        backgroundColor={theme.colors.white}
        dropdownProps={{
          selectedItem: groupList.find(e => e.id === memberGroupId) || {},
          itemToString: (item: Group) => item.name,
        }}
      />
      <Dropdown
        id="member-role"
        label=""
        titleText={`${words.role} ${isEdit ? '' : words.requiredMark}`}
        styles={dropdownStyles}
        items={selectRolesList(currentEditorRole)}
        onChange={handleChangeMemberRole}
        backgroundColor={theme.colors.white}
        dropdownProps={{
          selectedItem: data.memberRoles.find(e => e.id === memberRoleId) || {},
          itemToString: (item: Role) => item.name,
        }}
      />
      {isEdit && (
        <>
          <IdsContainer>
            <TextInput
              id="member-company-id"
              label={words.companyId}
              value={companyId.toString()}
              disabled
            />
            <TextInput
              id="member-user-id"
              label={words.userId}
              value={memberUserId?.toString()}
              disabled
            />
          </IdsContainer>
          <TextInput
            id="member-email"
            label={words.emailAddress}
            value={memberEmail}
            disabled
          />
          {memberFields?.map((memberField, index) => (
            <TextInput
              key={index}
              id={`member-${memberField.name}`}
              label={memberField.name}
              value={memberField.value}
              disabled
            />
          ))}
        </>
      )}
    </ContentContainer>
  );
};

export default Component;
