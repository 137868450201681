import { useCallback } from 'react';
import { StatusTypes } from '../../components/templates/RecordReferenceList/types';
import {
  RecordReference,
  RecordApproval,
  FetchRecordApprovalsParam,
  FetchRecordApprovalsRankingNamesParams,
} from '../../domain/entities/record';
import { PaginationQuery } from '../../domain/entities/request';
import { PaginatedResponse } from '../../domain/entities/response';
import { RecordHooks } from '.';
import { useDependencies } from '..';

export const useFetchRecordReferences: RecordHooks['useFetchRecordReferences'] = (): {
  fetchRecordReferences: (
    query: PaginationQuery & {
      companyId: number;
      memberId: number;
      rankingId?: number;
      status?: StatusTypes;
    },
  ) => Promise<PaginatedResponse & { results: RecordReference[] }>;
} => {
  const { recordInteractor } = useDependencies();

  const fetchRecordReferences = useCallback(
    async (
      query: PaginationQuery & {
        companyId: number;
        memberId: number;
        rankingId?: number;
        status?: StatusTypes;
      },
    ) => {
      const response = await recordInteractor.fetchRecordReferences(query);

      return response;
    },
    [],
  );

  return {
    fetchRecordReferences,
  };
};

export const useFetchRecordApprovals: RecordHooks['useFetchRecordApprovals'] = (): {
  fetchRecordApprovals: (
    query: PaginationQuery & FetchRecordApprovalsParam,
  ) => Promise<PaginatedResponse & { results: RecordApproval[] }>;
} => {
  const { recordInteractor } = useDependencies();
  const fetchRecordApprovals = useCallback(
    async (query: PaginationQuery & FetchRecordApprovalsParam) => {
      const response = await recordInteractor.fetchRecordApprovals(query);

      return response;
    },
    [],
  );

  return { fetchRecordApprovals };
};

export const useFetchReferenceDetails: RecordHooks['useFetchReferenceDetails'] = (): {
  fetchReferenceDetails: (params: {
    companyId: number;
    memberId: number;
    rankingId: number;
    entryId: number;
  }) => Promise<RecordReference>;
} => {
  const { recordInteractor } = useDependencies();
  const fetchReferenceDetails = useCallback(
    async (params: {
      companyId: number;
      memberId: number;
      rankingId: number;
      entryId: number;
    }) => {
      const response = await recordInteractor.fetchReferenceDetails(params);

      return {
        ...response,
        logs: response?.logs?.reverse(),
      };
    },
    [],
  );

  return { fetchReferenceDetails };
};

export const useFetchRecordApprovalsRankingNames: RecordHooks['useFetchRecordApprovalsRankingNames'] = (): {
  fetchRecordApprovalsRankingNames: (
    query: FetchRecordApprovalsRankingNamesParams,
  ) => Promise<{ results: string[] }>;
} => {
  const { recordInteractor } = useDependencies();
  const fetchRecordApprovalsRankingNames = useCallback(
    async (query: FetchRecordApprovalsRankingNamesParams) => {
      const response = await recordInteractor.fetchRecordApprovalsRankingNames(
        query,
      );

      return response;
    },
    [],
  );

  return { fetchRecordApprovalsRankingNames };
};
