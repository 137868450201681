import React from 'react';
import { theme } from '../../../config';

type Props = {
  isActive?: boolean;
};

const Component = ({ isActive = false }: Props): React.ReactElement => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 2.5H3.75C3.05964 2.5 2.5 3.05964 2.5 3.75V7.5C2.5 8.19036 3.05964 8.75 3.75 8.75H7.5C8.19036 8.75 8.75 8.19036 8.75 7.5V3.75C8.75 3.05964 8.19036 2.5 7.5 2.5ZM7.5 7.5H3.75V3.75H7.5V7.5ZM16.25 2.5H12.5C11.8096 2.5 11.25 3.05964 11.25 3.75V7.5C11.25 8.19036 11.8096 8.75 12.5 8.75H16.25C16.9404 8.75 17.5 8.19036 17.5 7.5V3.75C17.5 3.05964 16.9404 2.5 16.25 2.5ZM16.25 7.5H12.5V3.75H16.25V7.5ZM7.5 11.25H3.75C3.05964 11.25 2.5 11.8096 2.5 12.5V16.25C2.5 16.9404 3.05964 17.5 3.75 17.5H7.5C8.19036 17.5 8.75 16.9404 8.75 16.25V12.5C8.75 11.8096 8.19036 11.25 7.5 11.25ZM7.5 16.25H3.75V12.5H7.5V16.25ZM16.25 11.25H12.5C11.8096 11.25 11.25 11.8096 11.25 12.5V16.25C11.25 16.9404 11.8096 17.5 12.5 17.5H16.25C16.9404 17.5 17.5 16.9404 17.5 16.25V12.5C17.5 11.8096 16.9404 11.25 16.25 11.25ZM16.25 16.25H12.5V12.5H16.25V16.25Z"
        fill={isActive ? theme.colors.primaryButton1 : theme.colors.fontColor}
      />
    </svg>
  );
};

export default Component;
