import React, { useEffect, useState } from 'react';
import './member-footer.scss';
import Home from 'assets/icons/home-ft.svg';
import HomeActive from 'assets/icons/home-ft-active.svg';
import Ranking from 'assets/icons/ranking-ft.svg';
import Calendar from 'assets/icons/calendar-ft.svg';
import MyPage from 'assets/icons/user-ft.svg';
import MyPageActive from 'assets/icons/user-ft-active.svg';
import Setting from 'assets/icons/setting-ft.svg';
import SettingActive from 'assets/icons/setting-ft-active.svg';
import { useNavigate } from 'react-router-dom';
import urls from 'constants/urls';
import words from 'constants/words';

type PropType = {
  path: string;
};

export default function MemberFooter(props: PropType) {
  const { path } = props;
  const navigate = useNavigate();
  return (
    <div className="member-footer">
      <div
        className={`item ${path === urls.home ? 'active' : ''}`}
        onClick={() => navigate(urls.home)}>
        <img src={path === urls.home ? HomeActive : Home} />
        <p className={path === urls.home ? 'active' : ''}>{words.homeMenu}</p>
      </div>
      <div className="item">
        <img src={Ranking} />
        <p>{words.rankingMenu}</p>
      </div>
      <div
        className={`item ${path === urls.sph ? 'active' : ''}`}
        onClick={() => navigate(urls.sph)}>
        <img src={Calendar} />
        <p className={path === urls.sph ? 'active' : ''}>
          {words.calendarMenu}
        </p>
      </div>
      <div
        className={`item ${path === urls.member_profile ? 'active' : ''}`}
        onClick={() => navigate(urls.member_profile)}>
        <img src={path === urls.member_profile ? MyPageActive : MyPage} />
        <p className={path === urls.member_profile ? 'active' : ''}>
          {words.myPageMenu}
        </p>
      </div>
      <div
        className={`item ${path === urls.account_setting ? 'active' : ''}`}
        onClick={() => navigate(urls.account_setting)}>
        <img src={path === urls.account_setting ? SettingActive : Setting} />
        <p className={path === urls.account_setting ? 'active' : ''}>
          {words.settingMenu}
        </p>
      </div>
    </div>
  );
}
