import React from 'react';
import styled from 'styled-components';
import { theme } from '../../../config';

const StyledAnchorTag = styled.div`
  padding: 0;
  display: inline-block;
`;

const Label = styled.div`
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.16px;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  &:active {
    outline: solid;
    outline-width: thin;
    text-decoration: none;
  }
`;

const fontColor = {
  info: theme.colors.primaryButton1,
  edit: theme.colors.edit,
  delete: theme.colors.secondaryButton,
  disabled: theme.colors.text03,
};

export type AnchorTagProps = {
  onPress: React.MouseEventHandler<HTMLDivElement>;
  title?: string;
  style?: Object;
  type?: 'info' | 'edit' | 'delete' | 'disabled';
  className?: string;
};

const Component = ({
  type = 'info',
  title,
  style,
  onPress,
  className,
}: AnchorTagProps): React.ReactElement => {
  const anchorTagColor = {
    color: fontColor[type],
    ...style,
  };

  return (
    <StyledAnchorTag
      style={anchorTagColor}
      onClick={onPress}
      className={className}>
      <Label>{title}</Label>
    </StyledAnchorTag>
  );
};

export default Component;
