import React from 'react';
import { Formik } from 'formik';

import { ChangePasswordModalSchema } from './validation';

import words from '../../../constants/words';

import { Modal } from '../../molecules/Modal';
import { ChangePasswordForm } from '../../organisms/ChangePasswordForm';
import styled from 'styled-components';
import { theme } from '../../../config';

const Container = styled.div`
  @media ${theme.breakpoints.mobile} {
    .bx--modal-container {
      min-height: 504px;
    }
    .bx--children-container {
      overflow: auto;
      max-height: 55%;
      margin-bottom: 30px;
      padding-bottom: 50px;
    }
    .bx--modal-content {
      height: 110%;
      margin-bottom: -20px;
    }

    .buttonsContainer {
      margin: 0;
      & > button {
        height: 55px;
      }
    }
  }
`;

export type Props = {
  isOpen: boolean;
  onClose: () => void;
  onChangePassword: (values: {
    currentPassword: string;
    newPassword: string;
    confirmPassword: string;
  }) => void;
  currentPasswordError?: string;
};

const Component = ({
  isOpen,
  onClose,
  onChangePassword,
  currentPasswordError,
}: Props): React.ReactElement => {
  const initialValues = {
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  };

  return (
    <Container>
      <Formik
        initialValues={initialValues}
        validationSchema={ChangePasswordModalSchema}
        onSubmit={(values, { resetForm }) => {
          onChangePassword(values);
          onClose();
          resetForm();
        }}>
        {({
          handleChange,
          handleSubmit,
          values,
          errors,
          isValid,
          resetForm,
        }): React.ReactElement => {
          const { currentPassword, newPassword, confirmPassword } = values;
          const {
            newPassword: newPasswordError,
            confirmPassword: confirmPasswordError,
          } = errors;

          return (
            <Modal
              className="ChangePassModal"
              open={isOpen}
              title={words.changePassword}
              label={words.updateYourPassword}
              confirmButtonText={words.save}
              onConfirm={handleSubmit}
              onCancel={() => {
                resetForm();
                onClose();
              }}
              onClose={() => {
                resetForm();
                onClose();
              }}
              confirmButtonDisabled={
                !isValid ||
                !!!currentPassword ||
                !!!newPassword ||
                !!!confirmPassword
              }
              mobileButtonSpan
              mergedWithButtons>
              <ChangePasswordForm
                currentPassword={currentPassword}
                newPassword={newPassword}
                confirmPassword={confirmPassword}
                onChangeCurrentPassword={handleChange('currentPassword')}
                onChangeNewPassword={handleChange('newPassword')}
                onChangeConfirmPassword={handleChange('confirmPassword')}
                newPasswordError={newPasswordError}
                confirmPasswordError={confirmPasswordError}
                currentPasswordError={currentPasswordError}
              />
            </Modal>
          );
        }}
      </Formik>
    </Container>
  );
};

export default Component;
