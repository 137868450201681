/* eslint-disable @typescript-eslint/explicit-function-return-type */
import styled from 'styled-components';
import { theme } from '../../../config';
import themes from '../../../config/themes';
import { AnchorTag } from '../../atoms/AnchorTag';
import { Button } from '../../atoms/Button';
import TooltipComponent from '../../atoms/Tooltip/Tooltip';

export const StyledButton = styled(Button)`
  &&&& {
    width: 33%;
    margin-left: 13px;
    height: 48px;
    align-self: end;
    display: flex;
    justify-content: space-between;
    &&& svg {
      height: 9px;
      width: 9px;
    }
  }
`;

export const StyledHeaderButton = styled(Button)`
  width: 116px;
  height: 48px;
  display: flex;
`;

export const TitleHeaderDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3%;
  padding-right: 66px;
  padding-top: 39px;
  padding-left: 80px;
`;

export const ButtonsDiv = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 20px;
`;

export const HeaderText = styled.p`
  color: ${themes.main.colors.text01};
  font-size: 28px;
  line-height: 36px;
`;

export const DataTableContainer = styled.div`
  width: 90%;
  height: 700px;
  display: flex;
  background-color: ${themes.main.colors.ui03};
  justify-content: space-around;
  margin-left: 74px;
  margin-right: 69px;
`;

export const DataTableWrapper = styled.div`
  width: 95%;
  height: 700px;
  display: flex;
  flex-direction: column;
  background-color: ${themes.main.colors.ui03};
  .bx--data-table.bx--skeleton th span::before {
    animation: 3000ms ease-in-out skeleton infinite;
  }
  &&& {
    .bx--data-table tr {
      position: relative;
    }
  }
`;

export const InfoWrapper = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  margin-top: 27px;
  margin-bottom: 20px;
  align-items: center;
  column-gap: 10px;
`;

export const InfoText = styled.p`
  color: ${themes.main.colors.fontColor};
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.32px;
`;

export const ToolBar = styled.div`
  width: 100%;
  background-color: ${themes.main.colors.ui03};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  height: 63px;
  z-index: 10;
`;

export const Filters = styled.div`
  width: 73%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 2%;
`;

export const FieldText = styled.p`
  color: ${themes.main.colors.fontColor};
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.32px;
  padding-bottom: 5px;
`;

export const DropdownWrapper = styled.div`
  width: 40%;
  height: 60px;
  display: flex;
  flex-direction: column;
  align-self: end;
`;

export const StoryContainer = styled.div`
  width: 100vw;
  height: 100vh;
`;

export const SearchWrapper = styled.div`
  width: 60%;
  height: 60px;
  display: flex;
  flex-direction: column;
  align-self: end;
`;

export const StyledAnchorTag = styled(AnchorTag)`
  &&&&& {
    div {
      color: ${theme.colors.anchorTagModal};
    }
  }
`;

export const StyledTooltip = styled(TooltipComponent)`
  &&&&& {
    flex: 1;
  }
`;

export const StyledButtonTooltip = styled(Button)`
  &&&&&&&& {
    width: 55%;
    padding: 0px 15px 0px 13px;
  }
`;
