import React, { ChangeEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';

import { Banner } from '../../molecules/Banner';
import { AdminFirstLoginForm } from '../../organisms/AdminFirstLoginForm';

import ImageFile from '../../../assets/images/team-handshake.jpg';
import { theme } from '../../../config';
import words from '../../../constants/words';
import { AdminFirstLoginSchema } from './validation';
import { base64ToFile } from '../../../utils/fileBase64Converter';

const Container = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  min-height: 100vh;
  background-color: ${theme.colors.ui01};
  @media ${theme.breakpoints.mobileAndTablet} {
    grid-template-columns: 100%;
  }
`;

const Column = styled.div<{ isBanner?: boolean }>`
  padding: ${props => (props.isBanner ? '0' : '60px 5%')};
  @media ${theme.breakpoints.mobileAndTablet} {
    ${props => props.isBanner && 'display: none'};
  }
  @media ${theme.breakpoints.mobile} {
    padding: ${props => (props.isBanner ? '0' : '28px 0')};
  }
`;
export interface SignUpValues {
  companyId: string;
  email: string;
  emailNotif: boolean;
  group: string;
  name: string;
  password: string;
  password2: string;
  photo: File[] | undefined;
  role: string;
  userId: string;
}

export type Props = {
  name: string;
  companyName: string;
  companyId: string;
  userId: string;
  email: string;
  group: string;
  role: string;
  onRegister: (
    values: SignUpValues & {
      customFieldValues?: { identifier: string; value: string }[];
    },
  ) => void;
  passwordRes?: string;
  emailAddressRes?: string;
  signupError?: boolean;
  checkEmail: (value: string) => void;
  isLoading?: boolean;
  customFields: { identifier: string; name: string; isRequired: boolean }[];
  notifErrorMessage?: string;
};

const Component = ({
  name,
  companyName,
  companyId,
  userId,
  email,
  group,
  role,
  onRegister,
  passwordRes,
  emailAddressRes,
  signupError,
  checkEmail,
  isLoading,
  customFields,
  notifErrorMessage,
}: Props): React.ReactElement => {
  const { localStorage } = window;

  const persistedInputFields = JSON.parse(
    localStorage.getItem('input_fields') as string,
  );
  const persistedPhoto: { base64: string; name: string } = JSON.parse(
    localStorage.getItem('photo') as string,
  );
  const [photo, setPhoto] = useState<File | null>(null);

  const photoFile = () => {
    if (persistedPhoto) {
      base64ToFile(persistedPhoto.base64, persistedPhoto.name).then(
        response => {
          setPhoto(response);
          setIsDoneInit(true);
        },
      );
    } else {
      setIsDoneInit(true);
    }
  };

  const initialValues = Object.assign(
    {},
    {
      name,
      companyId,
      userId,
      email: email || persistedInputFields?.email || '',
      group,
      role,
      password: persistedInputFields?.password || '',
      password2: persistedInputFields?.password2 || '',
      emailNotif: true,
      photo: photo ? [photo] : undefined,
    },
    ...(customFields
      ? customFields
          .filter(field => field.isRequired)
          .map(field => {
            const persistedFieldObj = persistedInputFields?.customFields?.find(
              (persistedField: { identifier: string; value: string }) =>
                persistedField.identifier === field.identifier,
            );
            return persistedFieldObj
              ? { [field.identifier]: persistedFieldObj.value }
              : {};
          })
      : []),
  );

  const [emailVal, setEmail] = useState('');
  const [doneInit, setIsDoneInit] = useState(false);

  const handleEmailChange = (value: string) => {
    setEmail(value);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      emailVal.length > 1 && checkEmail(emailVal);
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [emailVal]);

  // email field is always first while photo field is always second as added via api
  const [emailField, photoField, ...otherFields] = customFields;
  const isEmailRequired = emailField?.isRequired;
  const isPhotoRequired = photoField?.isRequired;

  useEffect(() => {
    photoFile();
  }, []);

  return (
    <Container>
      <Column>
        {doneInit && (
          <Formik
            initialValues={initialValues}
            validationSchema={AdminFirstLoginSchema(otherFields)}
            enableReinitialize
            onSubmit={fields => {
              const customFieldValues = otherFields?.map(({ identifier }) => ({
                identifier,
                // need to type cast because we dont know the fields pre runtime
                value: ((fields as unknown) as { [key: string]: string })[
                  identifier
                ],
              }));
              const customValues = customFieldValues.filter(
                item => item.value !== undefined,
              );
              onRegister({
                ...fields,
                customFieldValues: customValues?.length ? customValues : [],
              });
            }}>
            {({
              handleSubmit,
              handleChange,
              values,
              errors,
              setFieldValue,
            }): React.ReactElement => {
              const {
                name,
                companyId,
                userId,
                email,
                group,
                role,
                password,
                password2,
                photo,
                emailNotif,
              } = values;
              const {
                password: password1Error,
                password2: password2Error,
                email: emailAddressError,
                photo: photoError,
              } = errors;

              return (
                <AdminFirstLoginForm
                  name={name}
                  companyName={companyName}
                  companyId={companyId}
                  userId={userId}
                  email={email}
                  password1={password}
                  password2={password2}
                  photo={photo}
                  onChangePassword1={handleChange('password')}
                  onChangepassword2={handleChange('password2')}
                  password1Error={password1Error as string}
                  password2Error={password2Error as string}
                  emailAddressError={emailAddressError as string}
                  setFieldValue={setFieldValue}
                  group={group}
                  role={role}
                  emailNotif={emailNotif}
                  onClickRegister={handleSubmit}
                  photoError={photoError as string}
                  onChangeEmail={(event: ChangeEvent) => {
                    handleChange(event);
                    !emailAddressError &&
                      handleEmailChange(
                        (event.target as HTMLTextAreaElement).value,
                      );
                  }}
                  passwordRes={passwordRes}
                  emailAddressRes={email.length > 1 ? emailAddressRes : ''}
                  signupError={signupError}
                  notifErrorMessage={notifErrorMessage}
                  isLoading={isLoading}
                  isEmailRequired={isEmailRequired}
                  isPhotoRequired={isPhotoRequired}
                  customFields={otherFields?.map(
                    ({ identifier, name, isRequired }) => ({
                      identifier,
                      name,
                      isRequired,
                      // need to typecast because we dont know the custom fields before runtime
                      value: ((values as unknown) as { [key: string]: string })[
                        identifier
                      ],
                      onChange: handleChange(identifier),
                    }),
                  )}
                />
              );
            }}
          </Formik>
        )}
      </Column>
      <Column isBanner>
        <Banner label={words.bannerLabel} imageUrl={ImageFile} />
      </Column>
    </Container>
  );
};

export default Component;
