import React from 'react';

type Props = {
  [key: string]: string | number | Function;
};

const Star = (props: Props): React.ReactElement => {
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.09911 1.625L5.497 4.50625L1.91602 4.96563L4.50756 7.20937L3.89489 10.375L7.09911 8.88125L10.3033 10.375L9.69066 7.20937L12.2822 4.96875L8.70122 4.50625L7.09911 1.625Z"
        fill="#D64D30"
        stroke="#D64D30"
      />
    </svg>
  );
};

export default Star;
