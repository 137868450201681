import { FetchLoginFieldProps } from '../../../domain/entities/field';
import LoginFieldRepository from '../../ports/LoginFieldsRepository';

export type checkDuplicateFieldUseCase = (payload: {
  companyId: number;
  fieldName: string;
}) => Promise<FetchLoginFieldProps>;

export const buildCheckDuplicateField = (dependencies: {
  loginFieldRepo: LoginFieldRepository;
}): checkDuplicateFieldUseCase => {
  const { loginFieldRepo } = dependencies;

  const checkDuplicateField: checkDuplicateFieldUseCase = async payload =>
    loginFieldRepo.checkDuplicateFieldName(payload);

  return checkDuplicateField;
};
