import React, { useEffect, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { theme } from '../../../config';
import { Info } from '../../atoms/Icons';
import words, { languageUsed } from '../../../constants/words';
import { DataTable } from '../DataTable';
import TooltipComponent from '../../atoms/Tooltip/Tooltip';
import { AnchorTag } from '../../atoms/AnchorTag';
import { Button } from '../../atoms/Button';
import { useRankingHooks } from '../../../hooks/rankings';
import { useGlobalState } from '../../../hooks/global';
import { RankingFields } from '../../../domain/entities/rankings';
import { useNavigate, useLocation } from 'react-router';
import { EmptyResultDisplay } from '../EmptyResultDisplay';
import { AxiosError } from 'axios';
import { navigateToErrorPage } from '../../../utils/handleErrorPage';

export const GlobalStyle = createGlobalStyle`
  .delete-disabled-ranking-tooltip {
    min-width: 256px !important;
    max-width: 256px;
    
    .bx--tooltip__content {
      width: 224px;
    }
    .bx--tooltip__caret {
      left: 90px;
    }
  }

  .activate-disabled-ranking-tooltip {
    width: 256px;
  }
`;

const Container = styled.div``;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  height: 64px;
`;

const ActivateButton = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;

  letter-spacing: 0.16px;
  color: ${theme.colors.interactive04};
  padding-right: 32px;
  cursor: pointer;
  white-space: nowrap;
  :hover {
    text-decoration: underline;
  }
`;

const DeleteButton = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;

  letter-spacing: 0.16px;
  color: ${theme.colors.errorColor};
  padding-right: 16px;
  cursor: pointer;
  white-space: nowrap;
  :hover {
    text-decoration: underline;
  }
`;

const InfoSpan = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;

  letter-spacing: 0.32px;

  /* text / text-02 */

  color: ${theme.colors.text02};

  display: flex;
  align-items: center;
  svg {
    height: 17.5px;
    width: 17.5px;
    margin-right: 9.25px;
    margin-left: 6.25px;
  }
`;

const StyledDivActions = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledTooltip = styled(TooltipComponent)`
  &&&&& {
    float: right;
  }
`;

const StyledAnchorTag = styled(AnchorTag)`
  &&&&& {
    div {
      color: ${theme.colors.anchorTagModal};
    }
  }
`;

const StyledDataTable = styled(DataTable)`
  .bx--data-table th:last-of-type,
  .bx--data-table td:last-of-type {
    width: 150px;
  }
`;

const EmptyResultContainer = styled.div`
  background: ${theme.colors.white};
`;

export type Props = {
  tabIndex?: number;
};

const HEADERS = [
  { key: 'id', header: 'No' },
  { key: 'name', header: words.rankingName },
  { key: 'index', header: words.rankDisplaySetting },
  { key: 'actions', header: words.actions },
];

type row = {
  id: string;
  name: string;
  index: string;
  actions: string | React.ReactElement;
}[];

const Component = ({ tabIndex }: Props): React.ReactElement => {
  const { pathname: locationPathname } = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [shouldOpenActiveTooltip, setShouldOpenActiveTooltip] = useState<
    boolean
  >(false);
  const [shouldOpenDeleteTooltip, setShouldOpenDeleteTooltip] = useState<
    boolean
  >(false);
  const [queryParams, setQueryParams] = useState<{
    page: number;
    pageSize: number;
  }>({ page: 1, pageSize: 10 });
  const [toDeleteRankingId, setToDeleteRankingId] = useState<
    number | undefined
  >(undefined);
  const [toEnableRankingId, setToEnableRankingId] = useState<
    number | undefined
  >(undefined);
  const { useSetRankings } = useRankingHooks();
  const { deleteRanking, updateRanking } = useSetRankings();
  const { useGetRankingList } = useRankingHooks();
  const { getRankingList } = useGetRankingList();

  const {
    mutate: deleteRankingMutation,
    isLoading: isDeletingRanking,
  } = useMutation(
    (id: number) => {
      return deleteRanking(id);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
        setToDeleteRankingId(undefined);

        if (rankings.length === 1 && queryParams.page !== 1) {
          setQueryParams({ ...queryParams, page: queryParams.page - 1 });
        }
      },
    },
  );
  const {
    mutate: reenableRankingMutation,
    isLoading: isReenablingRanking,
  } = useMutation(
    (id: number) => {
      return updateRanking(id, { is_active: true });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
        setToEnableRankingId(undefined);
      },
    },
  );

  const {
    useCurrentUser: { currentUser },
  } = useGlobalState();

  const {
    data: { results: rankings, count: rankingListCount } = {
      results: [],
      count: 0,
    },
    isFetching: isFetchingRankings,
  } = useQuery(
    ['disabled-ranking', queryParams],
    async () => {
      if (!currentUser?.companyId) {
        throw new Error(`'company id' not set`);
      }
      const query = {
        companyId: currentUser.companyId,
        page: queryParams?.page,
        page_size: queryParams?.pageSize,
        isActive: false,
      };
      return getRankingList(query);
    },
    {
      onError: (err: AxiosError) => {
        navigateToErrorPage(navigate, err.response?.status, locationPathname);
      },
    },
  );

  const renderIndexForRow = (data: RankingFields[]) => {
    const rankingFieldData = data.filter(item => item.is_basis === true);
    return rankingFieldData[0]?.name;
  };

  const renderRowData = () => {
    const rows: row = rankings.map((item, index) => ({
      id: (
        (queryParams.page - 1) * queryParams.pageSize +
        index +
        1
      ).toString(),
      name: item.name,
      index: renderIndexForRow(item.ranking_fields) as string,
      actions: renderIndex(item.id),
    }));
    return rows;
  };

  const renderIndex = (id: number) => {
    return (
      <StyledDivActions>
        <StyledTooltip
          message={words.confirmEnabling}
          direction={'bottom'}
          isOpen={toEnableRankingId === id && shouldOpenActiveTooltip}
          tooltipClassName="activate-disabled-ranking-tooltip"
          onChange={(ev, { open: isOpen }) => {
            if (!isOpen) {
              setToEnableRankingId(undefined);
            }
          }}
          triggerElement={
            <ActivateButton
              onClick={ev => {
                ev.stopPropagation();
                setShouldOpenActiveTooltip(true);
                setShouldOpenDeleteTooltip(false);
                setToEnableRankingId(id);
              }}>
              {words.disabledActivateRankingButton}
            </ActivateButton>
          }>
          <div className="bx--tooltip__footer">
            <StyledAnchorTag
              onPress={e => {
                e.stopPropagation();
                setShouldOpenActiveTooltip(false);
                setToEnableRankingId(undefined);
              }}
              title={words.cancel}
            />
            <Button
              onPress={e => {
                e.stopPropagation();
                setShouldOpenActiveTooltip(false);
                reenableRankingMutation(id);
              }}
              style={{
                fontSize: languageUsed === 'en' ? '16px' : '13px',
                width: languageUsed === 'en' ? 'auto' : '117px',
                height: languageUsed === 'en' ? 'auto' : '32px',
              }}
              title={words.disabledActivateRanking}
              disabled={isReenablingRanking}
            />
          </div>
        </StyledTooltip>
        <StyledTooltip
          message={words.deleteRanking}
          direction={'bottom'}
          isOpen={toDeleteRankingId === id && shouldOpenDeleteTooltip}
          onChange={(ev, { open: isOpen }) => {
            if (!isOpen) {
              setToDeleteRankingId(undefined);
            }
          }}
          triggerElement={
            <DeleteButton
              onClick={ev => {
                ev.stopPropagation();
                setShouldOpenActiveTooltip(false);
                setShouldOpenDeleteTooltip(true);
                setToDeleteRankingId(id);
              }}>
              {words.disabledColumnDeleteRanking}
            </DeleteButton>
          }
          menuOffset={{ left: -50 }}
          tooltipClassName="delete-disabled-ranking-tooltip">
          <div className="bx--tooltip__footer">
            <StyledAnchorTag
              onPress={e => {
                e.stopPropagation();
                setShouldOpenDeleteTooltip(false);
                setToDeleteRankingId(undefined);
              }}
              title={words.cancel}
            />
            <Button
              onPress={e => {
                e.stopPropagation();
                setShouldOpenDeleteTooltip(false);
                deleteRankingMutation(id);
              }}
              style={{ fontSize: 13, minWidth: 117 }}
              title={words.disabledColumnDeleteRanking}
              disabled={isDeletingRanking}
            />
          </div>
        </StyledTooltip>
      </StyledDivActions>
    );
  };

  const navigateToEdit = (path: string) => {
    navigate(path);
  };

  const handleClickRow = (id: string) => {
    const actualObject = rankings[parseInt(id) - 1];
    navigateToEdit(`ranking-details/${actualObject.id}`);
  };

  useEffect(() => {
    // reset query params on tab change
    if (tabIndex) {
      setQueryParams({ page: 1, pageSize: 10 });
    }
  }, [tabIndex]);

  return (
    <Container>
      <GlobalStyle />
      <Row>
        <InfoSpan>
          <Info />
          {words.findDisabledRankkings}
        </InfoSpan>
      </Row>
      {rankings.length || isFetchingRankings ? (
        <StyledDataTable
          rows={renderRowData()}
          headers={HEADERS}
          onChangePage={setQueryParams}
          page={queryParams.page}
          pageSize={queryParams.pageSize}
          totalItems={rankingListCount}
          isLoading={isFetchingRankings}
          onClickRow={id => handleClickRow(id)}
        />
      ) : (
        <EmptyResultContainer>
          <EmptyResultDisplay
            title={words.noDisabledRankingTitle}
            details={words.noDisabledRankingDetails}
          />
        </EmptyResultContainer>
      )}
    </Container>
  );
};

export default Component;
