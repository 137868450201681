import { Member } from '../../../domain/entities/member';
import { PaginationQuery } from '../../../domain/entities/request';
import { PaginatedResponse } from '../../../domain/entities/response';
import MemberRepository from '../../ports/MemberRepository';

export type fetchMultiSelectUseCase = (
  query: PaginationQuery & {
    companyId: number;
    groupId?: number | undefined;
    roleName?: string | undefined;
    name?: string | undefined;
  },
) => Promise<PaginatedResponse & { results: Member[] }>;

export const buildFetchMultiSelect = (dependencies: {
  memberRepo: MemberRepository;
}): fetchMultiSelectUseCase => {
  const { memberRepo } = dependencies;

  const fetchMultiSelect: fetchMultiSelectUseCase = async query =>
    memberRepo.fetchMultiSelect(query);

  return fetchMultiSelect;
};
