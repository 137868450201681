import React from 'react';
import styled from 'styled-components';

import { theme } from '../../../config';

import NoResultsImage from '../../../assets/images/no-results.png';

export const Container = styled.div`
  padding: 188px 0;
  margin: auto;
  text-align: center;
  background-color: ${theme.colors.white};

  @media ${theme.breakpoints.mobile} {
    padding: 0;
  }
`;

export const EmptyResultImage = styled.img`
  height: 160px;
  object-fit: cover;

  @media ${theme.breakpoints.mobile} {
    height: 126.95px;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const EmptyResultTitle = styled.div`
  margin-top: 19px;
  font-size: 28px;
  line-height: 36px;
  text-align: center;
  color: ${theme.colors.blackText};

  @media ${theme.breakpoints.mobile} {
    margin-top: 15.07px;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
  }
`;

export const EmptyResultDetails = styled.div`
  margin-top: 17px;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  white-space: pre-wrap;
  color: ${theme.colors.blackText};

  @media ${theme.breakpoints.mobile} {
    margin-top: 5.57px;
    margin-left: auto;
    margin-right: auto;
    white-space: normal;
    width: 248px;
  }
`;

export type Props = {
  title: string;
  details: string;
  className?: string;
};

const Component = ({
  title,
  details,
  className,
}: Props): React.ReactElement => {
  return (
    <Container className={className}>
      <EmptyResultImage src={NoResultsImage} />
      <EmptyResultTitle>{title}</EmptyResultTitle>
      <EmptyResultDetails>{details}</EmptyResultDetails>
    </Container>
  );
};

export default Component;
