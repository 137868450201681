import { Company } from '../../../domain/entities/company';
import CompanyRepository from '../../ports/CompanyRepository';

export type findCompanyUseCase = (
  companyId: number,
) => Promise<Company | undefined>;

export const buildFindCompany = (dependencies: {
  companyRepo: CompanyRepository;
}): findCompanyUseCase => {
  const { companyRepo } = dependencies;

  const findCompany: findCompanyUseCase = identifiers =>
    companyRepo.find(identifiers);

  return findCompany;
};
