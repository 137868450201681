import React, { useEffect, useState } from 'react';
import { TypeModeProfileBtn } from 'components/molecules/TypeModeProfileBtn';
import { MemberAccountSetting } from '../MemberAccountSetting';
import { checkInitDone, getCurrentUser } from 'utils/common';
import { Loading } from 'components/molecules/Loading';
import { useNavigate } from 'react-router';
import urls from 'constants/urls';
import constants from 'constants/constants';

export default function MemberProfile() {
  const [mode, setMode] = useState(1);
  const [isLoading, setIsLoading] = useState('none');
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading('block');
    const doneStt = checkInitDone();
    if (doneStt == '') {
      const user = getCurrentUser();
      if (!user.is_done_initial) {
        setIsLoading('none');
        navigate(urls.account_setting);
      }
    }
    if (doneStt == constants.SETTING) {
      setIsLoading('none');
      navigate(urls.account_setting);
      return;
    }
    if (doneStt == constants.SIGN_IN) {
      setIsLoading('none');
      navigate(urls.sign_in_page);
      return;
    }
    setIsLoading('none');
    return;
  }, []);

  return (
    <div className="member-profile-page">
      <Loading display={isLoading} />
      <TypeModeProfileBtn mode={mode} updateMode={mode => setMode(mode)} />
      {mode === 1 ? (
        <MemberAccountSetting modeSee={true} />
      ) : (
        <div className="pt-5 w-100 text-center">Chart</div>
      )}
    </div>
  );
}
