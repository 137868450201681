import { FetchLoginFieldProps } from '../../../domain/entities/field';
import LoginFieldRepository from '../../ports/LoginFieldsRepository';

export type fetchLoginFieldsUseCase = (query: {
  companyId: number;
}) => Promise<FetchLoginFieldProps[]>;

export const buildFetchLoginFields = (dependencies: {
  loginFieldRepo: LoginFieldRepository;
}): fetchLoginFieldsUseCase => {
  const { loginFieldRepo } = dependencies;

  const fetchLoginFields: fetchLoginFieldsUseCase = async query =>
    loginFieldRepo.fetch(query);

  return fetchLoginFields;
};
