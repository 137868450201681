import styled from 'styled-components';
import { theme } from '../../../config';

export const MainWrapper = styled.div`
  @media ${theme.breakpoints.mobile} {
    .bx--grid {
      padding: 0 23px;
    }

    .bx--text-input__field-wrapper[data-invalid] ~ .bx--form-requirement {
      font-size: 10px;
    }
  }
`;

export const BottomWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 3%;
  @media ${theme.breakpoints.mobileAndTablet} {
    flex-direction: column-reverse;
    margin-top: 2%;
  }

  @media ${theme.breakpoints.mobile} {
    padding-top: 25px;
  }
`;

export const NoteWrapper = styled.div`
  display: flex;
  @media ${theme.breakpoints.pc} {
    justify-content: start;
  }
  @media ${theme.breakpoints.mobileAndTablet} {
    margin-top: 8%;
  }

  @media ${theme.breakpoints.mobile} {
    margin-top: 25px;
  }
`;

export const Note = styled.text`
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.16px;
  color: ${theme.colors.interactive02};
  padding-right: 2px;
`;

export const ButtonWrapper = styled.div`
  width: 198px;
  align-items: center;
  @media ${theme.breakpoints.mobile} {
    height: 38px;
    width: 100%;
    margin: 0 auto;
  }

  & button {
    @media ${theme.breakpoints.mobile} {
      height: 38px !important;
      font-size: 14px !important;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.15px;
    }
`;

export const LoadingWrapper = styled.div`
  align-items: center;
  height: 48px;
  @media ${theme.breakpoints.mobile} {
    justify-content: center;
    width: 100%;
    height: 38px;
    margin: 2rem 0 0;
  }
`;

export const Spacer = styled.div`
  margin-bottom: 2.5rem;
  @media ${theme.breakpoints.mobile} {
    margin-bottom: 0;
  }
`;

export const Logo = styled.div<{ imageUrl: string }>`
  background-image: url(${(props): string => props.imageUrl});
  background-repeat: no-repeat;
  background-size: contain;
  height: 23px;
  margin-bottom: 5%;
  @media ${theme.breakpoints.mobile} {
    height: 18px;
    margin-bottom: 22px;
  }
`;

export const StyledHeader = styled.p`
  font-size: 28px;
  font-weight: 400;
  color: ${theme.colors.black};
  margin-bottom: 24px;

  @media ${theme.breakpoints.mobile} {
    font-size: 20px;
    font-weight:500;
    margin-bottom: 15px;
`;

export const StyledText = styled.p`
  color: ${theme.colors.black};
  margin: 0;
  margin-bottom: 39px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.15px;
  font-size: 13px;
  white-space: pre-wrap;

  @media ${theme.breakpoints.mobile} {
    margin-bottom: 34px;
  }
`;

export const ToastNotifWrapper = styled.div<{ $isSuccess?: boolean }>`
  .bx--inline-notification__subtitle {
    white-space: pre-wrap;
  }

  .bx--inline-notification__text-wrapper {
    display: ${(props): string => (props.$isSuccess ? 'flex' : 'block')};
  }

  @media ${theme.breakpoints.mobile} {
    .bx--inline-notification__title,
    .bx--inline-notification__subtitle {
      font-size: 12px !important;
    }
  }
`;
