import RankingRepository from '../../ports/RankingRepository';
import {
  buildFetchRankingList,
  fetchRankingListUseCase,
} from './fetch-rankinglist';
import { buildCreateRanking, createRankingUseCase } from './create-ranking';
import { buildDeleteRanking, deleteRankingUseCase } from './delete-ranking';
import { buildUpdateRanking, updateRankingUseCase } from './update-ranking';
import {
  buildFetchRankingAppList,
  fetchRankingAppListUseCase,
} from './fetch-ranking-app-list';
import { buildGetRankingList, getRankingListUseCase } from './get-rankinglist';
import {
  buildFetchRankingDetails,
  fetchRankingDetailsUseCase,
} from './fetch-ranking-details';
import {
  buildCheckRankingNameExists,
  checkRankingNameExistsUseCase,
} from './check-ranking-name-exists';
import {
  buildFetchRankingLogs,
  fetchRankingLogsUseCase,
} from './fetch-ranking-logs';
import {
  buildFetchRankingPeriod,
  fetchRankingPeriodUseCase,
} from './fetch-ranking-period';
import {
  buildFetchRankingResult,
  fetchRankingResultUseCase,
} from './fetch-ranking-result';
import {
  buildFetchRankingFieldsLookup,
  fetchRankingFieldsLookupUseCase,
} from './fetch-ranking-fields-lookup';

export default (dependencies: {
  rankingRepo: RankingRepository;
}): {
  fetchRankingList: fetchRankingListUseCase;
  getRankingList: getRankingListUseCase;
  createRanking: createRankingUseCase;
  updateRanking: updateRankingUseCase;
  deleteRanking: deleteRankingUseCase;
  fetchRankingAppList: fetchRankingAppListUseCase;
  fetchRankingDetails: fetchRankingDetailsUseCase;
  checkRankingNameExists: checkRankingNameExistsUseCase;
  fetchRankingLogs: fetchRankingLogsUseCase;
  fetchRankingPeriod: fetchRankingPeriodUseCase;
  fetchRankingResult: fetchRankingResultUseCase;
  fetchRankingFieldsLookup: fetchRankingFieldsLookupUseCase;
} => {
  const { rankingRepo } = dependencies;
  const fetchRankingList = buildFetchRankingList({ rankingRepo });
  const createRanking = buildCreateRanking({ rankingRepo });
  const updateRanking = buildUpdateRanking({ rankingRepo });
  const deleteRanking = buildDeleteRanking({ rankingRepo });
  const fetchRankingAppList = buildFetchRankingAppList({ rankingRepo });
  const getRankingList = buildGetRankingList({ rankingRepo });
  const fetchRankingDetails = buildFetchRankingDetails({ rankingRepo });
  const checkRankingNameExists = buildCheckRankingNameExists({ rankingRepo });
  const fetchRankingLogs = buildFetchRankingLogs({ rankingRepo });
  const fetchRankingPeriod = buildFetchRankingPeriod({ rankingRepo });
  const fetchRankingResult = buildFetchRankingResult({ rankingRepo });
  const fetchRankingFieldsLookup = buildFetchRankingFieldsLookup({
    rankingRepo,
  });

  return {
    fetchRankingList,
    createRanking,
    updateRanking,
    deleteRanking,
    fetchRankingAppList,
    getRankingList,
    fetchRankingDetails,
    checkRankingNameExists,
    fetchRankingLogs,
    fetchRankingPeriod,
    fetchRankingResult,
    fetchRankingFieldsLookup,
  };
};
