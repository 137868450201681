import React from 'react';
import GlobalStateProvider from './global/Provider';
import { AuthHooksContext } from './auth';
import { GroupHooksContext } from './group';
import { MemberHooksContext } from './member';
import { LoginFieldHooksContext } from './field';
import { EntryHooksContext } from './entry';
import { CompanyHooksContext } from './company';
import { RankingHooksContext } from './rankings';
import { NavigationHooksContext } from './navigation';
import { RecordHooksContext } from './record';
import { NotificationHooksContext } from './notification';
import * as authHooks from './auth/hooks';
import * as groupHooks from './group/hooks';
import * as loginFieldHooks from './field/hooks';
import * as memberHooks from './member/hooks';
import * as companyHooks from './company/hooks';
import * as entryHooks from './entry/hooks';
import * as rankingHooks from './rankings/hooks';
import * as navigationHooks from './navigation/hooks';
import * as recordHooks from './record/hooks';
import * as notificationHooks from './notification/hooks';
import { dependencies, Dependencies } from './dependencies';

export const DependenciesContext = React.createContext<Dependencies | null>(
  null,
);

export const useDependencies = (): Dependencies => {
  const context = React.useContext(DependenciesContext);
  if (!context) {
    throw new Error(`Context not instantiated`);
  }
  return context;
};

type Props = {
  children?: React.ReactElement | React.ReactElement[];
};

const Provider = ({ children }: Props): React.ReactElement => {
  return (
    <DependenciesContext.Provider value={dependencies}>
      <GlobalStateProvider>
        {/* put all contexts with hooks starting here */}
        <AuthHooksContext.Provider value={authHooks}>
          <LoginFieldHooksContext.Provider value={loginFieldHooks}>
            <CompanyHooksContext.Provider value={companyHooks}>
              <GroupHooksContext.Provider value={groupHooks}>
                <MemberHooksContext.Provider value={memberHooks}>
                  <RankingHooksContext.Provider value={rankingHooks}>
                    <EntryHooksContext.Provider value={entryHooks}>
                      <NavigationHooksContext.Provider value={navigationHooks}>
                        <RecordHooksContext.Provider value={recordHooks}>
                          <NotificationHooksContext.Provider
                            value={notificationHooks}>
                            {children}
                          </NotificationHooksContext.Provider>
                        </RecordHooksContext.Provider>
                      </NavigationHooksContext.Provider>
                    </EntryHooksContext.Provider>
                  </RankingHooksContext.Provider>
                </MemberHooksContext.Provider>
              </GroupHooksContext.Provider>
            </CompanyHooksContext.Provider>
          </LoginFieldHooksContext.Provider>
        </AuthHooksContext.Provider>
      </GlobalStateProvider>
    </DependenciesContext.Provider>
  );
};

export default Provider;
