import { Approvers } from '../../../domain/entities/group';
import GroupRepository from '../../ports/GroupRepository';

export type fetchApproversUseCase = (query: {
  companyId: number;
}) => Promise<Approvers[]>;

export const buildFetchApprovers = (dependencies: {
  groupRepo: GroupRepository;
}): fetchApproversUseCase => {
  const { groupRepo } = dependencies;

  const fetchApprovers: fetchApproversUseCase = async query =>
    groupRepo.fetchApprovers(query);

  return fetchApprovers;
};
