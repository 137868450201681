import { createContext, useContext } from 'react';
import {
  Ranking,
  RankingFields,
  RankingPeriod,
} from '../../domain/entities/rankings';
import {
  RankingApplication,
  RankingResult,
  RankingFieldsLookup,
} from '../../domain/entities/ranking';
import { PaginationQuery } from '../../domain/entities/request';
import { PaginatedResponse } from '../../domain/entities/response';

export type RankingHooks = {
  useGetRankingList: () => {
    getRankingList: (
      params: PaginationQuery & {
        companyId: number;
        isActive: boolean;
        appliedByMember?: number;
      },
    ) => Promise<PaginatedResponse & { results: Ranking[] }>;
    getRankingAppList: (
      params: PaginationQuery & { companyId: number; memberId: number },
    ) => Promise<PaginatedResponse & { results: RankingApplication[] }>;
    fetchRankingPeriod(query: {
      companyId: number;
      rankingId: number;
      isActive: boolean;
      isVisible: boolean;
    }): Promise<RankingPeriod[]>;
    fetchRankingResult(
      query: PaginationQuery & {
        companyId: number;
        rankingId: number;
        rankingPeriodStart?: Date;
        rankingPeriodEnd?: Date;
      },
    ): Promise<PaginatedResponse & { results: RankingResult[] }>;
    fetchRankingFieldsLookup(query: {
      companyId: number;
      rankingId: number;
    }): Promise<RankingFieldsLookup[]>;
  };
  useGetRankingDetails: () => {
    getRankingDetails: (rankingId: number) => Promise<Ranking>;
  };
  useSetRankings: () => {
    createRanking: (payload: {
      companyId: number;
      name: string;
      fields: Omit<RankingFields, 'id' | 'ranking_id'>[];
      periods: RankingPeriod[];
      memberIds: number[];
    }) => Promise<void>;
    updateRanking: (
      rankingId: number,
      fields: Partial<Ranking>,
    ) => Promise<object | null>;
    deleteRanking: (rankingId: number) => Promise<void>;
    getRanking: (payload: {
      companyId: number;
      rankingId: number;
      isActive?: boolean;
    }) => Promise<Ranking>;
  };
  useCheckDuplicateFieldRankingName: () => {
    checkDuplicateRankingName: (params: {
      companyId: number;
      rankingName: string;
    }) => Promise<object | null>;
  };
  useGetRankingLogs: () => {
    fetchRankingLogs: (rankingId: number) => Promise<object | null>;
  };
};

export const RankingHooksContext = createContext<RankingHooks | null>(null);

export const useRankingHooks = (): RankingHooks => {
  const context = useContext(RankingHooksContext);
  if (!context) {
    throw new Error(`Context not instantiated`);
  }
  return context;
};
