import React, { useState, useCallback } from 'react';
import { ValueContainerProps, PropsValue, components } from 'react-select';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { FormikErrors } from 'formik';

import words from '../../../constants/words';
import { theme } from '../../../config';

import { GroupApprover } from '../../../domain/entities/group';
import {
  EntryFields,
  FetchEntryFieldProps,
} from '../../../domain/entities/entry';

import { DatePicker } from '../../molecules/DatePicker';
import { TextInput } from '../../atoms/TextInput';
import { Dropdown } from '../../molecules/Dropdown';
import { MultiSelectDropdown } from '../MultiSelectDropdown';
import { IOptions as MultiSelectOptions } from '../MultiSelectDropdown/MultiSelectDropdown';
import { TransactionHistory } from '../TransactionHistory';

import {
  GlobalStyle,
  FirstColumn,
  SecondColumn,
  SecondColumnFields,
  MainContainer,
  DatesContainer,
  ButtonsContainer,
  MultiSelectContainer,
  MultiSelectPlaceholder,
  StatusTitle,
  Status,
  StatusWrapper,
  TransactionTitle,
  NotesInput,
} from './elements';
import { EntryLogProps } from '../../molecules/TransactionHistoryEntry/TransactionHistoryEntry';

export type StatusTypes = 'unapproved' | 'approved' | 'rejected' | 'withdraw';

export type RecordAppFormType = {
  applicationDate: Date;
  salesDate?: Date;
  customFields: FetchEntryFieldProps[];
  mainApprover?: GroupApprover;
  subApprovers?: PropsValue<MultiSelectOptions> | any;
  notes?: string;
  status: StatusTypes;
};

export type Props = RecordAppFormType & {
  approverOptions: GroupApprover[];
  onChangeSalesDate: (value: Date) => void;
  setFieldValue: (
    field: string,
    value: string | number,
    shouldValidate?: boolean,
  ) => void;
  onChangeMainApprover: (value: GroupApprover) => void;
  onChangeSubApprovers: (value: PropsValue<MultiSelectOptions>) => void;
  onChangeNotes: (value: string) => void;
  handleSubmit: () => void;
  errors?: FormikErrors<RecordAppFormType>;
  renderButtons: React.ReactElement;
  histories: EntryLogProps[];
  originalData?: any;
};

const Component = ({
  applicationDate,
  salesDate,
  customFields = [],
  mainApprover,
  subApprovers,
  notes,
  approverOptions,
  onChangeSalesDate,
  setFieldValue,
  onChangeMainApprover,
  onChangeSubApprovers,
  errors,
  renderButtons,
  status,
  histories,
  originalData,
}: Props): React.ReactElement => {
  const [selectedSubApprovers, setSelectedSubApprrovers] = useState<
    PropsValue<MultiSelectOptions> | undefined
  >(subApprovers);

  const handleSetFieldValueDeci = (fieldIndex: number) => (
    values: NumberFormatValues,
  ) => {
    const { value } = values;
    setFieldValue(`customFields[${fieldIndex}].value_decimal`, value || 0);
    setFieldValue(`customFields[${fieldIndex}].valueForValidation`, value);
  };

  const handleChangeNotes = (event: React.ChangeEvent<Element>) => {
    setFieldValue('notes', (event.target as HTMLInputElement).value);
  };

  const handleChangeMainApprover = useCallback(
    ({ selectedItem }: { selectedItem: GroupApprover }) => {
      onChangeMainApprover(selectedItem);
    },
    [],
  );

  const handleChangeSubApprovers = useCallback(selectedList => {
    setSelectedSubApprrovers(selectedList);
    onChangeSubApprovers(selectedList);
  }, []);

  const checkCustomFieldErrors = useCallback(
    (index: number) => {
      if (errors?.customFields) {
        const customFieldError = (errors.customFields[
          index
        ] as unknown) as FormikErrors<EntryFields | undefined>;
        if (customFieldError?.value_decimal)
          return customFieldError?.value_decimal;
        else if (customFieldError?.value_char)
          return customFieldError.value_char;
      }
      return undefined;
    },
    [errors],
  );

  const subApproversArray =
    (subApprovers as { value: string; label: string }[]) || [];

  const mainApproverOptions: GroupApprover[] = approverOptions.filter(
    (value: GroupApprover) => {
      return !subApproversArray.find(
        subApprover => Number(subApprover.value) == value.id,
      );
    },
  );

  const subApproverOptions: MultiSelectOptions[] = approverOptions
    .filter(approver => approver.id !== mainApprover?.id)
    .map((value: GroupApprover) => {
      const subApproverOption: MultiSelectOptions = {
        value: value.id.toString(),
        label: `${value.member?.name}`,
      };
      return subApproverOption;
    });

  const disabledDates = (date: Date) => {
    const dateNow = new Date();
    return date > dateNow;
  };

  const subApproversLength = ((selectedSubApprovers as unknown) as []).length;

  const CustomValueContainer: React.FC<ValueContainerProps> = ({
    children,
    className,
    ...props
  }) => {
    const childrenArray = React.Children.toArray(children);
    const lastElement = (childrenArray[
      childrenArray.length - 1
    ] as unknown) as { props: { value: string } };

    const shouldShowPlaceholder =
      subApproversLength > 0 && !lastElement.props.value;

    const newClassName =
      className +
      (shouldShowPlaceholder ? ' multi-select-sub-approver-exists' : '');

    return (
      <components.ValueContainer className={newClassName} {...props}>
        {children}
        {shouldShowPlaceholder && (
          <MultiSelectPlaceholder>
            {words.subApproverPlaceholder}
          </MultiSelectPlaceholder>
        )}
      </components.ValueContainer>
    );
  };

  const RenderStatus = ({ isMobile }: { isMobile?: boolean }) => {
    const statusName =
      words.recordRefStatus[status].charAt(0).toUpperCase() +
      words.recordRefStatus[status].slice(1);
    return (
      <StatusWrapper
        className={isMobile ? 'member-app-details-status-mobile' : undefined}>
        <StatusTitle status={status}>{words.status}</StatusTitle>
        <Status className={`approval-status-${status}`}>{statusName}</Status>
      </StatusWrapper>
    );
  };
  const originalSubApprovers = originalData?.approvers
    .filter(function(obj: any) {
      return !obj.is_main;
    })
    .map(({ group_approver }: { group_approver: any }) => group_approver.id);
  const subApproversMapped = subApprovers?.map(
    ({ value }: { value: any }) => value,
  );
  let subApproverChanges = false;
  if (originalSubApprovers.length > 0) {
    subApproverChanges = !originalSubApprovers.every((val: any) =>
      subApproversMapped.includes(val.toString()),
    );
  } else if (subApproversMapped.length > 0) {
    subApproverChanges = true;
  }
  const originalCustomFieldsValues = originalData.entry.fields?.map(
    ({ value_char, value_decimal }: { value_char: any; value_decimal: any }) =>
      value_char ? value_char : Number(value_decimal),
  );
  const currentCustomFieldsValues = customFields.map(
    ({
      value_char,
      value_decimal,
    }: {
      value_char?: string | undefined;
      value_decimal?: number | undefined;
    }) => (value_char ? value_char : Number(value_decimal)),
  );
  const hasChanges =
    notes === originalData?.notes &&
    originalData?.approvers.find((x: any) => x.is_main === true)?.group_approver
      .id === mainApprover?.id &&
    !subApproverChanges &&
    salesDate &&
    new Date(salesDate).toLocaleDateString('en-CA') ===
      originalData.entry.sales_date.split('T')[0] &&
    JSON.stringify(originalCustomFieldsValues) ===
      JSON.stringify(currentCustomFieldsValues)
      ? false
      : true;
  return (
    <MainContainer>
      <FirstColumn>
        <RenderStatus isMobile />
        <DatesContainer>
          <DatePicker
            label={words.applicationDate}
            value={applicationDate}
            datePickerType="single"
            disabled
          />
          <DatePicker
            label={words.salesDate}
            value={salesDate}
            datePickerType="single"
            datePickerProps={{
              disable: [disabledDates],
            }}
            onChange={onChangeSalesDate}
          />
        </DatesContainer>
        {customFields?.map((customFields, index) => {
          const fieldValue = customFields.value_decimal
            ? parseFloat(`${customFields.value_decimal}`)
            : '';
          return (
            <NumberFormat
              key={index}
              id={`${customFields.id}`}
              customInput={TextInput}
              value={fieldValue || ''}
              label={`${customFields.field_lookup_name}`}
              onValueChange={handleSetFieldValueDeci(index)}
              invalid={!!checkCustomFieldErrors(index)}
              invalidText={checkCustomFieldErrors(index)}
              thousandSeparator
              hasStarIndex={index === 0}
            />
          );
        })}
        <NotesInput
          id="application-notes"
          label={words.notes}
          value={notes}
          onChange={handleChangeNotes}
        />
      </FirstColumn>
      <SecondColumn>
        <ButtonsContainer> {renderButtons} </ButtonsContainer>
        <RenderStatus />
        <SecondColumnFields>
          <Dropdown
            id="member-group"
            label=""
            titleText={`${words.mainApprover}${words.requiredMark}`}
            styles={{
              width: '100%',
            }}
            items={mainApproverOptions}
            onChange={handleChangeMainApprover}
            backgroundColor={theme.colors.white}
            dropdownProps={{
              selectedItem: mainApprover,
              itemToString: (item: GroupApprover) => item.member?.name,
            }}
          />
          {subApprovers ? (
            <MultiSelectContainer>
              <MultiSelectDropdown
                label={words.subApproversOptional}
                isMulti={true}
                defaultValue={subApprovers}
                options={subApproverOptions}
                onChange={handleChangeSubApprovers}
                placeholder={words.subApproverPlaceholder}
                components={{
                  ValueContainer: CustomValueContainer,
                }}
                noOptionsText={words.noSubApproverFound}
              />
            </MultiSelectContainer>
          ) : null}
          <NotesInput
            id="application-notes"
            className="record-app-form-mobile"
            label={words.notes}
            value={notes}
            onChange={handleChangeNotes}
          />
          <TransactionTitle>{words.transactionHistory}</TransactionTitle>
          <TransactionHistory histories={histories} />
        </SecondColumnFields>
      </SecondColumn>
      <GlobalStyle />
    </MainContainer>
  );
};

export default Component;
