import CompanyRepository from '../../ports/CompanyRepository';

export type verifyCompanyEmailUseCase = (
  companyId: string,
  token: string,
) => Promise<void>;

export const buildVerifyCompanyEmail = (dependencies: {
  companyRepo: CompanyRepository;
}): verifyCompanyEmailUseCase => {
  const { companyRepo } = dependencies;

  const verifyCompanyEmail: verifyCompanyEmailUseCase = (company_id, token) =>
    companyRepo.emailVerification(company_id, token);

  return verifyCompanyEmail;
};
