import AuthService from '../../usecases/ports/AuthService';
import { HttpAdapter } from '../../usecases/ports/HttpAdapter';
import axios, { AxiosResponse } from 'axios';

export default class AuthServiceRestImpl implements AuthService {
  httpAdapter: HttpAdapter;

  urls: {
    [key: string]: any;
  };

  constructor(
    httpAdapter: HttpAdapter,
    urls: {
      [key: string]: any;
    },
  ) {
    this.httpAdapter = httpAdapter;
    this.urls = urls;
  }

  async signIn(
    userId: string,
    companyId: string,
    password: string,
  ): Promise<AxiosResponse<any>> {
    const request = await this.httpAdapter.post(this.urls.login, {
      //TODO: current format subject to changes
      username: `${companyId}A${userId}`,
      password: password,
    });
    if (request.data) {
      return request;
    } else {
      throw new Error(request.toString());
    }
  }

  signOut(): Promise<void> {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve();
      }, 500);
    });
  }

  register = async (
    username: string,
    email: string,
    password: string,
    password2: string,
  ): Promise<object | null> => {
    const response = await this.httpAdapter.post(this.urls.register, {
      name: username, // just assign username for now to be unique (no field)
      username: username,
      email: email,
      password: password,
      password2: password2,
    });
    return response.data;
  };

  adminSignUp = async (
    companyName: string,
    groupName: string,
    representativeName: string,
    companyEmail: string,
    userEmailAddress: string,
    companyTelephone: string,
  ): Promise<object | null> => {
    const response = await this.httpAdapter.post(this.urls.adminSignUp, {
      name: companyName,
      representative: representativeName,
      email: companyEmail,
      contact_number: companyTelephone,
      group: groupName,
      user: {
        email: userEmailAddress,
      },
    });
    return response.data;
  };

  adminFirstLogin = async (
    user_id: string,
    password: string,
    password2: string,
    email: string,
    file?: File[],
    customFields?: { identifier: string; value: string }[],
    company_id?: string,
  ): Promise<object | null> => {
    const form_data = new FormData();
    form_data.append('user_id', user_id);
    company_id && form_data.append('company_id', company_id);
    form_data.append('email', email);
    form_data.append('password', password);
    form_data.append('password2', password2);
    const mappedCustomFields = customFields?.map(customField => ({
      id: customField.identifier,
      value: customField.value,
    }));
    if (customFields?.length) {
      form_data.append('custom', JSON.stringify(mappedCustomFields));
    }
    if (file && file.length > 0) form_data.append('photo', file[0]);
    const response = await axios
      .patch(this.urls.adminFirstLogin, form_data, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      })
      .then(res => {
        return res.data;
      })
      .catch(err => {
        throw err;
      });
    return response;
  };

  verifyEmail = async (
    user_id: string,
    email: string,
  ): Promise<object | null> => {
    const params =
      user_id != '0'
        ? {
            user_id: user_id,
            email: email,
          }
        : { email: email };
    const response = await this.httpAdapter.post(this.urls.verifyEmail, params);
    return response.data;
  };

  verifyCompanyEmail = async (email: string): Promise<object | null> => {
    const response = await this.httpAdapter.post(this.urls.verifyCompanyEmail, {
      email: email,
    });
    return response.data;
  };

  adminVerification = async (
    pk: string,
    token: string,
  ): Promise<object | null> => {
    const response = await this.httpAdapter.patch(
      this.urls.adminVerification(pk),
      { params: { token } },
    );

    return response.data;
  };

  forgetPassword = async (
    email: string,
    email_token?: string,
  ): Promise<object | null> => {
    const response = await this.httpAdapter.get(this.urls.forgetPassword, {
      params: { email, email_token },
    });

    return response;
  };

  resetPassword = async (
    pk: string,
    token: string,
    password: string,
    password2: string,
  ): Promise<object | null> => {
    const response = await this.httpAdapter.patch(
      this.urls.resetPassword(pk, token),
      { password, password2 },
    );

    return response;
  };

  verifyAdminEmail = async (email: string): Promise<object | null> => {
    const response = await this.httpAdapter.get(this.urls.adminSignUp, {
      params: { email },
    });
    return response.data;
  };

  changePassword = async (newPassword: string): Promise<Object | null> => {
    const response = await this.httpAdapter.patch(this.urls.changePassword, {
      newPassword,
    });

    return response;
  };
}
