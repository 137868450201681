import { createContext, useContext } from 'react';
import {
  CheckNotificationCount,
  Notification,
  NotificationData,
  UpdateNotificationData,
} from '../../domain/entities/notification';
import { PaginationQuery } from '../../domain/entities/request';
import { PaginatedResponse } from '../../domain/entities/response';

export type NotificationHooks = {
  useFetchNotifications: () => {
    fetchNotifications: (
      params: PaginationQuery & {
        companyId: number;
        userId?: number;
      },
    ) => Promise<PaginatedResponse & { results: Notification[] }>;
  };

  useAddNotification: () => {
    addNotification: (
      payload: { companyId: number },
      data: NotificationData,
    ) => Promise<Response>;
  };

  useUpdateNotification: () => {
    updateNotification: (
      notificationId: number,
      data: UpdateNotificationData,
    ) => Promise<Response>;
  };

  useCheckNotificationCount: () => {
    checkNotificationCount: (query: {
      companyId: number;
      isRead?: boolean;
      userId?: number;
    }) => Promise<CheckNotificationCount>;
  };
};

export const NotificationHooksContext = createContext<NotificationHooks | null>(
  null,
);

export const useNotificationHooks = (): NotificationHooks => {
  const context = useContext(NotificationHooksContext);
  if (!context) {
    throw new Error(`Context not instantiated`);
  }
  return context;
};
