import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { DatePicker, DatePickerInput } from 'carbon-components-react';
import { theme } from '../../../config';
import { languageUsed } from '../../../constants/words';

export type Props = {
  id?: string;
  label?: React.ReactElement | string;
  value?: Date;
  className?: string;
  datePickerType?: 'simple' | 'single' | 'range';
  disabled?: boolean;
  onChange?: (date: Date) => void;
  datePickerProps?: Object;
  invalid?: boolean;
  invalidText?: string;
  placeholder?: string;
  dateformat?: string;
  title?: string;
  maxDate?: string;
  minDate?: string;
};

// because the calendar popup is appended to the body tag
const GlobalStyle = createGlobalStyle`
  &&& {
    .flatpickr-calendar.open {
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      background-color: ${theme.colors.ui01};
    }

    .flatpickr-day:focus {
      outline: 2px solid ${theme.colors.primaryButton1};
      outline-color: ${theme.colors.primaryButton1};
    }

    .flatpickr-day:hover {
      background-color: ${theme.colors.hoverui};
      color: ${theme.colors.ui04};
    }

    .numInputWrapper {
      width: 3.5rem;

      .numInput {
        background-color: ${theme.colors.ui01};
        padding-right: ${languageUsed == 'ja' ? '0' : ''};
      }
    }

    .flatpickr-current-month {
      .cur-month {
        margin: ${languageUsed == 'ja' ? '0' : ''};
      }
    }

    ${languageUsed == 'ja' &&
      `
      .flatpickr-current-month {
        flex-direction: row-reverse;
      }

      .numInputWrapper .arrowUp, .numInputWrapper .arrowDown {
        visibility: hidden;
      }

      .numInputWrapper::after {
        content: '年';
        position: absolute;
        top: 6px;
        left: 40px;
      }
    `}

    
  }
`;
const StyledDatePicker = styled(DatePicker)`
  input {
    border-bottom: 0;
    border-radius: 8px;
    background: ${theme.colors.white};
    border: solid ${theme.colors.icon03} 1px;
    font-family: inherit;
  }

  &.bx--date-picker--single input {
    font-size: 13px;
  }

  &,
  .bx--date-picker-container,
  &.bx--date-picker--simple .bx--date-picker__input,
  .bx--date-picker__input.flatpickr-input {
    width: 100%;
  }

  .bx--date-picker__input:focus,
  .bx--date-picker__input.bx--focused {
    outline: 2px solid ${theme.colors.primaryButton1};
  }

  &.bx--date-picker--simple > div > div > .bx--date-picker__input:disabled {
    background: ${theme.colors.disabled01};
    border: 1px solid ${theme.colors.disabledBorder};
  }

  &.bx--date-picker--single > div > div > .bx--date-picker__input:disabled {
    border: solid 1px ${theme.colors.disabledToggle2};
    background-color: ${theme.colors.disabledButton1};
    color: ${theme.colors.ui04};
    opacity: unset;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
  }
`;

const StyledWrapper = styled.div``;

const Component = ({
  id = '',
  label = '',
  value,
  datePickerProps,
  onChange,
  className,
  datePickerType = 'simple',
  disabled = false,
  invalid = false,
  title = '',
  invalidText = '',
  placeholder = 'yyyy/mm/dd',
  dateformat = 'Y/m/d',
  maxDate,
  minDate,
}: Props): React.ReactElement => {
  const handleOnChange = React.useCallback(
    (args: Date[]) => {
      if (onChange) {
        onChange(args[0]);
      }
    },
    [onChange],
  );
  return (
    <StyledWrapper className={className}>
      <StyledDatePicker
        datePickerType={datePickerType}
        dateFormat={dateformat}
        value={value}
        onChange={handleOnChange}
        locale={languageUsed}
        minDate={minDate}
        maxDate={maxDate}
        {...datePickerProps}>
        <DatePickerInput
          id={id}
          title={title}
          labelText={label}
          placeholder={placeholder}
          disabled={disabled}
          invalid={invalid}
          invalidText={invalidText}
        />
      </StyledDatePicker>
      <GlobalStyle />
    </StyledWrapper>
  );
};

export default Component;
