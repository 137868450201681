import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import themes from '../../../config/themes';

import words from '../../../constants/words';

import { TextInput } from '../../atoms/TextInput';

const ContentContainer = styled.div`
  margin-bottom: 21px;
  .bx--dropdown__wrapper.bx--list-box__wrapper {
    padding-bottom: 19px;
  }

  & > div:first-child {
    margin-top: 30px;
  }

  & > div:not(:first-child) {
    margin-top: 25px;
    @media ${themes.main.breakpoints.mobile} {
      margin-top: 9px;
    }
  }

  .bx--list-box__label {
    font-size: 13px;
  }

  @media ${themes.main.breakpoints.mobile} {
    margin-bottom: 0px;
  }
`;

export type Props = {
  newPassword: string;
  confirmPassword: string;
  onChangeNewPassword: (event: ChangeEvent) => void;
  onChangeConfirmPassword: (event: ChangeEvent) => void;
  newPasswordError?: string;
  confirmPasswordError?: string;
};

const Component = ({
  newPassword,
  confirmPassword,
  onChangeNewPassword,
  onChangeConfirmPassword,
  newPasswordError = '',
  confirmPasswordError = '',
}: Props): React.ReactElement => {
  return (
    <ContentContainer>
      <TextInput
        value={newPassword}
        onChange={onChangeNewPassword}
        label={words.permanentNewPassword}
        id="newPassword"
        showPasswordLabel={words.showPassword}
        hidePasswordLabel={words.hidePassword}
        type="password"
        invalid={newPasswordError.length > 0}
        invalidText={newPasswordError}
      />
      <TextInput
        value={confirmPassword}
        onChange={onChangeConfirmPassword}
        label={words.permanentNewPasswordConfirm}
        id="confirmPassword"
        showPasswordLabel={words.showPassword}
        hidePasswordLabel={words.hidePassword}
        type="password"
        invalid={confirmPasswordError.length > 0}
        invalidText={confirmPasswordError}
      />
    </ContentContainer>
  );
};

export default Component;
