import GroupRepository from '../../ports/GroupRepository';

export type checkDuplicateUseCase = (query: {
  companyId: number;
  groupName: string;
}) => Promise<Response>;

export const buildCheckDuplicateGroupName = (dependencies: {
  groupRepo: GroupRepository;
}): checkDuplicateUseCase => {
  const { groupRepo } = dependencies;

  const checkDuplicateGroupName: checkDuplicateUseCase = async query =>
    groupRepo.checkDuplicateGroupName(query);

  return checkDuplicateGroupName;
};
