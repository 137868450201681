import React from 'react';
import styled from 'styled-components';
import { theme } from '../../../config';

type Props = {
  [key: string]: string | number;
};

const Wrapper = styled.div`
  width: 156px;
  height: 135px;
  @media ${theme.breakpoints.mobile} {
    width: 100px;
    height: 85px;
  }
`;

const Component = (props: Props): React.ReactElement => {
  return (
    <Wrapper>
      <svg
        viewBox="0 0 156 135"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.25 100.938H66.875V112.062H11.25C5.10583 112.062 0.125 107.082 0.125 100.938V11.9375C0.125 5.79333 5.10583 0.8125 11.25 0.8125H144.75C150.894 0.8125 155.875 5.79333 155.875 11.9375V67.5625H144.75V16.9994L81.1706 60.9987C79.2638 62.3215 76.7362 62.3215 74.8294 60.9987L11.25 16.9994V100.938ZM78 49.6513L132.513 11.9375H23.4875L78 49.6513ZM81.2819 86.5306L101.196 106.5L81.2263 126.469L89.125 134.312L116.938 106.5L89.125 78.6875L81.2819 86.5306ZM140.133 106.5L120.219 86.5306L128.062 78.6875L155.875 106.5L128.062 134.312L120.164 126.469L140.133 106.5Z"
          fill="#FF714F"
        />
      </svg>
    </Wrapper>
  );
};

export default Component;
