import { useCallback } from 'react';
import { RankingHooks } from '.';
import { useDependencies } from '..';
import {
  Ranking,
  RankingFields,
  RankingPeriod,
} from '../../domain/entities/rankings';
import {
  RankingApplication,
  RankingLogs,
  RankingResult,
  RankingFieldsLookup,
} from '../../domain/entities/ranking';
import { PaginationQuery } from '../../domain/entities/request';
import { PaginatedResponse } from '../../domain/entities/response';
import { useGlobalState } from '../global';

export const useGetRankingList: RankingHooks['useGetRankingList'] = (): {
  getRankingList: (
    query: PaginationQuery & {
      companyId: number;
      isActive: boolean;
      appliedByMember?: number;
    },
  ) => Promise<PaginatedResponse & { results: Ranking[] }>;
  getRankingAppList: (
    params: PaginationQuery & { companyId: number; memberId: number },
  ) => Promise<PaginatedResponse & { results: RankingApplication[] }>;
  fetchRankingPeriod(query: {
    companyId: number;
    rankingId: number;
    isActive: boolean;
    isVisible: boolean;
  }): Promise<RankingPeriod[]>;
  fetchRankingResult(
    query: PaginationQuery & {
      companyId: number;
      rankingId: number;
      rankingPeriodStart?: Date;
      rankingPeriodEnd?: Date;
    },
  ): Promise<PaginatedResponse & { results: RankingResult[] }>;
  fetchRankingFieldsLookup(query: {
    companyId: number;
    rankingId: number;
  }): Promise<RankingFieldsLookup[]>;
} => {
  const { rankingInteractor } = useDependencies();
  const getRankingList = useCallback(
    async (query: PaginationQuery & { companyId: number }) => {
      const response = await rankingInteractor.fetchRankingList(query);

      return response;
    },
    [],
  );

  const getRankingAppList = useCallback(
    async (
      query: PaginationQuery & { companyId: number; memberId: number },
    ) => {
      const response = await rankingInteractor.fetchRankingAppList(query);

      return response;
    },
    [],
  );

  const fetchRankingPeriod = useCallback(
    async (query: {
      companyId: number;
      rankingId: number;
      isActive: boolean;
      isVisible: boolean;
    }) => {
      const response = await rankingInteractor.fetchRankingPeriod(query);

      return response;
    },
    [],
  );

  const fetchRankingResult = useCallback(
    async (
      query: PaginationQuery & {
        companyId: number;
        rankingId: number;
        rankingPeriodStart: Date;
        rankingPeriodEnd: Date;
      },
    ) => {
      const response = await rankingInteractor.fetchRankingResult(query);

      return response;
    },
    [],
  );

  const fetchRankingFieldsLookup = useCallback(
    async (query: { companyId: number; rankingId: number }) => {
      const response = await rankingInteractor.fetchRankingFieldsLookup(query);

      return response;
    },
    [],
  );

  return {
    getRankingList,
    getRankingAppList,
    fetchRankingPeriod,
    fetchRankingResult,
    fetchRankingFieldsLookup,
  };
};

export const useGetRankingDetails: RankingHooks['useGetRankingDetails'] = (): {
  getRankingDetails: (rankingId: number) => Promise<Ranking>;
} => {
  const { rankingInteractor } = useDependencies();
  const { useCurrentUser } = useGlobalState();
  const { currentUser } = useCurrentUser;

  const getRankingDetails = useCallback(async (rankingId: number) => {
    if (!currentUser?.companyId) {
      throw new Error(`field 'company id' missing`);
    }
    const response = await rankingInteractor.fetchRankingDetails({
      companyId: currentUser.companyId,
      rankingId,
    });
    return response;
  }, []);

  return { getRankingDetails };
};

export const useSetRankings: RankingHooks['useSetRankings'] = (): {
  createRanking: (payload: {
    companyId: number;
    name: string;
    fields: Omit<RankingFields, 'id' | 'ranking_id'>[];
    periods: RankingPeriod[];
    memberIds: number[];
  }) => Promise<void>;
  updateRanking: (
    rankingId: number,
    fields: Partial<Ranking>,
  ) => Promise<object | null>;
  deleteRanking: (rankingId: number) => Promise<void>;
  getRanking: (payload: {
    companyId: number;
    rankingId: number;
    isActive?: boolean;
  }) => Promise<Ranking>;
} => {
  const { rankingInteractor } = useDependencies();
  const { useCurrentUser } = useGlobalState();
  const { currentUser } = useCurrentUser;
  const createRanking = useCallback(async payload => {
    if (!currentUser?.memberId) {
      throw new Error(`field 'user id' missing`);
    }
    await rankingInteractor.createRanking({
      ...payload,
      user_id: currentUser.memberId,
    });
  }, []);

  const updateRanking = useCallback(
    async (
      rankingId: number,
      fields: Partial<Ranking & { user_id: number }>,
    ) => {
      if (!currentUser?.companyId) {
        throw new Error(`field 'company id' missing`);
      }
      if (!currentUser?.userId) {
        throw new Error(`field 'user id' missing`);
      }
      return await rankingInteractor.updateRanking(
        {
          companyId: currentUser.companyId,
          rankingId,
        },
        {
          ...fields,
          user_id: currentUser.memberId,
        },
      );
    },
    [currentUser],
  );
  const deleteRanking = useCallback(
    async (rankingId: number) => {
      if (!currentUser?.companyId) {
        throw new Error(`field 'company id' missing`);
      }
      await rankingInteractor.deleteRanking({
        companyId: currentUser.companyId,
        rankingId,
      });
    },
    [currentUser],
  );

  const getRanking = useCallback(
    async (payload: {
      companyId: number;
      rankingId: number;
      isActive?: boolean;
    }) => {
      if (!currentUser?.companyId) {
        throw new Error(`field 'company id' missing`);
      }
      const response = await rankingInteractor.getRankingList(payload);
      return response;
    },
    [],
  );

  return { createRanking, updateRanking, deleteRanking, getRanking };
};

export const useCheckDuplicateFieldRankingName: RankingHooks['useCheckDuplicateFieldRankingName'] = (): {
  checkDuplicateRankingName: (query: {
    companyId: number;
    rankingName: string;
  }) => Promise<object | null>;
} => {
  const { rankingInteractor } = useDependencies();
  const checkDuplicateRankingName = useCallback(
    async (query: { companyId: number; rankingName: string }) => {
      const response = await rankingInteractor.checkRankingNameExists(query);
      return response;
    },
    [],
  );

  return { checkDuplicateRankingName };
};

export const useGetRankingLogs: RankingHooks['useGetRankingLogs'] = (): {
  fetchRankingLogs: (rankingId: number) => Promise<RankingLogs[] | null>;
} => {
  const { useCurrentUser } = useGlobalState();
  const { currentUser } = useCurrentUser;
  const { rankingInteractor } = useDependencies();
  const fetchRankingLogs = useCallback(async (rankingId: number) => {
    if (!currentUser?.companyId) {
      throw new Error(`field 'company id' missing`);
    }
    const response = await rankingInteractor.fetchRankingLogs({
      companyId: currentUser?.companyId,
      rankingId,
    });
    return response as RankingLogs[];
  }, []);

  return { fetchRankingLogs };
};
