import React from 'react';
import styled from 'styled-components';
import { theme } from '../../../config';
import { Toggle as ToggleSwitch } from 'carbon-components-react';

export type Props = {
  id: string;
  defaultValue: boolean;
  label?: string;
  labelToggledOn: string;
  labelToggledOff: string;
  disabled?: boolean;
  toggled?: boolean;
  onToggle: (value: boolean) => void;
};

const Toggle = styled(ToggleSwitch)`
  .bx--toggle__switch::after {
    background-color: ${theme.colors.ui03};
  }

  .bx--toggle-input:focus
    + .bx--toggle-input__label
    > .bx--toggle__switch::before,
  .bx--toggle-input:active
    + .bx--toggle-input__label
    > .bx--toggle__switch::before {
    box-shadow: none;
  }

  .bx--toggle-input:focus
    + .bx--toggle-input__label
    > .bx--toggle__switch::after,
  .bx--toggle-input:active
    + .bx--toggle-input__label
    > .bx--toggle__switch::after {
    box-shadow: none;
  }

  .bx--toggle-input:disabled
    + .bx--toggle-input__label
    > .bx--toggle__switch::before {
    background-color: ${theme.colors.disabledToggle1};
  }
  .bx--toggle-input:disabled
    + .bx--toggle-input__label
    > .bx--toggle__switch::after {
    background-color: ${theme.colors.disabledToggle2};
  }

  .bx--toggle-input + .bx--toggle-input__label {
    color: ${theme.colors.text01};
  }

  .bx--toggle-input:disabled + .bx--toggle-input__label {
    color: ${theme.colors.ui04};
  }

  .bx--toggle__text--off,
  .bx--toggle__text--on {
    font-size: 13px;
  }
`;

const Wrapper = styled.div``;

const ToggleSwitchComponent = ({
  id,
  label = '',
  defaultValue,
  labelToggledOff,
  labelToggledOn,
  disabled = false,
  toggled = false,
  onToggle,
}: Props): React.ReactElement => {
  return (
    <Wrapper>
      <Toggle
        defaultToggled={defaultValue}
        id={id}
        labelText={label}
        labelA={labelToggledOff}
        labelB={labelToggledOn}
        disabled={disabled}
        onToggle={(value: boolean) => onToggle(value)}
        toggled={toggled}
      />
    </Wrapper>
  );
};

export default ToggleSwitchComponent;
