import * as Yup from 'yup';
import words from '../../../constants/words';

export const AdminSignUpSchema = Yup.object().shape({
  companyName: Yup.string()
    .min(1)
    .max(250),
  groupName: Yup.string()
    .min(1)
    .max(250),
  representativeName: Yup.string()
    .min(1)
    .max(250),
  companyEmail: Yup.string().email(`${words.validEmailAddress}`),
  representativeEmailAddress: Yup.string().email(`${words.validEmailAddress}`),
  companyTelephone: Yup.string()
    .matches(/^[0-9]*$/, `${words.onlyHalfWidthNumbers}`)
    .matches(/^[^\uFF01-\uFF5E]*$/, `${words.onlyHalfWidthNumbers}`)
    .min(1),
});
