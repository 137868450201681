import { UserRoles } from '../domain/entities/member';
import words from './words';

export type Role = {
  id: UserRoles;
  name: string;
};

export default {
  memberRoles: [
    {
      id: 'admin',
      name: words.admin,
    },
    {
      id: 'member',
      name: words.applicant,
    },
    {
      id: 'approver',
      name: words.approver,
    },
  ] as Role[],
  approverOnlyRoles: [
    { id: 'approver', name: words.approver },
    { id: 'member', name: words.applicant },
  ] as Role[],
  memberOnlyRoles: [{ id: 'member', name: words.applicant }] as Role[],
  memberRolesLabels: [
    {
      id: 'admin',
      label: 'Admin',
    },
    {
      id: 'approver',
      label: 'Approver',
    },
    {
      id: 'member',
      label: 'Member',
    },
  ],
  approvalStatus: [
    {
      id: 'unapproved',
      name: words.unapproved,
    },
    {
      id: 'approved',
      name: words.approved,
    },
    {
      id: 'rejected',
      name: words.rejected,
    },
    {
      id: 'withdraw',
      name: words.cancelApplication,
    },
  ],
  roleManagement: ['admin', 'approver'],
};
