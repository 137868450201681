import React from 'react';
import './signUpConfirmContent.scss';

export default function SignUpConfirmContent() {
  return (
    <div className="sign-up-confirm">
      <p>田中様、</p>
      <p>Self Proへご登録いただき、誠にありがとうござい ます。</p>
      <p>
        <span>以下の有効化を</span>クリックして有効化にしてください。
      </p>
      <br />
      <p>
        なおセキュリティ保護の観点から、下記のリンクは
        メール受信後、１時間経過すると無効となります。
      </p>
      <p>
        無効となった際は、お手数ですが、再度アカウント
        登録画面より手続きをお願いいたします。
      </p>
      <br />
      <a href="#">有効化</a>
      <br />
      <br />
      <p>SelfProチーム</p>
    </div>
  );
}
