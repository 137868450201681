import { buildFetchMembers, fetchMembersUseCase } from './fetch-members';
import { buildCreateMember, createMemberUseCase } from './create-member';
import { buildDeleteMember, deleteMemberUseCase } from './delete-member';
import { buildFetchMember, fetchMemberUseCase } from './fetch-member';
import { buildUpdateMember, updateMemberUseCase } from './update-member';
import {
  buildBatchUpdateMembersGroup,
  batchUpdateMembersGroupUseCase,
} from './batch-update-members-group';
import {
  buildGenerateTemporaryPassword,
  generateTemporaryPasswordUseCase,
} from './generate-temporary-password';
import {
  buildFilterDeletableMembers,
  filterDeletableMembersUseCase,
} from './filter-deletable-members';
import {
  buildBatchDeleteMembers,
  batchDeleteMembersUseCase,
} from './batch-delete-members';
import {
  buildFetchMultiSelect,
  fetchMultiSelectUseCase,
} from './fetch-multiselect';
import MemberRepository from '../../ports/MemberRepository';
import {
  buildValidateMemberRoleChangeable,
  validateMemberRoleChangeableUseCase,
} from './validate-member-role-changeable';
import {
  buildCheckUnassignedExists,
  checkUnassignedExistsUseCase,
} from './check-unassigned-exists';
import {
  buildCreateMemberFields,
  createMemberFieldsUseCase,
} from './create-member-fields';
import {
  buildUpdateMemberFields,
  updateMemberFieldsUseCase,
} from './update-member-fields';

export default (dependencies: {
  memberRepo: MemberRepository;
}): {
  fetchMembers: fetchMembersUseCase;
  createMember: createMemberUseCase;
  deleteMember: deleteMemberUseCase;
  fetchMember: fetchMemberUseCase;
  updateMember: updateMemberUseCase;
  batchUpdateMembersGroup: batchUpdateMembersGroupUseCase;
  generateTemporaryPassword: generateTemporaryPasswordUseCase;
  filterDeletableMembers: filterDeletableMembersUseCase;
  batchDeleteMembers: batchDeleteMembersUseCase;
  fetchMultiSelect: fetchMultiSelectUseCase;
  validateMemberRoleChangeable: validateMemberRoleChangeableUseCase;
  checkUnassignedExists: checkUnassignedExistsUseCase;
  createMemberFields: createMemberFieldsUseCase;
  updateMemberFields: updateMemberFieldsUseCase;
} => {
  const { memberRepo } = dependencies;
  const fetchMembers = buildFetchMembers({ memberRepo });
  const createMember = buildCreateMember({ memberRepo });
  const deleteMember = buildDeleteMember({ memberRepo });
  const fetchMember = buildFetchMember({ memberRepo });
  const updateMember = buildUpdateMember({ memberRepo });
  const batchUpdateMembersGroup = buildBatchUpdateMembersGroup({ memberRepo });
  const generateTemporaryPassword = buildGenerateTemporaryPassword({
    memberRepo,
  });
  const filterDeletableMembers = buildFilterDeletableMembers({ memberRepo });
  const batchDeleteMembers = buildBatchDeleteMembers({ memberRepo });
  const fetchMultiSelect = buildFetchMultiSelect({ memberRepo });
  const validateMemberRoleChangeable = buildValidateMemberRoleChangeable({
    memberRepo,
  });
  const checkUnassignedExists = buildCheckUnassignedExists({ memberRepo });
  const createMemberFields = buildCreateMemberFields({ memberRepo });
  const updateMemberFields = buildUpdateMemberFields({ memberRepo });

  return {
    fetchMembers,
    createMember,
    deleteMember,
    fetchMember,
    updateMember,
    batchUpdateMembersGroup,
    generateTemporaryPassword,
    filterDeletableMembers,
    batchDeleteMembers,
    fetchMultiSelect,
    validateMemberRoleChangeable,
    checkUnassignedExists,
    createMemberFields,
    updateMemberFields,
  };
};
