import styled, { createGlobalStyle } from 'styled-components';
import theme from '../../../config/themes/main';
import { Button } from '../../atoms/Button';
import { AnchorTag } from '../../atoms/AnchorTag';
import { TextInput } from '../../atoms/TextInput';
import { Link } from 'carbon-components-react';

export const GlobalStyle = createGlobalStyle`
  & {
    .bx--tooltip__footer.bx--tooltip__footer.bx--tooltip__footer.bx--tooltip__footer.bx--tooltip__footer > button {
      width: 117px;
    }

    .bx--tooltip.bx--tooltip--bottom.bx--tooltip--align-end {
      max-width: 256px;
    }

    .bx--tooltip.bx--tooltip--bottom.bx--tooltip--align-end .bx--tooltip__caret {
      margin-right: 65px;
    }

    .text-input-field-tooltip-message {
      padding: 2px 16px;
      max-width: 360px;
    }

    .copy-text-input-tooltip {
      padding: 2px 16px;
      max-width: 360px;

      .bx--tooltip__caret {
        margin-top: -10px;
      }
    }
  }
`;

export const StyledButton = styled(Button)`
  border-width: 2px;
  &:focus {
    box-shadow: inset 0 0 0 1px ${theme.colors.white};
  }
`;

export const ViewMemberTemplate = styled.div`
  background-color: ${theme.colors.dropDownContainerBgColor};
  min-height: 100vh;
`;

export const Content = styled.div`
  display: flex;
  padding-top: 18px;
  padding-right: 78px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

export const TextInputContainer = styled.div.attrs(
  (props: { isColumn1: boolean }) => ({
    ...props,
  }),
)`
  display: flex;
  flex-direction: column;
  height: 56px;
  width: calc(100% - ${props => (props.isColumn1 ? `78px` : `39px`)});
  margin-bottom: 60px;
  margin-left: ${props => (props.isColumn1 ? `78px` : `39px`)};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;

  &.btn-cont-adjust-margin {
    margin-top: 17px;
  }
`;

export const ButtonContainer = styled.div.attrs(
  (props: { isBack: boolean }) => ({
    ...props,
  }),
)`
  width: 141px;
  height: 48px;
  margin-left: ${props => (props.isBack ? '78px' : '19px')};
  margin-bottom: 60px;
`;

export const ImageContainer = styled.div`
  padding-left: 100px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 22px;
  margin-bottom: 28px;
`;

export const Spacer = styled.div`
  height: 118px;
`;

export const GenerateNewPass = styled(AnchorTag)`
  &&&&& {
    div {
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 18px;
      letter-spacing: 0.16px;
      color: ${theme.colors.interactive04};
    }
  }
`;

export const TempPasswordTextInput = styled(TextInput)`
  margin-bottom: -16px;
`;

export const TempPasswordLabel = styled.text`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.32px;
  color: ${theme.colors.disabled02};
  margin-bottom: 16px;
`;

export const StyledLink = styled(Link)`
  &:hover {
    cursor: pointer;
  }
`;
