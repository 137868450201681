import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { DataTableHeader } from 'carbon-components-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';

import { useGlobalState } from '../../../hooks/global';
import { useRankingHooks } from '../../../hooks/rankings';
import { useMemberHooks } from '../../../hooks/member';

import { navigateToErrorPage } from '../../../utils/handleErrorPage';

import { RankingApplication } from '../../../domain/entities/ranking';

import { Star } from '../../atoms/Icons';
import { DataTable } from '../../organisms/DataTable';
import { Notification } from '../../molecules/Notification';
import { EmptyResultDisplay } from '../../organisms/EmptyResultDisplay';
import {
  MainContainer,
  NotificationInlineErrorWrapper,
  DataTableWrapper,
  ActionsContainer,
  NewApplicationText,
  MobileNotificationInlineErrorWrapper,
  MobileListContainer,
  MobileItemWrapper,
  ItemNumber,
  ItemWrapper,
  ItemName,
  IconIndexWrapper,
  StarWrapper,
  IndexLabel,
  CustomPagination,
} from './elements';

import words from '../../../constants/words';
import { clearRankingFormPersistedData } from '../RecordApplication/RecordApplication';
import { TableLoader } from '../../atoms/Loading';

const HEADERS: DataTableHeader[] = [
  {
    header: words.rankingListHeaders.no,
    key: 'no',
  },
  {
    header: words.rankingListHeaders.name,
    key: 'name',
  },
  {
    header: words.rankingListHeaders.index,
    key: 'index',
  },
  {
    header: words.rankingListHeaders.action,
    key: 'action',
  },
];

export type Props = {
  onClickRecordNewApp: (id: number) => void;
};

const RankingListTemplate = ({
  onClickRecordNewApp,
}: Props): React.ReactElement => {
  const { pathname: locationPathname } = useLocation();
  const navigate = useNavigate();
  const {
    useCurrentUser: { currentUser },
  } = useGlobalState();

  const companyId = currentUser?.companyId;
  const memberId = currentUser?.memberId;

  const { useGetRankingList } = useRankingHooks();
  const { getRankingAppList } = useGetRankingList();

  const { useFetchMembers } = useMemberHooks();
  const { fetchMember } = useFetchMembers();

  const [queryParams, setQueryParams] = useState<{
    page: number;
    pageSize: number;
  }>({ page: 1, pageSize: 10 });

  const {
    data: { results: rankings, count: rankingListCount } = {
      results: [],
      count: 0,
    },
    isFetching: isRankingAppFetching,
    isLoading: isRankingAppLoading,
  } = useQuery(
    ['get-ranking-app-list', queryParams, currentUser],
    () =>
      getRankingAppList({
        page: queryParams.page,
        page_size: queryParams.pageSize,
        companyId: Number(companyId),
        memberId: Number(memberId),
      }),
    {
      onError: (err: AxiosError) => {
        navigateToErrorPage(navigate, err.response?.status, locationPathname);
      },
    },
  );

  const {
    data: memberData,
    isFetching: isMemberFetching,
    isLoading: isMemberLoading,
  } = useQuery(['fetch-member', currentUser], () =>
    fetchMember({ companyId: Number(companyId), memberId: Number(memberId) }),
  );

  // const memberGroupApproverHasError =
  //   !isMemberLoading &&
  //   (!memberData?.groupId || memberData?.isLastApproverInAssignedGroup);
  const memberGroupApproverHasError = false;

  const disableRecordNewApp =
    isMemberFetching ||
    !memberData?.groupId ||
    memberData?.isLastApproverInAssignedGroup;

  const groupApproverErrorMessage = memberData?.groupId
    ? memberData?.isLastApproverInAssignedGroup
      ? words.rankingAppListApproverErrorSubtitle
      : undefined
    : words.rankingAppListGroupErrorSubtitle;

  const handleRecordNewApp = (rankingId: number) => () => {
    clearRankingFormPersistedData();
    // if (!disableRecordNewApp)
    onClickRecordNewApp(rankingId);
  };

  const rankingIndex = (data: RankingApplication) => {
    const indexData = data.ranking.ranking_fields.find(e => e.is_basis);
    return indexData ? indexData.name : '';
  };

  const renderMobileItem = (
    count: number,
    name: string,
    index: string,
    id: number,
  ) => {
    return (
      <MobileItemWrapper>
        <ItemNumber>{count}</ItemNumber>
        <ItemWrapper>
          <ItemName>{name}</ItemName>
          <IconIndexWrapper>
            <StarWrapper>
              <Star />
            </StarWrapper>
            <IndexLabel>{index}</IndexLabel>
          </IconIndexWrapper>
          <NewApplicationText
            // className={disableRecordNewApp ? 'new-application-disabled' : ''}
            onClick={handleRecordNewApp(id)}>
            {words.recordNewApplication}
          </NewApplicationText>
        </ItemWrapper>
      </MobileItemWrapper>
    );
  };

  return (
    <MainContainer
      className={
        rankings.length > 0 || isRankingAppLoading
          ? ''
          : 'ranking-app-list-no-results'
      }>
      {rankings.length > 0 || isRankingAppLoading ? (
        <>
          {memberGroupApproverHasError && (
            <NotificationInlineErrorWrapper>
              <Notification
                kind="error"
                title={words.rankingAppListGroupApproverErrorTitle}
                subtitle={groupApproverErrorMessage}
                inline
                hideClose
              />
            </NotificationInlineErrorWrapper>
          )}
          <DataTableWrapper $hasInlineError={memberGroupApproverHasError}>
            <DataTable
              headers={HEADERS}
              rows={rankings.map((row: RankingApplication, index) => {
                return {
                  id: row.ranking_id,
                  no: (queryParams.page - 1) * queryParams.pageSize + index + 1,
                  name: row.ranking.name,
                  index: rankingIndex(row),
                  action: (
                    <ActionsContainer>
                      <NewApplicationText
                        // className={
                        //   disableRecordNewApp ? 'new-application-disabled' : ''
                        // }
                        onClick={handleRecordNewApp(row.ranking_id)}>
                        {words.recordNewApplication}
                      </NewApplicationText>
                    </ActionsContainer>
                  ),
                };
              })}
              onChangePage={setQueryParams}
              totalItems={rankingListCount}
              unclickableColumns={['action']}
              isLoading={isRankingAppFetching}
              page={queryParams.page}
              pageSize={queryParams.pageSize}
            />
          </DataTableWrapper>
          {memberGroupApproverHasError && (
            <MobileNotificationInlineErrorWrapper
              $isInUnassignedGroup={!memberData?.groupId}>
              <Notification
                kind="error"
                title={words.rankingAppListGroupApproverErrorTitle}
                subtitle={
                  <>
                    <strong>
                      {words.rankingAppListGroupApproverErrorTitle}
                    </strong>
                    <span> {groupApproverErrorMessage}</span>
                  </>
                }
                inline
              />
            </MobileNotificationInlineErrorWrapper>
          )}
          <MobileListContainer $hasInlineError={memberGroupApproverHasError}>
            {isRankingAppFetching ? (
              <TableLoader vh={89} />
            ) : (
              <>
                {' '}
                {rankings.map((row, index) => {
                  return renderMobileItem(
                    (queryParams.page - 1) * queryParams.pageSize + index + 1,
                    row.ranking.name,
                    rankingIndex(row),
                    row.ranking_id,
                  );
                })}
                <CustomPagination
                  pageSizes={[10, 20, 30, 40, 50]}
                  page={queryParams.page}
                  pageSize={queryParams.pageSize}
                  totalItems={rankingListCount}
                  onChange={setQueryParams}
                  itemRangeText={(min: number, max: number, total: number) =>
                    words.pagination(min, max, total)
                  }
                  itemsPerPageText={words.itemsPerPage}
                  pageRangeText={(_current: number, total: number) =>
                    `${words.pageNumber[0]} ${total} ${words.pageNumber[1]}`
                  }
                />
              </>
            )}
          </MobileListContainer>
        </>
      ) : (
        <EmptyResultDisplay
          title={words.rankingAppListEmptyTitle}
          details={words.rankingAppListEmptyDetails}
        />
      )}
    </MainContainer>
  );
};

export default RankingListTemplate;
