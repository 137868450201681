import React from 'react';
import styled from 'styled-components';
import { theme } from '../../../config';
import { TransactionHistoryEntry } from '../../molecules/TransactionHistoryEntry';
import { EntryLogProps } from '../../molecules/TransactionHistoryEntry/TransactionHistoryEntry';

export type Props = {
  histories: EntryLogProps[] | undefined;
};

const Container = styled.div`
  width: 100%;
  background: ${theme.colors.ui01};
  box-shadow: 0px 0px 8px rgba(236, 215, 192, 0.8);
  border: 2px solid ${theme.colors.ui02};
  overflow-y: auto;
  max-height: 338px;
`;

const EntryContainer = styled.div`
  width: calc(100% - 20px);
  border-bottom: 1px solid ${theme.colors.ui03};
  margin-left: 16px;
  :last-child {
    border-bottom: none;
  }
`;

const Component = ({ histories }: Props): React.ReactElement => {
  return (
    <Container>
      {histories?.map((history, index) => (
        <EntryContainer key={index}>
          <TransactionHistoryEntry
            dateAndTime={history.dateAndTime}
            action={history.action}
            reason={history.reason}
            memberName={history.memberName}
          />
        </EntryContainer>
      ))}
    </Container>
  );
};

export default Component;
