import MemberRepository from '../../ports/MemberRepository';
import { MemberField } from '../../../domain/entities/member';

export type updateMemberFieldsUseCase = (
  companyId: number,
  customFields: MemberField[],
) => Promise<void>;

export const buildUpdateMemberFields = (dependencies: {
  memberRepo: MemberRepository;
}): updateMemberFieldsUseCase => {
  const { memberRepo } = dependencies;

  const updateMemberFields: updateMemberFieldsUseCase = async (
    companyId,
    customFields,
  ) => memberRepo.updateMemberFields(companyId, customFields);

  return updateMemberFields;
};
