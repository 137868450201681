import React, { useState, useEffect } from 'react';
import './memberChangePassword.scss';
import LogoSelfPro from 'assets/icons/self-pro-logo.svg';
import { OrangeButton } from 'components/atoms/OrangeButton';
import words from 'constants/words';
import { memberChangePassType, memberChangePassErrorType } from 'type/member';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Loading } from 'components/molecules/Loading';
import CheckCircle from 'assets/icons/check-circle.svg';
import ErrorCircle from 'assets/icons/error-circle.svg';
import urls from 'constants/urls';
import axios from 'axios';
import { extractError, PopUpModal } from 'utils/common';
import { SignedUpSuccessModal } from 'components/templates/SignedUpSuccessModal';
import { ChangePassForm } from 'components/templates/ChangePassForm';
import { validateChangePass } from 'components/templates/ChangePassForm/validates';
import authHeader from 'api/auth-header';

const defaultValue = {
  old_password: '',
  password: '',
  passwordConfirm: '',
};

type PropType = {
  hash: string;
  activeLoading: (_isLoading: boolean) => void;
};

export default function MemberChangePassword(props: PropType) {
  const { hash, activeLoading } = props;
  const [errorResponse, setErrorResponse] = useState('');
  const [resetPassSuccess, setResetPassSuccess] = useState(true);
  const [showModal, setShowModal] = useState('none');
  const navigate = useNavigate();
  const [errors, setErrors] = useState<memberChangePassErrorType>(defaultValue);

  const [forgotInputValue, setForgotInputValue] = useState<
    memberChangePassType
  >(defaultValue);

  useEffect(() => {
    setErrors(defaultValue);
    setErrorResponse('');
    handleUpdateValues(defaultValue);
  }, [hash]);

  async function onSubmitForgot() {
    const listError = await validateChangePass(forgotInputValue);
    if (Object.entries(listError).length !== 0) {
      setErrors(listError);
      return;
    }
    // Handle Change Pass
    setErrors(defaultValue);
    activeLoading(true);
    await axios
      .post(
        urls.change_pass,
        {
          password: forgotInputValue.old_password,
          new_password: forgotInputValue.password,
        },
        { headers: authHeader() },
      )
      .then(res => {
        if (res.data.status_code === 400) {
          if (res.data.errors) {
            const errorSv = extractError(res.data.errors);
            setErrorResponse(errorSv);
          }
          setResetPassSuccess(false);
          activeLoading(false);
          PopUpModal('エラーが発生しました', false);
          return;
        }
        activeLoading(false);
        PopUpModal(
          'パスワードの変更が完了しました。',
          true,
          'パスワード変更完了',
        );
        setErrors(defaultValue);
        setResetPassSuccess(true);
        return;
      })
      .catch(err => {
        if (err.response.data.errors) {
          const errorSv = extractError(err.response.data.errors);
          setErrorResponse(errorSv);
        }
        setResetPassSuccess(false);
        PopUpModal('エラーが発生しました', false);
        activeLoading(false);
      });
  }

  function handleUpdateValues(data: memberChangePassType) {
    setForgotInputValue(data);
  }

  return (
    <div className="change-pages">
      <div className="container-fluid">
        <div className="row flex-row-reverse">
          <div className="col-12 col-sm-12 div-left">
            <SignedUpSuccessModal
              display={showModal}
              onClick={
                resetPassSuccess
                  ? () => navigate(urls.home)
                  : () => setShowModal('none')
              }
              icon={resetPassSuccess ? CheckCircle : ErrorCircle}
              message={
                resetPassSuccess
                  ? words.resetPasswordSuccessful
                  : words.resetPasswordFailed
              }
              textButton="OK"
            />
            <ChangePassForm
              handleUpdateValues={data => handleUpdateValues(data)}
              listError={errors}
              hash={hash}
            />
            <p
              className="errorFromServer"
              dangerouslySetInnerHTML={{ __html: errorResponse }}></p>
            <div className="orangeButton">
              <OrangeButton text={'保存'} onClick={onSubmitForgot} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
