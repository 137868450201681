import React, { ChangeEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Grid, Row, Column } from 'carbon-components-react';
import { theme } from '../../../config';

import { Button } from '../../atoms/Button';
import { TextInput } from '../../atoms/TextInput';
import { Checkbox } from '../../atoms/Checkbox';
import { FileInput } from '../../molecules/FileInput';
import { Notification } from '../../molecules/Notification';

import words from '../../../constants/words';
import LogoImage from '../../../assets/images/ever-contest.png';

const Spacer = styled.div`
  margin-bottom: 2.5rem;
  @media ${theme.breakpoints.mobile} {
    margin-bottom: 0;
  }
`;

const ButtonWrapper = styled.div`
  width: 141px;
  margin: 65px 0 0 auto;
  @media ${theme.breakpoints.mobile} {
    height: 38px;
    width: 100%;
    margin: 37px auto 0 auto;
  }
`;

const InputWraper = styled.div`
  margin-bottom: 19px;
`;

const FileInputWraper = styled.div`
  ${InputWraper};
  @media ${theme.breakpoints.mobile} {
    min-height: 125px;
    margin-bottom: 1rem;
  }
`;

const NotifWrapper = styled.div`
  margin-top: 3%;

  .bx--inline-notification__text-wrapper {
    display: block;
  }

  .bx--inline-notification__subtitle {
    white-space: pre-wrap;
  }

  @media ${theme.breakpoints.mobileAndTablet} {
    margin-top: 4%;
  }
`;

const Logo = styled.div<{ imageUrl: string }>`
  background-image: url(${props => props.imageUrl});
  background-repeat: no-repeat;
  background-size: contain;
  height: 23px;
  margin-bottom: 5%;
  @media ${theme.breakpoints.mobile} {
    height: 18px;
  }
`;

const StyledText = styled.p<{ isTitle?: boolean; isWelcome?: boolean }>`
  font-size: ${props =>
    props.isTitle ? '20' : props.isWelcome ? '28' : '13'}px;
  font-weight: ${props => (props.isTitle ? '500' : '400')};
  color: ${theme.colors.black};
  margin-bottom: ${props =>
    props.isTitle ? '30' : props.isWelcome ? '24' : '0'}px;

  @media ${theme.breakpoints.mobile} {
    font-size: ${props =>
      props.isTitle ? '13' : props.isWelcome ? '20' : '13'}px;
    font-weight: ${props => (props.isTitle || props.isWelcome ? '500' : '400')};
    margin-bottom: ${props =>
      props.isTitle ? '22' : props.isWelcome ? '14' : '0'}px;
  }
`;

const DescWrapper = styled.div`
  @media ${theme.breakpoints.mobile} {
    margin-bottom: 36px;
  }
`;

const CheckboxWrapper = styled.div`
  .bx--checkbox:focus + .bx--checkbox-label::before {
    outline: none;
  }
`;

export type Props = {
  name: string;
  companyName?: string;
  companyId: string;
  userId: string;
  email: string;
  password1?: string;
  password2?: string;
  photo?: File[];
  onChangePassword1: (event: ChangeEvent) => void;
  onChangepassword2: (event: ChangeEvent) => void;
  setFieldValue: (
    field: string,
    value: unknown,
    shouldValidate?: boolean | undefined,
  ) => void;
  group: string;
  role: string;
  emailNotif: boolean;
  onClickRegister: () => void;
  password1Error?: string;
  password2Error?: string;
  passwordRes?: string;
  emailAddressError?: string;
  emailAddressRes?: string;
  photoError?: string;
  onChangeEmail: (event: ChangeEvent) => void;
  signupError?: boolean;
  isLoading?: boolean;
  customFields?: {
    identifier: string;
    name: string;
    onChange: (event: ChangeEvent) => void;
    isRequired?: boolean;
    value?: string;
  }[];
  isPhotoRequired?: boolean;
  isEmailRequired?: boolean;
  notifErrorMessage?: string;
};

const Component = ({
  name,
  companyName = '',
  companyId,
  userId,
  email,
  group,
  role,
  emailNotif,
  password1,
  password2,
  photo,
  onChangePassword1,
  onChangepassword2,
  onClickRegister,
  password1Error,
  password2Error,
  passwordRes,
  emailAddressError,
  emailAddressRes,
  setFieldValue,
  photoError,
  onChangeEmail,
  signupError,
  isLoading,
  customFields = [],
  isPhotoRequired = false,
  isEmailRequired = false,
  notifErrorMessage = '',
}: Props): React.ReactElement => {
  const [showSignUPError, setShowSignUPError] = useState(false);

  const handleAttachFile = (file: File[], remove?: boolean): void =>
    setFieldValue('photo', remove ? [] : file);

  const handleEmailNotif = (isCheck: boolean): void =>
    setFieldValue('emailNotif', isCheck);

  useEffect(() => {
    if (signupError) {
      setShowSignUPError(true);
    }
  }, [signupError]);

  const handleClose = () => {
    setShowSignUPError(false);
    return false;
  };

  return (
    <Grid>
      <Row>
        <Column>
          <Logo imageUrl={LogoImage} />
        </Column>
      </Row>
      <Row>
        <Column>
          <StyledText isWelcome>{words.welcomeMsg(name)}</StyledText>
        </Column>
      </Row>
      <Row>
        <Column>
          <DescWrapper>
            {words
              .descMsg(companyName)
              .split('\n')
              .map(line => (
                <StyledText key={line}>{line}</StyledText>
              ))}
          </DescWrapper>
        </Column>
      </Row>
      <Spacer />
      <Row>
        <Column md={true} sm={4}>
          <TextInput
            id={'companyId'}
            label={words.companyId}
            value={companyId.padStart(3, '0')}
            disabled
          />
        </Column>
        <Column md={true} sm={4}>
          <TextInput
            id={'userId'}
            label={words.userId}
            value={userId.padStart(4, '0')}
            disabled
          />
        </Column>
      </Row>
      <Spacer />
      <Row>
        <Column>
          <TextInput
            id={'email'}
            autoComplete="off"
            label={`${words.representativeEmailAddress} ${
              isEmailRequired ? words.requiredLabel : words.optional
            }`}
            value={email}
            onChange={onChangeEmail}
            invalid={!!emailAddressError || !!emailAddressRes}
            invalidText={emailAddressError || emailAddressRes}
            disabled={isLoading}
          />
        </Column>
      </Row>
      <Spacer />
      <Row>
        <Column md={true} sm={4}>
          <InputWraper>
            <TextInput
              id={'password'}
              type={'password'}
              autoComplete="off"
              label={`${words.passwordLabel} ${words.requiredLabel}`}
              value={password1}
              onChange={onChangePassword1}
              showPasswordLabel={words.showPassword}
              hidePasswordLabel={words.hidePassword}
              invalid={!!password1Error || !!passwordRes}
              invalidText={password1Error || passwordRes}
              disabled={isLoading}
            />
          </InputWraper>
        </Column>
        <Column md={true} sm={4}>
          <InputWraper>
            <TextInput
              id={'password2'}
              type={'password'}
              autoComplete="off"
              label={`${words.confirmPassword} ${words.requiredLabel}`}
              value={password2}
              onChange={onChangepassword2}
              showPasswordLabel={words.showPassword}
              hidePasswordLabel={words.hidePassword}
              invalid={!!password2Error}
              invalidText={password2Error}
              disabled={isLoading}
            />
          </InputWraper>
        </Column>
      </Row>
      <Spacer />

      {customFields.map(({ identifier, name, value, onChange, isRequired }) => (
        <>
          <Row>
            <Column>
              <TextInput
                id={identifier}
                label={`${name} ${
                  isRequired ? words.requiredLabel : words.optional
                }`}
                value={value}
                onChange={onChange}
                disabled={isLoading}
              />
            </Column>
          </Row>
          <Spacer />
        </>
      ))}

      <Row>
        <Column>
          <FileInputWraper>
            <FileInput
              id={'photo'}
              photo={photo}
              label={words.profilePic}
              subLabel={isPhotoRequired ? words.requiredLabel : words.optional}
              description={words.fileInputLabel}
              onAttachFile={handleAttachFile}
              errorMessage={photoError}
              isLoading={isLoading}
              fileInputDesc={words.fileInputDesc}
              multiple
            />
          </FileInputWraper>
        </Column>
      </Row>
      <Spacer />
      <Row>
        <Column md={true} sm={4}>
          <TextInput
            id={'group'}
            label={words.groupLabel}
            value={group}
            disabled
          />
        </Column>
        <Column md={true} sm={4}>
          <TextInput
            id={'role'}
            label={words.roleLabel}
            value={role}
            disabled
          />
        </Column>
      </Row>
      <Spacer />
      <Row>
        <Column>
          <CheckboxWrapper>
            <Checkbox
              id={'emailNotif'}
              name={'emailNotif'}
              label={words.emailNotif}
              onChange={handleEmailNotif}
              isChecked={emailNotif}
            />
          </CheckboxWrapper>
        </Column>
      </Row>
      {showSignUPError && (
        <NotifWrapper>
          <Notification
            kind="error"
            title={words.error}
            subtitle={notifErrorMessage || words.somethingWentWrong}
            handleClose={handleClose}
            inline
          />
        </NotifWrapper>
      )}
      <ButtonWrapper>
        <Button
          title={words.register}
          onPress={onClickRegister}
          disabled={
            !!password1Error ||
            !!password2Error ||
            !!emailAddressError ||
            !!photoError ||
            (!!emailAddressRes && email.length > 1) ||
            !!!password1 ||
            !!!password2 ||
            !!customFields?.find(
              customField => customField.isRequired && !customField.value,
            ) ||
            !(
              (photo && isPhotoRequired && photo.length !== 0) ||
              !isPhotoRequired
            ) ||
            !((isEmailRequired && email.length > 1) || !isEmailRequired) ||
            isLoading ||
            !!notifErrorMessage
          }
        />
      </ButtonWrapper>
    </Grid>
  );
};

export default Component;
