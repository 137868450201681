import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useGlobalState } from '../../../hooks/global';
import { DataTableHeader, DenormalizedRow } from 'carbon-components-react';
import { Plus, Move, Trash } from '../../atoms/Icons';
import { Dropdown } from '../../molecules/Dropdown';
import { Search } from '../../molecules/Search';
import {
  DataTableContainer,
  DataTableWrapper,
  ToolBar,
  DropdownWrapper,
  StyledButton,
  ActionsContainer,
  EditText,
  DeleteText,
  ClearFilterText,
  SearchWrapper,
  StyledDatatableWithToolbar,
} from './elements';
import { useMemberHooks } from '../../../hooks/member';
import themes from '../../../config/themes';
import words from '../../../constants/words';
import data from '../../../constants/data';
import { DropdownSchema } from './types';
import { Group } from '../../../domain/entities/group';
import { Member } from '../../../domain/entities/member';
import { SelectedMemberType } from './types';
import { Tooltip } from '../../atoms/Tooltip';

const HEADERS: DataTableHeader[] = [
  {
    header: 'No',
    key: 'no',
  },
  {
    header: words.memberListHeaders.name,
    key: 'name',
  },
  {
    header: words.memberListHeaders.group,
    key: 'groupName',
  },
  {
    header: words.memberListHeaders.role,
    key: 'roleName',
  },
  {
    header: words.memberListHeaders.actions,
    key: 'actions',
  },
];

export type Props = {
  groupList: Group[];
  newlyAddedMember?: Member;
  newlyDeletedMember?: Member;
  isFetchingGroups: boolean;
  batchDeleteMembersFlag: boolean;
  moveMembersFlag?: number;
  currentEditorRole: string;
  onSelectRows: (selectedRows: SelectedMemberType[]) => void;
  onPressAddMember: () => void;
  onClickRow: (id: string) => void;
  onClickEdit: (id: number) => void;
  onClickDelete: (member: Member) => void;
  onMoveMembers: (selectedRows: DenormalizedRow[]) => void;
  onDeleteMembers: (selectedRows: DenormalizedRow[]) => void;
};

const Component = ({
  onSelectRows,
  onPressAddMember,
  onClickRow,
  onClickEdit,
  onClickDelete,
  currentEditorRole,
  groupList,
  newlyAddedMember,
  newlyDeletedMember,
  isFetchingGroups,
  onMoveMembers,
  onDeleteMembers,
  batchDeleteMembersFlag,
  moveMembersFlag,
}: Props): React.ReactElement => {
  const { useFetchMembers, useCheckUnassignedExists } = useMemberHooks();
  const { fetchMembers } = useFetchMembers();
  const {
    useCurrentUser: { currentUser },
  } = useGlobalState();

  const [queryParams, setQueryParams] = useState<{
    page: number;
    pageSize: number;
  }>({ page: 1, pageSize: 50 });

  const [filters, setFilters] = useState({
    groupFilterId: '',
    roleFilterId: '',
    name: '',
  });

  const [deleteTooltip, setDeleteTooltip] = useState<{
    memberId: undefined | number;
    message: string;
  }>({
    memberId: undefined,
    message: '',
  });

  const onChangeGroupFilter = (item: DropdownSchema) => {
    setFilters({
      ...filters,
      groupFilterId: item.selectedItem.id,
    });
    setQueryParams({
      ...queryParams,
      page: 1,
    });
  };

  const onChangeRoleFilter = (item: DropdownSchema) => {
    setFilters({
      ...filters,
      roleFilterId: item.selectedItem.id,
    });
    setQueryParams({
      ...queryParams,
      page: 1,
    });
  };

  const onChangeNameFilter = (item: string) => {
    setFilters({ ...filters, name: item.trimStart() });
    setQueryParams({
      ...queryParams,
      page: 1,
    });
  };

  const onChangePage = (data: { page: number; pageSize: number }) => {
    window.scrollTo(0, 0);
    setQueryParams(data);
  };

  const {
    data: { results: memberList, count: memberListCount } = {
      results: [],
      count: 0,
    },
    isFetching: isFetchingMembers,
  } = useQuery(
    [
      'member-list',
      queryParams,
      filters,
      newlyAddedMember,
      newlyDeletedMember,
      batchDeleteMembersFlag,
      moveMembersFlag,
    ],
    () => {
      if (!currentUser?.companyId) {
        throw new Error(`'company id' not set`);
      }

      const filterQuery: { [key: string]: number | string } = {};
      if (filters.groupFilterId !== '' && filters.groupFilterId !== 'all') {
        filterQuery.groupId = Number(filters.groupFilterId);
      }
      if (filters.roleFilterId !== '' && filters.roleFilterId !== 'all') {
        filterQuery.roleName = filters.roleFilterId;
      }
      if (filters.name !== '') {
        filterQuery.name = filters.name;
      }
      const query = {
        companyId: currentUser.companyId,
        page: queryParams?.page,
        page_size: queryParams?.pageSize,
        ...filterQuery,
      };

      return fetchMembers(query);
    },
    {
      refetchOnMount: true,
      cacheTime: moveMembersFlag !== 0 ? 0 : undefined,
    },
  );

  const { checkUnassignedExists } = useCheckUnassignedExists();

  const {
    data: hasUnassignedData,
    isFetching: isCheckingUnassignedExists,
  } = useQuery(
    [
      'member-list-check-unassigned',
      newlyAddedMember,
      newlyDeletedMember,
      batchDeleteMembersFlag,
      moveMembersFlag,
    ],
    () => {
      if (!currentUser?.companyId) {
        throw new Error(`'company id' not set`);
      }

      return checkUnassignedExists(currentUser.companyId);
    },
    {
      onSuccess: res => {
        if (!res.hasUnassigned && filters.groupFilterId === '0') {
          setFilters({
            ...filters,
            groupFilterId: 'all',
          });
        }
      },
      refetchOnMount: true,
    },
  );

  const groupItems = hasUnassignedData?.hasUnassigned
    ? [
        { id: 'all', label: words.dropdownAll },
        { id: '0', label: words.unassignedGroup },
      ].concat(
        groupList.map(group => ({
          id: group.id.toString(),
          label: group.name,
        })),
      )
    : [{ id: 'all', label: words.dropdownAll }].concat(
        groupList.map(group => ({
          id: group.id.toString(),
          label: group.name,
        })),
      );

  const roleItems = [{ id: 'all', label: words.dropdownAll }].concat(
    data.memberRoles.map(role => ({
      id: role.id,
      label: role.name,
    })),
  );

  const onClearFilter = () => {
    setFilters({
      groupFilterId: '',
      roleFilterId: '',
      name: '',
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const renderToolbarContent = (): React.ReactElement => {
    return (
      <ToolBar>
        <DropdownWrapper
          className={filters.groupFilterId === '' ? 'dropdown-filter-all' : ''}>
          <Dropdown
            styles={{ width: '100%' }}
            id={'group-filter'}
            label={words.groupFilter}
            items={groupItems}
            onChange={onChangeGroupFilter}
            dropdownProps={{
              selectedItem: groupItems.find(
                e => e.id === filters.groupFilterId,
              ) || { id: '', label: words.groupFilter },
            }}
            backgroundColor={themes.main.colors.field01}
          />
        </DropdownWrapper>
        <DropdownWrapper
          className={filters.roleFilterId === '' ? 'dropdown-filter-all' : ''}
          marginLeft="8px">
          <Dropdown
            styles={{ width: '100%' }}
            id={'role-filter'}
            label={words.roleFilter}
            items={roleItems}
            onChange={onChangeRoleFilter}
            dropdownProps={{
              selectedItem: roleItems.find(
                e => e.id === filters.roleFilterId,
              ) || { id: '', label: words.roleFilter },
            }}
            backgroundColor={themes.main.colors.field01}
          />
        </DropdownWrapper>
        <SearchWrapper>
          <Search
            size="lg"
            onChangeSearch={onChangeNameFilter}
            value={filters.name}
          />
        </SearchWrapper>
        <ClearFilterText onClick={onClearFilter}>
          {words.clearFilters}
        </ClearFilterText>
        <StyledButton
          onPress={onPressAddMember}
          theme="primary"
          title={words.addMember}
          icon={Plus}
        />
      </ToolBar>
    );
  };

  const toolbarActions = [
    {
      label: words.move,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      icon: Move,
      action: onMoveMembers,
    },
    {
      label: words.delete,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      icon: Trash,
      action: onDeleteMembers,
    },
  ];

  const parseSelectedRows = (rows: DenormalizedRow[]): void => {
    const parsedSelected = [] as SelectedMemberType[];

    rows.forEach(denormalized => {
      parsedSelected.push({
        id: Number(denormalized.id),
        name: denormalized.cells[1].value,
      });
    });

    onSelectRows(parsedSelected);
  };

  const checkMemberToDelete = (member: Member) => {
    if (member.roleName === 'approver' || member.roleName === 'admin') {
      const memberAsApproverGroups = groupList.filter(group =>
        group.approvers.includes(member.name),
      );
      const isOnlyApprover = memberAsApproverGroups.find(
        group => group.approvers.length === 1,
      );
      if (isOnlyApprover) {
        setDeleteTooltip({
          memberId: member.id,
          message: words.approverDeleteTooltipMessage,
        });
        return;
      }
      if (member.isLastAdmin) {
        setDeleteTooltip({
          memberId: member.id,
          message: words.adminDeleteTooltipMessage,
        });
        return;
      }
      onClickDelete(member);
    } else {
      onClickDelete(member);
    }
  };

  return (
    <DataTableContainer>
      <DataTableWrapper>
        <StyledDatatableWithToolbar
          rows={memberList.map((member, index) => {
            const roleName = data.memberRoles.find(
              e => e.id === member.roleName,
            ) || { name: '', id: '' };
            const isEditDeleteDisabled =
              currentEditorRole === 'approver' && roleName?.id === 'admin';

            return {
              ...member,
              groupName: member.group
                ? member.group.name
                : words.unassignedGroup,
              no: (queryParams.page - 1) * queryParams.pageSize + index + 1,
              roleName: roleName?.name,
              disabled: isEditDeleteDisabled,
              actions: (
                <ActionsContainer>
                  <EditText
                    onClick={() =>
                      !isEditDeleteDisabled && onClickEdit(member.id)
                    }
                    disabled={isEditDeleteDisabled}>
                    {words.edit}
                  </EditText>
                  <Tooltip
                    message={deleteTooltip.message}
                    isOpen={member.id === deleteTooltip.memberId}
                    onChange={(ev, { open }) => {
                      if (!open) {
                        setDeleteTooltip({ memberId: undefined, message: '' });
                      }
                    }}
                    triggerElement={
                      <DeleteText
                        onClick={ev => {
                          ev.stopPropagation();
                          !isEditDeleteDisabled && checkMemberToDelete(member);
                        }}
                        disabled={isEditDeleteDisabled}>
                        {words.delete}
                      </DeleteText>
                    }
                    align={'end'}
                    menuOffset={{ top: 5, left: 0 }}
                    autoOrientation></Tooltip>
                </ActionsContainer>
              ),
            };
          })}
          headers={HEADERS}
          isSelectable
          currentEditorRole={currentEditorRole}
          isLoading={
            isFetchingGroups || isFetchingMembers || isCheckingUnassignedExists
          }
          totalItems={memberListCount}
          onSelectRows={parseSelectedRows}
          onChangePage={onChangePage}
          onClickRow={onClickRow}
          unclickableColumns={['actions']}
          page={queryParams.page}
          pageSize={queryParams.pageSize}
          toolBarContent={renderToolbarContent()}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          toolBarActions={toolbarActions}
          toolBarSummaryLabels={{
            singular: words.memberSelectedSingular,
            plural: words.membersSelected,
          }}
          isEmptyResult={
            (filters.groupFilterId !== '' ||
              filters.name !== '' ||
              filters.roleFilterId !== '') &&
            memberListCount === 0
          }
        />
      </DataTableWrapper>
    </DataTableContainer>
  );
};

export default Component;
