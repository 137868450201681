import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useQuery } from 'react-query';
import { DataTableHeader } from 'carbon-components-react';
import data from '../../../constants/data';
import { theme } from '../../../config';
import { RecordReference } from '../../../domain/entities/record';
import { useGlobalState } from '../../../hooks/global';
import { useRecordHooks } from '../../../hooks/record';
import { useRankingHooks } from '../../../hooks/rankings';
import { Dropdown } from '../../molecules/Dropdown';
import { DataTable } from '../../organisms/DataTable';
import {
  DataTableContainer,
  StyledEmptyResultDisplay,
  DataTableWrapper,
  StatusText,
  ToolBar,
  DropdownWrapper,
  MobileListContainer,
  MobileItemWrapper,
  ItemNumber,
  ItemWrapper,
  DateStatusWrapper,
  DateLabel,
  StatusLabel,
  ItemName,
  ApproverName,
  CustomPagination,
} from './elements';
import { DropdownSchema, StatusTypes } from './types';
import words from '../../../constants/words';
import { useNavigate } from 'react-router';
import { Props as INotification } from '../../molecules/Notification/Notification';
import { useLocation } from 'react-router';
import { TIMEOUT } from '../../../constants/timeout';
import { ToastNotification } from '../../molecules/Notification';
import { clearReferenceFormPersistedData } from '../../pages/RecordReferenceDetails/RecordReferenceDetails';
import { AxiosError } from 'axios';
import { navigateToErrorPage } from '../../../utils/handleErrorPage';
import { TableLoader } from '../../atoms/Loading';

const HEADERS: DataTableHeader[] = [
  {
    header: words.recordReferenceListHeaders.no,
    key: 'no',
  },
  {
    header: words.recordReferenceListHeaders.date,
    key: 'date',
  },
  {
    header: words.recordReferenceListHeaders.name,
    key: 'name',
  },
  {
    header: words.recordReferenceListHeaders.mainApprover,
    key: 'mainApprover',
  },
  {
    header: words.recordReferenceListHeaders.status,
    key: 'status',
  },
];

export type Props = {};

const RecordReferenceListTemplate = ({}: Props): React.ReactElement => {
  const navigate = useNavigate();
  const {
    useCurrentUser: { currentUser },
  } = useGlobalState();

  const companyId = currentUser?.companyId;
  const memberId = currentUser?.memberId;

  const { useFetchRecordReferences } = useRecordHooks();
  const { useGetRankingList } = useRankingHooks();

  const { fetchRecordReferences } = useFetchRecordReferences();
  const { getRankingList } = useGetRankingList();

  const { state: locationState, pathname: locationPathname } = useLocation();
  const [notification, setNotification] = useState<INotification | null>(
    locationState?.toastMessage
      ? {
          ...locationState?.toastMessage,
          timeout: TIMEOUT,
          handleClose: () => {
            setNotification(null);
            return false;
          },
        }
      : null,
  );
  const [newlyDeletedEntry, setNewlyDeletedEntry] = useState<
    Response | undefined
  >();

  useEffect(() => {
    if (locationState?.updateData) {
      const { deletedEntry } = locationState;
      setNewlyDeletedEntry(deletedEntry);
    } else {
      setNewlyDeletedEntry(undefined);
    }
  });

  const [queryParams, setQueryParams] = useState<{
    page: number;
    pageSize: number;
  }>({ page: 1, pageSize: 10 });
  const [filters, setFilters] = useState({
    rankNameFilterId: '',
    statusFilterId: '',
  });

  const hasFiltersOn = Boolean(
    !!filters.rankNameFilterId || !!filters.statusFilterId,
  );

  const {
    data: { results: records, count: recordListCount } = {
      results: [],
      count: 0,
    },
    isFetching: isFetchingRecords,
  } = useQuery(
    [
      'fetchRecordReferences',
      queryParams.page,
      queryParams.pageSize,
      filters,
      newlyDeletedEntry,
    ],
    async () => {
      const rankingIdFilter =
        filters.rankNameFilterId == '' || filters.rankNameFilterId == 'all'
          ? undefined
          : Number(filters.rankNameFilterId);
      const statusFilter =
        filters.statusFilterId == '' || filters.statusFilterId == 'all'
          ? undefined
          : (filters.statusFilterId as StatusTypes);

      return fetchRecordReferences({
        page: queryParams.page,
        page_size: queryParams.pageSize,
        companyId: Number(companyId),
        memberId: Number(memberId),
        rankingId: rankingIdFilter,
        status: statusFilter,
      });
    },
    {
      onError: (err: AxiosError) => {
        navigateToErrorPage(navigate, err.response?.status, locationPathname);
      },
    },
  );

  const {
    data: { results: rankingNames } = {
      results: [],
      count: 0,
    },
    isFetching: isFetchingRankingNames,
  } = useQuery(
    'getRankingList',
    async () =>
      getRankingList({
        companyId: Number(companyId),
        isActive: true,
        appliedByMember: memberId,
      }),
    {
      onError: (err: AxiosError) => {
        navigateToErrorPage(navigate, err.response?.status, locationPathname);
      },
    },
  );

  const rankingFilterItems = [{ id: 'all', label: words.dropdownAll }].concat(
    rankingNames.map(ranking => ({
      id: ranking.id,
      label: ranking.name,
    })),
  );

  const statusFilterItems = [{ id: 'all', label: words.dropdownAll }].concat(
    data.approvalStatus.map(status => ({
      id: status.id,
      label: status.name,
    })),
  );

  const onChangeRankNameFilter = (item: DropdownSchema) => {
    setFilters({
      ...filters,
      rankNameFilterId: item.selectedItem.id,
    });
    setQueryParams({
      ...queryParams,
      page: 1,
    });
  };

  const onChangeStatusFilter = (item: DropdownSchema) => {
    setFilters({
      ...filters,
      statusFilterId: item.selectedItem.id,
    });
    setQueryParams({
      ...queryParams,
      page: 1,
    });
  };

  const onChangePage = (data: { page: number; pageSize: number }) => {
    window.scrollTo(0, 0);
    setQueryParams(data);
  };

  const onClickRow = (id: string | number) => {
    window.scrollTo(0, 0);
    const selectedEntry = records.find(record => record.entry.id === id);

    if (selectedEntry) {
      clearReferenceFormPersistedData();
      return navigate(
        `/reference/ranking/${selectedEntry.entry.ranking.id}/entry/${id}`,
      );
    }
  };

  const renderMobileItem = (
    count: number,
    date: string,
    name: string,
    approver: string,
    status: string,
    id: number,
  ) => {
    return (
      <MobileItemWrapper key={id} onClick={() => onClickRow(id)}>
        <ItemNumber>{count}</ItemNumber>
        <ItemWrapper>
          <DateStatusWrapper>
            <DateLabel>{date}</DateLabel>
            <StatusLabel className={`approval-status-${status}`}>
              {
                data.approvalStatus.find(appStatus => appStatus.id === status)
                  ?.name
              }
            </StatusLabel>
          </DateStatusWrapper>
          <ItemName>{name}</ItemName>
          <ApproverName>{approver}</ApproverName>
        </ItemWrapper>
      </MobileItemWrapper>
    );
  };

  return (
    <DataTableContainer
      className={
        recordListCount > 0 || isFetchingRecords
          ? ''
          : 'ranking-app-list-no-results'
      }>
      {recordListCount > 0 || isFetchingRecords || hasFiltersOn ? (
        <>
          <ToolBar>
            <DropdownWrapper
              marginTop="12px"
              className={
                filters.rankNameFilterId === '' ? 'dropdown-filter-all' : ''
              }>
              <Dropdown
                styles={{ width: '100%' }}
                id={'ranking-filter'}
                label={words.rankingListHeaders.name}
                items={rankingFilterItems}
                onChange={onChangeRankNameFilter}
                backgroundColor={theme.colors.white}
                dropdownProps={{
                  selectedItem: rankingFilterItems.find(
                    e => e.id === filters.rankNameFilterId,
                  ) || { id: '', label: words.rankingListHeaders.name },
                }}
              />
            </DropdownWrapper>
            <DropdownWrapper
              marginTop="8px"
              className={
                filters.statusFilterId === '' ? 'dropdown-filter-all' : ''
              }>
              <Dropdown
                styles={{ width: '100%' }}
                id={'status-filter'}
                label={words.recordReferenceListHeaders.status}
                items={statusFilterItems}
                onChange={onChangeStatusFilter}
                backgroundColor={theme.colors.white}
                dropdownProps={{
                  selectedItem: statusFilterItems.find(
                    e => e.id === filters.statusFilterId,
                  ) || {
                    id: '',
                    label: words.recordReferenceListHeaders.status,
                  },
                }}
              />
            </DropdownWrapper>
          </ToolBar>
          {recordListCount > 0 || isFetchingRecords ? (
            <>
              <DataTableWrapper>
                <DataTable
                  headers={HEADERS}
                  rows={records.map((row: RecordReference, index) => {
                    const mainApprover = row.approvers.find(
                      approver => approver.is_main,
                    );
                    return {
                      id: row.entry.id,
                      no:
                        (queryParams.page - 1) * queryParams.pageSize +
                        index +
                        1,
                      date: moment(row.entry.application_date).format(
                        'YYYY/M/D',
                      ),
                      name: row.entry.ranking.name,
                      mainApprover: mainApprover?.group_approver.member?.name,
                      status: (
                        <StatusText className={`approval-status-${row.status}`}>
                          {
                            data.approvalStatus.find(
                              status => status.id === row.status,
                            )?.name
                          }
                        </StatusText>
                      ),
                    };
                  })}
                  totalItems={recordListCount}
                  isLoading={isFetchingRecords || isFetchingRankingNames}
                  page={queryParams.page}
                  pageSize={queryParams.pageSize}
                  onChangePage={onChangePage}
                  onClickRow={onClickRow}
                />
              </DataTableWrapper>
              <MobileListContainer>
                {isFetchingRecords || isFetchingRankingNames ? (
                  <TableLoader vh={75} />
                ) : (
                  <>
                    {records.map((row: RecordReference, index) => {
                      const mainApprover = row.approvers.find(
                        approver => approver.is_main,
                      );
                      return renderMobileItem(
                        (queryParams.page - 1) * queryParams.pageSize +
                          index +
                          1,
                        moment(row.entry.application_date).format('YYYY/M/D'),
                        row.entry.ranking.name,
                        `${mainApprover?.group_approver.member?.name}`,
                        row.status,
                        row.entry.id,
                      );
                    })}
                    <CustomPagination
                      pageSizes={[10, 20, 30, 40, 50]}
                      page={queryParams.page}
                      pageSize={queryParams.pageSize}
                      totalItems={recordListCount}
                      onChange={onChangePage}
                      itemRangeText={(
                        min: number,
                        max: number,
                        total: number,
                      ) => words.pagination(min, max, total)}
                      itemsPerPageText={words.itemsPerPage}
                      pageRangeText={(_current: number, total: number) =>
                        `${words.pageNumber[0]} ${total} ${words.pageNumber[1]}`
                      }
                    />
                  </>
                )}
              </MobileListContainer>
            </>
          ) : (
            <StyledEmptyResultDisplay
              title={words.manageApplicationFilteredEmptyTitle}
              details={words.manageApplicationFilteredEmptyDetails}
              className="ranking-app-list-empty-table"
            />
          )}
        </>
      ) : (
        <StyledEmptyResultDisplay
          title={words.recordReferenceEmptyTitle}
          details={words.recordReferenceEmptyDetails}
        />
      )}
      {notification ? <ToastNotification notification={notification} /> : null}
    </DataTableContainer>
  );
};

export default RecordReferenceListTemplate;
