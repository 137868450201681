import styled from 'styled-components';
import { Search } from 'carbon-components-react';
import themes from '../../../config/themes';

export const SearchBar = styled(Search)`
  width: 100%;

  .bx--search-input {
    outline: none;
    border: thin solid ${themes.main.colors.inputBorderColor};
    border-radius: 8px;
    font-size: 13px;
    line-height: 18px;
    font-weight: 400;
    background-color: ${themes.main.colors.field01};

    &:focus {
      border: 2px solid ${themes.main.colors.primaryButton1};
    }
  }

  input.bx--search-input::placeholder {
    color: ${themes.main.colors.text03};
  }

  .bx--search-input:focus ~ .bx--search-close:hover {
    outline: none;
  }

  .bx--search-close {
    outline: none;

    &:hover {
      outline: none;
      border-radius: 0px 8px 8px 0px;
      background: transparent;
      border-color: transparent;
    }
  }

  .bx--search-close::before {
    outline: none;
    background-color: ${themes.main.colors.field01};
  }
`;
