import * as Yup from 'yup';
import words from '../../../constants/words';

export const AddEditMemberSchema = Yup.object().shape({
  memberName: Yup.string().required(`${words.required}`),
  memberGroupId: Yup.number()
    .min(1)
    .required(`${words.required}`),
  memberRoleId: Yup.string().required(`${words.required}`),
});
