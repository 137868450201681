import React, { useEffect, useState } from 'react';
import CloseButton from 'assets/icons/close-button.svg';
import './workingTimeRegisterModal.scss';
import { OrangeButton } from 'components/atoms/OrangeButton';
import DatePicker, { registerLocale } from 'react-datepicker';
import ja from 'date-fns/locale/ja';
import 'react-datepicker/dist/react-datepicker.css';
import { dateToString } from 'utils/common';
import { validateSphRegistration } from './validates';

type PropType = {
  isShow: boolean;
  dateCalendar: Date;
  onHideModal: () => void;
  eventCloseWorking: string;
  errorResponse: string;
  handleCreateNewPlanned: (
    sales_date: string,
    working_planned: string,
    working_actual: string,
    number_withdrawals: string,
    number_pre_probable: string,
    number_tosses: string,
  ) => void;
};

const defaultValue = {
  sales_date: '',
  time_expect: '',
  time_actual: '',
  number_of_product: '',
  number_withdrawals: '',
  number_pre_probable: '',
  number_tosses: '',
};

registerLocale('ja', ja);

export default function WorkingTimeRegisterModal(props: PropType) {
  const {
    isShow,
    dateCalendar,
    onHideModal,
    eventCloseWorking,
    handleCreateNewPlanned,
    errorResponse,
  } = props;
  const [date, setDate] = useState<string>(dateToString(new Date()));
  const [workingTime, setWorkingTime] = useState<string>('');
  const [workingTimeActual, setWorkingTimeActual] = useState<string>('');
  const [numberWithdrawals, setWithdrawals] = useState<string>('0');
  const [preProbable, setPreprobable] = useState<string>('0');
  const [tosses, setTosses] = useState<string>('');
  const [productPlanned, setProductPlanned] = useState<string>('');
  const [sphPlanned, setSphPlanned] = useState<string>('');
  const [errors, setErrors] = useState(defaultValue);

  async function handleCheckSphForm() {
    const listError = await validateSphRegistration({
      sales_date: date,
      time_expect: workingTime,
      time_actual: workingTimeActual,
      number_withdrawals: numberWithdrawals,
      number_pre_probable: preProbable,
      number_tosses: tosses,
      number_of_product: (
        parseFloat(numberWithdrawals) / parseFloat(preProbable)
      )
        .toFixed(4)
        .toString(),
    });
    if (Object.entries(listError).length !== 0) {
      setErrors(listError);
      return;
    }
    if (parseFloat(workingTime) <= 0.0) {
      setErrors(prevState => ({
        ...prevState,
        time_expect: 'TIME_EXPECTED_MUST_BE_POSITIVE_AND_GT_0',
      }));
      return;
    }
    if (parseFloat(workingTimeActual) <= 0.0) {
      setErrors(prevState => ({
        ...prevState,
        time_actual: 'TIME_ACTUAL_MUST_BE_POSITIVE_AND_GT_0',
      }));
      return;
    }
    if (parseFloat(tosses) <= 0.0) {
      setErrors(prevState => ({
        ...prevState,
        number_tosses: 'TOSSES_MUST_BE_POSITIVE_AND_GT_0',
      }));
      return;
    }
    if (parseFloat(numberWithdrawals) <= 0.0) {
      setErrors(prevState => ({
        ...prevState,
        number_withdrawals: 'NUMBER_WITHDRAWAL_MUST_BE_POSITIVE_AND_GT_0',
      }));
      return;
    }
    if (parseFloat(preProbable) <= 0.0) {
      setErrors(prevState => ({
        ...prevState,
        number_pre_probable: 'PRE_PROBABLE_MUST_BE_POSITIVE_AND_GT_0',
      }));
      return;
    }

    setErrors(defaultValue);
    handleCreateNewPlanned(
      date,
      workingTime,
      workingTimeActual,
      numberWithdrawals,
      preProbable,
      tosses,
    );
  }

  useEffect(() => {
    setSphPlanned(
      (parseFloat(productPlanned) / parseFloat(workingTime))
        .toFixed(4)
        .toString(),
    );
  }, [workingTime, productPlanned]);

  useEffect(() => {
    setWorkingTime('');
    setProductPlanned('');
    setWorkingTimeActual('');
    setSphPlanned('');
    setWithdrawals('1');
    setPreprobable('1');
    setTosses('1');
    setDate(dateToString(dateCalendar));
  }, [eventCloseWorking]);

  return (
    <div className={`working-time-register-pages ${isShow ? 'show' : 'hide'}`}>
      <div className="title-modal">
        <div className="modal-title">出勤予定登録</div>
        <div className="close-button" onClick={onHideModal}>
          <img src={CloseButton} />
        </div>
      </div>
      <div className="modal-invite-content">
        <div className="date-input">
          <label className={date.length > 0 ? 'filled' : 'not-filled'}>
            日付
          </label>
          <DatePicker
            onChange={(date: Date) => {
              setDate(dateToString(date));
            }}
            dateFormat="YYYY年MM月DD日"
            value={`${dateCalendar.getFullYear()}/${dateCalendar.getMonth() +
              1}/${dateCalendar.getDate()}`}
            locale="ja"
            className="w-100 form-control iop"
            disabled={true}
          />
          {errors && errors.sales_date && (
            <div className="errorMessage">{errors.sales_date}</div>
          )}
        </div>
        <div className="type-working-time">
          <div className="working-time-planned">
            <div className="labelText">
              <label
                className={workingTime.length > 0 ? 'filled' : 'not-filled'}>
                稼働時間 (計画)
              </label>
            </div>
            <div className="inputWorkingTime">
              <input
                className="w-100 form-control"
                placeholder="稼働時間 (計画)"
                onChange={e => setWorkingTime(e.target.value)}
                type="number"
                value={workingTime}
              />
            </div>
          </div>
          <div className="product-planned">
            <div className="labelText">
              <label
                className={
                  workingTimeActual.length > 0 ? 'filled' : 'not-filled'
                }>
                稼働時間 (結果)
              </label>
            </div>
            <div className="productPlanned">
              <input
                type="number"
                className="w-100 form-control"
                placeholder="稼働時間 (結果)"
                onChange={e => setWorkingTimeActual(e.target.value)}
                value={workingTimeActual}
              />
            </div>
          </div>
          {/* <div className="sph-planned">
            <div className="labelText">
              <label
                className={sphPlanned.length > 0 ? 'filled' : 'not-filled'}>
                SPH
              </label>
            </div>
            <div className="sphPlanned">
              <input
                type="text"
                className="w-100 form-control"
                placeholder="0"
                onChange={e => setSphPlanned(e.target.value)}
                readOnly={true}
                value={isNaN(parseFloat(sphPlanned)) ? 0 : sphPlanned}
              />
            </div>
          </div> */}
        </div>
        <div className="number_of_product">
          獲得件数（結果）：
          {parseFloat(numberWithdrawals) + parseFloat(preProbable)}
        </div>
        <div className="type-working-time">
          <div className="working-time-planned">
            <div className="labelText">
              <label
                className={
                  numberWithdrawals.length > 0 ? 'filled' : 'not-filled'
                }>
                取切件数 (結果)
              </label>
            </div>
            <div className="inputWorkingTime">
              <input
                className="w-100 form-control"
                placeholder="1"
                onChange={e => setWithdrawals(e.target.value)}
                type="number"
                value={numberWithdrawals}
              />
            </div>
          </div>
          <div className="product-planned">
            <div className="labelText">
              <label
                className={preProbable.length > 0 ? 'filled' : 'not-filled'}>
                前確件数（結果）
              </label>
            </div>
            <div className="productPlanned">
              <input
                type="number"
                className="w-100 form-control"
                placeholder="1"
                onChange={e => setPreprobable(e.target.value)}
                value={preProbable}
              />
            </div>
          </div>
        </div>
        <div className="type-working-time">
          <div className="working-time-planned">
            <div className="labelText">
              <label className={tosses.length > 0 ? 'filled' : 'not-filled'}>
                取切件数 (結果)
              </label>
            </div>
            <div className="inputWorkingTime">
              <input
                className="w-100 form-control"
                placeholder="1"
                onChange={e => setTosses(e.target.value)}
                type="number"
                value={tosses}
              />
            </div>
          </div>
        </div>
        {errors && errors.time_expect && (
          <div className="errorMessage">{errors.time_expect}</div>
        )}
        {errors && errors.time_actual && (
          <div className="errorMessage">{errors.time_actual}</div>
        )}
        {errors && errors.number_of_product && (
          <div className="errorMessage">{errors.number_of_product}</div>
        )}
        {errors && errors.number_pre_probable && (
          <div className="errorMessage">{errors.number_pre_probable}</div>
        )}
        {errors && errors.number_tosses && (
          <div className="errorMessage">{errors.number_tosses}</div>
        )}
        {errors && errors.number_withdrawals && (
          <div className="errorMessage">{errors.number_withdrawals}</div>
        )}
        {errorResponse && errorResponse.length && (
          <p
            className="errorFromServer"
            dangerouslySetInnerHTML={{ __html: errorResponse }}></p>
        )}
        <div className="btnBox">
          <OrangeButton
            text="登録"
            style={{ width: '100px', height: '50px', borderRadius: '5px' }}
            onClick={handleCheckSphForm}
          />
        </div>
      </div>
    </div>
  );
}
