import React from 'react';
import styled from 'styled-components';
import { theme } from '../../../config';
import words from '../../../constants/words';

export type Props = {
  onClickAccountConfig: () => void;
  onClickLogout: () => void;
};

const Container = styled.div`
  width: 100%;
  background: ${theme.colors.ui01};
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
`;

const ActionContainer = styled.div``;

const Separator = styled.div`
  height: 1px;
  width: calc(100% - 30px);
  margin-left: 15px;
  background: ${theme.colors.ui03};
`;

const Action = styled.div`
  background: ${theme.colors.ui01};
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 16px;
`;

const Label = styled.label`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.16px;
  color: ${theme.colors.text02};
  padding-top: 3px;
  cursor: pointer;
  &:hover {
    color: ${theme.colors.primaryButton1};
  }
`;

const Component = ({
  onClickAccountConfig,
  onClickLogout,
}: Props): React.ReactElement => {
  return (
    <Container>
      <ActionContainer onClick={onClickAccountConfig}>
        <Action>
          <Label>{words.accountConfigOption}</Label>
        </Action>
      </ActionContainer>
      <Separator />
      <ActionContainer onClick={onClickLogout}>
        <Action>
          <Label>{words.logoutOption}</Label>
        </Action>
      </ActionContainer>
    </Container>
  );
};

export default Component;
