import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import styled from 'styled-components';
import { theme } from '../../../config';
import words from '../../../constants/words';
import { useGlobalState } from '../../../hooks/global';
import { useNotificationHooks } from '../../../hooks/notification';
import { SubHeader } from '../../atoms/Subheader';
import { Notifications } from '../../templates/Notifications';
import { useNavigate, useLocation } from 'react-router';
import Pusher from 'pusher-js';
import { Notification } from '../../../domain/entities/notification';
import { NotificationItem } from '../../organisms/DataTable/NotificationTable';
import { AxiosError } from 'axios';
import { navigateToErrorPage } from '../../../utils/handleErrorPage';
import { PageLoader } from '../../atoms/Loading';

const ChildrenWrapper = styled.div<{ $isEmpty?: boolean }>`
  padding: 40px 70px 40px 70px;

  @media ${theme.breakpoints.mobile} {
    padding: 20px;
    width: 100vw;
    height: ${(props): string => (props.$isEmpty ? '100%' : 'auto')};
    padding-bottom: ${(props): string => (props.$isEmpty ? '70px' : '0')};
  }
`;

type Props = {};

const Component = ({}: Props): React.ReactElement => {
  const {
    useCurrentUser: { currentUser },
  } = useGlobalState();

  const { pathname: locationPathname } = useLocation();
  const navigate = useNavigate();

  const {
    useFetchNotifications,
    useUpdateNotification,
  } = useNotificationHooks();
  const { fetchNotifications } = useFetchNotifications();
  const { updateNotification } = useUpdateNotification();

  const [queryParams, setQueryParams] = useState<{
    page: number;
    pageSize: number;
  }>({ page: 1, pageSize: 20 });
  const [fetchProcess, setFetchProcess] = useState('');

  const {
    data: notificationData,
    isLoading: isLoadingNotifs,
    refetch: refetchNotification,
    isFetching: isFetchingNotifs,
    isRefetching: isRefetchingNotifs,
  } = useQuery(
    ['notification-data', queryParams],
    async () => {
      if (!currentUser?.companyId || !currentUser.userId) {
        throw new Error('Company id does not exists');
      }

      const notifications = await fetchNotifications({
        companyId: currentUser.companyId,
        page: queryParams?.page,
        page_size: queryParams?.pageSize,
        userId: currentUser.memberId,
      });

      return notifications;
    },
    {
      onError: (err: AxiosError) => {
        navigateToErrorPage(navigate, err.response?.status, locationPathname);
      },
    },
  );

  const { mutate: readNotification } = useMutation(
    (id: number) => {
      return updateNotification(id, { is_read: true });
    },
    {
      onSuccess: response => {
        const {
          ranking: rankingId,
          entry: entryId,
          member: memberId,
        } = (response as any).data;
        if (memberId === currentUser?.memberId) {
          navigate(`/reference/ranking/${rankingId}/entry/${entryId}`, {
            state: { previousPath: `/notifications` },
          });
        } else {
          navigate(
            `/approval/${entryId}/ranking/${rankingId}/member/${memberId}`,
            {
              state: { previousPath: `/notifications` },
            },
          );
        }
      },
    },
  );

  const mappedNotificationData = notificationData
    ? notificationData.results?.map((notification: Notification) => {
        return {
          id: notification.id.toString(),
          approvedStatus: notification.isApproved,
          rankingName: notification.rankingName,
          applicant: notification.applicantName,
          approver: notification.approverName,
          dateTime: new Date(notification.createdAt),
          readStatus: notification.isRead,
          rankingId: notification.rankingId,
          memberId: notification.memberId,
          entryId: notification.entryId,
          isApprover: notification.approverId === currentUser?.memberId,
        };
      })
    : [];

  const handleClickNotification = (id: number) => {
    readNotification(id);
  };

  useEffect(() => {
    if (!process.env.REACT_APP_PUSHER_APP_KEY) {
      throw new Error('Pusher not set');
    }

    const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
      cluster: process.env.REACT_APP_PUSHER_CLUSTER,
    });
    const channel = pusher.subscribe('notification-channel');
    channel.bind('add-notification', ({ data }: any) => {
      if (
        data.approver_id === currentUser?.memberId ||
        data.applicant_id === currentUser?.memberId
      ) {
        setFetchProcess('refetch');
        refetchNotification();
      }
      return data;
    });
  }, []);

  useEffect(() => {
    setFetchProcess('');
  }, [queryParams]);

  const showPageLoader = isFetchingNotifs && fetchProcess !== 'refetch';

  return (
    <>
      <SubHeader title={words.notificationList} />
      {showPageLoader ? (
        <PageLoader />
      ) : (
        <ChildrenWrapper $isEmpty={mappedNotificationData.length < 1}>
          <Notifications
            notifRows={mappedNotificationData as NotificationItem[]}
            isLoading={isLoadingNotifs}
            handleNotifClick={handleClickNotification}
            handlePageChange={setQueryParams}
            totalItems={notificationData?.count}
            page={queryParams.page}
            pageSize={queryParams.pageSize}
          />
        </ChildrenWrapper>
      )}
    </>
  );
};

export default Component;
