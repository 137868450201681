import React from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';

import words from '../../../constants/words';
import data from '../../../constants/data';

import { MemberField } from '../../../domain/entities/member';

import { useGlobalState } from '../../../hooks/global';
import { useMemberHooks } from '../../../hooks/member';
import { useLoginFieldHooks } from '../../../hooks/field';

import { TextInput } from '../../atoms/TextInput';
import { Modal } from '../../molecules/Modal';
import { theme } from '../../../config';

const StyledModal = styled(Modal)`
  .bx--modal-content.bx--modal-scroll-content > div {
    overflow-y: visible;
  }

  .bx--modal-header {
    margin-bottom: 28px;
  }

  @media ${theme.breakpoints.mobile} {
    .bx--modal-container {
      max-height: 80%;
    }

    .bx--modal-content.bx--modal-scroll-content > div {
      height: 100%;
      margin-bottom: 0;
    }
  }
`;

const StyledTextInput = styled(TextInput)`
  .bx--text-input:disabled {
    border: 1px solid ${theme.colors.inputBorderColor};
    background: ${theme.colors.white};
    color: ${theme.colors.text01};
    -webkit-text-fill-color: ${theme.colors.text01};
  }

  .bx--label--disabled {
    color: ${theme.colors.text02};
  }

  .text-input-copy-tooltip {
    overflow: hidden;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;

  @media ${theme.breakpoints.mobile} {
    gap: 0;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1em;

  @media ${theme.breakpoints.mobile} {
    flex-direction: column;
    gap: 0;
  }
`;

export type Props = {
  isOpen: boolean;
  onClose: () => void;
  memberId?: number;
};

const Component = ({
  isOpen,
  onClose,
  memberId,
}: Props): React.ReactElement => {
  const {
    useCurrentUser: { currentUser },
  } = useGlobalState();

  const companyId = currentUser?.companyId;

  const { useFetchMembers } = useMemberHooks();
  const { fetchMember } = useFetchMembers();
  const { useFetchLoginFields } = useLoginFieldHooks();
  const { fetchFields } = useFetchLoginFields();

  const { data: memberData } = useQuery({
    queryKey: ['fetchMember', companyId, memberId],
    queryFn: async () => {
      if (companyId && memberId) {
        return await fetchMember({ companyId, memberId: Number(memberId) });
      } else {
        throw new Error(`company id or member id not set`);
      }
    },
  });

  const { data: memberFieldsLookupData } = useQuery({
    queryKey: ['fetchFields', companyId],
    queryFn: async () => {
      if (companyId) {
        return await fetchFields({ companyId: Number(companyId) });
      } else {
        throw new Error(`company id' not set`);
      }
    },
  });

  const memberCustomFields = memberFieldsLookupData
    ?.filter(e => !e.is_default && e.visible)
    .map(field => {
      const memberField = memberData?.fields?.find(
        e => e.field_lookup_id === field.id,
      );

      const mappedMemberField: MemberField = {
        id: memberField?.id || 0,
        field_lookup_id: field.id,
        name: field.name,
        value_char: memberField?.value_char || '',
        member_id: memberData?.id || 0,
      };

      return mappedMemberField;
    });

  return (
    <StyledModal
      onConfirm={() => {}}
      open={isOpen}
      onClose={onClose}
      onCancel={onClose}
      confirmButtonText=""
      title={words.viewMemberModalHeader}
      label=""
      hideButtons
      hasScrollingContent
      enableScrollToTop>
      <Container>
        <Row>
          <StyledTextInput
            onCopyPress={() => {}}
            label={<pre>{words.viewMembeModalInputLabels.companyId}</pre>}
            disabled={true}
            id="company-id"
            value={
              memberData?.companyId
                ? String(memberData?.companyId).padStart(3, '0')
                : ''
            }
          />
          <StyledTextInput
            label={<pre>{words.viewMembeModalInputLabels.userId}</pre>}
            onCopyPress={() => {}}
            disabled={true}
            id="user-id"
            value={
              memberData?.username
                ? String(
                    memberData?.username
                      .substring(memberData.username.indexOf('A'))
                      .substring(1),
                  ).padStart(4, '0')
                : ''
            }
          />
        </Row>
        <Row>
          <StyledTextInput
            label={<pre>{words.viewMembeModalInputLabels.group}</pre>}
            value={memberData?.group?.name || words.unassignedGroup}
            disabled={true}
            id="group"
          />
        </Row>
        <Row>
          <StyledTextInput
            label={<pre>{words.viewMembeModalInputLabels.role}</pre>}
            value={
              data.memberRoles.find(e => e.id === memberData?.roleName)?.name ||
              ''
            }
            disabled={true}
            id="role"
          />
        </Row>
        <Row>
          <StyledTextInput
            label={<pre>{words.viewMembeModalInputLabels.name}</pre>}
            value={memberData?.name || ''}
            disabled={true}
            id="name"
          />
        </Row>
        <Row>
          <StyledTextInput
            label={<pre>{words.viewMembeModalInputLabels.emailAddress}</pre>}
            onCopyPress={() => {}}
            value={memberData?.email || ''}
            disabled={true}
            id="emailAddress"
          />
        </Row>
        {memberCustomFields?.map((customField, index) => (
          <Row key={index}>
            <StyledTextInput
              label={<pre>{customField.name}</pre>}
              value={customField.value_char || ''}
              disabled={true}
              id="remark"
            />
          </Row>
        ))}
      </Container>
    </StyledModal>
  );
};

export default Component;
