import React from 'react';
import './signUpConfirm.scss';
import LogoSelfPro from 'assets/icons/self-pro-logo.svg';
import { SignUpConfirmContent } from 'components/templates/SignUpConfirmContent';

export default function SignUpConfirm() {
  return (
    <div className="signup-confirm-pages">
      <div className="self-pro-logo">
        <img src={LogoSelfPro} />
      </div>
      <SignUpConfirmContent />
    </div>
  );
}
