import React, { useEffect, useState } from 'react';
import { ProgressBar } from 'react-bootstrap';
import { sphForMonthPerson } from 'type/sph';
import { dateToStringOnlyMonth } from 'utils/common';
import './personalMonthTemplates.scss';

type PropType = {
  dateCalendar: Date;
  sphForMonthData: sphForMonthPerson;
};

export default function PersonalMonthTemplates(props: PropType) {
  const { dateCalendar, sphForMonthData } = props;
  return (
    <div className="personalMonthTemplates">
      <div className="option-bar">
        <div className="day-title">
          {dateToStringOnlyMonth(dateCalendar)}{' '}
          <span className="title">※計画/結果</span>
        </div>
        <div className="progress-bar">
          <ProgressBar
            animated
            now={
              parseInt(sphForMonthData.sum_process_percent) > 100
                ? 100
                : parseInt(sphForMonthData.sum_process_percent)
            }
            label={`${
              parseInt(sphForMonthData.sum_process_percent) > 100
                ? 100
                : parseInt(sphForMonthData.sum_process_percent)
            }%`}
            variant="success"
            className="progress"
          />
        </div>
      </div>
      <div className="data-bar">
        <div className="working-time">
          稼働時間（計画/結果）：{' '}
          <span className="value-data">
            {sphForMonthData.sum_time_expect} /{' '}
            {sphForMonthData.sum_time_actual}
          </span>
        </div>
        <div className="number-of-product">
          獲得件数（結果）：
          <span className="value-data">
            {sphForMonthData.plan_month_number_of_order} /{' '}
            {sphForMonthData.sum_product_actual}
          </span>{' '}
          （取切：
          <span className="value-data">
            {sphForMonthData.sum_number_of_withdrawals}
          </span>{' '}
          ＆ 前確：
          <span className="value-data">
            {sphForMonthData.sum_pre_probable_number}
          </span>
          )
        </div>
        <div className="sph-result">
          SPH（結果）：
          <span className="value-data">
            {(
              parseFloat(sphForMonthData.plan_month_number_of_order) /
              parseFloat(sphForMonthData.sum_time_expect)
            ).toFixed(4)}{' '}
            / {parseFloat(sphForMonthData.sum_sph_actual).toFixed(4)}
          </span>
        </div>
        <div className="number-of-product-result">
          トス件数：
          <span className="value-data">
            {(
              parseFloat(sphForMonthData.sum_product_actual) /
              parseFloat(sphForMonthData.toss_rate_month)
            ).toFixed(4)}{' '}
            / {sphForMonthData.sum_number_of_tosses}
          </span>
        </div>
        <div className="sph-tosses-result">
          トスSPH：
          <span className="value-data">
            {(
              parseFloat(sphForMonthData.sum_product_actual) /
              parseFloat(sphForMonthData.toss_rate_month) /
              parseFloat(sphForMonthData.sum_time_expect)
            ).toFixed(4)}{' '}
            /{' '}
            {parseFloat(sphForMonthData.sum_number_of_tosses) /
              parseFloat(sphForMonthData.sum_number_of_tosses)}
          </span>
        </div>
        <div className="tosses-rate">
          トス通過率：
          <span className="value-data">
            {sphForMonthData.plan_month_number_of_order} /{' '}
            {(
              parseFloat(sphForMonthData.sum_product_actual) /
              parseFloat(sphForMonthData.sum_number_of_tosses)
            ).toFixed(4)}
            （%）
          </span>
        </div>
      </div>
    </div>
  );
}
