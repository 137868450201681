import {
  LoginField,
  UpdateLoginFieldProps,
} from '../../../domain/entities/field';
import LoginFieldRepository from '../../ports/LoginFieldsRepository';

export type updateLoginFieldUseCase = (
  payload: {
    companyId: number;
    fieldId: number;
  },
  data: UpdateLoginFieldProps,
) => Promise<LoginField>;

export const buildUpdateLoginField = (dependencies: {
  loginFieldRepo: LoginFieldRepository;
}): updateLoginFieldUseCase => {
  const { loginFieldRepo } = dependencies;

  const updateLoginField: updateLoginFieldUseCase = async (payload, data) =>
    loginFieldRepo.update(payload, data);

  return updateLoginField;
};
