import MemberRepository from '../../ports/MemberRepository';

export type filterDeletableMembersUseCase = (
  companyId: number,
  memberIds: string,
) => Promise<string[]>;

export const buildFilterDeletableMembers = (dependencies: {
  memberRepo: MemberRepository;
}): filterDeletableMembersUseCase => {
  const { memberRepo } = dependencies;

  const filterDeletableMembers: filterDeletableMembersUseCase = async (
    companyId,
    memberIds,
  ) => memberRepo.filterDeletableMembers(companyId, memberIds);

  return filterDeletableMembers;
};
