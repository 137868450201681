import React from 'react';
import {
  DataTable,
  DataTableSkeletonHeader,
  Table,
  TableHead,
  TableRow,
  TableBody,
  DataTableRow,
  DataTableHeader,
  DataTableCustomRenderProps,
  DenormalizedRow,
  TableToolbar,
  TableBatchAction,
  TableBatchActionsTranslationKey,
} from 'carbon-components-react';
import {
  CustomTableHeader,
  CustomTableSelectAll,
  CustomTableRow,
  CustomTableSelectRow,
  CustomTableCell,
  CustomPagination,
  Container,
  CustomTableToolbarContent,
  CustomBatchActions,
  CustomCheckbox,
  CustomDataTableSkeletonContainer,
  CustomDataTableSkeleton,
  EmptyColumn,
} from './elements';
import words from '../../../constants/words';
import { EmptyResultDisplay } from '../../organisms/EmptyResultDisplay';
import { TableLoader } from '../../atoms/Loading';

export type PropsWithToolbar = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rows: DataTableRow<any>[];
  headers: DataTableHeader<string>[];
  isSelectable?: boolean;
  isLoading?: boolean;
  page?: number;
  pageSize?: number;
  totalItems?: number;
  className?: string;
  unclickableColumns?: string[];
  toolBarContent: React.ReactElement;
  toolBarActions: {
    label: string;
    icon: React.ReactElement;
    action: (selectedRows: DenormalizedRow[]) => void;
  }[];
  toolBarSummaryLabels: {
    singular: string;
    plural: string;
  };
  onSelectRows?: (selectedRows: DenormalizedRow[]) => void;
  onChangePage: (data: { page: number; pageSize: number }) => void;
  onClickRow?: (id: string) => void;
  paginationBackwardText?: string;
  paginationForwardText?: string;
  isEmptyResult: boolean;
};

const Component = ({
  rows,
  headers,
  isSelectable,
  isLoading,
  onSelectRows,
  page,
  pageSize,
  totalItems,
  onChangePage,
  className,
  onClickRow,
  unclickableColumns,
  toolBarContent,
  toolBarActions,
  toolBarSummaryLabels,
  paginationBackwardText,
  paginationForwardText,
  isEmptyResult,
}: PropsWithToolbar): React.ReactElement => {
  const translationKeys: { [key: string]: string } = {
    'carbon.table.batch.cancel': words.cancel,
    'carbon.table.batch.items.selected': toolBarSummaryLabels.plural,
    'carbon.table.batch.item.selected': toolBarSummaryLabels.singular,
  };
  const translate = (
    id: TableBatchActionsTranslationKey,
    state: { totalSelected: number },
  ) => {
    if (id === 'carbon.table.batch.cancel') {
      return translationKeys[id];
    }

    return `${state.totalSelected}${translationKeys[id]}`;
  };

  return (
    <Container className={className}>
      <DataTable rows={rows} headers={headers}>
        {({
          rows,
          headers,
          getTableProps,
          getHeaderProps,
          getRowProps,
          getSelectionProps,
          selectedRows,
          getBatchActionProps,
        }: DataTableCustomRenderProps) => {
          if (onSelectRows) {
            onSelectRows(selectedRows as DenormalizedRow[]);
          }
          const batchActionProps = getBatchActionProps();
          const selectionProps = getSelectionProps();

          return (
            <>
              <TableToolbar>
                <CustomTableToolbarContent>
                  {toolBarContent}
                </CustomTableToolbarContent>
                <CustomBatchActions
                  {...getBatchActionProps()}
                  translateWithId={translate}>
                  {toolBarActions.map((row, index) => (
                    <TableBatchAction
                      key={index}
                      tabIndex={
                        batchActionProps.shouldShowBatchActions ? 0 : -1
                      }
                      renderIcon={row.icon}
                      onClick={() =>
                        row.action(selectedRows as DenormalizedRow[])
                      }>
                      {row.label}
                    </TableBatchAction>
                  ))}
                </CustomBatchActions>
              </TableToolbar>
              {isLoading ? (
                <CustomDataTableSkeletonContainer>
                  {isSelectable && (
                    <CustomCheckbox label="" onChange={() => {}} />
                  )}
                  <CustomDataTableSkeleton
                    showToolbar={false}
                    showHeader={false}
                    headers={headers as DataTableSkeletonHeader[]}
                    columnCount={headers.length}
                    className={className}
                    $isSelectable={isSelectable}
                    rowCount={0}
                  />
                  <TableLoader />
                </CustomDataTableSkeletonContainer>
              ) : isEmptyResult ? (
                <EmptyResultDisplay
                  title={words.emptyMemberListTitle}
                  details={words.emptyMemberListDetails}
                />
              ) : (
                <Table {...getTableProps()}>
                  <TableHead>
                    <TableRow>
                      {isSelectable && (
                        <CustomTableSelectAll {...selectionProps} />
                      )}
                      {headers.map((header: DataTableHeader<string>) => (
                        <CustomTableHeader
                          {...getHeaderProps({ header })}
                          key={header.key}>
                          {header.header}
                        </CustomTableHeader>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map(row => {
                      const rowProps = getRowProps({ row });
                      const selectionProps = getSelectionProps({ row });

                      return (
                        // eslint-disable-next-line react/jsx-key
                        <CustomTableRow {...rowProps} key={row.id}>
                          {isSelectable && !rowProps.disabled ? (
                            <CustomTableSelectRow
                              {...selectionProps}
                              isSelected={selectedRows.some(
                                item => item.id === row.id,
                              )}
                            />
                          ) : (
                            <EmptyColumn />
                          )}
                          {row.cells.map(cell => (
                            <CustomTableCell
                              key={cell.id}
                              onClick={() =>
                                onClickRow &&
                                !unclickableColumns?.includes(
                                  cell.info.header,
                                ) &&
                                !rowProps.disabled &&
                                onClickRow(row.id)
                              }
                              isSelected={selectedRows.some(
                                item => item.id === row.id,
                              )}>
                              {cell.value}
                            </CustomTableCell>
                          ))}
                        </CustomTableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              )}
            </>
          );
        }}
      </DataTable>
      {!isLoading && !isEmptyResult && (
        <CustomPagination
          pageSizes={[50, 100, 200]}
          page={page}
          pageSize={pageSize}
          totalItems={totalItems}
          onChange={onChangePage}
          itemRangeText={(min: number, max: number, total: number) =>
            words.pagination(min, max, total)
          }
          itemsPerPageText={words.itemsPerPage}
          pageRangeText={(_current: number, total: number) =>
            `${words.pageNumber[0]} ${total} ${words.pageNumber[1]}`
          }
          backwardText={paginationBackwardText}
          forwardText={paginationForwardText}
        />
      )}
    </Container>
  );
};

Component.defaultProps = {
  isSelectable: false,
  isLoading: false,
  page: 1,
  pageSize: 10,
  totalItems: 10,
  unclickableColumns: [],
};

export default Component;
