// TODO implement logic to change when in staging
const AUTH_API_URL = process.env.REACT_APP_API_URL;
const API_URL = `${AUTH_API_URL}/contest`;
const APP_URL = process.env.REACT_APP_URL;

export default {
  todo: { getTodos: `https://gorest.co.in/public/v1/todos` },
  auth: {
    login: `${AUTH_API_URL}/token/obtain/`,
    register: `${AUTH_API_URL}/register/`,
    adminSignUp: `${API_URL}/admin-signup/`,
    adminFirstLogin: `${API_URL}/admin-firstlogin/`,
    verifyEmail: `${API_URL}/verify-email/`,
    verifyCompanyEmail: `${API_URL}/verify-company-email/`,
    adminVerification: (pk: string): string =>
      `${API_URL}/admin-verification/${pk}/`,
    forgetPassword: `${API_URL}/reset-password/`,
    resetPassword: (pk: string, token: string): string =>
      `${API_URL}/reset-password/${pk}/${token}/`,
    changePassword: `${API_URL}/change-password/`,
  },
  group: {
    getGroups: ({ companyId }: { [key: string]: string }): string =>
      `${API_URL}/company/${companyId}/groups/`,
    updateGroup: ({ companyId, groupId }: { [key: string]: string }): string =>
      `${API_URL}/company/${companyId}/groups/${groupId}/`,
    deleteGroup: ({ companyId, groupId }: { [key: string]: string }): string =>
      `${API_URL}/company/${companyId}/groups/${groupId}/`,
    addGroup: ({ companyId }: { [key: string]: string }): string =>
      `${API_URL}/company/${companyId}/groups/`,
    getApprovers: ({ companyId }: { [key: string]: string }): string =>
      `${API_URL}/company/${companyId}/members/?roleName=approver,admin`,
    checkDuplicateGroupName: ({
      companyId,
      groupName,
    }: {
      [key: string]: string;
    }): string => `${API_URL}/company/${companyId}/groups/?name=${groupName}`,
  },
  rankings: {
    getRankingList: ({
      companyId,
    }: {
      [key: string]: string | number;
    }): string => `${API_URL}/company/${companyId}/contests/`,
    getIndividualRanking: ({
      companyId,
      rankingId,
    }: {
      [key: string]: string | number;
    }): string => `${API_URL}/company/${companyId}/contests/${rankingId}/`,
    createRanking: ({
      companyId,
    }: {
      [key: string]: string | number;
    }): string => `${API_URL}/company/${companyId}/contests/`,
    updateRanking: ({
      companyId,
      rankingId,
    }: {
      [key: string]: string | number;
    }): string => `${API_URL}/company/${companyId}/contests/${rankingId}/`,
    deleteRanking: ({
      companyId,
      rankingId,
    }: {
      [key: string]: string | number;
    }): string => `${API_URL}/company/${companyId}/contests/${rankingId}/`,
    checkRankingNameExists: ({
      companyId,
      rankingName,
    }: {
      [key: string]: string | number;
    }): string =>
      `${API_URL}/company/${companyId}/contests/?rankingName=${rankingName}`,
    getRankingAppList: ({
      companyId,
      memberId,
    }: {
      [key: string]: string | number;
    }): string =>
      `${API_URL}/company/${companyId}/members/${memberId}/rankings/`,
    fetchRankingLogs: ({
      companyId,
      rankingId,
    }: {
      [key: string]: string | number;
    }): string => `${API_URL}/company/${companyId}/contests/${rankingId}/logs/`,
    fetchRankingPeriod: ({
      companyId,
      rankingId,
    }: {
      [key: string]: string | number;
    }): string =>
      `${API_URL}/company/${companyId}/contests/${rankingId}/period/`,
    fetchRankingResult: ({
      companyId,
      rankingId,
    }: {
      [key: string]: string | number;
    }): string =>
      `${API_URL}/company/${companyId}/contests/${rankingId}/result/`,
    fetchRankingFieldsLookup: ({
      companyId,
      rankingId,
    }: {
      [key: string]: string | number;
    }): string =>
      `${API_URL}/company/${companyId}/contests/${rankingId}/ranking-fields-lookup/`,
  },
  loginField: {
    createField: ({ companyId }: { [key: string]: string }): string =>
      `${API_URL}/company/${companyId}/member-fields-lookup/`,
    updateField: ({ companyId, fieldId }: { [key: string]: string }): string =>
      `${API_URL}/company/${companyId}/member-fields-lookup/${fieldId}/`,
    getFields: ({ companyId }: { [key: string]: string }): string =>
      `${API_URL}/company/${companyId}/member-fields-lookup/`,
    deleteField: ({ companyId, fieldId }: { [key: string]: string }): string =>
      `${API_URL}/company/${companyId}/member-fields-lookup/${fieldId}/`,
    checkDuplicateFieldName: ({
      companyId,
      fieldName,
    }: {
      [key: string]: string;
    }): string =>
      `${API_URL}/company/${companyId}/member-fields-lookup/?name=${fieldName}`,
  },
  member: {
    getMembers: ({ companyId }: { [key: string]: string | number }): string =>
      `${API_URL}/company/${companyId}/members/`,
    createMember: ({ companyId }: { [key: string]: string | number }): string =>
      `${API_URL}/company/${companyId}/members/`,
    memberDetails: ({
      companyId,
      memberId,
    }: {
      [key: string]: string | number;
    }): string => `${API_URL}/company/${companyId}/members/${memberId}/`,
    generateTemporaryPassword: ({
      companyId,
      memberId,
    }: {
      [key: string]: string | number;
    }): string =>
      `${API_URL}/company/${companyId}/members/${memberId}/generate_temporary_password/`,
    updateMembersGroup: ({
      companyId,
    }: {
      [key: string]: string | number;
    }): string => `${API_URL}/company/${companyId}/members/move-members/`,
    filterDeletableMembers: ({
      companyId,
      memberIds,
    }: {
      [key: string]: string | number;
    }): string =>
      `${API_URL}/company/${companyId}/members/filter-deletable-members/?memberIds=${memberIds}`,
    deleteMembers: ({
      companyId,
      memberIds,
    }: {
      [key: string]: string | number;
    }): string =>
      `${API_URL}/company/${companyId}/members/delete-members/?memberIds=${memberIds}`,
    validateMemberRoleChangeable: ({
      companyId,
      memberId,
    }: {
      [key: string]: string | number;
    }): string =>
      `${API_URL}/company/${companyId}/members/${memberId}/validate-member-role-changeable/`,
    checkIfUnassignedExists: ({
      companyId,
    }: {
      [key: string]: string | number;
    }): string => `${API_URL}/company/${companyId}/members/check-unassigned/`,
    createMemberFields: ({
      companyId,
    }: {
      [key: string]: string | number;
    }): string => `${API_URL}/company/${companyId}/member-fields/create/`,
    updateMemberFields: ({
      companyId,
    }: {
      [key: string]: string | number;
    }): string => `${API_URL}/company/${companyId}/member-fields/update/`,
  },
  company: {
    findCompany: ({ companyId }: { [key: string]: string }): string =>
      `${API_URL}/company/${companyId}/`,
    updateCompany: ({ companyId }: { [key: string]: string }): string =>
      `${API_URL}/company/${companyId}/`,
    verifyUpdatedCompanyEmail: ({
      companyId,
      token,
    }: {
      [key: string]: string;
    }): string =>
      `${API_URL}/verify-updated-company-email/${companyId}/${token}/`,
    resendCompanyEmailVerification: ({
      companyId,
    }: {
      [key: string]: string;
    }): string => `${API_URL}/resend-company-email-verification/${companyId}/`,
  },
  entry: {
    formFieldsApprovers: ({
      companyId,
      memberId,
      rankingId,
    }: {
      [key: string]: string | number;
    }): string =>
      `${API_URL}/company/${companyId}/members/${memberId}/rankings/${rankingId}/entries/form_fields_approvers/`,
    entryApplication: ({
      companyId,
      memberId,
      rankingId,
    }: {
      [key: string]: string | number;
    }): string =>
      `${API_URL}/company/${companyId}/members/${memberId}/rankings/${rankingId}/entries/`,
    getRecordReferences: ({
      companyId,
      memberId,
    }: {
      [key: string]: string | number;
    }): string =>
      `${API_URL}/company/${companyId}/members/${memberId}/entries/`,
    getRecordReferenceDetails: ({
      companyId,
      memberId,
      rankingId,
      entryId,
    }: {
      [key: string]: string | number;
    }): string =>
      `${API_URL}/company/${companyId}/members/${memberId}/rankings/${rankingId}/entries/${entryId}/`,
    getRecordApprovals: ({
      companyId,
      memberId,
    }: {
      [key: string]: string | number;
    }): string =>
      `${API_URL}/company/${companyId}/members/${memberId}/record-approvals/`,
    getRecordApprovalsRankingNames: ({
      companyId,
      memberId,
    }: {
      [key: string]: string | number | boolean;
    }): string =>
      `${API_URL}/company/${companyId}/members/${memberId}/record-approvals-ranking-names/`,
  },
  notification: {
    getNotifications: ({
      companyId,
    }: {
      [key: string]: string | number | boolean;
    }): string => `${API_URL}/company/${companyId}/notifications/`,
    addNotification: ({
      companyId,
    }: {
      [key: string]: string | number | boolean;
    }): string => `${API_URL}/company/${companyId}/notifications/`,
    updateNotification: ({
      companyId,
      notificationId,
    }: {
      [key: string]: string | number | boolean;
    }): string =>
      `${API_URL}/company/${companyId}/notifications/${notificationId}/`,
    checkNotificationCount: ({
      companyId,
    }: {
      [key: string]: string | number | boolean;
    }): string =>
      `${API_URL}/company/${companyId}/notifications/notification-count/`,
  },
  // NEW
  signIn: `${APP_URL}/sign-in`,
  signUp: `${APP_URL}/sign-up`,
  forgot: `${APP_URL}/forgot`,
  profile: `${APP_URL}/member-profile`,
  account_setting_front: `${APP_URL}/account-setting`,
  // API
  member_sign_up: `${AUTH_API_URL}/member-sign-up/`,
  member_sign_in: `${AUTH_API_URL}/member-sign-in/`,
  sns_member_sign_up: `${AUTH_API_URL}/sns-member-sign-up/`,
  sns_member_sign_in: `${AUTH_API_URL}/sns-member-sign-in/`,
  member_forgot: `${AUTH_API_URL}/forgot-password/`,
  handle_member_forgot: `${AUTH_API_URL}/handle-reset-password/`,
  member_info: `${AUTH_API_URL}/member-info/`,
  member_update_info: `${AUTH_API_URL}/update-member-info/`,
  handle_confirm_update_info: `${AUTH_API_URL}/confirmed-info-change/`,
  handle_update_avatar: `${AUTH_API_URL}/update-member-avatar/`,
  get_group: `${AUTH_API_URL}/groups/`,
  get_group_approver: `${AUTH_API_URL}/group-approver/`,
  get_member_in_group: `${AUTH_API_URL}/group-member/`,
  get_member_info: `${AUTH_API_URL}/group-member-info/`,
  handle_update_change: `${AUTH_API_URL}/update-member/`,
  approve_member: `${AUTH_API_URL}/approve-member/`,
  reject_member: `${AUTH_API_URL}/reject-member/`,
  remove_member: `${AUTH_API_URL}/remove-group-member/`,
  move_member_group: `${AUTH_API_URL}/move-member-group/`,
  invite_member_group: `${AUTH_API_URL}/invite-list-email/`,
  update_member_group_avatar: `${AUTH_API_URL}/approver-update-member-avatar/`,
  handle_email_invite: `${AUTH_API_URL}/handle-invite-list-email/`,
  group_approver_details: `${AUTH_API_URL}/get-group-approver/`,
  create_new_group: `${AUTH_API_URL}/create-new-group/`,
  delete_group: `${AUTH_API_URL}/delete-group/`,
  update_group: `${AUTH_API_URL}/update-group-approver/`,
  get_all_member_company: `${AUTH_API_URL}/get-all-member/`,
  check_approver: `${AUTH_API_URL}/check-approver/`,
  input_entry: `${AUTH_API_URL}/entry-input/`,
  edit_input_entry: `${AUTH_API_URL}/edit-entry-input/`,
  group_entry: `${AUTH_API_URL}/group-ranking-list/`,
  update_result_entry: `${AUTH_API_URL}/update-entry-result/`,
  cancel_entry: `${AUTH_API_URL}/cancel-entry/`,
  get_personal_entry_day: `${AUTH_API_URL}/rankings/?start_date=`,
  get_personal_entry_month: `${AUTH_API_URL}/rankings/?start_date=`,
  get_group_entry_month: `${AUTH_API_URL}/approve-entry-list/?start_date=`,
  group_data_register_by_approver: `${AUTH_API_URL}/approve-entry-list/`,
  get_company_entry_day: `${AUTH_API_URL}/company-ranking-list/`,
  get_company_entry_month: `${AUTH_API_URL}/company-ranking-list/?start_date=`,
  change_pass: `${AUTH_API_URL}/reset-password/`,
  sph_get: `${AUTH_API_URL}/sph/`,
  // NAVIGATE
  home: '/home',
  sign_in_page: '/sign-in',
  sign_up_page: '/sign-up',
  member_profile: '/member-profile',
  account_setting: '/account-setting',
  forgot_input: '/forgot-input',
  forgot_page: '/forgot',
  member_list: '/member-list-settings',
  sph: '/sph',
};
