import LoginFieldRepository from '../../ports/LoginFieldsRepository';
import {
  buildFetchLoginFields,
  fetchLoginFieldsUseCase,
} from './fetch-login-fields';

import {
  buildCreateLoginField,
  createLoginFieldUseCase,
} from './create-login-field';
import {
  buildDeleteLoginField,
  deleteLoginFieldUseCase,
} from './delete-login-field';
import {
  buildUpdateLoginField,
  updateLoginFieldUseCase,
} from './update-login-field';
import {
  buildCheckDuplicateField,
  checkDuplicateFieldUseCase,
} from './check-duplicate';

export default (dependencies: {
  loginFieldRepo: LoginFieldRepository;
}): {
  fetchLoginFields: fetchLoginFieldsUseCase;
  createLoginField: createLoginFieldUseCase;
  deleteLoginField: deleteLoginFieldUseCase;
  updateLoginField: updateLoginFieldUseCase;
  checkDuplicateField: checkDuplicateFieldUseCase;
} => {
  const { loginFieldRepo } = dependencies;
  const fetchLoginFields = buildFetchLoginFields({ loginFieldRepo });
  const createLoginField = buildCreateLoginField({ loginFieldRepo });
  const deleteLoginField = buildDeleteLoginField({ loginFieldRepo });
  const updateLoginField = buildUpdateLoginField({ loginFieldRepo });
  const checkDuplicateField = buildCheckDuplicateField({ loginFieldRepo });

  return {
    fetchLoginFields,
    createLoginField,
    deleteLoginField,
    updateLoginField,
    checkDuplicateField,
  };
};
