import { Ranking } from '../../../domain/entities/rankings';
import RankingRepository from '../../ports/RankingRepository';

export type updateRankingUseCase = (
  identifiers: { companyId: number; rankingId: number },
  payload: Partial<Ranking & { user_id: number }>,
) => Promise<object | null>;

export const buildUpdateRanking = (dependencies: {
  rankingRepo: RankingRepository;
}): updateRankingUseCase => {
  const { rankingRepo } = dependencies;

  const updateRanking: updateRankingUseCase = async (identifiers, payload) =>
    rankingRepo.update(identifiers, payload);

  return updateRanking;
};
