import React from 'react';
import styled from 'styled-components';

import { theme } from '../../../config';

import {
  ToastNotification,
  InlineNotification,
  NotificationKind,
} from 'carbon-components-react';

const StyledToastNotification = styled(ToastNotification)<{
  topOffSet: string;
  subtitleWidth: string;
}>`
  &.bx--toast-notification {
    min-height: 128px;
  }

  &.bx--toast-notification:first-child {
    margin-top: ${props => props.topOffSet};
  }

  .bx--toast-notification__title,
  .bx--toast-notification__subtitle {
    color: ${theme.colors.text01};
    font-size: 13px;
    line-height: 18px;
    white-space: pre-line;
    width: ${props => props.subtitleWidth};
  }

  .bx--toast-notification__details {
    margin-right: 10px;
  }

  .bx--toast-notification__close-button .bx--toast-notification__close-icon {
    fill: ${theme.colors.icon01};
  }

  &.bx--toast-notification--warning
    .bx--toast-notification__icon
    path[opacity='0'] {
    opacity: 0;
  }

  &.bx--toast-notification--error .bx--toast-notification__icon {
    fill: ${theme.colors.support01};
  }

  &.bx--toast-notification--success .bx--toast-notification__icon {
    fill: ${theme.colors.support02};
  }

  &.bx--toast-notification--warning .bx--toast-notification__icon {
    fill: ${theme.colors.support03};
  }

  &.bx--toast-notification--info .bx--toast-notification__icon {
    fill: ${theme.colors.support04};
  }

  &.bx--toast-notification--error {
    background-color: ${theme.colors.notificationErrorBackgroundColor};
    border-color: ${theme.colors.support01};
  }

  &.bx--toast-notification--success {
    background-color: ${theme.colors.notificationSuccessBackgroundColor};
    border-color: ${theme.colors.support02};
  }

  &.bx--toast-notification--warning {
    background-color: ${theme.colors.notificationWarningBackgroundColor};
    border-color: ${theme.colors.support03};
  }

  &.bx--toast-notification--info {
    background-color: ${theme.colors.notificationInfoBackgroundColor};
    border-color: ${theme.colors.support04};
  }

  @media ${theme.breakpoints.wideScreen} {
    &.bx--toast-notification {
      max-width: 288px;
    }
  }

  @media ${theme.breakpoints.mobile} {
    &.bx--toast-notification:first-child {
      margin-top: 7px;
    }

    &.bx--toast-notification {
      min-height: 98px;
    }
  }
`;

const StyledInlineNotification = styled(InlineNotification)<{
  $hideClose: boolean;
}>`
  && {
    max-width: inherit;
    min-width: inherit;

    .bx--inline-notification__text-wrapper {
      align-items: end;
    }

    .bx--inline-notification__title,
    .bx--inline-notification__subtitle {
      color: ${theme.colors.text01};
    }

    .bx--inline-notification__title {
      font-weight: 700;
    }

    .bx--inline-notification__subtitle {
      font-size: 13px;
    }

    .bx--inline-notification__close-button {
      display: ${({ $hideClose }) => ($hideClose ? 'none' : '')};
    }

    .bx--inline-notification__close-button
      .bx--inline-notification__close-icon {
      fill: ${theme.colors.icon01};
    }

    &.bx--inline-notification--warning
      .bx--inline-notification__icon
      path[opacity='0'] {
      opacity: 0;
    }

    &.bx--inline-notification--error .bx--inline-notification__icon {
      fill: ${theme.colors.support01};
    }

    &.bx--inline-notification--success .bx--inline-notification__icon {
      fill: ${theme.colors.support02};
    }

    &.bx--inline-notification--warning .bx--inline-notification__icon {
      fill: ${theme.colors.support03};
    }

    &.bx--inline-notification--info .bx--inline-notification__icon {
      fill: ${theme.colors.support04};
    }

    &.bx--inline-notification--error {
      background-color: ${theme.colors.notificationErrorBackgroundColor};
      border: 1px solid rgba(218, 30, 40, 0.4);
      border-left: 3px solid ${theme.colors.support01};
    }

    &.bx--inline-notification--success {
      background-color: ${theme.colors.notificationSuccessBackgroundColor};
      border: 1px solid rgba(36, 161, 72, 0.4);
      border-left: 3px solid ${theme.colors.support02};
    }

    &.bx--inline-notification--warning {
      background-color: ${theme.colors.notificationWarningBackgroundColor};
      border: 1px solid rgba(241, 194, 27, 0.4);
      border-left: 3px solid ${theme.colors.support03};
    }

    &.bx--inline-notification--info {
      background-color: ${theme.colors.notificationInfoBackgroundColor};
      border: 1px solid rgba(0, 67, 206, 0.4);
      border-left: 3px solid ${theme.colors.support04};
    }
  }
`;

export type Props = {
  kind: NotificationKind;
  title: string;
  subtitle?: React.ReactNode;
  style?: Object;
  timeout?: number;
  inline?: boolean;
  topOffSet?: string;
  subtitleWidth?: string;
  handleClose?: () => boolean;
  hideClose?: boolean;
};

const Component = ({
  kind,
  title,
  subtitle,
  style,
  timeout,
  topOffSet = '52px',
  inline = false,
  subtitleWidth = 'inherit',
  handleClose,
  hideClose = false,
}: Props): React.ReactElement => {
  return inline ? (
    <StyledInlineNotification
      kind={kind}
      title={title}
      subtitle={subtitle}
      style={style}
      onClose={handleClose}
      $hideClose={hideClose}
    />
  ) : (
    <StyledToastNotification
      kind={kind}
      title={title}
      subtitle={subtitle}
      style={style}
      timeout={timeout}
      onClose={handleClose}
      topOffSet={topOffSet}
      subtitleWidth={subtitleWidth}
    />
  );
};

export default Component;
