import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormikProps } from 'formik';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { Member } from '../../../domain/entities/member';

import { useAuthHooks } from '../../../hooks/auth';
import { useGlobalState } from '../../../hooks/global';

import { Login } from '../../templates/Login';
import { LoginFormProps } from '../../templates/Login/Login';
import { LoginCustomInfo } from '../../templates/LoginCustomInfo';

type Props = {
  loginError?: string;
  isLoggedIn?: boolean;
  didSetPassword?: boolean;
  didLogin?: boolean;
};

const Component = ({}: Props): React.ReactElement => {
  const navigate = useNavigate();
  const location = useLocation();
  const { useLogin } = useAuthHooks();
  const { login } = useLogin();
  const loginFormikRef = useRef<{ resetForm: () => void }>(null);
  const {
    useCurrentUser: { currentUser },
  } = useGlobalState();

  const emptyFields = currentUser && currentUser.emptyCustomFields;
  const [signUpSuccess, setSignUpSuccess] = useState<boolean>(false);
  const [resetPasswordSuccess, setResetPasswordSuccess] = useState<boolean>(
    false,
  );
  const [showResetPasswordModal, setShowResetPasswordModal] = useState<boolean>(
    false,
  );

  const { mutate: loginMutation, error } = useMutation(
    (params: {
      userId: string;
      companyId: string;
      temporaryPassword: string;
      newPassword?: string;
      isUpdateTemporaryPassword?: boolean;
    }) => {
      return login(
        params.userId,
        params.companyId,
        params.temporaryPassword,
        params.newPassword,
        params.isUpdateTemporaryPassword,
      );
    },
    {
      onSuccess: resp => {
        const res = resp as Member & { isSuccessResetPassword?: boolean };
        const {
          is_reset_password,
          isSuccessResetPassword,
          isDoneInitial,
        } = res;

        if (is_reset_password) {
          return setShowResetPasswordModal(is_reset_password);
        }

        if (isSuccessResetPassword) {
          loginFormikRef.current?.resetForm();
          setResetPasswordSuccess(true);
          return setShowResetPasswordModal(false);
        }

        if (!isDoneInitial) {
          return navigate(`/setup-credentials`);
        }
      },
    },
  );

  const scopeVarsRef = useRef<{
    userId: string;
    companyId: string;
    temporaryPassword: string;
  }>({
    userId: '',
    companyId: '',
    temporaryPassword: '',
  });

  const submitLoginForm = useCallback((values: Object): void => {
    const { userId, companyId, password } = values as {
      userId: string;
      companyId: string;
      password: string;
    };
    const userIdClean = userId.replace(/^0+/, '');
    const companyIdClean = companyId.replace(/^0+/, '');

    scopeVarsRef.current = {
      userId: userIdClean,
      companyId: companyIdClean,
      temporaryPassword: password,
    };
    loginMutation({
      userId: userIdClean,
      companyId: companyIdClean,
      temporaryPassword: password,
    });
  }, []);

  const onChangePassword = useCallback((values: Object): void => {
    const { newPassword } = values as {
      newPassword: string;
    };
    loginMutation({
      userId: scopeVarsRef.current.userId,
      companyId: scopeVarsRef.current.companyId,
      temporaryPassword: scopeVarsRef.current.temporaryPassword,
      newPassword,
      isUpdateTemporaryPassword: true,
    });
  }, []);

  const promptUser = (event: any) => {
    event.preventDefault();
    event.returnValue = '';
  };

  useEffect(() => {
    if (location.state && location.state.adminSignUp) {
      setSignUpSuccess(true);
    }
  }, [location.state]);

  useEffect(() => {
    emptyFields &&
      emptyFields.length > 0 &&
      window.addEventListener('beforeunload', promptUser);
    return () => {
      emptyFields &&
        emptyFields.length > 0 &&
        window.removeEventListener('beforeunload', promptUser);
    };
  }, [emptyFields]);

  return emptyFields && emptyFields.length > 0 ? (
    <LoginCustomInfo setResetPasswordModal={setShowResetPasswordModal} />
  ) : (
    <Login
      login={submitLoginForm}
      loginError={error}
      handleForgotPass={() => navigate('/forgot-password')}
      handleRegister={() => navigate('/admin-signup')}
      signUpSuccess={signUpSuccess}
      resetPasswordStatus={location.state?.resetPasswordStatus || ''}
      showResetPasswordModal={showResetPasswordModal}
      onChangePassword={onChangePassword}
      resetPasswordSuccess={resetPasswordSuccess}
      loginFormikRef={loginFormikRef as React.Ref<FormikProps<LoginFormProps>>}
    />
  );
};

export default Component;
