import React from 'react';
import styled from 'styled-components';

import { theme } from '../../../config';
import { Mail } from '../../atoms/Icons';
import words from '../../../constants/words';

const Container = styled.div`
  display: flex;
  grid-template-columns: 50% 50%;
  min-height: 100vh;
  background-color: ${theme.colors.ui01};
  align-items: center;
  justify-content: center;

  @media ${theme.breakpoints.mobileAndTablet} {
    grid-template-columns: 100%;
  }
`;

const MainMessage = styled.div`
  display: flex;
  background-color: ${theme.colors.white};
  width: 620px;
  height: 520px;
  border: 1px solid ${theme.colors.inputBorderColor};
  border-radius: 8px;

  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media ${theme.breakpoints.mobile} {
    width: 100%;
    height: 398px;
    margin: 0 20px;
  }
`;

const CongratulationsText = styled.p`
  font-size: 28px;
  color: ${theme.colors.black};
  text-align: center;
  padding: 25px 100px 20px;
  font-weight: 400;

  @media ${theme.breakpoints.mobile} {
    font-size: 16px;
    padding: 15px 20px 20px;
  }
`;

const LinkText = styled.p`
  font-size: 16px;
  text-align: center;
  padding: 0 100px;
  @media ${theme.breakpoints.mobile} {
    font-size: 14px;
    padding: 0 20px;
  }
`;

const EmailText = styled.span`
  text-decoration: underline;
  font-weight: 700;
`;

const StyledMail = styled.div`
  margin-bottom: 20px;
`;

export type ApplicationSentProps = {
  email: string;
};

const Component = ({ email }: ApplicationSentProps): React.ReactElement => {
  return (
    <Container>
      <MainMessage>
        <StyledMail>
          <Mail />
        </StyledMail>
        <CongratulationsText>{words.successfulSignUp}</CongratulationsText>
        <LinkText>
          {words.sentLink1}
          <EmailText>{email}</EmailText>
          {words.sentLink2}
        </LinkText>
      </MainMessage>
    </Container>
  );
};

export default Component;
