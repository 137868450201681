import React from 'react';
import './orangeButtonStyle.scss';

type PropType = {
  text: string;
  onClick?: () => void;
  style?: React.CSSProperties;
  disabled?: boolean;
};

export default function OrangeButton(props: PropType) {
  const { text, onClick, style, disabled } = props;
  return (
    <div className={`orange-btn-container ${disabled ? 'disabled' : ''}`}>
      <button
        className="orangeButton"
        onClick={onClick}
        style={style}
        disabled={disabled ? disabled : false}>
        {text}
      </button>
    </div>
  );
}
