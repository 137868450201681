import { CreateEntryApplication } from '../../../domain/entities/entry';
import EntryRepository from '../../ports/EntryRepository';

export type updateEntryApplicationUseCase = (
  companyId: number,
  memberId: number,
  rankingId: number,
  entryId: number,
  payload: CreateEntryApplication,
) => Promise<Response>;

export const buildUpdateEntryApplication = (dependencies: {
  entryRepo: EntryRepository;
}): updateEntryApplicationUseCase => {
  const { entryRepo } = dependencies;

  const updateEntryApplication: updateEntryApplicationUseCase = async (
    companyId,
    memberId,
    rankingId,
    entryId,
    payload,
  ) =>
    entryRepo.updateEntryApplication(
      companyId,
      memberId,
      rankingId,
      entryId,
      payload,
    );

  return updateEntryApplication;
};
