import { Ranking } from '../../../domain/entities/rankings';
import RankingRepository from '../../ports/RankingRepository';

export type getRankingListUseCase = (payload: {
  companyId: number;
  rankingId: number;
  appliedByMember?: number;
}) => Promise<Ranking>;

export const buildGetRankingList = (dependencies: {
  rankingRepo: RankingRepository;
}): getRankingListUseCase => {
  const { rankingRepo } = dependencies;

  const getRanking: getRankingListUseCase = async query =>
    rankingRepo.get(query);

  return getRanking;
};
