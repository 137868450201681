import AuthService from '../../ports/AuthService';

export type verifyEmailUseCase = (
  user_id: string,
  email: string,
) => Promise<object | null>;

export const buildVerifyEmail = (dependencies: {
  authService: AuthService;
}): verifyEmailUseCase => {
  const { authService } = dependencies;

  const verifyEmail: verifyEmailUseCase = (user_id, email) =>
    authService.verifyEmail(user_id, email);

  return verifyEmail;
};
