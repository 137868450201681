import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import { PropsValue } from 'react-select';

import words from '../../../constants/words';
import { theme } from '../../../config';

import { MemberApplicationFormDetails } from '../../organisms/MemberApplicationFormDetails';
import { WithdrawAppForm } from '../../organisms/RecordAppForm';
import { TransactionHistory } from '../../organisms/TransactionHistory';
import { DeleteApplicationModal } from '../DeleteApplicationModal';

import {
  ButtonsContainer,
  FirstColumn,
  MainContainer,
  SecondColumn,
  DetailsContainer,
  Status,
  StatusTitle,
  StatusWrapper,
  StyledLink,
  TransactionContainer,
  TransactionTitle,
  GlobalStyle,
  MultiSelectContainer,
  StyledDropdown,
  StyledDropdownSingle,
  StyledTextInput,
  StyledTooltip,
  StyledButton,
} from './elements';

import { IOptions as MultiSelectOptions } from '../../organisms/MultiSelectDropdown/MultiSelectDropdown';

import { AppFormType } from '../../organisms/MemberApplicationFormDetails/MemberApplicationFormDetails';
import { RecordAppFormType } from '../../organisms/RecordAppForm/WithdrawAppForm';
import { GroupApprover } from '../../../domain/entities/group';
import { EntryLogProps } from '../../molecules/TransactionHistoryEntry/TransactionHistoryEntry';
import { moveIsBasisFieldAtFirstPosition } from '../../../utils/arrays';

export type Props = {
  applicationStatus: StatusTypes;
  applicationValues: AppFormType;
  approverOptions: GroupApprover[];
  histories: EntryLogProps[];
  mainApprover?: GroupApprover;
  subApprovers?: PropsValue<MultiSelectOptions>;
  updateStatus: (status: StatusTypes) => void;
  submitForm: (status: AppFormType) => void;
  rankingName: string;
  deleteEntry: () => void;
  originalData?: any;
  handleCancel: () => void;
  areValuesChanged?: boolean;
};

export type StatusTypes = 'unapproved' | 'approved' | 'rejected' | 'withdraw';

const ApplicationTooltip = (props: {
  tooltipMsg: string;
  triggerElement: React.ReactElement;
  triggerElementMobile?: React.ReactElement;
  isOpen: boolean;
  onCancel: () => void;
  onPressYes: () => void;
  isOpenMobile?: boolean;
  onCancelMobile?: () => void;
  onPressYesMobile?: () => void;
  yesButtonText?: string;
  forBack?: boolean;
  hideMobile?: boolean;
}) => {
  const {
    tooltipMsg,
    triggerElement,
    triggerElementMobile,
    isOpen,
    onCancel,
    onPressYes,
    isOpenMobile,
    onCancelMobile = () => {},
    onPressYesMobile = () => {},
    yesButtonText,
    forBack,
    hideMobile,
  } = props;
  return (
    <>
      <StyledTooltip
        message={tooltipMsg}
        isOpen={isOpen}
        onChange={(ev, { open }) => {
          if (!open) onCancel();
        }}
        triggerElement={triggerElement}
        menuOffset={{ top: 5, left: 40 }}
        autoOrientation
        align="end">
        <div className="bx--tooltip__footer">
          <StyledLink onClick={onCancel}>
            {forBack ? words.cancelTooltipNo : words.cancel}
          </StyledLink>
          <StyledButton
            title={yesButtonText || words.yes}
            onPress={onPressYes}
            style={{ fontSize: '14px' }}
          />
        </div>
      </StyledTooltip>
      {!hideMobile && (
        <StyledTooltip
          message={tooltipMsg}
          isOpen={isOpenMobile}
          onChange={(ev, { open }) => {
            if (!open) onCancelMobile();
          }}
          triggerElement={triggerElementMobile}
          menuOffset={{ top: -10 }}
          align="center"
          direction="top"
          $isMobile>
          <div className="bx--tooltip__footer">
            <StyledLink onClick={onCancelMobile}>
              {forBack ? words.cancelTooltipNo : words.cancel}
            </StyledLink>
            <StyledButton
              title={yesButtonText || words.yes}
              onPress={onPressYesMobile}
              style={{ fontSize: '14px' }}
            />
          </div>
        </StyledTooltip>
      )}
    </>
  );
};

const MemberApplicationDetails = ({
  applicationStatus,
  applicationValues,
  approverOptions,
  histories,
  mainApprover,
  subApprovers,
  updateStatus,
  submitForm,
  rankingName,
  deleteEntry,
  originalData,
  handleCancel,
  areValuesChanged,
}: Props): React.ReactElement => {
  const { setFieldValue, handleChange, errors, values } = useFormikContext<
    RecordAppFormType
  >();
  const hasEmptyCustomValue =
    values && values.customFields
      ? values.customFields.filter(fieldValue => !fieldValue.value_decimal)
      : [];

  const [openApplyTooltip, setOpenApplyTooltip] = useState(false);
  const [openBackTooltip, setOpenBackTooltip] = useState(false);

  const [openWithdrawTooltip, setOpenWithdrawTooltip] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [mobileApplyTooltip, setMobileApplyTooltip] = useState(false);
  const [mobileWithdrawTooltip, setMobileWithdrawTooltip] = useState(false);

  const RenderStatus = ({ isMobile }: { isMobile?: boolean }) => {
    const statusName =
      words.recordRefStatus[applicationStatus].charAt(0).toUpperCase() +
      words.recordRefStatus[applicationStatus].slice(1);
    return (
      <StatusWrapper
        className={isMobile ? 'member-app-details-status-mobile' : undefined}>
        <StatusTitle>{words.status}</StatusTitle>
        <Status status={applicationStatus}>{statusName}</Status>
      </StatusWrapper>
    );
  };

  const renderWithdrawnButtons = (hasChanges?: boolean) => {
    return (
      <>
        <ApplicationTooltip
          triggerElement={
            <StyledButton
              title={words.back}
              theme="light"
              onPress={() =>
                hasChanges
                  ? (setOpenBackTooltip(true), setOpenApplyTooltip(false))
                  : handleCancel()
              }
            />
          }
          yesButtonText={words.reapplyApplicationTooltipYes}
          tooltipMsg={words.discardChangesEditBasicInfo}
          isOpen={openBackTooltip}
          onCancel={() => setOpenBackTooltip(false)}
          onPressYes={() => {
            setOpenBackTooltip(false);
            handleCancel();
          }}
          forBack
        />
        <ApplicationTooltip
          triggerElement={
            <StyledButton
              title={words.reapply}
              theme="primary"
              onPress={() => {
                setOpenApplyTooltip(true);
                setOpenBackTooltip(false);
              }}
              disabled={
                hasEmptyCustomValue.length > 0 ||
                Object.keys(mainApprover || {}).length === 0
              }
            />
          }
          triggerElementMobile={
            <StyledButton
              title={words.reapply}
              theme="primary"
              onPress={() => {
                setMobileApplyTooltip(true);
              }}
              disabled={
                hasEmptyCustomValue.length > 0 ||
                Object.keys(mainApprover || {}).length === 0
              }
            />
          }
          yesButtonText={words.reapplyApplicationTooltipYes}
          tooltipMsg={words.tooltipReapplyApplicationMsg}
          isOpen={openApplyTooltip}
          onCancel={() => setOpenApplyTooltip(false)}
          onPressYes={() => {
            setOpenApplyTooltip(false);
            const formValues = {
              ...values,
              status: 'unapproved',
            } as AppFormType;
            submitForm(formValues);
          }}
          isOpenMobile={mobileApplyTooltip}
          onCancelMobile={() => setMobileApplyTooltip(false)}
          onPressYesMobile={() => {
            setMobileApplyTooltip(false);
            const formValues = {
              ...values,
              status: 'unapproved',
            } as AppFormType;
            submitForm(formValues);
          }}
        />
        <StyledButton
          title={words.deleteApplicationButton}
          theme="secondary"
          onPress={() => setIsDeleteModalOpen(true)}
          disabled={false}
        />
      </>
    );
  };

  const renderApprovedButtons = () => (
    <StyledButton
      title={words.back}
      theme="light"
      onPress={handleCancel}
      $hideMobile
    />
  );

  const renderUnapprovedButtons = () => (
    <>
      <StyledButton
        title={words.back}
        theme="light"
        onPress={handleCancel}
        $hideMobile
      />
      <ApplicationTooltip
        triggerElement={
          <StyledButton
            title={words.cancelApplicationButton}
            theme="primary"
            onPress={() => setOpenWithdrawTooltip(true)}
            disabled={false}
            style={{ maxWidth: '175px', width: '175px' }}
          />
        }
        triggerElementMobile={
          <StyledButton
            title={words.cancelApplicationButton}
            theme="primary"
            onPress={() => setMobileWithdrawTooltip(true)}
            disabled={false}
          />
        }
        yesButtonText={words.withdrawApplicationTooltipYes}
        tooltipMsg={words.tooltipCancelApplicationMsg}
        isOpen={openWithdrawTooltip}
        onCancel={() => setOpenWithdrawTooltip(false)}
        onPressYes={() => {
          updateStatus('withdraw');
          setOpenWithdrawTooltip(false);
        }}
        isOpenMobile={mobileWithdrawTooltip}
        onCancelMobile={() => setMobileWithdrawTooltip(false)}
        onPressYesMobile={() => {
          updateStatus('withdraw');
          setMobileWithdrawTooltip(false);
        }}
      />
    </>
  );

  const renderButtonGroup = () =>
    applicationStatus === 'approved'
      ? renderApprovedButtons()
      : renderUnapprovedButtons();

  const showApplicationForm =
    applicationStatus === 'withdraw' || applicationStatus === 'rejected';

  const basisFirstCustomFields = applicationValues.customFields.sort(
    (a, b) => Number(a.order_id) - Number(b.order_id),
  );

  return showApplicationForm ? (
    <>
      <WithdrawAppForm
        applicationDate={applicationValues.applicationDate}
        salesDate={applicationValues.salesDate}
        customFields={basisFirstCustomFields}
        mainApprover={mainApprover}
        subApprovers={applicationValues.subApprovers}
        notes={values['notes']}
        approverOptions={approverOptions}
        onChangeSalesDate={(value: Date) => {
          setFieldValue('salesDate', value);
        }}
        onChangeMainApprover={(value: GroupApprover) => {
          setFieldValue('mainApprover', value);
        }}
        onChangeSubApprovers={(value: PropsValue<MultiSelectOptions>) => {
          setFieldValue('subApprovers', value);
        }}
        onChangeNotes={handleChange('notes')}
        handleSubmit={() => {}}
        errors={errors}
        renderButtons={renderWithdrawnButtons(areValuesChanged)}
        setFieldValue={setFieldValue}
        status={applicationStatus}
        histories={histories}
        originalData={originalData}
      />
      <DeleteApplicationModal
        id={0}
        open={isDeleteModalOpen}
        onCancel={() => setIsDeleteModalOpen(false)}
        onConfirm={() => {
          deleteEntry();
          setIsDeleteModalOpen(false);
        }}
        onClose={() => setIsDeleteModalOpen(false)}
        rankingName={rankingName}
      />
    </>
  ) : (
    <MainContainer>
      <FirstColumn>
        <RenderStatus isMobile />
        <MemberApplicationFormDetails values={applicationValues} />
      </FirstColumn>
      <SecondColumn>
        <ButtonsContainer>{renderButtonGroup()}</ButtonsContainer>
        <DetailsContainer>
          <RenderStatus />
          {mainApprover ? (
            <StyledDropdownSingle
              id="member-group"
              label={mainApprover.member?.name || ''}
              titleText={words.mainApproverRequired}
              styles={{
                width: '100%',
              }}
              backgroundColor={theme.colors.white}
              items={[]}
              onChange={() => {}}
              disabled
            />
          ) : null}
          <MultiSelectContainer>
            {subApprovers ? (
              <div style={{ cursor: 'not-allowed' }}>
                <StyledDropdown
                  label={words.subApproversOptional}
                  isMulti={true}
                  defaultValue={subApprovers}
                  options={[]}
                  onChange={() => {}}
                  isDisabled={true}
                  hasXIcon={false}
                />
              </div>
            ) : null}
          </MultiSelectContainer>
          <StyledTextInput
            id="application-notes"
            label={words.notes}
            value={`${applicationValues.notes}`}
            disabled
            $isMobile
          />
          <TransactionContainer>
            <TransactionTitle>{words.transactionHistory}</TransactionTitle>
            <TransactionHistory histories={histories} />
          </TransactionContainer>
        </DetailsContainer>
      </SecondColumn>
      <GlobalStyle />
    </MainContainer>
  );
};

export default MemberApplicationDetails;
