import React, { useState, useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';

import { CompanyInfoForm } from '../../organisms/CompanyInfoForm';
import { CompanyInfo } from '../../organisms/CompanyInfoForm/CompanyInfoForm';
import { CompanyInfoSchema, EmailValidation } from './validation';
import { useMutation, useQuery } from 'react-query';
import { useCompanyHooks } from '../../../hooks/company';
import { useGlobalState } from '../../../hooks/global';
import { Props as INotification } from '../../molecules/Notification/Notification';
import { ToastNotification } from '../../molecules/Notification';
import words from '../../../constants/words';
import { TIMEOUT } from '../../../constants/timeout';
import { useAuthHooks } from '../../../hooks/auth';
import { PageLoader } from '../../atoms/Loading';
import { theme } from '../../../config';

const ContentContainer = styled.div<{ isLoading?: boolean }>`
  padding: ${props => (props.isLoading ? '0px 70px' : '40px 70px')};
  @media ${theme.breakpoints.mobile} {
    padding: 0 23px;
  }
`;

const StyledToastNotification = styled(ToastNotification)`
  .bx--toast-notification__details {
    margin-right: 0;
  }
`;

const FormWrapper = styled.div`
  max-width: 489px;
`;

export type Props = {};

type APIResponse = {
  error: boolean;
  msg: string;
};

const Component = ({}: Props): React.ReactElement => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [notification, setNotification] = useState<INotification | null>(null);
  const [newCompanyInfo, setNewCompanyInfo] = useState<CompanyInfo | undefined>(
    undefined,
  );
  const [isEmailVerified, setIsEmailVerified] = useState<boolean | undefined>(
    true,
  );
  const {
    useFetchCompanies,
    useUpdateCompany,
    useVerifyCompanyEmail,
  } = useCompanyHooks();
  const { useSignUp } = useAuthHooks();
  const { verifyCompanyEmail } = useSignUp();
  const { fetchCompany } = useFetchCompanies();
  const { updateCompany } = useUpdateCompany();
  const { resendCompanyEmailVerification } = useVerifyCompanyEmail();
  const {
    useCurrentUser: { currentUser },
  } = useGlobalState();
  const companyId = currentUser?.companyId;

  const toggleIsEdit = useCallback(() => {
    setIsEdit(!isEdit);
  }, [isEdit]);

  const {
    data: initialValues = {
      name: '',
      repName: '',
      telephone: '',
      email: '',
    },
    isFetching: isFetchingCompanyInfo,
  } = useQuery(
    ['company-info', companyId, newCompanyInfo],
    async () => {
      if (!companyId) {
        throw new Error(`field 'company id' not set`);
      }
      const response = await fetchCompany(companyId);
      if (!response) {
        throw new Error(`Company '${companyId}' not found`);
      }
      setIsEmailVerified(response?.email_verified);
      return {
        name: response.name,
        repName: response.repName,
        telephone: response.telephone,
        email: response.email,
      };
    },
    { refetchOnMount: true },
  );

  const { mutate: saveChanges, isLoading: isSubmitting } = useMutation(
    async (values: CompanyInfo) => {
      if (!companyId) {
        throw new Error(`field 'company id' not set`);
      }
      const res = await updateCompany(companyId, values);
      const mutatedCompanyInfo = {
        name: res.name,
        repName: res.repName,
        telephone: res.telephone,
        email: res.email,
      };
      setNewCompanyInfo(mutatedCompanyInfo);
      return res;
    },
    {
      onSuccess: () => {
        toggleIsEdit();
        setNotification({
          kind: 'success',
          title: words.companyInfoChanged,
          timeout: TIMEOUT,
          subtitle: words.companyInformationChanged,
          handleClose: () => {
            setNotification(null);
            return false;
          },
        });
      },
      onError: () => {
        setNotification({
          kind: 'error',
          title: words.error,
          timeout: TIMEOUT,
          subtitle: words.errorOccured,
          handleClose: () => {
            setNotification(null);
            return false;
          },
        });
      },
    },
  );

  const { mutate: verifyCompanyEmailMutation } = useMutation(
    ([email]: [string]) => {
      return verifyCompanyEmail(email);
    },
  );

  const {
    mutate: resendCompanyEmailVerificationMutation,
    isLoading,
  } = useMutation(([companyId]: [string]) => {
    return resendCompanyEmailVerification(companyId);
  });

  const handleFormSubmission = useCallback((values: CompanyInfo) => {
    saveChanges(values);
  }, []);

  const promptUser = (event: any) => {
    event.preventDefault();
    event.returnValue = '';
  };

  useEffect(() => {
    isEdit && window.addEventListener('beforeunload', promptUser);
    return () => {
      isEdit && window.removeEventListener('beforeunload', promptUser);
    };
  }, [isEdit]);

  const checkCompanyEmail = useCallback((email: string): void => {
    verifyCompanyEmailMutation([email], {
      onError: response => {
        const res = response as APIResponse;
        setCompanyEmailRes(res.error ? res.msg : '');
      },
      onSuccess: response => {
        const res = response as APIResponse;
        setCompanyEmailRes(res.error ? res.msg : '');
      },
    });
  }, []);

  const resendVerificationEmail = useCallback(() => {
    resendCompanyEmailVerificationMutation([(companyId as unknown) as string], {
      onSuccess: () => {
        setNotification({
          kind: 'success',
          title: words.companyInfoChanged,
          timeout: TIMEOUT,
          subtitle: words.verificationLinkSentToYourAddress,
          handleClose: () => {
            setNotification(null);
            return false;
          },
        });
      },
      onError: () => {
        setNotification({
          kind: 'error',
          title: words.error,
          timeout: TIMEOUT,
          subtitle: words.errorOccured,
          handleClose: () => {
            setNotification(null);
            return false;
          },
        });
      },
    });
  }, []);

  const [isChangingEmail, setIsChangingEmail] = useState<boolean>(false);
  const [emailVal, setEmailVal] = useState<string | null>(null);
  const [companyEmailRes, setCompanyEmailRes] = useState<string>('');
  const formRef = useRef<{ dirty: boolean; values: { email: string } }>();

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      const email = formRef.current?.values?.email;
      setCompanyEmailRes('');
      email &&
        email.length > 1 &&
        (await EmailValidation.validate(email)) &&
        isEdit &&
        formRef.current?.dirty &&
        checkCompanyEmail(email);
      setIsChangingEmail(false);
    }, 1000);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [emailVal]);

  return isFetchingCompanyInfo ? (
    <PageLoader vh={81} />
  ) : (
    <ContentContainer isLoading={isFetchingCompanyInfo}>
      <FormWrapper>
        <Formik
          innerRef={formRef as any}
          enableReinitialize
          initialValues={initialValues}
          validationSchema={CompanyInfoSchema}
          onSubmit={handleFormSubmission}>
          {({
            handleChange,
            handleSubmit,
            values,
            errors,
            dirty,
            resetForm,
            setFieldValue,
          }): React.ReactElement => {
            const { name, repName, email, telephone } = values;
            return (
              <CompanyInfoForm
                name={name}
                email={email}
                repName={repName}
                telephone={telephone}
                onChangeName={handleChange('name')}
                onChangeRepName={handleChange('repName')}
                onChangeEmail={(value: string) => {
                  setFieldValue('email', value);

                  setIsChangingEmail(true);
                  setEmailVal(value);
                }}
                onChangeTelephone={handleChange('telephone')}
                isEdit={isEdit}
                errors={errors}
                onClickButton={isEdit ? handleSubmit : toggleIsEdit}
                onCancel={() => {
                  resetForm();
                  toggleIsEdit();
                  setCompanyEmailRes('');
                }}
                isSubmitDisabled={
                  (isEdit && !dirty) ||
                  (isEdit &&
                    Object.values(errors || {}).filter(item => !!item).length >
                      0) ||
                  isChangingEmail ||
                  !!companyEmailRes.length ||
                  isSubmitting
                }
                isEmailVerified={isEmailVerified}
                companyEmailRes={companyEmailRes}
                onPressResendEmail={resendVerificationEmail}
                isResendingEmail={isLoading}
              />
            );
          }}
        </Formik>
      </FormWrapper>
      {notification ? (
        <StyledToastNotification notification={notification} />
      ) : null}
    </ContentContainer>
  );
};

export default Component;
