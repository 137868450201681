import EntryRepository from '../../ports/EntryRepository';
import {
  buildFetchFormFieldsApprover,
  fetchFormFieldsApproversUseCase,
} from './fetch-entry-form-fields-approvers';
import {
  buildCreateEntryApplication,
  createEntryApplicationUseCase,
} from './create-entry-application';
import {
  buildUpdateEntryApplication,
  updateEntryApplicationUseCase,
} from './update-entry-application';
import {
  buildDeleteEntryApplication,
  deleteEntryApplicationUseCase,
} from './delete-entry-application';

export default (dependencies: {
  entryRepo: EntryRepository;
}): {
  fetchFormFieldsApprovers: fetchFormFieldsApproversUseCase;
  createEntryApplication: createEntryApplicationUseCase;
  updateEntryApplication: updateEntryApplicationUseCase;
  deleteEntryApplication: deleteEntryApplicationUseCase;
} => {
  const { entryRepo } = dependencies;
  const fetchFormFieldsApprovers = buildFetchFormFieldsApprover({
    entryRepo,
  });
  const createEntryApplication = buildCreateEntryApplication({
    entryRepo,
  });
  const updateEntryApplication = buildUpdateEntryApplication({ entryRepo });
  const deleteEntryApplication = buildDeleteEntryApplication({ entryRepo });

  return {
    fetchFormFieldsApprovers,
    createEntryApplication,
    updateEntryApplication,
    deleteEntryApplication,
  };
};
