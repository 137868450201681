import moment from 'moment';
import React from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import { theme } from '../../../config';
import words from '../../../constants/words';
import { RankingLogs } from '../../../domain/entities/ranking';

const Container = styled.div``;

const HeaderLabel = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;

  color: ${theme.colors.text01};

  margin-bottom: 16px;
`;

const ContentWrapper = styled.div`
  /* ui/ui-01 */
  background: ${theme.colors.ui01};
  /* ui/ui-03 */
  border: 1px solid ${theme.colors.ui03};
  box-sizing: border-box;

  padding: 0 12px 12px 12px;
  overflow-y: auto;
  max-height: 427px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: ${({ isLast }: { isLast?: boolean }) =>
    isLast ? `none` : `1px solid ${theme.colors.ui03}`};

  padding: 9px 0;
`;

const Image = styled.img`
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 2px;
`;

const InitialProfile = styled.div`
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 2px;
  background-color: ${theme.colors.ui04};
  color: ${theme.colors.white};
  font-weight: 500;
  font-size: 13px;
  text-align: center;
  line-height: 1.9;
`;

const Details = styled.div`
  margin-left: 7px;
`;

const UpdatedBy = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.16px;

  color: ${theme.colors.text01};
`;

const ViewChanges = styled.span`
  color: ${theme.colors.text01};
  cursor: pointer;
`;

const UpdateTime = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.32px;

  /* text / text-01 */

  color: ${theme.colors.text01};
`;

const CurrentVersion = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.16px;

  color: ${theme.colors.support02};
`;

export type Props = {
  className?: string;
  logs?: RankingLogs[];
};

const Component = ({ className, logs }: Props): React.ReactElement => {
  return (
    <Container className={className}>
      <HeaderLabel>{words.changelogs}</HeaderLabel>
      <ContentWrapper>
        {/* TODO need to confirm if need translation */}
        {logs !== undefined && logs.length > 0
          ? logs.map((log, index) => {
              return (
                <Row
                  key={`${log.user_details.name}-${index}`}
                  isLast={index === logs.length - 1}>
                  {log.user_details.photoUrl ? (
                    <Image src={log.user_details.photoUrl} />
                  ) : (
                    <InitialProfile>
                      {log.user_details.name.charAt(0)}
                    </InitialProfile>
                  )}
                  <Details>
                    <UpdatedBy>
                      {index === 0 ? (
                        words.logUpdatedBy(log.user_details.name)
                      ) : (
                        <>
                          <div>
                            {words.logHasUpdated(log.user_details.name)}
                          </div>
                          <ViewChanges>{words.logSeeChanges}</ViewChanges>
                        </>
                      )}
                    </UpdatedBy>
                    <UpdateTime>
                      {` (${moment(log.createdAt).format('MM/DD')} @ ${moment(
                        log.createdAt,
                      ).format('H:mm')})`}
                    </UpdateTime>
                    {index === 0 && (
                      <CurrentVersion>{words.viewingLatest}</CurrentVersion>
                    )}
                  </Details>
                </Row>
              );
            })
          : ''}
      </ContentWrapper>
    </Container>
  );
};

export default Component;
