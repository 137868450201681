import React from 'react';
import styled from 'styled-components';
import { Checkbox, CheckboxSkeleton } from 'carbon-components-react';

import { theme } from '../../../config';

const CheckboxContainer = styled.div<{
  checkboxTheme: string;
  disabled?: boolean;
}>`
  &&&& {
    .bx--checkbox:checked + .bx--checkbox-label::before {
      border-radius: 3px;
      border-width: 1px;
      border-color: ${props =>
        props.disabled ? theme.colors.disabled02 : props.checkboxTheme};
      background-color: ${props =>
        props.disabled ? theme.colors.disabled02 : props.checkboxTheme};
    }

    .bx--checkbox-label::before {
      border-radius: 3px;
    }

    .bx--checkbox-label .bx--checkbox-label-text {
      line-height: 19px;
      font-size: 13px;
    }
  }
`;

const checkedboxTheme = {
  primary: theme.colors.primaryButton1,
  secondary: theme.colors.edit,
};

export type checkboxProps = {
  id?: string;
  name?: string;
  label: React.ReactElement | string;
  isChecked?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  theme?: 'primary' | 'secondary';
  onChange: (val: boolean) => void;
};

const Component = ({
  id = '',
  name = '',
  label,
  isChecked = false,
  disabled = false,
  isLoading = false,
  onChange,
  theme = 'primary',
  ...props
}: checkboxProps): React.ReactElement => {
  const onToggle = (event: boolean): void => {
    onChange(event);
  };

  return (
    <CheckboxContainer
      checkboxTheme={checkedboxTheme[theme]}
      disabled={disabled}>
      {!isLoading ? (
        <Checkbox
          wrapperClassName={'testCheckbox'}
          id={id}
          name={name}
          checked={isChecked}
          labelText={label}
          disabled={disabled}
          onChange={onToggle}
          {...props}
        />
      ) : (
        <CheckboxSkeleton />
      )}
    </CheckboxContainer>
  );
};

export default Component;
