import React from 'react';
import styled from 'styled-components';
import { theme } from '../../../config';
import words from '../../../constants/words';
import { SubHeader } from '../../atoms/Subheader';
import { RankingList } from '../../templates/RankingList';

const ChildrenWrapper = styled.div`
  padding: 40px 70px 40px 70px;

  @media ${theme.breakpoints.mobile} {
    padding: 20px;
    width: 100vw;
  }
`;

type Props = {};

const Component = ({}: Props): React.ReactElement => {
  return (
    <div>
      <SubHeader title={words.rankingList} />
      <ChildrenWrapper>
        <React.Suspense fallback={<div />}>
          <RankingList />
        </React.Suspense>
      </ChildrenWrapper>
    </div>
  );
};

export default Component;
