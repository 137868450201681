import { buildSignInUser, signInUserUseCase } from './signin-user';
import { buildSignOut, signOutUseCase } from './signout';
import { buildRegisterUser, registerUserUseCase } from './register-user';
import { buildAdminSignUp, adminSignUpUseCase } from './admin-signup';
import {
  buildAdminFirstLogin,
  adminFirstLoginUseCase,
} from './admin-first-login';
import { buildVerifyEmail, verifyEmailUseCase } from './verify-email';
import {
  buildVerifyCompanyEmail,
  verifyCompanyEmailUseCase,
} from './verify-company-email';
import {
  buildAdminVerification,
  adminVerificationUseCase,
} from './admin-verification';
import { buildForgetPassword, forgetPasswordUseCase } from './forget-password';
import { buildResetPassword, resetPasswordUseCase } from './reset-password';
import {
  buildVerifyAdminEmail,
  verifyAdminEmailUseCase,
} from './verify-admin-email';

import AuthService from '../../ports/AuthService';
import { buildChangePassword, changePasswordUseCase } from './change-password';

export default (dependencies: {
  authService: AuthService;
}): {
  signIn: signInUserUseCase;
  signOut: signOutUseCase;
  register: registerUserUseCase;
  adminSignUp: adminSignUpUseCase;
  adminFirstLogin: adminFirstLoginUseCase;
  verifyEmail: verifyEmailUseCase;
  verifyCompanyEmail: verifyCompanyEmailUseCase;
  adminVerification: adminVerificationUseCase;
  forgetPassword: forgetPasswordUseCase;
  resetPassword: resetPasswordUseCase;
  adminEmailVerification: verifyAdminEmailUseCase;
  changePassword: changePasswordUseCase;
} => {
  const { authService } = dependencies;
  const signIn = buildSignInUser({ authService });
  const signOut = buildSignOut({ authService });
  const register = buildRegisterUser({ authService });
  const adminSignUp = buildAdminSignUp({ authService });
  const adminFirstLogin = buildAdminFirstLogin({ authService });
  const verifyEmail = buildVerifyEmail({ authService });
  const verifyCompanyEmail = buildVerifyCompanyEmail({ authService });
  const adminVerification = buildAdminVerification({ authService });
  const forgetPassword = buildForgetPassword({ authService });
  const resetPassword = buildResetPassword({ authService });
  const adminEmailVerification = buildVerifyAdminEmail({ authService });
  const changePassword = buildChangePassword({ authService });

  return {
    signIn,
    signOut,
    register,
    adminSignUp,
    adminFirstLogin,
    verifyEmail,
    verifyCompanyEmail,
    adminVerification,
    forgetPassword,
    resetPassword,
    adminEmailVerification,
    changePassword,
  };
};
