import styled, { createGlobalStyle } from 'styled-components';
import { Table } from '../../organisms/Table';
import { theme } from '../../../config';
import { Button } from '../../atoms/Button';
import { Tooltip } from '../../atoms/Tooltip';

export const Wrapper = styled.div.attrs(
  (props: { isAddingField: boolean; editFieldRow: number }) => props,
)`
  .bx--text-input__field-outer-wrapper {
    width: 320px;
  }

  .bx--text-input__field-wrapper {
    input {
      width: 320px;
    }
  }

  .bx--data-table.bx--skeleton th {
    background-color: ${theme.colors.ui03};
  }

  tbody tr:last-child td {
    background-color: ${props =>
      props.isAddingField ? theme.colors.ui03 : theme.colors.ui01};
  }

  tbody tr:last-child:hover td {
    background-color: ${props =>
      props.isAddingField ? theme.colors.ui03 : theme.colors.hoverui};
  }

  tbody tr:nth-child(${props => props.editFieldRow}) td,
  tbody tr:nth-child(${props => props.editFieldRow}):hover td {
    background-color: ${theme.colors.ui03};
  }

  tbody tr:hover td {
    background-color: ${theme.colors.hoverui};
  }

  .bx--data-table-content {
    box-shadow: 0px 0px 8px rgba(236, 215, 192, 0.8);
  }

  tbody tr td:first-child,
  thead tr th:first-child {
    width: 10%;
  }

  tbody tr td:nth-child(2),
  thead tr th:nth-child(2) {
    width: 30%;
  }

  tbody tr td:nth-child(3),
  tbody tr td:nth-child(3),
  tbody tr td:nth-child(4),
  tbody tr td:nth-child(4),
  tbody tr td:last-child,
  thead tr th:last-child {
    width: 20%;
  }
`;

export const StyledTable = styled(Table)``;

export const CellWrapper = styled.div`
  .bx--toggle__switch {
    margin-top: 0;
  }
`;

export const TextInputWrapper = styled.div`
  justify-content: center;
  align-items: center;
  margin-left: -1.2em;
  padding: 0.5em 0;
  input {
    margin-bottom: 0;
  }

  .bx--form-requirement {
    width: max-content;
  }
`;

export const AddFieldWrapper = styled.div<{ isDisabled: boolean }>`
  padding-top: 50px;

  ${props =>
    props.isDisabled
      ? `.field-button, .field-button:hover {
    background-color: ${theme.colors.disabledButton1} !important;
    border-color: ${theme.colors.disabledButton2} !important;
    color: ${theme.colors.disabledButton2} !important;
    cursor: not-allowed !important;
  }
  `
      : ``}
`;

export const AddButton = styled(Button)`
  width: auto;
  padding: 10px 61px 10px 13px;
  display: flex;

  &&& svg {
    height: 9px;
    width: 9px;
  }
`;

export const DefaultText = styled.div`
  color: ${theme.colors.text03};
`;

export const StyledTooltip = styled(Tooltip)`
  margin-bottom: 50px;
`;

export const GlobalStyle = createGlobalStyle`
  & {
    .bx--tooltip {
      max-width: 434px;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
`;
