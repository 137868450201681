import React, { useCallback, useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router';
import { useCompanyHooks } from '../../../hooks/company';
import { VerificationEmailSuccess } from '../../templates/VerificationEmailSuccess';

const Component = () => {
  const navigate = useNavigate();
  const { companyId, token } = useParams();
  const { useVerifyCompanyEmail } = useCompanyHooks();
  const { verifyCompanyEmail } = useVerifyCompanyEmail();
  const [isDoneInit, setIsDoneInit] = useState(false);

  const { mutate: verifyCompanyEmailMutation, isLoading } = useMutation(
    ([companyId, token]: [string, string]) => {
      return verifyCompanyEmail(companyId, token);
    },
    {
      onError: (error: AxiosError) => {
        const status = error.response?.status;
        if (status && [404, 400, 403].includes(status)) {
          return navigate('/page-not-found');
        }
        if (status === 410) {
          return navigate('/link-expired');
        }
        return navigate('/login');
      },
      onSettled: () => {
        setIsDoneInit(true);
      },
    },
  );

  const companyEmailVerification = useCallback(
    (values: { companyId: string; token: string }): void => {
      const { companyId, token } = values;
      verifyCompanyEmailMutation([companyId, token]);
    },
    [],
  );

  const backToHome = () => {
    return navigate('/login');
  };

  useEffect(() => {
    if (companyId && token) {
      companyEmailVerification({ companyId, token });
    }
  }, []);

  return (
    <>
      {isDoneInit && !isLoading && (
        <VerificationEmailSuccess backToHome={backToHome} />
      )}
    </>
  );
};

export default Component;
