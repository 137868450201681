import { Member } from '../../../domain/entities/member';
import MemberRepository from '../../ports/MemberRepository';

export type fetchMemberUseCase = (payload: {
  companyId: number;
  memberId: number;
}) => Promise<Member | undefined>;

export const buildFetchMember = (dependencies: {
  memberRepo: MemberRepository;
}): fetchMemberUseCase => {
  const { memberRepo } = dependencies;

  const fetchMember: fetchMemberUseCase = async query => memberRepo.get(query);

  return fetchMember;
};
