import React from 'react';
import styled from 'styled-components';
import { Loading } from './';

export type Props = {
  vh?: number;
};

const LoaderDiv = styled.div<{ vh: number }>`
  width: 100%;
  height: ${props => `${props.vh}vh`};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoaderContainer = styled.div``;

const Component = ({ vh = 85 }: Props): React.ReactElement => {
  return (
    <LoaderDiv vh={vh}>
      <LoaderContainer>
        <Loading
          isActive={true}
          withOverlay={false}
          isSmall={false}
          width={70}
          height={70}
          strokeWidth={7}
        />
      </LoaderContainer>
    </LoaderDiv>
  );
};

export default Component;
