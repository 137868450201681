import React from 'react';
import { useQuery } from 'react-query';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';

import { useGlobalState } from '../../../hooks/global';
import { useEntryHooks } from '../../../hooks/entry';

import { navigateToErrorPage } from '../../../utils/handleErrorPage';

import { RecordApplication } from '../../templates/RecordApplication';

type Props = {};

const Component = ({}: Props): React.ReactElement => {
  const { rankingId } = useParams();
  const { pathname: locationPathname } = useLocation();
  const navigate = useNavigate();

  const {
    useCurrentUser: { currentUser },
  } = useGlobalState();

  const { useFetchEntry } = useEntryHooks();

  const { fetchFormFieldsApprovers } = useFetchEntry();

  const {
    data: fieldsApproversData,
    isFetching: isFetchingFieldsApprovers,
  } = useQuery(
    ['fetchFormFieldsApprovers'],
    () => {
      if (!currentUser?.companyId) {
        throw new Error(`'company id' not set`);
      }

      return fetchFormFieldsApprovers(
        currentUser.companyId,
        currentUser.memberId,
        Number(rankingId),
      );
    },
    {
      onError: (err: AxiosError) => {
        navigateToErrorPage(navigate, err.response?.status, locationPathname);
      },
    },
  );

  const formValues = {
    applicationDate: new Date(),
    salesDate: new Date(),
    customFields:
      fieldsApproversData?.customFields.sort(
        (a, b) => Number(a.order_id) - Number(b.order_id),
      ) || [],
  };

  return (
    <RecordApplication
      formValues={formValues}
      rankingName={fieldsApproversData?.rankingName}
      approverOptions={fieldsApproversData?.approvers}
      approverOptionsAll={fieldsApproversData?.approversAll}
      isFetchingFieldsApprovers={isFetchingFieldsApprovers}
    />
  );
};

export default Component;
