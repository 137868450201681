import RankingRepository from '../../ports/RankingRepository';

export type deleteRankingUseCase = (identifiers: {
  companyId: number;
  rankingId: number;
}) => Promise<void>;

export const buildDeleteRanking = (dependencies: {
  rankingRepo: RankingRepository;
}): deleteRankingUseCase => {
  const { rankingRepo } = dependencies;

  const deleteRanking: deleteRankingUseCase = async identifiers =>
    rankingRepo.delete(identifiers);

  return deleteRanking;
};
