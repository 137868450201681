import AuthService from '../../ports/AuthService';

export type resetPasswordUseCase = (
  pk: string,
  token: string,
  password: string,
  password2: string,
) => Promise<object | null>;

export const buildResetPassword = (dependencies: {
  authService: AuthService;
}): resetPasswordUseCase => {
  const { authService } = dependencies;

  const resetPassword: resetPasswordUseCase = (
    pk: string,
    token: string,
    password: string,
    password2: string,
  ) => authService.resetPassword(pk, token, password, password2);

  return resetPassword;
};
