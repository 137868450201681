import React from 'react';

type Props = {
  [key: string]: string | number | Function;
};

const BigInfo = (props: Props): React.ReactElement => {
  return (
    <svg
      width="62"
      height="62"
      viewBox="0 0 62 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <mask
        id="path-1-outside-1_10415_201"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="62"
        height="62"
        fill="black">
        <rect fill="white" width="62" height="62" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31 3C15.536 3 3 15.536 3 31C3 46.464 15.536 59 31 59C46.464 59 59 46.464 59 31C59 23.5739 56.05 16.452 50.799 11.201C45.548 5.94999 38.4261 3 31 3ZM31 13C32.6569 13 34 14.3431 34 16C34 17.6569 32.6569 19 31 19C29.3431 19 28 17.6569 28 16C28 14.3431 29.3431 13 31 13ZM39 47.24H23V42.76H28.76V29.24H25V24.76H33.26V42.76H39V47.24Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31 3C15.536 3 3 15.536 3 31C3 46.464 15.536 59 31 59C46.464 59 59 46.464 59 31C59 23.5739 56.05 16.452 50.799 11.201C45.548 5.94999 38.4261 3 31 3ZM31 13C32.6569 13 34 14.3431 34 16C34 17.6569 32.6569 19 31 19C29.3431 19 28 17.6569 28 16C28 14.3431 29.3431 13 31 13ZM39 47.24H23V42.76H28.76V29.24H25V24.76H33.26V42.76H39V47.24Z"
        fill="#FF714F"
      />
      <path
        d="M39 47.24V50.24H42V47.24H39ZM23 47.24H20V50.24H23V47.24ZM23 42.76V39.76H20V42.76H23ZM28.76 42.76V45.76H31.76V42.76H28.76ZM28.76 29.24H31.76V26.24H28.76V29.24ZM25 29.24H22V32.24H25V29.24ZM25 24.76V21.76H22V24.76H25ZM33.26 24.76H36.26V21.76H33.26V24.76ZM33.26 42.76H30.26V45.76H33.26V42.76ZM39 42.76H42V39.76H39V42.76ZM31 0C13.8792 0 0 13.8792 0 31H6C6 17.1929 17.1929 6 31 6V0ZM0 31C0 48.1208 13.8792 62 31 62V56C17.1929 56 6 44.8071 6 31H0ZM31 62C48.1208 62 62 48.1208 62 31H56C56 44.8071 44.8071 56 31 56V62ZM62 31C62 22.7783 58.7339 14.8933 52.9203 9.07969L48.6777 13.3223C53.3661 18.0107 56 24.3696 56 31H62ZM52.9203 9.07969C47.1067 3.26606 39.2217 0 31 0V6C37.6304 6 43.9893 8.63392 48.6777 13.3223L52.9203 9.07969ZM31 16H37C37 12.6863 34.3137 10 31 10V16ZM31 16V22C34.3137 22 37 19.3137 37 16H31ZM31 16H25C25 19.3137 27.6863 22 31 22V16ZM31 16V10C27.6863 10 25 12.6863 25 16H31ZM39 44.24H23V50.24H39V44.24ZM26 47.24V42.76H20V47.24H26ZM23 45.76H28.76V39.76H23V45.76ZM31.76 42.76V29.24H25.76V42.76H31.76ZM28.76 26.24H25V32.24H28.76V26.24ZM28 29.24V24.76H22V29.24H28ZM25 27.76H33.26V21.76H25V27.76ZM30.26 24.76V42.76H36.26V24.76H30.26ZM33.26 45.76H39V39.76H33.26V45.76ZM36 42.76V47.24H42V42.76H36Z"
        fill="#3B1F34"
        mask="url(#path-1-outside-1_10415_201)"
      />
    </svg>
  );
};

export default BigInfo;
