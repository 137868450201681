import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import styled, { css } from 'styled-components';
import { theme } from '../../../config';
import { Account, Evercontest, Notification } from '../../atoms/Icons';
import {
  Header,
  HeaderGlobalAction,
  HeaderGlobalBar,
} from 'carbon-components-react/lib/components/UIShell';
import { useGlobalState } from '../../../hooks/global';
import { useNotificationHooks } from '../../../hooks/notification';
import { useQuery } from 'react-query';
import Pusher from 'pusher-js';
import { ImageDisplay } from '../../atoms/ImageDisplay';
import { AccountOptions } from '../../molecules/AccountOptions';
import { Loading } from '../../atoms/Loading';
import { LoadingImage } from '../../atoms/ImageDisplay/ImageDisplay';
import words from '../../../constants/words';

const StyledHeader = styled(Header)`
  flex: 1;
  z-index: 5;
  position: sticky;
  background-color: ${theme.colors.ui01};
  border-bottom-color: ${theme.colors.ui01};
  .bx--header__name {
    color: ${theme.colors.ui05};
  }
  .bx--header__action:hover {
    background-color: transparent;
  }
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:focus {
    box-shadow: none;
  }
`;

const NotifIcon = styled.div<{ hasNewNotif: boolean }>`
  ${props =>
    props.hasNewNotif
      ? css`
          animation: shake 2s linear;
          @keyframes shake {
            10% {
              transform: rotate(10deg);
            }
            20% {
              transform: rotate(0deg);
            }
            25% {
              transform: rotate(-10deg);
            }
            30% {
              transform: rotate(0deg);
            }
          }
        `
      : css``}
`;

const NotifAction = styled(HeaderGlobalAction)`
  &.bx--header__action--active {
    border: 0;
    & > svg {
      path {
        fill: ${theme.colors.primaryButton1};
      }
    }
  }

  &:hover path {
    fill: ${theme.colors.iconNotifHover};
  }
  &:active path {
    fill: ${theme.colors.primaryButton1};
  }
`;

const ClickableTitle = styled.div`
  display: flex;
  align-items: center;
  padding-left: 17px;
  &:hover {
    cursor: pointer;
  }
`;

const Badge = styled.div`
  position: absolute;
  left: 55%;
  top: 20%;
  background: ${theme.colors.errorColor};
  border-radius: 50%;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 0px 2px white;

  font-weight: 700;
  font-size: 8px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
`;

const OptionsWrapper = styled.div`
  width: 208px;
  position: absolute;
  top: 43px;
  right: 13px;
  @media ${theme.breakpoints.mobile} {
    width: calc(100% - -60px);
    right: 0px;
  }
`;

const AccountWrapper = styled.div`
  margin-top: 12px;
  margin-right: 10px;
  margin-left: 15px;
  @media ${theme.breakpoints.mobile} {
    margin-left: 5px;
  }
  cursor: pointer;
`;

const IconWrapper = styled.div`
  padding-top: 2px;
`;

export type HeaderProps = {
  onPressNotif: () => void;
  onPressAccount: () => void;
  onPressTitle: () => void;
  hideNotifAction?: boolean;
  hideAccountAction?: boolean;
  accountProfileImage?: string;
  isLoadingProfileImage: boolean;
};

const Component = ({
  onPressNotif,
  onPressAccount,
  onPressTitle,
  hideNotifAction = false,
  hideAccountAction = false,
  accountProfileImage,
  isLoadingProfileImage,
}: HeaderProps): React.ReactElement => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    useCurrentUser: { currentUser },
  } = useGlobalState();
  const { useCheckNotificationCount } = useNotificationHooks();
  const { checkNotificationCount } = useCheckNotificationCount();
  const [hasNewNotif, setHasNewNotif] = useState(false);
  const [openAccountOptions, setOpenAccountOptions] = useState(false);
  const currPathName = location.pathname;

  const {
    data: notificationCount,
    isLoading,
    refetch: refetchCount,
  } = useQuery(['notification-count'], () => {
    if (!currentUser?.companyId || !currentUser?.userId) {
      throw new Error(`'company id' not set`);
    }

    return checkNotificationCount({
      companyId: currentUser.companyId,
      userId: currentUser.memberId,
    });
  });

  useEffect(() => {
    if (!process.env.REACT_APP_PUSHER_APP_KEY) {
      throw new Error('Pusher not set');
    }

    const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
      cluster: process.env.REACT_APP_PUSHER_CLUSTER,
    });
    const channel = pusher.subscribe('notification-channel');
    channel.bind('add-notification', ({ data }: any) => {
      if (
        data.approver_id === currentUser?.memberId ||
        data.applicant_id === currentUser?.memberId
      ) {
        refetchCount();
      }
      return data;
    });
  }, []);

  const accountRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const checkIfClickedOutside = (event: any) => {
      if (
        openAccountOptions &&
        accountRef.current &&
        !accountRef.current.contains(event.target)
      ) {
        setOpenAccountOptions(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [openAccountOptions]);

  useEffect(() => {
    if (
      currPathName === '/notifications' &&
      notificationCount &&
      notificationCount.notification_count > 0
    ) {
      const clearNotificationBadge = setTimeout(() => {
        onPressNotif();
      }, 3000);
      return () => clearTimeout(clearNotificationBadge);
    }
  }, [currPathName, notificationCount]);

  useEffect(() => {
    if (notificationCount && notificationCount?.notification_count > 0)
      setHasNewNotif(true);

    const resetHasNewNotif = setTimeout(() => {
      setHasNewNotif(false);
    }, 1000);
    return () => clearTimeout(resetHasNewNotif);
  }, [notificationCount]);

  return (
    <StyledHeader aria-label="">
      <ClickableTitle>
        <Evercontest onClick={onPressTitle} />
      </ClickableTitle>
      <HeaderGlobalBar>
        {!hideNotifAction && (
          <NotifIcon hasNewNotif={hasNewNotif}>
            <NotifAction
              isActive={location.pathname === '/notifications'}
              onClick={onPressNotif}
              aria-label={words.notificationList}>
              <Notification />
              {isLoading ? (
                <></>
              ) : notificationCount?.notification_count &&
                notificationCount?.notification_count > 0 ? (
                <Badge>
                  {notificationCount.notification_count > 9
                    ? '9+'
                    : notificationCount.notification_count}
                </Badge>
              ) : (
                <></>
              )}
            </NotifAction>
          </NotifIcon>
        )}
        {!hideAccountAction && (
          <AccountWrapper ref={accountRef}>
            {isLoadingProfileImage ? (
              <LoadingImage width={'23'} height={'23'}>
                <Loading width={20} height={20} />
              </LoadingImage>
            ) : accountProfileImage ? (
              <div onClick={() => setOpenAccountOptions(true)}>
                <ImageDisplay
                  imageSrc={accountProfileImage}
                  isLoading={isLoadingProfileImage}
                  width={'23x'}
                  height={'23px'}
                  loaderHeight={20}
                  loaderWidth={20}
                />
              </div>
            ) : (
              <IconWrapper onClick={() => setOpenAccountOptions(true)}>
                <Account />
              </IconWrapper>
            )}
            <OptionsWrapper hidden={!openAccountOptions}>
              <AccountOptions
                onClickAccountConfig={() => {
                  setOpenAccountOptions(false);
                  navigate(`/account-info`);
                }}
                onClickLogout={() => {
                  setOpenAccountOptions(false);
                  onPressAccount();
                }}
              />
            </OptionsWrapper>
          </AccountWrapper>
        )}
      </HeaderGlobalBar>
    </StyledHeader>
  );
};

export default Component;
