import React, { useEffect, useState } from 'react';
import { ProgressBar } from 'react-bootstrap';
import './personalDayTemplates.scss';
import Option3Dots from 'assets/icons/optionThreeDotWhite.svg';
import { dateToString } from 'utils/common';
import { sphForDayPerson } from 'type/sph';

type PropType = {
  dateCalendar: Date;
  sphForDayData: sphForDayPerson;
};

export default function PersonalDayTemplates(props: PropType) {
  const { dateCalendar, sphForDayData } = props;
  return (
    <div className="personalDayTemplates">
      <div className="option-bar">
        <div className="day-title">{dateToString(dateCalendar)}</div>
        <div className="progress-bar">
          <ProgressBar
            animated
            now={parseInt(sphForDayData.sum_process_percent)}
            label={`${sphForDayData.sum_process_percent}%`}
            variant="success"
            className="progress"
          />
        </div>
        <div className="option" onClick={() => console.log('DOT')}>
          <img src={Option3Dots} />
        </div>
      </div>
      <div className="data-bar">
        <div className="working-time">
          稼働時間（計画/結果）：
          <span className="value-data">
            {sphForDayData.sum_time_expect} / {sphForDayData.sum_time_actual}
          </span>
        </div>
        <div className="number-of-product">
          獲得件数（結果）：
          <span className="value-data">{sphForDayData.sum_product_actual}</span>
          （取切：
          <span className="value-data">
            {sphForDayData.sum_number_of_withdrawals}
          </span>
          ＆ 前確：
          <span className="value-data">
            {sphForDayData.sum_pre_probable_number}
          </span>
          )
        </div>
        <div className="number-of-tosses">
          トス件数（結果）：{' '}
          <span className="value-data">
            {sphForDayData.sum_number_of_tosses}
          </span>
        </div>
      </div>
    </div>
  );
}
