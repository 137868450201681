import { getCurrentUser, isEmptyObject } from 'utils/common';

export default function authHeader(): object {
  const user = getCurrentUser();

  if (!isEmptyObject(user) && user.token.access) {
    return {
      Authorization: `JWT ${user.token.access}`,
    };
  } else {
    return {};
  }
}
