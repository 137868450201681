import React, { useState } from 'react';
import Select from 'react-select';
import CloseButton from 'assets/icons/close-button.svg';
import './changeGroupModal.scss';
import words from 'constants/words';
import { ListMember } from '../ListMember';
import { OrangeButton } from 'components/atoms/OrangeButton';
import { listGroupOptionType, listMemberGroupType } from 'type/member';

const options = [
  { value: 1, label: '第一Group' },
  { value: 2, label: '第2Group' },
  { value: 3, label: '第3Group' },
];

type PropType = {
  isShow: boolean;
  onHideModal: () => void;
  groupName: string;
  mode: number; // 1 for edit - 2 for delete
  listMember: listMemberGroupType | null;
  listGroup: listGroupOptionType;
  groupId: number;
  handleUpdateCurrentChoose: (_data: string) => void;
  currentGroupName: string;
  submitChecked: (_member_id: number, _checked: boolean) => void;
  memberSelected: number[];
  handleMoveMemberGroup: () => void;
  handleDeleteMemberGroup: () => void;
  current: number;
};

export default function ChangeGroupModal(props: PropType) {
  const {
    isShow,
    onHideModal,
    mode,
    groupName,
    listMember,
    listGroup,
    groupId,
    handleUpdateCurrentChoose,
    currentGroupName,
    submitChecked,
    memberSelected,
    handleMoveMemberGroup,
    handleDeleteMemberGroup,
    current,
  } = props;

  function newValueUpdate(data: string) {
    handleUpdateCurrentChoose(data);
  }

  function getDisableMode() {
    if (mode === 1) {
      return groupName === currentGroupName ? true : false;
    } else {
      return false;
    }
  }

  return (
    <div className={`batch-edit-member-pages ${isShow ? 'show' : 'hide'}`}>
      <div className="title-modal">
        <div className="group-name" style={{ display: 'none' }}>
          {groupName}
        </div>
        <div className="modal-title">
          {mode === 1 ? '所属グループ変更' : '一括削除'}
        </div>
        <div className="close-button" onClick={onHideModal}>
          <img src={CloseButton} />
        </div>
      </div>
      <div className="modal-content">
        <p
          className="group-title"
          style={{ display: mode == 1 ? 'block' : 'none' }}>
          グループ名 （必須）
        </p>
        {listGroup && groupId && mode == 1 ? (
          <Select
            value={listGroup.filter(
              option => option.value === groupId.toString(),
            )}
            options={listGroup}
            className="groups__selection"
            classNamePrefix="groups__selection"
            placeholder={
              <span className="placeholder-option">{words.groupName}</span>
            }
            isSearchable={false}
            onChange={e => newValueUpdate(e!.value)}
          />
        ) : (
          ''
        )}
      </div>
      <div className="list-member">
        <ListMember
          listType={2}
          onHandleUpdateProfile={() => {
            return;
          }}
          listMember={listMember}
          onHandleApproveMember={() => {
            return;
          }}
          onHandleRejectMember={() => {
            return;
          }}
          onHandleDeleteMember={() => {
            return;
          }}
          submitChecked={(member_id, checked) =>
            submitChecked(member_id, checked)
          }
          groupName={groupName}
          currentGroupName={currentGroupName}
          memberSelected={memberSelected}
          mode={mode}
          current={current}
        />
      </div>
      <hr />
      <div className="btnBox">
        <div className="cancelBtn" onClick={onHideModal}>
          キャンセル
        </div>
        <OrangeButton
          text={mode === 1 ? '変更' : '削除'}
          style={{ width: '144px', height: '42px' }}
          onClick={mode === 1 ? handleMoveMemberGroup : handleDeleteMemberGroup}
          disabled={getDisableMode()}
        />
      </div>
    </div>
  );
}
