import React, { MouseEventHandler, useState } from 'react';
import styled from 'styled-components';

import { Tooltip, TooltipProps } from 'carbon-components-react';

const TooltipWrapper = styled.div`
  .bx--tooltip__label {
    display: initial;
  }

  .bx--tooltip__label:focus {
    outline: none;
  }
`;

const StyledTooltip = styled(Tooltip)`
  font-size: 13px;

  &[data-floating-menu-direction='right'],
  &[data-floating-menu-direction='left'] {
    padding: 6px 16px;
    min-width: fit-content;
  }

  &[data-floating-menu-direction='right'] {
    margin-top: -5px;
  }

  &[data-floating-menu-direction='right'] .bx--tooltip__caret {
    top: 60%;
  }

  &[data-floating-menu-direction='left'] {
    margin-top: 5px;
  }

  &[data-floating-menu-direction='left'] .bx--tooltip__caret {
    top: 15%;
  }
`;

const TooltipTriggerWrapper = styled.div<{ $stopOpen?: boolean }>`
  cursor: ${({ $stopOpen }) => ($stopOpen ? 'inherit' : 'pointer')};
`;

export type Props = {
  triggerNode?: React.ReactNode;
  children?: React.ReactNode;
  wrapperClassName?: string;
  tooltipClassName?: string;
  triggerWrapperClassName?: string;
  direction?: TooltipProps['direction'];
  autoOrientation?: boolean;
  menuOffset?: TooltipProps['menuOffset'];
  align?: TooltipProps['align'];
  stopOpen?: boolean;
};

const Component = ({
  triggerNode,
  children,
  wrapperClassName,
  tooltipClassName,
  triggerWrapperClassName,
  direction,
  autoOrientation,
  menuOffset,
  align,
  stopOpen,
}: Props): React.ReactElement => {
  const [isTooltipOpen, setTooltipOpen] = useState(false);

  const handleTooltipOpen = (isOpen: boolean) => () => {
    if (!stopOpen) setTooltipOpen(isOpen);
  };

  const handleTriggerClickTooltip = (e: React.MouseEvent) => {
    if (!stopOpen) {
      e.stopPropagation();
      setTooltipOpen(true);
    }
  };

  return (
    <TooltipWrapper className={wrapperClassName}>
      <StyledTooltip
        className={tooltipClassName}
        showIcon={false}
        open={isTooltipOpen}
        triggerText={
          <TooltipTriggerWrapper
            className={triggerWrapperClassName}
            onClick={handleTriggerClickTooltip}
            onMouseEnter={handleTooltipOpen(true)}
            onMouseLeave={handleTooltipOpen(false)}
            $stopOpen={stopOpen}>
            {triggerNode}
          </TooltipTriggerWrapper>
        }
        direction={direction}
        autoOrientation={autoOrientation}
        menuOffset={menuOffset}
        align={align}>
        {children}
      </StyledTooltip>
    </TooltipWrapper>
  );
};

export default Component;
