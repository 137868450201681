import React, { useEffect, useState } from 'react';
import './member-header.scss';
import BackButton from 'assets/icons/back-button.svg';
import NotificationButton from 'assets/icons/notification.svg';
import DefaultUserImg from 'assets/icons/default-user.svg';
import { useNavigate } from 'react-router-dom';
import urls from 'constants/urls';
import { useLocation } from 'react-router';
import { getTitleName } from 'utils/common';

type PropType = {
  onClick: () => void;
};

export default function MemberHeader(props: PropType) {
  const { onClick } = props;
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div
      className={`member-header ${location.pathname === '/sph' ? 'sph' : ''}`}>
      <div className="back-button" onClick={() => navigate(-1)}>
        {location.pathname !== urls.home ? <img src={BackButton} /> : ''}
      </div>
      <div className="title-header">{getTitleName(location.pathname)}</div>
      <div className="user-header" onClick={onClick}>
        <img src={DefaultUserImg} />
      </div>
      <div className="notification-header">
        <img src={NotificationButton} />
      </div>
    </div>
  );
}
