import { object, string, ValidationError } from 'yup';
import * as Yup from 'yup';
import words from 'constants/words';
import { memberForgotInputErrorType } from 'type/member';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function getErrors(items: ValidationError[]) {
  if (!items) {
    return {};
  }

  return items.reduce((error: object, e: ValidationError) => {
    return {
      ...error,
      [`${e.path}`]: e.message,
    };
  }, {});
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export async function validateForgotInput(data: memberForgotInputErrorType) {
  const schema = object().shape({
    password: string()
      .min(6, words.passwordMin)
      .max(254, words.passwordMaxRequired)
      .required(words.memberSignupPasswordRequired),
    passwordConfirm: string()
      .required(words.memberSignupPasswordCfRequired)
      .oneOf([Yup.ref('password'), null], words.passwordMismatch),
  });

  const result = await schema
    .validate(data, { abortEarly: false })
    .catch(err => err);
  return getErrors(result.inner) as memberForgotInputErrorType;
}
