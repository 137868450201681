import React, { useState } from 'react';
import './stateButtonStyle.scss';

type PropType = {
  display: boolean;
  mode: number;
  handleChooseModeSee: (_mode: number) => void;
};

export default function StateButton(props: PropType) {
  const { display, mode, handleChooseModeSee } = props;
  return (
    <div className={`boxButonContainer ${display ? 'block' : 'hide'}`}>
      <div className="stateBtnContainer">
        <button
          className={`${mode === 0 ? 'stateBtn active' : 'stateBtn'} Ohs`}
          onClick={() => handleChooseModeSee(0)}>
          今日
        </button>
        <button
          className={mode === 1 ? 'stateBtn active' : 'stateBtn'}
          onClick={() => handleChooseModeSee(1)}>
          月
        </button>
        <button
          className={mode === 2 ? 'stateBtn active' : 'stateBtn'}
          onClick={() => handleChooseModeSee(2)}>
          週
        </button>
        <button
          className={mode === 3 ? 'stateBtn active' : 'stateBtn'}
          onClick={() => handleChooseModeSee(3)}>
          日
        </button>
      </div>
    </div>
  );
}
