import React from 'react';
import styled from 'styled-components';
import { theme } from '../../../config';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const ImageContainer = styled.div<{ imageUrl: string }>`
  display: flex;
  flex-direction: column-reverse;
  width: inherit;
  height: inherit;
  background: linear-gradient(
      180deg,
      rgba(8, 41, 103, 0.1) 0%,
      rgba(31, 51, 77, 0.5) 77.08%
    ),
    url(${props => props.imageUrl});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 0 80px 0 66px;
`;

const OrangeBar = styled.div`
  height: 6px;
  width: 144px;
  background: ${theme.colors.primaryButton1};
  margin: 0 0 15px 2px;
`;

const BannerText = styled.p`
  font-size: 36px;
  font-weight: 500;
  line-height: 42px;
  letter-spacing: 0px;
  color: #fbf7f4;
  display: inline-block;
  height: 26%;
  width: 571px;
`;

export type Props = {
  label?: string;
  imageUrl: string;
};

const Component = ({ label, imageUrl }: Props): React.ReactElement => {
  return (
    <Wrapper>
      <ImageContainer imageUrl={imageUrl}>
        <BannerText>{label}</BannerText>
        <OrangeBar />
      </ImageContainer>
    </Wrapper>
  );
};

export default Component;
