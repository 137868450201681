import { RankingPeriod } from '../../../domain/entities/rankings';
import RankingRepository from '../../ports/RankingRepository';

export type fetchRankingPeriodUseCase = (query: {
  companyId: number;
  rankingId: number;
  isActive: boolean;
  isVisible: boolean;
}) => Promise<RankingPeriod[]>;

export const buildFetchRankingPeriod = (dependencies: {
  rankingRepo: RankingRepository;
}): fetchRankingPeriodUseCase => {
  const { rankingRepo } = dependencies;

  const fetchRankingPeriod: fetchRankingPeriodUseCase = async query =>
    rankingRepo.fetchRankingPeriod(query);

  return fetchRankingPeriod;
};
