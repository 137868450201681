import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import { Grid, Row, Column } from 'carbon-components-react';

import { theme } from '../../../config';
import words from '../../../constants/words';

import { AnchorTag } from '../../atoms/AnchorTag';
import { Button } from '../../atoms/Button';
import { TextInput } from '../../atoms/TextInput';
import { SubmitLoading } from '../../molecules/SubmitLoading';
import LogoImage from '../../../assets/images/ever-contest.png';

const MainWrapper = styled.div`
  @media ${theme.breakpoints.mobile} {
    .bx--grid {
      padding: 0 23px;
    }

    .bx--row:nth-child(3) {
      margin-bottom: 8px;
    }

    .bx--text-input__field-wrapper[data-invalid] ~ .bx--form-requirement {
      font-size: 10px;
    }
  }
`;

const BottomWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 5%;
  @media ${theme.breakpoints.mobileAndTablet} {
    flex-direction: column-reverse;
    margin-top: 23px;
    padding: 0;
  }
`;

const NoteWrapper = styled.div`
  display: flex;
  @media ${theme.breakpoints.pc} {
    justify-content: start;
  }
  @media ${theme.breakpoints.mobileAndTablet} {
    margin-top: 8%;
  }
`;

const Note = styled.text`
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.16px;
  color: ${theme.colors.interactive02};
`;

const ButtonWrapper = styled.div`
  width: 198px;
  align-items: center;

  @media ${theme.breakpoints.mobile} {
    height: 38px;
    width: 100%;
    margin: 0 auto;
  }

  & button {
    @media ${theme.breakpoints.mobile} {
      height: 38px !important;
      font-size: 14px !important;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.15px;
    }
  }
`;

const LoadingWrapper = styled.div`
  align-items: center;
  height: 48px;
  @media ${theme.breakpoints.mobile} {
    justify-content: center;
    width: 100%;
    height: 38px;
    margin: 2rem 0 0;
  }
`;

const Spacer = styled.div`
  margin-bottom: 2.5rem;
  @media ${theme.breakpoints.mobile} {
    margin-bottom: 0;
  }
`;

const Logo = styled.div<{ imageUrl: string }>`
  background-image: url(${props => props.imageUrl});
  background-repeat: no-repeat;
  background-size: contain;
  height: 23px;
  margin-bottom: 5%;
  @media ${theme.breakpoints.mobile} {
    height: 18px;
  }
`;

const StyledText = styled.p`
  font-size: 28px;
  font-weight: 400;
  color: ${theme.colors.black};
  margin-bottom: 24px;

  @media ${theme.breakpoints.mobile} {
    font-size: 20px;
    font-weight:500;
    margin-bottom: 34px;
`;

const ButtonStyle = {
  height: 48,
  padding: 0,
};

export type ResetPasswordProps = {
  password?: string;
  password2?: string;
  onChangePassword: (event: ChangeEvent) => void;
  onChangePassword2: (event: ChangeEvent) => void;
  onPressSubmit: () => void;
  onPressLogin: () => void;
  passwordError?: string;
  password2Error?: string;
  isSubmitDisabled: boolean;
  isLoading?: boolean;
};

const Component = ({
  password,
  password2,
  onChangePassword,
  onChangePassword2,
  onPressSubmit,
  onPressLogin,
  passwordError,
  password2Error,
  isLoading,
  isSubmitDisabled,
}: ResetPasswordProps): React.ReactElement => {
  return (
    <MainWrapper>
      <Grid>
        <Row>
          <Column>
            <Logo imageUrl={LogoImage} />
          </Column>
        </Row>
        <Row>
          <Column>
            <StyledText>{words.setupNewPassword}</StyledText>
          </Column>
        </Row>
        <Row>
          <Column>
            <TextInput
              id={'password'}
              type={'password'}
              label={`${words.newPassword} ${words.requiredLabel}`}
              value={password}
              onChange={onChangePassword}
              showPasswordLabel={words.showPassword}
              hidePasswordLabel={words.hidePassword}
              invalid={!!passwordError}
              invalidText={passwordError}
              disabled={isLoading}
            />
          </Column>
        </Row>
        <Spacer />
        <Row>
          <Column>
            <TextInput
              id={'password2'}
              type={'password'}
              label={`${words.newPasswordConfirm}${words.requiredLabel}`}
              value={password2}
              onChange={onChangePassword2}
              showPasswordLabel={words.showPassword}
              hidePasswordLabel={words.hidePassword}
              invalid={!!password2Error}
              invalidText={password2Error}
              disabled={isLoading}
            />
          </Column>
        </Row>
        <BottomWrapper>
          <NoteWrapper>
            <Note>{words.alreadyHaveAccount}</Note>
            <AnchorTag onPress={onPressLogin} title={words.alreadyLogin} />
          </NoteWrapper>
          {isLoading ? (
            <LoadingWrapper>
              <SubmitLoading text={words.submitting} />
            </LoadingWrapper>
          ) : (
            <ButtonWrapper>
              <Button
                style={ButtonStyle}
                onPress={onPressSubmit}
                title={words.submit}
                disabled={isSubmitDisabled || !!!password || !!!password2}
              />
            </ButtonWrapper>
          )}
        </BottomWrapper>
      </Grid>
    </MainWrapper>
  );
};

export default Component;
