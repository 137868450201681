import { Company, UpdateCompany } from '../../../domain/entities/company';
import CompanyRepository from '../../ports/CompanyRepository';

export type updateCompanyUseCase = (
  companyId: number,
  params: UpdateCompany,
) => Promise<Company>;

export const buildUpdateCompany = (dependencies: {
  companyRepo: CompanyRepository;
}): updateCompanyUseCase => {
  const { companyRepo } = dependencies;

  const updateCompany: updateCompanyUseCase = (identifiers, params) =>
    companyRepo.update(identifiers, params);

  return updateCompany;
};
