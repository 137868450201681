import React, { useCallback } from 'react';
import { useMutation } from 'react-query';
import { Formik, FormikHelpers } from 'formik';

import { AddEditMemberSchema } from './validation';

import words from '../../../constants/words';

import { CreateMemberType, UserRoles } from '../../../domain/entities/member';
import { Member } from '../../../domain/entities/member';
import { Group } from '../../../domain/entities/group';

import { useMemberHooks } from '../../../hooks/member';

import { Modal } from '../../molecules/Modal';
import { SetMemberForm } from '../../organisms/SetMemberForm';
import { SetMemberFormType } from '../../organisms/SetMemberForm/SetMemberForm';
import { Props as INotification } from '../../molecules/Notification/Notification';

export type Props = {
  isOpen: boolean;
  companyId: number;
  onClose: () => void;
  onSubmit: (
    newMember?: Member & { temporary_password: string },
    toast?: INotification,
  ) => void;
  editValues?: SetMemberFormType;
  isEdit?: boolean;
  groupList?: Group[];
  currentEditorRole: UserRoles;
};

const Component = ({
  isOpen,
  companyId,
  onClose,
  onSubmit,
  editValues,
  isEdit = false,
  groupList = [],
  currentEditorRole,
}: Props): React.ReactElement => {
  const { useCreateMember } = useMemberHooks();

  const { createMember } = useCreateMember();

  const initialValues: SetMemberFormType = editValues || {
    memberName: '',
    memberGroupId: 0,
    memberRoleId: '',
    memberUserId: undefined,
    memberEmail: '',
    memberFields: [],
  };

  const {
    mutate: mutateCreateMember,
    isLoading: createMemberLoading,
  } = useMutation((params: CreateMemberType) => createMember(params), {
    onSuccess: newMember => {
      onSubmit(newMember, {
        kind: 'success',
        title: words.addMemberToastTitleSuccess,
        subtitle: words.addMemberToastSubSuccess,
      });
      onClose();
    },
    onError: () => {
      onSubmit(undefined, {
        kind: 'error',
        title: words.updateToastTitleError,
        subtitle: words.errorOccuredToastSub,
      });
      onClose();
    },
  });

  const submitModal = useCallback(
    (
      values: SetMemberFormType,
      { resetForm }: FormikHelpers<SetMemberFormType>,
    ) => {
      const newMember: CreateMemberType = {
        name: values.memberName,
        companyId: companyId,
        groupId: values.memberGroupId,
        roleName: values.memberRoleId,
      };
      mutateCreateMember(newMember, {
        onSuccess: () => {
          resetForm();
        },
      });
    },
    [],
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={AddEditMemberSchema}
      onSubmit={submitModal}
      isInitialValid={isEdit}>
      {({
        handleChange,
        handleSubmit,
        setFieldValue,
        values,
        errors,
        isValid,
        resetForm,
      }): React.ReactElement => {
        const {
          memberName,
          memberGroupId,
          memberRoleId,
          memberUserId,
          memberEmail,
          memberFields,
        } = values;

        const handleClose = useCallback(() => {
          resetForm();
          onClose();
        }, []);

        return (
          <Modal
            open={isOpen}
            title={isEdit ? words.editMember : words.modalAddMember}
            label={isEdit ? words.updateMemberInfo : words.addNewMemberInfo}
            confirmButtonText={words.save}
            onConfirm={handleSubmit}
            onCancel={handleClose}
            onClose={handleClose}
            confirmButtonDisabled={!isValid || createMemberLoading}
            hasScrollingContent={isEdit}>
            <SetMemberForm
              memberName={memberName}
              currentEditorRole={currentEditorRole}
              memberGroupId={memberGroupId}
              memberRoleId={memberRoleId}
              memberUserId={memberUserId}
              memberEmail={memberEmail}
              memberFields={memberFields}
              groupList={groupList}
              companyId={companyId}
              onChangeMemberName={handleChange('memberName')}
              onChangeMemberGroup={(id: number) =>
                setFieldValue('memberGroupId', id)
              }
              onChangeMemberRole={handleChange('memberRoleId')}
              errors={errors}
              isEdit={isEdit}
            />
          </Modal>
        );
      }}
    </Formik>
  );
};

export default Component;
