import CompanyRepository from '../../ports/CompanyRepository';

export type resendVerificationEmailUseCase = (
  companyId: string,
) => Promise<void>;

export const buildResendVerificationEmail = (dependencies: {
  companyRepo: CompanyRepository;
}): resendVerificationEmailUseCase => {
  const { companyRepo } = dependencies;

  const resendVerificationEmail: resendVerificationEmailUseCase = companyId =>
    companyRepo.resendEmailVerification(companyId);

  return resendVerificationEmail;
};
