import AuthService from '../../ports/AuthService';

export type forgetPasswordUseCase = (
  email: string,
  email_token?: string,
) => Promise<object | null>;

export const buildForgetPassword = (dependencies: {
  authService: AuthService;
}): forgetPasswordUseCase => {
  const { authService } = dependencies;

  const forgetPassword: forgetPasswordUseCase = (
    email: string,
    email_token?: string,
  ) => authService.forgetPassword(email, email_token);

  return forgetPassword;
};
