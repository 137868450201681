import AuthService from '../../ports/AuthService';

export type verifyAdminEmailUseCase = (email: string) => Promise<object | null>;

export const buildVerifyAdminEmail = (dependencies: {
  authService: AuthService;
}): verifyAdminEmailUseCase => {
  const { authService } = dependencies;

  const verifyAdminEmail: verifyAdminEmailUseCase = email =>
    authService.verifyAdminEmail(email);

  return verifyAdminEmail;
};
