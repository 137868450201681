import styled from 'styled-components';

import { theme } from '../../../config';
import { AnchorTag } from '../../atoms/AnchorTag';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  min-height: 100vh;
  background-color: ${theme.colors.ui01};
  @media ${theme.breakpoints.mobileAndTablet} {
    grid-template-columns: 100%;
  }
`;

export const TextHeader = styled.div`
  display: flex;
  margin-bottom: 5%;
  flex-direction: column;
  justify-content: space-between;
  @media ${theme.breakpoints.mobileAndTablet} {
    margin-bottom: 8%;
  }
`;

export const LoginTitle = styled.div`
  font-size: 28px;
  line-height: 36px;
  color: ${theme.colors.blackText};
  margin-bottom: 1em;
  @media ${theme.breakpoints.mobile} {
    font-size: 20px;
    line-height: 26px;
    font-weight: 500;
  }
`;

export const Subtitle = styled.p`
  line-height: 18px;
  font-size: 13px;
  white-space: pre-wrap;
`;

export const Logo = styled.div<{ imageUrl: string }>`
  background-image: url(${props => props.imageUrl});
  background-repeat: no-repeat;
  background-size: contain;
  height: 23px;
  margin-bottom: 5%;
  @media ${theme.breakpoints.mobile} {
    height: 18px;
  }
`;

export const BottomWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media ${theme.breakpoints.mobileAndTablet} {
    flex-direction: column-reverse;
    margin-top: 4%;
  }
`;

export const ButtonWrapper = styled.div`
  width: 141px;
  height: 38px;
  @media ${theme.breakpoints.mobileAndTablet} {
    width: 100%;
    height: 100%;
  }
`;

export const LogoutButton = styled(AnchorTag)`
  @media ${theme.breakpoints.mobileAndTablet} {
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 25px;
  }
`;

export const InputWrapper = styled.div`
  margin-bottom: 19px;
  @media ${theme.breakpoints.mobile} {
    margin-bottom: 4px;
  }
`;

export const FileInputWraper = styled.div`
  ${InputWrapper};
  margin-bottom: 19px;
  @media ${theme.breakpoints.mobile} {
    min-height: 114px;
    margin-bottom: 4px;
  }
`;

export const Column = styled.div<{ isBanner?: boolean }>`
  padding: ${props => (props.isBanner ? '0' : '60px 5%')};
  @media ${theme.breakpoints.mobileAndTablet} {
    ${props => props.isBanner && 'display: none'};
  }
  @media ${theme.breakpoints.mobile} {
    padding: ${props => (props.isBanner ? '0' : '28px 0')};
  }
`;
