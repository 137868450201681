export default {
  actions: 'Actions',
  requiredMark: '*Required',

  // Login Screen
  todoApp: 'Todo App',
  employeeNumber: 'Employee number',
  password: 'Password',
  login: 'Login',
  registerHere: 'Register here',
  forgotPassword: 'Forgot Password?',
  showPassword: 'Show Password',
  hidePassword: 'Hide Password',
  companyId: 'Company ID',
  userId: 'User ID',
  noAccountYet: 'Setup new company account.',
  everContest: 'Ever Contest',
  everContestLogin: 'Login to Ever Contest',
  bannerLabel:
    'Increase company sales by increasing employees motivation for work.',

  //Forgot Password Screen
  forgotPassInstruction:
    'Please enter your registered email address to reset your password.',
  alreadyHaveAnAccount: 'Already have an account?',
  sendResetLink: 'Send Reset Link',
  forgotPasswordTitle: 'Forgot Password',
  setupNewPassword: 'Setup new password',
  successSent: 'Success',
  backToLogin: 'Back to login',
  linkSent: ' Password reset link has been sent to your email',
  changedPassword: 'You have successfully changed your password.',
  submitting: 'Submitting...',
  forgotPasswordError: 'Error',
  resetPasswordLinkExpired:
    'Either the link had already expired or the link was already used to change the password. Please enter your email address above to request a new password link.',

  // Admin Registration
  register: 'Register',
  confirmPassword: 'Confirm New Password',
  emailAdd: 'Email Address',
  groupLabel: 'Group',
  roleLabel: 'Role',
  passwordLabel: 'New Password',
  passwordMin: 'Atleast 6 half-width letters or numbers',
  passwordMismatch: "Password didn't match, please enter again.",
  emailNotif: 'Enable email notifications for important updates',
  profilePic: 'Profile Picture',
  optional: '(Optional)',
  fileInputLabel:
    'Only .jpg and .png files. 500kb max file size.\nYou can upload your profile picture later.',
  fileInputDesc: 'Drag and drop files here or upload',
  fileErrorMsg: 'Only accepts 500kb max file size.',
  unsupportedFileFormat: 'Unsupported file format',
  welcomeMsg: (name: string): string => `Welcome, ${name}!`,
  descMsg: (companyName: string): string =>
    `Let’s finish creating your account for ${companyName}.\nFill out the rest of the form to complete your registration.`,
  fileInputDescDefault: 'Drag and drop files here or upload',
  accountCreated: 'Success',
  successfullyCreated: 'You have successfully created an account.',

  // Change Password Screen
  pleaseSetNewPassword: 'Please set the new password',
  newPassword: 'New Password',
  newPasswordConfirm: 'Confirm New Password',
  change: 'Change',
  changePassword: 'Change password',
  member: 'Member',
  substituteRecord: 'Substitute',
  submit: 'Submit',

  // Change Temporary Password Modal
  changeTemporaryPassword: 'Change password',
  permanentNewPassword: 'New Password (Required)',
  permanentNewPasswordConfirm: 'Confirm New Password (Required)',
  youLoggedWithATempPassword:
    'You logged in with a temporary password. Please enter a new and permanent password.',
  submitTemporaryPassword: 'Save',

  // Home Screen
  home: 'Home',
  offDuty: 'Off-duty',
  onDuty: 'On-duty',
  constructionNumber: 'Client number construction',
  constructionSubject: 'Construction subject',
  start: 'Start',
  modify: 'Modify',
  finish: 'Finish',
  cancel: 'Cancel',
  confirm: 'Confirm',
  confirmAttendance: 'The work schedule will be marked as follows. Is it okay?',
  all: 'All',
  edit: 'Edit',
  delete: 'Delete',
  move: 'Move',

  // Member Screen
  addMember: 'Add Member',
  editMemberButton: 'Edit',
  itemsPerPage: 'Records per page',
  pagination: (min: number, max: number, total: number): string =>
    `${min}-${max} of ${total} Records`,
  pageNumber: ['of', 'pages'],
  save: 'Save',
  modalAddMember: 'Add member',
  addNewMemberInfo: 'Add new member information',
  editMember: 'Edit member',
  updateMemberInfo: 'Update member information',
  name: 'Name',
  group: 'Group',
  role: 'Role',
  emailAddress: 'Email Address',
  emailAddOptional: 'User Email Address (Optional)',
  remark: 'Remark',
  editMemberSaveButton: 'Save',
  memberListHeaders: {
    name: 'Name',
    group: 'Group',
    role: 'Role',
    actions: 'Actions',
    action: 'Action',
  },
  groupFilter: 'Group',
  groupFilterMemberRanking: 'Group',
  roleFilter: 'Role',
  deleteMemberConfirmation: 'Delete member confirmation',
  sureToDeleteMember: 'Are you sure you want to delete this member?',
  resetPassword: 'Reset Password',
  temporaryPassword: 'Temporary Password',
  searchPlaceholder: 'Search member',
  updateMemberToastTitleSuccess: 'Success',
  updateMemberToastSubSuccess: 'Member record has been successfully updated.',
  addMemberToastTitleSuccess: 'Success',
  addMemberToastSubSuccess: 'Member record has been successfully added.',
  successDeleteMember: 'Success',
  deleteMemberToastSuccess: 'Selected member has been deleted.',
  members: 'Members',
  clearFilters: 'Clear Filters',
  back: 'Back',
  listing: 'Listing',
  setting: 'Setting',
  editMemberSubHeader: 'Edit Member',
  viewMemberSubHeader: 'View Member',
  generateNewPassword: 'Generate new temporary password',
  generateNewPasswordTooltip:
    'Are you sure you want to reset the password of this member?',
  successGeneratePassword: 'Success',
  tempPasswordSuccess: 'Temporary password successfully generated',
  sendTempPassword: 'You can send the temporary password to the user.',
  yesGeneratePassword: 'Yes',
  searchMember: 'Search Member',
  memberSelected: '1 member selected',
  membersSelected: ' members selected',
  memberSelectedSingular: ' member selected',
  moveMemberModalTitle: 'Move Members',
  moveMemberModalSubtitle: 'Select group to move selected members',
  moveMemberConfirmation: 'Move Members',
  iCopiedIt: 'Copied!',
  memberDeletionInlineTitleError: 'Deletion error',
  memberDeletionInlineApproverSubError:
    'At least 1 approver need to be set in the group, please assign a new approver before deleting.',
  memberDeletionInlineAdminSubError:
    'At least 1 admin need to be set in the company, please assign a new admin before deleting.',
  viewMemberDeleteButton: 'Delete',
  unassignedGroup: 'Unassigned',
  moveMemberTitleSuccess: 'Success',
  moveMemberSubtitleSuccess: 'Selected members has been moved.',
  editMemberRoleLastApproverError:
    'Cannot change the role of the only approver to a group.\nPlease set other approvers and change their permissions.',
  editMemberRoleLastAdminError:
    'Cannot change the role of the only admin in the company',
  adminDeleteTooltipMessage:
    'You cannot delete\nthis Admin.\n\nAt least 1 admin\nneeds to be set in the\ncompany, please\nassign a new admin\nbefore deleting.',
  approverDeleteTooltipMessage:
    "You cannot delete\nthis member as the\nmember is the only\napprover on it's\ngroup.\n\nAdd at least 1\napprover to the\nmember's group in\norder to delete this\nmember.",
  emptyMemberListTitle: '検索結果がありません', // no english translation
  emptyMemberListDetails: '検索内容を変更して再度検索してください。', // no english translation

  // Roles
  admin: 'Admin',
  approver: 'Approver',

  // Group Screen
  addGroup: 'Create Group',
  number: 'No.',
  groupName: 'Group Name',
  approvers: 'Approvers',
  membersCount: 'Members Count',
  groups: 'Groups',
  deleteGroup: 'Delete group',
  sureToDelete: 'Are you sure you want to delete this group?',
  yesDelete: 'Yes, Delete',
  plusMore: (num: number): string => `+${num} more`,
  groupNameAlreadyTaken: 'Group name already taken',

  // Add Group Modal
  addGroupModal: 'Add Group',
  addSaveButton: 'Save',
  addLabelModal: 'Create a group for members',
  addInvalidTextGroupName: 'Group name is required',
  addLabelInput: 'Group Name (Required)',
  addPlaceholderInput: 'Add group name',
  addLabelMultiSelect: 'Approvers (Required)',
  addPlaceholderMultiSelect: 'Select group approvers',
  addErrorMessageMultiSelect: 'Approvers are required',
  errorMessageMultiSelect: `You cannot delete this member as the member is the only approver on it's group. Add at least 1 approver to the member's group in order to delete this member.`,
  errorMessageDeleteLastGroup: `You cannot delete the last remaining group.`,
  labelDeleteAnchor: 'Delete',

  // Edit Group Modal
  editGroup: 'Edit Group',
  editSaveButton: 'Save',
  editGroupLabel: 'Update Group Information',
  editInvalidTextInput: 'Group name is required',
  editLabelInput: 'Group Name (Required)',
  editPlaceholderInput: 'Add group name',
  editLabelMultiSelect: 'Approvers (Required)',
  editPlaceholderMultiSelect: 'Select group approvers',
  editErrorMessageMultiSelect: 'Approvers are required',

  // Errors
  required: 'This field is required',
  requiredLabel: '(Required)',
  invalidCompanyIdFormat: 'Invalid company ID format.',
  invalidUserIdFormat: 'Invalid user ID format.',
  invalidPasswordFormat: 'Invalid password format.',
  invalidEmailFormat: 'Enter valid email address.',
  passwordInvalid: 'Password is too short.',
  loginErrorTitle: 'Login error',
  loginError: 'Incorrect IDs or password',
  changePassNotMatchError: 'Passwords do not match.',
  changePassCaseError:
    'Use at least one lowercase letter and one half-size number each.',
  somethingWentWrong: 'Something went wrong. Please retry.',
  updateToastTitleError: 'Error',
  errorOccuredToastSub: 'An error occured, please try again later.',
  error500kbMax: 'Profile Picture only accepts 500kb max file size.',
  error500PageSubHeader: '500 - Server Error',
  error500PageTitle: 'Internal Server Error',
  error500PageDetails:
    'Something went wrong at our end.\nPlease be patient and try again later.',
  error500PageActionBtnTitle: 'Reload',
  error404PageSubHeader: 'Error 404',
  error404PageTitle: 'Page Not Found',
  error404PageDetails:
    'The page you are looking for was moved, removed,\nrenamed or might never existed.',
  error404PageActionBtnTitle: 'Go Home',
  errorLinkExpiredSubHeader: 'Link Expired',
  errorLinkExpiredTitle: ' Verification Link Has Expired',
  errorLinkExpiredPageDetails:
    'Sorry, the email verification link has expired. Please request for new link and try again.',
  errorLinkExpiredPageActionBtnTitle: 'Back To Home',

  // Login Setting
  addField: 'Add Field',
  fieldName: 'Field Name',
  fieldSetting: 'Field Settings',
  fieldVisibility: 'Visibility',
  mandatory: 'Mandatory',
  optional2: 'Optional',
  shown: 'Shown',
  hidden: 'Hidden',
  default: 'Default',
  deleteFieldConfirmation: 'Delete member information field',
  deleteModalSubtitle: 'Are you sure you want to delete this field?',
  deleteField: 'Yes, Delete',
  fieldNameAlreadyTaken: 'Name has already been taken',
  fieldNameIsRequired: 'Name is required',
  fieldSuccessTitle: 'Success',
  addFieldSuccessMsg: 'A record has been successfully added.',
  updateFieldSuccessMsg: 'A record has been successfully updated.',
  deleteFieldSuccessMsg: 'A record has been successfully deleted.',
  maxFieldsReached:
    'You’ve already reached the maximum number of fields. Delete fields to add new one.',
  roleLoginSetting: 'Role',
  keep: 'Save',
  emailSetting: 'ユーザメールアドレス',
  profileSetting: 'プロフィール画像',

  // Admin Sign Up
  createAnAccount: 'Create an account',
  companyName: 'Company Name',
  representativeName: 'Representative Name',
  representativeEmailAddress: 'User Email Address',
  companyTelephone: 'Company Telephone',
  alreadyHaveAccount: 'Already have an account?',
  alreadyLogin: 'Login',
  enterValidEmail: 'Enter valid email address.',
  onlyHalfWidthNumbers: 'Only half-width numbers are accepted.',
  validEmailAddress: 'Please enter a valid email address.',

  //Ranking List
  addMemberRanking: 'Add Members To Ranking',
  memberInRanking: 'Members in this ranking',
  selectGroup: 'Select Group',
  selectMembers: 'Select Members',
  addMemberInfo: 'You can add more members from other groups',
  confirmRanking: 'Confirm Ranking Details',
  createRanking: 'Create Ranking',
  saveRankingMember: 'Save',
  noGroup: 'Unassigned',

  // Navigation Items
  record: 'Record',
  recordApplication: 'Record Application',
  recordReference: 'Record Reference',
  recordApproval: 'Record Approval',
  ranking: 'Ranking',
  rankingTable: 'Ranking Table',
  graph: 'Graph',
  manageRanking: 'Manage Ranking',
  rankingList: 'Ranking List',
  config: 'Config',
  groupManagement: 'Group Management',
  memberManagement: 'Member Management',
  companyInfo: 'Company Info',
  companyInformation: 'Company Information',
  payment: 'Payment',
  accountConfig: 'Account Config',

  // Application Sent
  successfulSignUp: `Great! You've successfully signed up for Ever Contest.`,
  sentLink: (
    email: string,
  ): string => `We've sent you a link to ${email} to confirm your company email address. 
  Please check your inbox.`,
  sentLink1: `We've sent you a link to `,
  sentLink2: ' to confirm your company email address. Please check your inbox.',

  // User Profile
  userEmailAddress: 'User Email Address',

  success: 'Success',
  accountUpdated: 'Success',
  successfullyUpdated: 'A record has been successfully updated.',
  successfullyDeleted: 'A record has been successfully deleted.',
  successfullyAdded: 'A record has been successfully added.',
  successfullyAddedGroup: 'A group has been successfully added.',
  successfullyUpdatedGroup: 'A group has been successfully updated.',
  error: 'Error',
  updateError: 'Error',
  errorOccured: 'An error occured, please try again later.',

  index: 'Index',
  dataType: 'Data Type',
  columnName: 'Column Name',
  userInfo: 'User Info',
  decimal: 'Decimal',
  duplicate: 'Duplicate',
  disable: 'Disable',
  activate: 'Activate',
  addRankingColumn: 'Add Ranking Column',
  createRankingName: 'Create Ranking Name',
  editRankingName: 'Edit Ranking Name',
  pleaseEnterRankingName: 'Please enter the ranking name for this ranking.',
  rankingName: 'Ranking Name',
  rankNameUnique: 'Ranking name should be unique',
  activeRanking: 'Active Ranking',
  disabledRanking: 'Disabled Ranking',
  createNewRanking: 'Create New Ranking',
  findActiveRankings:
    'You will find the active rankings here. You can edit each steps or disable them.',
  findDisabledRankkings:
    'You will find the disabled rankings here. You can reactivate or delete them.',
  no: 'No',
  yes: 'Yes',
  editBasic: 'Edit Basic',
  editMembers: 'Edit Members',
  editPeriod: 'Edit Period',
  editUserProfile: 'Edit',

  // Ranking List
  rankingListHeaders: {
    no: 'No',
    name: 'Ranking Name',
    index: 'Index',
    action: 'Actions',
  },
  recordNewApplication: 'Record New Application',
  publish: 'Publish',
  changeSettingNow: 'Change setting now',
  setChangeSchedule: 'Set change setting’s date and time',
  date: 'Date',
  chooseTime: 'Choose a time',
  changelogs: 'Change Logs',
  activeColumns: 'Active Columns',
  disabledColumns: 'Disabled Columns',
  remove: 'Remove',
  removeRankingList: 'Remove',
  rankingAppListGroupApproverErrorTitle: 'Warning',
  rankingAppListApproverErrorSubtitle: `You can't apply\n because you're the only group\n approver, assign additional\n approver in your group.`,
  rankingAppListGroupErrorSubtitle: `You can’t record an\n application since you’re not\n assigned to a group, contact your\n admin to proceed with the\n application.`,

  // Record Reference
  recordReferenceListHeaders: {
    no: 'No',
    date: 'Application Date',
    name: 'Ranking Name',
    mainApprover: 'Main Approver',
    status: 'Status',
  },
  recordRefStatus: {
    unapproved: 'unapproved',
    approved: 'approved',
    rejected: 'rejected',
    withdraw: 'cancelled',
  },
  dropdownAll: 'All',
  disableRanking: 'Are you sure you want to disable this ranking?',
  deleteRanking: 'Are you sure you want to delete this ranking?',
  editBasicInfo: 'Edit Basic Info',
  updatedBasicInfo: [
    'This is the updated basic info of the ranking set by approvers/admins. To view the current ranking basic info. Click ',
    'here',
    '.',
  ],
  statusFilterValues: [
    {
      id: '',
      label: 'All',
    },
    {
      id: 'unapproved',
      label: 'Unapproved',
    },
    {
      id: 'approved',
      label: 'Approved',
    },
    {
      id: 'rejected',
      label: 'Rejected',
    },
    {
      id: 'withdraw',
      label: 'Withdraw',
    },
  ],
  deleteRankingApplicationTitle: 'Delete ranking application',
  deleteRankingApplicationSubtitle:
    'Are you sure you want to delete this ranking application?',
  deleteApplicationButton: 'Delete',
  withdrawApplicationButton: 'Withdraw',
  cancelApplicationButton: 'Cancel application',
  withdrawApplicationTooltipYes: 'Yes',
  reapplyApplicationTooltipYes: 'Yes',
  recordReferencePageHeader: 'Record Reference',
  recordReferenceEmptyTitle: 'No applications recorded',
  recordReferenceEmptyDetails:
    'You haven’t recorded an application for a ranking.\nYour applications will be shown here.',
  withdrawalCompleted: 'Success',
  referenceWithdrawSuccess:
    'Ranking application has been successfully withdrawn.',
  referenceCancelSuccess: 'Ranking application has been successfully cancel.',
  reapplyCompleted: 'Reapplication success',
  reapplySuccess: 'Ranking application has been successfully reapplied.',
  referenceDeleted: 'Deletion success',
  referenceDeletedSuccess: 'Ranking application has been successfully deleted.',
  manageApplicationFilteredEmptyTitle: 'No results found',
  manageApplicationFilteredEmptyDetails: 'Please try another filters.',

  // Record Application
  apply: 'Apply',
  recordAppTooltipConfirm: 'Yes',
  applicationDate: 'Application Date',
  salesDate: 'Sales Date',
  calling: 'Calling',
  appointment: 'Appointment',
  totalSalesIndex: 'Total Sales (Index)',
  mainApprover: 'Main Approver',
  subApproversOptional: 'Sub Approvers (optional)',
  subApproverPlaceholder: 'Search sub approver',
  notesOptional: 'Notes (optional)',
  onlyNumericAreAccepted: 'Only numeric values are accepted',
  onlyHalfNumbersAccepted: 'Only half-width numbers are accepted.',
  details: 'Details',
  addRecordAppToastTitleSuccess: 'Success',
  addRecordAppToastSubSuccess:
    'Ranking application\n record has been\n successfully added.',
  addRecordAppToastTitleError: 'Error',
  addRecordAppToastSubError: 'Can’t save application.\n Please try again.',
  rankingAppListPageHeader: 'Ranking Applications',
  rankingAppListEmptyTitle: 'No ranking applications',
  rankingAppListEmptyDetails:
    'No ranking applications has been assigned to you yet.\nYour ranking applications will be shown here.',
  cancelTooltipNo: 'No',

  //AddPeriod Table
  rankingPeriodName: 'Ranking Period Name',
  startDate: 'Start Date',
  endDate: 'End Date',
  visibility: 'Visibility',
  addRankingPeriod: 'Add Ranking Period',
  deleteRankingPeriod:
    'Are you sure you want to remove this ranking period from this ranking?',
  savePeriod: 'Save',
  invalidText: 'Field is requried',
  invalidDate: 'Field is requried',
  deletePeriod: 'Yes',
  deleteTooltipRankingPeriod: 'Yes',
  deleteLastPeriod: 'You cannot delete the last remaining period.',

  // Record Approval List
  myMainApprover: 'My Main Approval',
  secondary: 'Secondary',
  applicant: 'Applicant',
  status: 'Status',
  tooltipApplyMessage: 'Are you sure you want to apply on this ranking?',
  mainApprovalEmpty: 'No main approval applications',
  mainApprovalEmptyDetails:
    'No ranking applications to approve here.\nMain ranking approval applications will be shown here.',
  secondaryApprovalEmpty: 'No secondary approval applications',
  secondaryApprovalEmptyDetails:
    'No ranking applications to approve here.\nSecondary ranking approval applications will be shown here.',
  noFilterResults: 'No filter results',
  noFilterResultsDetails:
    'Please select a ranking and ranking period to show results.',
  noFilterResultsDetailsMobile:
    'Please select a ranking and ranking\n period to show results.',

  // Approval Status
  unapproved: 'Unapproved',
  approved: 'Approved',
  rejected: 'Rejected',
  withdraw: 'Withdraw',
  cancelApplication: 'Cancel Application',

  // Reject Application Modal
  rejectApplication: 'Reject Application',
  rejectApplicationReason: 'Tell the reason why you rejected this application',
  rejectApplicationReasonMobile:
    'Tell the reason why you\nrejected this application',
  reasonOptional: 'Reason (Optional)',
  submitRejection: 'Submit',

  // Reject/Approve Application
  reject: 'Reject',
  approve: 'Approve',
  notes: 'Notes',
  tooltipApproveMessage: 'Are you sure you want to approve this application?',
  unapprovedUppercase: 'Unapproved',
  transHistDate: 'Date',
  transHistAction: 'Action',
  yesApprove: 'Yes',
  mainApproverRequired: 'Main Approver',
  approvalSuccess: 'Success',
  approvalSuccessMessage: 'Ranking application has been approved.',
  approvalRejected: 'Success',
  approvalRejectedMessage: 'Ranking application has been rejected.', // no translation yet
  approvalError: 'Error',
  approvalErrorMessage: 'An error occured, please try again later.',

  viewingLatest: 'Current Version',
  companyEmail: 'Company Email Address',

  reapply: 'Reapply',
  tooltipWithdrawApplicationMsg:
    'Are you sure you want to withdraw application on this ranking?',
  tooltipCancelApplicationMsg:
    'Are you sure you want to cancel application on this ranking?',
  tooltipReapplyApplicationMsg:
    'Are you sure you want to reapply on this ranking?',
  basic: 'Basic',
  period: 'Period',
  next: 'Next',

  //create ranking
  basicInfo: 'Basic Info',
  rankingPeriod: 'Ranking Period',
  groupLabelRanking: 'Group members on this ranking',
  rankingMembers: 'Members',
  confirmDeleteColumn:
    'Are you sure you want to remove\nthis column from this ranking?',
  confirmDisableColumn:
    'Are you sure you want to disable\nthis column from this ranking?',
  selectIndexBeforeDisable:
    'Select index first before disabling the column.\n\nIf you disable, column will be hidden from the ranking result view.',
  copied: 'Copied!',
  memberRanking: 'Member',
  decimalIndex: 'Decimal',
  userInfoIndex: 'User Info',
  shownPeriod: 'Shown',
  hiddenPeriod: 'Hidden',
  loadingMultiSelect: 'Loading...',

  //Change Password Modal
  currentPassword: 'Current Password',
  updateYourPassword: 'Update your password',
  transactionHistory: 'Transaction History',
  reason: 'Reason',
  appliedToThisRanking: (name: string): string =>
    `${name} applied to this ranking.`,
  withdrawnApplication: (name: string): string =>
    `${name} have withdrawn this application.`,
  reappliedToThisRanking: (name: string): string =>
    `${name} have reapplied this application.`,
  approvedApplication: (name: string): string =>
    `Application approved by ${name}.`,
  rejectedApplication: (name: string): string =>
    `Application rejected by ${name}.`,

  //Member Ranking List
  chooseGroup: 'Choose a group',
  chooseGroupText:
    'You need to choose a group and select members and click “Add Members To Ranking” button. You can also add multiple members from different groups.',
  confirmChangeIndex:
    'Are you sure you want to change the index to this column?',
  selectIndexBeforeDelete:
    'Select a new  index first before deleting the column.',
  selectIndexTip:
    'This is the index of your ranking, you can change index once you added more ranking column.',
  toEdit: 'Edit',
  toUpdate: 'Save',
  deleteTooltipMsg:
    'Cancelling will discard any changes you’ve made. Do you want to continue?',
  allMemberRanking: 'All',
  selectAll: 'Select All',
  noMembersAvailable: 'No members available',
  successfullyUpdatedRankingMembers: 'The ranking members have been updated.',
  rankingEditMemberAll: 'All',
  rankingEditMembers: 'Edit Members',
  notificationTitle: 'Member update completed',

  // Company Info
  companyEmailCopyTooltip: 'Copied!',
  companyInfoChanged: 'Success',
  companyInformationChanged: 'A record has been successfully updated.',

  //Ranking Period
  editRankingPeriod: 'Edit Ranking Period',
  notificationTitlePeriod: 'Ranking period update completed',
  successfullyUpdatedRankingPeriods:
    'Ranking period has been successfully updated.',

  // Notifications
  applicationReceivedNotif: {
    from: '\xa0さんより\xa0',
    receivedApplicationForGrades: 'への成績申請',
    receivedApplicationForGrades2: 'を受領しました。',
    receivedApplicationForGradesFull: 'への成績申請を受領しました。',
  },
  applicationGradedNotif: {
    backTo: 'への申請が',
    isApproved: 'さんに承認',
    isApproved2: 'されました。',
    isRejected: 'さんに差し',
    isRejected2: '戻されました。',
    isApprovedFull: 'さんに承認されました。',
    isRejectedFull: 'さんに差し戻されました。',
  },
  applicationRankingName: '\xa0への申請が\xa0',
  approvedNotif: '\xa0さんに承認されました。',
  rejectedNotif: '\xa0さんに差し戻されました。',
  applicantName: '\xa0さんより\xa0',
  receivedNotif: '\xa0への成績申請を受領しました。',
  today: 'Today',
  yesterday: 'Yesterday',
  pastDates: 'Past Dates',
  noNotifications: 'No notifications',
  noNotifLabel: 'Your notifications will be shown here.',
  itemsPerPageNotif: 'Notifications per page:',
  paginationNotif: (min: number, max: number, total: number): string =>
    `${min}-${max} of ${total} Notifications`,
  // Bulk Delete Mains
  bulkDeleteMembersModalConfirmation: 'Delete Members Confirmation',
  bulkDeleteMembersModalSubtitle:
    'Are you sure you want to delete these members?',
  cannotDeleteMembers:
    'Cannot delete these members as they are the only approver on their group',
  bulkDeleteCheckboxLabel:
    'I understand that some members will not be deleted from my selection',
  bulkDeleteSuccess: 'Success',
  bulkDeleteSuccessMsg: 'Selected members has been deleted.',

  activateRanking: 'Yes',
  confirmEnabling:
    'Are you sure you want to activate this column from this ranking?',
  disabledActivateRankingButton: 'Activate',
  disabledActivateRanking: 'Yes',
  disabledDeleteRanking: 'Delete',
  disableActivateRankingDetails: 'Yes',
  confirmSaveRanking: 'Yes',
  setSaveRanking: 'Set',
  logHasUpdated: (name: string): string => `${name} has updated`,
  logSeeChanges: 'See Changes',
  logUpdatedBy: (name: string): string => `Updated by ${name}`,

  // Edit Basic Info
  saveEditBasicInfo: 'Save Edit Basic Info',
  confirmToSave: 'Are you sure you want save this?',
  enterRankingName: 'Please enter the ranking name for this ranking.',
  selectNewIndexFirst: 'Select a new  index first before deleting the column.',
  noDisabledColumnsTitle: 'No disabled columns',
  noDisabledColumnsDetials:
    'You can activate or delete disabled columns\nhere.',
  warning: 'Warning',
  warningDeleteColumnDatails:
    'You deleted a ranking item and you will not be able to recover this. If this is okay, please click the save button to save the changes.',
  maxActiveColInfo:
    'You’ve already reached the\nmaximum number of active\ncolumns. Delete column to add\nnew one.',
  discardChangesEditBasicInfo:
    'Cancelling will discard any\nchanges you’ve made. Do you\nwant to continue?',

  cancelDiscard: 'No',
  proceedDiscard: 'Yes',

  // Login Form Setting Default Field Names
  companyIdDefaultField: 'company id',
  userIdDefaultField: 'user id',
  nameDefaultField: 'name',
  groupDefaultField: 'group',
  roleDefaultField: 'role',
  rankDisplaySetting: 'Index',
  archiveRanking: 'Yes',
  archiveActiveRanking: 'Yes',

  shouldHaveIndexedColumn:
    'There should be at least one decimal indexed column.',
  didJustReachLimit:
    'You’ve already reached the maximum\nnumber of columns. Delete column to add new one.',
  rankingSave: 'Yes',
  confirmRankingIndex: 'Yes',
  confirmRankingDisabledColumnDelete: 'Yes',
  addRankingColumnDelete: 'Yes',
  disableRankingColumn:
    'Are you sure you want to disable\nthis column from this ranking?',
  confirmDisableRankingColumn: 'Yes',

  //Email verification link
  verificationLinkExpired: 'Email verification link expired',
  emailAddressRequired: 'Email Address (Required)',
  enterYourEmailAdd: 'Please enter your registered email address.',
  enterEmailAssociatedWithYourAccount:
    'Enter the email address associated with your account and we’ll send you a link to verify your email.',
  newVerificationLinkSent:
    'A new verification link has been sent to your email',

  logout: 'Logout',
  additionalInfoRequired: 'Account information required',
  additionalInfoSubtitle:
    'Additional account information is required to provide a better ranking data. You will be redirected to your account after submitting the required information.',
  customFieldRequired: 'This field is required.',
  verificationLinkError: 'Error',
  verificationLinkSuccess: 'Success',

  //RankingDetails
  rankingDetails: 'Ranking Details',
  activateDisabledRanking: 'Activate Ranking',
  uniqueRankingName: 'Ranking should be unique',
  disabledColumnDeleteRanking: 'Delete',
  noFilterResultsRankingTable: 'No filter results',
  noFilterResultsSubdescription:
    'Please select a ranking and ranking period first to show results.',

  // Period List
  rankingPeriodHeaders: {
    no: 'No',
    name: 'Ranking Period',
    index: 'Index',
    action: '',
  },

  viewMemberModalHeader: 'View member',
  viewMemberModalSubHeader: 'Preview of member information',

  viewMembeModalInputLabels: {
    companyId: 'Company Id',
    userId: 'User ID',
    group: 'Group',
    role: 'Role',
    name: 'Name',
    emailAddress: 'Email Address',
    remark: 'Remark',
  },
  rankingTableHeaders: {
    totalSales: 'Total Sales',
    group: 'Group',
    appointment: 'Appointment',
    calling: 'Calling',
  },

  noOptions: 'No options',
  noSubApproverFound: 'No sub approver found',
  noApproverFound: 'No approver found',
  oneOrMorePeriodRequired: 'No options',

  //Change reset temporary password
  changedPasswordSuccessNotifTitle: 'Success',
  changePasswordSuccessNotifSubtitle:
    'You have successfully changed your password.',
  notificationList: 'Notifications',
  oldPasswordError: 'Your old password is incorrect.',

  cancelEditPeriodTooltip:
    'Cancelling will discard any changes you’ve made. Do you want to continue?',
  proceedCancelEditPeriod: 'Yes',
  noActiveRankingTitle: 'No active ranking',
  noActiveRankingDetails:
    'Click on the “Create New Ranking” button to create one.',
  noDisabledRankingTitle: 'No disabled ranking',
  noDisabledRankingDetails: 'Disabled ranking will be shown here.',

  accountInformation: 'Account Information',
  //Homepage
  homepage: 'Home',
  basicHomepage: 'Basic Functions',
  recordApplications: 'Record Application',
  rankings: 'Ranking',
  accountSettings: 'Account Settings',
  manageFunctions: 'Manage Functions',
  rankingLists: 'Ranking List',
  memberManagements: 'Member Management',
  recordApprovals: 'Record Approval',
  showDetails: 'Show Details',
  hideDetails: 'Hide Details',

  //Star index tooltip
  rankingIndex: 'Ranking index',

  //account options
  accountConfigOption: 'Account Config',
  logoutOption: 'Logout',

  //Company Email verification
  verificationSentThisEmail:
    'A verification link has been sent this email address',
  resend: 'Resend',
  emailVerificationSuccess: 'Email verification success.',
  successfullyVerifiedEmail:
    'Thank you. You have successfully verified your email.',
  backToHome: 'Back to Home',
  companyEmailVerificationLinkExpired:
    'Company email verification link expired',
  enterNewCompanyEmailAddress: 'Please enter the new company email address.',
  enterCompanyEmailAndWellSendAlink:
    'Enter the new company email address and we’ll send you a link to verify the provided email address.',
  verificationLinkHasBeenSent:
    'A new verification link has been sent to the specified email.',
  verificationLinkSentToYourAddress:
    'A verification link has been sent your email address.',
  unverifiedEmailAddress: 'Unverified email address',
  noneSelectApprover: 'None Select',
  memberSignUpTitle: 'Member Sign-up',
  memberSignUpCompanyIdTitle: 'CompanyID (Required)',
  memberSignUpCompanyIdPlaceholder: 'CompanyID',
  memberSignupCompanyIdRequired: 'CompanyID is required',
  memberSignUpFullnameTitle: 'Fullname (Required)',
  memberSignupFullnameRequired: 'Fullname is required',
  memberSignUpEmailTitle: 'Email (Required)',
  memberSignupEmailRequired: 'Email is required',
  memberSignupEmailInvalid: 'Email is invalid',
  memberSignupPasswordRequired: 'Password is required',
  memberSignupPasswordCfRequired: 'Password Confirm is required',
  memberPasswordMustMatch: 'Password confirm must match',
  memberSignUpPasswordTitle: 'Password (Required)',
  memberSignUpPasswordCfTitle: 'Password Confirm (Required)',
  memberSignUpPasswordPlaceholder: 'Password',
  memberSignUpGoogleRegister: 'Sign-up with Google',
  memberSignUpAnotherMethod: 'OR',
  memberSignUpSuccess: 'Sign-up Successfully',
  backToProfile: 'Back to Profile',
  memberSignInTitle: 'Login',
  memberForgotPassword: 'Forgot Password ?',
  memberHaventAccount: 'Do not have an account?',
  googleButtonSignIn: 'Login with Google',
  memberForgotPassTitle: 'Forgot Password',
  memberForgotDesc:
    'To reset your password, please enter your registered email address.',
  memberForgotNotice:
    'A password reset email has been sent. Please check the email you received.',
  memberForgotTitle: 'Change Password',
  send: 'Send',
  backToLoginPage: 'Back To Login',
  registerComplete: 'Register Complete',
  registerFail: 'Register Failed. \n Please try again.',
  close: 'Close',
  homeMenu: 'Home',
  rankingMenu: 'Ranking',
  calendarMenu: 'Calendar',
  myPageMenu: 'MyPage',
  settingMenu: 'Setting',
  homeTitle: 'Homepage',
  memberListTitle: 'Member List',
  myPageTitle: 'MyPage',
  rankingTitle: 'Ranking',
  settingTitle: 'Setting',
  resetPasswordSuccessful: 'Reset password successful.',
  resetPasswordFailed: 'Reset password failed, please try again.',
  emailMaxRequired: 'Email address maximum 254 characters',
  fullNameMaxRequired: 'Full name maximum 255 characters',
  passwordMaxRequired: 'Password maximum 128 characters',
  fullNameRequired: 'Full name (Required)',
  fullNamePlaceholder: 'セルフ 太郎',
  accountSettingTitle: 'Account Setting',
  userIdRequired: 'User Id Required',
  minRequiredFullname: 'Fullname cannot be less than 6 characters.',
  roleRequired: 'Role is required',
  groupRequired: 'Group is required',
  changeRequestApproved: 'Member Info approved successfully',
  changeRequestFailed:
    'Member information has been not approved because an error occurred',
  changeRequestRejected: 'Request to change information is rejected.',
  fileSizeAccepted: 'File size is larger than the allowed size.',
  fileTypeNotAllowed: 'File type is not allowed',
  here: 'Here',
  memberListTitlePage: 'Member List',
  no_name: 'NO NAME',
};
