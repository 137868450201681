import React, { useCallback, useState, useEffect } from 'react';
import { useMutation } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import words from '../../../constants/words';

import { useAuthHooks } from '../../../hooks/auth';
import { useNavigationHooks } from '../../../hooks/navigation';
import { AdminFirstLogin } from '../../templates/AdminFirstLogin';
import { SignUpValues } from '../../templates/AdminFirstLogin/AdminFirstLogin';

type Props = {};
type ResponseVal = {
  password: string;
  emailAddress: string;
  error: boolean;
  msg: string;
  linkExpired: boolean;
};

const Component = ({}: Props): React.ReactElement => {
  const navigate = useNavigate();
  const location = useLocation();
  const { useSignUp } = useAuthHooks();
  const { useNavigation } = useNavigationHooks();
  const { adminFirstLogin, verifyEmail, adminVerification } = useSignUp();
  const { getUrlQuery } = useNavigation();

  const [passwordRes, setPasswordRes] = useState<string>('');
  const [companyEmailRes, setCompanyEmailRes] = useState<string>('');
  const [signupError, setSignupError] = useState<boolean>(false);
  const [isVerifyingAdmin, setIsVerifyingAdmin] = useState<boolean>(true);
  const [adminData, setAdminData] = useState<any>({});

  const query = getUrlQuery();
  const { pk } = useParams();
  const token = query.get('token');

  const { mutate: firstLoginMutation, isLoading } = useMutation(
    ([user_id, password, password2, email, photo]: [
      string,
      string,
      string,
      string,
      File[],
    ]) => {
      return adminFirstLogin(user_id, password, password2, email, photo);
    },
  );

  const { mutate: adminVerificationMutation } = useMutation(
    ([pk, token]: [string, string]) => {
      return adminVerification(pk, token);
    },
  );

  const { mutate: verifyEmailMutation } = useMutation(
    ([user_id, email]: [string, string]) => {
      return verifyEmail(user_id, email);
    },
  );

  const onSubmitRegister = useCallback(
    (values: SignUpValues): void => {
      const { email, password, password2, photo } = values;
      setSignupError(false);
      firstLoginMutation(
        [
          adminData.user_id,
          password,
          password2,
          email,
          photo || ([] as File[]),
        ],
        {
          onError: () => {
            setSignupError(true);
          },
          onSuccess: response => {
            responseHandler(response);
          },
        },
      );
    },
    [adminData.user_id],
  );

  const checkEmail = useCallback((email: string): void => {
    setSignupError(false);
    verifyEmailMutation([adminData.user_id, email], {
      onSuccess: response => {
        const res = response as ResponseVal;
        setCompanyEmailRes(res.error ? res.msg : '');
      },
    });
  }, []);

  const navigateToLinkExpiredPage = () => {
    navigate('/verification-link-expired', {
      state: { from: location.pathname },
    });
  };

  const verifyAdmin = useCallback(
    (pk: string, token: string): void => {
      adminVerificationMutation([pk, token], {
        onSuccess: response => {
          setAdminData(response);
          setIsVerifyingAdmin(false);
        },
        onError: (error: any) => {
          const { response } = error;
          response?.status === 403 && response?.data?.error === 'Link expired!'
            ? navigateToLinkExpiredPage()
            : navigate('/login');
        },
      });
    },
    [pk, token],
  );

  const responseHandler = (res: any) => {
    if (typeof res === 'object' && res && res.hasOwnProperty('errors')) {
      const resp = res['errors'] as ResponseVal;
      if (resp.linkExpired) {
        return navigateToLinkExpiredPage();
      }
      setCompanyEmailRes(resp.emailAddress || '');
      setPasswordRes(resp.password || '');
    } else {
      navigate('/login', { state: { adminSignUp: true } });
    }
  };

  useEffect(() => {
    if (!pk || !token) {
      navigate('/login');
    } else {
      verifyAdmin(pk, token);
    }
  }, [pk, token]);

  useEffect(() => {
    window.addEventListener('beforeunload', promptUser);
    return () => {
      window.removeEventListener('beforeunload', promptUser);
    };
  });

  const promptUser = (event: any) => {
    event.preventDefault();
    event.returnValue = '';
  };

  return isVerifyingAdmin ? (
    <></> // TODO: Replace with placeholder while loading or skeleton for first login
  ) : (
    <AdminFirstLogin
      name={adminData.representative}
      companyId={adminData.company_id.toString() || '0'}
      userId={adminData.username.split('A')[1] || '1'}
      email={adminData.user_email}
      group={adminData.group}
      companyName={adminData.company}
      role={words.admin}
      onRegister={onSubmitRegister}
      passwordRes={passwordRes}
      emailAddressRes={companyEmailRes}
      signupError={signupError}
      checkEmail={checkEmail}
      isLoading={isLoading}
      customFields={[]}
    />
  );
};

export default Component;
