import React, { useEffect, useState } from 'react';
import CloseButton from 'assets/icons/close-button.svg';
import './editGroupModal.scss';
import Select from 'react-select';
import { OrangeButton } from 'components/atoms/OrangeButton';
import { listGroupOptionType } from 'type/member';
import axios from 'axios';
import authHeader from 'api/auth-header';
import urls from 'constants/urls';
import { getCurrentUser } from 'utils/common';

type PropType = {
  isShow: boolean;
  onHideModal: () => void;
  listUserOfGroup: any[];
  group: string;
  handleEditNewGroup: (
    __groupId: string,
    _groupName: string,
    _listOwner: number[],
    _listApprover: number[],
  ) => void;
  errorEditGroupFromServer?: string;
  editGroupStatus: string;
};

export default function EditGroupModal(props: PropType) {
  const {
    isShow,
    onHideModal,
    handleEditNewGroup,
    group,
    errorEditGroupFromServer,
    editGroupStatus,
  } = props;
  const [groupName, setGroupName] = useState('');
  const [listOption, setListOption] = useState<any[]>([]);
  const [selectedApprover, setSelectedApprover] = useState<number[]>([]);
  const [selectedOwner, setSelectedOwner] = useState<number[]>([]);
  const [errorGroupName, setErrorGroupName] = useState(false);
  const [errorGroupApprover, setErrorGroupApprover] = useState(false);
  const [errorGroupOwner, setErrorGroupOwner] = useState(false);
  const [selectedInside, setSelectedInside] = useState<listGroupOptionType>([]);
  const [selectedOwnInside, setSelectedOwnInside] = useState<
    listGroupOptionType
  >([]);
  const [listCanSelect, setListCanSelect] = useState<listGroupOptionType>([]);
  const [listTemp, setListTemp] = useState<listGroupOptionType>([]);

  useEffect(() => {
    const user = getCurrentUser();
    if (group) {
      axios
        .post(
          urls.get_all_member_company,
          { company_id: user.company_id },
          { headers: authHeader() },
        )
        .then(res => {
          const data: any[] = [];
          res.data.data &&
            res.data.data.map((item: { id: number; full_name: string }) => {
              const newItem = {
                value: item.id.toString(),
                label: item.full_name,
              };
              data.push(newItem);
            });
          setListOption(data);
        })
        .catch();
      axios
        .post(
          urls.group_approver_details,
          { group_id: group ? group : 0 },
          { headers: authHeader() },
        )
        .then(res => {
          const data: any[] = [];
          const dataOwn: any[] = [];
          const idArr: number[] = [];
          const idOwnArr: number[] = [];
          setGroupName(res.data.data.group_info.name);
          res.data.data.approver &&
            res.data.data.approver.map(
              (item: { id: number; full_name: string; is_owner: boolean }) => {
                if (!item.is_owner) {
                  const newItem = {
                    value: item.id.toString(),
                    label: item.full_name,
                  };
                  data.push(newItem);
                  idArr.push(item.id);
                }
                if (item.is_owner) {
                  const newItem = {
                    value: item.id.toString(),
                    label: item.full_name,
                  };
                  dataOwn.push(newItem);
                  idOwnArr.push(item.id);
                }
              },
            );
          setSelectedApprover(idArr);
          setSelectedOwner(idOwnArr);
          setSelectedInside(data);
          setSelectedOwnInside(dataOwn);
        })
        .catch();
    }
  }, [group]);

  useEffect(() => {
    setListCanSelect(listOption);
    setListTemp(listOption);
  }, [listOption]);

  function handleEditGroupInComp() {
    setErrorGroupName(false);
    setErrorGroupApprover(false);
    if (groupName.length === 0) {
      setErrorGroupName(true);
      return;
    }
    if (selectedApprover.length === 0) {
      setErrorGroupApprover(true);
      return;
    }
    if (selectedOwner.length === 0) {
      setErrorGroupOwner(true);
      return;
    }
    setErrorGroupApprover(false);
    setErrorGroupOwner(false);
    handleEditNewGroup(group, groupName, selectedOwner, selectedApprover);
  }

  useEffect(() => {
    setSelectedApprover([]);
    setSelectedOwner([]);
    setSelectedInside([]);
    setSelectedOwnInside([]);
    setGroupName('');
  }, [editGroupStatus]);

  useEffect(() => {
    const data = [...selectedOwner, ...selectedApprover];
    setListCanSelect(listTemp);
    data.map(item => {
      setListCanSelect(prev => prev.filter(i => parseInt(i.value) !== item));
    });
  }, [selectedOwner, selectedApprover]);

  function handleChange(value: any) {
    const members = { value };
    if (members.value.length > 0) {
      const listApprover: number[] = [];
      members.value.map((item: { value: string; label: string }) => {
        listApprover.push(parseInt(item.value));
      });
      setSelectedApprover(listApprover);
      setSelectedInside(members.value);
    } else {
      setSelectedApprover([]);
      setSelectedInside([]);
    }
  }

  function handleChangeApprover(value: any) {
    const approvers = { value };
    if (approvers.value.length > 0) {
      const listApprover: number[] = [];
      approvers.value.map((item: { value: string; label: string }) => {
        listApprover.push(parseInt(item.value));
      });
      setSelectedOwner(listApprover);
      setSelectedOwnInside(approvers.value);
    } else {
      setSelectedOwner([]);
      setSelectedOwnInside([]);
    }
  }

  return (
    <div className={`edit-group-pages ${isShow ? 'show' : 'hide'}`}>
      <div className="title-modal">
        <div className="modal-title">グループ編集</div>
        <div className="close-button" onClick={onHideModal}>
          <img src={CloseButton} />
        </div>
      </div>
      <div className="modal-content">
        <label
          className={
            groupName && groupName.length > 0 ? 'filled' : 'not-filled'
          }>
          グループ名 (必須)
        </label>
        <br />
        <input
          className="input-signup"
          type="text"
          placeholder="グループ名 (必須)"
          value={groupName}
          onChange={e => setGroupName(e.target.value)}
          required
          autoComplete="new-password"
        />
        {errorGroupName && (
          <div className="errorFormat">
            グループ名を空にすることはできません。254
            文字以下にする必要があります。
          </div>
        )}
        <br />
        <br />
        <label
          className={
            groupName && groupName.length > 0 ? 'filled' : 'not-filled'
          }>
          グループ所有者 (必須)
        </label>
        <Select
          value={selectedOwnInside}
          placeholder="グループ所有者"
          options={listCanSelect}
          isMulti
          className="select-group"
          classNamePrefix="select-group"
          menuPlacement="top"
          noOptionsMessage={() => '空の'}
          onChange={handleChangeApprover}></Select>
        {errorGroupOwner && (
          <div className="errorFormat">
            所有者を少なくとも 1 人選択してください。
          </div>
        )}
        <br />
        <br />
        <label
          className={
            groupName && groupName.length > 0 ? 'filled' : 'not-filled'
          }>
          承認者 (必須)
        </label>
        <Select
          value={selectedInside}
          placeholder="承認者"
          options={listCanSelect}
          isMulti
          className="select-group"
          classNamePrefix="select-group"
          menuPlacement="top"
          noOptionsMessage={() => '空の'}
          onChange={handleChange}></Select>
        {errorGroupApprover && (
          <div className="errorFormat">
            少なくとも 1 人の承認者を選択してください。
          </div>
        )}
        <p
          className="errorFromServer"
          style={{
            display: errorEditGroupFromServer !== '' ? 'block' : 'none',
          }}
          dangerouslySetInnerHTML={{
            __html: errorEditGroupFromServer ? errorEditGroupFromServer : '',
          }}></p>
        <OrangeButton
          text="編集"
          onClick={handleEditGroupInComp}
          style={{ width: '144px', height: '42px', marginTop: '20px' }}
        />
      </div>
    </div>
  );
}
