import React from 'react';
import { Dropdown } from 'carbon-components-react';
import styled from 'styled-components';
import { theme } from '../../../config';
import { Exclamation as ExclamationIcon } from '../../atoms/Icons';

export type Props = {
  styles?: Object;
  id: string;
  label: string;
  items: Array<Object | string | number>;
  onChange: Function;
  disabled?: boolean;
  dropdownProps?: Object;
  direction?: 'top' | 'bottom';
  helperText?: string;
  titleText?: string;
  backgroundColor?: string;
  className?: string;
  inline?: boolean;
  invalid?: boolean;
  invalidText?: string;
};
const MainWrapper = styled.div<{ $isDisabled?: boolean }>`
  cursor: ${(props): string => (props.$isDisabled ? 'not-allowed' : 'pointer')};
`;
const Wrapper = styled.div<{ $isDisabled?: boolean }>`
  position: relative;
  pointer-events: ${(props): string => (props.$isDisabled ? 'none' : 'auto')};
  width: 100%;

  .bx--list-box__field {
    border: 1px solid;
    border-color: ${props =>
      props.theme.inline ? 'transparent' : theme.colors.disabledButton2};
    outline: 0;
    background-color: ${props => props.theme.backgroundColor};
    max-width: 100%;
    border-radius: 8px;

    .bx--list-box__menu-icon svg {
      pointer-events: none;
    }

    &:focus {
      outline-width: ${props => (props.theme.inline ? '0' : '2px')};
      outline-color: ${props =>
        props.theme.inline ? 'none' : theme.colors.primaryButton1};
    }

    &:disabled {
      border: 1px solid ${theme.colors.disabledToggle2};
      background: ${theme.colors.disabledButton1};

      .bx--list-box__label {
        color: ${theme.colors.ui04};
      }
    }
  }

  .bx--list-box__menu-item--active .bx--list-box__menu-item__selected-icon {
    display: none;
  }

  .bx--dropdown {
    width: 100%;
    border: 0;
    border-radius: 8px;
  }

  .bx--dropdown--invalid {
    outline: none !important;
  }

  .bx--list-box__menu {
    outline: 0;
    background-color: ${theme.colors.ui01};
    width: 100%;
    min-width: 200px;

    .bx--list-box__menu-item {
      width: auto;

      .bx--list-box__menu-item__option {
        border-top: 1px solid ${theme.colors.ui03};
      }

      &:hover {
        background-color: ${theme.colors.disabled01};
      }
    }

    .bx--list-box__menu-item--active {
      background-color: transparent;

      .bx--list-box__menu-item__option {
        color: ${theme.colors.text03};
      }
    }
  }

  .bx--list-box__menu:active,
  .bx--list-box__menu
    .bx--list-box__menu-item:first-child
    .bx--list-box__menu-item__option {
    border: 0;
  }

  .bx--list-box[data-invalid] .bx--list-box__field {
    border: 2px solid ${theme.colors.support01};
  }

  .bx--list-box[data-invalid] ~ .bx--form-requirement {
    color: ${theme.colors.support01};
    white-space: pre-wrap;
  }
`;

const ErrorIcon = styled(ExclamationIcon)<{
  $backgroundColor: string;
  $titleText: string;
}>`
  position: absolute;
  top: ${props => (props.$titleText ? '35px' : '13px')};
  right: 22px;
  background-color: ${props => props.$backgroundColor};

  & > path {
    fill: ${theme.colors.support01};
  }
`;

const DropdownComponent = ({
  styles,
  items,
  id,
  label,
  onChange,
  disabled = false,
  dropdownProps,
  direction = 'bottom',
  helperText = '',
  titleText = '',
  backgroundColor = theme.colors.ui01,
  className,
  inline,
  invalid,
  invalidText,
}: Props): React.ReactElement => {
  return (
    <MainWrapper $isDisabled={disabled}>
      <Wrapper
        className={className}
        style={styles}
        $isDisabled={disabled}
        theme={{ backgroundColor, inline }}>
        <Dropdown
          items={items}
          id={id}
          titleText={titleText}
          label={label}
          onChange={item => onChange(item)}
          disabled={disabled}
          direction={direction}
          helperText={helperText}
          invalid={invalid}
          invalidText={invalidText}
          {...dropdownProps}
        />
        {invalid && (
          <ErrorIcon
            $backgroundColor={backgroundColor}
            $titleText={titleText}
          />
        )}
      </Wrapper>
    </MainWrapper>
  );
};

export default DropdownComponent;
