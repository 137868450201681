import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useFormikContext } from 'formik';
import { useQuery, useMutation } from 'react-query';

import { Plus } from '../../atoms/Icons';
import { TextInput } from '../../atoms/TextInput';
import { ToggleSwitch } from '../../atoms/ToggleSwitch';

import { LoginFieldActions } from '../../molecules/LoginFieldsActions';
import { Props as INotification } from '../../molecules/Notification/Notification';
import { ToastNotification } from '../../molecules/Notification';
import { languageUsed } from '../../../constants/words';

import {
  AddButton,
  AddFieldWrapper,
  CellWrapper,
  DefaultText,
  TextInputWrapper,
  Wrapper,
  StyledTable,
  StyledTooltip,
  GlobalStyle,
} from './elements';
import { debouncer } from '../../../utils/debouncer';
import { DeleteFieldModal } from '../DeleteFieldModal';

import words from '../../../constants/words';
import { useLoginFieldHooks } from '../../../hooks/field';
import { useGlobalState } from '../../../hooks/global';
import { CreateLoginFieldProps } from '../../../domain/entities/field';
import { TIMEOUT } from '../../../constants/timeout';

import { DataTableSkeleton } from 'carbon-components-react';

export type SettingProps = {
  id: number;
  name: string;
  required: boolean;
  visible: boolean;
  disabled: boolean;
  isDefault: boolean;
};

export type Props = {};

type RowProps = {
  id: string;
  name: string | React.ReactElement;
  setting: React.ReactElement;
  visible: React.ReactElement;
  action: string | React.ReactElement;
};

const STARTER_SETTINGS_DATA = [
  {
    id: 0,
    name: words.companyId,
    required: true,
    visible: true,
    disabled: true,
    isDefault: true,
  },
  {
    id: 0,
    name: words.userId,
    required: true,
    visible: true,
    disabled: true,
    isDefault: true,
  },
  {
    id: 0,
    name: words.name,
    required: true,
    visible: true,
    disabled: true,
    isDefault: true,
  },
  {
    id: 0,
    name: words.group,
    required: true,
    visible: true,
    disabled: true,
    isDefault: true,
  },
  {
    id: 0,
    name: words.roleLoginSetting,
    required: true,
    visible: true,
    disabled: true,
    isDefault: true,
  },
];

export const LOGIN_SETTING_HEADERS = [
  {
    key: 'id',
    header: 'No.',
  },
  {
    key: 'name',
    header: words.fieldName,
  },
  {
    key: 'setting',
    header: words.fieldSetting,
  },
  {
    key: 'visible',
    header: words.fieldVisibility,
  },
  {
    key: 'action',
    header: words.actions,
  },
];

const defaultFieldNames = [
  words.companyIdDefaultField,
  words.userIdDefaultField,
  words.nameDefaultField,
  words.groupDefaultField,
  words.roleDefaultField,
];

const Component = (): React.ReactElement => {
  const [isDebouncing, setIsDebouncing] = useState<boolean>();
  const [isAddingField, setIsAddingField] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [indexToDelete, setIndexToDelete] = useState<number | null>(null);
  const [indexToEdit, setIndexToEdit] = useState<number | null>(null);
  const { values, setFieldValue } = useFormikContext();
  const [reUpdateFields, setReUpdateFields] = useState<Response | undefined>();
  const [newlyDeletedField, setNewlyDeletedField] = useState<
    SettingProps | undefined
  >();
  const [
    addFieldVisibilityToggleValue,
    setAddFieldVisibilityToggleValue,
  ] = useState<boolean>(true);
  const [addFieldSettingToggleValue, setAddFieldSettingToggleValue] = useState<
    boolean
  >(true);
  const [isMaxFieldTooltipOpen, setIsMaxFieldTooltipOpen] = useState<boolean>(
    false,
  );
  const [isDoneInitialMount, setIsDoneInitialMount] = useState(false);

  const {
    useCurrentUser: { currentUser },
  } = useGlobalState();
  const {
    useCreateLoginField,
    useDeleteLoginField,
    useUpdateLoginField,
    useFetchLoginFields,
    useCheckDuplicateFieldName,
  } = useLoginFieldHooks();
  const [nameError, setNameError] = useState('');
  const { createLoginField } = useCreateLoginField();
  const { deleteLoginField } = useDeleteLoginField();
  const { updateLoginField } = useUpdateLoginField();
  const { fetchFields } = useFetchLoginFields();
  const { checkDuplicate } = useCheckDuplicateFieldName();
  const [notification, setNotification] = useState<INotification | null>(null);
  const companyId = currentUser ? currentUser.companyId : 0;

  const successToast = (successMsg: string) =>
    toastNotification({
      kind: 'success',
      title: words.fieldSuccessTitle,
      subtitle: successMsg,
    });

  const errorToast = () =>
    toastNotification({
      kind: 'error',
      title: words.error,
      subtitle: words.errorOccuredToastSub,
    });

  const renderToast = (successMsg: string, wasAddingField?: boolean) => {
    return {
      onSuccess: (response: any) => {
        if (wasAddingField) {
          if (data.length - 7 === 4) {
            setIsMaxFieldTooltipOpen(true);
          }
        }
        setReUpdateFields(response);
        successToast(successMsg);
      },
      onError: () => errorToast(),
    };
  };

  const { mutate: checkDuplicateName } = useMutation(
    ({ companyId, fieldName }: { companyId: number; fieldName: string }) => {
      return checkDuplicate({ companyId, fieldName });
    },
    {
      onSuccess: () => setNameError(words.fieldNameAlreadyTaken),
      onError: () => {
        setIsDebouncing(false);
        setNameError('');
      },
    },
  );

  const checkDuplicateField = (text: string) => {
    const queryCheckDuplicate: { companyId: number; fieldName: string } = {
      companyId,
      fieldName: text,
    };
    const isDefaultName = nameExists(text);

    if (isDefaultName) {
      setNameError(words.fieldNameAlreadyTaken);
    } else {
      checkDuplicateName(queryCheckDuplicate);
    }
  };

  const debounceUserInput = debouncer((text: string) => {
    const formattedText = removeSpaces(text);
    if (!currentUser?.companyId) {
      throw new Error(`'company id' field not set`);
    }

    if (formattedText !== '') {
      if (
        indexToEdit !== null &&
        data[indexToEdit].name.toLowerCase() !== formattedText.toLowerCase()
      ) {
        checkDuplicateField(formattedText);
      } else if (indexToEdit === null) {
        checkDuplicateField(formattedText);
      }
    }
  }, 500);

  const nameExists = (name: string) =>
    defaultFieldNames.includes(name.toLowerCase());

  const removeSpaces = (name: string) => name.trim();

  const fetchFieldsAPI = useCallback(async () => {
    const response = await fetchFields({
      companyId,
    });
    const fields: SettingProps[] = response.map((item: any) => {
      return {
        ...item,
        id: `${item.id}`,
        isDefault: item.is_default,
        visible: item.visible,
        disabled: false,
      };
    });

    const allFields = STARTER_SETTINGS_DATA.concat(fields);
    return allFields;
  }, []);

  const { mutate: addFieldAPI } = useMutation(
    (data: CreateLoginFieldProps) =>
      createLoginField(
        {
          companyId,
        },
        data,
      ),
    renderToast(words.addFieldSuccessMsg, true),
  );

  const { mutate: deleteFieldAPI } = useMutation(
    (id: number) =>
      deleteLoginField({
        companyId,
        fieldId: id,
      }),
    {
      onSuccess: () => {
        successToast(words.deleteFieldSuccessMsg);
        if (indexToDelete) {
          setIndexToDelete(null);
          setNewlyDeletedField(data[indexToDelete]);
        }
      },
      onError: () => {
        errorToast();
      },
    },
  );

  const { mutate: updateFieldAPI } = useMutation(
    (params: { id: number; data: CreateLoginFieldProps }) =>
      updateLoginField(
        {
          companyId,
          fieldId: params.id,
        },
        params.data,
      ),
    renderToast(words.updateFieldSuccessMsg),
  );

  const { data = STARTER_SETTINGS_DATA, isFetching: isFetchingData } = useQuery(
    [newlyDeletedField, reUpdateFields],
    fetchFieldsAPI,
    {
      refetchOnMount: true,
    },
  );

  const renderSettingCell = (
    index: number,
    defaultValue: boolean,
    isDisabled: boolean,
  ) => {
    return (
      <CellWrapper>
        <ToggleSwitch
          id={`${index}_setting`}
          labelToggledOff={words.optional2}
          labelToggledOn={words.mandatory}
          onToggle={value => onToggle(index, value, 'required')}
          defaultValue={defaultValue}
          disabled={isDisabled}
          toggled={defaultValue}
        />
      </CellWrapper>
    );
  };

  const renderVisibilityCell = (
    index: number,
    defaultValue: boolean,
    isDisabled: boolean,
  ) => {
    return (
      <CellWrapper>
        <ToggleSwitch
          id={`${index}_visible`}
          labelToggledOff={words.hidden}
          labelToggledOn={words.shown}
          onToggle={value => onToggle(index, value, 'visible')}
          defaultValue={defaultValue}
          disabled={isDisabled}
          toggled={defaultValue}
        />
      </CellWrapper>
    );
  };

  const renderActionCell = (
    isDefault: boolean,
    isCreatingField: boolean,
    idx: number,
  ) => {
    return isDefault ? (
      <DefaultText>{words.default}</DefaultText>
    ) : (
      <LoginFieldActions
        id={idx}
        isAddingField={isCreatingField || idx === indexToEdit}
        onPressEdit={() => {
          onEditItem(idx);
          setNameError('');
        }}
        onPressCancel={() => {
          onCancelAction(idx);
          setNameError('');
        }}
        onPressDelete={() => {
          setIsDeleteModalOpen(true);
          setIndexToDelete(idx);
        }}
        onPressSave={() =>
          isCreatingField ? onSaveItem(null) : onSaveItem(indexToEdit)
        }
      />
    );
  };

  const handleNameChange = useCallback(
    (value: string) => {
      setNameError('');
      setIsDebouncing(true);
      setFieldValue('name', value);

      const formattedText = removeSpaces(value);
      if (formattedText === '') {
        setNameError(words.fieldNameIsRequired);
        return;
      }

      if (
        indexToEdit !== null &&
        data[indexToEdit]?.name?.toLowerCase() === formattedText.toLowerCase()
      ) {
        setNameError('');
        setIsDebouncing(false);
        return;
      }

      debounceUserInput(value);
    },
    [data, indexToEdit],
  );

  const renderTextInput = (value: string) => {
    return (
      <TextInputWrapper>
        <TextInput
          id="add-field"
          value={value}
          onChange={event =>
            handleNameChange((event.target as HTMLTextAreaElement).value)
          }
          invalid={nameError !== ''}
          invalidText={nameError}
          maxLength={50}
        />
      </TextInputWrapper>
    );
  };

  const outdatedRowData = useRef<Array<RowProps> | []>([]);
  const renderRowData = () => {
    const rows: RowProps[] | never = [];

    const formValues = values as SettingProps;
    if (data.length > 5 && !isFetchingData) {
      data.forEach((item, index) => {
        const isEmailOrProfileImg = index === 5 || index === 6;
        const settingItem = {
          id: `${index + 1}`,
          name:
            indexToEdit === index
              ? renderTextInput(formValues.name)
              : item.name,
          setting: renderSettingCell(index, item.required, item.id === 0),
          visible: renderVisibilityCell(
            index,
            item.visible,
            item.id === 0 || isEmailOrProfileImg,
          ),
          action: renderActionCell(item.isDefault, false, index),
        };
        rows.push(settingItem as RowProps);
      });

      if (isAddingField) {
        const nextIdx = data.length + 1;
        const addFieldForm = {
          id: `${nextIdx}`,
          name: renderTextInput(formValues.name),
          setting: renderSettingCell(
            nextIdx,
            addFieldSettingToggleValue,
            false,
          ),
          visible: renderVisibilityCell(
            nextIdx,
            addFieldVisibilityToggleValue,
            false,
          ),
          action: renderActionCell(false, true, data.length),
        };
        rows.push(addFieldForm);
      }
      outdatedRowData.current = rows;
    } else {
      return outdatedRowData.current;
    }

    return rows;
  };

  const clearToDeleteItem = () => {
    setIndexToDelete(null);
    setIsDeleteModalOpen(false);
  };

  const resetInitialValues = (idx?: number) => {
    setFieldValue('name', idx ? data[idx].name : '');
    setFieldValue('required', idx ? data[idx].required : true);
    setFieldValue('visible', idx ? data[idx].visible : true);
    setFieldValue('isDefault', idx ? data[idx].isDefault : false);
    setFieldValue('disabled', idx ? data[idx].disabled : false);
    setAddFieldSettingToggleValue(true);
    setAddFieldVisibilityToggleValue(true);
  };

  const onEditItem = (idx: number) => {
    setIndexToEdit(idx);
    setIsAddingField(false);
    resetInitialValues(idx);
  };

  const onCancelAction = (idx?: number) => {
    if (idx === indexToEdit) {
      resetInitialValues();
      setIndexToEdit(null);
    } else {
      setIsAddingField(false);
    }
  };

  const onAddItem = () => {
    resetInitialValues();
    setIndexToEdit(null);
    setNameError('');
    setIsAddingField(!isAddingField);
  };

  const onDeleteItem = (id: number) => {
    deleteFieldAPI(id);
    clearToDeleteItem();
  };

  const onToggle = (
    idx: number,
    value: boolean,
    type: 'required' | 'visible',
  ) => {
    if (isAddingField && data[idx] === undefined) {
      if (type === 'required') {
        setAddFieldSettingToggleValue(value);
      }
      if (type === 'visible') {
        setAddFieldVisibilityToggleValue(value);
      }
    } else {
      setFieldValue(type, value);

      const formValues = data[idx];
      const fieldParams = {
        name: formValues.name,
        visible: type === 'visible' ? value : formValues.visible,
        required: type === 'required' ? value : formValues.required,
        is_default: formValues.isDefault,
      };

      updateFieldAPI({ id: data[idx].id, data: fieldParams });
    }
  };

  const onSaveItem = (idx: number | null) => {
    try {
      const formValues = values as SettingProps;
      /** this is to disable saving when input is still checking for duplicate */
      if (isDebouncing) {
        return;
      }
      /** end */
      if (formValues.name === '') {
        setNameError(words.fieldNameIsRequired);
      } else if (nameError !== '') {
        setNameError(words.fieldNameAlreadyTaken);
      } else if (nameExists(formValues.name)) {
        setNameError(words.fieldNameIsRequired);
      } else {
        const fieldParams = {
          name: removeSpaces(formValues.name),
          visible: isAddingField
            ? addFieldVisibilityToggleValue
            : formValues.visible,
          required: isAddingField
            ? addFieldSettingToggleValue
            : formValues.required,
          is_default: formValues.isDefault,
        };

        if (!isAddingField && idx) {
          const isNewName =
            indexToEdit && data[indexToEdit].name !== formValues.name;

          if (isNewName) {
            updateFieldAPI({ id: data[idx].id, data: fieldParams });
          }
          setIndexToEdit(null);
        } else {
          setIsAddingField(false);
          addFieldAPI(fieldParams);
        }
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const toastNotification = useCallback((toast: INotification) => {
    setNotification({
      ...toast,
      timeout: TIMEOUT,
      handleClose: () => {
        setNotification(null);
        return false;
      },
    });
  }, []);

  const hasMaxCustomFields = data.length - 7 === 5;

  useEffect(() => {
    setIsDoneInitialMount(true);
  }, []);

  useEffect(() => {
    if (!isFetchingData && outdatedRowData.current?.length > 5) {
      setIsDoneInitialMount(false);
    }
  }, [isFetchingData]);

  return (
    <Wrapper
      isAddingField={isAddingField}
      editFieldRow={indexToEdit !== null ? indexToEdit + 1 : 0}>
      {isFetchingData && isDoneInitialMount ? (
        <DataTableSkeleton
          headers={LOGIN_SETTING_HEADERS}
          showToolbar={false}
          showHeader={true}
          columnCount={LOGIN_SETTING_HEADERS.length}
          className={'login-setting-table-skeleton'}
        />
      ) : (
        <StyledTable rows={renderRowData()} headers={LOGIN_SETTING_HEADERS} />
      )}
      {!isAddingField ? (
        <AddFieldWrapper isDisabled={hasMaxCustomFields}>
          {hasMaxCustomFields ? (
            <StyledTooltip
              message={words.maxFieldsReached}
              isOpen={isMaxFieldTooltipOpen}
              align={'start'}
              onChange={(ev, { open }) => {
                if (!open) {
                  setIsMaxFieldTooltipOpen(false);
                }
              }}
              triggerElement={
                <AddButton
                  onPress={() => setIsMaxFieldTooltipOpen(true)}
                  title={words.addField}
                  icon={Plus}
                  className="field-button"
                />
              }
              menuOffset={{ top: 5, left: languageUsed === 'ja' ? -97 : -55 }}
            />
          ) : (
            <AddButton
              onPress={onAddItem}
              title={words.addField}
              icon={Plus}
              className="field-button"
            />
          )}
        </AddFieldWrapper>
      ) : null}
      {indexToDelete !== null ? (
        <DeleteFieldModal
          id={indexToDelete}
          open={isDeleteModalOpen}
          onCancel={clearToDeleteItem}
          onConfirm={() => onDeleteItem(data[indexToDelete].id)}
          onClose={clearToDeleteItem}
          fieldName={data[indexToDelete].name}
          fieldSetting={
            data[indexToDelete].required ? words.mandatory : words.optional2
          }
        />
      ) : null}
      {notification ? <ToastNotification notification={notification} /> : null}
      <GlobalStyle />
    </Wrapper>
  );
};

export default Component;
