import { useCallback } from 'react';
import { LoginFieldHooks } from '.';
import { useDependencies } from '..';
import {
  CreateLoginFieldProps,
  FetchLoginFieldProps,
  LoginField,
  UpdateLoginFieldProps,
} from '../../domain/entities/field';

export const useFetchLoginFields: LoginFieldHooks['useFetchLoginFields'] = (): {
  fetchFields: (query: {
    companyId: number;
  }) => Promise<FetchLoginFieldProps[]>;
} => {
  const { loginFieldInteractor } = useDependencies();
  const fetchFields = useCallback(async (query: { companyId: number }) => {
    const response = await loginFieldInteractor.fetchLoginFields(query);

    return response;
  }, []);

  return { fetchFields };
};

export const useCreateLoginField: LoginFieldHooks['useCreateLoginField'] = (): {
  createLoginField: (
    payload: { companyId: number },
    data: CreateLoginFieldProps,
  ) => Promise<LoginField>;
} => {
  const { loginFieldInteractor } = useDependencies();
  const createLoginField = useCallback(
    async (payload: { companyId: number }, data: CreateLoginFieldProps) => {
      const response = await loginFieldInteractor.createLoginField(
        payload,
        data,
      );

      return response;
    },
    [],
  );

  return { createLoginField };
};

export const useUpdateLoginField: LoginFieldHooks['useUpdateLoginField'] = (): {
  updateLoginField: (
    params: {
      companyId: number;
      fieldId: number;
    },
    data: UpdateLoginFieldProps,
  ) => Promise<LoginField>;
} => {
  const { loginFieldInteractor } = useDependencies();
  const updateLoginField = useCallback(
    async (
      params: {
        companyId: number;
        fieldId: number;
      },
      data: UpdateLoginFieldProps,
    ) => {
      const response = await loginFieldInteractor.updateLoginField(
        params,
        data,
      );

      return response;
    },
    [],
  );
  return { updateLoginField };
};

export const useDeleteLoginField: LoginFieldHooks['useDeleteLoginField'] = (): {
  deleteLoginField: (params: {
    companyId: number;
    fieldId: number;
  }) => Promise<void>;
} => {
  const { loginFieldInteractor } = useDependencies();
  const deleteLoginField = useCallback(
    async (query: { companyId: number; fieldId: number }) => {
      const response = await loginFieldInteractor.deleteLoginField(query);

      return response;
    },
    [],
  );

  return { deleteLoginField };
};

export const useCheckDuplicateFieldName: LoginFieldHooks['useCheckDuplicateFieldName'] = (): {
  checkDuplicate: (query: {
    companyId: number;
    fieldName: string;
  }) => Promise<FetchLoginFieldProps>;
} => {
  const { loginFieldInteractor } = useDependencies();
  const checkDuplicate = useCallback(
    async (query: { companyId: number; fieldName: string }) => {
      const response = await loginFieldInteractor.checkDuplicateField(query);
      return response;
    },
    [],
  );

  return { checkDuplicate };
};
