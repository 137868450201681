import React, { useCallback, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { RejectApproveApplication } from '../../templates/RejectApproveApplication';
import { SubHeader } from '../../atoms/Subheader';
import { useRecordHooks } from '../../../hooks/record';
import { useMutation, useQuery } from 'react-query';
import { useGlobalState } from '../../../hooks/global';
import { useMemberHooks } from '../../../hooks/member';
import { CreateEntryApplication } from '../../../domain/entities/entry';
import { useEntryHooks } from '../../../hooks/entry';
import words from '../../../constants/words';
import { useRankingHooks } from '../../../hooks/rankings';
import { useNotificationHooks } from '../../../hooks/notification';
import { NotificationData } from '../../../domain/entities/notification';
import data from '../../../constants/data';
import { moveIsBasisFieldAtFirstPositionRecordApproval } from '../../../utils/arrays';
import { PageLoader } from '../../atoms/Loading';

export type addNotificationData = {
  companyId: number;
  data: NotificationData;
};

const Component = (): React.ReactElement => {
  const { recordId, rankingId, memberId } = useParams();
  const { useFetchReferenceDetails } = useRecordHooks();
  const { fetchReferenceDetails } = useFetchReferenceDetails();
  const { useFetchMembers } = useMemberHooks();
  const { fetchMember } = useFetchMembers();
  const { useUpdateEntry } = useEntryHooks();
  const { updateEntryApplication } = useUpdateEntry();
  const { useAddNotification } = useNotificationHooks();
  const { addNotification } = useAddNotification();
  const { useSetRankings } = useRankingHooks();
  const { getRanking } = useSetRankings();
  const { state: locationState } = useLocation();
  const {
    useCurrentUser: { currentUser },
  } = useGlobalState();
  const companyId = currentUser?.companyId;
  const navigate = useNavigate();

  const backOnpress = () => {
    if (locationState?.previousPath) navigate(locationState.previousPath);
    else navigate(`/approval`);
  };

  const updateStatus = (params: {
    status: 'rejected' | 'approved' | 'unapproved' | 'withdraw';
    reason?: string;
    approver_id: number;
  }) => {
    mutateUpdateEntry(
      {
        memberId: Number(memberId),
        rankingId: Number(rankingId),
        recordId: Number(recordId),
        payload: params,
      },
      {
        onSuccess: () => {
          navigate(`/approval`, {
            state: {
              updateData: { id: Number(recordId), status: params.status },
              toastMessage: {
                kind: 'success',
                title:
                  params.status === 'approved'
                    ? words.approvalSuccess
                    : words.approvalRejected,
                subtitle:
                  params.status === 'approved'
                    ? words.approvalSuccessMessage
                    : words.approvalRejectedMessage,
              },
            },
          });
        },
        onError: () => {
          navigate(`/approval`, {
            state: {
              updateData: undefined,
              toastMessage: {
                kind: 'error',
                title: words.approvalError,
                subtitle: words.approvalErrorMessage,
              },
            },
          });
        },
      },
    );
  };

  const handleAddNotitication = (isApproved: boolean) => {
    if (
      !currentUser?.companyId ||
      !memberId ||
      !rankingId ||
      !recordId ||
      !currentUser?.userId
    ) {
      throw new Error(`'company id' field not set`);
    }

    const notifData: addNotificationData = {
      companyId: currentUser.companyId,
      data: {
        is_approved: isApproved,
        approver_name: recordMainApprover?.group_approver.member?.name,
        applicant_name: memberData?.name,
        ranking_name: recordData?.entry.ranking.name,
        rankingId: Number(rankingId),
        entryId: Number(recordId),
        applicantId: memberData?.id,
        memberId: memberData?.id,
      },
    };
    addNotificationMutation(notifData);
  };

  const { data: memberData, isFetching: isFetchingMemberData } = useQuery({
    queryKey: ['member-data', companyId, memberId],
    queryFn: async () => {
      if (companyId && memberId) {
        return await fetchMember({ companyId, memberId: Number(memberId) });
      } else {
        throw new Error(`query param not set`);
      }
    },
    refetchOnMount: true,
  });

  const { data: recordData, isFetching: isFetchingRecordData } = useQuery(
    ['record-data', recordId, rankingId, memberId, companyId],
    async () => {
      if (!companyId || !memberId || !rankingId || !recordId) {
        throw new Error(`query params not set`);
      }
      const query = {
        companyId: companyId,
        memberId: Number(memberId),
        rankingId: Number(rankingId),
        entryId: Number(recordId),
      };

      return fetchReferenceDetails(query);
    },
    {
      refetchOnMount: true,
    },
  );

  const approveOnPress = useCallback(() => {
    if (currentUser) {
      updateStatus({
        status: 'approved',
        approver_id: currentUser?.memberId,
      });
      handleAddNotitication(true);
    }
  }, [memberData, recordData]);

  const onSubmitRejectApplication = useCallback(
    (reason?: string) => {
      if (currentUser) {
        updateStatus({
          status: 'rejected',
          reason,
          approver_id: currentUser.memberId,
        });
        handleAddNotitication(false);
      }
    },
    [memberData, recordData],
  );

  const { mutate: addNotificationMutation } = useMutation(
    ({ companyId, data }: addNotificationData) => {
      return addNotification({ companyId }, data);
    },
  );
  const recordMainApprover = recordData?.approvers.find(
    approver => approver.is_main,
  );

  const recordSubApprovers = recordData?.approvers
    .filter(approver => !approver.is_main)
    .map(approver => ({
      label: `${approver.group_approver.member?.name}`,
      value: `${approver.group_approver.id}`,
    }));

  const recordEntryLogs = recordData?.logs?.map(log => ({
    dateAndTime: log.created_at,
    action: log.action,
    reason: log.reason,
    memberName: log.member.name,
  }));

  const { mutate: mutateUpdateEntry } = useMutation(
    async (params: {
      memberId: number;
      rankingId: number;
      recordId: number;
      payload: CreateEntryApplication;
    }) => {
      if (!companyId || !memberId || !rankingId || !recordId) {
        throw new Error(`query params not set`);
      }

      const response = await updateEntryApplication(
        companyId,
        params.memberId,
        params.rankingId,
        params.recordId,
        params.payload,
      );
      return response;
    },
  );

  const { data: rankingDetails } = useQuery({
    queryKey: ['fetchRanking', companyId, rankingId],
    queryFn: async () => {
      if (companyId && rankingId) {
        return await getRanking({
          companyId: companyId,
          rankingId: Number(rankingId),
        });
      } else {
        throw new Error(`query params not set`);
      }
    },
    refetchOnMount: true,
  });

  const userInfoData = [
    {
      name: words.emailSetting,
      value: memberData?.email,
    },
    {
      name: words.companyId,
      value: memberData?.companyId,
    },
    {
      name: words.userId,
      value: memberData?.username
        .substring(memberData.username.indexOf('A'))
        ?.substring(1)
        .padStart(4, '0'),
    },
    {
      name: words.roleLoginSetting,
      value: data.memberRoles.find(e => e.id === memberData?.roleName)?.name,
    },
  ];

  const getCustomFields = () => {
    const basisFirstCustomFields = moveIsBasisFieldAtFirstPositionRecordApproval(
      rankingDetails?.ranking_fields,
    );
    const rankingCustomFieldValues = basisFirstCustomFields
      .filter(item => item.is_required && !item.is_default)
      .sort((a, b) => Number(a.order_id) - Number(b.order_id))
      .map(item => {
        if (item.data_type === 'decimal') {
          const applicationCustomValue = recordData?.entry.fields.find(
            field => field.field_lookup?.id === item.id,
          );
          return {
            name: item.name,
            value_char: undefined,
            value_decimal: applicationCustomValue?.value_decimal || undefined,
          };
        } else {
          const userInfoField = userInfoData.find(x => x.name === item.name);
          if (userInfoField) {
            return {
              name: item.name,
              value_char: `${userInfoField?.value}` || undefined,
              value_decimal: undefined,
            };
          }
          const memberCustomValue = memberData?.fields.find(
            field => field.field_lookup_id === item.member_field_lookup_id,
          );
          return {
            name: item.name,
            value_char: memberCustomValue?.value_char || undefined,
            value_decimal: undefined,
          };
        }
      });

    return rankingCustomFieldValues;
  };

  useEffect(() => {
    if (!isFetchingRecordData) {
      const isWithdrawStatus = recordData?.status === 'withdraw';
      const isAnApprover = recordData?.approvers.find(
        approver =>
          approver.group_approver.member?.id === currentUser?.memberId,
      );
      if (isWithdrawStatus || !isAnApprover) navigate('/page-not-found');
    }
  }, [isFetchingRecordData]);

  return (
    <React.Suspense fallback={<div />}>
      <SubHeader
        title={recordData?.entry.ranking.name || ''}
        onBack={backOnpress}
        isStickyOnMobile
        hasBackOnMobile
      />
      {isFetchingRecordData ? (
        <PageLoader />
      ) : (
        <RejectApproveApplication
          backOnPress={backOnpress}
          approveOnPress={approveOnPress}
          onSubmitRejectApplication={onSubmitRejectApplication}
          histories={recordEntryLogs || []}
          customFields={getCustomFields()}
          name={memberData?.name}
          group={memberData?.group?.name}
          applicationDate={recordData?.entry.application_date}
          salesDate={recordData?.entry.sales_date}
          notes={recordData?.notes}
          status={recordData?.status || ''}
          mainApprover={recordMainApprover?.group_approver.member?.name}
          subApprovers={recordSubApprovers || []}
          profileImageSrc={memberData?.photoUrl || undefined}
          isLoadingProfImage={isFetchingMemberData}
        />
      )}
    </React.Suspense>
  );
};

export default Component;
