import { AxiosResponse } from 'axios';
import MemberRepository from '../../ports/MemberRepository';

export type batchUpdateMembersGroupUseCase = (
  companyId: number,
  memberIds: number[],
  groupId: number,
) => Promise<AxiosResponse>;

export const buildBatchUpdateMembersGroup = (dependencies: {
  memberRepo: MemberRepository;
}): batchUpdateMembersGroupUseCase => {
  const { memberRepo } = dependencies;

  const batchUpdateMembersGroup: batchUpdateMembersGroupUseCase = async (
    companyId,
    memberIds,
    groupId,
  ) => memberRepo.batchUpdateGroup(companyId, memberIds, groupId);

  return batchUpdateMembersGroup;
};
