import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import { TextArea } from 'carbon-components-react';
import { theme } from '../../../config';

export type Props = {
  id: string;
  onChange?: (event: ChangeEvent) => void;
  value?: string;
  maxLength?: number;
  labelText?: string;
  invalid?: boolean;
  invalidText?: string;
  placeholder?: string;
  helperText?: string;
  hideLabel?: boolean;
  charCounter?: boolean;
  disabled?: boolean;
};

const LabelContainer = styled.div`
  display: flex;
  margin-bottom: 1px;
`;

const Label = styled.text`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.32px;
  color: ${theme.colors.text02};
`;

const CounterContainer = styled.div`
  margin-left: auto;
`;

const StyledTextArea = styled(TextArea)<{
  filled: true | false;
}>`
  &&&& {
    background-color: ${theme.colors.white};
    border: 1px solid ${theme.colors.icon03};
    border-radius: 8px;
    border-bottom: 1px solid ${theme.colors.icon03};
    box-sizing: border-box;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.16px;
    color: ${theme.colors.text01};
    &.bx--text-area:focus {
      background-color: ${theme.colors.white};
      outline: 2px solid ${theme.colors.primaryButton1};
      outline-offset: -2px;
    }
    &.bx--text-area:active {
      background-color: ${theme.colors.white};
      outline: 2px solid ${theme.colors.primaryButton1};
      outline-offset: -2px;
    }
    &.bx--text-area--invalid {
      background-color: ${theme.colors.field01};
    }
    &.bx--text-area:disabled {
      background: ${theme.colors.disabled01};
      border: 1px solid ${theme.colors.disabledBorder};
    }
    &.bx--text-area::placeholder {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.16px;
      color: ${theme.colors.text03};
    }
  }
`;

const Component = ({
  id,
  onChange = () => {},
  value = '',
  maxLength = 100,
  labelText,
  invalid = false,
  invalidText,
  placeholder = '',
  helperText,
  hideLabel = false,
  charCounter = true,
  disabled = false,
}: Props): React.ReactElement => {
  const onChangeHandle = (event: ChangeEvent<Element>) => {
    const target = event.target as HTMLInputElement;
    if (target.value.length > maxLength) {
      target.value = target.value.slice(0, maxLength);
    }
    onChange(event);
  };

  return (
    <div>
      {!hideLabel ? (
        <LabelContainer>
          <Label>{labelText}</Label>
          {charCounter ? (
            <CounterContainer>
              <Label>{`${value.length}/${maxLength}`}</Label>
            </CounterContainer>
          ) : null}
        </LabelContainer>
      ) : null}
      <StyledTextArea
        id={id}
        placeholder={placeholder}
        hideLabel={true}
        labelText={''}
        maxLength={maxLength}
        onChange={onChangeHandle}
        invalid={invalid}
        helperText={helperText}
        invalidText={invalidText}
        value={value}
        disabled={disabled}
        filled={value.length > 0}
      />
    </div>
  );
};

export default Component;
