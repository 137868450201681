import AuthService from '../../ports/AuthService';

export type adminFirstLoginUseCase = (
  user_id: string,
  password: string,
  password2: string,
  email: string,
  file?: File[],
  customFields?: { identifier: string; value: string }[],
  company_id?: string,
) => Promise<object | null>;

export const buildAdminFirstLogin = (dependencies: {
  authService: AuthService;
}): adminFirstLoginUseCase => {
  const { authService } = dependencies;

  const adminFirstLogin: adminFirstLoginUseCase = (
    user_id,
    password,
    password2,
    email,
    file,
    customFields,
    company_id,
  ) =>
    authService.adminFirstLogin(
      user_id,
      password,
      password2,
      email,
      file,
      customFields,
      company_id,
    );
  return adminFirstLogin;
};
