import React, { useState, useEffect } from 'react';
import './accountSettingForm.scss';
import words from 'constants/words';
import {
  accountSettingType,
  accountSettingErrType,
  listGroupOptionType,
  groupOptionType,
  groupType,
} from 'type/member';
import Select from 'react-select';
import { getCurrentUser } from 'utils/common';
import urls from 'constants/urls';
import authHeader from 'api/auth-header';
import axios from 'axios';

type PropType = {
  handleUpdateValues: (object: accountSettingType) => void;
  listError: accountSettingErrType;
  isSetCompanyId: boolean;
  userInfo: accountSettingType;
  modeSee?: boolean;
};

export default function AccountSettingForm(props: PropType) {
  const {
    handleUpdateValues,
    listError,
    isSetCompanyId,
    userInfo,
    modeSee,
  } = props;
  const [listOption, setListOption] = useState<listGroupOptionType>([]);
  const [value, setValue] = useState<accountSettingType>(userInfo);

  const roleOptions = [
    { value: 'member', label: 'Applicant' },
    { value: 'approver', label: 'Approver' },
    { value: 'admin', label: 'Admin', isDisabled: true },
  ];

  function newValueUpdate(data: string, type: string) {
    setValue(prevState => ({ ...prevState, [type]: data.toString() }));
  }

  useEffect(() => {
    handleUpdateValues(value);
  }, [value]);

  useEffect(() => {
    setValue(userInfo);
  }, [userInfo]);

  useEffect(() => {
    const user = getCurrentUser();
    if (user.company_id !== null) {
      const fetchData = async () => {
        await axios
          .get(`${urls.get_group}?company_id=${user.company_id}`, {
            headers: authHeader(),
          })
          .then(res => {
            const groupOption: listGroupOptionType = [];
            res.data.data.map((item: groupType) => {
              const newData: groupOptionType = {
                value: '',
                label: '',
              };
              newData.value = item.id.toString();
              newData.label = item.name;
              groupOption.push(newData);
              setListOption(groupOption);
            });
          });
      };

      fetchData();
    }
  }, []);

  return (
    <div className="form-account-setting">
      <table>
        <tbody>
          {/* Full name field */}
          <tr>
            <td colSpan={2}>
              <label
                className={
                  value.full_name && value.full_name.length > 0
                    ? 'filled'
                    : 'not-filled'
                }>
                {words.fullNameRequired}
              </label>
              <br />
              <input
                className="input-signup"
                type="text"
                placeholder={words.fullNamePlaceholder}
                value={value.full_name}
                onChange={e => newValueUpdate(e.target.value, 'full_name')}
                required
                autoComplete="new-password"
                disabled={modeSee}
              />
            </td>
          </tr>
          <tr>
            <td
              className={
                listError.full_name && listError.full_name.length > 0
                  ? 'errorMessage'
                  : 'noMessage'
              }
              colSpan={2}>
              {listError.full_name && listError.full_name.length > 0
                ? listError.full_name
                : ''}
            </td>
          </tr>
          {/* CompanyID & User ID Filed */}
          <tr>
            {/* CompanyID */}
            <td colSpan={1} className="colSpan1">
              <label
                className={
                  (value.company_id && value.company_id.length > 0) ||
                  isSetCompanyId
                    ? 'filled'
                    : 'not-filled'
                }>
                {words.companyId}
              </label>
              <br />
              <input
                className="input-signup"
                type="text"
                placeholder="111"
                value={value.company_id ? value.company_id : ''}
                onChange={e => newValueUpdate(e.target.value, 'company_id')}
                required
                autoComplete="new-password"
                disabled={isSetCompanyId ? true : false}
              />
            </td>
            {/* User ID */}
            <td colSpan={1} className="colSpan1">
              <label className="disabledLabel">{words.userId}</label>
              <br />
              <input
                className="input-signup"
                type="text"
                placeholder="11"
                value={value.user_id ? value.user_id : ''}
                onChange={e => newValueUpdate(e.target.value, 'user_id')}
                required
                autoComplete="new-password"
                disabled
              />
            </td>
          </tr>
          <tr>
            <td
              className={
                listError.company_id && listError.company_id.length > 0
                  ? 'errorMessageSplit'
                  : 'noMessage'
              }
              colSpan={1}>
              {listError.company_id && listError.company_id.length > 0
                ? listError.company_id
                : ''}
            </td>
            <td
              className={
                listError.user_id && listError.user_id.length > 0
                  ? 'errorMessageSplit'
                  : 'noMessage'
              }
              colSpan={1}>
              {listError.user_id && listError.user_id.length > 0
                ? listError.user_id
                : ''}
            </td>
          </tr>
          {/* Group Fields */}
          <tr>
            <td colSpan={2}>
              <label
                className={
                  isSetCompanyId === false || modeSee ? 'filled' : 'not-filled'
                }>
                {words.groupName}
              </label>
              <br />
              {value && (
                <Select
                  value={listOption.filter(
                    option => option.value === value.group_id,
                  )}
                  options={listOption}
                  className="selectGroup"
                  classNamePrefix={modeSee ? 'select2' : 'select'}
                  placeholder={
                    <span className="placeholder-option">
                      {words.groupName}
                    </span>
                  }
                  isSearchable={false}
                  isDisabled={!isSetCompanyId || modeSee}
                  onChange={e => newValueUpdate(e!.value, 'group_id')}
                />
              )}
            </td>
          </tr>
          <tr>
            <td
              className={
                listError.role && listError.role.length > 0
                  ? 'errorMessage'
                  : 'noMessage'
              }
              colSpan={2}>
              {listError.role && listError.role.length > 0
                ? listError.role
                : ''}
            </td>
          </tr>
          {/* Role field */}
          <tr>
            <td colSpan={2}>
              <label
                className={
                  isSetCompanyId === false || modeSee ? 'filled' : 'not-filled'
                }>
                {words.roleLabel}
              </label>
              <br />
              <Select
                value={roleOptions.filter(
                  option => option.value === value.role,
                )}
                options={roleOptions}
                className="roleOption"
                classNamePrefix={modeSee ? 'select2' : 'select'}
                placeholder={
                  <span className="placeholder-option">{words.roleLabel}</span>
                }
                isSearchable={false}
                isOptionDisabled={option => option.label === 'Admin'}
                isDisabled={!isSetCompanyId || modeSee}
                onChange={e => newValueUpdate(e!.value, 'role')}
              />
            </td>
          </tr>
          <tr>
            <td
              className={
                listError.role && listError.role.length > 0
                  ? 'errorMessage'
                  : 'noMessage'
              }
              colSpan={2}>
              {listError.role && listError.role.length > 0
                ? listError.role
                : ''}
            </td>
          </tr>
          {/* Email field */}
          <tr>
            <td colSpan={2}>
              <label className="disabledLabel">{words.emailAdd}</label>
              <br />
              <input
                className="input-signup"
                type="text"
                placeholder="emailuser@gmail.com"
                value={value.email}
                required
                autoComplete="new-password"
                disabled
              />
            </td>
          </tr>
        </tbody>
      </table>
      <div className="chatwork-notification">
        <div className="text-notification">チャットワークで通知を受け取る</div>
        <input
          type="checkbox"
          className={`input-checkbox`}
          checked={false}
          onClick={() => console.log('Handle choose Chatwork')}
        />
      </div>
    </div>
  );
}
