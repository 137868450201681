import React from 'react';
import styled from 'styled-components';
import { theme } from '../../../config';
import { PropsValue } from 'react-select';
import NumberFormat from 'react-number-format';
import words from '../../../constants/words';

import { TextInput as Input } from '../../atoms/TextInput';
import { DatePicker } from '../../molecules/DatePicker';
import { IOptions as MultiSelectOptions } from '../MultiSelectDropdown/MultiSelectDropdown';
import { FetchEntryFieldProps } from '../../../domain/entities/entry';
import { StatusTypes } from '../RecordAppForm/WithdrawAppForm';
import { GroupApprover } from '../../../domain/entities/group';
import { EntryLogProps } from '../../molecules/TransactionHistoryEntry/TransactionHistoryEntry';
import { moveIsBasisFieldAtFirstPosition } from '../../../utils/arrays';

export type AppFormType = {
  applicationDate: Date;
  salesDate: Date;
  mainApprover?: GroupApprover;
  subApprovers?: PropsValue<MultiSelectOptions>;
  notes: string | undefined;
  customFields: Array<FetchEntryFieldProps>;
  status?: StatusTypes;
  entryLogs?: EntryLogProps[];
};

export type Props = {
  values: AppFormType;
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const DatesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;

  & > div {
    flex: 1;
  }

  & > div:first-child {
    margin-right: 14px;
  }

  @media ${theme.breakpoints.mobile} {
    flex-direction: column;
    margin-bottom: 0;

    & > div {
      margin-bottom: 23px;
    }

    & > div:first-child {
      margin-right: 0;
    }
  }
`;

const TextInput = styled(Input)`
  margin-top: 20px;

  .bx--text-input:disabled {
    border: 1px solid ${theme.colors.inputBorderColor};
    background: ${theme.colors.white};
    color: ${theme.colors.text01};
    -webkit-text-fill-color: ${theme.colors.text01};
  }

  .bx--label--disabled {
    color: ${theme.colors.text02};
  }

  @media ${theme.breakpoints.mobile} {
    margin-top: 0;

    &.application-notes {
      display: none;
    }
  }
`;

const StyledDatePicker = styled(DatePicker)`
  .bx--date-picker--single > div > div > .bx--date-picker__input:disabled {
    border: 1px solid ${theme.colors.inputBorderColor};
    background-color: ${theme.colors.white};
    color: ${theme.colors.text01};
  }
  .bx--label--disabled {
    color: ${theme.colors.text02};
  }
  .bx--date-picker__input:disabled ~ .bx--date-picker__icon {
    fill: ${theme.colors.text01};
  }
`;

const Component = (props: Props): React.ReactElement => {
  const basisFirstCustomFields = moveIsBasisFieldAtFirstPosition(
    props.values.customFields,
  );
  const renderCustomValues = () => {
    return basisFirstCustomFields.map(
      (item: FetchEntryFieldProps, index: number) => {
        const fieldLabel = `${item.field_lookup_name}`;
        const fieldValue = item.value_decimal
          ? parseFloat(`${item.value_decimal}`)
          : '';
        return (
          <NumberFormat
            key={index}
            id={`${index}`}
            customInput={TextInput}
            value={fieldValue.toLocaleString()}
            label={fieldLabel}
            maxLength={255}
            thousandSeparator
            disabled={true}
            hasStarIndex={index === 0}
          />
        );
      },
    );
  };

  return (
    <Wrapper>
      <DatesWrapper>
        <StyledDatePicker
          label={words.applicationDate}
          value={props.values.applicationDate}
          datePickerType="single"
          disabled
        />
        <StyledDatePicker
          label={words.salesDate}
          value={props.values.salesDate}
          datePickerType="single"
          disabled
        />
      </DatesWrapper>
      {renderCustomValues()}
      <TextInput
        id="application-notes"
        className="application-notes"
        label={words.notes}
        value={`${props.values.notes}`}
        disabled
      />
    </Wrapper>
  );
};

export default Component;
