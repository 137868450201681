import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { theme } from '../../../config';

export type SubAccordionItemProps = {
  label?: string;
  onClick: React.ReactEventHandler;
  to?: string;
};

const SubAccordionItem = styled(NavLink)`
  width: 100%;
  height: 35px;
  cursor: pointer;
  font-size: 14px;
  font-weight: normal;
  color: ${theme.colors.text02};

  display: flex;
  align-items: center;
  padding-left: 30px;

  text-decoration: none;

  &:hover {
    background: ${theme.colors.dropDownContainerBgColor};
    color: ${theme.colors.primaryButton1};
  }

  &.active {
    font-weight: bold;
    color: ${theme.colors.primaryButton1};
  }
`;

const Component = ({
  label = '',
  onClick,
  to = '',
}: SubAccordionItemProps): React.ReactElement => {
  return (
    <SubAccordionItem
      onClick={event => {
        event.stopPropagation();
        onClick(event);
      }}
      to={to}>
      {label}
    </SubAccordionItem>
  );
};

export default Component;
