import MemberRepository from '../../ports/MemberRepository';

export type generateTemporaryPasswordUseCase = (
  companyId: number,
  memberId: number,
) => Promise<{ temporary_password: string }>;

export const buildGenerateTemporaryPassword = (dependencies: {
  memberRepo: MemberRepository;
}): generateTemporaryPasswordUseCase => {
  const { memberRepo } = dependencies;

  const generateTemporaryPassword: generateTemporaryPasswordUseCase = async (
    companyId,
    memberId,
  ) => memberRepo.generateTemporaryPassword(companyId, memberId);

  return generateTemporaryPassword;
};
