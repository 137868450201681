import React, { useState } from 'react';
import './memberForgotPass.scss';
import LogoSelfPro from 'assets/icons/self-pro-logo.svg';
import { OrangeButton } from 'components/atoms/OrangeButton';
import words from 'constants/words';
import { memberForgotType } from 'type/member';
import { ForgotPassForm } from 'components/templates/ForgotPassForm';
import { validateForgot } from 'components/templates/ForgotPassForm/validates';
import { ForgotSuccessModal } from 'components/templates/ForgotSuccessModal';
import { Loading } from 'components/molecules/Loading';
import { useNavigate } from 'react-router-dom';
import url from 'constants/urls';
import urls from 'constants/urls';
import axios from 'axios';
import { extractError } from 'utils/common';
import ForgotImg from 'assets/images/forgotImg.jpeg';

const defaultValue = {
  email: '',
};

export default function LoginPage() {
  const [showModal, setShowModal] = useState('none');
  const [errors, setErrors] = useState(defaultValue);
  const [errorResponse, setErrorResponse] = useState('');
  const [isLoading, setIsLoading] = useState('none');
  const navigate = useNavigate();

  const [forgotValue, setForgotValue] = useState<memberForgotType>(
    defaultValue,
  );

  async function onSubmitForgot() {
    setErrorResponse('');
    const listError = await validateForgot(forgotValue);
    if (Object.entries(listError).length !== 0) {
      setErrors(listError);
      return;
    }
    // Handle Login
    setErrors(defaultValue);
    setIsLoading('block');
    await axios
      .post(urls.member_forgot, forgotValue)
      .then(() => {
        setIsLoading('none');
        setErrors(defaultValue);
        setShowModal('block');
      })
      .catch(err => {
        if (err.response.data.errors) {
          const errorSv = extractError(err.response.data.errors);
          setErrorResponse(errorSv);
        }
        setIsLoading('none');
      });
  }

  function handleUpdateValues(data: memberForgotType) {
    setForgotValue(data);
  }

  function handleClickOk() {
    navigate(urls.sign_in_page);
  }

  return (
    <div className="forgot-pages">
      <ForgotSuccessModal display={showModal} onClick={handleClickOk} />
      <Loading display={isLoading} />
      <div className="container-fluid">
        <div className="row flex-row-reverse">
          <div className="col-12 col-sm-6 div-left">
            <div className="self-pro-logo">
              <img src={LogoSelfPro} />
            </div>
            <div className="forgot-title">
              <p>{words.memberForgotPassTitle}</p>
            </div>
            <div className="forgot-desc">
              <p>{words.memberForgotDesc}</p>
            </div>
            <ForgotPassForm
              handleUpdateValues={data => handleUpdateValues(data)}
              listError={errors}
            />
            <p
              className="errorFromServer"
              dangerouslySetInnerHTML={{ __html: errorResponse }}></p>
            <div className="orangeButton">
              <OrangeButton text="送信" onClick={onSubmitForgot} />
            </div>
            <div className="go-to-login">
              {words.alreadyHaveAnAccount}
              <a href={url.signIn}> {words.alreadyLogin}</a>
            </div>
          </div>
          <div className="col-12 col-sm-6 image-right">
            <img src={ForgotImg} alt="Login Img" className="img-fluid" />
          </div>
        </div>
      </div>
    </div>
  );
}
