import * as Yup from 'yup';
import words from '../../../constants/words';

export const ChangePasswordModalSchema = Yup.object().shape({
  newPassword: Yup.string()
    .matches(/^[^\uFF01-\uFF5E]*$/, `${words.passwordMin}`)
    .min(6, `${words.passwordMin}`),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref('newPassword'), null],
    `${words.passwordMismatch}`,
  ),
});
