import React from 'react';

type Props = {
  [key: string]: string | number | Function;
};

const Copy = (props: Props): React.ReactElement => {
  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 3.37207H0V0.87207H17V3.37207ZM17 10.2178H0V7.71777H17V10.2178ZM0 17.0625H17V14.5625H0V17.0625Z"
        fill="#161616"
      />
    </svg>
  );
};

export default Copy;
