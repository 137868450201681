import React, { useState, useEffect } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { ApprovedNotif, DeclineNotif } from '../../atoms/Icons';
import words from '../../../constants/words';
import {
  DataTable,
  DataTableSkeleton,
  Table,
  TableBody,
  DataTableCustomRenderProps,
  DataTableRow,
} from 'carbon-components-react';
import {
  CustomTableRowNotif,
  CustomTableCell,
  CustomPagination,
  NotifTextWrapper,
  NotifText,
  NotifTableContainer,
  NotifMobileWrapper,
  NotifMobileColumnWrapper,
  NotifMobileDateWrapper,
  NotifMobileRowWrapper,
  NotifTextNoWrap,
} from './elements';
import moment from 'moment';
import ReceivedApplicationNotif from '../../atoms/Icons/ReceivedApplicationNotif';
import { theme } from '../../../config';

const HEADERS = [
  {
    header: 'No',
    key: 'approvedStatus',
  },
  {
    header: 'No',
    key: 'message',
  },
  {
    header: 'No',
    key: 'dateTime',
  },
];

const HeaderMobile = [
  {
    header: 'No',
    key: 'mobileMessage',
  },
];

export type NotificationItem = {
  id: string;
  approvedStatus?: boolean;
  rankingName: string;
  approver?: string;
  applicant?: string;
  dateTime: Date;
  readStatus: boolean;
  rankingId: number;
  entryId: number;
  memberId: number;
  isApprover: boolean;
};

export type PropsNotificationTable = {
  notifRows?: NotificationItem[];
  isLoading?: boolean;
  className?: string;
  selectedRow: (id: number) => void;
  page?: number;
  pageSize?: number;
  totalItems?: number;
  onChangePage: (data: { page: number; pageSize: number }) => void;
};

const Component = ({
  notifRows,
  className,
  isLoading,
  selectedRow,
  page,
  pageSize,
  totalItems,
  onChangePage,
}: PropsNotificationTable): React.ReactElement => {
  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const getStatusIcon = (isApprover: boolean, status?: boolean) => {
    if (isApprover) return <ReceivedApplicationNotif />;
    if (!isApprover && status) {
      return <ApprovedNotif />;
    }
    if (!isApprover && !status) {
      return <DeclineNotif />;
    }
  };

  /**
   * Format for the Notif Message:（Ranking/Application Name）some text（Ranking / Approver Name）some text。
   * Format depends on what kind of notification it is
   */
  const getMessage = (
    isApprover: boolean,
    rankingName: string,
    applicantName: string,
    status: boolean | undefined,
    isRead: boolean,
    approverName?: string,
    isMobile?: boolean,
  ) => {
    const {
      applicationGradedNotif: {
        backTo,
        isApproved,
        isRejected,
        isApproved2,
        isRejected2,
        isApprovedFull,
        isRejectedFull,
      },
      applicationReceivedNotif: {
        from,
        receivedApplicationForGrades,
        receivedApplicationForGrades2,
        receivedApplicationForGradesFull,
      },
    } = words;

    const messageDetails = isApprover
      ? {
          firstName: applicantName,
          secondName: rankingName,
          preposition: from,
          action:
            (rankingName && rankingName.length > 12) ||
            (width <= theme.breakpoints.smallMobileWidth &&
              rankingName.length > 5)
              ? receivedApplicationForGrades
              : receivedApplicationForGradesFull,
          action2: receivedApplicationForGrades2,
          actionFull: receivedApplicationForGradesFull,
        }
      : {
          firstName: rankingName,
          secondName: approverName,
          preposition: backTo,
          action: status
            ? (approverName && approverName.length > 12) ||
              (width <= theme.breakpoints.smallMobileWidth &&
                approverName &&
                approverName.length > 5)
              ? isApproved
              : isApprovedFull
            : (approverName && approverName.length > 12) ||
              (width <= theme.breakpoints.smallMobileWidth &&
                approverName &&
                approverName.length > 5)
            ? isRejected
            : isRejectedFull,
          action2: status ? isApproved2 : isRejected2,
          actionFull: status ? isApprovedFull : isRejectedFull,
        };
    return isMobile ? (
      <NotifMobileColumnWrapper>
        <NotifTextWrapper>
          <NotifTextNoWrap $isBold={!isRead}>
            {messageDetails.firstName}
          </NotifTextNoWrap>
          {messageDetails.firstName && messageDetails.firstName.length < 24 && (
            <NotifText>{messageDetails.preposition}</NotifText>
          )}
        </NotifTextWrapper>
        {messageDetails.firstName && messageDetails.firstName.length >= 24 && (
          <NotifText>{messageDetails.preposition}</NotifText>
        )}
        <NotifTextWrapper>
          <NotifTextNoWrap $isBold={!isRead}>
            {messageDetails.secondName}
          </NotifTextNoWrap>
          <NotifText>{messageDetails.action}</NotifText>
        </NotifTextWrapper>
        {((messageDetails.secondName &&
          messageDetails.secondName.length > 12) ||
          (width <= theme.breakpoints.smallMobileWidth &&
            messageDetails.secondName &&
            messageDetails.secondName.length > 5)) && (
          <NotifText>{messageDetails.action2}</NotifText>
        )}
      </NotifMobileColumnWrapper>
    ) : (
      <NotifTextWrapper>
        <NotifText $isBold={!isRead}>{messageDetails.firstName}</NotifText>
        <NotifText>{messageDetails.preposition}</NotifText>
        <NotifText $isBold={!isRead}>{messageDetails.secondName}</NotifText>
        <NotifText>{messageDetails.actionFull}</NotifText>
      </NotifTextWrapper>
    );
  };
  const isMobile = width <= 768;
  return isLoading || notifRows === undefined ? (
    <DataTableSkeleton
      showToolbar={false}
      showHeader={false}
      columnCount={HEADERS.length}
      className={className}
    />
  ) : (
    <NotifTableContainer className={className}>
      <DataTable
        rows={notifRows as DataTableRow[]}
        headers={isMobile ? HeaderMobile : HEADERS}>
        {({ rows, getTableProps, getRowProps }: DataTableCustomRenderProps) => {
          return (
            <Table {...getTableProps()}>
              <TableBody>
                <TransitionGroup>
                  {rows.map(row => {
                    const notifItem =
                      notifRows[rows.map(e => e.id).indexOf(row.id)];
                    return (
                      <CSSTransition
                        key={row.id}
                        classNames="notif-item"
                        timeout={300}>
                        <CustomTableRowNotif
                          {...getRowProps({ row })}
                          key={row.id}
                          $isRead={notifItem?.readStatus}
                          onClick={() => {
                            selectedRow(Number(notifItem.id));
                          }}>
                          {row.cells.map(cell => (
                            <CustomTableCell key={cell.id}>
                              {cell.info.header === 'approvedStatus' ? (
                                getStatusIcon(notifItem?.isApprover, cell.value)
                              ) : cell.info.header === 'message' ? (
                                getMessage(
                                  notifItem?.isApprover,
                                  notifItem?.rankingName,
                                  notifItem?.applicant || '',
                                  notifItem?.approvedStatus,
                                  notifItem?.readStatus,
                                  notifItem?.approver,
                                )
                              ) : cell.info.header === 'dateTime' ? (
                                `${moment(notifItem?.dateTime).format(
                                  'YYYY/MM/DD',
                                )} - ${moment(notifItem?.dateTime).format(
                                  'HH:mm',
                                )}`
                              ) : cell.info.header === 'mobileMessage' ? (
                                <NotifMobileRowWrapper>
                                  <NotifMobileWrapper>
                                    {getStatusIcon(
                                      notifItem?.isApprover,
                                      notifItem?.approvedStatus,
                                    )}
                                    {getMessage(
                                      notifItem?.isApprover,
                                      notifItem?.rankingName,
                                      notifItem?.applicant || '',
                                      notifItem?.approvedStatus,
                                      notifItem?.readStatus,
                                      notifItem?.approver,
                                      true,
                                    )}
                                  </NotifMobileWrapper>
                                  <NotifMobileDateWrapper>
                                    {`${moment(notifItem?.dateTime).format(
                                      'YYYY/MM/DD',
                                    )} - ${moment(notifItem?.dateTime).format(
                                      'HH:mm',
                                    )}`}
                                  </NotifMobileDateWrapper>
                                </NotifMobileRowWrapper>
                              ) : (
                                cell.value
                              )}
                            </CustomTableCell>
                          ))}
                        </CustomTableRowNotif>
                      </CSSTransition>
                    );
                  })}
                </TransitionGroup>
              </TableBody>
            </Table>
          );
        }}
      </DataTable>
      <CustomPagination
        pageSizes={[20, 40, 60, 80, 100, 120]}
        page={page}
        pageSize={pageSize}
        totalItems={totalItems}
        onChange={onChangePage}
        itemRangeText={(min: number, max: number, total: number) =>
          words.paginationNotif(min, max, total)
        }
        itemsPerPageText={words.itemsPerPageNotif}
        pageRangeText={(_current: number, total: number) =>
          `${words.pageNumber[0]} ${total} ${words.pageNumber[1]}`
        }
      />
    </NotifTableContainer>
  );
};

export default Component;
