import { NotificationHooks } from '.';
import { useDependencies } from '..';
import {
  CheckNotificationCount,
  Notification,
  NotificationData,
  UpdateNotificationData,
} from '../../domain/entities/notification';
import { useCallback } from 'react';
import { PaginationQuery } from '../../domain/entities/request';
import { PaginatedResponse } from '../../domain/entities/response';
import { useGlobalState } from '../global';

export const useFetchNotifications: NotificationHooks['useFetchNotifications'] = (): {
  fetchNotifications: (
    query: PaginationQuery & {
      companyId: number;
      userId?: number;
    },
  ) => Promise<PaginatedResponse & { results: Notification[] }>;
} => {
  const { notificationInteractor } = useDependencies();
  const fetchNotifications = useCallback(
    async (query: { companyId: number }) => {
      const response = await notificationInteractor.fetchNotifications(query);

      return response;
    },
    [],
  );

  return { fetchNotifications };
};

export const useAddNotification: NotificationHooks['useAddNotification'] = (): {
  addNotification: (
    payload: { companyId: number },
    data: NotificationData,
  ) => Promise<Response>;
} => {
  const { notificationInteractor } = useDependencies();
  const addNotification = useCallback(
    async (payload: { companyId: number }, data: NotificationData) => {
      const response = await notificationInteractor.addNotification(
        payload,
        data,
      );
      return response;
    },
    [],
  );
  return { addNotification };
};

export const useUpdateNotification: NotificationHooks['useUpdateNotification'] = (): {
  updateNotification: (
    notificationId: number,
    data: UpdateNotificationData,
  ) => Promise<Response>;
} => {
  const { notificationInteractor } = useDependencies();
  const { useCurrentUser } = useGlobalState();
  const { currentUser } = useCurrentUser;
  const updateNotification = useCallback(
    async (notificationId: number, data: UpdateNotificationData) => {
      if (!currentUser?.companyId) {
        throw new Error(`field 'company id' missing`);
      }
      const response = await notificationInteractor.updateNotification(
        { companyId: currentUser.companyId, notificationId },
        data,
      );
      return response;
    },
    [],
  );
  return { updateNotification };
};

export const useCheckNotificationCount: NotificationHooks['useCheckNotificationCount'] = (): {
  checkNotificationCount: (query: {
    companyId: number;
    isRead?: boolean;
    userId?: number;
  }) => Promise<CheckNotificationCount>;
} => {
  const { notificationInteractor } = useDependencies();
  const checkNotificationCount = useCallback(
    async (query: { companyId: number; isRead?: boolean; userId?: number }) => {
      return await notificationInteractor.checkNotificationCount(query);
    },
    [],
  );

  return { checkNotificationCount };
};
