import en from './en';
import ja from './ja';

const isProduct = true;
const words = {
  en,
  ja,
};

// this is set to export since there are some wordings that has not same length from en and ja
// this is to determine if the app is using en or ja languages
export const languageUsed = isProduct ? 'ja' : 'en';

export default words[isProduct ? 'ja' : 'en'];
