import * as Yup from 'yup';
import words from '../../../constants/words';
import data from '../../../constants/data';

const validateObject = Yup.object().shape({
  applicationDate: Yup.date().required(words.required),
  salesDate: Yup.date().required(words.required),
  customFields: Yup.array().of(
    Yup.object().shape({
      valueForValidation: Yup.string().when(['is_required', 'is_basis'], {
        is: (is_required: boolean, is_basis: boolean) =>
          is_required || is_basis,
        then: Yup.string().required(words.required),
      }),
    }),
  ),
});

export const RecordApplicationSchema = (roleName: string): any => {
  if (data.roleManagement.includes(roleName)) {
    return validateObject.concat(
      Yup.object().shape({
        mainApprover: Yup.object().nullable(true),
      }),
    );
  } else {
    return validateObject.concat(
      Yup.object().shape({
        mainApprover: Yup.object().required(words.required),
      }),
    );
  }
};
