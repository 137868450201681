import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';

import words from '../../../constants/words';
import { theme } from '../../../config';
import themes from '../../../config/themes';

import { Modal } from 'carbon-components-react';

import { Button } from '../../atoms/Button';

const StyledModal = styled(Modal)`
  .bx--modal-container {
    background-color: ${theme.colors.field01};
    border-radius: 8px;
    max-width: ${props => (props.size === 'lg' ? 'none' : '672px')};
    overflow: ${props => (props.hasScrollingContent ? 'hidden' : 'visible')};

    @media ${themes.main.breakpoints.mobileAndTablet} {
      .bx--modal-header__heading {
        padding-right: 2px;
        color: ${theme.colors.text01};
        line-height: 20px;
      }
      width: 90%;
      height: auto;
    }
    @media ${themes.main.breakpoints.mobile} {
      position: absolute;
      left: 5%;
      right: 10%;
      top: 33px;
    }
  }

  .bx--modal-close {
    border: none;
  }

  .bx--modal-close:hover {
    background: none;
  }

  .bx--modal-close:focus {
    border: none;
  }

  .bx--modal-header__label {
    font-size: 20px;
    font-weight: 500;
    color: ${theme.colors.text01};
    letter-spacing: 0px;
  }

  .bx--modal-header__heading {
    font-size: 14px;
    color: ${theme.colors.text02};
    letter-spacing: 0.16px;
  }

  .bx--modal-content {
    margin-bottom: 0;
    overflow: ${props => (props.hasScrollingContent ? 'hidden' : 'visible')};
    overflow-y: visible;
  }

  .bx--modal-content:focus {
    outline: none;
  }

  .bx--modal-scroll-content > *:last-child {
    padding-bottom: 0;
  }

  .bx--modal-content p,
  .bx--modal-content__regular-content {
    font-size: 13px;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.16px;
  }

  @media ${theme.breakpoints.landscape.mobile} {
    .bx--modal-container {
      overflow: hidden;
      max-height: 90%;
    }

    .bx--modal-content {
      overflow: hidden;
    }
  }
`;

const ChildrenContainer = styled.div<{ mergedWithButtons: boolean }>`
  margin-bottom: 22px;

  &.bx--has-scrolling-content {
    height: calc(100% - 104px);
    overflow-y: auto;
  }

  @media ${theme.breakpoints.landscape.mobile} {
    height: ${props =>
      props.mergedWithButtons ? 'auto' : ' calc(100% - 104px)'};
    overflow: ${props => (props.mergedWithButtons ? 'hidden' : ' auto')};
    margin-bottom: ${props => (props.mergedWithButtons ? '30' : '22')}px;
  }
`;

const ButtonsContainer = styled.div<{
  mobileButtonSpan: boolean;
  mergedWithButtons: boolean;
}>`
  margin: 18px 0;
  display: flex;
  flex-wrap: wrap;
  @media ${themes.main.breakpoints.mobile} {
    flex-direction: column-reverse;
    margin: ${props => (props.mergedWithButtons ? '0 0 20px' : ' 0')};
  }

  & > button {
    flex: 1;
    font-size: 16px;
    padding-top: 18px;
    padding-bottom: 18px;
    line-height: 16px;
  }

  & > button:first-child {
    margin-right: 8px;
    @media ${themes.main.breakpoints.mobile} {
      margin-right: ${props => (props.mobileButtonSpan ? '0' : '8px')};
      width: ${props => (props.mobileButtonSpan ? '100%' : '256px')};
      padding: ${props => (props.mobileButtonSpan ? '16.5' : '13.5')}px;
    }
  }

  & > button:last-child {
    margin-left: ${props => (props.mobileButtonSpan ? '0' : '8px')};
    @media ${themes.main.breakpoints.mobile} {
      margin-left: 0px;
      width: ${props => (props.mobileButtonSpan ? '100%' : '256px')};
      padding: ${props => (props.mobileButtonSpan ? '16.5' : '15')}px;
      margin-bottom: ${props =>
        props.mobileButtonSpan && !props.mergedWithButtons ? '0' : '15'}px;
    }
  }

  & > .bx--btn--primary:focus {
    box-shadow: inset 0 0 0 1px ${theme.colors.white};
  }
`;

export type Props = {
  open: boolean;
  title: string;
  label: string;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  children?: React.ReactNode;
  className?: string;
  hideButtons?: boolean;
  confirmButtonText: string;
  confirmButtonTheme?: 'primary' | 'secondary' | 'tertiary' | 'light';
  confirmButtonDisabled?: boolean;
  cancelButtonDisabled?: boolean;
  preventCloseOnClickOutside?: boolean;
  hasScrollingContent?: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  onClose: () => void;
  hideCancelButton?: boolean;
  mobileButtonSpan?: boolean;
  mergedWithButtons?: boolean;
  enableScrollToTop?: boolean;
};

const Component = ({
  open,
  title,
  label,
  children,
  className,
  size = 'md',
  confirmButtonText,
  confirmButtonTheme,
  onConfirm,
  onCancel,
  onClose,
  hideButtons = false,
  confirmButtonDisabled = false,
  cancelButtonDisabled = false,
  preventCloseOnClickOutside = false,
  hasScrollingContent = false,
  hideCancelButton = false,
  mobileButtonSpan = false,
  mergedWithButtons = false,
  enableScrollToTop = false,
}: Props): React.ReactElement => {
  const childrenContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (enableScrollToTop && open && childrenContainerRef.current)
      childrenContainerRef.current.scrollTo(0, 0);
  }, [enableScrollToTop, open, childrenContainerRef]);

  return (
    <StyledModal
      size={size}
      open={open}
      passiveModal={true}
      modalHeading={label}
      modalLabel={title}
      onRequestClose={onClose}
      hasScrollingContent={hasScrollingContent}
      preventCloseOnClickOutside={preventCloseOnClickOutside}
      className={className}>
      <ChildrenContainer
        ref={childrenContainerRef}
        className={
          hasScrollingContent
            ? 'bx--has-scrolling-content'
            : 'bx--children-container'
        }
        mergedWithButtons={mergedWithButtons}>
        {children}
      </ChildrenContainer>
      {!hideButtons && (
        <ButtonsContainer
          mobileButtonSpan={mobileButtonSpan}
          mergedWithButtons={mergedWithButtons}
          className="buttonsContainer">
          {!hideCancelButton && (
            <Button
              onPress={onCancel}
              theme="tertiary"
              title={words.cancel}
              disabled={cancelButtonDisabled}
            />
          )}
          <Button
            onPress={onConfirm}
            theme={confirmButtonTheme}
            title={confirmButtonText}
            disabled={confirmButtonDisabled}
          />
        </ButtonsContainer>
      )}
    </StyledModal>
  );
};

export default Component;
