import styled from 'styled-components';
import { Pagination } from 'carbon-components-react';
import themes from '../../../config/themes';
import { EmptyResultDisplay } from '../../organisms/EmptyResultDisplay';

export const DataTableContainer = styled.div`
  margin: 48px 78px 48px 73px;
  width: 87.5%;
  box-shadow: 0px 0px 8px rgba(236, 215, 192, 0.8);
  padding: 2px;

  &.ranking-app-list-no-results {
    background: ${themes.main.colors.ui02};
  }

  @media ${themes.main.breakpoints.mobile} {
    width: inherit;
    padding: 0;
    margin: 13px;
    background-color: ${themes.main.colors.white};

    &.ranking-app-list-no-results {
      height: calc(100% - 83px);
    }
  }
`;

export const StyledEmptyResultDisplay = styled(EmptyResultDisplay)`
  @media ${themes.main.breakpoints.mobile} {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &.ranking-app-list-empty-table {
      height: calc(100% - 114px);
    }
  }
`;

export const DataTableWrapper = styled.div`
  width: 100%;
  height: fit-content;

  @media ${themes.main.breakpoints.mobile} {
    display: none;
  }
`;

export const StatusText = styled.p`
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;

  &.approval-status-unapproved {
    color: ${themes.main.colors.text02};
  }

  &.approval-status-approved {
    color: ${themes.main.colors.support02};
  }

  &.approval-status-rejected {
    color: ${themes.main.colors.support01};
  }

  &.approval-status-withdraw {
    color: ${themes.main.colors.primaryButton1};
  }
`;

export const ToolBar = styled.div`
  width: 100%;
  height: 64px;
  background-color: ${themes.main.colors.ui01};
  display: flex;
  flex-direction: row;
  align-items: center;

  @media ${themes.main.breakpoints.mobile} {
    flex-direction: column;
    align-items: center;
    height: 110px;
    margin-bottom: 4px;
    width: 100%;
  }
`;

export const DropdownWrapper = styled.div<{ marginTop?: string }>`
  width: 320px;
  height: 40px;
  margin-left: 16px;

  @media ${themes.main.breakpoints.mobile} {
    width: 92.19%;
    margin-left: 0px;
    margin-top: ${(props): string =>
      props.marginTop ? props.marginTop : '0px'};
  }

  &.dropdown-filter-all div.bx--list-box__menu > div:first-child > div {
    color: ${themes.main.colors.text03};
  }
`;

export const MobileListContainer = styled.div`
  @media ${themes.main.breakpoints.pc} {
    display: none;
  }
  @media ${themes.main.breakpoints.tablet} {
    display: none;
  }
  width: 100%;
  height: max-content;
  background: white;
`;

export const MobileItemWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  background-color: ${themes.main.colors.ui01};
  margin-bottom: 5px;
`;

export const ItemNumber = styled.p`
  width: 10.5px;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  padding: 0.625rem 1.4375rem 0rem 1.5rem;
  color: ${themes.main.colors.text02};
`;

export const ItemWrapper = styled.div`
  width: calc(100% - 10.5px);
  display: flex;
  flex-direction: column;
  padding: 0.625rem 0rem 0.625rem 0rem;
`;

export const DateStatusWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const DateLabel = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${themes.main.colors.ui04};
`;

export const StatusLabel = styled.p`
  font-weight: 500;
  font-size: 11px;
  line-height: 18px;
  text-transform: capitalize;
  margin-right: 13px;

  &.approval-status-unapproved {
    color: ${themes.main.colors.ui04};
  }

  &.approval-status-approved {
    color: ${themes.main.colors.support02};
  }

  &.approval-status-rejected {
    color: ${themes.main.colors.support01};
  }

  &.approval-status-withdraw {
    color: ${themes.main.colors.textLingOrange};
  }
`;

export const ItemName = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: ${themes.main.colors.text01};
`;

export const ApproverName = styled.p`
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: ${themes.main.colors.text01};
`;

export const CustomPagination = styled(Pagination)`
  &&&& {
    background-color: ${themes.main.colors.ui01};
    border: none;
    border-top: thin solid ${themes.main.colors.ui03};
    overflow: hidden;

    .bx--select-input {
      padding: 0 2rem 0 var(--cds-spacing-05, 1rem);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      &:focus {
        outline: none;
      }
    }

    .bx--select-input .bx--select-option {
      background-color: ${themes.main.colors.paginationDropdownBackground};
      color: ${themes.main.colors.text01};
      border: thin solid ${themes.main.colors.paginationDropdownBorder};
      border-radius: 6px;

      &:hover {
        background-color: ${themes.main.colors.paginationDropdownHover};
      }
    }

    .bx--pagination__button,
    .bx--btn--ghost.bx--pagination__button {
      width: 3rem;

      &:focus {
        outline: none;
      }
    }

    .bx--btn--ghost:focus {
      box-shadow: none;
    }

    .bx--pagination__left .bx--pagination__text {
      font-size: 13px;
      line-height: 18px;
      color: ${themes.main.colors.text01};
    }

    .bx--pagination__right .bx--pagination__text {
      font-size: 13px;
      line-height: 18px;
      color: ${themes.main.colors.text01};
    }
  }
`;
