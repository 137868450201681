import React from 'react';
import { Formik, FormikProps } from 'formik';
import styled from 'styled-components';
import { theme } from '../../../config';
import words from '../../../constants/words';

import { Banner } from '../../molecules/Banner';
import { LoginForm } from '../../organisms/LoginForm';
import { LoginSchema } from './validation';
import BannerImage from '../../../assets/images/team-handshake.jpg';
import LogoImage from '../../../assets/images/ever-contest.png';
import { ChangeTempPasswordModal } from '../ChangeTempPasswordModal';
import { Notification } from '../../molecules/Notification';

const Wrapper = styled.div`
  display: flex;
  background-color: ${theme.colors.ui01};
  min-height: 100vh;
  @media ${theme.breakpoints.pc} {
    height: 100vh;
    max-height: 100%;
  }
  @media ${theme.breakpoints.mobileAndTablet} {
    padding: 6% 1rem 5%;
  }
`;

const LeftWrapper = styled.div`
  @media ${theme.breakpoints.mobileAndTablet} {
    width: 100%;
    height: 100%;
  }
  @media ${theme.breakpoints.pc} {
    width: 50%;
    height: 100%;
    padding-left: 4%;
    padding-right: 4%;
    padding-top: 4%;
  }
`;

const RightWrapper = styled.div`
  width: 50%;
  height: 100%;
  @media ${theme.breakpoints.mobileAndTablet} {
    display: none;
  }
`;

const TextHeader = styled.div`
  display: flex;
  margin-bottom: 5%;
  flex-direction: column;
  justify-content: space-between;
  @media ${theme.breakpoints.mobileAndTablet} {
    margin-bottom: 14%;
  }
`;

const LoginTitle = styled.div`
  font-size: 28px;
  line-height: 36px;
  color: ${theme.colors.blackText};
  @media ${theme.breakpoints.mobile} {
    font-size: 20px;
    line-height: 26px;
    font-weight: 500;
    white-space: nowrap;
  }
`;

const Logo = styled.div<{ imageUrl: string }>`
  background-image: url(${props => props.imageUrl});
  background-repeat: no-repeat;
  background-size: contain;
  height: 23px;
  margin-bottom: 5%;
  @media ${theme.breakpoints.mobile} {
    height: 18px;
  }
`;

const ToastNotifWrapper = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  margin-right: 9px;

  @media ${theme.breakpoints.mobileAndTablet} {
    margin: 0;
    .bx--toast-notification:first-child {
      margin: 8px 10px 0;
    }
  }
`;

export type LoginFormProps = {
  companyId: string;
  userId: string;
  password: string;
};

export type LoginProps = {
  login: (values: object) => void;
  handleForgotPass: () => void;
  handleRegister: () => void;
  loginError?: unknown;
  signUpSuccess?: boolean;
  resetPasswordStatus?: string;
  showResetPasswordModal?: boolean;
  onChangePassword: (values: object) => void;
  resetPasswordSuccess?: boolean;
  loginFormikRef?: React.Ref<FormikProps<LoginFormProps>>;
};

const Component = ({
  login,
  handleForgotPass,
  handleRegister,
  loginError,
  signUpSuccess,
  resetPasswordStatus,
  onChangePassword,
  showResetPasswordModal = false,
  resetPasswordSuccess = false,
  loginFormikRef,
}: LoginProps): React.ReactElement => {
  const initialValues = {
    companyId: '',
    userId: '',
    password: '',
  };

  return (
    <>
      {resetPasswordSuccess && (
        <ToastNotifWrapper>
          <Notification
            kind="success"
            title={words.changedPasswordSuccessNotifTitle}
            subtitle={words.changePasswordSuccessNotifSubtitle}
            timeout={5000}
          />
        </ToastNotifWrapper>
      )}
      <Wrapper>
        <LeftWrapper>
          <TextHeader>
            <Logo imageUrl={LogoImage} />
            <LoginTitle>{words.everContestLogin}</LoginTitle>
          </TextHeader>
          <Formik
            initialValues={initialValues}
            validationSchema={LoginSchema}
            innerRef={loginFormikRef}
            onSubmit={login}>
            {({
              handleChange,
              handleSubmit,
              values,
              errors,
            }): React.ReactElement => {
              const { companyId, userId, password } = values;
              const {
                companyId: companyIdError,
                userId: userIdError,
                password: passwordError,
              } = errors;
              return (
                <LoginForm
                  companyId={companyId}
                  userId={userId}
                  password={password}
                  onChangeCompanyId={handleChange('companyId')}
                  onChangeUserId={handleChange('userId')}
                  onChangePassword={handleChange('password')}
                  companyIdError={companyIdError}
                  userIdError={userIdError}
                  passwordError={passwordError}
                  onPressLogin={handleSubmit}
                  onPressForgotPass={handleForgotPass}
                  onPressRegister={handleRegister}
                  loginError={loginError}
                  signUpSuccess={signUpSuccess}
                  resetPasswordStatus={resetPasswordStatus}
                />
              );
            }}
          </Formik>
        </LeftWrapper>
        <RightWrapper>
          <Banner imageUrl={BannerImage} label={words.bannerLabel} />
        </RightWrapper>
      </Wrapper>
      <ChangeTempPasswordModal
        isOpen={showResetPasswordModal}
        onChangePassword={onChangePassword}
      />
    </>
  );
};

export default Component;
