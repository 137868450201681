import { NotificationData } from '../../../domain/entities/notification';
import NotificationRepository from '../../ports/NotificationRepository';

export type addNotificationUseCase = (
  payload: {
    companyId: number;
  },
  data: NotificationData,
) => Promise<Response>;

export const buildAddNotification = (dependencies: {
  notifRepo: NotificationRepository;
}): addNotificationUseCase => {
  const { notifRepo } = dependencies;

  const addNotification: addNotificationUseCase = async (query, data) =>
    notifRepo.addNotification(query, data);

  return addNotification;
};
