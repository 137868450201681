import React from 'react';

type Props = {
  [key: string]: string | number | Function;
};

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="66"
      height="62"
      viewBox="0 0 66 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.817 0C24.4828 0 29.8865 5.4037 29.8865 12.0695C29.8865 18.7353 24.4828 24.139 17.817 24.139C11.1512 24.139 5.74746 18.7353 5.74746 12.0695C5.74746 5.4037 11.1512 0 17.817 0V0Z"
        fill="#F5E7D8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.0197 14.0811C59.6855 14.0811 65.0892 19.4848 65.0892 26.1506C65.0892 32.8164 59.6855 38.2201 53.0197 38.2201C46.3539 38.2201 40.9502 32.8164 40.9502 26.1506C40.9502 19.4848 46.3539 14.0811 53.0197 14.0811V14.0811Z"
        fill="#F5E7D8"
      />
      <circle cx="15.0869" cy="14.4764" r="8.04634" fill="#FF714F" />
      <circle cx="50.2896" cy="30.5691" r="8.04634" fill="#FF714F" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M65.7358 62.0001H61.3534V55.4265C61.3534 51.7961 58.4103 48.853 54.7798 48.853H46.015C42.3846 48.853 39.4415 51.7961 39.4415 55.4265V62.0001H35.0591V55.4265C35.0591 49.3757 39.9642 44.4706 46.015 44.4706H54.7798C60.8306 44.4706 65.7358 49.3757 65.7358 55.4265V62.0001ZM50.3975 22.5587C54.028 22.5587 56.9711 25.5018 56.9711 29.1323C56.9711 32.7628 54.028 35.7059 50.3975 35.7059C46.767 35.7059 43.8239 32.7628 43.8239 29.1323C43.8239 25.5018 46.767 22.5587 50.3975 22.5587ZM50.3975 18.1766C44.3467 18.1766 39.4415 23.0817 39.4415 29.1325C39.4415 35.1833 44.3467 40.0885 50.3975 40.0885C56.4483 40.0885 61.3534 35.1833 61.3534 29.1325C61.3534 23.0817 56.4483 18.1766 50.3975 18.1766ZM30.6767 47.4883H26.2943V40.9147C26.2943 37.2843 23.3512 34.3412 19.7207 34.3412H10.956C7.32547 34.3412 4.38238 37.2843 4.38238 40.9147V47.4883H0V40.9147C0 34.8639 4.90515 29.9588 10.956 29.9588H19.7207C25.7715 29.9588 30.6767 34.8639 30.6767 40.9147V47.4883ZM15.3384 8.04645C18.9689 8.04645 21.912 10.9895 21.912 14.62C21.912 18.2505 18.9689 21.1936 15.3384 21.1936C11.7079 21.1936 8.76483 18.2505 8.76483 14.62C8.76483 10.9895 11.7079 8.04645 15.3384 8.04645ZM15.3384 3.66428C9.28757 3.66428 4.38242 8.56942 4.38242 14.6202C4.38242 20.671 9.28757 25.5762 15.3384 25.5762C21.3892 25.5762 26.2943 20.671 26.2943 14.6202C26.2943 11.7145 25.14 8.92784 23.0854 6.8732C21.0308 4.81856 18.2441 3.66428 15.3384 3.66428Z"
        fill="#3B1F34"
      />
    </svg>
  );
};

export default Component;
