import React, { ChangeEvent } from 'react';
import words from '../../../constants/words';
import { SearchBar } from './elements';

export type Props = {
  size: 'sm' | 'md' | 'lg' | 'xl';
  value: string | number;
  placeholder: string;
  onChangeSearch: (value: string) => void;
};

const Component = ({
  size,
  onChangeSearch,
  value,
}: Props): React.ReactElement => {
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChangeSearch(event.target.value);
  };

  return (
    <SearchBar
      labelText=""
      placeholder={words.searchPlaceholder}
      size={size}
      onChange={onChange}
      value={value}
    />
  );
};

Component.defaultProps = {
  size: 'sm',
  value: '',
  placeholder: words.searchPlaceholder,
};

export default Component;
