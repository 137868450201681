export const debouncer = (
  callback: { (text: string): void },
  delay: number,
): Function => {
  let timer: NodeJS.Timeout;
  return (text: string) => {
    clearTimeout(timer);
    timer = setTimeout(() => callback(text), delay);
  };
};
