import React from 'react';

import words from '../../../constants/words';

import { SubHeader } from '../../atoms/Subheader';
import { RecordReferenceList } from '../../templates/RecordReferenceList';

const Components = (): React.ReactElement => {
  return (
    <>
      <SubHeader title={words.recordReferencePageHeader} />
      <RecordReferenceList />
    </>
  );
};

export default Components;
