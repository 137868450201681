import React, { useState } from 'react';
import { number } from 'yup/lib/locale';
import './typeModeProfileBtn.scss';

type PropType = {
  mode: number;
  updateMode: (_mode: number) => void;
};

const StateButton = (props: PropType) => {
  const { mode, updateMode } = props;
  return (
    <div className="boxTypeProfileBtnContainer">
      <div className="typeBtnContainer">
        <button
          className={mode === 1 ? 'stateBtn active' : 'stateBtn'}
          onClick={() => updateMode(1)}>
          基本情報
        </button>
        <button
          className={mode === 2 ? 'stateBtn active' : 'stateBtn'}
          onClick={() => updateMode(2)}>
          成績
        </button>
      </div>
    </div>
  );
};

export default StateButton;
