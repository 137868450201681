import { RankingApplication } from '../../../domain/entities/ranking';
import { PaginationQuery } from '../../../domain/entities/request';
import { PaginatedResponse } from '../../../domain/entities/response';
import RankingRepository from '../../ports/RankingRepository';

export type fetchRankingAppListUseCase = (
  query: PaginationQuery & { companyId: number; memberId: number },
) => Promise<PaginatedResponse & { results: RankingApplication[] }>;

export const buildFetchRankingAppList = (dependencies: {
  rankingRepo: RankingRepository;
}): fetchRankingAppListUseCase => {
  const { rankingRepo } = dependencies;

  const fetchRankingAppList: fetchRankingAppListUseCase = async query =>
    rankingRepo.fetchRankingAppList(query);

  return fetchRankingAppList;
};
