import React from 'react';
import './signedUpSuccessModal.scss';
import { OrangeButton } from 'components/atoms/OrangeButton';
import words from 'constants/words';

type PropType = {
  display: string;
  onClick?: () => void;
  icon: string;
  message?: string;
  textButton?: string;
};

export default function SignUp(props: PropType) {
  const { display, onClick, icon, message, textButton } = props;

  return (
    <div className="modal-signed-up-sc" style={{ display: display }}>
      <div className="modal-content">
        <div className="modal-box">
          <img src={icon} />
          <p className="sc-title">{message}</p>
          <div className="sc-button">
            <OrangeButton text={textButton || ''} onClick={onClick} />
          </div>
        </div>
      </div>
    </div>
  );
}
