import { createContext, useContext } from 'react';
import { StatusTypes } from '../../components/templates/RecordReferenceList/types';
import {
  RecordReference,
  RecordApproval,
  FetchRecordApprovalsParam,
  FetchRecordApprovalsRankingNamesParams,
} from '../../domain/entities/record';
import { PaginationQuery } from '../../domain/entities/request';
import { PaginatedResponse } from '../../domain/entities/response';

export type RecordHooks = {
  useFetchRecordReferences: () => {
    fetchRecordReferences: (
      params: PaginationQuery & {
        companyId: number;
        memberId: number;
        rankingId?: number;
        status?: StatusTypes;
      },
    ) => Promise<PaginatedResponse & { results: RecordReference[] }>;
  };
  useFetchRecordApprovals: () => {
    fetchRecordApprovals: (
      params: PaginationQuery & FetchRecordApprovalsParam,
    ) => Promise<PaginatedResponse & { results: RecordApproval[] }>;
  };
  useFetchReferenceDetails: () => {
    fetchReferenceDetails: (params: {
      companyId: number;
      memberId: number;
      rankingId: number;
      entryId: number;
    }) => Promise<RecordReference>;
  };
  useFetchRecordApprovalsRankingNames: () => {
    fetchRecordApprovalsRankingNames: (
      params: FetchRecordApprovalsRankingNamesParams,
    ) => Promise<{ results: string[] }>;
  };
};

export const RecordHooksContext = createContext<RecordHooks | null>(null);

export const useRecordHooks = (): RecordHooks => {
  const context = useContext(RecordHooksContext);
  if (!context) {
    throw new Error(`Context not instantiated`);
  }

  return context;
};
