import React from 'react';
import styled from 'styled-components';
import { Loading, LoadingProps } from 'carbon-components-react';

export type Props = {
  isActive?: boolean;
  withOverlay?: boolean;
  isSmall?: boolean;
  description?: string;
  width?: number;
  height?: number;
  strokeWidth?: number;
};

const StyledLoading = styled(
  Loading as React.FC<
    { width: number; height: number; strokeWidth: number } & LoadingProps
  >,
)`
  &.bx--loading {
    width: ${props => `${props.width}`}px;
    height: ${props => `${props.height}`}px;
    && > svg > circle.bx--loading__stroke {
      stroke: #ff714f;
      stroke-width: ${props => `${props.strokeWidth}`}px;
    }
  }
`;

const Component = ({
  isActive = true,
  withOverlay = true,
  isSmall = false,
  description = 'loading indicator',
  width = 36,
  height = 36,
  strokeWidth = 10,
}: Props): React.ReactElement => {
  return (
    <StyledLoading
      width={width}
      height={height}
      active={isActive}
      withOverlay={withOverlay}
      small={isSmall}
      description={description}
      strokeWidth={strokeWidth}
    />
  );
};

export default Component;
