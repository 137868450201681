import React, { useState, useEffect } from 'react';
import './signUpForm.scss';
import HomeImg from 'assets/icons/home.svg';
import UserImg from 'assets/icons/user.svg';
import FullnameImg from 'assets/icons/fullname.svg';
import PassImg from 'assets/icons/lock.svg';
import HideEyeImg from 'assets/icons/hide-eye.svg';
import ShowEyeImg from 'assets/icons/show-eye.svg';
import words from 'constants/words';
import { memberSignUpType, memberSignUpErrType } from 'type/member';

type PropType = {
  handleUpdateValues: (object: memberSignUpType) => void;
  listError: memberSignUpErrType;
};

export default function SignUp(props: PropType) {
  const { handleUpdateValues, listError } = props;
  const [eyeMode, setEyeMode] = useState<boolean>(false);
  const [value, setValue] = useState<memberSignUpType>({
    company_id: '',
    full_name: '',
    email: '',
    password: '',
  });

  function newValueUpdate(data: string, type: string) {
    setValue(prevState => ({ ...prevState, [type]: data.toString() }));
  }

  useEffect(() => {
    handleUpdateValues(value);
  }, [value]);

  return (
    <div className="form-sign-up">
      <table>
        <tbody>
          <tr>
            <td>
              <img src={HomeImg} />
            </td>
            <td>
              <label
                className={
                  value.company_id.length > 0 ? 'filled' : 'not-filled'
                }>
                {words.memberSignUpCompanyIdTitle}
              </label>
              <br />
              <input
                className="input-signup"
                type="text"
                placeholder={words.memberSignUpCompanyIdPlaceholder}
                value={value.company_id}
                onChange={e => newValueUpdate(e.target.value, 'company_id')}
                required
                autoComplete="new-password"
              />
            </td>
          </tr>
          <tr>
            <td
              className={
                listError.company_id && listError.company_id.length > 0
                  ? 'errorMessage'
                  : 'noMessage'
              }
              colSpan={2}>
              {listError.company_id && listError.company_id.length > 0
                ? listError.company_id
                : ''}
            </td>
          </tr>
          <tr>
            <td>
              <img src={UserImg} />
            </td>
            <td>
              <label
                className={
                  value.full_name.length > 0 ? 'filled' : 'not-filled'
                }>
                {words.memberSignUpFullnameTitle}
              </label>
              <br />
              <input
                className="input-signup"
                type="text"
                placeholder="セルフ 太郎"
                value={value.full_name}
                onChange={e => newValueUpdate(e.target.value, 'full_name')}
                required
                autoComplete="new-password"
              />
            </td>
          </tr>
          <tr>
            <td
              className={
                listError.full_name && listError.full_name.length > 0
                  ? 'errorMessage'
                  : 'noMessage'
              }
              colSpan={2}>
              {listError.full_name && listError.full_name.length > 0
                ? listError.full_name
                : ''}
            </td>
          </tr>
          <tr>
            <td>
              <img src={FullnameImg} />
            </td>
            <td>
              <label
                className={value.email.length > 0 ? 'filled' : 'not-filled'}>
                {words.memberSignUpEmailTitle}
              </label>
              <br />
              <input
                className="input-signup"
                type="email"
                placeholder="self.pro@flat-holdings.co.jp"
                value={value.email}
                onChange={e => newValueUpdate(e.target.value, 'email')}
                required
                autoComplete="new-password"
              />
            </td>
          </tr>
          <tr>
            <td
              className={
                listError.email && listError.email.length > 0
                  ? 'errorMessage'
                  : 'noMessage'
              }
              colSpan={2}>
              {listError.email && listError.email.length > 0
                ? listError.email
                : ''}
            </td>
          </tr>
          <tr>
            <td>
              <img src={PassImg} />
            </td>
            <td className="pass-input">
              <label
                className={value.password.length > 0 ? 'filled' : 'not-filled'}>
                {words.memberSignUpPasswordTitle}
              </label>
              <br />
              <input
                className="input-signup"
                type={eyeMode ? 'text' : 'password'}
                placeholder={words.memberSignUpPasswordPlaceholder}
                value={value.password}
                onChange={e => newValueUpdate(e.target.value, 'password')}
                required
                autoComplete="new-password"
              />
              <div className="eyeIcon" onClick={() => setEyeMode(!eyeMode)}>
                <img src={eyeMode ? ShowEyeImg : HideEyeImg} />
              </div>
            </td>
          </tr>
          <tr>
            <td
              className={
                listError.password && listError.password.length > 0
                  ? 'errorMessage'
                  : 'noMessage'
              }
              colSpan={2}>
              {listError.password && listError.password.length > 0
                ? listError.password
                : ''}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
