import React, { ChangeEvent, useCallback, useState } from 'react';
import { Formik } from 'formik';

import words from '../../../constants/words';

import { Modal } from '../../molecules/Modal';
import { TextInput } from '../../atoms/TextInput';
import styled from 'styled-components';
import { theme } from '../../../config';
import { debouncer } from './../../../utils/debouncer';
import { useGlobalState } from '../../../hooks/global';
import { useMutation } from 'react-query';
import { useRankingHooks } from '../../../hooks/rankings';

type HelperTextProps = {
  hasError?: boolean;
};
const HelperText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;

  letter-spacing: 0.32px;

  color ${({ hasError }: HelperTextProps) =>
    hasError ? theme.colors.errorColor : theme.colors.text02};

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 4px 0px;
  padding-bottom: 14px;
`;

const StyledTextInput = styled(TextInput)`
  padding-top: 18px;
  .bx--text-input {
    margin-bottom: 0;
  }
  .bx--label {
    white-space: pre;
  }
`;

type Ranking = { rankingName: string };

export type Props = {
  isOpen: boolean;
  onClose: () => void;
  isEdit?: boolean;
  initialValues?: Ranking;
  onSubmit: (ranking: Ranking) => void;
};

// TODO to be handled in the parent component
const Component = ({
  isOpen,
  onClose,
  isEdit = false,
  initialValues = {
    rankingName: '',
  },
  onSubmit,
}: Props): React.ReactElement => {
  const {
    useCurrentUser: { currentUser },
  } = useGlobalState();
  const { useCheckDuplicateFieldRankingName } = useRankingHooks();
  const { checkDuplicateRankingName } = useCheckDuplicateFieldRankingName();
  const [rankingNameHasError, setRankingNameHasError] = useState<boolean>();
  const [isDebouncing, setIsDebouncing] = useState<boolean>();

  const removeSpaces = (name: string) => name.trim();

  const handleRankingName = useCallback(
    (value: string, setFieldValue: (field: string, value: any) => void) => {
      setFieldValue('rankingName', value);
      setRankingNameHasError(false);
      setIsDebouncing(true);
      const formattedText = removeSpaces(value);
      if (formattedText === initialValues.rankingName)
        return setRankingNameHasError(false);

      debounceRankingInput(formattedText);
    },
    [],
  );

  const { mutate: checkRankingName } = useMutation(
    ({
      companyId,
      rankingName,
    }: {
      companyId: number;
      rankingName: string;
    }) => {
      return checkDuplicateRankingName({ companyId, rankingName });
    },
    {
      onSuccess: () => setRankingNameHasError(true),
      onError: () => setIsDebouncing(false),
    },
  );

  const debounceRankingInput = debouncer((text: string) => {
    const queryCheckDuplicate: { companyId: number; rankingName: string } = {
      companyId: currentUser ? currentUser.companyId : 0,
      rankingName: text,
    };

    if (!currentUser?.companyId) {
      throw new Error(`'company id' field not set`);
    }

    checkRankingName(queryCheckDuplicate);
  }, 500);

  const handleSubmitRankingName = (ranking: Ranking) => {
    if (rankingNameHasError) {
      onClose();
      return;
    }

    onSubmit(ranking);
  };

  const handleCloseModal = (
    setFieldValue: (field: string, value: any) => void,
  ) => {
    setFieldValue('rankingName', initialValues.rankingName);
    setRankingNameHasError(false);
    onClose();
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmitRankingName}
      enableReinitialize>
      {({ handleSubmit, values, setFieldValue }): React.ReactElement => {
        const {} = values;

        return (
          <Modal
            open={isOpen}
            title={isEdit ? words.editRankingName : words.createRankingName}
            label={words.pleaseEnterRankingName}
            confirmButtonText={words.setSaveRanking}
            onConfirm={handleSubmit}
            onCancel={() => handleCloseModal(setFieldValue)}
            onClose={() => handleCloseModal(setFieldValue)}
            confirmButtonDisabled={!values.rankingName || isDebouncing}>
            <StyledTextInput
              id="set-ranking-name"
              label={`${words.rankingName}  ${words.requiredLabel}`}
              onChange={(event: ChangeEvent) =>
                handleRankingName(
                  (event.target as HTMLInputElement).value,
                  setFieldValue,
                )
              }
              value={values.rankingName}
              invalid={rankingNameHasError}
            />

            <HelperText hasError={rankingNameHasError}>
              {words.uniqueRankingName}
            </HelperText>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default Component;
