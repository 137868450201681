import React from 'react';

type Props = {
  [key: string]: string | number | Function;
};

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="49"
      height="62"
      viewBox="0 0 49 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M9.5 6.44513C9.5 4.78827 10.8431 3.44513 12.5 3.44513H36.7714C37.5587 3.44513 38.3145 3.75464 38.8757 4.30689L43.5 8.85776L47.6043 12.8968C48.1773 13.4608 48.5 14.2311 48.5 15.0351V56.5397C48.5 58.1966 47.1569 59.5397 45.5 59.5397H12.5C10.8431 59.5397 9.5 58.1966 9.5 56.5397V6.44513Z"
        fill="#F5E7D8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.325 16.1648L28.575 0.665005C28.1739 0.227635 27.5984 -0.0150864 27 0.000727291H4.5C2.01472 0.000727291 0 1.98344 0 4.42925V57.5715C0 60.0173 2.01472 62 4.5 62H40.5C42.9853 62 45 60.0173 45 57.5715V17.7148C45.0161 17.1259 44.7694 16.5595 44.325 16.1648ZM27 5.31485L39.6 17.7147H27V5.31485ZM4.5 4.42924V57.5715H40.5V22.1433H27C24.5147 22.1433 22.5 20.1606 22.5 17.7148V4.42924H4.5Z"
        fill="#3B1F34"
      />
      <path
        d="M15.2747 36.5481L14.2226 35.5127L13.1705 36.5481L10.4479 39.2275L9.36149 40.2966L10.4479 41.3657L18.1716 48.9667L19.2237 50.0021L20.2758 48.9667L35.7232 33.7647L36.8096 32.6956L35.7232 31.6265L33.0006 28.9471L31.9485 27.9117L30.8964 28.9471L19.2237 40.4344L15.2747 36.5481Z"
        fill="#FF714F"
        stroke="#3B1F34"
        strokeWidth="3"
      />
    </svg>
  );
};

export default Component;
