import * as Yup from 'yup';
import words from '../../../constants/words';

export const CompanyInfoSchema = Yup.object().shape({
  name: Yup.string().required(`${words.required}`),
  repName: Yup.string().required(`${words.required}`),
  email: Yup.string()
    .required(`${words.required}`)
    .email(words.invalidEmailFormat),
  telephone: Yup.string()
    .required(`${words.required}`)
    .matches(/^\d+$/, words.onlyHalfNumbersAccepted),
});

export const EmailValidation = Yup.string().email();
