import React, { useCallback, useEffect, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { theme } from '../../../config';
import { Button } from '../../atoms/Button';
import { Info, Plus } from '../../atoms/Icons';
import words from '../../../constants/words';
import { DataTable } from '../DataTable';
import TooltipComponent from '../../atoms/Tooltip/Tooltip';
import { AnchorTag } from '../../atoms/AnchorTag';
import { useNavigate, useLocation } from 'react-router';
import { SetRankingModal } from '../../templates/SetRankingModal';
import { useRankingHooks } from '../../../hooks/rankings';
import { useGlobalState } from '../../../hooks/global';
import { RankingFields } from '../../../domain/entities/rankings';
import { EmptyResultDisplay } from '../EmptyResultDisplay';
import { AxiosError } from 'axios';
import { navigateToErrorPage } from '../../../utils/handleErrorPage';

export const GlobalStyle = createGlobalStyle`
  .disable-active-ranking-tooltip {
    .bx--tooltip__content {
      width: 224px;
    }
    .bx--tooltip__caret {
      left: 90px;
    }
  }
`;

const Container = styled.div``;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  height: 64px;
`;

const CreateButton = styled(Button)`
  margin: 11px;
  width: auto;
  padding: 10px 61px 10px 13px;
  display: flex;
  &&& svg {
    height: 9px;
    width: 9px;
  }
`;

export const EditButton = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;

  letter-spacing: 0.16px;
  color: ${theme.colors.interactive04};
  padding-right: 32px;
  cursor: pointer;
  white-space: nowrap;
  :hover {
    text-decoration: underline;
  }
`;

export const DeleteButton = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;

  letter-spacing: 0.16px;
  color: ${theme.colors.errorColor};
  padding-right: 16px;
  cursor: pointer;
  white-space: nowrap;
  :hover {
    text-decoration: underline;
  }
`;

const InfoSpan = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;

  letter-spacing: 0.32px;

  color: ${theme.colors.text02};

  display: flex;
  align-items: center;
  svg {
    height: 17.5px;
    width: 17.5px;
    margin-right: 9.25px;
    margin-left: 6.25px;
  }
`;

export const StyledDivActions = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledTooltip = styled(TooltipComponent)`
  &&&&& {
    float: right;
  }
`;

export const StyledAnchorTag = styled(AnchorTag)`
  &&&&& {
    div {
      color: ${theme.colors.anchorTagModal};
    }
  }
`;

const StyledDataTable = styled(DataTable)`
  tbody tr td:last-child {
    width: 0;
  }
`;

const EmptyResultContainer = styled.div`
  background: ${theme.colors.white};

  & > div {
    width: 325px;
  }
`;

export type Props = {
  tabIndex?: number;
};

type row = {
  id: string;
  name: string;
  index: string;
  actions: string | React.ReactElement;
}[];

const HEADERS = [
  { key: 'id', header: 'No' },
  { key: 'name', header: words.rankingName },
  { key: 'index', header: words.rankDisplaySetting },
  { key: 'actions', header: words.actions },
];

const Component = ({ tabIndex }: Props): React.ReactElement => {
  const [queryParams, setQueryParams] = useState<{
    page: number;
    pageSize: number;
  }>({ page: 1, pageSize: 10 });
  const queryClient = useQueryClient();
  const [toDisableRankingId, setToDisableRankingId] = useState<
    number | undefined
  >(undefined);

  const [isAddingModalOpen, setIsAddingModalOpen] = useState(false);
  const { pathname: locationPathname } = useLocation();
  const navigate = useNavigate();
  const { useSetRankings } = useRankingHooks();
  const { updateRanking } = useSetRankings();

  const {
    useCurrentUser: { currentUser },
  } = useGlobalState();

  const {
    mutate: disableRankingMutation,
    isLoading: isDisablingRanking,
  } = useMutation(
    (id: number) => {
      return updateRanking(id, { is_active: false });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
        if (rankings.length === 1 && queryParams.page !== 1) {
          setQueryParams({ ...queryParams, page: queryParams.page - 1 });
        }

        setToDisableRankingId(undefined);
      },
    },
  );

  const { useGetRankingList } = useRankingHooks();

  const { getRankingList } = useGetRankingList();

  const toggleAddingModal = useCallback(() => {
    setIsAddingModalOpen(!isAddingModalOpen);
  }, [isAddingModalOpen]);

  const {
    data: { results: rankings, count: rankingListCount } = {
      results: [],
      count: 0,
    },
    isFetching: isFetchingRankings,
  } = useQuery(
    ['active-ranking', queryParams],
    async () => {
      if (!currentUser?.companyId) {
        throw new Error(`'company id' not set`);
      }
      const query = {
        companyId: currentUser.companyId,
        page: queryParams?.page,
        page_size: queryParams?.pageSize,
        isActive: true,
      };
      return getRankingList(query);
    },
    {
      onError: (err: AxiosError) => {
        navigateToErrorPage(navigate, err.response?.status, locationPathname);
      },
    },
  );

  const navigateToEdit = (path: string) => {
    navigate(path);
  };

  const handleEditBasic = (id: number) => {
    const rankingFieldIndex = rankings.findIndex(item => item.id === id);
    // const index_val = rankingValues.findIndex(item => item.id === fieldId);
    navigate('edit-basicinfo', {
      state: {
        rankingId: id,
        rankingName: rankings[rankingFieldIndex].name,
        rankingFields: rankings[rankingFieldIndex].ranking_fields,
      },
    });
  };

  const renderIndex = (id: number) => {
    return (
      <StyledDivActions>
        <EditButton
          onClick={e => {
            e.stopPropagation();
            handleEditBasic(id);
          }}>
          {words.editBasic}
        </EditButton>
        <EditButton
          onClick={e => {
            e.stopPropagation();
            navigateToEdit(`member/${id}/edit`);
          }}>
          {words.editMembers}
        </EditButton>
        <EditButton
          onClick={e => {
            e.stopPropagation();
            navigateToEdit(`edit-period/${id}`);
          }}>
          {words.editPeriod}
        </EditButton>
        {!isDisablingRanking && (
          <StyledTooltip
            message={words.disableRanking}
            direction={'bottom'}
            triggerElement={
              <DeleteButton
                onClick={ev => {
                  ev.stopPropagation();
                  setToDisableRankingId(id);
                }}>
                {words.disable}
              </DeleteButton>
            }
            isOpen={toDisableRankingId === id}
            onChange={(ev, { open: isOpen }) => {
              ev.stopPropagation();
              if (!isOpen) {
                setToDisableRankingId(undefined);
              }
            }}
            menuOffset={{ left: -50 }}
            tooltipClassName="disable-active-ranking-tooltip">
            <div className="bx--tooltip__footer">
              <StyledAnchorTag
                onPress={e => {
                  e.stopPropagation();
                  setToDisableRankingId(undefined);
                }}
                title={words.cancel}
              />
              <Button
                style={{ fontSize: 13 }}
                onPress={e => {
                  e.stopPropagation();
                  disableRankingMutation(id);
                }}
                title={words.archiveActiveRanking}
              />
            </div>
          </StyledTooltip>
        )}
      </StyledDivActions>
    );
  };

  const renderIndexForRow = (data: RankingFields[]) => {
    const rankingFieldData = data.filter(item => item.is_basis === true);
    return rankingFieldData[0]?.name;
  };

  const renderRowData = () => {
    const rows: row = rankings.map((item, index) => ({
      id: (
        (queryParams.page - 1) * queryParams.pageSize +
        index +
        1
      ).toString(),
      rankingID: item.id,
      name: item.name,
      index: renderIndexForRow(item.ranking_fields) as string,
      actions: renderIndex(item.id),
    }));

    return rows;
  };

  const handleClickRow = (id: string) => {
    // the onClickRow (Table) Function returns the Index id of the data since
    //we are using the index as id in rendering on the table.
    // thus we should get the actual id of the data to get the correct ID
    const rankingRowData = renderRowData();
    const selectedRowData = rankingRowData.filter(
      data => data.id === id,
    )[0] as any;
    if (selectedRowData && selectedRowData.rankingID) {
      navigateToEdit(`ranking-details/${selectedRowData.rankingID}`);
    }
  };

  useEffect(() => {
    // reset query params on tab change
    if (tabIndex) {
      setQueryParams({ page: 1, pageSize: 10 });
    }
  }, [tabIndex]);

  return (
    <Container>
      <GlobalStyle />
      <Row>
        <InfoSpan>
          <Info />
          {words.findActiveRankings}
        </InfoSpan>
        <CreateButton
          onPress={toggleAddingModal}
          title={words.createNewRanking}
          icon={Plus}
        />
      </Row>
      {rankings.length || isFetchingRankings ? (
        <StyledDataTable
          rows={renderRowData()}
          headers={HEADERS}
          onChangePage={setQueryParams}
          page={queryParams.page}
          pageSize={queryParams.pageSize}
          totalItems={rankingListCount}
          isLoading={isFetchingRankings}
          onClickRow={id => handleClickRow(id)}
        />
      ) : (
        <EmptyResultContainer>
          <EmptyResultDisplay
            title={words.noActiveRankingTitle}
            details={words.noActiveRankingDetails}
          />
        </EmptyResultContainer>
      )}
      <SetRankingModal
        isOpen={isAddingModalOpen}
        onClose={toggleAddingModal}
        onSubmit={ranking => {
          navigate('create', {
            state: {
              rankingName: ranking.rankingName,
            },
          });
        }}
      />
    </Container>
  );
};

export default Component;
