import { CheckNotificationCount } from '../../../domain/entities/notification';
import NotificationRepository from './../../ports/NotificationRepository';

export type checkNotificationCountUseCase = (query: {
  companyId: number;
  isRead?: boolean;
  userId?: number;
}) => Promise<CheckNotificationCount>;

export const buildCheckNotificationCount = (dependencies: {
  notifRepo: NotificationRepository;
}): checkNotificationCountUseCase => {
  const { notifRepo } = dependencies;

  const checkNotificationCount: checkNotificationCountUseCase = async query =>
    notifRepo.checkNotificationCount(query);

  return checkNotificationCount;
};
