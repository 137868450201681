import React from 'react';
import styled from 'styled-components';

import words from '../../../constants/words';
import { theme } from '../../../config';

import { Tabs } from '../../organisms/Tabs';
import { ApprovalList } from '../../organisms/ApprovalList';
import { UpdatedRecord } from '../../pages/RecordApproval/RecordApproval';

const Container = styled.div`
  border: 2px solid ${theme.colors.ui02};
  box-shadow: 0px 0px 8px rgba(236, 215, 192, 0.8);

  .bx--tab-content {
    padding: 0;
  }

  && .bx--tabs--scrollable {
    background-color: ${theme.colors.white};
  }

  &&
    .bx--tabs--scrollable__nav
    > li:last-child
    > .bx--tabs--scrollable__nav-link {
    border-right: 1px solid ${theme.colors.ui04};
  }

  &&
    .bx--tabs--scrollable__nav
    > li.bx--tabs--scrollable__nav-item--selected
    > button {
    border-top: 2px solid ${theme.colors.primaryButton1};
    &:hover {
      background-color: ${theme.colors.ui01};
    }
  }

  && .bx--tabs--scrollable__nav > li > button:focus {
    outline: none;
  }

  && .bx--tabs--scrollable__nav > li > button:focus-visible {
    outline: 2px solid ${theme.colors.primaryButton1};
  }

  &&
    .bx--tabs--scrollable__nav-item--selected
    > .bx--tabs--scrollable__nav-link {
    font-weight: 500;
  }

  @media ${theme.breakpoints.mobile} {
    border: none;
    box-shadow: none;

    & .bx--tabs--scrollable__nav,
    &&&& .bx--tabs--scrollable__nav > li,
    && .bx--tabs--scrollable__nav > li > button {
      width: 100%;
    }
  }
`;

export type Props = {
  newlyUpdatedRecord: UpdatedRecord | undefined;
};

const Component = ({ newlyUpdatedRecord }: Props): React.ReactElement => {
  return (
    <Container>
      <Tabs
        tabs={[
          {
            id: 'approval-list-main',
            label: words.myMainApprover,
            element: (
              <ApprovalList
                isMainApprover
                newlyUpdatedRecord={newlyUpdatedRecord}
              />
            ),
          },
          {
            id: 'approval-list-secondary',
            label: words.secondary,
            element: <ApprovalList newlyUpdatedRecord={newlyUpdatedRecord} />,
          },
        ]}
      />
    </Container>
  );
};

export default Component;
