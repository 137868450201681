import React from 'react';
import { BrowserRouter, useLocation } from 'react-router-dom';
import { getCurrentUser, isEmptyObject } from 'utils/common';
// import { useGlobalState } from '../../hooks/global';

import { GlobalRoutes } from './GlobalRoutes';
import { ProtectedRoutes } from './ProtectedRoutes';
import { UnprotectedRoutes } from './UnprotectedRoutes';

const RenderRoute = () => {
  // const {
  //   useCurrentUser: { currentUser },
  // } = useGlobalState();

  const userLoggedIn = getCurrentUser();

  const { pathname: locationPath } = useLocation();

  const isPathGlobal =
    locationPath === '/page-not-found' ||
    locationPath === '/server-error' ||
    locationPath === '/link-expired' ||
    locationPath.includes('/company-email-verification/');

  if (isPathGlobal) return <GlobalRoutes />;
  // if (currentUser?.isLoggedIn) {
  //   if (currentUser.emptyCustomFields === undefined) {
  //     return <ProtectedRoutes />;
  //   }
  // }
  // return <UnprotectedRoutes />;

  if (!isEmptyObject(userLoggedIn) && userLoggedIn.is_email_verified === true) {
    return <ProtectedRoutes />;
  }
  return <UnprotectedRoutes />;
};

const RootNavigator = (): React.ReactElement => {
  return (
    <React.Suspense fallback={<div>loading routes...</div>}>
      <BrowserRouter>
        <RenderRoute />
      </BrowserRouter>
    </React.Suspense>
  );
};

export default RootNavigator;
