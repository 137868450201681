import React, { useEffect, useState } from 'react';
import './signUpStyle.scss';
import LogoSelfPro from 'assets/icons/self-pro-logo.svg';
import { SignUpForm } from 'components/templates/SignUpForm';
import { OrangeButton } from 'components/atoms/OrangeButton';
import { SignedUpModal } from 'components/templates/SignedUpModal';
import { validateSignup } from 'components/templates/SignUpForm/validates';
import { SignedUpSuccessModal } from 'components/templates/SignedUpSuccessModal';
import { memberSignUpType } from 'type/member';
import words from 'constants/words';
import url from 'constants/urls';
import axios from 'axios';
import { Loading } from 'components/molecules/Loading';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { gapi } from 'gapi-script';
import GoogleLogin from 'react-google-login';
import {
  extractError,
  getCurrentUser,
  isEmptyObject,
  replaceUser,
  setCurrentUser,
} from 'utils/common';
import CheckCircle from 'assets/icons/check-circle.svg';
import ErrorCircle from 'assets/icons/error-circle.svg';
import urls from 'constants/urls';
import SignUpImg from 'assets/images/signup-img.jpeg';

const defaultValue = {
  company_id: '',
  full_name: '',
  email: '',
  password: '',
};

const snsDefaultValue = {
  email: '',
  full_name: '',
  sns_id: '',
};

export default function SignUp() {
  const [isShowModal, setIsShowModal] = useState('none');
  const [isShowSuccessModal, setIsShowSuccessModal] = useState('none');
  const [isLoading, setIsLoading] = useState('none');
  const [errors, setErrors] = useState(defaultValue);
  const [errorResponse, setErrorResponse] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const [verifyMode, setVerifyMode] = useState(false);
  const [snsValue, setSnsValue] = useState(snsDefaultValue);
  const [isShowSnsModal, setIsShowSnsModal] = useState('none');
  const navigate = useNavigate();

  const email = searchParams.get('_email');
  const user = getCurrentUser();

  useEffect(() => {
    if (
      searchParams.get('sign-up-verify-success') &&
      searchParams.get('sign-up-verify-success') === '1' &&
      !isEmptyObject(user) &&
      user.email === email
    ) {
      user.is_email_verified = true;
      replaceUser(user);
      setVerifyMode(true);
      setIsShowSuccessModal('block');
    }

    if (!isEmptyObject(user) && email && user.email !== email) {
      setVerifyMode(false);
      setIsShowSuccessModal('block');
    }

    if (
      searchParams.get('sign-up-verify-success') &&
      searchParams.get('sign-up-verify-success') !== '1'
    ) {
      setVerifyMode(false);
      setIsShowSuccessModal('block');
    }

    if (searchParams.get('sign-up-verify-success') && isEmptyObject(user)) {
      navigate(urls.sign_in_page);
    }

    const initClient = () => {
      gapi.client.init({
        clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        scope: '',
      });
    };
    gapi.load('client:auth2', initClient);
  }, []);

  const [valueSignUp, setValueSignUp] = useState<memberSignUpType>(
    defaultValue,
  );

  async function onSubmitSignUp() {
    setErrorResponse('');
    const listError = await validateSignup(valueSignUp);
    if (Object.entries(listError).length !== 0) {
      setErrors(listError);
      return;
    }
    setIsLoading('block');
    await axios
      .post(urls.member_sign_up, valueSignUp)
      .then(res => {
        setIsLoading('none');
        setIsShowModal('block');
        setCurrentUser(res.data);
      })
      .catch(err => {
        if (err.response.data.errors) {
          const errorSv = extractError(err.response.data.errors);
          setErrorResponse(errorSv);
        }
        setIsLoading('none');
      });
    setErrors(defaultValue);
  }

  function handleUpdateValues(data: memberSignUpType) {
    setValueSignUp(data);
  }

  function handleGotoLogin() {
    // Redirect user to login page when click.
    setIsShowModal('none');
    window.location.href = urls.signIn;
  }

  function backToProfile() {
    const currentUser = getCurrentUser();
    const check = isEmptyObject(currentUser);
    if (check) {
      window.location.href = urls.signIn;
    } else {
      navigate(urls.account_setting);
    }
  }

  async function responseGoogle(response: any) {
    const valueSns = {
      full_name: response.profileObj.name,
      sns_id: response.googleId,
      email: response.profileObj.email,
    };
    setSnsValue(valueSns);
    setIsLoading('block');
    setErrorResponse('');
    await axios
      .post(urls.sns_member_sign_up, valueSns)
      .then(res => {
        const user = getCurrentUser();
        setIsLoading('none');
        setIsShowSnsModal('block');
        if (isEmptyObject(user)) {
          setCurrentUser(res.data);
        }
      })
      .catch(err => {
        if (err.response.data.errors) {
          const errorSv = extractError(err.response.data.errors);
          setErrorResponse(errorSv);
        }
        setIsLoading('none');
      });
  }

  function handleFailFromGoogle() {
    setIsLoading('none');
    setVerifyMode(false);
    setIsShowSuccessModal('block');
  }

  return (
    <div className="signup-pages">
      <Loading display={isLoading} />
      <div className="container-fluid">
        <div className="row flex-row-reverse">
          <div className="col-12 col-sm-6 div-left">
            <SignedUpModal
              email={snsValue.email}
              display={isShowSnsModal}
              onClick={handleGotoLogin}
            />
            <SignedUpModal
              email={valueSignUp.email}
              display={isShowModal}
              onClick={handleGotoLogin}
            />
            <SignedUpSuccessModal
              display={isShowSuccessModal}
              onClick={
                verifyMode ? backToProfile : () => setIsShowSuccessModal('none')
              }
              icon={verifyMode ? CheckCircle : ErrorCircle}
              message={verifyMode ? words.registerComplete : words.registerFail}
              textButton={verifyMode ? words.backToProfile : words.close}
            />
            <div className="self-pro-logo">
              <img src={LogoSelfPro} />
            </div>
            <div className="sign-up-title">
              <p>{words.memberSignUpTitle}</p>
            </div>
            <SignUpForm
              handleUpdateValues={data => handleUpdateValues(data)}
              listError={errors}
            />
            <p
              className="errorFromServer"
              dangerouslySetInnerHTML={{ __html: errorResponse }}></p>
            <OrangeButton text={words.register} onClick={onSubmitSignUp} />
            <div className="fieldset-line">
              {words.memberSignUpAnotherMethod}
            </div>

            <div className="buttonGoogleBox">
              <GoogleLogin
                clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}
                buttonText={words.memberSignUpGoogleRegister}
                onSuccess={responseGoogle}
                onFailure={handleFailFromGoogle}
                cookiePolicy={'single_host_origin'}
                className="buttonGoogle"
              />
            </div>
            <div className="go-to-login">
              {words.alreadyHaveAnAccount}
              <a href={url.signIn}> {words.alreadyLogin}</a>
            </div>
          </div>
          <div className="col-12 col-sm-6 image-right">
            <img src={SignUpImg} alt="Login Img" className="img-fluid" />
          </div>
        </div>
      </div>
    </div>
  );
}
