import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useMutation, useQuery } from 'react-query';
import words from '../../../constants/words';
import { useGroupHooks } from '../../../hooks/group';
import { Button } from '../../atoms/Button';
import { Plus } from '../../atoms/Icons';
import { DataTable } from '../../organisms/DataTable';
import { Approvers, Group, GroupData } from '../../../domain/entities/group';
import { AnchorTag } from '../../atoms/AnchorTag';
import { TruncatedList } from '../../atoms/TruncatedList';
import { theme } from '../../../config';
import { DeleteGroupModal } from '../DeleteGroupModal';
import { SetGroupModal } from '../SetGroupModal';
import { useGlobalState } from '../../../hooks/global';
import { IOptions } from '../../organisms/MultiSelectDropdown/MultiSelectDropdown';
import { ToastNotification } from '../../molecules/Notification';
import { Props as INotification } from '../../molecules/Notification/Notification';
import { debouncer } from '../../../utils/debouncer';
import { TIMEOUT } from '../../../constants/timeout';
import { DefinitionTooltip } from '../../atoms/Tooltip';

const Wrapper = styled.div``;

export type Props = {};

type DataSubmitted = {
  approvers: Approvers[];
  name: string;
};
type AddGroupData = {
  companyID: number;
  data: GroupData;
};

type UpdateGroupData = {
  companyID: number;
  groupID: number;
  data: GroupData;
};

export type EditGroupData = {
  name: string;
  approvers: IOptions[];
  groupId: number;
};

type CheckDuplicateParam = {
  companyID: number;
  groupName: string;
};

const CreateGroupButton = styled(Button)<{ isLoading: boolean }>`
  display: ${props => (props.isLoading ? 'none !important' : 'inherit')};
  width: auto;
  padding-right: 60px;
  display: flex;
  &&& svg {
    height: 9px;
    width: 9px;
  }
`;

const StyledDataTable = styled(DataTable)<{ isLoading: boolean }>`
  margin-bottom: ${props => (props.isLoading ? '0px' : '34px')};
  background: ${theme.colors.ui02};
  box-shadow: 0px 0px 8px rgba(236, 215, 192, 0.8);
  padding: 2px;

  .bx--data-table-content {
    overflow-x: unset;
  }

  thead {
    font-size: 13px;
  }
  tbody {
    font-size: 13px;
    tr td:nth-child(1) {
      width: 10%;
    }
    tr td:nth-child(2) {
      width: 20%;
    }
    tr td:nth-child(3) {
      width: 37%;
    }
    tr td:nth-child(4) {
      width: 14%;
    }
    tr td:nth-child(5) {
      text-align: right;
      padding-right: 30px;
    }
    tr td {
      .nameHolder {
        div {
          position: unset;
        }
      }
    }
  }
`;

const EditButton = styled(AnchorTag)`
  margin-right: 31px;
`;

const DeleteButton = styled(AnchorTag)``;

const DeleteInActiveButton = styled(DefinitionTooltip)`
  position: unset;
  display: inline-block;
  p {
    color: ${theme.colors.text03};
    cursor: not-allowed;
  }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--align-end.bx--tooltip--a11y
    + .bx--assistive-text {
    max-width: inherit;
  }
`;

const StyledTruncatedList = styled(TruncatedList)`
  && {
    position: unset;
  }
`;

const headers = [
  {
    key: 'number',
    header: words.number,
  },
  {
    key: 'name',
    header: words.groupName,
  },
  {
    key: 'approvers',
    header: words.approvers,
  },
  {
    key: 'members_count',
    header: words.membersCount,
  },
  {
    key: 'actions',
    header: '',
  },
];

const Component = ({}: Props): React.ReactElement => {
  const {
    useFetchGroups,
    useFetchApprovers,
    useAddGroup,
    useUpdateGroup,
    useCheckDuplicateGroupName,
  } = useGroupHooks();
  const { addGroup } = useAddGroup();
  const { fetchGroups } = useFetchGroups();
  const { fetchApprovers } = useFetchApprovers();
  const { updateGroup } = useUpdateGroup();
  const { checkDuplicates } = useCheckDuplicateGroupName();
  const {
    useCurrentUser: { currentUser },
  } = useGlobalState();

  const [notification, setNotification] = useState<INotification | null>(null);

  const [queryParams, setQueryParams] = useState<{
    page: number;
    pageSize: number;
  }>({ page: 1, pageSize: 10 });

  const [openAddModal, setOpenAddModal] = useState<boolean>(false);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);

  const [groupToDelete, setGroupToDelete] = useState<
    | {
        group: Group;
        newPage: number | undefined;
      }
    | undefined
  >();
  const [newlyDeletedGroup, setNewlyDeletedGroup] = useState<
    Group | undefined
  >();
  const [reUpdateGroup, setReUpdateGroup] = useState<Response | undefined>();
  const [editGroupData, setEditGroupData] = useState<EditGroupData>();
  const [inputError, setInputError] = useState<boolean>(false);
  const [isCheckingDuplicate, setIsCheckingDuplicate] = useState<boolean>();
  const [invalidTextInput, setinvalidTextInput] = useState<string>('');

  const toastNotification = useCallback((toast: INotification) => {
    setNotification({
      ...toast,
      timeout: TIMEOUT,
      handleClose: () => {
        setNotification(null);
        return false;
      },
    });
  }, []);

  const { mutate: checkDuplicateNames } = useMutation(
    ({ companyID, groupName }: CheckDuplicateParam) => {
      return checkDuplicates({ companyId: companyID, groupName });
    },
    {
      onSuccess: () => {
        setinvalidTextInput(words.groupNameAlreadyTaken);
        setInputError(true);
      },
      onError: () => {
        setinvalidTextInput('');
        setInputError(false);
        setIsCheckingDuplicate(false);
      },
    },
  );

  const { mutate: addGroupMutation } = useMutation(
    ({ companyID, data }: AddGroupData) => {
      return addGroup({ companyId: companyID }, data);
    },
    {
      onSuccess: data => {
        setReUpdateGroup(data);
        toastNotification({
          kind: 'success',
          title: words.success,
          subtitle: words.successfullyAddedGroup,
        });
      },
      onError: () =>
        toastNotification({
          kind: 'error',
          title: words.error,
          subtitle: words.errorOccured,
        }),
    },
  );

  const { mutate: updateGroupMutation } = useMutation(
    ({ companyID, groupID, data }: UpdateGroupData) => {
      return updateGroup({ companyId: companyID, groupId: groupID }, data);
    },
    {
      onSuccess: data => {
        setReUpdateGroup(data);
        toastNotification({
          kind: 'success',
          title: words.success,
          subtitle: words.successfullyUpdatedGroup,
        });
      },
      onError: () =>
        toastNotification({
          kind: 'error',
          title: words.error,
          subtitle: words.errorOccured,
        }),
    },
  );

  const { data: fetchApproversData = [] } = useQuery(
    [openAddModal, openEditModal],
    async () => {
      if (!currentUser?.companyId) {
        throw new Error(`'company id' field not set`);
      }
      const response = await fetchApprovers({
        companyId: currentUser.companyId,
      });
      const options: IOptions[] = response.map(data => ({
        value: data.id.toString(),
        label: data.name,
      }));
      return options;
    },
  );

  const {
    data: { results: groupList, count: groupListCount } = {
      results: [],
      count: 0,
    },
    isFetching,
  } = useQuery(
    [queryParams, newlyDeletedGroup, currentUser, reUpdateGroup],
    () => {
      if (!currentUser?.companyId) {
        throw new Error(`'company id' not set`);
      }
      const query = {
        companyId: currentUser.companyId,
        page: queryParams?.page,
        page_size: queryParams?.pageSize,
      };
      return fetchGroups(query);
    },
    {
      refetchOnMount: true,
    },
  );

  const reassignApproverIdsToInt = (data: DataSubmitted) => {
    return {
      approvers: data.approvers.map(approver => {
        return parseInt(approver.value as string);
      }),
      name: data.name || '',
    };
  };

  const onSubmitAddGroupModal = (data: DataSubmitted) => {
    //mutate approvers to Id's only
    if (!currentUser?.companyId) {
      throw new Error(`'company id' field not set`);
    }

    const groupData = reassignApproverIdsToInt(data);

    const dataToBeSubmitted: AddGroupData = {
      companyID: currentUser?.companyId,
      data: groupData,
    };

    addGroupMutation(dataToBeSubmitted);
    setOpenAddModal(false);
  };

  const onSubmitEditGroupModal = (e: object) => {
    const updateGroupData: DataSubmitted = e as DataSubmitted;

    if (!currentUser?.companyId) {
      throw new Error(`'company id' field not set`);
    } else {
      const groupData = reassignApproverIdsToInt(updateGroupData);

      if (!editGroupData?.groupId) {
        throw new Error(`'group id' field not set`);
      } else {
        const dataToBeUpdated: UpdateGroupData = {
          companyID: currentUser.companyId,
          groupID: editGroupData.groupId as number,
          data: groupData,
        };
        updateGroupMutation(dataToBeUpdated);
      }
      setOpenEditModal(false);
    }
  };

  const onCloseEditModal = () => {
    setOpenAddModal(false);
    setInputError(false);
    setOpenEditModal(false);
  };

  const onOpenEditModal = (group: Group) => {
    const approvers = [];
    for (const approver of group.approvers) {
      const filteredApprover = fetchApproversData.find(
        approverData => approverData.label === approver,
      );
      if (filteredApprover) {
        approvers.push(filteredApprover);
      }
    }

    setEditGroupData({
      name: group.name,
      groupId: group.id,
      approvers: approvers,
    });
    setOpenEditModal(true);
  };

  const onCloseAddModal = () => {
    setOpenAddModal(false);
    setInputError(false);
    setinvalidTextInput('');
  };
  const onOpenAddModal = () => {
    setOpenAddModal(true);
  };

  // will wait the user to stop typing
  const debounceUserInput = debouncer((text: string) => {
    if (!currentUser?.companyId) {
      throw new Error(`'company id' field not set`);
    }

    const queryCheckDuplicate: CheckDuplicateParam = {
      companyID: currentUser?.companyId,
      groupName: text,
    };
    if (
      (text !== '' && !editGroupData) ||
      text.toLocaleLowerCase() !== editGroupData?.name.toLocaleLowerCase()
    ) {
      checkDuplicateNames(queryCheckDuplicate);
    }
  }, 500);

  const trimString = (text: string) => text.trim();

  const filterIfGroupNameExist = useCallback(
    (name: string, editGroupData: EditGroupData | undefined) => {
      setinvalidTextInput('');
      setInputError(false);
      setIsCheckingDuplicate(true);
      setEditGroupData(editGroupData);

      const formattedText = trimString(name);
      if (formattedText === editGroupData?.name) {
        setIsCheckingDuplicate(false);
        setInputError(false);
        return;
      }

      debounceUserInput(name);
    },
    [],
  );

  return (
    <>
      <Wrapper>
        <StyledDataTable
          rows={groupList.map((group, index) => ({
            ...group,
            id: `${group.id}`,
            number: (queryParams.page - 1) * queryParams.pageSize + (index + 1),
            approvers: <TruncatedList itemList={group.approvers} />,
            actions: (
              <>
                <EditButton
                  onPress={() => onOpenEditModal(group)}
                  type="edit"
                  title={words.edit}
                />

                {groupList.length > 1 ||
                (queryParams.page - 1) * queryParams.pageSize + (index + 1) >
                  1 ? (
                  <DeleteButton
                    onPress={() => {
                      if (groupList.length === 1 && index === 0)
                        setGroupToDelete({
                          group,
                          newPage: queryParams.page - 1,
                        });
                      else setGroupToDelete({ group, newPage: undefined });
                    }}
                    type="delete"
                    title={words.delete}
                  />
                ) : (
                  <DeleteInActiveButton
                    message={words.errorMessageDeleteLastGroup}
                    label={words.labelDeleteAnchor}
                    align={`end`}
                  />
                )}
              </>
            ),
          }))}
          isLoading={isFetching}
          headers={headers}
          onChangePage={setQueryParams}
          page={queryParams.page}
          pageSize={queryParams.pageSize}
          totalItems={groupListCount}
        />
        <CreateGroupButton
          onPress={() => onOpenAddModal()}
          title={words.addGroup}
          icon={Plus}
          isLoading={isFetching}
        />
      </Wrapper>
      {/** Add Modal */}
      <SetGroupModal
        open={openAddModal}
        title={words.addGroupModal}
        confirmButtonText={words.addSaveButton}
        label={words.addLabelModal}
        idInput={'GroupNameAdd'} // this is on Id name so no need to put on dictionary
        labelInput={words.addLabelInput}
        placeholderInput={words.addPlaceholderInput}
        isCheckingDuplicate={isCheckingDuplicate}
        labelMultiSelect={words.addLabelMultiSelect}
        placeholderMultiSelect={words.addPlaceholderMultiSelect}
        errorMessageMultiSelect={words.addErrorMessageMultiSelect}
        invalidInput={inputError}
        isEditMode={false}
        disableInput={false}
        disableMultiSelect={false}
        options={fetchApproversData}
        onSubmitSetGroupModal={e => onSubmitAddGroupModal(e as DataSubmitted)}
        onChangeNameInput={(name, groupdata) =>
          filterIfGroupNameExist(name, groupdata)
        }
        onCancel={onCloseAddModal}
        onClose={onCloseAddModal}
        invalidTextInput={invalidTextInput}
      />
      {/** Edit Modal */}
      <SetGroupModal
        open={openEditModal}
        title={words.editGroup}
        confirmButtonText={words.editSaveButton}
        label={words.editGroupLabel}
        idInput={'GroupNameEdit'} // this is on Id name so no need to put on dictionary
        labelInput={words.editLabelInput}
        placeholderInput={words.editPlaceholderInput}
        labelMultiSelect={words.editLabelMultiSelect}
        placeholderMultiSelect={words.editPlaceholderMultiSelect}
        errorMessageMultiSelect={words.editErrorMessageMultiSelect}
        invalidInput={inputError}
        disableInput={false}
        isCheckingDuplicate={isCheckingDuplicate}
        disableMultiSelect={false}
        isEditMode={true}
        options={fetchApproversData}
        onSubmitSetGroupModal={e => onSubmitEditGroupModal(e)}
        onCancel={onCloseEditModal}
        onClose={onCloseEditModal}
        approverValue={editGroupData?.approvers}
        textValue={editGroupData?.name}
        editGroupData={editGroupData}
        onChangeNameInput={(name, groupdata) =>
          filterIfGroupNameExist(name, groupdata)
        }
        invalidTextInput={invalidTextInput}
      />
      <DeleteGroupModal
        onCancel={() => {
          setGroupToDelete(undefined);
        }}
        onSuccessDelete={() => {
          if (groupToDelete?.newPage) {
            setQueryParams({ page: groupToDelete?.newPage, pageSize: 10 });
          }
          setNewlyDeletedGroup(groupToDelete?.group);
          setGroupToDelete(undefined);
          toastNotification({
            kind: 'success',
            title: words.success,
            subtitle: words.successfullyDeleted,
          });
        }}
        onFailureDelete={() => {
          setGroupToDelete(undefined);
          toastNotification({
            kind: 'error',
            title: words.updateError,
            subtitle: words.errorOccured,
          });
        }}
        groupToDelete={groupToDelete?.group}
      />
      {notification ? <ToastNotification notification={notification} /> : null}
    </>
  );
};

export default Component;
