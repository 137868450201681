import React from 'react';
import styled, { css } from 'styled-components';
import {
  FileUploaderDropContainer,
  FileUploaderItem,
} from 'carbon-components-react';

import { theme } from '../../../config';
import words from '../../../constants/words';

const FileInput = styled(FileUploaderDropContainer)`
  color: ${theme.colors.primaryButton1};
  font-size: 13px;
  text-decoration: underline;
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
`;

const ErrorLabel = styled.p`
  font-size: 12px;
  font-weight: 400;
  color: ${theme.colors.errorColor};
  @media ${theme.breakpoints.mobile} {
    margin-bottom: 15px;
  }
`;

// Copied the styling of Carbon file label
const StyledLabel = styled.p`
  font-size: 13px;
  font-weight: 600;
  line-height: 1.28572;
  letter-spacing: 0.16px;
  margin-bottom: 0.5rem;
  margin-right: 10px;
  color: ${theme.colors.text01};
`;

const TextSyle = css`
  font-weight: 400;
  line-height: 1.28572;
  letter-spacing: 0.16px;
  color: ${theme.colors.text02};
`;

const Description = styled.p`
  ${TextSyle}
  font-size: 13px;
`;

const SubLabel = styled.p`
  ${TextSyle};
  font-size: 12px;
`;

const DescContainer = styled.div`
  margin-bottom: 1rem;
`;

const Container = styled.div`
  width: 100%;
  height: auto;
  &&&& {
    .bx--file .bx--file-browse-btn {
      max-width: 100%;
    }
    .bx--file-browse-btn:hover {
      outline: 2px solid ${theme.colors.primaryButton1};
      text-decoration-color: ${theme.colors.primaryButton1};
    }
    .bx--loading__stroke {
      stroke: ${theme.colors.primaryButton1};
    }
    .bx--file__selected-file {
      max-width: 100%;
      background-color: ${theme.colors.background};
      border: 1px solid ${theme.colors.disabledToggle2};
      margin-top: 15px;
    }
  }
`;

export type Props = {
  id?: string;
  photo?: File[];
  label?: string;
  subLabel?: string;
  description?: string;
  fileInputDesc?: string;
  onAttachFile?: (file: File[], remove?: boolean) => void;
  errorMessage?: string;
  multiple?: boolean;
  isLoading?: boolean;
};

const Component = ({
  id,
  photo,
  label,
  subLabel,
  description,
  fileInputDesc = words.fileInputDescDefault,
  onAttachFile,
  errorMessage,
  multiple = false,
  isLoading,
}: Props): React.ReactElement => {
  const handleAttachFiles = (
    event: React.DragEvent<HTMLElement>,
    content: { addedFiles: File[] },
  ) => onAttachFile && onAttachFile(content.addedFiles);

  const handleDelete = () => photo && onAttachFile && onAttachFile(photo, true);
  return (
    <Container>
      <LabelContainer>
        <StyledLabel>{label}</StyledLabel>
        <SubLabel>{subLabel}</SubLabel>
      </LabelContainer>
      {photo && photo.length > 0 ? (
        <FileUploaderItem
          name={photo[0].name}
          status={isLoading ? 'uploading' : 'edit'}
          onDelete={() => handleDelete()}
          size={'lg'}
        />
      ) : (
        <>
          {description && (
            <DescContainer>
              {description.split('\n').map(line => (
                <Description key={line}>{line}</Description>
              ))}
            </DescContainer>
          )}
          <FileInput
            id={id}
            className={'FileInput'}
            labelText={fileInputDesc}
            multiple={multiple}
            accept={['image/jpeg', 'image/png']}
            onAddFiles={handleAttachFiles}
          />
        </>
      )}
      {errorMessage && <ErrorLabel>{errorMessage}</ErrorLabel>}
    </Container>
  );
};

export default Component;
