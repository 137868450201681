import { createContext, useContext } from 'react';
import { Approvers, Group, GroupData } from '../../domain/entities/group';
import { PaginationQuery } from '../../domain/entities/request';
import { PaginatedResponse } from '../../domain/entities/response';

export type GroupHooks = {
  useFetchGroups: () => {
    fetchGroups: (
      params: PaginationQuery & { companyId: number },
    ) => Promise<PaginatedResponse & { results: Group[] }>;
  };

  useDeleteGroup: () => {
    deleteGroup: (payload: {
      companyId: number;
      groupId: number;
    }) => Promise<void>;
  };

  useAddGroup: () => {
    addGroup: (
      payload: {
        companyId: number;
      },
      data: GroupData,
    ) => Promise<Response>;
  };

  useUpdateGroup: () => {
    updateGroup: (
      payload: {
        companyId: number;
        groupId: number;
      },
      data: GroupData,
    ) => Promise<Response>;
  };

  useFetchApprovers: () => {
    fetchApprovers: (params: { companyId: number }) => Promise<Approvers[]>;
  };

  useCheckDuplicateGroupName: () => {
    checkDuplicates: (params: {
      companyId: number;
      groupName: string;
    }) => Promise<Response>;
  };
};

export const GroupHooksContext = createContext<GroupHooks | null>(null);

export const useGroupHooks = (): GroupHooks => {
  const context = useContext(GroupHooksContext);
  if (!context) {
    throw new Error(`Context not instantiated`);
  }
  return context;
};
