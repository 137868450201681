import { useCallback } from 'react';
import { CompanyHooks } from '.';
import { Company, UpdateCompany } from '../../domain/entities/company';
import { useDependencies } from '..';

export const useFetchCompanies: CompanyHooks['useFetchCompanies'] = (): {
  fetchCompany: (companyId: number) => Promise<Company | undefined>;
} => {
  const { companyInteractor } = useDependencies();
  const fetchCompany = useCallback(async (companyId: number) => {
    const company: Company | undefined = await companyInteractor.findCompany(
      companyId,
    );

    return company;
  }, []);

  return { fetchCompany };
};

export const useUpdateCompany: CompanyHooks['useUpdateCompany'] = (): {
  updateCompany: (
    companyId: number,
    payload: UpdateCompany,
  ) => Promise<Company>;
} => {
  const { companyInteractor } = useDependencies();
  const updateCompany = useCallback(
    async (companyId: number, payload: UpdateCompany) => {
      const company: Company = await companyInteractor.updateCompany(
        companyId,
        payload,
      );

      return company;
    },
    [],
  );

  return { updateCompany };
};

export const useVerifyCompanyEmail: CompanyHooks['useVerifyCompanyEmail'] = (): {
  verifyCompanyEmail: (companyId: string, token: string) => Promise<void>;
  resendCompanyEmailVerification: (email: string) => Promise<void>;
} => {
  const { companyInteractor } = useDependencies();
  const verifyCompanyEmail = useCallback(
    async (companyId: string, token: string) => {
      const company = await companyInteractor.verifyCompanyEmail(
        companyId,
        token,
      );

      return company;
    },
    [],
  );

  const resendCompanyEmailVerification = useCallback(
    async (companyId: string) => {
      await companyInteractor.resendVerificationEmail(companyId);
    },
    [],
  );

  return { verifyCompanyEmail, resendCompanyEmailVerification };
};
