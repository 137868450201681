import React from 'react';

type Props = {
  [key: string]: string | number | Function;
};

const Copy = (props: Props): React.ReactElement => {
  return (
    <svg
      width="12"
      height="15"
      viewBox="0 0 12 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 8H1V1H8V0H1C0.447944 0.00055123 0.00055123 0.447944 0 1V8ZM8.6885 3.289L11.7175 6.3495C11.8979 6.53353 11.9993 6.78078 12 7.0385V13.5C11.9994 14.0521 11.5521 14.4994 11 14.5H4C3.44794 14.4994 3.00055 14.0521 3 13.5V4C3.00055 3.44794 3.44794 3.00055 4 3H7.999C8.25812 3.00083 8.50623 3.10483 8.6885 3.289ZM8 4.015L10.962 7H8V4.015ZM4 4V13.5H11.0005L11 8H8C7.44794 7.99945 7.00055 7.55206 7 7V4H4Z"
        fill="#C6C6C6"
      />
    </svg>
  );
};

export default Copy;
