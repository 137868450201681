import { buildFetchGroups, fetchGroupsUseCase } from './fetch-group';
import { buildDeleteGroup, deleteGroupUseCase } from './delete-group';

import GroupRepository from '../../ports/GroupRepository';
import {
  buildFetchApprovers,
  fetchApproversUseCase,
} from './fetchapprovers-group';
import { addGroupUseCase, buildAddGroup } from './add-group';
import { buildUpdateGroup, updateGroupUseCase } from './update-group';
import {
  buildCheckDuplicateGroupName,
  checkDuplicateUseCase,
} from './check-duplicate-groupname';

export default (dependencies: {
  groupRepo: GroupRepository;
}): {
  fetchGroups: fetchGroupsUseCase;
  deleteGroup: deleteGroupUseCase;
  fetchApprovers: fetchApproversUseCase;
  addGroup: addGroupUseCase;
  updateGroup: updateGroupUseCase;
  checkDuplicateGroupName: checkDuplicateUseCase;
} => {
  const { groupRepo } = dependencies;
  const fetchGroups = buildFetchGroups({ groupRepo });
  const deleteGroup = buildDeleteGroup({ groupRepo });
  const fetchApprovers = buildFetchApprovers({ groupRepo });
  const addGroup = buildAddGroup({ groupRepo });
  const updateGroup = buildUpdateGroup({ groupRepo });
  const checkDuplicateGroupName = buildCheckDuplicateGroupName({ groupRepo });

  return {
    fetchGroups,
    deleteGroup,
    fetchApprovers,
    addGroup,
    updateGroup,
    checkDuplicateGroupName,
  };
};
