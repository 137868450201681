import React from 'react';

type Props = {
  [key: string]: string | number | Function;
};

const NoNotif = (props: Props): React.ReactElement => {
  return (
    <svg
      width="203"
      height="160"
      viewBox="0 0 203 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <circle cx="40.8076" cy="119.192" r="40.8081" fill="#F5E7D8" />
      <circle cx="139.393" cy="62.6263" r="62.6263" fill="#F5E7D8" />
      <path d="M20.2013 138.182H9.69629" stroke="#3B1F34" strokeWidth="2" />
      <path d="M46.8682 138.182H167.272" stroke="#3B1F34" strokeWidth="2" />
      <path d="M54.1411 143.838H174.545" stroke="#3B1F34" strokeWidth="2" />
      <path
        d="M141.892 115.556L141.414 116.584V121.212H153.864L154.343 116.584L153.385 115.556H141.892Z"
        fill="#FF714F"
      />
      <path d="M57.373 74.3434V137.374" stroke="#3B1F34" />
      <path
        d="M153.251 115.556H142.458C141.889 115.556 141.434 116.03 141.459 116.599L142.329 136.418C142.353 136.952 142.793 137.374 143.328 137.374H151.511C152.03 137.374 152.462 136.978 152.508 136.461L154.247 116.643C154.299 116.058 153.838 115.556 153.251 115.556Z"
        stroke="#3B1F34"
      />
      <circle
        cx="96.1612"
        cy="64.6465"
        r="11.9293"
        stroke="#3B1F34"
        strokeWidth="2"
      />
      <circle cx="96.1613" cy="64.6465" r="9.69697" fill="white" />
      <path
        d="M88.8884 73.5354L80.8076 84.0404"
        stroke="#3B1F34"
        strokeWidth="2"
      />
      <path
        d="M132.704 75.4665L99.6932 66.974C98.1445 66.5755 96.5554 67.462 96.0809 68.9892L80.1268 120.334C79.6178 121.972 80.5832 123.702 82.2442 124.129L115.255 132.622C116.804 133.02 118.393 132.134 118.868 130.607L134.822 79.2621C135.331 77.6242 134.365 75.8938 132.704 75.4665Z"
        fill="#FF714F"
        stroke="black"
      />
      <path
        d="M46.8682 101.01L73.5529 83.4273C78.1591 82.9521 87.1811 83.0472 86.4187 87.229C85.6563 91.4109 81.9712 94.9908 80.224 96.258H67.8347"
        stroke="#3B1F34"
        strokeWidth="2"
      />
      <path
        d="M61.8183 92.3813L67.5436 100.056C68.3388 100.855 69.452 103.126 67.5436 105.812C65.1581 109.17 50.3677 129.316 39.3942 139.389C30.6154 147.447 25.8761 142.747 24.6038 139.389C21.5821 134.272 16.6838 120.01 21.264 103.893C26.9893 83.7473 28.4202 65.5198 47.9819 47.7721C63.6313 33.5739 70.406 34.3414 71.8373 34.3413C80.5843 33.0622 99.7959 31.0797 106.666 33.3821"
        stroke="#3B1F34"
        strokeWidth="2"
      />
      <path
        d="M147.878 110.695L123.318 108.912C122.712 108.868 122.139 108.64 121.652 108.277C116.553 104.472 106.19 99.3534 99.944 105.445C99.8365 105.55 99.7374 105.666 99.6534 105.79C97.2184 109.399 97.4288 116.444 117.097 117.172"
        stroke="#3B1F34"
        strokeWidth="2"
      />
      <path
        d="M101.818 32.3232C114.33 34.2498 142.145 45.2313 153.309 73.7448C159.508 91.0848 170.148 126.458 163.6 130.476C163.479 130.55 163.353 130.602 163.216 130.639C160.075 131.484 147.786 130.8 121.466 121.907C119.91 121.381 119.078 119.693 119.597 118.135L122.511 109.387"
        stroke="#3B1F34"
        strokeWidth="2"
      />
      <path
        d="M84.0399 53.3333C81.4362 56.404 77.062 64.097 80.3947 70.303"
        stroke="#3B1F34"
      />
      <path
        d="M120.403 31.5152C128.537 33.2847 147.198 42.7116 156.767 66.2626"
        stroke="#3B1F34"
      />
      <path
        d="M60.4697 9.91305C62.2457 9.91305 63.6257 10.369 64.6097 11.281C65.5937 12.193 66.0857 13.345 66.0857 14.737C66.0857 15.745 65.7737 16.669 65.1497 17.509C64.5497 18.3491 63.8897 19.1051 63.1697 19.7771C62.4017 20.473 61.8017 21.085 61.3697 21.6131C60.9377 22.1171 60.7217 22.6931 60.7217 23.3411C60.7217 23.6051 60.7337 23.8451 60.7577 24.0611C60.7817 24.2531 60.8537 24.5051 60.9737 24.8171L58.6337 25.3571C58.4897 25.0691 58.3817 24.7691 58.3097 24.4571C58.2617 24.1211 58.2377 23.7851 58.2377 23.4491C58.2377 22.6091 58.4297 21.8531 58.8137 21.1811C59.2217 20.4851 59.8577 19.7171 60.7217 18.8771C61.4177 18.2051 62.0177 17.5691 62.5217 16.9691C63.0257 16.3451 63.2777 15.6371 63.2777 14.845C63.2777 14.221 63.0617 13.645 62.6297 13.1171C62.1977 12.5891 61.4297 12.3251 60.3257 12.3251C59.8697 12.3251 59.4017 12.3851 58.9217 12.5051C58.4417 12.625 57.9617 12.865 57.4817 13.225L56.2937 11.2091C56.8697 10.7531 57.5297 10.4291 58.2737 10.237C59.0417 10.021 59.7737 9.91305 60.4697 9.91305ZM60.0377 26.9411C60.5657 26.9411 60.9977 27.1211 61.3337 27.4811C61.6697 27.8171 61.8377 28.2611 61.8377 28.8131C61.8377 29.3651 61.6697 29.8211 61.3337 30.1811C60.9977 30.5171 60.5657 30.6851 60.0377 30.6851C59.5097 30.6851 59.0777 30.5171 58.7417 30.1811C58.4057 29.8211 58.2377 29.3651 58.2377 28.8131C58.2377 28.2611 58.4057 27.8171 58.7417 27.4811C59.0777 27.1211 59.5097 26.9411 60.0377 26.9411Z"
        fill="#3B1F34"
      />
      <path
        d="M101.609 26.1198C97.7121 14.9743 85.8033 15.3471 79.7227 17.0791C79.3462 17.1864 79.0015 17.3802 78.6976 17.627C76.0216 19.7999 73.4134 21.5119 72.0733 29.6033C70.9402 36.4451 76.3226 42.5901 79.1554 44.8074C83.8742 48.8256 89.2617 48.7774 91.6301 48.2052C91.8137 48.1609 91.9985 48.1267 92.1856 48.1009C95.973 47.5777 98.6974 43.6367 99.7625 41.3778C99.8782 41.1324 99.9475 40.87 99.9831 40.6011L101.731 27.4063C101.788 26.9756 101.753 26.53 101.609 26.1198Z"
        stroke="#3B1F34"
      />
      <path
        d="M79.7261 17.0901C85.8233 15.3319 97.7737 14.9591 101.639 26.3364C101.761 26.6964 101.802 27.0853 101.771 27.4642L101.422 31.7356C101.372 32.3419 101.139 32.9187 100.754 33.3897L98.9847 35.5545C98.4508 36.8245 95.2384 38.9794 90.9584 38.9238C88.0616 38.8861 84.3761 36.8061 82.2763 38.1669C81.6908 38.5464 81.1188 38.8384 80.7347 38.2559C79.7145 36.7084 78.398 33.286 77.7386 36.5171C76.706 41.5766 81.8511 47.0251 79.1554 44.6997C76.3226 42.4535 70.9402 36.7097 72.0733 29.7786C73.4133 21.5824 76.0212 19.8476 78.6969 17.6467C79.0012 17.3964 79.3475 17.1993 79.7261 17.0901Z"
        fill="#3B1F34"
      />
      <path
        d="M84.04 42.8643C86.2286 42.6867 90.9087 43.0774 92.1208 46.0606"
        stroke="#3B1F34"
      />
      <ellipse
        cx="25.0502"
        cy="34.3434"
        rx="19.3939"
        ry="18.9899"
        fill="#F5E7D8"
      />
      <path d="M131.312 137.778V100.606" stroke="#3B1F34" />
    </svg>
  );
};

export default NoNotif;
