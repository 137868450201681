import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { useMutation } from 'react-query';
import { Formik } from 'formik';
import { PropsValue } from 'react-select';
import { useParams, useNavigate } from 'react-router-dom';

import { RecordApplicationSchema } from './validation';

import themes from '../../../config/themes';
import words from '../../../constants/words';
import { TIMEOUT } from '../../../constants/timeout';
import { RANKING_DATA, RANKING_FORM_KEY } from '../../../constants/storageKeys';

import { GroupApprover } from '../../../domain/entities/group';
import { CreateEntryApplication } from '../../../domain/entities/entry';
import { FetchEntryFieldProps } from '../../../domain/entities/entry';
import { NotificationData } from '../../../domain/entities/notification';

import { useGlobalState } from '../../../hooks/global';
import { useEntryHooks } from '../../../hooks/entry';
import { useNotificationHooks } from '../../../hooks/notification';

import { RecordAppForm } from '../../organisms/RecordAppForm';
import { RecordAppFormType } from '../../organisms/RecordAppForm/RecordAppForm';
import { ToastNotification } from '../../molecules/Notification';
import { SubHeader } from '../../atoms/Subheader';
import { PageLoader } from '../../atoms/Loading';
import { IOptions as MultiSelectOptions } from '../../organisms/MultiSelectDropdown/MultiSelectDropdown';
import { Props as INotification } from '../../molecules/Notification/Notification';
import data from '../../../constants/data';

const Container = styled.div`
  margin: 51px 89px 0 78px;

  @media ${themes.main.breakpoints.mobile} {
    margin: 51px 0 58px 0;
  }
`;

export type Props = {
  formValues?: RecordAppFormType;
  approverOptions?: GroupApprover[];
  approverOptionsAll?: GroupApprover[];
  rankingName?: string;
  isFetchingFieldsApprovers?: boolean;
};

type addNotificationData = {
  companyId: number;
  data: NotificationData;
};

const getApplicationInitValues = (
  currRankingId: number,
  memberId?: number,
  formValues?: RecordAppFormType,
): RecordAppFormType => {
  const rankingDataFromLocal = window.localStorage.getItem(RANKING_DATA);
  const persistedRankingData = rankingDataFromLocal
    ? JSON.parse(rankingDataFromLocal)
    : {};

  const isTheSameRanking =
    rankingDataFromLocal &&
    persistedRankingData.id === currRankingId &&
    persistedRankingData.memberId === memberId;

  if (isTheSameRanking) {
    const formFromLocal = window.localStorage.getItem(RANKING_FORM_KEY);
    const persistedForm =
      formFromLocal && isTheSameRanking ? JSON.parse(formFromLocal) : {};

    const persistedCustom = persistedForm?.customFields;
    const fetchedCustomFields = formValues ? formValues.customFields : [];
    const customArrToObject = persistedCustom?.reduce((acc: any, curr: any) => {
      return {
        ...acc,
        [curr.field_lookup_id]: curr,
      };
    }, {});
    const custom: FetchEntryFieldProps[] = [];

    fetchedCustomFields.forEach(item => {
      const value_decimal = customArrToObject
        ? customArrToObject[item.field_lookup_id]?.value_decimal
        : 0;
      let fieldValueItem: FetchEntryFieldProps = item;
      if (value_decimal) {
        fieldValueItem = {
          ...fieldValueItem,
          value_decimal,
          valueForValidation: value_decimal,
        };
      }
      custom.push(fieldValueItem);
    });

    return {
      applicationDate: new Date(),
      salesDate: persistedForm?.salesDate || new Date(),
      customFields: custom,
      mainApprover: persistedForm?.mainApprover || undefined,
      subApprovers: persistedForm?.subApprovers || undefined,
      notes: persistedForm?.notes || '',
    };
  }
  return formValues as RecordAppFormType;
};

export const clearRankingFormPersistedData = (): void => {
  window.localStorage.removeItem(RANKING_FORM_KEY);
  window.localStorage.removeItem(RANKING_DATA);
};

const Component = ({
  formValues,
  approverOptions = [],
  approverOptionsAll = [],
  rankingName,
  isFetchingFieldsApprovers,
}: Props): React.ReactElement => {
  const { rankingId } = useParams();
  const navigate = useNavigate();

  const {
    useCurrentUser: { currentUser },
  } = useGlobalState();

  const { useCreateEntry } = useEntryHooks();
  const { createEntryApplication } = useCreateEntry();
  const { useAddNotification } = useNotificationHooks();
  const { addNotification } = useAddNotification();

  const [notification, setNotification] = useState<INotification | null>(null);
  const [notificationData, setNotificationData] = useState<addNotificationData>(
    {
      companyId: 0,
      data: {
        is_approved: null,
        entryId: 0,
        memberId: 0,
        rankingId: 0,
      },
    },
  );

  const { mutate: addNotificationMutation } = useMutation(
    ({ companyId, data }: addNotificationData) => {
      return addNotification({ companyId }, data);
    },
  );

  const {
    mutate: mutateCreateEntryApplication,
    isLoading: isLoadingCreateEntryApplication,
  } = useMutation(
    (payload: CreateEntryApplication) => {
      if (!currentUser?.companyId) {
        throw new Error(`'company id' not set`);
      }

      return createEntryApplication(
        currentUser.companyId,
        currentUser.memberId,
        Number(rankingId),
        payload,
      );
    },
    {
      onSuccess: (response: any) => {
        const toastMessage: INotification = {
          kind: 'success',
          title: words.addRecordAppToastTitleSuccess,
          subtitle: words.addRecordAppToastSubSuccess,
        };
        addNotificationMutation({
          ...notificationData,
          data: {
            ...notificationData.data,
            entryId: response.data.entry_id,
          },
        });
        clearRankingFormPersistedData();
        navigate('/application', { state: { toastMessage } });
      },
      onError: () => {
        setNotification({
          kind: 'error',
          title: words.addRecordAppToastTitleError,
          subtitle: words.addRecordAppToastSubError,
          timeout: TIMEOUT,
          handleClose: () => {
            setNotification(null);
            navigate(0);
            return false;
          },
        });
      },
    },
  );

  const handleCancel = useCallback(() => {
    window.scrollTo(0, 0);
    clearRankingFormPersistedData();
    navigate('/application');
  }, []);

  const submitForm = useCallback(
    (values: RecordAppFormType) => {
      const indexValue = values.customFields?.find(
        customField => customField.is_basis,
      );

      const subApproversIdArray = (
        (values.subApprovers as {
          value: string;
          label: string;
        }[]) || []
      ).map(value => Number(value.value));

      const customFieldsArray = (values.customFields || [])
        .filter(
          customField =>
            (!customField.value_char && customField.value_decimal) ||
            (customField.value_char && !customField.value_decimal),
        )
        .map(customField => ({
          field_lookup_id: customField.field_lookup_id,
          value_char: customField.value_char,
          value_decimal: customField.value_decimal,
        }));

      const salesDate = values?.salesDate
        ? new Date(values.salesDate.toDateString())
        : values.salesDate;
      const applicationDate = new Date();

      let mainApprover = values.mainApprover?.member?.id;
      let mainApproverName = values.mainApprover?.member?.name;
      let groupApprover = values.mainApprover?.id;
      const approver: GroupApprover[] = approverOptionsAll.filter(
        (option: GroupApprover) => {
          return option?.member?.id == currentUser?.memberId;
        },
      );
      if (
        !mainApprover &&
        !mainApproverName &&
        data.roleManagement.includes(String(currentUser?.roleName))
      ) {
        if (approver.length > 0) {
          groupApprover = approver[0].id;
        }
        mainApprover = currentUser?.memberId;
        mainApproverName = currentUser?.name;
      }

      const entryApplication: CreateEntryApplication = {
        value: indexValue?.value_decimal,
        sales_date: salesDate,
        notes: values.notes || '',
        main_approver_id: groupApprover,
        sub_approvers_id: subApproversIdArray,
        fields: customFieldsArray,
        application_date: applicationDate,
      };

      const dataToPersist = {
        applicationDate: applicationDate,
        salesDate: salesDate,
        customFields: values.customFields,
        mainApprover: values.mainApprover,
        subApprovers: values.subApprovers,
        notes: values.notes || '',
      };

      window.localStorage.setItem(
        RANKING_FORM_KEY,
        JSON.stringify(dataToPersist),
      );
      window.localStorage.setItem(
        RANKING_DATA,
        JSON.stringify({
          id: Number(rankingId),
          memberId: currentUser?.memberId,
        }),
      );

      if (
        !currentUser?.companyId ||
        !rankingId ||
        !currentUser?.memberId ||
        !currentUser?.userId
      ) {
        throw new Error(`'company id' field not set`);
      }

      const notifData: addNotificationData = {
        companyId: currentUser?.companyId,
        data: {
          is_approved: null,
          applicant_name: currentUser?.name,
          approver_name: mainApproverName,
          ranking_name: rankingName,
          rankingId: Number(rankingId),
          memberId: currentUser.memberId,
          approverId: mainApprover,
        },
      };

      setNotificationData(notifData);
      mutateCreateEntryApplication(entryApplication);
    },
    [rankingName],
  );

  return (
    <>
      <Formik
        initialValues={getApplicationInitValues(
          Number(rankingId),
          currentUser?.memberId,
          formValues,
        )}
        validationSchema={RecordApplicationSchema(
          String(currentUser?.roleName),
        )}
        onSubmit={submitForm}
        validateOnMount={true}
        enableReinitialize>
        {({
          handleChange,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          initialValues,
          values,
          errors,
          touched,
          isValid,
        }): React.ReactElement => {
          const {
            applicationDate,
            salesDate,
            customFields,
            mainApprover,
            subApprovers,
            notes,
          } = values;

          return (
            <>
              <SubHeader
                title={rankingName || ''}
                onBack={handleCancel}
                backTooltipMessage={words.discardChangesEditBasicInfo}
                backTooltipCancelText={words.cancelTooltipNo}
                backTooltipConfirmText={words.recordAppTooltipConfirm}
                hasBackOnMobileTooltip={initialValues !== values}
                isStickyOnMobile
                hasBackOnMobile
              />
              {isFetchingFieldsApprovers ? (
                <PageLoader />
              ) : (
                <Container>
                  <RecordAppForm
                    applicationDate={applicationDate}
                    salesDate={salesDate}
                    customFields={customFields}
                    mainApprover={mainApprover}
                    subApprovers={subApprovers}
                    notes={notes}
                    approverOptions={approverOptions}
                    onChangeSalesDate={(value: Date) => {
                      setFieldValue('salesDate', value);
                    }}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    onChangeMainApprover={(value: GroupApprover) => {
                      setFieldValue('mainApprover', value);
                    }}
                    onChangeSubApprovers={(
                      value: PropsValue<MultiSelectOptions>,
                    ) => {
                      setFieldValue('subApprovers', value);
                    }}
                    onChangesNotes={handleChange('notes')}
                    handleCancel={handleCancel}
                    handleSubmit={handleSubmit}
                    errors={errors}
                    touched={touched}
                    isValid={isValid}
                    isLoadingCreateEntryApplication={
                      isLoadingCreateEntryApplication
                    }
                    originalSalesDate={formValues?.salesDate}
                  />
                </Container>
              )}
            </>
          );
        }}
      </Formik>
      {notification ? <ToastNotification notification={notification} /> : null}
    </>
  );
};

export default Component;
