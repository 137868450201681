import React from 'react';

type Props = {
  [key: string]: string | number | Function;
};

const Copy = (props: Props): React.ReactElement => {
  return (
    <svg
      width="14"
      height="10"
      viewBox="0 0 14 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.3 0.7L12.1 4.5H0V5.5H12.1L8.3 9.3L9 10L14 5L9 0L8.3 0.7Z"
        fill="white"
      />
    </svg>
  );
};

export default Copy;
