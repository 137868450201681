import React from 'react';
import styled from 'styled-components';
import { theme } from '../../../config';
import words from '../../../constants/words';
import { SubHeader } from '../../atoms/Subheader';
import { CompanyInfo } from '../../templates/CompanyInfo';

const ChildrenWrapper = styled.div``;

const TabsRow = styled.div`
  display: flex;
  background-color: ${theme.colors.ui03};
  padding: 0 78px;
  @media ${theme.breakpoints.mobile} {
    padding: 0;
  }
`;

const Tab = styled.span`
  border-bottom: solid #ff714f 2px;
  padding: 8px 20px;
  min-width: 160px;

  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.16px;
  color: ${theme.colors.text01};
`;

type Props = {};

const Component = ({}: Props): React.ReactElement => {
  return (
    <div>
      <SubHeader title={words.companyInformation} />
      <TabsRow>
        <Tab>{words.details}</Tab>
      </TabsRow>
      <ChildrenWrapper>
        <React.Suspense fallback={<div />}>
          <CompanyInfo />
        </React.Suspense>
      </ChildrenWrapper>
    </div>
  );
};

export default Component;
