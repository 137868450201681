import React from 'react';
import styled from 'styled-components';
import {
  DataTable,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  DataTableCustomRenderProps,
  DataTableHeader,
  DataTableRow,
} from 'carbon-components-react';
import { theme } from '../../../config';
import { EmptyResultDisplay } from '../../organisms/EmptyResultDisplay';
import words from '../../../constants/words';

const StyledTable = styled(Table)`
  thead {
    font-size: 13px;
  }
  tbody {
    font-size: 13px;
  }
  th {
    background-color: ${theme.colors.ui03};
  }
  && {
    td {
      background-color: ${theme.colors.ui01};
    }
    .bx--data-table--selected td {
      background-color: ${theme.colors.disabledButton1};
    }
    tr {
      :hover td {
        background-color: ${theme.colors.hoverui};
      }
    }
  }
`;

export type TableHeaderType = DataTableHeader;

export type TableRowType = DataTableRow;

export type Props = {
  headers: DataTableHeader[];
  rows: DataTableRow[];
  className?: string;
  disabledColumns?: boolean;
  emptyDataLabelTitle?: string;
  emptyDataLabelDetails?: string;
};

const Component = ({
  headers,
  rows,
  className,
  emptyDataLabelTitle = '',
  emptyDataLabelDetails = '',
}: Props): React.ReactElement => {
  return (
    <DataTable rows={rows} headers={headers}>
      {({ rows: _rows, headers: _headers }: DataTableCustomRenderProps) => (
        <StyledTable className={className}>
          <TableHead>
            <TableRow>
              {_headers.map(header => (
                <TableHeader key={header.key}>{header.header}</TableHeader>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {_rows.length ? (
              _rows.map(row => (
                <TableRow key={row.id}>
                  {row.cells.map(cell => (
                    <TableCell key={cell.id}>{cell.value}</TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow key={_rows.length}>
                <TableCell colSpan={_headers.length}>
                  <EmptyResultDisplay
                    title={emptyDataLabelTitle}
                    details={emptyDataLabelDetails}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </StyledTable>
      )}
    </DataTable>
  );
};

export default Component;
