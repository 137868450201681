import styled from 'styled-components';
import { Pagination } from 'carbon-components-react';
import themes from '../../../config/themes';

export const MainContainer = styled.div`
  &.ranking-app-list-no-results {
    margin: 64px 73px 62px 78px;
    background: ${themes.main.colors.ui02};
    box-shadow: 0px 0px 8px rgba(236, 215, 192, 0.8);
  }

  @media ${themes.main.breakpoints.mobile} {
    &.ranking-app-list-no-results {
      margin: 13px;
      height: calc(100% - 85px);
      display: flex;
    }
  }
`;

export const NotificationInlineErrorWrapper = styled.div`
  margin: 32px 73px 32px 78px;

  @media ${themes.main.breakpoints.mobile} {
    display: none;
  }
`;

export const DataTableWrapper = styled.div<{ $hasInlineError?: boolean }>`
  width: calc(100% - 151px);
  height: fit-content;
  box-shadow: 0px 0px 8px rgba(236, 215, 192, 0.8);
  margin: ${({ $hasInlineError }): string => ($hasInlineError ? '0' : '64px')}
    73px 20px 78px;

  @media ${themes.main.breakpoints.mobile} {
    display: none;
  }
`;

export const ActionsContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const NewApplicationText = styled.p`
  color: ${themes.main.colors.edit};
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;

  &:not(.new-application-disabled):hover {
    cursor: pointer;
    text-decoration: underline;
  }

  &.new-application-disabled {
    color: ${themes.main.colors.text03};
  }

  &.new-application-disabled: hover {
    cursor: not-allowed;
  }
`;

export const MobileNotificationInlineErrorWrapper = styled.div<{
  $isInUnassignedGroup?: boolean;
}>`
  display: none;

  .bx--inline-notification__details {
    ${({ $isInUnassignedGroup }): string =>
      $isInUnassignedGroup ? 'margin: 0 2rem 0 1rem;' : ''}
  }

  .bx--inline-notification__text-wrapper {
    ${({ $isInUnassignedGroup }): string =>
      $isInUnassignedGroup
        ? ''
        : `
          margin-left: auto;
          margin-right: auto;
          `}
  }

  .bx--inline-notification__title {
    display: none;
  }

  .bx--inline-notification__subtitle {
    text-align: ${({ $isInUnassignedGroup }): string =>
      $isInUnassignedGroup ? 'left' : 'center'};
    white-space: pre-wrap;
  }

  @media ${themes.main.breakpoints.mobile} {
    display: inherit;
    margin: 13px 14px 12px 14px;
  }
`;

export const MobileListContainer = styled.div<{
  $hasInlineError?: boolean;
}>`
  box-shadow: ${(props): string =>
    props.$hasInlineError ? '0px 0px 8px rgba(236, 215, 192, 0.8)' : ''};
  padding: ${(props): string => (props.$hasInlineError ? '5px 0' : '0')};

  @media ${themes.main.breakpoints.pc} {
    display: none;
  }
  @media ${themes.main.breakpoints.tablet} {
    display: none;
  }
  width: 100%;
  height: max-content;
  background: white;
`;

export const MobileItemWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  background-color: ${themes.main.colors.ui01};
  margin-bottom: 5px;
`;

export const ItemNumber = styled.p`
  width: 10.5px;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  padding: 0.625rem 1.4375rem 0rem 1.5rem;
  color: ${themes.main.colors.text02};
`;

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.625rem 0rem 0.625rem 0rem;
`;

export const ItemName = styled.p`
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  color: ${themes.main.colors.text01};
`;

export const IconIndexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StarWrapper = styled.div`
  width: max-content;
  height: max-content;
  margin-right: 0.45rem;
`;

export const IndexLabel = styled.p`
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: ${themes.main.colors.text01};
`;

export const CustomPagination = styled(Pagination)`
  &&&& {
    background-color: ${themes.main.colors.ui01};
    border: none;
    border-top: thin solid ${themes.main.colors.ui03};
    overflow: hidden;

    .bx--select-input {
      padding: 0 2rem 0 var(--cds-spacing-05, 1rem);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      &:focus {
        outline: none;
      }
    }

    .bx--select-input .bx--select-option {
      background-color: ${themes.main.colors.paginationDropdownBackground};
      color: ${themes.main.colors.text01};
      border: thin solid ${themes.main.colors.paginationDropdownBorder};
      border-radius: 6px;

      &:hover {
        background-color: ${themes.main.colors.paginationDropdownHover};
      }
    }

    .bx--pagination__button,
    .bx--btn--ghost.bx--pagination__button {
      width: 3rem;

      &:focus {
        outline: none;
      }
    }

    .bx--btn--ghost:focus {
      box-shadow: none;
    }

    .bx--pagination__left .bx--pagination__text {
      font-size: 13px;
      line-height: 18px;
      color: ${themes.main.colors.text01};
    }

    .bx--pagination__right .bx--pagination__text {
      font-size: 13px;
      line-height: 18px;
      color: ${themes.main.colors.text01};
    }
  }
`;
