import React from 'react';
import { useNavigate } from 'react-router';
import { Homepage } from '../../templates/Homepage';

import { useGlobalState } from '../../../hooks/global';

type IHomepage = {};

const Component = ({}: IHomepage): React.ReactElement => {
  const navigate = useNavigate();
  const {
    useCurrentUser: { currentUser },
  } = useGlobalState();

  const removeHomeOptionState = () => {
    const elements = document.getElementsByClassName(
      'bx--accordion__title active',
    );
    elements[0].classList.remove('active');
  };

  const onClickRoutes = (routeName: string) => {
    switch (routeName) {
      case 'RecordApplication':
        navigateToEdit(`/application`);
        removeHomeOptionState();
        break;
      case 'Ranking':
        navigateToEdit(`/ranking-table`);
        removeHomeOptionState();
        break;
      case 'AccountSettings':
        navigateToEdit(`/account-info`);
        removeHomeOptionState();
        break;
      case 'RankingList':
        navigateToEdit(`/ranking-list`);
        removeHomeOptionState();
        break;
      case 'MemberManagement':
        navigateToEdit(`/members`);
        removeHomeOptionState();
        break;
      case 'RecordApproval':
        navigateToEdit(`/approval`);
        removeHomeOptionState();
        break;
      default:
        break;
    }
  };

  const navigateToEdit = (path: string) => {
    navigate(path);
  };

  return (
    <>
      <Homepage
        onClickRouteList={e => onClickRoutes(e)}
        showManageFunctions={currentUser?.roleName !== 'member'}
      />
    </>
  );
};

export default Component;
