import React from 'react';

type Props = {
  [key: string]: string | number | Function;
};

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="80"
      height="62"
      viewBox="0 0 80 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <rect x="24.1111" width="55.1111" height="20.6667" fill="#F5E7D8" />
      <rect
        x="24.1111"
        y="39.037"
        width="55.1111"
        height="20.6667"
        fill="#F5E7D8"
      />
      <path
        d="M27.1617 47.8154H25.6617V49.3154V55.4351V56.9351H27.1617H69.9992H71.4992V55.4351V49.3154V47.8154H69.9992H27.1617Z"
        fill="#FF714F"
        stroke="#3B1F34"
        strokeWidth="3"
      />
      <path
        d="M27.1617 7.97307H25.6617V9.47307V15.5927V17.0927H27.1617H69.9992H71.4992V15.5927V9.47307V7.97307H69.9992H27.1617Z"
        fill="#FF714F"
        stroke="#3B1F34"
        strokeWidth="3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.80351 17.7561V0.149124H4.40175V2.35H0V6.75176H4.40175V17.7561H0V22.1579H13.2053V17.7561H8.80351Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2053 62H0V53.1965C0 50.7655 1.97073 48.7947 4.40175 48.7947H8.80351V44.393H0V39.9912H8.80351C11.2345 39.9912 13.2053 41.962 13.2053 44.393V48.7947C13.2053 51.2258 11.2345 53.1965 8.80351 53.1965H4.40175V57.5982H13.2053V62Z"
        fill="black"
      />
    </svg>
  );
};

export default Component;
