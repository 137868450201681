import { HttpAdapter } from '../../usecases/ports/HttpAdapter';
import { Company, UpdateCompany } from '../../domain/entities/company';
import CompanyRepository from '../../usecases/ports/CompanyRepository';

export default class CompanyRepositoryImpl implements CompanyRepository {
  httpAdapter: HttpAdapter;
  urls: {
    [key: string]: (query: { [key: string]: string }) => string;
  };

  constructor(
    httpAdapter: HttpAdapter,
    urls: {
      [key: string]: (query: { [key: string]: string }) => string;
    },
  ) {
    this.httpAdapter = httpAdapter;
    this.urls = urls;
  }

  find = async (companyId: number): Promise<Company | undefined> => {
    const response = await this.httpAdapter.get(
      this.urls.findCompany({ companyId: `${companyId}` }),
      {},
    );
    const { data } = response;
    return {
      ...data,
      repName: data.representative,
      telephone: data.contact_number,
    };
  };

  update = async (
    companyId: number,
    params: UpdateCompany,
  ): Promise<Company> => {
    const response = await this.httpAdapter.patch(
      this.urls.updateCompany({ companyId: `${companyId}` }),
      {
        name: params.name,
        representative: params.repName,
        contact_number: params.telephone,
        email: params.email,
      },
    );
    return response.data;
  };

  emailVerification = async (
    companyId: string,
    token: string,
  ): Promise<void> => {
    const response = await this.httpAdapter.get(
      this.urls.verifyUpdatedCompanyEmail({
        companyId,
        token,
      }),
      {},
    );
    return response.data;
  };

  resendEmailVerification = async (companyId: string): Promise<void> => {
    await this.httpAdapter.get(
      this.urls.resendCompanyEmailVerification({
        companyId,
      }),
      {},
    );
  };
}
