import React from 'react';
import styled from 'styled-components';
import { theme } from '../../../config';
import themes from '../../../config/themes';
import words from '../../../constants/words';
import { Button } from '../../atoms/Button';
import { EmailVerified } from '../../atoms/Icons';

const MainWrapper = styled.div`
  width: 100%;
  height: 100vh;
  background: ${theme.colors.dropDownContainerBgColor};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media ${themes.main.breakpoints.mobile} {
    padding: 56px 23px;
    display: block;
    position: relative;
  }
`;

const Container = styled.div`
  height: 522px;
  width: 620px;
  background-color: ${theme.colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #c7b29b;
  border-radius: 8px;

  @media ${themes.main.breakpoints.mobile} {
    height: auto;
    width: 100%;
    padding: 60px 22px;
  }
`;

const IconWrapper = styled.div`
  @media ${themes.main.breakpoints.mobile} {
    width: 100%;
    display: flex;
    align-items: center;
    display: flex;
    justify-content: center;
  }
`;

const TextHeader = styled.p`
  font-size: 28px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0px;
  margin-top: 40px;

  @media ${themes.main.breakpoints.mobile} {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    margin-top: 24px;
  }
`;

const SubtitleText = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  margin: 36px 0;
  text-align: center;

  @media ${themes.main.breakpoints.mobile} {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.16px;
    margin: 24px 0;
  }
`;

const StyledButton = styled(Button)`
  height: 48px;
  width: 141px;
  padding: 2px;

  @media ${themes.main.breakpoints.mobile} {
    width: 214px !important;
    height: 38px !important;
    font-size: 14px !important;
  }
`;

const Component = ({
  backToHome,
}: {
  backToHome: () => void;
}): React.ReactElement => {
  return (
    <MainWrapper>
      <Container>
        <IconWrapper>
          <EmailVerified />
        </IconWrapper>
        <TextHeader>{words.emailVerificationSuccess}</TextHeader>
        <SubtitleText>{words.successfullyVerifiedEmail}</SubtitleText>
        <StyledButton onPress={backToHome} title={words.backToHome} />
      </Container>
    </MainWrapper>
  );
};

export default Component;
