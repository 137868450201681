import React from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';

import { Banner } from '../../molecules/Banner';
import { ResetPasswordForm } from '../../organisms/ResetPasswordForm';

import { ResetPasswordSchema } from './validation';

import BannerImage from '../../../assets/images/team-handshake.jpg';
import { theme } from '../../../config';
import words from '../../../constants/words';

const Container = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  min-height: 100vh;
  background-color: ${theme.colors.ui01};
  @media ${theme.breakpoints.mobileAndTablet} {
    grid-template-columns: 100%;
  }
`;

const Column = styled.div<{ isBanner?: boolean }>`
  padding: ${props => (props.isBanner ? '0' : '60px 5%')};
  @media ${theme.breakpoints.mobileAndTablet} {
    ${props => props.isBanner && 'display: none'};
  }
  @media ${theme.breakpoints.mobile} {
    padding: ${props => (props.isBanner ? '0' : '31px 0')};
  }
`;

export type Props = {
  onResetPassword: (values: { password: string; password2: string }) => void;
  onPressLogin: () => void;
  isLoading?: boolean;
};

const Component = ({
  onResetPassword,
  isLoading = false,
  onPressLogin,
}: Props): React.ReactElement => {
  const initialValues = {
    password: '',
    password2: '',
  };

  return (
    <Container>
      <Column>
        <Formik
          initialValues={initialValues}
          validationSchema={ResetPasswordSchema}
          onSubmit={onResetPassword}>
          {({
            handleSubmit,
            handleChange,
            values,
            errors,
            isValid,
            dirty,
          }): React.ReactElement => {
            const { password, password2 } = values;
            const {
              password: passwordError,
              password2: password2Error,
            } = errors;

            return (
              <ResetPasswordForm
                password={password}
                password2={password2}
                onChangePassword={handleChange('password')}
                onChangePassword2={handleChange('password2')}
                passwordError={passwordError}
                password2Error={password2Error}
                onPressSubmit={handleSubmit}
                isLoading={isLoading}
                onPressLogin={onPressLogin}
                isSubmitDisabled={!(isValid && dirty) || isLoading}
              />
            );
          }}
        </Formik>
      </Column>
      <Column isBanner>
        <Banner label={words.bannerLabel} imageUrl={BannerImage} />
      </Column>
    </Container>
  );
};

export default Component;
