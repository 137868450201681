import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import CloseButton from 'assets/icons/close-button.svg';
import './inviteMemberModal.scss';
import words from 'constants/words';
import { OrangeButton } from 'components/atoms/OrangeButton';
import AddBtn from 'assets/icons/addBtn.svg';
import { MemberItemInvite } from '../MemberItemInvite';
import AttachIcon from 'assets/icons/attach-icon.svg';
import { validateEmail, validateRole } from 'utils/common';
import { listMemberInvite } from 'type/member';

const options = [
  { value: 1, label: '申請者' },
  { value: 2, label: '承認者' },
];

type PropType = {
  isShow: boolean;
  onHideModal: () => void;
  handleAddNewInvite: (_data: { email: string; role: string }) => void;
  listMemberInvite: listMemberInvite;
  dupEmail: boolean;
  handleCancel: (_email: string) => void;
  handleInviteMemberList: () => void;
  copyAllLink: () => void;
};

export default function InviteMemberModal(props: PropType) {
  const {
    isShow,
    onHideModal,
    handleAddNewInvite,
    listMemberInvite,
    dupEmail,
    handleCancel,
    handleInviteMemberList,
    copyAllLink,
  } = props;
  const [newUserEmail, setNewUserEmail] = useState<string>('');
  const [newUserRole, setNewUserRole] = useState<string>('申請者');
  const [error, setError] = useState<boolean>(true);
  const [errorRole, setErrorRole] = useState<boolean>(false);

  function accentUser() {
    if (error === false && errorRole === false && newUserEmail !== '') {
      handleAddNewInvite({
        email: newUserEmail,
        role: newUserRole,
      });
      setNewUserEmail('');
    }
    return;
  }

  function handleEmailAddr(value: string) {
    const check = validateEmail(value);
    setNewUserEmail(value);
    if (value.length === 0) {
      setError(true);
      return;
    }
    if (!check) {
      setError(true);
      return;
    }
    setError(false);
    return;
  }

  function onHandleRole(value: number, label: string) {
    const listRole = [1, 2];
    setNewUserRole(label);
    if (!listRole.includes(value)) {
      setErrorRole(true);
      return;
    }
    const check = validateRole(label);
    if (!check) {
      setErrorRole(true);
      return;
    }
    setErrorRole(false);
    return;
  }

  return (
    <div className={`invite-member-pages ${isShow ? 'show' : 'hide'}`}>
      <div className="title-modal">
        <div className="modal-title">メンバー招待</div>
        <div className="close-button" onClick={onHideModal}>
          <img src={CloseButton} />
        </div>
      </div>
      <div className="modal-invite-content">
        <div className="inputEmail">
          <input
            type="email"
            placeholder="メールアドレス入力"
            className={`${error || newUserEmail.length == 0 ? 'failed' : ''}`}
            onChange={e => handleEmailAddr(e.target.value)}
            value={newUserEmail}
          />
        </div>
        <div className="selectRole">
          <Select
            options={options}
            defaultValue={{ value: 1, label: '申請者' }}
            className={`groups__selection ${errorRole ? 'failed' : ''}`}
            classNamePrefix="groups__selection"
            placeholder={
              <span className="placeholder-option">{words.groupName}</span>
            }
            isSearchable={false}
            onChange={e => onHandleRole(e!.value, e!.label)}
          />
        </div>
        <div className="addBtn" onClick={() => accentUser}>
          <img src={AddBtn} onClick={accentUser} />
        </div>
      </div>
      <div className="duplicate">
        {dupEmail ? 'このメールは招待リストに既に存在します。' : ''}
      </div>
      <div className="member-invite">
        {listMemberInvite &&
          listMemberInvite.map(item => (
            <MemberItemInvite
              key={item.email}
              email={item.email}
              role={item.role}
              handleCancel={data => handleCancel(data)}
            />
          ))}
      </div>
      <div className="copiedInvite" onClick={copyAllLink}>
        <span>
          <img src={AttachIcon} />
        </span>
        <span>リンクコピー</span>
      </div>
      <div className="btnBox">
        <OrangeButton
          text="招待を送信"
          style={{ width: '144px', height: '42px' }}
          onClick={handleInviteMemberList}
        />
      </div>
    </div>
  );
}
