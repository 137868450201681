import React, { useState } from 'react';
import ReactLoading from 'react-loading';
import './loading.scss';

type PropType = {
  display: string;
};

const Loading = (props: PropType) => {
  const { display } = props;
  return (
    <div className="loading-react" style={{ display: display }}>
      <div className="boxLoading">
        <ReactLoading
          type="bars"
          color="#FB8C00"
          height={175}
          width={175}
          className="loadingWrap"
        />
      </div>
    </div>
  );
};

export default Loading;
