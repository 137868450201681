import React, { useState } from 'react';
import './memberItemInvite.scss';
import GreenCheck from 'assets/icons/greenCheck.svg';
import Close from 'assets/icons/close-button-2.svg';
import { cutString, getRoleName } from 'utils/common';

type PropType = {
  email: string;
  role: string;
  handleCancel: (_email: string) => void;
};

export default function MemberItemInvite(props: PropType) {
  const { email, role, handleCancel } = props;
  return (
    <div className="member-invite-item-page">
      <div className="emailShow">{cutString(email, 15)}</div>
      <div className="checkBtn">
        <img src={GreenCheck} />
      </div>
      <div className="roleName">{getRoleName(role)}</div>
      <div className="cancelMail" onClick={() => handleCancel(email)}>
        <img src={Close} />
      </div>
    </div>
  );
}
