import React from 'react';

type Props = {
  [key: string]: string | number | Function;
};

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.75 1.34375L11.6562 0.25L6.5 5.40625L1.34375 0.25L0.25 1.34375L5.40625 6.5L0.25 11.6562L1.34375 12.75L6.5 7.59375L11.6562 12.75L12.75 11.6562L7.59375 6.5L12.75 1.34375Z"
        fill="#161616"
      />
    </svg>
  );
};

export default Component;
