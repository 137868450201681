import GroupRepository from '../../ports/GroupRepository';

export type deleteGroupUseCase = (payload: {
  companyId: number;
  groupId: number;
}) => Promise<void>;

export const buildDeleteGroup = (dependencies: {
  groupRepo: GroupRepository;
}): deleteGroupUseCase => {
  const { groupRepo } = dependencies;

  const deleteGroup: deleteGroupUseCase = async query =>
    groupRepo.delete(query);

  return deleteGroup;
};
