import styled, { createGlobalStyle } from 'styled-components';
import theme from '../../../config/themes/main';
import { Button } from '../../atoms/Button';
import { Link } from 'carbon-components-react';
import { TextInput } from '../../atoms/TextInput';
import { DatePicker } from '../../molecules/DatePicker';
import { MultiSelectDropdown } from '../../organisms/MultiSelectDropdown';

export const BackgroundTemplate = styled.div`
  min-height: 100vh;
  @media ${theme.breakpoints.mobile} {
    flex-direction: column;
  }
`;

export const Content = styled.div<{
  status: 'unapproved' | 'approved' | 'rejected' | 'withdraw' | '';
}>`
  display: flex;
  padding-top: 51px;
  padding-right: 78px;
  @media ${theme.breakpoints.mobile} {
    flex-direction: column;
    padding-top: 37px;
    padding-right: 0px;
    margin-bottom: ${props =>
      props.status === 'unapproved'
        ? '135px'
        : props.status === 'approved'
        ? '80px'
        : '0px'};
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  @media ${theme.breakpoints.mobile} {
    width: 100%;
  }
`;

export const TextInputContainer = styled.div.attrs(
  (props: {
    isColumn1: boolean;
    isDate?: boolean;
    isNotesOnRightCol?: boolean;
    isNotesOnLeftCol?: boolean;
  }) => ({
    ...props,
  }),
)`
  height: 56px;
  width: calc(100% - 30px);
  margin-bottom: 48px;
  margin-left: ${props => (props.isColumn1 ? `65px` : `70px`)};
  display: ${props => (props.isNotesOnRightCol ? `none` : `initial`)};
  @media ${theme.breakpoints.mobile} {
    height: 32px;
    width: calc(100% - 42px);
    margin-bottom: ${props => (props.isDate ? `160px` : `60px`)};
    margin-left: 23px;
    display: ${props => (props.isNotesOnLeftCol ? `none` : `initial`)};
  }
`;

export const StyledTextInput = styled(TextInput)`
  .bx--text-input:disabled {
    border: 1px solid ${theme.colors.inputBorderColor};
    background: ${theme.colors.white};
    color: ${theme.colors.text01};
    -webkit-text-fill-color: ${theme.colors.text01};
  }
  .bx--label--disabled {
    color: ${theme.colors.text02};
  }
`;

export const DatePickersContainer = styled.div`
  display: flex;
  flex-direction: row;
  & > div {
    flex: 1;
  }
  & > div:first-child {
    margin-right: 14px;
  }
  @media ${theme.breakpoints.mobile} {
    flex-direction: column;
  }
`;

export const DatePickerContainer = styled.div`
  width: calc(100% - 225px);
  height: 40px;
  @media ${theme.breakpoints.mobile} {
    width: 100%;
    margin-bottom: 32px;
  }
`;

export const StyledDatePicker = styled(DatePicker)`
  .bx--date-picker--single > div > div > .bx--date-picker__input:disabled {
    border: 1px solid ${theme.colors.inputBorderColor};
    background-color: ${theme.colors.white};
    color: ${theme.colors.text01};
  }
  .bx--label--disabled {
    color: ${theme.colors.text02};
  }
  .bx--date-picker__input:disabled ~ .bx--date-picker__icon {
    fill: ${theme.colors.text01};
  }
`;

export const StyledDropdown = styled(MultiSelectDropdown)`
  .css-1insrsq-control {
    border: 1px solid ${theme.colors.inputBorderColor};
    background-color: ${theme.colors.white};
  }
  .css-1rhbuit-multiValue {
    background-color: ${theme.colors.chipColor};
    color: ${theme.colors.white};
  }
  .css-12jo7m5 {
    color: ${theme.colors.white};
  }
  .css-lr9fot-singleValue {
    color: ${theme.colors.text01};
  }
  .css-109onse-indicatorSeparator {
    display: none;
  }
  .css-tlfecz-indicatorContainer {
    color: ${theme.colors.icon01};
    svg {
      stroke: ${theme.colors.white};
      stroke-width: 1px;
    }
  }
  .labelContainer > span {
    color: ${theme.colors.text01};
  }
  .css-1rhbuit-multiValue > .css-12jo7m5 > .labelContainer > span {
    color: ${theme.colors.white};
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 13px;
  margin-left: 70px;
  margin-bottom: 47px;
  @media ${theme.breakpoints.mobile} {
    display: none;
  }
`;

export const ButtonsContainerMobile = styled.div<{ isRejected: boolean }>`
  display: none;
  @media ${theme.breakpoints.mobile} {
    flex-direction: column;
    margin-left: 0px;
    margin-bottom: 0px;
    margin-top: 0px;
    padding-bottom: 18px;
    padding-top: 15px;
    padding-left: 23px;
    padding-right: 23px;
    position: fixed;
    bottom: 0;
    z-index: 1000;
    background: ${theme.colors.ui01};
    box-shadow: 0px 0 10px rgba(0, 0, 0, 0.2);
    width: 100%;
    display: ${props => (props.isRejected ? 'none' : 'flex')};
    & div > div {
      width: 100%;
      margin-bottom: 7px;
    }
  }
`;

export const ButtonContainer = styled.div`
  width: 125px;
  height: 48px;
  margin-right: 16px;
  margin-top: 5px;
  @media ${theme.breakpoints.mobile} {
    display: none;
  }
`;

export const ButtonContainerMobile = styled.div`
  display: none;
  @media ${theme.breakpoints.mobile} {
    display: flex;
    flex-direction: column;
  }
`;

export const StyledButton = styled(Button)`
  height: 45px;
  padding: 9px;
  &:focus {
    box-shadow: inset 0 0 0 1px ${theme.colors.white};
  }
`;

export const StyledLink = styled(Link)`
  &:hover {
    cursor: pointer;
    @media ${theme.breakpoints.mobile} {
      padding-left: 10px;
    }
  }
`;

export const Label = styled.text.attrs((props: { status?: string }) => ({
  ...props,
}))`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: ${props =>
    props.status === 'approved'
      ? `${theme.colors.support02}`
      : props.status === 'rejected'
      ? `${theme.colors.support01}`
      : `${theme.colors.text01}`};
`;

export const Text = styled.text`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.32px;
  color: ${theme.colors.disabled02};
`;

export const TransactionCont = styled.div`
  display: flex;
  flex-direction: column;
  @media ${theme.breakpoints.mobile} {
    width: calc(100% - 13px);
  }
`;

export const HistContainer = styled.div`
  margin-bottom: 18px;
  margin-left: 70px;
  width: calc(100% - 30px);
  @media ${theme.breakpoints.mobile} {
    margin-left: 23px;
  }
`;

export const StatusCont = styled.div<{
  isStatusRightCol?: boolean;
  isStatusLeftCol?: boolean;
}>`
  height: 51px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 70px;
  margin-bottom: 53px;
  display: ${props => (props.isStatusRightCol ? `none` : `inherit`)};
  @media ${theme.breakpoints.mobile} {
    margin-left: 25px;
    margin-bottom: 30px;
    display: ${props => (props.isStatusLeftCol ? `none` : `inherit`)};
  }
`;

export const MultiSelectContainer = styled.div`
  margin-top: 6px;
  &&
    > div.multi-select-sub-approver-exists
    > div:nth-child(3)
    > div:first-child
    > div:nth-last-child(2) {
    max-width: 1px;
  }
  span {
    color: ${theme.colors.text02};
  }
`;

export const MultiSelectPlaceholder = styled.div`
  height: 20px;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.16px;
  color: ${theme.colors.disabled02};
  margin-left: 5px;
`;

export const ProfileImageContainer = styled.div`
  padding-left: 100px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 25px;
  @media ${theme.breakpoints.mobile} {
    padding-left: 0px;
    margin-top: 0px;
    margin-bottom: 30px;
  }
`;

export const GlobalStyle = createGlobalStyle`
  & {
    .bx--tooltip__footer.bx--tooltip__footer.bx--tooltip__footer.bx--tooltip__footer.bx--tooltip__footer > button {
      width: 115px;
      height: 32px;
    }

    .bx--tooltip.bx--tooltip--bottom.bx--tooltip--align-end {
      max-width: 262px;
      width: 257px;
    }

    .bx--tooltip.bx--tooltip--bottom.bx--tooltip--align-end .bx--tooltip__caret {
      margin-right: 65px;
    }

    .bx--tooltip p {
      width: 100%;
    }
  }

  @media ${theme.breakpoints.mobile} {
    & {
      .bx--tooltip__footer.bx--tooltip__footer.bx--tooltip__footer.bx--tooltip__footer.bx--tooltip__footer > button {
        width: 115px;
      }
      .bx--tooltip.bx--tooltip--shown.bx--tooltip.bx--tooltip--shown.bx--tooltip.bx--tooltip--shown {
        min-width: calc(100% - 45px) !important;
        padding: 16px 25px;
      }

      .bx--tooltip .bx--tooltip__footer {
        margin-top: 17px;
      }

      .bx--tooltip[data-floating-menu-direction=top] {
        position: fixed !important;
        top: calc(100% - 220px) !important;
        left: 24px !important;
      }
    }
  }
`;
