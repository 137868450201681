import { useCallback } from 'react';
import { GroupHooks } from '.';
import { useDependencies } from '..';
import { Approvers, Group, GroupData } from '../../domain/entities/group';
import { PaginationQuery } from '../../domain/entities/request';
import { PaginatedResponse } from '../../domain/entities/response';

export const useFetchGroups: GroupHooks['useFetchGroups'] = (): {
  fetchGroups: (
    query: PaginationQuery & { companyId: number },
  ) => Promise<PaginatedResponse & { results: Group[] }>;
} => {
  const { groupInteractor } = useDependencies();
  const fetchGroups = useCallback(
    async (query: PaginationQuery & { companyId: number }) => {
      const response = await groupInteractor.fetchGroups(query);

      return response;
    },
    [],
  );

  return { fetchGroups };
};

export const useDeleteGroup: GroupHooks['useDeleteGroup'] = (): {
  deleteGroup: (payload: {
    groupId: number;
    companyId: number;
  }) => Promise<void>;
} => {
  const { groupInteractor } = useDependencies();
  const deleteGroup = useCallback(
    async (payload: { groupId: number; companyId: number }) => {
      await groupInteractor.deleteGroup(payload);
    },
    [],
  );

  return { deleteGroup };
};

export const useFetchApprovers: GroupHooks['useFetchApprovers'] = (): {
  fetchApprovers: (query: { companyId: number }) => Promise<Approvers[]>;
} => {
  const { groupInteractor } = useDependencies();
  const fetchApprovers = useCallback(async (query: { companyId: number }) => {
    const response = await groupInteractor.fetchApprovers(query);

    return response;
  }, []);

  return { fetchApprovers };
};

export const useAddGroup: GroupHooks['useAddGroup'] = (): {
  addGroup: (
    payload: { companyId: number },
    data: GroupData,
  ) => Promise<Response>;
} => {
  const { groupInteractor } = useDependencies();
  const addGroup = useCallback(
    async (payload: { companyId: number }, data: GroupData) => {
      const response = await groupInteractor.addGroup(payload, data);
      return response;
    },
    [],
  );
  return { addGroup };
};

export const useUpdateGroup: GroupHooks['useUpdateGroup'] = (): {
  updateGroup: (
    payload: { companyId: number; groupId: number },
    data: GroupData,
  ) => Promise<Response>;
} => {
  const { groupInteractor } = useDependencies();
  const updateGroup = useCallback(
    async (
      payload: { companyId: number; groupId: number },
      data: GroupData,
    ) => {
      const response = await groupInteractor.updateGroup(payload, data);
      return response;
    },
    [],
  );
  return { updateGroup };
};

export const useCheckDuplicateGroupName: GroupHooks['useCheckDuplicateGroupName'] = (): {
  checkDuplicates: (query: {
    companyId: number;
    groupName: string;
  }) => Promise<Response>;
} => {
  const { groupInteractor } = useDependencies();
  const checkDuplicates = useCallback(
    async (query: { companyId: number; groupName: string }) => {
      const response = await groupInteractor.checkDuplicateGroupName(query);
      return response;
    },
    [],
  );

  return { checkDuplicates };
};
