import { Formik } from 'formik';
import React from 'react';
import styled from 'styled-components';
import { theme } from '../../../config';
import words from '../../../constants/words';
import { Banner } from '../../molecules/Banner';
import { ResendEmailSchema } from './validation';
import BannerImage from '../../../assets/images/team-handshake.jpg';
import { ResendEmailVerificationForm } from '../../organisms/ResendEmailVerificationForm';

const Container = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  min-height: 100vh;
  background-color: ${theme.colors.ui01};
  @media ${theme.breakpoints.mobileAndTablet} {
    grid-template-columns: 100%;
  }
`;

const Column = styled.div<{ isBanner?: boolean }>`
  padding: ${props => (props.isBanner ? '0' : '60px 5%')};
  @media ${theme.breakpoints.mobileAndTablet} {
    ${props => props.isBanner && 'display: none'};
  }
  @media ${theme.breakpoints.mobile} {
    padding: ${props => (props.isBanner ? '0' : '31px 0')};
  }
`;

export type VerificationLinkExpiredProps = {
  onClickSubmit: (values: FormValuesProps, resetForm?: () => void) => void;
  onPressBackToLogin: () => void;
  isLoading?: boolean;
  showErrorNotif: boolean;
  emailSentSuccess: boolean;
  onCloseNotif?: () => void;
  isCompanyEmail?: boolean;
};

export type FormValuesProps = {
  email: string;
};

const Component = ({
  onClickSubmit,
  isLoading,
  onPressBackToLogin,
  showErrorNotif,
  emailSentSuccess,
  onCloseNotif,
  isCompanyEmail,
}: VerificationLinkExpiredProps): React.ReactElement => {
  const initialValues = {
    email: '',
  };

  return (
    <Container>
      <Column>
        <Formik
          initialValues={initialValues}
          validationSchema={ResendEmailSchema}
          onSubmit={(values: FormValuesProps, { resetForm }) =>
            onClickSubmit(values, resetForm)
          }>
          {({
            handleSubmit,
            handleChange,
            values,
            errors,
            isValid,
            dirty,
          }): React.ReactElement => {
            const { email } = values;
            const { email: emailError } = errors;

            return (
              <ResendEmailVerificationForm
                email={email}
                onChangeEmail={handleChange('email')}
                emailError={emailError}
                onPressSubmit={handleSubmit}
                isLoading={isLoading}
                onPressBackToLogin={onPressBackToLogin}
                isSubmitDisabled={!(isValid && dirty) || isLoading}
                showErrorNotif={showErrorNotif}
                emailSentSuccess={emailSentSuccess}
                onCloseNotif={onCloseNotif}
                isCompanyEmail={isCompanyEmail}
              />
            );
          }}
        </Formik>
      </Column>
      <Column isBanner>
        <Banner label={words.bannerLabel} imageUrl={BannerImage} />
      </Column>
    </Container>
  );
};

export default Component;
