import MemberRepository from '../../ports/MemberRepository';
import { CheckUnassignedExistsType } from '../../../domain/entities/member';

export type checkUnassignedExistsUseCase = (
  companyId: number,
) => Promise<CheckUnassignedExistsType>;

export const buildCheckUnassignedExists = (dependencies: {
  memberRepo: MemberRepository;
}): checkUnassignedExistsUseCase => {
  const { memberRepo } = dependencies;

  const checkUnassignedExists: checkUnassignedExistsUseCase = async companyId =>
    memberRepo.checkUnassignedExists(companyId);

  return checkUnassignedExists;
};
