import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import styled from 'styled-components';
import { theme } from '../../../config';
import words from '../../../constants/words';

import { Banner } from '../../molecules/Banner';
import LogoImage from '../../../assets/images/ever-contest.png';
import BannerImage from '../../../assets/images/team-handshake.jpg';
import { ForgetPasswordForm } from '../../organisms/ForgetPasswordForm';
import { ForgetPasswordSchema } from './validation';

const Container = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  min-height: 100vh;
  background-color: ${theme.colors.ui01};

  @media ${theme.breakpoints.mobileAndTablet} {
    grid-template-columns: 100%;
  }
`;

const Column = styled.div<{ isBanner?: boolean }>`
  padding: ${props => (props.isBanner ? '0' : '60px 5%')};
  @media ${theme.breakpoints.mobileAndTablet} {
    ${props => props.isBanner && 'display: none'};
  }
  @media ${theme.breakpoints.mobile} {
    padding: ${props => (props.isBanner ? '0' : '31px 0')};
  }
`;

const TextHeader = styled.div`
  display: flex;
  margin-bottom: 5%;
  flex-direction: column;
  justify-content: space-between;
  @media ${theme.breakpoints.mobileAndTablet} {
    margin-bottom: 34px;
  }
`;

const Logo = styled.div<{ imageUrl: string }>`
  background-image: url(${props => props.imageUrl});
  background-repeat: no-repeat;
  background-size: contain;
  height: 23px;
  margin-bottom: 5%;
  margin-left: 2rem;
  @media ${theme.breakpoints.mobile} {
    height: 18px;
    margin-left: 25px;
  }
`;

const ForgetPasswordTitle = styled.text`
  font-size: 28px;
  line-height: 36px;
  padding-left: 2rem;
  color: ${theme.colors.blackText};
  margin-bottom: 3%;
  @media ${theme.breakpoints.mobile} {
    font-size: 20px;
    line-height: 26px;
    font-weight: 500;
    margin-bottom: 14px;
    padding-left: 23px;
  }
`;

const Instructions = styled.text`
  font-size: 13px;
  line-height: 18px;
  padding-left: 2rem;
  letter-spacing: 0.16px;
  color: ${theme.colors.blackText};
  @media ${theme.breakpoints.mobile} {
    padding: 0 18px 0 23px;
  }
`;

export type ForgetPasswordProps = {
  forgetPassword: (values: { email: string }, resetForm?: () => void) => void;
  successNotif?: boolean;
  errorNotif?: boolean;
  handleLogin: () => void;
  isBtnLoading?: boolean;
  setToDefaultNotifState?: () => void;
};

const Component = ({
  forgetPassword,
  successNotif,
  errorNotif,
  handleLogin,
  isBtnLoading,
  setToDefaultNotifState,
}: ForgetPasswordProps): React.ReactElement => {
  const initialValues = {
    email: '',
  };

  const [showSuccessNotif, setShowSuccessNotif] = useState<boolean | undefined>(
    undefined,
  );

  useEffect(() => {
    if (successNotif) {
      setShowSuccessNotif(true);
    } else {
      setShowSuccessNotif(successNotif);
    }
  }, [successNotif]);

  return (
    <Container>
      <Column>
        <TextHeader>
          <Logo imageUrl={LogoImage} />
          <ForgetPasswordTitle>{words.forgotPasswordTitle}</ForgetPasswordTitle>
          <Instructions>{words.forgotPassInstruction}</Instructions>
        </TextHeader>

        <Formik
          initialValues={initialValues}
          validationSchema={ForgetPasswordSchema}
          onSubmit={(values, { resetForm }) =>
            forgetPassword(values, resetForm)
          }>
          {({
            handleChange,
            handleSubmit,
            values,
            errors,
          }): React.ReactElement => {
            const { email } = values;
            const { email: emailError } = errors;
            return (
              <ForgetPasswordForm
                email={email}
                onChangeEmail={handleChange('email')}
                emailError={emailError}
                successNotif={successNotif}
                errorNotif={errorNotif}
                onPressSendLink={handleSubmit}
                onPressLogin={handleLogin}
                isLoading={isBtnLoading}
                setToDefaultNotifState={setToDefaultNotifState}
              />
            );
          }}
        </Formik>
      </Column>
      <Column isBanner>
        <Banner imageUrl={BannerImage} label={words.bannerLabel} />
      </Column>
    </Container>
  );
};

export default Component;
