import { useFormikContext } from 'formik';
import React, { useState, useEffect } from 'react';
import Moment from 'react-moment';
import styled, { createGlobalStyle } from 'styled-components';
import words, { languageUsed } from '../../../constants/words';
import { AnchorTag } from '../../atoms/AnchorTag';
import { TextInput } from '../../atoms/TextInput';
import { ToggleSwitch } from '../../atoms/ToggleSwitch';
import { DatePicker } from '../../molecules/DatePicker';
import { AddButton } from '../../templates/LoginFormSetting/elements';
import { Table } from '../Table';
import { Plus } from '../../atoms/Icons';
import themes from '../../../config/themes';
import TooltipComponent from '../../atoms/Tooltip/Tooltip';
import { Button } from '../../atoms/Button';
import { theme } from '../../../config';
import moment from 'moment';
import { TableLoader } from '../../atoms/Loading';
import {
  CustomDataTableSkeletonContainer,
  CustomDataTableSkeleton,
} from '../../organisms/DataTable/elements';

const TextInputWrapper = styled.div`
  justify-content: center;
  align-items: center;
  margin-left: -1.2em;
  input {
    margin-bottom: 0;
  }
`;

const CellWrapper = styled.div`
  .bx--toggle__switch {
    margin-top: 0;
  }
  .bx--toggle-input:checked
    + .bx--toggle-input__label
    > .bx--toggle__switch::before {
    background-color: ${themes.main.colors.rankingbgColor};
  }
`;

const AddFieldWrapper = styled.div`
  padding-top: 50px;
`;

const StyledAnchor = styled.div`
  && {
    margin-right: 0px;
    flex: 1;
    div:active {
      outline: none;
    }
    div {
      width: fit-content;
    }
  }
`;

const SaveCancelAnchors = styled(AnchorTag)`
  && {
    div:nth-child(1) {
      margin-right: 0px;
      flex: 1;
    }
    div {
      font-size: 16px;
      color: ${themes.main.colors.fontColor};
    }
  }
`;

//used !important to overide the inline css on button
const StyledAddButton = styled(AddButton)`
  && {
    width: 275px;
    background-color: ${themes.main.colors.interactive02} !important;
    border-color: ${themes.main.colors.interactive02} !important;
  }
`;

const StyledTooltip = styled(TooltipComponent)`
  &&&&& {
    flex: 1;
  }
`;

const StyledButton = styled(Button)`
  &&&&&&&& {
    width: 55%;
    padding: 0px 15px 0px 13px;
  }
`;

const StyledAnchorTag = styled(AnchorTag)`
  &&&&& {
    div {
      color: ${theme.colors.anchorTagModal};
    }
  }
`;

const StyledDivActions = styled.div`
  && {
    display: flex;
    div.cancelBtn {
      flex: 1;
    }
    div.saveBtn {
      flex: 1;
      div {
        color: ${theme.colors.anchorLinkColor};
      }
    }
  }
`;

const StyledDatePicker = styled(DatePicker)`
  &&& {
    input {
      text-align: center;
    }
    svg.bx--date-picker__icon {
      display: none;
    }
    svg.bx--date-picker__icon--invalid {
      display: unset;
    }
    .bx--date-picker.bx--date-picker--single .bx--date-picker__input {
      width: 100%;
    }
  }
`;

const TableStyle = styled(Table).attrs(
  (props: { isAddingField: boolean; indexToHighlight: number | null }) => props,
)`
  &&& {
    tbody tr:hover,
    tbody tr:hover td {
      background-color: ${theme.colors.rankingHover};
    }

    tbody tr:last-child td {
      background-color: ${props =>
        props.isAddingField ? theme.colors.ui03 : ''};
    }

    tbody
      tr:nth-child(${props =>
          props.indexToHighlight !== null ? props.indexToHighlight : ''})
      td {
      background-color: ${props =>
        props.indexToHighlight !== null ? theme.colors.ui03 : ''};
    }

    tbody tr td:nth-child(3),
    tbody tr td:nth-child(4),
    thead tr th:nth-child(3),
    thead tr th:nth-child(4) {
      text-align: center;
    }

    tbody tr td:nth-child(2) {
      width: 17%;
    }

    tbody tr td:nth-child(3),
    tbody tr td:nth-child(4) {
      width: 160px;
      max-width: 160px;
      @media ${themes.main.breakpoints.pc} {
        padding-left: 5px;
        padding-right: 5px;
      }
      @media ${themes.main.breakpoints.wideScreen} {
        padding-left: 40px;
        padding-right: 40px;
      }
      @media ${themes.main.breakpoints.tablet} {
        padding-left: 0px;
        padding-right: 0px;
      }
      @media ${themes.main.breakpoints.mobile} {
        padding-left: 0px;
        padding-right: 0px;
      }
    }

    tbody tr td:nth-child(5),
    thead tr th:nth-child(5) {
      width: 190px;
    }

    tbody tr td:nth-child(1),
    thead tr th:nth-child(1) {
      width: 84px;
      max-width: 84px;
    }

    tbody tr th:nth-child(6),
    tbody tr td:nth-child(6) {
      width: 300px;
      max-width: 300px;
    }

    tbody tr td {
      .bx--text-input__field-wrapper,
      .bx--date-picker-input__wrapper {
        margin-top: 3px;
        margin-bottom: 2px;
      }
      .bx--form-requirement {
        text-align: left;
        padding-bottom: 5px;
      }
    }
  }
`;

const StyledEditRankingDiv = styled.div`
  padding-left: 82px;
  padding-right: 55px;
`;

export const GlobalStyle = createGlobalStyle`
  .tooltip {
    &&&&& {
      min-width: 256px;
      max-width: 256px;
    }
  }
`;

export type IAddPeriodProps = {
  id: number;
  rankingPeriodName: string;
  startDate: Date;
  endDate: Date;
  visibility: boolean;
  disabled: boolean;
};

export type Props = {
  data: IAddPeriodProps[];
  isEmpty?: boolean;
  onDeleteRanking: (id: number | null) => void;
  onToggledSwitch?: (toggled?: boolean) => void;
  isLoading?: boolean;
};

export const ADD_PERIOD_HEADERS = [
  {
    key: 'id',
    header: 'No',
  },
  {
    key: 'rankingPeriodName',
    header: words.rankingPeriodName,
  },
  {
    key: 'startDate',
    header: words.startDate,
  },
  {
    key: 'endDate',
    header: words.endDate,
  },
  {
    key: 'visibility',
    header: words.visibility,
  },
  {
    key: 'action',
    header: '',
  },
];

const Component = ({
  data,
  isEmpty,
  onDeleteRanking,
  onToggledSwitch,
  isLoading = false,
}: Props): React.ReactElement => {
  const [isAddingField, setIsAddingField] = useState(false);
  const [isDeletingField, setIsDeletingField] = useState(false);
  const [idxToEdit, setIdxToEdit] = useState<number | null>(null);
  const [idToDelete, setIdToDelete] = useState<number | null>(null);
  const [indexToEDit, setIndexToEdit] = useState<number | null>(null);
  const [indexToHighlight, setIndexToHighlight] = useState<number | null>(null);
  const [switchValue, setSwitchValue] = useState<
    Array<{ id: number; value: boolean }>
  >([{ id: 0, value: false }]);
  const [inputValueError, setInputValueError] = useState<boolean>(false);
  const [startDateValueError, setStartDateValueError] = useState<boolean>(
    false,
  );
  const [endDateValueError, setEndDateValueError] = useState<boolean>(false);
  const [inputValueErrorText, setInputValueErrorText] = useState<string>('');
  const [startDateValueErrorText, setStartDateValueErrorText] = useState<
    string
  >('');
  const [endDateValueErrorText, setEndDateValueErrorText] = useState<string>(
    '',
  );
  const [inputValue, setInputValue] = useState<string>('');
  const [startDateValue, setStartDateValue] = useState<Date | undefined>(
    undefined,
  );
  const [endDateValue, setEndDateValue] = useState<Date | undefined>(undefined);
  const { values, setFieldValue, handleSubmit } = useFormikContext();

  const resetInitialValues = (idx?: number) => {
    setFieldValue('id', idx !== undefined ? data[idx].id : 0);
    setFieldValue(
      'rankingPeriodName',
      idx !== undefined ? data[idx].rankingPeriodName : '',
    );
    setFieldValue('startDate', idx !== undefined ? data[idx].startDate : null);
    setFieldValue('endDate', idx !== undefined ? data[idx].endDate : null);
    setFieldValue(
      'visibility',
      idx !== undefined ? data[idx].visibility : true,
    );
    setFieldValue('disabled', idx !== undefined ? data[idx].disabled : false);
  };

  const textInputValue = (value: any, id: number) => {
    setInputValue(value.target.value);
    setFieldValue('rankingPeriodName', value.target.value);
    if (value.target.value !== '') {
      setInputValueError(false);
      setInputValueErrorText('');
    } else {
      setInputValueError(true);
      setInputValueErrorText(words.invalidText);
    }
  };

  const renderTextInput = (value: string, id: number) => {
    return (
      <TextInputWrapper>
        <TextInput
          id="add-field"
          value={value}
          onChange={e => textInputValue(e, id)}
          invalid={inputValueError}
          invalidText={inputValueErrorText}
        />
      </TextInputWrapper>
    );
  };

  const hadleOnStartDateChange = (date: Date) => {
    setStartDateValue(date);
    setFieldValue('startDate', date);

    if (date) {
      setStartDateValueError(false);
      setStartDateValueErrorText('');
    } else {
      setStartDateValueError(true);
      setStartDateValueErrorText(words.invalidDate);
    }
  };

  const hadleOnEndDateChange = (date: Date) => {
    setEndDateValue(date);
    setFieldValue('endDate', date);

    if (date) {
      setEndDateValueError(false);
      setEndDateValueErrorText('');
    } else {
      setEndDateValueError(true);
      setEndDateValueErrorText(words.invalidDate);
    }
  };

  const handleFormatDateToString = (date: Date) => {
    return date ? moment(date).format('YYYY/MM/DD') : '';
  };

  const renderStartDate = (index: number, startDate: Date) => {
    const formValues = values as IAddPeriodProps;
    return (
      <StyledDatePicker
        id={`${index}_startDate`}
        value={startDate}
        datePickerType={'single'}
        onChange={date => hadleOnStartDateChange(date)}
        invalid={startDateValueError}
        invalidText={startDateValueErrorText}
        placeholder={startDateValueError ? '' : 'yyyy/mm/dd'}
        maxDate={handleFormatDateToString(formValues.endDate)}
      />
    );
  };

  const renderEndDate = (index: number, endDate: Date) => {
    const formValues = values as IAddPeriodProps;

    return (
      <StyledDatePicker
        id={`${index}_endDate`}
        value={endDate}
        datePickerType={'single'}
        onChange={date => hadleOnEndDateChange(date)}
        invalid={endDateValueError}
        invalidText={endDateValueErrorText}
        placeholder={endDateValueError ? '' : 'yyyy/mm/dd'}
        minDate={handleFormatDateToString(formValues.startDate)}
      />
    );
  };

  const onToggleSwitch = (value: boolean, id: number, index: number) => {
    const newSwitchValue = [...switchValue];
    newSwitchValue[index] = { id, value };
    setSwitchValue(newSwitchValue);
    setFieldValue('visibility', value);

    if (onToggledSwitch && !isAddingField) {
      onToggledSwitch();
    }
  };

  const renderVisibilityCell = (
    index: number,
    defaultValue: boolean,
    isDisabled: boolean,
    id: number,
  ) => {
    return (
      <CellWrapper>
        <ToggleSwitch
          id={`${index}_visibility`}
          labelToggledOff={words.hidden}
          labelToggledOn={words.shown}
          onToggle={value => onToggleSwitch(value, id, index)}
          defaultValue={defaultValue}
          disabled={isDisabled}
          toggled={
            switchValue[index]?.id === id
              ? switchValue[index]?.value
              : defaultValue
          }
        />
      </CellWrapper>
    );
  };

  const renderActionCell = (
    onEdit: (idx: number) => void,
    onDelete: (idx: number) => void,
    idx: number,
  ) => {
    return (
      <>
        <StyledDivActions>
          <StyledAnchor>
            <AnchorTag
              onPress={() => onEdit(data[idx].id)}
              title={words.edit}
              type="edit"
            />
          </StyledAnchor>

          <StyledTooltip
            message={
              data.length <= 1
                ? words.deleteLastPeriod
                : words.deleteRankingPeriod
            }
            direction={'bottom'}
            tooltipClassName="tooltip"
            isOpen={isDeletingField && data[idx].id === idToDelete}
            preserveText={data.length <= 1}
            align={'end'}
            onChange={(ev, { open }) => {
              if (!open && data.length <= 1) {
                setIsDeletingField(false);
                setIdToDelete(null);
              }
            }}
            triggerElement={
              <AnchorTag
                onPress={() => {
                  setIsDeletingField(true);
                  setIdToDelete(data[idx].id);
                }}
                title={words.delete}
                type={data.length <= 1 ? 'disabled' : 'delete'}
              />
            }>
            <>
              {data.length > 1 && (
                <div className="bx--tooltip__footer">
                  <StyledAnchorTag
                    onPress={() => {
                      setIsDeletingField(false);
                      setIdToDelete(null);
                    }}
                    title={words.cancel}
                  />
                  <StyledButton
                    onPress={() => onDelete(idx)}
                    style={{
                      fontSize: languageUsed === 'en' ? '16px' : '13px',
                      width: languageUsed === 'en' ? 'auto' : '117px',
                      height: languageUsed === 'en' ? 'auto' : '32px',
                    }}
                    title={words.deleteTooltipRankingPeriod}
                  />
                </div>
              )}
            </>
          </StyledTooltip>
        </StyledDivActions>
      </>
    );
  };

  const renderSaveCancelCell = (onSave: () => void, onCancel: () => void) => {
    return (
      <>
        <StyledDivActions>
          <SaveCancelAnchors
            className="saveBtn"
            onPress={onSave}
            title={words.savePeriod}
            type="info"
          />
          <SaveCancelAnchors
            className="cancelBtn"
            onPress={onCancel}
            title={words.cancel}
            type="info"
          />
        </StyledDivActions>
      </>
    );
  };

  const clearErrorLabels = () => {
    setInputValueError(false);
    setStartDateValueError(false);
    setEndDateValueError(false);
  };

  const handleOnSaveAddRanking = (idx?: number, item?: IAddPeriodProps) => {
    if (inputValue === '' && !startDateValue && !endDateValue && !item) {
      setInputValueError(true);
      setStartDateValueError(true);
      setEndDateValueError(true);
      setInputValueErrorText(words.invalidText);
      setStartDateValueErrorText(words.invalidDate);
      setEndDateValueErrorText(words.invalidDate);
      return false;
    }

    if (!endDateValue && !startDateValue && !item) {
      setStartDateValueError(true);
      setStartDateValueErrorText(words.invalidText);
      setEndDateValueError(true);
      setEndDateValueErrorText(words.invalidDate);
      return false;
    }

    if (inputValue === '' && !endDateValue && !item) {
      setInputValueError(true);
      setInputValueErrorText(words.invalidText);
      setEndDateValueError(true);
      setEndDateValueErrorText(words.invalidDate);
      return false;
    }

    if (inputValue === '' && !startDateValue && !item) {
      setInputValueError(true);
      setInputValueErrorText(words.invalidText);
      setStartDateValueError(true);
      setStartDateValueErrorText(words.invalidDate);
      return false;
    }

    if (inputValueError || (inputValue === '' && !item)) {
      setInputValueError(true);
      setInputValueErrorText(words.invalidText);
      return false;
    }

    if (
      (!item && !startDateValue) ||
      startDateValueError ||
      (item && !item.startDate)
    ) {
      setStartDateValueError(true);
      setStartDateValueErrorText(words.invalidDate);
      return false;
    }

    if (
      (!item && !endDateValue) ||
      endDateValueError ||
      (item && !item.endDate)
    ) {
      setEndDateValueError(true);
      setEndDateValueErrorText(words.invalidDate);
      return false;
    }

    if (idx === idxToEdit) {
      setFieldValue('id', idx);
      setIdxToEdit(null);
      setIndexToEdit(null);
      setIndexToHighlight(null);
      setIdToDelete(null);
    } else {
      setFieldValue('id', idx);
      setIsAddingField(false);
    }
    clearErrorLabels();
    handleSubmit();
  };

  const handleOnDeleteRanking = (idx: number) => {
    // TODO: Handle Delete function here
    setIsDeletingField(false);
    onDeleteRanking(idToDelete);
    resetInitialValues();
  };

  const handleOnCancel = (idx?: number) => {
    clearErrorLabels();
    if (idx === idxToEdit) {
      resetInitialValues();
      setIdxToEdit(null);
      setIndexToEdit(null);
      setIndexToHighlight(null);
    } else {
      setIsAddingField(false);
    }
  };

  const onEditItem = (idx: number, index: number) => {
    clearErrorLabels();
    setIndexToEdit(index);
    setIndexToHighlight(index + 1);
    setIdxToEdit(idx);
    setIsAddingField(false);
    resetInitialValues(index);
  };

  const renderRowData = () => {
    const rows: {
      id: string;
      rankingPeriodName: string | React.ReactElement;
      startDate: string | React.ReactElement;
      endDate: string | React.ReactElement;
      visibility: React.ReactElement;
      action: string | React.ReactElement;
    }[] = [];

    const formValues = values as IAddPeriodProps;

    data.forEach((item, index) => {
      item.visibility =
        switchValue.length && switchValue[index]?.id === item.id
          ? switchValue[index].value
          : item.visibility;

      const settingItem = {
        id: `${index + 1}`,
        rankingPeriodName:
          indexToEDit === index
            ? renderTextInput(formValues.rankingPeriodName, item.id)
            : item.rankingPeriodName,
        startDate:
          indexToEDit === index ? (
            renderStartDate(index, item.startDate)
          ) : (
            <Moment format="YYYY/MM/DD" date={item.startDate} />
          ),
        endDate:
          indexToEDit === index ? (
            renderEndDate(index, item.endDate)
          ) : (
            <Moment format="YYYY/MM/DD" date={item.endDate} />
          ),
        visibility: renderVisibilityCell(
          index,
          item.visibility,
          item.disabled,
          item.id,
        ),
        action:
          indexToEDit === index
            ? renderSaveCancelCell(
                () => handleOnSaveAddRanking(item.id, item),
                () => handleOnCancel(item.id),
              )
            : renderActionCell(
                idx => {
                  onEditItem(idx, index);
                },
                idx => {
                  handleOnDeleteRanking(idx);
                },
                index,
              ),
      };
      rows.push(settingItem);
    });

    if (isAddingField) {
      const nextIdx = data.length > 0 ? data.length + 1 : 1;
      const addFieldForm = {
        id: `${nextIdx}`,
        rankingPeriodName: renderTextInput(
          formValues.rankingPeriodName,
          nextIdx,
        ),
        startDate: renderStartDate(nextIdx, formValues.startDate),
        endDate: renderEndDate(nextIdx, formValues.endDate),
        visibility: renderVisibilityCell(nextIdx - 1, true, false, nextIdx),
        action: renderSaveCancelCell(
          () => handleOnSaveAddRanking(nextIdx),
          handleOnCancel,
        ),
      };
      rows.push(addFieldForm);
    }
    return rows;
  };

  const onAddItem = () => {
    resetInitialValues();
    handleOnCancel(idxToEdit as number);
    setIdxToEdit(null);
    setIsAddingField(!isAddingField);
    setInputValue('');
    setStartDateValue(undefined);
    setEndDateValue(undefined);
  };

  useEffect(() => {
    if (isEmpty) {
      resetInitialValues();
      setIdxToEdit(null);
      setIsAddingField(!isAddingField);
    }
  }, [isEmpty]);
  return (
    <>
      <GlobalStyle />
      {isLoading ? (
        <StyledEditRankingDiv>
          <CustomDataTableSkeletonContainer>
            <CustomDataTableSkeleton
              showToolbar={false}
              showHeader={false}
              headers={ADD_PERIOD_HEADERS}
              columnCount={ADD_PERIOD_HEADERS.length}
              className="AddPeriodTable"
              rowCount={0}
            />
            <TableLoader />
          </CustomDataTableSkeletonContainer>
        </StyledEditRankingDiv>
      ) : (
        <TableStyle
          className="AddPeriodTable"
          rows={renderRowData()}
          headers={ADD_PERIOD_HEADERS}
          isAddingField={isAddingField}
          indexToHighlight={indexToHighlight}
        />
      )}
      {!isAddingField ? (
        <AddFieldWrapper className={'addPeriodBtn'}>
          <StyledAddButton
            onPress={onAddItem}
            title={words.addRankingPeriod}
            icon={Plus}
          />
        </AddFieldWrapper>
      ) : null}
    </>
  );
};

export default Component;
