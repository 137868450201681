import React, { useEffect, useState } from 'react';
import './memberForgotInputPass.scss';
import LogoSelfPro from 'assets/icons/self-pro-logo.svg';
import { OrangeButton } from 'components/atoms/OrangeButton';
import words from 'constants/words';
import { memberForgotInputType, memberForgotInputErrorType } from 'type/member';
import { validateForgotInput } from 'components/templates/ForgotInputPassForm/validates';
import { ForgotInputPassForm } from 'components/templates/ForgotInputPassForm';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Loading } from 'components/molecules/Loading';
import CheckCircle from 'assets/icons/check-circle.svg';
import ErrorCircle from 'assets/icons/error-circle.svg';
import urls from 'constants/urls';
import axios from 'axios';
import { extractError } from 'utils/common';
import { SignedUpSuccessModal } from 'components/templates/SignedUpSuccessModal';
import InputForgotImg from 'assets/images/input-forgot.jpeg';

const defaultValue = {
  password: '',
  passwordConfirm: '',
};

export default function MemberForgotInputPass() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState('none');
  const [errorResponse, setErrorResponse] = useState('');
  const [resetPassSuccess, setResetPassSuccess] = useState(true);
  const [showModal, setShowModal] = useState('none');
  const navigate = useNavigate();
  const [errors, setErrors] = useState<memberForgotInputErrorType>(
    defaultValue,
  );

  const [forgotInputValue, setForgotInputValue] = useState<
    memberForgotInputType
  >(defaultValue);

  const uid = searchParams.get('uid');
  const token = searchParams.get('token');

  useEffect(() => {
    if (uid === null || token === null) {
      navigate(urls.member_sign_in);
    }
  }, []);

  async function onSubmitForgot() {
    const listError = await validateForgotInput(forgotInputValue);
    if (Object.entries(listError).length !== 0) {
      setErrors(listError);
      return;
    }
    // Handle Forgot
    setErrors(defaultValue);
    setIsLoading('block');
    await axios
      .post(urls.handle_member_forgot, {
        ...forgotInputValue,
        uid,
        token,
      })
      .then(() => {
        setIsLoading('none');
        setErrors(defaultValue);
        setResetPassSuccess(true);
        setShowModal('block');
      })
      .catch(err => {
        if (err.response.data.errors) {
          const errorSv = extractError(err.response.data.errors);
          setErrorResponse(errorSv);
        }
        setResetPassSuccess(false);
        setIsLoading('none');
        setShowModal('block');
      });
  }

  function handleUpdateValues(data: memberForgotInputType) {
    setForgotInputValue(data);
  }

  return (
    <div className="forgot-pages">
      <Loading display={isLoading} />
      <div className="container-fluid">
        <div className="row flex-row-reverse">
          <div className="col-12 col-sm-6 div-left">
            <SignedUpSuccessModal
              display={showModal}
              onClick={
                resetPassSuccess
                  ? () => navigate(urls.sign_in_page)
                  : () => navigate(urls.forgot_page)
              }
              icon={resetPassSuccess ? CheckCircle : ErrorCircle}
              message={
                resetPassSuccess
                  ? words.resetPasswordSuccessful
                  : words.resetPasswordFailed
              }
              textButton="OK"
            />
            <div className="self-pro-logo">
              <img src={LogoSelfPro} />
            </div>
            <div className="forgot-title">
              <p>{words.memberForgotTitle}</p>
            </div>
            <ForgotInputPassForm
              handleUpdateValues={data => handleUpdateValues(data)}
              listError={errors}
            />
            <p
              className="errorFromServer"
              dangerouslySetInnerHTML={{ __html: errorResponse }}></p>
            <div className="orangeButton">
              <OrangeButton text={words.send} onClick={onSubmitForgot} />
            </div>
            <div className="go-to-login">
              {words.alreadyHaveAccount}
              <a href="/sign-in">{words.alreadyLogin}</a>
            </div>
          </div>
          <div className="col-12 col-sm-6 image-right">
            <img src={InputForgotImg} alt="Login Img" className="img-fluid" />
          </div>
        </div>
      </div>
    </div>
  );
}
