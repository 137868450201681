import React from 'react';

type Props = {
  [key: string]: string | number | Function;
};

const Copy = (props: Props): React.ReactElement => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 1.25C5.16751 1.25 1.25 5.16751 1.25 10C1.25 14.8325 5.16751 18.75 10 18.75C14.8325 18.75 18.75 14.8325 18.75 10C18.75 7.67936 17.8281 5.45376 16.1872 3.81282C14.5462 2.17187 12.3206 1.25 10 1.25ZM10 4.375C10.5178 4.375 10.9375 4.79473 10.9375 5.3125C10.9375 5.83027 10.5178 6.25 10 6.25C9.48223 6.25 9.0625 5.83027 9.0625 5.3125C9.0625 4.79473 9.48223 4.375 10 4.375V4.375ZM12.5 15.075H7.5V13.675H9.3V9.45H8.125V8.05H10.7062V13.675H12.5V15.075Z"
        fill="#0043CE"
      />
    </svg>
  );
};

export default Copy;
