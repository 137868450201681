import { object, string, ValidationError } from 'yup';
import words from 'constants/words';
import { memberForgotType } from 'type/member';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function getErrors(items: ValidationError[]) {
  if (!items) {
    return {};
  }

  return items.reduce((error: object, e: ValidationError) => {
    return {
      ...error,
      [`${e.path}`]: e.message,
    };
  }, {});
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export async function validateForgot(data: memberForgotType) {
  const schema = object().shape({
    email: string()
      .required(words.memberSignupEmailRequired)
      .max(254, words.passwordMaxRequired)
      .email(words.memberSignupEmailInvalid),
  });

  const result = await schema
    .validate(data, { abortEarly: false })
    .catch(err => err);
  return getErrors(result.inner) as memberForgotType;
}
