import React, { useState } from 'react';
import styled from 'styled-components';
import { theme } from '../../../config';
import { Tabs } from '../../organisms/Tabs';
import { ActiveRanking } from '../../organisms/ActiveRanking';
import words from '../../../constants/words';
import { DisabledRanking } from '../../organisms/DisabledRanking';

const Container = styled.div`
  background: ${theme.colors.ui02};
  box-shadow: 0px 0px 8px rgba(236, 215, 192, 0.8);
  padding: 2px;
`;

const ContentWrapper = styled.div`
  background: ${theme.colors.ui01};
  .bx--tab-content {
    padding: 0;
  }
  .bx--tabs--scrollable {
    background-color: ${theme.colors.white};
  }
  && .bx--tabs--scrollable__nav > li.bx--tabs--scrollable__nav-item {
    width: 187.5px;
  }
  && .bx--tabs--scrollable__nav > li.bx--tabs--scrollable__nav-item > button {
    width: 187.5px;
  }
`;

const StyledTabs = styled(Tabs)`
  &&&&& {
    .bx--tabs--scrollable__nav-link:focus {
      outline: none;
      border-top: 2px solid ${theme.colors.primaryButton1};
    }
  }
  .bx--tabs--scrollable__nav-item--selected {
    .bx--tabs--scrollable__nav-link {
      &:hover {
        background-color: ${theme.colors.ui01};
      }
    }
  }
`;

export type Props = {};

const Component = ({}: Props): React.ReactElement => {
  const [tabIndex, setTabIndex] = useState<number>(0);
  return (
    <Container>
      <ContentWrapper>
        <StyledTabs
          onSelectionChange={(index: number) => setTabIndex(index)}
          tabs={[
            {
              id: 'active-ranking',
              label: words.activeRanking,
              element: <ActiveRanking tabIndex={tabIndex} />,
            },
            {
              id: 'disabled-ranking',
              label: words.disabledRanking,
              element: <DisabledRanking tabIndex={tabIndex} />,
            },
          ]}
        />
      </ContentWrapper>
    </Container>
  );
};

export default Component;
