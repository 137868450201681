import React from 'react';

type Props = {
  [key: string]: string | number | Function;
};

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.00047 1.6875L5.29422 4.85687L1.48047 5.36219L4.24047 7.83031L3.58797 11.3125L7.00047 9.66937L10.413 11.3125L9.76047 7.83031L12.5205 5.36563L8.70672 4.85687L7.00047 1.6875Z"
        fill="#D64D30"
        stroke="#D64D30"
      />
    </svg>
  );
};

export default Component;
