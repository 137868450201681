import { Formik } from 'formik';
import React from 'react';
import styled from 'styled-components';
import { LoginFormSetting } from '../../templates/LoginFormSetting';

const ChildrenWrapper = styled.div`
  padding: 40px 70px 40px 70px;
`;

type Props = {};

const initialValues = {
  name: '',
  required: true,
  visible: true,
  disabled: false,
  isDefault: false,
};

const Component = ({}: Props): React.ReactElement => {
  return (
    <div>
      <ChildrenWrapper>
        <React.Suspense fallback={<div />}>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={() => {}}>
            <LoginFormSetting />
          </Formik>
        </React.Suspense>
      </ChildrenWrapper>
    </div>
  );
};

export default Component;
