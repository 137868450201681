import React, { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';

import { ImageDisplay } from '../../atoms/ImageDisplay';
import words from '../../../constants/words';
import { useMemberHooks } from '../../../hooks/member';
import { useMutation } from 'react-query';
import { UpdateMemberType } from '../../../domain/entities/member';
import { useGlobalState } from '../../../hooks/global';
import { Props as INotification } from '../../molecules/Notification/Notification';

export type UserImageProps = {
  setNewlyUpdatedMember?: (member: UpdateMemberType | undefined) => void;
  imageSrc?: string;
  memberId: number | undefined;
  isLoading: boolean;
  toastNotification: (toast: INotification) => void;
  viewOnly?: boolean;
  setImage?: (src: string | undefined) => void;
};

const Container = styled.div`
  position: relative;
`;
const ChangeButton = styled.div`
  position: absolute;
  bottom: 3px;
  left: 1px;
  display: flex;
  width: 66px;
  height: 33px;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;

  justify-content: center;
  align-items: flex-end;

  background: rgba(0, 0, 0, 0.4);

  &:hover {
    cursor: pointer;
  }
`;
const ChangeText = styled.p`
  color: white;
  font-size: 12px;
  padding-bottom: 9px;
  line-height: 16px;
  letter-spacing: 0.32px;
  font-style: normal;
  font-weight: normal;
`;

const Component = ({
  setNewlyUpdatedMember = () => {},
  imageSrc,
  memberId,
  isLoading,
  toastNotification,
  viewOnly = false,
  setImage = () => {},
}: UserImageProps): React.ReactElement => {
  const { useUpdateMember } = useMemberHooks();
  const { updateMember } = useUpdateMember();
  const {
    useCurrentUser: { currentUser },
  } = useGlobalState();
  const companyId = currentUser?.companyId;
  const [isLoadingImageMutation, setIsLoadingImageMutation] = useState<boolean>(
    false,
  );

  const { mutate: mutateUpdateMember } = useMutation(
    async (params: { memberId: number; payload: UpdateMemberType }) => {
      setIsLoadingImageMutation(true);
      if (!companyId) {
        throw new Error(`'company id' not set`);
      }
      const response = await updateMember(
        companyId,
        params.memberId,
        params.payload,
      );
      const newPhotoUrl = response.photoUrl;
      setNewlyUpdatedMember({
        photoUrl: newPhotoUrl,
      });
      setImage(newPhotoUrl);
      return response;
    },
    {
      onSuccess: () => {
        console.log(`Success!`);
        setIsLoadingImageMutation(false);
      },
      onError: () => {
        toastNotification({
          kind: 'error',
          title: words.error,
          subtitle: words.errorOccuredToastSub,
        });
        setIsLoadingImageMutation(false);
      },
    },
  );

  const handleSaveMember = useCallback(
    (image: File) => {
      if (!memberId) {
        throw new Error(`'member id' not set`);
      } else {
        mutateUpdateMember(
          {
            memberId: memberId,
            payload: { photo: image },
          },
          {
            onSuccess: () => {
              console.log(`Success!`);
            },
            onError: () => {
              console.log(`Error: Something went wrong`);
            },
          },
        );
      }
    },
    [memberId],
  );

  const imageSizeKilobyteLimit = 500;
  const fileRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const handleChange = (event: React.ChangeEvent) => {
    const target = event.target as HTMLInputElement;
    if (target.value.length == 0) {
      return;
    }
    if (target.files) {
      const file = target.files[0];
      if (file.size > imageSizeKilobyteLimit * 1024) {
        toastNotification({
          kind: 'error',
          title: words.error,
          subtitle: words.error500kbMax,
          subtitleWidth: '178px',
        });
        target.value = '';
        return;
      }
      handleSaveMember(file);
    }
  };

  return (
    <Container>
      {isLoading || isLoadingImageMutation || viewOnly ? null : (
        <>
          <ChangeButton onClick={() => fileRef.current.click()}>
            <ChangeText>{words.change}</ChangeText>
          </ChangeButton>
          <input
            ref={fileRef}
            onChange={handleChange}
            multiple={false}
            type="file"
            accept="image/*"
            hidden
          />
        </>
      )}

      <ImageDisplay
        imageSrc={imageSrc}
        isLoading={isLoading || isLoadingImageMutation}
      />
    </Container>
  );
};

export default Component;
