import React from 'react';
import styled from 'styled-components';
import { theme } from '../../../config';
import words from '../../../constants/words';
import { SubHeader } from '../../atoms/Subheader';
import { RankingTable } from '../../templates/RankingTable';

const ChildrenWrapper = styled.div`
  padding: 40px 70px 40px 70px;

  @media ${theme.breakpoints.mobile} {
    padding: 0;
    width: 100vw;
    height: 100%;
  }
`;

type Props = {};

const Component = ({}: Props): React.ReactElement => {
  return (
    <>
      <SubHeader title={words.rankingTable} />
      <ChildrenWrapper>
        <RankingTable />
      </ChildrenWrapper>
    </>
  );
};

export default Component;
