import React, { useState } from 'react';
import { CurrentUser, GlobalState, GlobalStateContext } from '.';

type Props = {
  value?: GlobalState;
  children?: React.ReactElement;
};

const USER_STORAGE_KEY = 'user';
const INITIAL_USER = undefined;

const Provider = (props: Props): React.ReactElement => {
  const [currentUser, setCurrentUser] = useState<CurrentUser | undefined>(
    () => {
      try {
        const item = window.localStorage.getItem(USER_STORAGE_KEY);
        return item ? JSON.parse(item) : INITIAL_USER;
      } catch (error) {
        console.error(error);
        return INITIAL_USER;
      }
    },
  );
  const useCurrentUser = React.useMemo(
    () => ({
      currentUser,
      setCurrentUser: (user?: CurrentUser): void => {
        setCurrentUser(user);
        window.localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(user));
      },
    }),
    [currentUser],
  );
  return (
    <GlobalStateContext.Provider
      value={{
        useCurrentUser,
      }}
      {...props}
    />
  );
};

export default Provider;
