import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';

import { SubHeader } from '../../atoms/Subheader';
import { MemberList } from '../../templates/MemberList';
import { BulkDeleteMembersModal } from '../../templates/BulkDeleteMembersModal';
import { SelectedMemberType } from '../../templates/MemberList/types';

import { SetMemberModal } from '../../templates/SetMemberModal';
import { Props as INotification } from '../../molecules/Notification/Notification';

import { DeleteMemberModal } from '../../templates/DeleteMemberModal';
import { MoveMemberModal } from '../../templates/MoveMemberGroupModal';

import { useGlobalState } from '../../../hooks/global';
import { useGroupHooks } from '../../../hooks/group';
import { Member } from '../../../domain/entities/member';
import words from '../../../constants/words';

import { MemberTabs } from '../../organisms/Tabs';
import { LoginSettingPage } from '../LoginSetting';
import { ToastNotification } from '../../molecules/Notification';
import { TIMEOUT } from '../../../constants/timeout';
import { isEqualIgnoreOrderUsingIds } from '../../../utils/arrays';

const ChildrenWrapper = styled.div`
  padding: 40px 70px 40px 70px;

  .bx--modal {
    z-index: 10000;
  }
`;

const ToastNotificationWrapper = styled.div`
  & > div {
    z-index: 100;
  }
`;

const StyledToast = styled(ToastNotification)`
  &&&& {
    line-break: strict;
  }
`;

type Props = {};
type LocationStateProps = {
  deleteData: { deletedMember: Member; toast: INotification } | undefined;
};

const Component = ({}: Props): React.ReactElement => {
  const [bulkDeleteModalOpen, setBulkDeleteModalOpen] = useState(false);
  const [membersToDelete, setMembersToDelete] = useState<
    Array<{ id: string; name: string }>
  >([]);
  const navigate = useNavigate();
  const location = useLocation();
  const membersPageState: LocationStateProps | undefined = location.state;

  const {
    useCurrentUser: { currentUser },
  } = useGlobalState();

  const { useFetchGroups } = useGroupHooks();

  const { fetchGroups } = useFetchGroups();

  const [isMemberModalOpen, setIsMemberModalOpen] = useState(false);
  const [member, setMember] = useState<Member | undefined>(undefined);
  const [notification, setNotification] = useState<INotification | null>(null);
  const [newlyDeletedMember, setNewlyDeletedMember] = useState<
    Member | undefined
  >();
  const [selectedMembers, setSelectedMembers] = useState(
    [] as SelectedMemberType[],
  );
  const [isMoveMemberModalOpen, setIsMoveMemberModalOpen] = useState(false);
  const [moveMembersFlag, setMoveMembersFlag] = useState(0);
  const [batchDeleteMembersFlag, setBatchDeleteMembersFlag] = useState(false);

  useEffect(() => {
    if (membersPageState?.deleteData) {
      const { deleteData } = membersPageState;
      onSubmitDeleteModal(deleteData.deletedMember, deleteData.toast);
    }
  });

  const openMemberModal = useCallback(() => {
    setIsMemberModalOpen(true);
  }, []);

  const closeMemberModal = useCallback(() => {
    setIsMemberModalOpen(false);
  }, []);

  const onClickDelete = useCallback((member: Member) => {
    setMember(member);
  }, []);

  const closeDeleteMemberModal = useCallback(() => {
    setMember(undefined);
  }, []);

  const onSubmitMemberModal = useCallback(
    (
      newMember?: Member & { temporary_password: string },
      toast?: INotification,
    ) => {
      if (newMember) {
        navigate(`/members/${newMember.id}`, {
          state: {
            temporary_password: newMember.temporary_password,
            toastMessage: toast,
          },
        });
      } else if (toast)
        setNotification({
          ...toast,
          timeout: TIMEOUT,
          handleClose: () => {
            setNotification(null);
            return false;
          },
        });
    },
    [notification],
  );

  const onSubmitDeleteModal = useCallback(
    (newDeletedMember: Member | undefined, toast: INotification) => {
      setNotification({
        ...toast,
        timeout: TIMEOUT,
        handleClose: () => {
          setNotification(null);
          return false;
        },
      });
      setNewlyDeletedMember(newDeletedMember);
      if (membersPageState?.deleteData) {
        membersPageState.deleteData = undefined;
      }
    },
    [notification],
  );

  const onSubmitMoveModal = useCallback(
    (isSuccessful: boolean, toast: INotification) => {
      setNotification({
        ...toast,
        timeout: TIMEOUT,
        handleClose: () => {
          setNotification(null);
          return false;
        },
      });
      if (isSuccessful) setMoveMembersFlag(moveMembersFlag + 1);
      window.scrollTo(0, 0);
    },
    [notification, moveMembersFlag],
  );

  const onSubmitBatchDeleteModal = useCallback(
    (isSuccessful: boolean, toast: INotification) => {
      setNotification({
        ...toast,
        timeout: TIMEOUT,
        handleClose: () => {
          setNotification(null);
          return false;
        },
      });
      isSuccessful && setBatchDeleteMembersFlag(!batchDeleteMembersFlag);
    },
    [notification],
  );

  const {
    data: { results: groupList } = {
      results: [],
      count: 0,
    },
    isLoading: isFetchingGroups,
  } = useQuery(
    [currentUser],
    () => {
      if (!currentUser?.companyId) {
        throw new Error(`'company id' not set`);
      }
      const query = {
        companyId: currentUser.companyId,
      };
      return fetchGroups(query);
    },
    {
      refetchOnMount: true,
    },
  );

  const onBulkDeletePress = (members: Array<any>) => {
    const dataToDelete: Array<{ id: string; name: string }> = [];
    members.forEach(item => {
      dataToDelete.push({ id: item.id, name: item.cells[1].value });
    });
    setMembersToDelete(dataToDelete);
    setBulkDeleteModalOpen(true);
  };

  const openMoveMemberModal = () => {
    setIsMoveMemberModalOpen(true);
  };

  const closeMoveMemberModal = () => {
    setIsMoveMemberModalOpen(false);
  };

  const checkSelectedMembers = (newlySelected: SelectedMemberType[]) => {
    const currentlySelected = selectedMembers;

    if (!isEqualIgnoreOrderUsingIds(newlySelected, currentlySelected)) {
      setSelectedMembers(newlySelected);
    }
  };

  return (
    <div>
      <SubHeader title={words.members} />
      <MemberTabs
        tabs={[
          {
            id: 'listing-tab',
            label: words.listing,
            element: (
              <ChildrenWrapper>
                <React.Suspense fallback={<div />}>
                  <MemberList
                    currentEditorRole={currentUser?.roleName || ''}
                    onSelectRows={checkSelectedMembers}
                    onPressAddMember={openMemberModal}
                    onClickRow={(id: string) => {
                      window.scrollTo(0, 0);
                      navigate(`/members/${id}`);
                    }}
                    onClickEdit={(id: number) => {
                      window.scrollTo(0, 0);
                      navigate(`/members/${id}/edit`);
                    }}
                    onClickDelete={onClickDelete}
                    groupList={groupList}
                    newlyDeletedMember={newlyDeletedMember}
                    isFetchingGroups={isFetchingGroups}
                    onDeleteMembers={members => onBulkDeletePress(members)}
                    onMoveMembers={openMoveMemberModal}
                    batchDeleteMembersFlag={batchDeleteMembersFlag}
                    moveMembersFlag={moveMembersFlag}
                  />
                  <SetMemberModal
                    isOpen={isMemberModalOpen}
                    companyId={Number(currentUser?.companyId)}
                    currentEditorRole={currentUser?.roleName || ''}
                    onClose={closeMemberModal}
                    onSubmit={onSubmitMemberModal}
                    groupList={groupList}
                  />
                  <DeleteMemberModal
                    isOpen={!!member}
                    onClose={closeDeleteMemberModal}
                    onSubmit={onSubmitDeleteModal}
                    memberToDelete={member}
                  />
                  <MoveMemberModal
                    isOpen={isMoveMemberModalOpen}
                    membersToBeMoved={selectedMembers}
                    companyId={Number(currentUser?.companyId)}
                    onClose={closeMoveMemberModal}
                    onSubmit={onSubmitMoveModal}
                    groupList={groupList}
                  />
                </React.Suspense>
              </ChildrenWrapper>
            ),
            disabled: false,
          },
          {
            id: 'setting-tab',
            label: words.setting,
            element: <LoginSettingPage />,
            disabled: false,
          },
        ]}
      />
      <BulkDeleteMembersModal
        open={bulkDeleteModalOpen}
        memberList={membersToDelete}
        onCancel={() => setBulkDeleteModalOpen(false)}
        onClose={() => setBulkDeleteModalOpen(false)}
        onSubmit={onSubmitBatchDeleteModal}
      />
      {notification ? (
        <ToastNotificationWrapper>
          <StyledToast notification={notification} />
        </ToastNotificationWrapper>
      ) : null}
    </div>
  );
};

export default Component;
