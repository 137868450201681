import React from 'react';
import styled from 'styled-components';
import { Loading } from './';
import themes from '../../../config/themes';

export type Props = {
  isActive?: boolean;
  withOverlay?: boolean;
  isSmall?: boolean;
  description?: string;
  width?: number;
  height?: number;
  tableRowHeight?: number;
  vh?: number;
};

const TableLoaderDiv = styled.div<{ height: number; vh: number }>`
  width: 100%;
  height: ${props => `${props.height}px`};
  background: ${themes.main.colors.white};
  display: flex;
  justify-content: center;
  @media ${themes.main.breakpoints.mobile} {
    height: ${props => `${props.vh}vh`};
  }
`;

const LoaderContainer = styled.div`
  margin: auto;
`;

const Component = ({
  isActive = true,
  withOverlay = false,
  isSmall = false,
  description = 'loading indicator',
  width = 36,
  height = 36,
  tableRowHeight = 531,
  vh = 70,
}: Props): React.ReactElement => {
  return (
    <TableLoaderDiv height={tableRowHeight} vh={vh}>
      <LoaderContainer>
        <Loading
          width={width}
          height={height}
          isActive={isActive}
          withOverlay={withOverlay}
          isSmall={isSmall}
          description={description}
        />
      </LoaderContainer>
    </TableLoaderDiv>
  );
};

export default Component;
