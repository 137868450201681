import { createContext, useContext } from 'react';
import { NavigateFunction } from 'react-router';

export type AuthHooks = {
  useLogin: () => {
    login: (
      userId: string,
      companyId: string,
      temporaryPassword: string,
      newPassword?: string,
      isUpdateTemporaryPassword?: boolean,
    ) => Promise<object | void>;
    verifyPassword: (
      userId: string,
      companyId: string,
      password: string,
    ) => Promise<void>;
  };
  useLogout: () => {
    logout: (navigate: NavigateFunction) => Promise<void>;
  };
  useLocalStorage: () => {
    localStorage: (key: string, initialValue: string) => void;
  };
  useSignUp: () => {
    signUp: (
      username: string,
      email: string,
      password: string,
      password2: string,
    ) => Promise<object | null | void>;
    adminSignUp: (
      companyName: string,
      groupName: string,
      representativeName: string,
      companyEmail: string,
      userEmailAddress: string,
      companyTelephone: string,
    ) => Promise<object | null | void>;
    adminFirstLogin: (
      user_id: string,
      password: string,
      password2: string,
      email: string,
      file?: File[],
      customFields?: { identifier: string; value: string }[],
      company_id?: string,
    ) => Promise<object | null | void>;
    verifyEmail: (
      user_id: string,
      email: string,
    ) => Promise<object | null | void>;
    verifyCompanyEmail: (email: string) => Promise<object | null | void>;
    adminVerification: (
      pk: string,
      token: string,
    ) => Promise<object | null | void>;
    adminEmailVerification: (email: string) => Promise<object | null | void>;
  };
  useResetPassword: () => {
    forgetPassword: (
      email: string,
      email_token?: string,
    ) => Promise<object | null | void>;
    resetPassword: (
      pk: string,
      token: string,
      password: string,
      password2: string,
    ) => Promise<object | null | void>;
    changePassword: (newPassword: string) => Promise<object | null | void>;
  };
};

export const AuthHooksContext = createContext<AuthHooks | null>(null);

export const useAuthHooks = (): AuthHooks => {
  const context = useContext(AuthHooksContext);
  if (!context) {
    throw new Error(`Context not instantiated`);
  }
  return context;
};
