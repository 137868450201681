import React from 'react';
import styled from 'styled-components';
import { AccordionItem } from 'carbon-components-react';

import { SubAccordionItem } from '../../atoms/SubAccordionItem';
import { NavLink } from 'react-router-dom';
import { theme } from '../../../config';

export type AccordionItemProps = {
  label: string;
  to?: string;
  activeClass?: string;
  onPress?: (e: React.MouseEvent) => void;
  subroutes?: {
    label: string;
    to: string;
    onPress?: () => void;
  }[];
  toggleItemClick?: (e: any) => void;
};

const StyledAccordionItem = styled(AccordionItem)`
  border: 0;
  .bx--accordion__heading {
    &:hover::before {
      background-color: transparent;
    }
    &:focus::before {
      border: none;
    }
  }

  .bx--accordion__title {
    font-weight: bold;
    font-size: 13px;
    letter-spacing: 0.16px;
    color: ${theme.colors.black};
  }

  &:last-child {
    border-bottom: 0px;
  }

  .bx--accordion__content {
    padding-inline: 0px;
    padding-top: 0px;
    padding-bottom: 10px;
  }
`;

const EmptyAccordion = styled.li`
  border: 0;
  min-height: 40px;
  display: flex;
  align-items: center;

  .bx--accordion__title {
    font-weight: bold;
    font-size: 13px;
    letter-spacing: 0.16px;
    color: ${theme.colors.black};
  }
`;

const StyledNav = styled(NavLink)`
  text-decoration: none;
  color: ${theme.colors.black};

  &.active {
    font-weight: bold;
    color: ${theme.colors.primaryButton1};
  }
`;

const Component = ({
  label = '',
  onPress,
  to,
  subroutes = [],
  activeClass,
  toggleItemClick,
}: AccordionItemProps): React.ReactElement => {
  const renderSubroutes = (
    subroutes: {
      label: string;
      to: string;
      onPress?: () => void;
    }[],
  ) =>
    subroutes.map(subroute => (
      <SubAccordionItem
        label={subroute.label}
        key={subroute.to}
        onClick={e => {
          const selectedEvent = e as any;
          onPress?.(selectedEvent);
          toggleItemClick?.(subroute);
          if (subroute.onPress) {
            subroute.onPress();
          }
        }}
        to={subroute.to}
      />
    ));
  return !!to ? (
    <EmptyAccordion className="bx--accordion__item" onClick={onPress}>
      <StyledNav to={to} className={'bx--accordion__title ' + activeClass}>
        {label}
      </StyledNav>
    </EmptyAccordion>
  ) : (
    <StyledAccordionItem title={label} key={label} onClick={onPress}>
      {renderSubroutes(subroutes)}
    </StyledAccordionItem>
  );
};

export default Component;
