import React, { useEffect, useState } from 'react';
import { listMemberGroupType } from 'type/member';
import { getCurrentUser, isEmptyObject } from 'utils/common';
import { MemberItem } from '../MemberItem';
import { MemberItemSelect } from '../MemberItemSelect';
import './listMember.scss';
type PropType = {
  listType: number;
  onHandleUpdateProfile: (_data: number) => void;
  onHandleApproveMember: (_data: number) => void;
  onHandleRejectMember: (_data: number) => void;
  onHandleDeleteMember: (_data: number) => void;
  listMember: listMemberGroupType | null;
  groupName?: string;
  currentGroupName?: string;
  submitChecked: (_member_id: number, _checked: boolean) => void;
  memberSelected?: number[];
  mode: number;
  current?: number;
};

const ListMember = (props: PropType) => {
  const {
    listType,
    onHandleUpdateProfile,
    listMember,
    onHandleApproveMember,
    onHandleRejectMember,
    onHandleDeleteMember,
    groupName,
    currentGroupName,
    submitChecked,
    memberSelected,
    mode,
    current,
  } = props;

  return (
    <div className="list-member-page">
      {listType && listType === 1
        ? listMember &&
          listMember.map(item => (
            <MemberItem
              key={item.id}
              userId={item.id}
              member={item}
              onHandleUpdateProfile={data => onHandleUpdateProfile(data)}
              onHandleApproveMember={data => onHandleApproveMember(data)}
              onHandleRejectMember={data => onHandleRejectMember(data)}
              onHandleDeleteMember={data => onHandleDeleteMember(data)}
            />
          ))
        : listMember &&
          listMember.map(item => (
            <MemberItemSelect
              key={item.id}
              groupName={groupName ?? ''}
              currentGroupName={currentGroupName ?? ''}
              member={item}
              submitChecked={(member_id, checked) =>
                submitChecked(member_id, checked)
              }
              memberSelected={memberSelected ? memberSelected : []}
              mode={mode}
              current={current ? current : 0}
            />
          ))}
    </div>
  );
};

export default ListMember;
