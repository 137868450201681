import EntryRepository from '../../usecases/ports/EntryRepository';
import { HttpAdapter } from '../../usecases/ports/HttpAdapter';
import {
  EntryFields,
  EntryApplicationForm,
  CreateEntryApplication,
} from '../../domain/entities/entry';
import { RankingFieldsLookup } from '../../domain/entities/ranking';
import { Member } from '../../domain/entities/member';

export default class EntryRepositoryImpl implements EntryRepository {
  httpAdapter: HttpAdapter;
  urls: {
    [key: string]: (query: { [key: string]: string | number }) => string;
  };

  constructor(
    httpAdapter: HttpAdapter,
    urls: {
      [key: string]: (query: { [key: string]: string | number }) => string;
    },
  ) {
    this.httpAdapter = httpAdapter;
    this.urls = urls;
  }

  fetchFormFieldsApprovers = async (
    companyId: number,
    memberId: number,
    rankingId: number,
  ): Promise<EntryApplicationForm | undefined> => {
    const response = await this.httpAdapter.get(
      this.urls.formFieldsApprovers({ companyId, memberId, rankingId }),
      {},
    );

    const customFields: EntryFields[] = response.data.fields_lookup
      .filter(
        (fieldLookup: RankingFieldsLookup) =>
          !fieldLookup.is_default &&
          fieldLookup.data_type != 'user-info' &&
          !fieldLookup.is_disabled,
      )
      .map((fieldLookup: RankingFieldsLookup) => ({
        field_lookup_id: fieldLookup.id,
        field_lookup_name: fieldLookup.name,
        value_char: fieldLookup.data_type === 'decimal' ? undefined : '',
        value_decimal: fieldLookup.data_type === 'decimal' ? 0 : undefined,
        is_default: fieldLookup.is_default,
        is_basis: fieldLookup.is_basis,
        is_required: fieldLookup.is_required,
        is_disabled: fieldLookup.is_disabled,
        order_id: fieldLookup.order_id,
      }));

    return {
      rankingName: response.data.ranking_name,
      approvers: response.data.approvers,
      approversAll: response.data.approvers_all,
      customFields,
    };
  };

  createEntryApplication = async (
    companyId: number,
    memberId: number,
    rankingId: number,
    payload: CreateEntryApplication,
  ): Promise<Response> => {
    const response = await this.httpAdapter.post(
      this.urls.entryApplication({ companyId, memberId, rankingId }),
      payload,
    );

    return response;
  };

  updateEntryApplication = async (
    companyId: number,
    memberId: number,
    rankingId: number,
    entryId: number,
    payload: CreateEntryApplication,
  ): Promise<Response> => {
    const response = await this.httpAdapter.patch(
      this.urls.getRecordReferenceDetails({
        companyId,
        memberId,
        rankingId,
        entryId,
      }),
      payload,
    );

    return response;
  };

  deleteEntry = async (
    companyId: number,
    memberId: number,
    rankingId: number,
    entryId: number,
  ): Promise<void> => {
    await this.httpAdapter.delete(
      this.urls.getRecordReferenceDetails({
        companyId,
        memberId,
        rankingId,
        entryId,
      }),
      {},
    );
  };
}
