import React, { useState, useEffect } from 'react';
import './forgotInputPassForm.scss';
import PassImg from 'assets/icons/lock.svg';
import words from 'constants/words';
import { memberForgotInputType, memberForgotInputErrorType } from 'type/member';
import HideEyeImg from 'assets/icons/hide-eye.svg';
import ShowEyeImg from 'assets/icons/show-eye.svg';

type PropType = {
  handleUpdateValues: (object: memberForgotInputType) => void;
  listError: memberForgotInputErrorType;
};

const defaultValue = {
  password: '',
  passwordConfirm: '',
};
export default function ForgotInputPassForm(props: PropType) {
  const { handleUpdateValues, listError } = props;
  const [eyeMode, setEyeMode] = useState<boolean>(false);
  const [eyeModeCf, setEyeModeCf] = useState<boolean>(false);
  const [value, setValue] = useState<memberForgotInputType>(defaultValue);

  function newValueUpdate(data: string, type: string) {
    setValue(prevState => ({ ...prevState, [type]: data.toString() }));
  }

  useEffect(() => {
    handleUpdateValues(value);
  }, [value]);

  return (
    <div className="form-forgot-input">
      <table>
        <tbody>
          {/* Password Input */}
          <tr>
            <td>
              <img src={PassImg} />
            </td>
            <td className="pass-input">
              <label
                className={value.password.length > 0 ? 'filled' : 'not-filled'}>
                {words.memberSignUpPasswordTitle}
              </label>
              <br />
              <input
                className="input-signup"
                type={eyeMode ? 'text' : 'password'}
                placeholder={words.memberSignUpPasswordPlaceholder}
                value={value.password}
                onChange={e => newValueUpdate(e.target.value, 'password')}
                required
                autoComplete="new-password"
              />
              <div className="eyeIcon" onClick={() => setEyeMode(!eyeMode)}>
                <img src={eyeMode ? ShowEyeImg : HideEyeImg} />
              </div>
            </td>
          </tr>
          <tr>
            <td
              className={
                listError.password && listError.password.length > 0
                  ? 'errorMessage'
                  : 'noMessage'
              }
              colSpan={2}>
              {listError.password && listError.password.length > 0
                ? listError.password
                : ''}
            </td>
          </tr>
          {/* End Password Input */}
          {/* Password Confirm Input */}
          <tr>
            <td>
              <img src={PassImg} />
            </td>
            <td className="pass-input">
              <label
                className={
                  value.passwordConfirm.length > 0 ? 'filled' : 'not-filled'
                }>
                {words.memberSignUpPasswordCfTitle}
              </label>
              <br />
              <input
                className="input-signup"
                type={eyeModeCf ? 'text' : 'password'}
                placeholder={words.memberSignUpPasswordPlaceholder}
                value={value.passwordConfirm}
                onChange={e =>
                  newValueUpdate(e.target.value, 'passwordConfirm')
                }
                required
                autoComplete="new-password"
              />
              <div className="eyeIcon" onClick={() => setEyeModeCf(!eyeModeCf)}>
                <img src={eyeModeCf ? ShowEyeImg : HideEyeImg} />
              </div>
            </td>
          </tr>
          <tr>
            <td
              className={
                listError.passwordConfirm &&
                listError.passwordConfirm.length > 0
                  ? 'errorMessage'
                  : 'noMessage'
              }
              colSpan={2}>
              {listError.passwordConfirm && listError.passwordConfirm.length > 0
                ? listError.passwordConfirm
                : ''}
            </td>
          </tr>
          {/* End Password Confirm Input */}
        </tbody>
      </table>
    </div>
  );
}
