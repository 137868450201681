import React from 'react';

type Props = {
  [key: string]: string | number | Function;
};

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99973 2.125L7.44035 7.31125L1.71973 8.13812L5.85973 12.1769L4.88098 17.875L9.99973 15.1862L15.1185 17.875L14.1397 12.1769L18.2797 8.14375L12.5591 7.31125L9.99973 2.125Z"
        fill="#D64D30"
        stroke="#D64D30"
      />
    </svg>
  );
};

export default Component;
