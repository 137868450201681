import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

import { theme } from '../../../config';
import { TIMEOUT } from '../../../constants/timeout';
import { Props as INotification } from '../../molecules/Notification/Notification';

import { ToastNotification } from '../../molecules/Notification';
import { EditBasicInfo } from '../../templates/EditBasicInfo';

const Container = styled.div`
  background: ${theme.colors.uiBackground};
`;

export const CloseCircle = styled.div`
  width: 16px;
  height: 15px;
  margin-left: 2px;
  margin-top: 13px;
  cursor: pointer;
`;

type Props = {};

const Component = ({}: Props): React.ReactElement => {
  const location = useLocation();

  const [notification, setNotification] = useState<INotification | null>(null);

  const toastNotification = useCallback(
    (toast: INotification) => {
      setNotification({
        ...toast,
        timeout: TIMEOUT,
        handleClose: () => {
          setNotification(null);
          return false;
        },
      });
    },
    [notification],
  );

  return (
    <Container>
      <EditBasicInfo
        rankingId={location.state.rankingId}
        rankingNameProp={location.state.rankingName}
        toastNotification={toastNotification}
        redirectedFromRankingDetails={
          location.state.redirectedFromRankingDetails
        }
      />
      {notification && <ToastNotification notification={notification} />}
    </Container>
  );
};

export default Component;
