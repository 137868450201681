import React, { useCallback } from 'react';
import { useMutation } from 'react-query';
import { Formik, FormikHelpers } from 'formik';
import { MoveMemberToGroupSchema } from './validation';
import { Modal } from '../../molecules/Modal';
import { Props as INotification } from '../../molecules/Notification/Notification';
import { SelectedMemberType } from '../../templates/MemberList/types';
import { Group } from '../../../domain/entities/group';
import { useMemberHooks } from '../../../hooks/member';
import words from '../../../constants/words';
import { ScrollableUL, Item, ItemNumber } from './elements';
import SetGroupForm from './SetGroupForm';
import { MoveMemberToGroupFormType, MoveMembersType } from './types';

export type Props = {
  isOpen: boolean;
  membersToBeMoved: SelectedMemberType[];
  companyId: number;
  groupList: Group[];
  onClose: () => void;
  onSubmit: (isSuccessful: boolean, toast: INotification) => void;
};

const Component = ({
  isOpen = false,
  membersToBeMoved,
  companyId,
  onClose,
  onSubmit,
  groupList,
}: Props): React.ReactElement => {
  const initialValues: MoveMemberToGroupFormType = {
    memberGroupId: -1,
  };

  const { useBatchUpdateMembersGroup } = useMemberHooks();
  const { batchUpdateMembersGroup } = useBatchUpdateMembersGroup();

  const { mutate: mutateMoveMembers, isLoading } = useMutation(
    (params: MoveMembersType) =>
      batchUpdateMembersGroup(
        params.companyId,
        params.memberIds,
        params.groupId,
      ),
    {
      onSuccess: () => {
        onSubmit(true, {
          kind: 'success',
          title: words.moveMemberTitleSuccess,
          subtitle: words.moveMemberSubtitleSuccess,
        });
        onClose();
      },
      onError: () => {
        onSubmit(false, {
          kind: 'error',
          title: words.updateToastTitleError,
          subtitle: words.errorOccuredToastSub,
        });
        onClose();
      },
    },
  );

  const submitModal = useCallback(
    (
      values: MoveMemberToGroupFormType,
      { resetForm }: FormikHelpers<MoveMemberToGroupFormType>,
    ) => {
      mutateMoveMembers(
        {
          companyId,
          memberIds: membersToBeMoved.map(member => member.id),
          groupId: values.memberGroupId,
        },
        {
          onSuccess: () => {
            resetForm();
          },
        },
      );
    },
    [membersToBeMoved],
  );

  const renderMemberList = (members: SelectedMemberType[]) => {
    return (
      <ScrollableUL>
        {members.map((member, index) => (
          <Item key={member.id}>
            <ItemNumber>{index + 1}</ItemNumber>
            {member.name}
          </Item>
        ))}
      </ScrollableUL>
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={MoveMemberToGroupSchema}
      onSubmit={submitModal}
      validateOnMount={true}
      isInitialValid={false}>
      {({
        setFieldValue,
        handleSubmit,
        resetForm,
        values,
        isValid,
      }): React.ReactElement => {
        const { memberGroupId } = values;

        const handleOnClose = useCallback(() => {
          resetForm();
          onClose();
        }, []);

        return (
          <Modal
            open={isOpen}
            title={words.moveMemberModalTitle}
            label={words.moveMemberModalSubtitle}
            confirmButtonText={words.moveMemberConfirmation}
            onConfirm={handleSubmit}
            onCancel={handleOnClose}
            onClose={handleOnClose}
            confirmButtonDisabled={!isValid || isLoading}>
            <SetGroupForm
              memberGroupId={memberGroupId}
              groupList={groupList}
              onChangeMemberGroup={(id: number) =>
                setFieldValue('memberGroupId', id)
              }
            />
            {renderMemberList(membersToBeMoved)}
          </Modal>
        );
      }}
    </Formik>
  );
};

export default Component;
