import { createContext, useContext } from 'react';
import { AxiosResponse } from 'axios';
import {
  Member,
  CreateMemberType,
  UpdateMemberType,
  ValidateMemberRole,
  CheckUnassignedExistsType,
  CreateMemberField,
  MemberField,
  UserRoles,
} from '../../domain/entities/member';
import { PaginationQuery } from '../../domain/entities/request';
import { PaginatedResponse } from '../../domain/entities/response';

export type MemberHooks = {
  useFetchMembers: () => {
    fetchMembers: (
      params: PaginationQuery & {
        groupId?: number;
        roleName?: UserRoles;
        name?: string;
        companyId: number;
      },
    ) => Promise<PaginatedResponse & { results: Member[] }>;
    fetchMember: (payload: {
      companyId: number;
      memberId: number;
    }) => Promise<Member | undefined>;
    fetchMultiSelect: (
      params: PaginationQuery & {
        groupId?: number;
        roleName?: string;
        name?: string;
        companyId: number;
      },
    ) => Promise<PaginatedResponse & { results: Member[] }>;
    validateMemberRoleChangeable: (
      companyId: number,
      memberId: number,
    ) => Promise<ValidateMemberRole>;
  };
  useCreateMember: () => {
    createMember: (
      params: CreateMemberType,
    ) => Promise<Member & { temporary_password: string }>;
  };
  useDeleteMember: () => {
    deleteMember: (payload: {
      companyId: number;
      memberId: number;
    }) => Promise<void>;
  };
  useUpdateMember: () => {
    updateMember: (
      companyId: number,
      memberId: number,
      payload: UpdateMemberType,
    ) => Promise<Member>;
  };
  useBatchUpdateMembersGroup: () => {
    batchUpdateMembersGroup: (
      companyId: number,
      memberIds: number[],
      groupId: number,
    ) => Promise<AxiosResponse>;
  };
  useGenerateTemporaryPassword: () => {
    generateTemporaryPassword: (
      companyId: number,
      memberId: number,
    ) => Promise<{ temporary_password: string }>;
  };
  useFilterDeletableMembers: () => {
    filterDeletableMembers: (
      companyId: number,
      memberIds: string,
    ) => Promise<string[]>;
  };
  useBatchDeleteMembers: () => {
    batchDeleteMembers: (companyId: number, memberIds: string) => Promise<void>;
  };
  useCheckUnassignedExists: () => {
    checkUnassignedExists: (
      companyId: number,
    ) => Promise<CheckUnassignedExistsType>;
  };
  useCreateMemberFields: () => {
    createMemberFields: (
      companyId: number,
      customFieldValues: CreateMemberField[],
      memberId: number,
    ) => Promise<void>;
  };
  useUpdateMemberFields: () => {
    updateMemberFields: (
      companyId: number,
      customFields: MemberField[],
    ) => Promise<void>;
  };
};

export const MemberHooksContext = createContext<MemberHooks | null>(null);

export const useMemberHooks = (): MemberHooks => {
  const context = useContext(MemberHooksContext);
  if (!context) {
    throw new Error(`Context not instantiated`);
  }
  return context;
};
