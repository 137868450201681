import React from 'react';
import styled from 'styled-components';
import { theme } from '../../../config';
import words from '../../../constants/words';
import { Modal } from '../../molecules/Modal';

const TextWrapper = styled.text`
  padding: 0.5em 0;
  font-size: 13px;
  line-height: 18px;
  color: ${theme.colors.black};
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1em 0;
`;

export type DeleteFieldModalProps = {
  open: boolean;
  id: number;
  fieldName: string;
  fieldSetting: string;
  onConfirm: (id: number) => void;
  onClose: () => void;
  onCancel: () => void;
};

const ModalComponent = ({
  open,
  id,
  onConfirm,
  onClose,
  onCancel,
  fieldName,
  fieldSetting,
}: DeleteFieldModalProps): React.ReactElement => {
  return (
    <>
      <Modal
        label={words.deleteModalSubtitle}
        open={open}
        title={words.deleteFieldConfirmation}
        confirmButtonText={words.deleteField}
        confirmButtonTheme="secondary"
        hasScrollingContent={false}
        onConfirm={() => onConfirm(id)}
        onClose={onClose}
        onCancel={onCancel}>
        <ContentWrapper>
          <TextWrapper>
            {words.fieldName}: {fieldName}
          </TextWrapper>
          <TextWrapper>
            {words.fieldSetting}: {fieldSetting}
          </TextWrapper>
        </ContentWrapper>
      </Modal>
    </>
  );
};

export default ModalComponent;
