import React, { useEffect, useState } from 'react';
import { SimpleBarChart } from '@carbon/charts-react';
import '@carbon/charts/styles.css';
import styled from 'styled-components';
import { theme } from '../../../config';
import { useWindowDimensions } from '../../../utils/screenDimension';
import DefaultProfileImg from '../../../assets/images/profile-user.jpg';
import OrdinalSuffixOf from '../../../utils/numberToOrdinalNumber';
import { HoverTooltip } from '../../atoms/Tooltip';
import words from '../../../constants/words';
import NumberFormat from 'react-number-format';

const Container = styled.div<{ screenWidth: number; totalData: number }>`
  width: ${props =>
    (props.screenWidth *
      (props.totalData / 10 > 1 ? props.totalData / 10 + 1 : 1)) /
    1.4}px;
  position: relative;

  margin: 14px 0 15px 41px;

  .cds--cc--grid rect.chart-grid-backdrop.stroked {
    stroke: none;
  }

  .cds--cc--axes g.axis path.domain {
    stroke: ${theme.colors.black};
  }

  .cds--cc--axes g.axis g.tick text {
    font-family: 'M PLUS 1p';
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    fill: ${theme.colors.black};
  }

  @media ${theme.breakpoints.mobile} {
    width: ${props =>
      (props.screenWidth *
        (props.totalData / 4 > 1 ? props.totalData / 4 + 1 : 1)) /
      1.4}px;
  }
`;

const AxisTitleWrapper = styled.div<{ highestNumberLength: number }>`
  position: absolute;
  width: ${props => detailsPaddingLeft[`${props.highestNumberLength}`]};
  height: 28px;
  top: -20px;
  left: -12px;
  overflow: hidden;
  word-break: break-all;
`;

const AxisTitle = styled.p`
  font-size: 13px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.16px;
  text-align: right;
  color: ${theme.colors.textLingOrange};
`;

const ChartContainer = styled.div`
  margin-top: 41px;
`;

const DetailsWrapper = styled.div<{
  screenWidth: number;
  totalData: number;
  highestNumberLength: number;
}>`
  width: ${props =>
    (props.screenWidth *
      (props.totalData / 10 > 1 ? props.totalData / 10 + 1 : 1)) /
    1.4}px;
  padding-left: ${props => detailsPaddingLeft[`${props.highestNumberLength}`]};
  display: flex;
  justify-content: space-around;

  @media ${theme.breakpoints.mobile} {
    width: ${props =>
      (props.screenWidth *
        (props.totalData / 4 > 1 ? props.totalData / 4 + 1 : 1)) /
      1.4}px;
  }
`;

const Details = styled.div`
  width: 100%;
  text-align: center;
  padding: 6px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ItemImage = styled.img`
  border: 1px solid ${theme.colors.listBorder};
  box-sizing: border-box;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
`;

const NameText = styled.p`
  word-break: break-all;
  font-size: 12px;
  margin-top: 5px;
  line-height: 16px;
  letter-spacing: 0.32px;
`;

const GroupName = styled.p`
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.32px;
  color: #6f6f6f;
`;

const detailsPaddingLeft: any = {
  '12': '133px',
  '11': '124px',
  '10': '118px',
  '9': '106px',
  '8': '98px',
  '7': '90px',
  '6': '79px',
  '5': '71px',
  '4': '63px',
  '3': '52px',
  '2': '45px',
  '1': '38px',
};

export type RankingGraphProps = {
  fields: {
    data_type: string;
    field_lookup_name: string;
    is_basis: boolean;
    value_char: string | undefined;
    value_decimal: number;
  }[];
  id: number | undefined;
  member: {
    photo: string | undefined;
    name: string;
    group: {
      name: string;
    };
  };
  rank: number;
  value: string;
}[];

const Component = ({
  data,
}: {
  data: RankingGraphProps;
}): React.ReactElement => {
  const { width } = useWindowDimensions();
  const [screenWidth, setScreenWidth] = useState(0);
  const [indexName, setIndexName] = useState('');

  const rankColor = (num: number): string => {
    switch (num) {
      case 1:
        return theme.colors.gold;
      case 2:
        return theme.colors.silver;
      case 3:
        return theme.colors.bronze;
      default:
        return theme.colors.gray;
    }
  };

  const intervalCalculator = (number: number) => {
    const numberLength = Math.round(number).toString().length;
    const multiplier = Number(
      `1${numberLength >= 3 ? '0'.repeat(numberLength - 2) : ''}`,
    );
    const highestNumber =
      Math.round((number / multiplier) * multiplier) + multiplier;
    const numberToDeduct = number < 6 ? 1 : Math.floor(highestNumber / 6);
    const arrayToReturn = [0];

    for (let i = 0; i < 6; i++) {
      arrayToReturn.push(
        Math.round(
          Math.floor(highestNumber - numberToDeduct * i) / multiplier,
        ) * multiplier,
      );
    }

    return arrayToReturn;
  };

  const chartOptions: {} = {
    axes: {
      left: {
        mapsTo: 'value',
        ticks: {
          values: intervalCalculator(data.length && parseFloat(data[0].value)),
          formatter: (value: string | number) =>
            Number(value).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
        },
      },
      bottom: {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        scaleType: 'labels',
        mapsTo: 'group',
      },
    },
    color: {
      scale: {
        ...Object.assign(
          {},
          ...data.map((value, key) => {
            const index = key + 1;
            return {
              [OrdinalSuffixOf(index)]: rankColor(index),
            };
          }),
        ),
      },
    },
    height: '408px',
    width: '100%',
    grid: {
      x: {
        enabled: false,
      },
      y: {
        alignWithAxisTicks: true,
      },
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      customHTML: (data: Array<{ group: string; value: number }>) =>
        Number(data[0].value).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      enabled: true,
    },
    animations: true,
    toolbar: {
      enabled: false,
    },
  };

  useEffect(() => {
    if (width > screenWidth) {
      setScreenWidth(width - 200);
    }
  }, [width]);

  useEffect(() => {
    if (data.length) {
      const basisField = data[0].fields?.find(
        (e: { is_basis: boolean }) => e.is_basis,
      );
      setIndexName(basisField?.field_lookup_name as string);
    }
  }, [data]);

  return (
    <>
      {data.length && (
        <Container screenWidth={screenWidth} totalData={data.length}>
          <AxisTitleWrapper
            highestNumberLength={
              Math.round((data[0].value as unknown) as number).toString().length
            }>
            <AxisTitle>{indexName}</AxisTitle>
          </AxisTitleWrapper>
          <ChartContainer>
            <SimpleBarChart
              options={chartOptions}
              data={[
                ...data.map((item, key) => {
                  return {
                    group:
                      key === 0
                        ? OrdinalSuffixOf(item.rank)
                        : [OrdinalSuffixOf(item.rank)],
                    value: parseFloat(item.value),
                  };
                }),
              ]}
            />
          </ChartContainer>
          <DetailsWrapper
            screenWidth={screenWidth}
            totalData={data.length}
            highestNumberLength={
              Math.round((data[0].value as unknown) as number).toString().length
            }>
            {data.map((item, key) => {
              const fieldsFilteredOutBasis = item.fields?.filter(
                (e: { is_basis: boolean }) => !e.is_basis,
              );
              return (
                <>
                  <Details key={key}>
                    <div>
                      <HoverTooltip
                        menuOffset={{ top: 20 }}
                        tooltipClassName={'detail-tooltip'}
                        triggerNode={
                          <>
                            <div>
                              <ItemImage
                                src={item.member.photo || DefaultProfileImg}
                              />
                              <NameText>{item.member?.name}</NameText>
                            </div>
                            <GroupName>{item.member?.group?.name}</GroupName>
                          </>
                        }
                        direction={'top'}
                        autoOrientation={false}>
                        <div>
                          <p>
                            {words.group}: {item.member?.group?.name}
                          </p>
                          {fieldsFilteredOutBasis?.map((field, index) => {
                            const fieldValueElement =
                              field.data_type === 'decimal' ? (
                                <NumberFormat
                                  displayType="text"
                                  value={field.value_decimal || 0}
                                  decimalScale={4}
                                  thousandSeparator
                                />
                              ) : (
                                field.value_char
                              );
                            return (
                              <p key={index}>
                                {field.field_lookup_name}: {fieldValueElement}
                              </p>
                            );
                          })}
                        </div>
                      </HoverTooltip>
                    </div>
                  </Details>
                </>
              );
            })}
          </DetailsWrapper>
        </Container>
      )}
    </>
  );
};

export default Component;
