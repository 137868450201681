import { object, string, ValidationError } from 'yup';
import words from 'constants/words';
import { memberLoginType } from 'type/member';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function getErrors(items: ValidationError[]) {
  if (!items) {
    return {};
  }

  return items.reduce((error: object, e: ValidationError) => {
    return {
      ...error,
      [`${e.path}`]: e.message,
    };
  }, {});
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export async function validateLogin(data: memberLoginType) {
  const schema = object().shape({
    company_id: string().required(words.memberSignupCompanyIdRequired),
    email: string()
      .required(words.memberSignupEmailRequired)
      .max(254, words.emailMaxRequired)
      .email(words.memberSignupEmailInvalid),
    password: string()
      .required(words.memberSignupPasswordRequired)
      .min(6, words.passwordMin)
      .max(254, words.passwordMaxRequired),
  });

  const result = await schema
    .validate(data, { abortEarly: false })
    .catch(err => err);
  return getErrors(result.inner) as memberLoginType;
}
