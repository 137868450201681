import { HttpAdapter } from '../../usecases/ports/HttpAdapter';
import { AxiosResponse, AxiosStatic, AxiosError, AxiosInstance } from 'axios';
export default class HttpAdapterAxiosImpl implements HttpAdapter {
  token?: string;
  axios: AxiosInstance;

  constructor(axiosInstance: AxiosStatic, baseURL?: string) {
    this.axios = axiosInstance.create({
      baseURL,
    });
    this.axios.interceptors.request.use(config => {
      const strUser = localStorage.getItem('user');
      const objUser = JSON.parse(
        strUser && strUser !== 'undefined' ? strUser : '{}',
      );
      const { access_token } = objUser;

      config.headers['Accept'] = 'application/json';
      config.headers['Content-Type'] = 'application/json';
      if (access_token) {
        config.headers['Authorization'] = 'JWT ' + access_token;
      }
      return config;
    });
    this.axios.interceptors.response.use(
      response => response,
      error => {
        const originalRequest = error.config;
        if (
          error.response.status === 401 &&
          error.response.statusText === 'Unauthorized'
        ) {
          const user = JSON.parse(localStorage.getItem('user') || '{}');
          const { refresh_token } = user;

          return this.axios
            .post('/token/refresh/', { refresh: refresh_token })
            .then(response => {
              if (Object.keys(user).length !== 0) {
                user.access_token = response.data.access;
                user.refresh_token = response.data.refresh;
                localStorage.setItem('user', JSON.stringify(user));
              }

              this.axios.defaults.headers['Authorization'] =
                'JWT ' + response.data.access;
              originalRequest.headers['Authorization'] =
                'JWT ' + response.data.access;

              return this.axios(originalRequest);
            })
            .catch(err => {
              throw new Error(err);
            });
        }
        return Promise.reject(error);
      },
    );
  }

  get = (url: string, options: Object): Promise<AxiosResponse> => {
    return this.axios.get(url, options);
  };

  head = (url: string, options: Object): Promise<AxiosResponse> => {
    return this.axios.head(url, options);
  };

  post = async (url: string, body: Object): Promise<AxiosResponse> => {
    try {
      const response = await this.axios.post(url, body);
      return response;
    } catch (err) {
      if ((err as AxiosError).response?.data) {
        throw new Error((err as AxiosError).response?.data);
      }
      throw err;
    }
  };

  patch = (url: string, body: Object): Promise<AxiosResponse> => {
    return this.axios.patch(url, body);
  };

  put = (url: string, body: Object): Promise<AxiosResponse> => {
    return this.axios.put(url, body);
  };

  delete = (url: string, options: Object): Promise<AxiosResponse> => {
    return this.axios.delete(url, options);
  };

  setToken = (token: string): void => {
    this.token = token;
  };
}
