import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  MouseEventHandler,
  useRef,
} from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { theme } from '../../../config';

import { SelectedStar, UnselectedStar } from '../../atoms/Icons';
import { TextInput } from '../../atoms/TextInput';
import { DraggableTable } from '../../organisms/Table';
import words, { languageUsed } from '../../../constants/words';
import { Dropdown } from '../../molecules/Dropdown';
import { Tooltip } from '../../atoms/Tooltip';
import { Button } from '../../atoms/Button';
import { AnchorTag } from '../../atoms/AnchorTag';
import { DataTableSkeleton } from 'carbon-components-react';
import themes from '../../../config/themes';

import { useGlobalState } from '../../../hooks/global';
import { useLoginFieldHooks } from '../../../hooks/field';
import { useQuery } from 'react-query';
import { SettingProps } from '../../../domain/entities/member';
import { TableLoader } from '../../atoms/Loading';

export type RankingColumn = {
  id: number;
  ranking_id?: number;
  dataType: 'user-info' | 'decimal';
  name: string;
  memberFieldLookupId?: number;
  isIndex?: boolean;
  isDefault?: boolean;
  isDisabled?: boolean;
  isBasis?: boolean;
  isRequired?: boolean;
  orderId: number;
};

export type Props = {
  initialData: Array<RankingColumn>;
  onDelete?: (fieldId: number) => void;
  onDisable?: (fieldId: number) => void;
  onDuplicate?: (fieldParams: RankingColumn) => void;
  onCreate?: (fieldParams: Omit<RankingColumn, 'id'>) => void;
  onUpdate?: (
    fieldId: number,
    fieldParams: RankingColumn,
    prevEdited?: RankingColumn,
    memberLookupId?: number,
  ) => void;
  onActivate?: (fieldId: number) => void;
  onIndex?: (fieldId: number) => void;
  onCancelAdd?: () => void;
  toggleEdit?: () => void;
  type?: 'default' | 'active' | 'inactive';
  className?: string;
  isDisabled?: boolean;
  isAdding?: boolean;
  isEditing?: boolean;
  isLoading?: boolean;
  fieldNames?: string[];
  duplicatedIds?: number[];
  isMaxFields?: boolean;
  emptyDataLabelTitle?: string;
  emptyDataLabelDetails?: string;
  isFromAddRanking?: boolean;
  onDrag?: (order: any[]) => void;
  isDraggable?: boolean;
};

const STARTER_SETTINGS_DATA = [
  {
    id: 0,
    name: words.companyId,
    required: true,
    visible: true,
    disabled: true,
    isDefault: true,
  },
  {
    id: 0,
    name: words.userId,
    required: true,
    visible: true,
    disabled: true,
    isDefault: true,
  },
  {
    id: 0,
    name: words.name,
    required: true,
    visible: true,
    disabled: true,
    isDefault: true,
  },
  {
    id: 0,
    name: words.group,
    required: true,
    visible: true,
    disabled: true,
    isDefault: true,
  },
  {
    id: 0,
    name: words.roleLoginSetting,
    required: true,
    visible: true,
    disabled: true,
    isDefault: true,
  },
];

const ACTIVE_HEADERS = [
  {
    key: 'number',
    header: 'No',
  },
  {
    key: 'index',
    header: words.index,
  },
  {
    key: 'dataType',
    header: words.dataType,
  },
  {
    key: 'name',
    header: words.columnName,
  },
  {
    key: 'action',
    header: '',
  },
];
const INACTIVE_HEADERS = [
  {
    key: 'number',
    header: 'No',
  },
  {
    key: 'dataType',
    header: words.dataType,
  },
  {
    key: 'name',
    header: words.columnName,
  },
  {
    key: 'action',
    header: '',
  },
];

const DATA_TYPE_OPTIONS = {
  decimal: { id: 'decimal', label: words.decimal },
  'user-info': { id: 'user-info', label: words.userInfo },
};

const Wrapper = styled.div.attrs(
  (props: { isAddingField: boolean; editRow: number; duplicateRow: number }) =>
    props,
)`
  display: flex;

  background: ${theme.colors.white};
  box-shadow: 0px 0px 8px rgba(236, 215, 192, 0.8);
  flex: 1;

  .bx--data-table-content {
    overflow: visible;
    display: table;
    flex: 1;
  }
  .bx--data-table {
  }
  .bx--text-input__field-outer-wrapper {
    width: 0;
  }
  .bx--tab-content:focus {
    outline: 0 !important;
  }
  .bx--text-input__field-wrapper {
    input {
      width: 320px;
    }
  }

  tbody tr td:last-child,
  thead tr th:last-child {
    width: 200px;
    padding: 0;
  }

  &&& {
    tbody tr:nth-child(${props => props.editRow}) td {
      background-color: ${theme.colors.ui03};
    }
    tbody tr:nth-child(${props => props.duplicateRow}) td {
      background-color: ${theme.colors.ui03};
    }

    tbody tr:last-child td {
      ${props =>
        props.isAddingField ? `background-color: ${theme.colors.ui03};` : ''}
    }
  }
`;

const TextInputWrapper = styled.div.attrs((props: { width: number }) => props)`
  justify-content: center;
  align-items: center;
  width: ${({ width }) => `${width}px`};
  max-width: 100%;
  padding: 0.5em 0;
  input {
    max-width: 100%;
    margin-bottom: 0;
    margin-left: -1rem;
  }
  .bx--text-input__field-outer-wrapper {
    width: ${({ width }) => `${width}px`};
    max-width: 100%;
  }
  .bx--text-input__invalid-icon,
  .bx--text-input__readonly-icon {
    right: 2rem;
  }

  @media ${themes.main.breakpoints.pc} {
    min-width: 180px;
    width: 180px;
  }
  @media ${themes.main.breakpoints.wideScreen} {
    min-width: ${({ width }) => `${width}px`};
    width: ${({ width }) => `${width}px`};
  }
`;

const StyledTable = styled(DraggableTable)``;

const DropdownWrapper = styled.div.attrs((props: { width: number }) => props)`
  width: ${({ width }) => `${width}px`};
  padding: 0.5em 0;
`;

const IndexWrapper = styled.div.attrs((props: { width: number }) => props)`
  width: ${({ width }) => `${width}px`};
  padding: 0 16px;
`;

const ButtonsWrapper = styled.div<{ $width?: number }>`
  display: flex;
  min-width: ${({ $width = 200 }) => $width}px;
  width: ${({ $width = 200 }) => $width}px;
  div:nth-child(2) {
    padding-left: 16px;
  }

  @media ${themes.main.breakpoints.pc} {
    min-width: 200px;
    width: 200px;
  }
  @media ${themes.main.breakpoints.wideScreen} {
    min-width: ${({ $width = 200 }) => $width}px;
    width: ${({ $width = 200 }) => $width}px;
  }
`;

export const CustomDataTableSkeletonContainer = styled.div`
  & > .bx--skeleton.bx--data-table-container {
    padding-top: 0;
  }
`;

export const CustomDataTableSkeleton = styled(DataTableSkeleton)`
  th {
    background-color: ${themes.main.colors.ui03};
  }
  .bx--table-header-label {
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.16px;
  }
`;

type ActionButtonType = {
  $color: string;
  $textAlign?: 'left' | 'center' | 'right';
  disabled?: boolean;
};
const ActionButton = styled.button`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.16px;

  color: ${({ $color }: ActionButtonType) => $color};
  cursor: ${({ disabled = false }: ConfirmButtonType) =>
    disabled ? 'not-allowed' : 'pointer'};
  flex: 1;

  :hover {
    text-decoration: underline;
  }

  background: none;
  text-align: ${({ $textAlign = 'left' }: ActionButtonType) => $textAlign};
  border: none;
  padding: 0;
  outline: inherit;
`;

type ConfirmButtonType = {
  color?: string;
  disabled?: boolean;
};
const ConfirmButton = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;

  color: ${({ color = theme.colors.text02 }: ConfirmButtonType) => color};
  cursor: ${({ disabled = false }: ConfirmButtonType) =>
    disabled ? 'not-allowed' : 'pointer'};
  flex: 1;
`;

const DisabledButton = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: 0.16px;

  color: ${theme.colors.text03};
  flex: 1;
`;

const NumberCell = styled.div`
  text-align: right;
  width: 30px;
  display: flex;
`;

const CustomDiv = styled.div``;

const StyledTooltip = styled(Tooltip)`
  flex: 1;
`;

const CancelLink = styled(AnchorTag)`
  div {
    color: ${theme.colors.anchorTagModal};
    @media ${theme.breakpoints.mobile} {
      padding-left: 10px;
    }
  }
`;

export const GlobalStyle = createGlobalStyle`
  .tooltip {
    min-width: 256px !important;
    max-width: 256px;
  }
`;

const INITIAL_RANKINGS: RankingColumn[] = [
  {
    id: 0,
    name: words.profilePic,
    dataType: 'user-info',
    isDefault: true,
    orderId: 0,
  },
  {
    id: -1,
    name: words.name,
    dataType: 'user-info',
    isDefault: true,
    orderId: 0,
  },
  {
    id: -2,
    name: words.group,
    dataType: 'user-info',
    isDefault: true,
    orderId: 0,
  },
];

const usePrevious = (value: any) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const Component = ({
  initialData,
  onDelete = () => {},
  onDisable = () => {},
  onDuplicate = () => {},
  onUpdate = () => {},
  onCreate = () => {},
  onActivate = () => {},
  onIndex = () => {},
  onCancelAdd,
  toggleEdit,
  type = 'default',
  className,
  isDisabled = false,
  isAdding = false,
  isEditing = false,
  isLoading = false,
  fieldNames = [],
  duplicatedIds = [],
  isMaxFields = false,
  emptyDataLabelTitle = '',
  emptyDataLabelDetails = '',
  isFromAddRanking = false,
  onDrag,
  isDraggable = false,
}: Props): React.ReactElement => {
  const [toBeAdded, setToBeAdded] = useState<
    | (Omit<RankingColumn, 'id' | 'isIndex' | 'isDefault' | 'dataType'> & {
        dataType?: 'user-info' | 'decimal';
      })
    | undefined
  >(undefined);
  const [toBeEdited, setToBeEdited] = useState<
    Omit<RankingColumn, 'isDefault' | 'isIndex'> | undefined
  >(undefined);
  const [toBeDuplicated, setToBeDuplicated] = useState<
    Omit<RankingColumn, 'isDefault' | 'isIndex'> | undefined
  >(undefined);
  const {
    useCurrentUser: { currentUser },
  } = useGlobalState();
  const { useFetchLoginFields } = useLoginFieldHooks();
  const [toBeDeletedId, setToBeDeletedId] = useState<number | undefined>();
  const [toBeActivatedId, setToBeActivatedId] = useState<number | undefined>();
  const [toBeIndexedId, setToBeIndexedId] = useState<number | undefined>();
  const [toBeDisabledId, setToBeDisabledId] = useState<number | undefined>();
  const [shouldShowIndexTip, setShouldShowIndexTip] = useState<boolean>(false);
  const [fieldNameError, setFieldNameError] = useState<string>('');
  const [dropdownFieldNameError, setDropdownFieldNameError] = useState<string>(
    '',
  );
  const [tableDataState, setTableData] = useState<any>([]);
  const { fetchFields } = useFetchLoginFields();
  const companyId = currentUser ? currentUser.companyId : 0;
  const prevRowEdited: any = usePrevious(toBeEdited);
  const prevRowAdded: any = usePrevious(toBeAdded);
  useEffect(() => {
    const evalShouldShowTip =
      initialData.findIndex(data => data.isIndex) === initialData.length - 1;
    setShouldShowIndexTip(evalShouldShowTip);
    return () => {};
  }, [initialData.length]);

  const onClickAdd = useCallback((): void => {
    setToBeDuplicated(undefined);
    setToBeEdited(undefined);
    setFieldNameError('');
    setToBeAdded({ name: '', orderId: 0 });
  }, [setToBeAdded]);

  useEffect(() => {
    if (isAdding) {
      onClickAdd();
    } else {
      setToBeAdded(undefined);
    }
  }, [isAdding]);

  useEffect(() => {
    setTableData(initialData);
  }, [initialData]);

  const checkDuplicatedColumn = (id: number): boolean =>
    duplicatedIds.includes(id);

  const checkFieldName = (text: string) => {
    const formattedText = removeSpaces(text);
    if (formattedText !== '') {
      return checkDuplicateField(formattedText);
    }
    setFieldNameError(words.customFieldRequired);
  };

  const checkDuplicateField = (text: string) => {
    const isFieldNameExists = nameExists(text);
    setFieldNameError(isFieldNameExists ? words.fieldNameAlreadyTaken : '');
  };

  const nameExists = (name: string) =>
    fieldNames
      .map((columnName: string): string => {
        return columnName.toLowerCase();
      })
      .includes(name.toLowerCase());

  const removeSpaces = (name: string) => name.trim();

  const { data: basicInfoFields, isLoading: isLoadingFields } = useQuery(
    ['member-setting-fields', currentUser],
    async () => {
      if (!currentUser?.companyId) {
        throw new Error(`'company id' field not set`);
      }

      const response = await fetchFields({
        companyId,
      });
      const fields: SettingProps[] = response.map((item: any) => {
        return {
          ...item,
          id: `${item.id}`,
          isDefault: item.is_default,
          visible: item.visible,
          disabled: false,
        };
      });

      const allFields = STARTER_SETTINGS_DATA.concat(fields);
      return allFields;
    },
  );

  const renderTextInput = useCallback(
    (
      id: string,
      value: string | undefined,
      onChangeText: (text: string) => void,
      prevEditRow?: any,
      currentEditRow?: any,
    ): React.ReactElement => {
      let flag = 0;
      let tripleChangeError = 0;
      if (
        (id.includes('edit-field') || id.includes('add-field')) &&
        prevEditRow
      ) {
        if (prevEditRow.dataType === 'user-info') {
          value = undefined;
        }
      }
      if (
        (id.includes('add-field') || id.includes('edit-field')) &&
        prevEditRow &&
        currentEditRow
      ) {
        if (
          prevEditRow.name === currentEditRow.name &&
          prevEditRow.dataType !== undefined &&
          currentEditRow.dataType !== undefined &&
          prevEditRow.dataType === currentEditRow.dataType
        ) {
          flag = 1;
          tripleChangeError = 1;
        }
      }
      return (
        <TextInputWrapper width={type === 'default' ? 280 : 216}>
          <TextInput
            id={id}
            value={flag === 1 ? undefined : value}
            onChange={ev => {
              const target = ev.target as HTMLInputElement;
              onChangeText(target.value);
              checkFieldName(target.value);
            }}
            invalid={
              (fieldNameError === words.customFieldRequired &&
                (value === '' ||
                  value === undefined ||
                  (value !== '' && tripleChangeError === 1))) ||
              fieldNameError === words.fieldNameAlreadyTaken
            }
            invalidText={fieldNameError}
          />
        </TextInputWrapper>
      );
    },
    [type, fieldNames, fieldNameError],
  );

  const renderDropdownInput = useCallback(
    (
      id: string,
      infoFields: any,
      onChange: (selectedItem: { id: string; label: string }) => void,
      selectedItem?: { id: string; label: string },
      editRow?: any,
      currentEditRow?: any,
      disabled?: boolean,
    ): React.ReactElement => {
      let invalid = false;
      let requiredError = false;
      const USER_INFO_TYPE_OPTIONS = infoFields
        .filter((value: any) => value.visible !== false)
        .filter(
          (a: any) => !INITIAL_RANKINGS.some((b: any) => a.name === b.name),
        )
        .filter((a: any) => !tableDataState.some((b: any) => a.name === b.name))
        .filter((a: any) => !fieldNames.some((b: any) => a.name === b))
        .reduce(
          (result: any, key: any) =>
            Object.assign(result, {
              [key.name.replace(' ', '-')]: {
                id: key.name.replace(' ', '-'),
                label: key.name,
              },
            }),
          {},
        );
      if (id === 'edit-duplicated-dropdownField' && selectedItem) {
        if (tableDataState.some((e: any) => e.name === selectedItem?.label)) {
          invalid = true;
        } else {
          invalid = false;
        }
      } else if (
        (id === 'edit-dropdown-field' || id === 'basic-info-dropdown') &&
        editRow
      ) {
        if (editRow.dataType === 'decimal') {
          selectedItem = undefined;
          invalid = true;
          requiredError = true;
        } else if (
          editRow.name === currentEditRow.name &&
          editRow.dataType === currentEditRow.dataType &&
          fieldNameError !== ''
        ) {
          selectedItem = undefined;
          invalid = true;
          requiredError = true;
        }
      }
      return (
        <TextInputWrapper width={type === 'default' ? 280 : 216}>
          <Dropdown
            id={id}
            label=""
            items={Object.values(USER_INFO_TYPE_OPTIONS)}
            onChange={(selected: {
              selectedItem: { id: string; label: string };
            }) => {
              onChange(selected.selectedItem);
              checkFieldName(selected.selectedItem.label);
            }}
            backgroundColor={theme.colors.white}
            dropdownProps={{
              selectedItem,
            }}
            invalid={fieldNameError !== '' || invalid}
            invalidText={
              fieldNameError
                ? fieldNameError
                : requiredError
                ? words.customFieldRequired
                : words.fieldNameAlreadyTaken
            }
            styles={{
              // to offset padding left of dropdown component
              marginLeft: '-1rem',
            }}
          />
        </TextInputWrapper>
      );
    },
    [type, fieldNameError, tableDataState, fieldNames],
  );

  const renderDropdown = useCallback(
    (
      id: string,
      onChange: (selectedItem: {
        id: 'decimal' | 'user-info';
        label: string;
      }) => void,
      selectedItem?: { id: string; label: string },
      disabled?: boolean,
    ): React.ReactElement => {
      let invalid = false;
      if (selectedItem === undefined) {
        invalid = true;
      }
      return (
        <DropdownWrapper width={type === 'default' ? 280 : 132}>
          <Dropdown
            id={id}
            label=""
            items={Object.values(DATA_TYPE_OPTIONS)}
            onChange={(selected: {
              selectedItem: { id: 'decimal' | 'user-info'; label: string };
            }) => {
              onChange(selected.selectedItem);
              setFieldNameError('');
            }}
            backgroundColor={theme.colors.white}
            dropdownProps={{
              selectedItem,
            }}
            styles={{
              width: type === 'default' ? 280 : 132,
              // to offset padding left of dropdown component
              marginLeft: '-1rem',
            }}
            disabled={!!disabled}
            invalid={fieldNameError === words.customFieldRequired && invalid}
            invalidText={fieldNameError}
          />
        </DropdownWrapper>
      );
    },
    [type, fieldNameError],
  );

  const renderActions = ({
    id,
    editCallback,
    deleteCallback,
    duplicateCallback,
    disableCallback,
    activateCallback,
    saveCallback,
    cancelCallback,
    isDefault,
    isIndex,
    isDuplicated,
  }: {
    id?: number;
    editCallback?: React.MouseEventHandler<HTMLSpanElement>;
    deleteCallback?: React.MouseEventHandler<HTMLSpanElement>;
    duplicateCallback?: React.MouseEventHandler<HTMLSpanElement>;
    disableCallback?: React.MouseEventHandler<HTMLSpanElement>;
    activateCallback?: React.MouseEventHandler<HTMLSpanElement>;
    saveCallback?: MouseEventHandler<HTMLSpanElement>;
    cancelCallback?: React.MouseEventHandler<HTMLSpanElement>;
    isDefault?: boolean;
    isIndex?: boolean;
    isDuplicated?: boolean;
  }): React.ReactElement | null => {
    if (isDefault) {
      return (
        <ButtonsWrapper>
          <DisabledButton>{words.default}</DisabledButton>
          <DisabledButton />
        </ButtonsWrapper>
      );
    }
    if (isDisabled) {
      return (
        <ButtonsWrapper>
          <DisabledButton>{words.edit}</DisabledButton>
          <DisabledButton>{words.disable}</DisabledButton>
        </ButtonsWrapper>
      );
    }
    if (editCallback && deleteCallback) {
      return (
        <ButtonsWrapper>
          <ActionButton
            onClick={editCallback}
            $color={theme.colors.interactive04}>
            {words.edit}
          </ActionButton>
          <StyledTooltip
            message={
              isIndex
                ? words.selectIndexBeforeDelete
                : words.confirmDeleteColumn
            }
            direction="bottom"
            tooltipClassName="tooltip"
            isOpen={id !== undefined && id === toBeDeletedId}
            onChange={(ev, { open }) => {
              if (!open) {
                setToBeDeletedId(undefined);
              }
            }}
            triggerElement={
              <ActionButton
                onClick={() => {
                  if (toBeDeletedId !== undefined && toBeDeletedId === id) {
                    setToBeDeletedId(undefined);
                  } else {
                    setToBeDeletedId(id);
                  }
                }}
                $color={theme.colors.errorColor}>
                {words.delete}
              </ActionButton>
            }>
            {!isIndex ? (
              <div className="bx--tooltip__footer">
                <CancelLink
                  onPress={() => {
                    setToBeDeletedId(undefined);
                  }}
                  title={words.cancel}
                />
                <Button
                  style={{
                    fontSize: languageUsed === 'en' ? '16px' : '13px',
                    width: languageUsed === 'en' ? 'auto' : '117px',
                    height: languageUsed === 'en' ? 'auto' : '32px',
                  }}
                  onPress={deleteCallback}
                  title={words.addRankingColumnDelete}
                />
              </div>
            ) : (
              undefined
            )}
          </StyledTooltip>
        </ButtonsWrapper>
      );
    }
    if (duplicateCallback && disableCallback) {
      return (
        <ButtonsWrapper>
          <ActionButton
            onClick={
              !isDuplicated || !isMaxFields ? duplicateCallback : undefined
            }
            $color={
              isDuplicated || isMaxFields
                ? theme.colors.text03
                : theme.colors.interactive04
            }
            disabled={isDuplicated || isMaxFields}>
            {words.duplicate}
          </ActionButton>
          {/* <ActionButton
            onClick={editCallback}
            $color={theme.colors.interactive04}>
            {words.edit}
          </ActionButton> */}
          <StyledTooltip
            message={
              isIndex ? words.selectNewIndexFirst : words.confirmDisableColumn
            }
            direction="bottom"
            tooltipClassName="tooltip"
            preserveText
            isOpen={id !== undefined && id === toBeDisabledId}
            onChange={(ev, { open }) => {
              if (!open) {
                setToBeDisabledId(undefined);
              }
            }}
            triggerElement={
              <ActionButton
                onClick={() => {
                  setToBeDisabledId(id);
                }}
                $color={theme.colors.errorColor}>
                {words.disable}
              </ActionButton>
            }>
            <div
              className="bx--tooltip__footer"
              style={{ display: isIndex ? 'none' : '' }}>
              <CancelLink
                onPress={() => {
                  setToBeDisabledId(undefined);
                }}
                title={words.cancel}
              />
              <Button
                style={{
                  fontSize: languageUsed === 'en' ? '16px' : '13px',
                  width: languageUsed === 'en' ? 'auto' : '136px',
                  height: languageUsed === 'en' ? 'auto' : '32px',
                }}
                onPress={disableCallback}
                title={words.confirmDisableRankingColumn}
              />
            </div>
          </StyledTooltip>
        </ButtonsWrapper>
      );
    }
    if (activateCallback && deleteCallback) {
      return (
        <ButtonsWrapper>
          <StyledTooltip
            message={words.maxActiveColInfo}
            direction="bottom"
            tooltipClassName="tooltip"
            isOpen={id !== undefined && id === toBeActivatedId && isMaxFields}
            preserveText
            onChange={(ev, { open }) => {
              if (!open) {
                setToBeActivatedId(undefined);
              }
            }}
            triggerElement={
              <ActionButton
                onClick={activateCallback}
                $color={
                  isMaxFields ? theme.colors.text03 : theme.colors.interactive04
                }>
                {words.activate}
              </ActionButton>
            }
          />
          <StyledTooltip
            message={words.confirmDeleteColumn}
            direction="bottom"
            tooltipClassName="tooltip"
            isOpen={id !== undefined && id === toBeDeletedId}
            preserveText
            onChange={(ev, { open }) => {
              if (!open) {
                setToBeDeletedId(undefined);
              }
            }}
            triggerElement={
              <ActionButton
                onClick={() => {
                  setToBeDeletedId(id);
                }}
                $color={theme.colors.errorColor}>
                {words.delete}
              </ActionButton>
            }>
            <div className="bx--tooltip__footer">
              <CancelLink
                onPress={() => {
                  setToBeDeletedId(undefined);
                }}
                title={words.cancel}
              />
              <Button
                style={{
                  fontSize: languageUsed === 'en' ? '16px' : '13px',
                  width: languageUsed === 'en' ? 'auto' : '117px',
                  height: languageUsed === 'en' ? 'auto' : '32px',
                }}
                onPress={deleteCallback}
                title={words.confirmRankingDisabledColumnDelete}
              />
            </div>
          </StyledTooltip>
        </ButtonsWrapper>
      );
    }
    if (saveCallback && cancelCallback) {
      return (
        <ButtonsWrapper>
          <ConfirmButton
            onClick={fieldNameError === '' ? saveCallback : undefined}
            color={
              fieldNameError !== '' && isFromAddRanking === false
                ? theme.colors.text03
                : theme.colors.interactive04
            }
            disabled={!!(fieldNameError !== '')}>
            {words.rankingSave}
          </ConfirmButton>
          <ConfirmButton onClick={cancelCallback}>{words.cancel}</ConfirmButton>
        </ButtonsWrapper>
      );
    }

    return null;
  };

  const renderIndex = useCallback(
    (isSelected: boolean, itemId: number): React.ReactElement => {
      if (isSelected) {
        return (
          <IndexWrapper width={type === 'default' ? 77 : 44}>
            <StyledTooltip
              message={words.selectIndexTip}
              direction="bottom"
              isOpen={shouldShowIndexTip}
              onChange={(ev, { open: isOpen }) => {
                if (!isOpen) {
                  setShouldShowIndexTip(false);
                }
              }}
              triggerElement={
                <SelectedStar
                  onClick={() => {
                    onIndex(itemId);
                  }}
                />
              }
            />
          </IndexWrapper>
        );
      }
      return (
        <IndexWrapper width={type === 'default' ? 77 : 44}>
          <StyledTooltip
            message={words.confirmChangeIndex}
            direction="bottom"
            tooltipClassName="tooltip"
            isOpen={toBeIndexedId !== undefined && itemId === toBeIndexedId}
            onChange={(ev, { open }) => {
              if (!open) {
                setToBeIndexedId(undefined);
              }
            }}
            triggerElement={
              <UnselectedStar
                onClick={() => {
                  setToBeIndexedId(itemId);
                }}
              />
            }>
            <div className="bx--tooltip__footer">
              <CancelLink
                onPress={() => {
                  setToBeIndexedId(undefined);
                }}
                title={words.cancel}
              />
              <Button
                style={{
                  fontSize: languageUsed === 'en' ? '16px' : '13px',
                  width: languageUsed === 'en' ? 'auto' : '117px',
                  height: languageUsed === 'en' ? 'auto' : '32px',
                }}
                onPress={() => {
                  onIndex(itemId);
                  setToBeIndexedId(undefined);
                }}
                title={words.confirmRankingIndex}
              />
            </div>
          </StyledTooltip>
        </IndexWrapper>
      );
    },
    [type, toBeIndexedId, shouldShowIndexTip],
  );

  const renderRowData = () => {
    const tableData: {
      id: string;
      index?: React.ReactElement;
      dataType?: React.ReactElement | string;
      action: React.ReactElement | null;
      name: React.ReactElement | string;
      isReorderable?: boolean;
    }[] = [];

    initialData.forEach(_data => {
      if (toBeEdited && toBeEdited.id === _data.id && isAdding === false) {
        return tableData.push({
          id: `${_data.id}`,
          name:
            toBeEdited.dataType !== 'user-info'
              ? renderTextInput(
                  `edit-field-${_data.id}`,
                  toBeEdited.name,
                  (value: string) => {
                    setToBeEdited({ ...toBeEdited, name: value });
                  },
                  prevRowEdited,
                  toBeEdited,
                )
              : renderDropdownInput(
                  `edit-dropdown-field`,
                  basicInfoFields,
                  (selectedItem: { id: string; label: string }) => {
                    setToBeEdited({ ...toBeEdited, name: selectedItem.label });
                  },
                  {
                    id: toBeEdited.name.replace(' ', '-'),
                    label: toBeEdited.name,
                  },
                  prevRowEdited,
                  toBeEdited,
                ),
          dataType: renderDropdown(
            `edit-data-type-${_data.id}`,
            (selectedItem: { id: 'decimal' | 'user-info'; label: string }) => {
              setToBeEdited({
                ...toBeEdited,
                dataType: selectedItem.id,
              });
            },
            DATA_TYPE_OPTIONS[toBeEdited.dataType],
          ),
          action: renderActions({
            saveCallback: () => {
              let flag = 0;
              if (
                _data.name === toBeEdited.name &&
                _data.dataType === 'decimal' &&
                toBeEdited.dataType === 'user-info'
              ) {
                flag = 1;
              } else if (
                _data.name === toBeEdited.name &&
                _data.dataType === 'user-info' &&
                toBeEdited.dataType === 'decimal'
              ) {
                flag = 1;
              } else if (
                toBeEdited.name === prevRowEdited.name &&
                toBeEdited.dataType !== prevRowEdited.dataType
              ) {
                flag = 1;
              }
              if (flag === 0) {
                let findId;
                if (toBeEdited.dataType === 'user-info') {
                  findId = basicInfoFields?.find(
                    x => x.name === toBeEdited.name,
                  );
                }
                onUpdate(
                  _data.id,
                  { ...toBeEdited },
                  _data,
                  findId ? +findId.id : 0,
                );
                setToBeEdited(undefined);
                toggleEdit && toggleEdit();
              } else setFieldNameError(words.customFieldRequired);
            },
            cancelCallback: () => {
              setToBeEdited(undefined);
              setFieldNameError('');
              toggleEdit && toggleEdit();
            },
          }),
        });
        // if no special function but type is = active
      } else if (type === 'active') {
        tableData.push({
          id: `${_data.id}`,
          name: <TextInputWrapper width={216}>{_data.name}</TextInputWrapper>,
          dataType: (
            <DropdownWrapper width={132}>
              {DATA_TYPE_OPTIONS[_data.dataType]?.label}
            </DropdownWrapper>
          ),
          index:
            !_data.isDefault && _data.dataType === 'decimal'
              ? renderIndex(!!_data.isIndex, _data.id)
              : undefined,
          isReorderable: !_data.isDefault,
          action: renderActions({
            id: _data.id,
            isDefault: _data.isDefault,
            isIndex: !!_data.isIndex,
            isDuplicated:
              checkDuplicatedColumn(_data.id) || _data.dataType === 'user-info',
            duplicateCallback: () => {
              onCancelAdd && onCancelAdd();
              setToBeAdded(undefined);
              setToBeEdited(undefined);
              setFieldNameError(words.fieldNameAlreadyTaken);
              setToBeDuplicated({
                id: _data.id,
                name: _data.name,
                dataType: _data.dataType,
                orderId: 0,
              });
            },
            disableCallback: () => {
              onDisable(_data.id);
              setToBeDisabledId(undefined);
            },
          }),
        });
        // if no special function but type is = inactive
      } else if (type === 'inactive') {
        return tableData.push({
          id: `${_data.id}`,
          name: <TextInputWrapper width={216}>{_data.name}</TextInputWrapper>,
          dataType: (
            <DropdownWrapper width={132}>
              {DATA_TYPE_OPTIONS[_data.dataType]?.label}
            </DropdownWrapper>
          ),
          action: renderActions({
            id: _data.id,
            activateCallback: () => {
              !isMaxFields && onActivate(_data.id);
              setToBeActivatedId(_data.id);
            },
            deleteCallback: () => {
              onDelete(_data.id);
            },
          }),
        });
        // if no special function but type is = default
      } else if (type === 'default') {
        // default
        tableData.push({
          id: `${_data.id}`,
          name: (
            <TextInputWrapper width={type === 'default' ? 280 : 216}>
              {_data.name}
            </TextInputWrapper>
          ),
          dataType: (
            <DropdownWrapper width={type === 'default' ? 280 : 132}>
              {DATA_TYPE_OPTIONS[_data.dataType]?.label}
            </DropdownWrapper>
          ),
          index:
            !_data.isDefault && _data.dataType === 'decimal'
              ? renderIndex(!!_data.isIndex, _data.id)
              : undefined,
          isReorderable: !_data.isDefault,
          action: renderActions({
            id: _data.id,
            isDefault: _data.isDefault,
            isIndex: !!_data.isIndex,
            editCallback: () => {
              if (!!toBeAdded) {
                onCancelAdd && onCancelAdd();
              }
              if (!isEditing) {
                toggleEdit && toggleEdit();
              }
              setFieldNameError('');
              setToBeDuplicated(undefined);
              setToBeEdited({
                ..._data,
              });
            },
            deleteCallback: () => {
              onDelete(_data.id);
            },
          }),
        });
      }

      // if a row has been duplicated
      if (toBeDuplicated && toBeDuplicated.id === _data.id) {
        tableData.push({
          id: `${_data.id}-duplicate`,
          name:
            toBeDuplicated.dataType !== 'user-info'
              ? renderTextInput(
                  `edit-duplicated-field-${_data.id}`,
                  toBeDuplicated.name,
                  (value: string) => {
                    setFieldNameError(words.fieldNameAlreadyTaken);
                    setToBeDuplicated({ ...toBeDuplicated, name: value });
                  },
                )
              : renderDropdownInput(
                  `edit-duplicated-dropdownField`,
                  basicInfoFields,
                  (selectedItem: { id: string; label: string }) => {
                    setFieldNameError(words.fieldNameAlreadyTaken);
                    setToBeDuplicated({
                      ...toBeDuplicated,
                      name: selectedItem.label,
                    });
                  },
                  {
                    id: toBeDuplicated.name.replace(' ', '-'),
                    label: toBeDuplicated.name,
                  },
                ),
          dataType: renderDropdown(
            `duplicate-data-type-${_data.id}`,
            (selectedItem: { id: 'decimal' | 'user-info'; label: string }) => {
              setToBeDuplicated({
                ...toBeDuplicated,
                dataType: selectedItem.id,
              });
            },
            DATA_TYPE_OPTIONS[toBeDuplicated.dataType],
            true,
          ),
          action: renderActions({
            saveCallback: ev => {
              // prevents weird interaction with tooltip onblur https://stackoverflow.com/questions/17769005/onclick-and-onblur-ordering-issue/57630197#57630197
              ev.stopPropagation();
              let findId;
              if (toBeDuplicated.dataType === 'user-info') {
                findId = basicInfoFields?.find(
                  x => x.name === toBeDuplicated.name,
                );
              }
              const payload = {
                ...toBeDuplicated,
                id: _data.id,
                memberFieldLookupId: findId ? +findId.id : 0,
              };
              onDuplicate(payload);
              setToBeDuplicated(undefined);
            },
            cancelCallback: () => {
              setToBeDuplicated(undefined);
              setFieldNameError('');
            },
          }),
        });
      }
    });

    if (toBeAdded) {
      tableData.push({
        id: `+1`,
        dataType: renderDropdown(
          `data-type-default`,
          (selectedItem: { id: 'decimal' | 'user-info'; label: string }) => {
            setToBeAdded({
              ...toBeAdded,
              dataType: selectedItem.id,
            });
          },
          toBeAdded.dataType
            ? DATA_TYPE_OPTIONS[toBeAdded.dataType]
            : undefined,
        ),
        name:
          toBeAdded.dataType !== 'user-info'
            ? renderTextInput(
                `add-field`,
                toBeAdded.name,
                (value: string) => {
                  setToBeAdded({ ...toBeAdded, name: value });
                },
                prevRowAdded,
                toBeAdded,
              )
            : renderDropdownInput(
                `basic-info-dropdown`,
                basicInfoFields,
                (selectedItem: { id: string; label: string }) => {
                  setToBeAdded({
                    ...toBeAdded,
                    name: selectedItem.label,
                  });
                },
                undefined,
                prevRowAdded,
                toBeAdded,
              ),
        action: renderActions({
          saveCallback: ev => {
            // prevents weird interaction with tooltip onblur https://stackoverflow.com/questions/17769005/onclick-and-onblur-ordering-issue/57630197#57630197
            ev.stopPropagation();
            const isNameUsed = initialData.find(
              data => data.name === toBeAdded?.name,
            );
            if (toBeAdded?.dataType && toBeAdded?.name && !isNameUsed) {
              const findId = basicInfoFields?.find(
                x => x.name === toBeAdded.name,
              );
              if (
                !(
                  prevRowAdded.name === toBeAdded.name &&
                  prevRowAdded.dataType !== toBeAdded.dataType
                )
              ) {
                onCreate({
                  dataType: toBeAdded.dataType,
                  name: toBeAdded.name,
                  memberFieldLookupId: findId ? +findId.id : 0,
                  orderId: 0,
                });
                setFieldNameError('');
              }
              if (
                //if name is entered before dataType
                prevRowAdded.dataType === undefined &&
                prevRowAdded.name === toBeAdded.name &&
                toBeAdded.dataType === 'decimal'
              ) {
                onCreate({
                  dataType: toBeAdded.dataType,
                  name: toBeAdded.name,
                  memberFieldLookupId: findId ? +findId.id : 0,
                  orderId: 0,
                });
                setFieldNameError('');
              } else {
                setFieldNameError(words.customFieldRequired);
              }
              if (
                prevRowAdded.name === toBeAdded.name &&
                prevRowAdded.dataType === 'user-info' &&
                toBeAdded.dataType === 'decimal'
              ) {
                setFieldNameError(words.customFieldRequired);
              }
            } else if (
              toBeAdded.name !== '' &&
              toBeAdded.dataType === 'decimal'
            ) {
              setFieldNameError(words.fieldNameAlreadyTaken);
            } else setFieldNameError(words.customFieldRequired);
          },
          cancelCallback: () => {
            if (onCancelAdd) {
              onCancelAdd();
            }
            setToBeAdded(undefined);
            setFieldNameError('');
          },
        }),
      });
    }
    return tableData.map((ret, index) => ({
      ...ret,
      number: (
        <NumberCell>
          <div />
          {index + 1}
        </NumberCell>
      ),
    }));
  };

  const duplicateIndex = useMemo(() => {
    const index = initialData.findIndex(
      _data => toBeDuplicated && _data.id === toBeDuplicated.id,
    );
    return index > -1 ? index + 2 : -1;
  }, [initialData, toBeDuplicated]);
  const editIndex = useMemo(() => {
    const index = initialData.findIndex(
      _data => toBeEdited && _data.id === toBeEdited.id,
    );
    const hasEditableRow = index > -1;
    if (!hasEditableRow) {
      return -1;
    }
    const hasRowToDuplicate = duplicateIndex > -1;
    const isEditBeforeDuplicate = index < duplicateIndex;
    if (!hasRowToDuplicate || isEditBeforeDuplicate) {
      return index + 1;
    }
    return index + 2;
  }, [initialData, toBeEdited, duplicateIndex]);
  const isDragDisabled =
    (initialData.length === 4 && initialData[3].dataType === 'user-info') ||
    (initialData.length === 5 &&
      initialData[3].dataType === 'decimal' &&
      initialData[3].isIndex === true)
      ? true
      : false;
  const indexObject = initialData.find(x => x.isIndex === true);
  return isLoading ? (
    <CustomDataTableSkeletonContainer>
      <CustomDataTableSkeleton
        showToolbar={false}
        showHeader={false}
        headers={type === 'inactive' ? INACTIVE_HEADERS : ACTIVE_HEADERS}
        columnCount={
          type === 'inactive' ? INACTIVE_HEADERS.length : ACTIVE_HEADERS.length
        }
        className={className}
        rowCount={0}
      />
      <TableLoader />
    </CustomDataTableSkeletonContainer>
  ) : (
    <Wrapper
      isAddingField={!!toBeAdded}
      editRow={!!toBeAdded ? -1 : editIndex}
      duplicateRow={duplicateIndex}
      className={className}>
      <GlobalStyle />
      <StyledTable
        rows={renderRowData()}
        headers={type === 'inactive' ? INACTIVE_HEADERS : ACTIVE_HEADERS}
        emptyDataLabelTitle={emptyDataLabelTitle}
        emptyDataLabelDetails={emptyDataLabelDetails}
        onDrag={onDrag}
        disableDrag={
          toBeAdded !== undefined ||
          editIndex !== -1 ||
          duplicateIndex !== -1 ||
          isDragDisabled ||
          !isDraggable
            ? true
            : false
        }
        indexObject={indexObject}
      />
    </Wrapper>
  );
};

export default Component;
