import { createContext, useContext } from 'react';
import { URLSearchParams } from 'url';

export type NavigationHooks = {
  useNavigation: () => {
    getUrlQuery: () => URLSearchParams;
  };
};

export const NavigationHooksContext = createContext<NavigationHooks | null>(
  null,
);

export const useNavigationHooks = (): NavigationHooks => {
  const context = useContext(NavigationHooksContext);
  if (!context) {
    throw new Error(`Context not instantiated`);
  }
  return context;
};
