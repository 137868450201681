import LoginFieldsRepository from '../../usecases/ports/LoginFieldsRepository';
import { HttpAdapter } from '../../usecases/ports/HttpAdapter';
import { PaginationQuery } from '../../domain/entities/request';
import {
  CreateLoginFieldProps,
  FetchLoginFieldProps,
  LoginField,
  UpdateLoginFieldProps,
} from '../../domain/entities/field';

export default class LoginFieldRepositoryImpl implements LoginFieldsRepository {
  httpAdapter: HttpAdapter;
  urls: { [key: string]: (query: { [key: string]: string }) => string };

  constructor(
    httpAdapter: HttpAdapter,
    urls: { [key: string]: (query: { [key: string]: string }) => string },
  ) {
    this.httpAdapter = httpAdapter;
    this.urls = urls;
  }

  find = async (
    query: PaginationQuery & { companyId: string },
  ): Promise<LoginField[]> => {
    const { companyId } = query;
    const response = await this.httpAdapter.get(
      this.urls.getFields({ companyId }),
      {
        params: query,
      },
    );
    const loginFields = response.data;
    return loginFields;
  };

  fetch = async (query: {
    companyId: number;
  }): Promise<FetchLoginFieldProps[]> => {
    const { companyId } = query;
    const response = await this.httpAdapter.get(
      this.urls.getFields({ companyId: `${companyId}` }),
      {
        params: query,
      },
    );
    return response.data;
  };

  insert = async (
    { companyId }: { companyId: number },
    data: CreateLoginFieldProps,
  ): Promise<LoginField> => {
    const response = await this.httpAdapter.post(
      this.urls.createField({ companyId: `${companyId}` }),
      data,
    );

    return response;
  };

  update = async (
    payload: { companyId: number; fieldId: number },
    data: UpdateLoginFieldProps,
  ): Promise<LoginField> => {
    const { companyId, fieldId } = payload;
    const response = await this.httpAdapter.patch(
      this.urls.updateField({
        companyId: `${companyId}`,
        fieldId: `${fieldId}`,
      }),
      data,
    );
    return response.data;
  };

  delete = async (payload: {
    companyId: number;
    fieldId: number;
  }): Promise<void> => {
    await this.httpAdapter.delete(
      this.urls.deleteField({
        companyId: `${payload.companyId}`,
        fieldId: `${payload.fieldId}`,
      }),
      {},
    );
  };

  async checkDuplicateFieldName(query: {
    companyId: number;
    fieldName: string;
  }): Promise<FetchLoginFieldProps> {
    const { companyId, fieldName } = query;
    const response = await this.httpAdapter.head(
      this.urls.checkDuplicateFieldName({
        companyId: `${companyId}`,
        fieldName: `${fieldName}`,
      }),
      {
        params: query,
      },
    );
    return response;
  }
}
