import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import './yearSwiper.scss';

const YearSwiper = () => {
  return (
    <Swiper className="yearSwiper">
      <SwiperSlide>
        <span>2022年</span>
      </SwiperSlide>
      <SwiperSlide>
        <span>2021年</span>
      </SwiperSlide>
      <SwiperSlide>
        <span>2020年</span>
      </SwiperSlide>
      <SwiperSlide>
        <span>2019年</span>
      </SwiperSlide>
      <SwiperSlide>
        <span>2018年</span>
      </SwiperSlide>
      <SwiperSlide>
        <span>2017年</span>
      </SwiperSlide>
      <SwiperSlide>
        <span>2016年</span>
      </SwiperSlide>
      <SwiperSlide>
        <span>2015年</span>
      </SwiperSlide>
      <SwiperSlide>
        <span>2014年</span>
      </SwiperSlide>
    </Swiper>
  );
};

export default YearSwiper;
