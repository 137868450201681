import React, { useState } from 'react';
import './memberItem.scss';
import DefaultAvatar from 'assets/icons/davatar.png';
import Option3Dots from 'assets/icons/optionThreeDot.svg';
import EditMember from 'assets/icons/edit-member.svg';
import DeleteMember from 'assets/icons/delete-member.svg';
import ApproveIcon from 'assets/icons/approve-icon.svg';
import RejectIcon from 'assets/icons/reject-icon.svg';
import { memberGroupType } from 'type/member';
import { cutString, getCurrentUser, getRoleName } from 'utils/common';
import words from 'constants/words';

type PropType = {
  userId: number;
  onHandleUpdateProfile: (_data: number) => void;
  onHandleApproveMember: (_data: number) => void;
  onHandleRejectMember: (_data: number) => void;
  onHandleDeleteMember: (_data: number) => void;
  member: memberGroupType;
};

export default function MemberItem(props: PropType) {
  const {
    userId,
    onHandleUpdateProfile,
    member,
    onHandleApproveMember,
    onHandleRejectMember,
    onHandleDeleteMember,
  } = props;

  const user = getCurrentUser();

  function resetModal() {
    setIsShowOption(false);
    setIsShowStatusOption(false);
  }

  function handleDelete() {
    if (member.is_request_change) {
      onHandleRejectMember(userId);
    } else {
      onHandleDeleteMember(userId);
    }
    setIsShowOption(false);
  }

  const [isShowOption, setIsShowOption] = useState<boolean>(false);
  const [isShowStatusOption, setIsShowStatusOption] = useState<boolean>(false);

  return (
    <div className="member-item-page">
      <div className="member-item">
        <div className="avatar">
          <img
            src={
              member && member.photo
                ? process.env.REACT_APP_S3_FRONT + member.photo
                : DefaultAvatar
            }
          />
        </div>
        <div className="member-info">
          <p className="full-name">
            {member && member.full_name ? (
              cutString(member.full_name, 5)
            ) : (
              <span className="no-name">{words.no_name}</span>
            )}
          </p>
          <p className="position">
            {member && member.role && getRoleName(member.role)}
          </p>
        </div>
        <div className="member-group">
          <p className="group-name">
            {member && member.group && cutString(member.group.name, 10)}
          </p>
        </div>
        {member &&
        member.is_request_change &&
        user.id.toString() !== member.id.toString() ? (
          <div className="member-status">
            <p
              className="accept-status"
              onClick={() => {
                resetModal();
                setIsShowStatusOption(!isShowStatusOption);
              }}>
              承認待ち
            </p>
          </div>
        ) : (
          <div className="member-status"></div>
        )}

        <div
          className="member-option"
          onClick={() => {
            resetModal();
            setIsShowOption(!isShowOption);
          }}>
          <img src={Option3Dots} />
        </div>
      </div>
      <div
        className="append-option-member"
        style={{ display: isShowOption ? 'flex' : 'none' }}>
        <div
          className="edit-member"
          onClick={() => {
            onHandleUpdateProfile(userId);
            setIsShowOption(false);
          }}>
          編集 <img src={EditMember} />
        </div>
        <div className="delete-member" onClick={handleDelete}>
          削除 <img src={DeleteMember} />
        </div>
      </div>
      <div
        className="append-status-member"
        style={{ display: isShowStatusOption ? 'flex' : 'none' }}>
        <div
          className="edit-member"
          onClick={() => {
            onHandleApproveMember(userId);
            setIsShowStatusOption(false);
          }}>
          承認 <img src={ApproveIcon} />
        </div>
        <div
          className="delete-member"
          onClick={() => {
            onHandleRejectMember(userId);
            setIsShowStatusOption(false);
          }}>
          不許可 <img src={RejectIcon} />
        </div>
      </div>
    </div>
  );
}
