import React from 'react';
import './member-top-popup.scss';
import UserImg from 'assets/icons/user-ft-active.svg';
import SignOutImg from 'assets/icons/sign-out.svg';
import words from 'constants/words';

type PropType = {
  onClick: (_type: number) => void;
  display: boolean;
};

export default function MemberTopPopUp(props: PropType) {
  const { display, onClick } = props;
  return (
    <div className={`member-top-popup ${display ? 'show' : 'hidden'}`}>
      <p onClick={() => onClick(1)}>
        <img src={UserImg} />
        {words.myPageMenu}
      </p>
      <p onClick={() => onClick(2)}>
        <img src={SignOutImg} />
        {words.logout}
      </p>
    </div>
  );
}
