import React from 'react';
import styled from 'styled-components';
import { theme } from '../../../config';
import Select, { GroupBase, PropsValue, StylesConfig } from 'react-select';
import { SelectComponents } from 'react-select/dist/declarations/src/components';
import { WarningFilled32 } from '@carbon/icons-react';
import words, { languageUsed } from '../../../constants/words';

const StyledLabelDiv = styled.div<IStyledMultiSelect>`
  margin-bottom: 0.5em;
  font-size: 0.75rem;
  span {
    color: ${props =>
      props.isDisabled ? theme.colors.disabled02 : theme.colors.fontColor};
  }
`;

const StyledErrorMessage = styled.div<{
  $languageUsed: string;
}>`
  font-size: 0.75rem;
  margin-top: 4px;
  color: ${theme.colors.errorColor};
  width: ${({ $languageUsed }) => ($languageUsed === 'en' ? '81%' : '90%')};
  word-break: keep-all;
`;

const StyledIcons = styled(WarningFilled32)`
  width: 15px;
  position: absolute;
  right: 55px;
  top: 40px;
  fill: ${theme.colors.errorColor};
`;

const StyledMultiSelect = styled(Select)<IStyledMultiSelect>`
  .css-1insrsq-control {
    background-color: ${props =>
      props.isDisabled ? theme.colors.disabledButton1 : theme.colors.ui02};
  }
  .css-1s2u09g-control {
    border: ${props => (!props.isError ? `1px` : `2px`)} solid
      ${props =>
        !props.isError
          ? theme.colors.inputBorderColor
          : theme.colors.errorColor};
    border-radius: 8px;
  }
  div.css-1n7v3ny-option .labelContainer,
  div.css-yt9ioa-option .labelContainer {
    border-bottom: 1px solid ${theme.colors.ui03};
    padding-top: 11px;
    padding-bottom: 11px;
    margin-left: 15px;
  }
  .css-1pahdxg-control:hover {
    border: 1px solid ${theme.colors.inputBorderColor};
  }
  .css-xb97g8 {
    display: ${props => (props.hasXIcon ? 'inherit' : 'none')};
  }
  .css-xb97g8:hover {
    background-color: transparent;
    color: ${theme.colors.white};
    cursor: pointer;
  }
  .css-1pahdxg-control {
    box-shadow: unset;
    border-color: ${theme.colors.inputBorderColor};
  }
  .css-1rhbuit-multiValue {
    background-color: ${props =>
      props.isDisabled ? theme.colors.disabled01 : theme.colors.chipColor};
    border-radius: 20px;
  }
  .css-12jo7m5,
  .css-1rhbuit-multiValue {
    color: ${props =>
      props.isDisabled ? theme.colors.disabled02 : theme.colors.white};
  }
  .css-12jo7m5 {
    font-size: 14px;
    padding: ${props => (props.hasXIcon ? '3px' : '4px')};
    padding-left: ${props => (props.hasXIcon ? '6px' : '8px')};
    padding-right: ${props => (props.hasXIcon ? 'none' : '8px')};
  }
  .css-1okebmr-indicatorSeparator {
    display: none;
  }
  .css-1n7v3ny-option {
    background-color: ${theme.colors.multiselectOptionBg};

    div {
      color: ${theme.colors.text01};
    }
  }
  .css-1n7v3ny-option:active {
    background-color: ${theme.colors.multiselectOptionBg};
  }
  &&&& {
    .css-yt9ioa-option,
    .css-1n7v3ny-option {
      color: ${theme.colors.fontColor};
      font-size: 13px;
      margin-right: 0px;
      margin-left: 0px;
      padding-left: 0px;
      cursor: pointer;
      padding: 0px;
    }
  }
  &&&& {
    div.css-yt9ioa-option:last-child .labelContainer,
    div.css-1n7v3ny-option:last-child .labelContainer {
      border-bottom: unset;
    }
  }
  .css-4ljt47-MenuList {
    padding: 0px;
    margin-right: 15px;
  }

  .css-26l3qy-menu {
    margin: 0px;
    border-radius: unset;
    background-color: ${theme.colors.dropDownContainerBgColor};
  }
`;

type IStyledMultiSelect = {
  isError?: boolean;
  isDisabled?: boolean;
  hasXIcon?: boolean;
};

export type IOptions = {
  value: string;
  label: string;
};

export type IMultiSelectDropdown = {
  isMulti?: boolean;
  isClearable?: boolean;
  isDisabled?: boolean;
  placeholder?: string;
  label?: string;
  errorMessage?: string;
  isError?: boolean;
  defaultValue?: PropsValue<IOptions>;
  isLoading?: boolean;
  options: IOptions[];
  value?: IOptions[];
  onChange: (selectedList: unknown) => void;
  components?: Partial<SelectComponents<unknown, boolean, GroupBase<unknown>>>;
  className?: string;
  hasXIcon?: boolean;
  withLabel?: boolean;
  noOptionsText?: string;
  styles?: StylesConfig;
};

const Component = ({
  onChange,
  isMulti = true,
  isClearable = false,
  isDisabled = false,
  isError = false,
  isLoading = false,
  placeholder = '',
  label = '',
  defaultValue,
  errorMessage = '',
  options,
  value,
  components,
  withLabel = true,
  className,
  hasXIcon = true,
  noOptionsText = words.noOptions,
  styles,
  ...props
}: IMultiSelectDropdown): React.ReactElement => {
  const formatOptionLabel = ({ label }: IOptions) => (
    <div className="labelContainer">
      <span>{label}</span>
    </div>
  );

  return (
    <>
      {withLabel && (
        <StyledLabelDiv isDisabled={isDisabled}>
          <span>{label}</span>
        </StyledLabelDiv>
      )}

      <StyledMultiSelect
        defaultValue={defaultValue}
        options={options}
        isMulti={isMulti}
        isClearable={isClearable}
        onChange={onChange}
        isDisabled={isDisabled}
        placeholder={placeholder}
        isError={isError}
        isLoading={isLoading}
        value={value}
        formatOptionLabel={formatOptionLabel as any}
        components={components}
        className={className}
        hasXIcon={hasXIcon}
        noOptionsMessage={() => noOptionsText}
        styles={styles}
        {...props}
      />

      {isError ? (
        <>
          <StyledIcons className="errorIcon" />
          <StyledErrorMessage
            $languageUsed={languageUsed}
            className="errorLabel">
            <span>{errorMessage}</span>
          </StyledErrorMessage>
        </>
      ) : (
        ''
      )}
    </>
  );
};

export default Component;
