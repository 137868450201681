import React from 'react';

type Props = {
  [key: string]: string | number | Function;
};

const Icon = (props: Props): React.ReactElement => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 1C4.2 1 1 4.2 1 8C1 11.8 4.2 15 8 15C11.8 15 15 11.9 15 8C15 4.1 11.9 1 8 1ZM7.5 4H8.5V9H7.5V4ZM8 12.2C7.6 12.2 7.2 11.8 7.2 11.4C7.2 11 7.5 10.6 8 10.6C8.4 10.6 8.8 11 8.8 11.4C8.8 11.8 8.4 12.2 8 12.2Z"
        fill="#DA1E28"
      />
      <path
        opacity="0.01"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.49922 4H8.49922V9H7.49922V4ZM7.99922 12.2C7.59922 12.2 7.19922 11.8 7.19922 11.4C7.19922 11 7.49922 10.6 7.99922 10.6C8.39922 10.6 8.79922 11 8.79922 11.4C8.79922 11.8 8.39922 12.2 7.99922 12.2Z"
        fill="#DA1E28"
      />
    </svg>
  );
};

export default Icon;
