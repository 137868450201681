import React from 'react';

type Props = {
  [key: string]: string | number | Function;
};

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="49"
      height="62"
      viewBox="0 0 49 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M9.5 6.44512C9.5 4.78827 10.8431 3.44512 12.5 3.44512H36.7714C37.5587 3.44512 38.3145 3.75464 38.8757 4.30689L43.5 8.85776L47.6043 12.8968C48.1773 13.4608 48.5 14.2311 48.5 15.0351V56.5397C48.5 58.1966 47.1569 59.5397 45.5 59.5397H12.5C10.8431 59.5397 9.5 58.1966 9.5 56.5397V6.44512Z"
        fill="#F5E7D8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.325 16.1648L28.575 0.665005C28.1739 0.227635 27.5984 -0.0150864 27 0.000727291H4.5C2.01472 0.000727291 0 1.98344 0 4.42925V57.5715C0 60.0173 2.01472 62 4.5 62H40.5C42.9853 62 45 60.0173 45 57.5715V17.7148C45.0161 17.1259 44.7694 16.5595 44.325 16.1648ZM27 5.31485L39.6 17.7147H27V5.31485ZM4.5 4.42924V57.5715H40.5V22.1433H27C24.5147 22.1433 22.5 20.1606 22.5 17.7148V4.42924H4.5Z"
        fill="#3B1F34"
      />
      <path d="M31.5 32.5L27 28L12.5 42.5V47H17L31.5 32.5Z" fill="#FF714F" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.25 51.326H36.2164V53.2521H9.25V51.326ZM31.7958 33.9904C32.1575 33.6292 32.3607 33.1389 32.3607 32.6277C32.3607 32.1165 32.1575 31.6262 31.7958 31.2649L28.348 27.8171C27.9867 27.4554 27.4964 27.2521 26.9852 27.2521C26.474 27.2521 25.9837 27.4554 25.6224 27.8171L11.1762 42.2633V48.4367H17.3495L31.7958 33.9904ZM26.9804 29.175L30.4379 32.6325L27.5486 35.5217L24.0912 32.0643L26.9804 29.175ZM13.1023 46.5106V43.0531L22.7332 33.4222L26.1907 36.8797L16.5598 46.5106H13.1023Z"
        fill="#3B1F34"
      />
    </svg>
  );
};

export default Component;
