import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import RootNavigator from './navigators/root/RootNavigator';
import * as serviceWorker from './serviceWorker';
import Hooks from './hooks';

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider
      client={
        new QueryClient({
          defaultOptions: {
            queries: {
              retry: 0,
              suspense: false,
              refetchOnReconnect: false,
              refetchOnWindowFocus: false,
              refetchOnMount: true,
            },
          },
        })
      }>
      <Hooks>
        <RootNavigator />
      </Hooks>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
