import React from 'react';
import { theme } from '../../../config';

type Props = {
  isActive?: boolean;
};

const Component = ({ isActive = false }: Props): React.ReactElement => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.875 17.5V3.75H11.875V17.5H9.375V8.75H4.375V17.5H2.5V1.25H1.25V17.5C1.25 18.1904 1.80964 18.75 2.5 18.75H18.75V17.5H16.875ZM8.125 17.5H5.625V10H8.125V17.5ZM15.625 17.5H13.125V5H15.625V17.5Z"
        fill={isActive ? theme.colors.primaryButton1 : theme.colors.fontColor}
      />
    </svg>
  );
};

export default Component;
