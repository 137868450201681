import React from 'react';
import styled from 'styled-components';
import { Props as INotification } from './Notification';
import { Notification } from '.';

export const ToastWrapper = styled.div<{ $zIndex: number }>`
  position: fixed;
  top: 0;
  right: 0;
  z-index: ${({ $zIndex }) => $zIndex};
`;

export type Props = {
  notification: INotification;
  className?: string;
  zIndex?: number;
};

const Component = ({
  notification,
  className,
  zIndex = 10,
}: Props): React.ReactElement => {
  return (
    <ToastWrapper className={className} $zIndex={zIndex}>
      <Notification {...notification} />
    </ToastWrapper>
  );
};

export default Component;
