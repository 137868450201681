import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { InlineNotification } from 'carbon-components-react';
import { useMutation } from 'react-query';
import { theme } from '../../../config';
import words from '../../../constants/words';
import { useMemberHooks } from '../../../hooks/member';
import { useGlobalState } from '../../../hooks/global';
import { Modal } from '../../molecules/Modal';
import { Table } from '../../organisms/Table';
import { Checkbox } from '../../atoms/Checkbox';
import { Props as INotification } from '../../molecules/Notification/Notification';

const StyledModal = styled(Modal)`
  .bx--modal-header__heading {
    white-space: pre-wrap;
  }
`;

const FilteredMembersWrapper = styled.div`
  button {
    display: none;
  }

  .bx--inline-notification__title,
  .bx--inline-notification__subtitle {
    color: ${theme.colors.text01};
  }

  .bx--inline-notification--error {
    background-color: ${theme.colors.notificationErrorBackgroundColor};
    border: none;
  }
  .bx--inline-notification__subtitle {
    font-size: 13px;
    color: ${theme.colors.text02};
  }
  color: ${theme.colors.text02};

  .bx--inline-notification__details svg {
    margin-top: auto;
    margin-bottom: auto;
  }
  .bx--inline-notification__text-wrapper {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1em 0;

  .bx--checkbox:focus + .bx--checkbox-label::before {
    outline: none;
  }
`;

const ListWrapper = styled.div`
  height: 200px;
  overflow-y: auto;
`;

const StyledTable = styled(Table)`
  thead {
    display: none;
  }

  tbody tr td:first-child {
    width: 2%;
  }
`;

const WarningMessageWrapper = styled.div`
  font-size: 13px;
`;

const WarningMessage = styled.div`
  padding: 25px 0 0 15px;
  margin-bottom: -10px;
`;

export const HEADERS = [
  {
    key: 'id',
    header: '',
  },
  {
    key: 'name',
    header: '',
  },
];

type MemberToDelete = {
  id: string;
  name: string;
};

export type BulkDeleteMembersModalProps = {
  open: boolean;
  memberList: MemberToDelete[];
  onClose: () => void;
  onCancel: () => void;
  onSubmit: (isSuccessful: boolean, toast: INotification) => void;
};

const ModalComponent = ({
  open,
  onClose,
  onCancel,
  memberList,
  onSubmit,
}: BulkDeleteMembersModalProps): React.ReactElement => {
  const {
    useCurrentUser: { currentUser },
  } = useGlobalState();
  const [isChecked, setIsChecked] = useState(false);
  const { useFilterDeletableMembers, useBatchDeleteMembers } = useMemberHooks();
  const { filterDeletableMembers } = useFilterDeletableMembers();
  const [unDeletableMemberIds, setUnDeletableMemberIds] = useState<string[]>(
    [],
  );
  const { batchDeleteMembers } = useBatchDeleteMembers();

  useEffect(() => {
    if (open && currentUser?.companyId) {
      const memberIdsToDelete = memberList.map(item => item.id);
      const memberIdsToDeleteStr = memberIdsToDelete.join(',');
      filterMembersToDelete({
        companyId: currentUser?.companyId,
        memberIds: memberIdsToDeleteStr,
      });
    }
  }, [open]);

  const { mutate: filterMembersToDelete, isLoading } = useMutation(
    ({ companyId, memberIds }: { companyId: number; memberIds: string }) => {
      return filterDeletableMembers(companyId, memberIds);
    },
    {
      onSuccess: resp => setUnDeletableMemberIds(resp),
      onError: err => console.log({ err }),
    },
  );

  const { mutate: deleteMembers } = useMutation(
    ({ companyId, memberIds }: { companyId: number; memberIds: string }) => {
      return batchDeleteMembers(companyId, memberIds);
    },
    {
      onSuccess: () => {
        onSubmit(true, {
          kind: 'success',
          title: words.bulkDeleteSuccess,
          subtitle: words.bulkDeleteSuccessMsg,
        });
        onCloseModal();
      },
      onError: () => {
        onSubmit(false, {
          kind: 'error',
          title: words.updateToastTitleError,
          subtitle: words.errorOccuredToastSub,
        });
        onCloseModal();
      },
    },
  );

  const onConfirmDeleteMembers = () => {
    const deletableIds = memberList
      .map(item => `${item.id}`)
      .filter(item => !unDeletableMemberIds.includes(item))
      .join(',');
    if (currentUser?.companyId && deletableIds !== '') {
      deleteMembers({
        companyId: currentUser?.companyId,
        memberIds: deletableIds,
      });
    } else {
      onCloseModal();
    }
  };

  const onToggleCheckbox = (event: boolean) => setIsChecked(event);

  const renderData = () => {
    const formattedData: MemberToDelete[] = [];
    memberList.forEach((item, index) => {
      formattedData.push({
        id: `${index + 1}`,
        name: item.name,
      });
    });

    return formattedData;
  };

  const formatUndeletableMemberNames = () => {
    const formattedList = memberList.reduce(
      (newObj: { [key: string]: MemberToDelete }, item: MemberToDelete) => {
        newObj[item.id] = item;
        return newObj;
      },
      {},
    );
    const undeletableMemberNames = unDeletableMemberIds.map(
      item => formattedList[item].name,
    );
    return undeletableMemberNames.join(', ');
  };

  const onCloseModal = () => {
    onClose();
    setIsChecked(false);
    setUnDeletableMemberIds([]);
  };

  return (
    <StyledModal
      label={words.bulkDeleteMembersModalSubtitle}
      open={open}
      title={words.bulkDeleteMembersModalConfirmation}
      confirmButtonText={words.deleteField}
      confirmButtonTheme="secondary"
      hasScrollingContent={true}
      confirmButtonDisabled={
        isLoading || (unDeletableMemberIds.length > 0 && !isChecked)
      }
      onConfirm={onConfirmDeleteMembers}
      onClose={onCloseModal}
      onCancel={onCloseModal}>
      <ContentWrapper>
        <ListWrapper>
          <StyledTable rows={renderData()} headers={HEADERS} />
        </ListWrapper>
        {unDeletableMemberIds.length > 0 ? (
          <WarningMessageWrapper>
            <FilteredMembersWrapper>
              <WarningMessage>{words.cannotDeleteMembers}</WarningMessage>
              <InlineNotification
                kind="error"
                title=""
                subtitle={formatUndeletableMemberNames()}
                className="filtered-names-list"
              />
            </FilteredMembersWrapper>
            <Checkbox
              id="checkbox"
              isChecked={isChecked}
              onChange={onToggleCheckbox}
              label={words.bulkDeleteCheckboxLabel}
            />
          </WarningMessageWrapper>
        ) : null}
      </ContentWrapper>
    </StyledModal>
  );
};

export default ModalComponent;
