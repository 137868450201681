import MemberRepository from '../../ports/MemberRepository';

export type deleteMemberUseCase = (payload: {
  companyId: number;
  memberId: number;
}) => Promise<void>;

export const buildDeleteMember = (dependencies: {
  memberRepo: MemberRepository;
}): deleteMemberUseCase => {
  const { memberRepo } = dependencies;

  const deleteMember: deleteMemberUseCase = async payload =>
    memberRepo.delete(payload);

  return deleteMember;
};
