import React, { useEffect, useState } from 'react';
import './groupItem.scss';
import Option3Dots from 'assets/icons/optionThreeDot.svg';
import { groupType } from 'type/member';
import axios from 'axios';
import urls from 'constants/urls';
import authHeader from 'api/auth-header';
import { connectStringWithComma, cutString } from 'utils/common';
import EditMember from 'assets/icons/edit-member.svg';
import DeleteMember from 'assets/icons/delete-member.svg';

type PropType = {
  group: groupType;
  handleDeleteGroup: (_group_id: string) => void;
  handleUpdateGroup: (_group_id: string) => void;
  handleChooseGroup: (_group_id: string, _group_name: string) => void;
};

export default function GroupItem(props: PropType) {
  const {
    group,
    handleDeleteGroup,
    handleChooseGroup,
    handleUpdateGroup,
  } = props;
  const [count, setCount] = useState(0);
  const [approverList, setApproverList] = useState('');
  const [isShowOption, setIsShowOption] = useState(false);

  useEffect(() => {
    if (group) {
      const data = {
        group_id: group.id ? group.id : 0,
      };
      axios
        .post(urls.group_approver_details, data, { headers: authHeader() })
        .then(res => {
          setCount(res.data.data.count);
          setApproverList(
            cutString(connectStringWithComma(res.data.data.approver), 10),
          );
        })
        .catch();
    }
  }, [group]);

  function handleDelete() {
    handleDeleteGroup(group.id);
    setIsShowOption(false);
  }

  function handleUpdate() {
    handleUpdateGroup(group.id);
    setIsShowOption(false);
  }

  function chooseGroup() {
    handleChooseGroup(group.id, group.name);
  }

  return (
    <div className="group-item-page">
      <div className="basic-info">
        <div className="group-name" onClick={chooseGroup}>
          <p className="name">{cutString(group.name, 10)}</p>
          <p className="count">({count}名)</p>
        </div>
        <div className="approver" onClick={chooseGroup}>
          <p className="approver-list">{approverList}</p>
          <p className="position">承認者</p>
        </div>
        <div className="option" onClick={() => setIsShowOption(!isShowOption)}>
          <img src={Option3Dots} />
        </div>
      </div>
      <div
        className="append-option-member"
        style={{ display: isShowOption ? 'flex' : 'none' }}>
        <div className="edit-member" onClick={handleUpdate}>
          編集 <img src={EditMember} />
        </div>
        <div className="delete-member" onClick={handleDelete}>
          削除 <img src={DeleteMember} />
        </div>
      </div>
    </div>
  );
}
