import React from 'react';

type Props = {
  [key: string]: string | number | Function;
};

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="15"
      height="18"
      viewBox="0 0 15 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1465 0.25H5.39822V1.5H10.1465V0.25ZM0.649902 2.75V4H1.83698V16.5C1.83698 17.1904 2.36846 17.75 3.02406 17.75H12.5207C13.1763 17.75 13.7078 17.1904 13.7078 16.5V4H14.8949V2.75H0.649902ZM3.02406 16.5V4H12.5207V16.5H3.02406ZM5.39822 6.5H6.5853V14H5.39822V6.5ZM10.1465 6.5H8.95947V14H10.1465V6.5Z"
        fill="white"
      />
    </svg>
  );
};

export default Component;
