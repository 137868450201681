import { Ranking } from '../../../domain/entities/rankings';
import { PaginationQuery } from '../../../domain/entities/request';
import { PaginatedResponse } from '../../../domain/entities/response';
import RankingRepository from '../../ports/RankingRepository';

export type fetchRankingListUseCase = (
  query: PaginationQuery & { companyId: number },
) => Promise<PaginatedResponse & { results: Ranking[] }>;

export const buildFetchRankingList = (dependencies: {
  rankingRepo: RankingRepository;
}): fetchRankingListUseCase => {
  const { rankingRepo } = dependencies;

  const fetchRankingList: fetchRankingListUseCase = async query =>
    rankingRepo.fetch(query);

  return fetchRankingList;
};
