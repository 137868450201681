import React, { ChangeEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Grid, Row, Column } from 'carbon-components-react';
import { theme } from '../../../config';

import { Button } from '../../atoms/Button';
import { TextInput } from '../../atoms/TextInput';
import { AnchorTag } from '../../atoms/AnchorTag';
import { Notification } from '../../molecules/Notification';

import words from '../../../constants/words';

const Spacer = styled.div`
  margin-bottom: 2.5rem;
  @media ${theme.breakpoints.mobile} {
    margin-bottom: 0;
  }
`;

const ButtonWrapper = styled.div`
  width 141px;
  align-items: center;
  @media ${theme.breakpoints.mobile} {
    height: 38px; 
    width: 100%;
    margin: 20px auto 0 auto;
  }
`;

const NoteWrapper = styled.div`
  display: flex;
  @media ${theme.breakpoints.pc} {
    justify-content: start;
  }
  @media ${theme.breakpoints.mobileAndTablet} {
    margin-top: 8%;
  }
`;

const Note = styled.text`
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.16px;
  color: ${theme.colors.interactive02};
`;

const NotifWrapper = styled.div`
  margin-top: 3%;
  @media ${theme.breakpoints.mobileAndTablet} {
    margin-top: 4%;
  }
`;

const BottomWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media ${theme.breakpoints.mobileAndTablet} {
    flex-direction: column-reverse;
  }
`;

export type AdminSignUpFormProps = {
  companyName: string;
  groupName: string;
  representativeName: string;
  companyEmail: string;
  representativeEmailAddress: string;
  companyTelephone: string;
  onPressLogin: () => void;
  onPressRegister: () => void;
  onChange: (event: ChangeEvent) => void;
  onChangeRepEmail: (event: ChangeEvent) => void;
  onChangeCompEmail: (event: ChangeEvent) => void;
  buttonDisable: boolean;
  companyNameError?: string;
  groupNameError?: string;
  representativeNameError?: string;
  companyEmailError?: string;
  representativeEmailAddressError?: string;
  companyTelephoneError?: string;
  companyEmailRes?: string;
  memberEmailRes?: string;
  signupError?: boolean;
  isLoading?: boolean;
};

const Component = ({
  companyName,
  groupName,
  representativeName,
  companyEmail,
  representativeEmailAddress,
  companyTelephone,
  onPressLogin,
  onPressRegister,
  buttonDisable,
  onChange,
  onChangeRepEmail,
  onChangeCompEmail,
  companyNameError,
  groupNameError,
  representativeNameError,
  companyEmailError,
  representativeEmailAddressError,
  companyTelephoneError,
  companyEmailRes,
  memberEmailRes,
  signupError,
  isLoading,
}: AdminSignUpFormProps): React.ReactElement => {
  const [showSignUPError, setShowSignUPError] = useState(false);

  useEffect(() => {
    if (signupError) {
      setShowSignUPError(true);
    }
  }, [signupError]);

  const handleClose = () => {
    setShowSignUPError(false);
    return false;
  };

  return (
    <Grid>
      <Row>
        <Column>
          <TextInput
            id={'companyName'}
            onChange={onChange}
            label={`${words.companyName}\u00A0\u00A0${words.requiredLabel}`}
            value={companyName}
            invalid={!!companyNameError}
            invalidText={companyNameError}
            maxLength={255}
            disabled={isLoading}
          />
        </Column>
      </Row>
      <Spacer />
      <Row>
        <Column>
          <TextInput
            id={'groupName'}
            onChange={onChange}
            label={`${words.groupName}\u00A0\u00A0${words.requiredLabel}`}
            value={groupName}
            invalid={!!groupNameError}
            invalidText={groupNameError}
            maxLength={255}
            disabled={isLoading}
          />
        </Column>
      </Row>
      <Spacer />
      <Row>
        <Column>
          <TextInput
            id={'representativeName'}
            onChange={onChange}
            label={`${words.representativeName}\u00A0\u00A0${words.requiredLabel}`}
            value={representativeName}
            invalid={!!representativeNameError}
            invalidText={representativeNameError}
            maxLength={255}
            disabled={isLoading}
          />
        </Column>
      </Row>
      <Spacer />
      <Row>
        <Column>
          <TextInput
            id={'companyEmail'}
            onChange={onChangeCompEmail}
            label={`${words.companyEmail}\u00A0\u00A0${words.requiredLabel}`}
            value={companyEmail}
            invalid={!!companyEmailError || !!companyEmailRes}
            invalidText={companyEmailError || companyEmailRes}
            maxLength={255}
            disabled={isLoading}
          />
        </Column>
      </Row>
      <Spacer />
      <Row>
        <Column>
          <TextInput
            id={'representativeEmailAddress'}
            onChange={onChangeRepEmail}
            label={`${words.representativeEmailAddress}\u00A0\u00A0${words.optional}`}
            value={representativeEmailAddress}
            invalid={!!representativeEmailAddressError || !!memberEmailRes}
            invalidText={representativeEmailAddressError || memberEmailRes}
            maxLength={255}
            disabled={isLoading}
          />
        </Column>
      </Row>
      <Spacer />
      <Row>
        <Column>
          <TextInput
            id={'companyTelephone'}
            onChange={onChange}
            label={`${words.companyTelephone}\u00A0\u00A0${words.requiredLabel}`}
            value={companyTelephone}
            invalid={!!companyTelephoneError}
            invalidText={companyTelephoneError}
            maxLength={255}
            disabled={isLoading}
          />
        </Column>
      </Row>
      {showSignUPError && (
        <Row>
          <Column>
            <NotifWrapper>
              <Notification
                kind="error"
                title={words.error}
                subtitle={words.somethingWentWrong}
                handleClose={handleClose}
                inline
              />
            </NotifWrapper>
          </Column>
        </Row>
      )}
      <Spacer />
      <BottomWrapper>
        <NoteWrapper>
          <Note>{words.alreadyHaveAccount}</Note>
          <AnchorTag onPress={onPressLogin} title={words.alreadyLogin} />
        </NoteWrapper>
        <ButtonWrapper>
          <Button
            title={words.register}
            onPress={onPressRegister}
            disabled={buttonDisable}
          />
        </ButtonWrapper>
      </BottomWrapper>
    </Grid>
  );
};

export default Component;
