import React from 'react';
import { useLocation } from 'react-router-dom';

import { ErrorCode } from '../../templates/ErrorCode';
import { Props as ErrorCodeProps } from '../../templates/ErrorCode/ErrorCode';

type Props = {
  statusCode: ErrorCodeProps['statusCode'];
};

const Component = ({ statusCode }: Props): React.ReactElement => {
  const { state: locationState } = useLocation();

  const redirectPath: string | undefined = locationState?.redirectPath;

  return <ErrorCode statusCode={statusCode} navigatePath={redirectPath} />;
};

export default Component;
