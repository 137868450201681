import React from 'react';
import words from '../../../constants/words';
import { DefinitionTooltip } from '../Tooltip';

export type Props = {
  itemList: string[];
  lengthBeforeTruncate?: number;
};

const Component = ({
  itemList = [],
  lengthBeforeTruncate = 2,
}: Props): React.ReactElement => {
  const item1 = itemList?.[0];
  const middleItems = React.useMemo(
    () =>
      itemList.filter(
        (item, index) => index < lengthBeforeTruncate && index > 0,
      ),
    [lengthBeforeTruncate, itemList],
  );
  const remainingItemsCount = React.useMemo(
    () => itemList.length - lengthBeforeTruncate,
    [lengthBeforeTruncate, itemList],
  );
  const remainingItems = React.useMemo(() => {
    return itemList
      .map((item, index) => {
        return index + 1 === remainingItemsCount + 2
          ? `${item}\n`
          : `${item}、\n`;
      })
      .splice(2);
  }, [lengthBeforeTruncate, itemList]);

  return (
    <span className="nameHolder">
      {item1}
      {middleItems.map(item => `、${item}`)}
      {remainingItemsCount > 0 && (
        <>
          、
          <DefinitionTooltip
            message={remainingItems.join('')}
            label={` ${words.plusMore(remainingItemsCount)}`}
          />
        </>
      )}
    </span>
  );
};

export default Component;
