import React from 'react';
import styled from 'styled-components';
import { theme } from '../../../config';
import words from '../../../constants/words';
import { Modal } from '../../molecules/Modal';

const TextWrapper = styled.text`
  padding: 0.5em 0;
  font-size: 13px;
  line-height: 18px;
  color: ${theme.colors.black};
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1em 0;
`;

const StyledModal = styled(Modal)`
  .bx--modal-header__heading {
    white-space: pre-wrap;
  }

  @media ${theme.breakpoints.mobile} {
    .bx--children-container {
      margin-bottom: 0;
      padding-bottom: 22px;
    }

    .bx--modal-content {
      padding-bottom: 23px;
    }

    .buttonsContainer {
      align-content: center;
    }

    .buttonsContainer > button {
      width: 100%;
    }
  }
`;

export type DeleteApplicatonModalProps = {
  open: boolean;
  id: number;
  rankingName: string;
  onConfirm: (id: number) => void;
  onClose: () => void;
  onCancel: () => void;
};

const ModalComponent = ({
  open,
  id,
  onConfirm,
  onClose,
  onCancel,
  rankingName,
}: DeleteApplicatonModalProps): React.ReactElement => {
  return (
    <>
      <StyledModal
        label={words.deleteRankingApplicationSubtitle}
        open={open}
        title={words.deleteRankingApplicationTitle}
        confirmButtonText={words.deleteField}
        confirmButtonTheme="secondary"
        hasScrollingContent={false}
        onConfirm={() => onConfirm(id)}
        onClose={onClose}
        onCancel={onCancel}>
        <ContentWrapper>
          <TextWrapper>
            {words.rankingName}: {rankingName}
          </TextWrapper>
        </ContentWrapper>
      </StyledModal>
    </>
  );
};

export default ModalComponent;
