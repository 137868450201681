import { Formik } from 'formik';
import React, { useCallback, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams, useLocation } from 'react-router';
import styled from 'styled-components';
import { theme } from '../../../config';
import {
  CreateEntryApplication,
  EntryApprover,
  FetchEntryFieldProps,
} from '../../../domain/entities/entry';
import { GroupApprover } from '../../../domain/entities/group';
import { useEntryHooks } from '../../../hooks/entry';
import { useGlobalState } from '../../../hooks/global';
import { useRecordHooks } from '../../../hooks/record';
import { SubHeader } from '../../atoms/Subheader';
import { AppFormType } from '../../organisms/MemberApplicationFormDetails/MemberApplicationFormDetails';
import { MemberApplicationDetails } from '../../templates/MemberApplicationDetails';
import { StatusTypes } from '../../templates/MemberApplicationDetails/MemberApplicationDetails';
import { RecordApplicationSchema } from './validation';
import { Props as INotification } from '../../molecules/Notification/Notification';
import { TIMEOUT } from '../../../constants/timeout';
import words from '../../../constants/words';
import { ToastNotification } from '../../molecules/Notification';
import { PropsValue } from 'react-select';
import { IOptions as MultiSelectOptions } from '../../organisms/MultiSelectDropdown/MultiSelectDropdown';
import {
  REFERENCE_DATA_KEY,
  REFERENCE_FORM_KEY,
} from '../../../constants/storageKeys';
import { useNotificationHooks } from '../../../hooks/notification';
import { addNotificationData } from '../RecordApprovalForm/RecordApprovalForm';
import { AxiosError } from 'axios';
import { navigateToErrorPage } from '../../../utils/handleErrorPage';
import { PageLoader } from '../../atoms/Loading';

const ChildrenWrapper = styled.div<{ isLoading?: boolean }>`
  padding: ${props =>
    props.isLoading ? `0px 70px 40px 70px` : `40px 70px 40px 70px`};

  @media ${theme.breakpoints.mobile} {
    padding: 19px 23px;
  }
`;

type Props = {};

const arrayToObject = (arr: FetchEntryFieldProps[]) => {
  const newObject = arr.reduce((newObj: { [key: string]: any }, item: any) => {
    newObj[item.field_lookup.id] = item.value_decimal;
    return newObj;
  }, {});
  return newObject;
};

export const clearReferenceFormPersistedData = (): void => {
  window.localStorage.removeItem(REFERENCE_DATA_KEY);
  window.localStorage.removeItem(REFERENCE_FORM_KEY);
};

const Component = ({}: Props): React.ReactElement => {
  const {
    useCurrentUser: { currentUser },
  } = useGlobalState();
  const { id: entryId, rankingId } = useParams();
  const { useFetchEntry, useUpdateEntry, useDeleteEntry } = useEntryHooks();
  const { fetchFormFieldsApprovers } = useFetchEntry();
  const { updateEntryApplication } = useUpdateEntry();
  const { deleteEntryApplication } = useDeleteEntry();
  const { useAddNotification } = useNotificationHooks();
  const { addNotification } = useAddNotification();

  const [application, setApplication] = useState<AppFormType | undefined>();
  const [notification, setNotification] = useState<INotification | null>(null);
  const [reUpdateReference, setReUpdateReference] = useState<
    Response | undefined
  >();

  const { useFetchReferenceDetails } = useRecordHooks();
  const { fetchReferenceDetails } = useFetchReferenceDetails();

  const { pathname: locationPathname, state: locationState } = useLocation();
  const navigate = useNavigate();

  const fetchCustomFields = useCallback(async () => {
    if (currentUser) {
      const response = await fetchFormFieldsApprovers(
        currentUser.companyId,
        currentUser.memberId,
        Number(rankingId),
      );

      return response;
    }
  }, []);

  const { data: formSetupData } = useQuery(
    ['fetchFormFieldsApprovers'],
    fetchCustomFields,
    {
      onError: (err: AxiosError) => {
        navigateToErrorPage(navigate, err.response?.status, locationPathname);
      },
      refetchOnMount: true,
    },
  );

  const fetchReference = useCallback(async () => {
    if (currentUser && entryId) {
      const response = await fetchReferenceDetails({
        companyId: currentUser.companyId,
        memberId: currentUser.memberId,
        rankingId: Number(rankingId),
        entryId: Number(entryId),
      });

      const { approvers, entry, notes, created_at, logs } = response;
      const entryLogs = logs?.map(log => {
        return {
          dateAndTime: log.created_at,
          action: log.action,
          reason: log.reason,
          memberName: log.member.name,
        };
      });

      const mainApprover = approvers.find(approver => approver.is_main) || {
        group_approver: undefined,
      };
      const values: AppFormType = {
        applicationDate: entry.application_date || created_at,
        salesDate: entry.sales_date,
        mainApprover: {
          ...mainApprover.group_approver,
        } as GroupApprover,
        notes,
        subApprovers: getSubApprovers(approvers),
        customFields: entry.fields,
        entryLogs,
      };

      setApplication(values);
      return {
        ...response,
      };
    }
  }, []);

  const getSubApprovers = (approvers: EntryApprover[]) => {
    return approvers
      .filter(approver => !approver.is_main)
      .map(subApprover => {
        return {
          label: `${subApprover.group_approver.member?.name}`,
          value: `${subApprover.group_approver.id}`,
        };
      });
  };

  const handleAddNotitication = (newMainApproverId: number) => {
    if (
      !currentUser?.companyId ||
      !currentUser?.memberId ||
      !rankingId ||
      !entryId ||
      !currentUser?.userId
    ) {
      throw new Error(`'company id' field not set`);
    }

    const mainApprover = formSetupData?.approvers.find(
      approver => approver.id === newMainApproverId,
    );
    const notifData: addNotificationData = {
      companyId: currentUser?.companyId,
      data: {
        is_approved: null,
        applicant_name: currentUser?.name,
        approver_name: mainApprover?.member?.name,
        ranking_name: formSetupData?.rankingName,
        rankingId: Number(rankingId),
        memberId: currentUser.memberId,
        approverId: mainApprover?.member?.id,
        entryId: Number(entryId),
      },
    };
    addNotificationMutation(notifData);
  };

  const { mutate: addNotificationMutation } = useMutation(
    ({ companyId, data }: addNotificationData) => {
      return addNotification({ companyId }, data);
    },
  );

  const { data, isError, isFetching } = useQuery(
    ['fetchReferenceDetails', reUpdateReference],
    fetchReference,
    {
      onError: (err: AxiosError) => {
        navigateToErrorPage(navigate, err.response?.status, locationPathname);
      },
      enabled: formSetupData && Object.keys(formSetupData).length > 0,
    },
  );

  const formatCustomFieldValues = (data: any) => {
    if (application) {
      const fieldValues = arrayToObject(data);
      const customValues = formSetupData?.customFields?.map(field => {
        return {
          ...field,
          value_decimal: fieldValues[field.field_lookup_id],
        };
      });
      return customValues as FetchEntryFieldProps[];
    }
    return [];
  };

  const getApplicationInitValues = (formValues?: any): AppFormType => {
    const referenceDataFromLocal = window.localStorage.getItem(
      REFERENCE_DATA_KEY,
    );
    const persistedReferenceData = referenceDataFromLocal
      ? JSON.parse(referenceDataFromLocal)
      : {};

    const isTheSameRanking =
      Object.entries(persistedReferenceData).length > 0 &&
      persistedReferenceData.rankingId === Number(rankingId) &&
      persistedReferenceData.memberId === currentUser?.memberId &&
      persistedReferenceData.entryId === Number(entryId);

    const formFromLocal = window.localStorage.getItem(REFERENCE_FORM_KEY);
    const persistedForm = formFromLocal ? JSON.parse(formFromLocal) : {};

    if (isTheSameRanking && Object.entries(persistedForm).length > 0) {
      const persistedCustom = persistedForm?.customFields;
      const arrToConvert =
        persistedCustom ||
        formatCustomFieldValues(formValues?.customFields) ||
        [];
      const customArrToObject = arrToConvert.reduce((acc: any, curr: any) => {
        return {
          ...acc,
          [curr.field_lookup_id]: curr,
        };
      }, {});
      const custom: any[] = [];
      formSetupData?.customFields.forEach((item: any) => {
        const value_decimal = customArrToObject
          ? customArrToObject[item.field_lookup_id]?.value_decimal
          : 0;
        let fieldValueItem: FetchEntryFieldProps = item;
        if (value_decimal) {
          fieldValueItem = {
            ...fieldValueItem,
            value_decimal,
            valueForValidation: value_decimal,
          };
        }
        custom.push(fieldValueItem);
      });

      const hasPersistedForm = Object.entries(persistedForm).length > 0;

      return {
        applicationDate: initialApplicationDate || new Date(),
        salesDate:
          persistedForm?.salesDate ||
          formValues?.salesDate ||
          new Date(new Date().toDateString()),
        customFields: custom,
        mainApprover:
          persistedForm?.mainApprover || formValues?.mainApprover || {},
        subApprovers:
          persistedForm?.subApprovers || formValues?.subApprover || [],
        notes: hasPersistedForm ? persistedForm?.notes : formValues?.notes,
        status: formValues?.status || 'unapproved',
      };
    }
    return {
      ...formValues,
      customFields: formatCustomFieldValues(formValues?.customFields),
    };
  };

  const { mutate: updateApplication } = useMutation(
    (params: {
      companyId: number;
      memberId: number;
      entryApplication: CreateEntryApplication;
      isWithdraw: boolean;
    }) =>
      updateEntryApplication(
        params.companyId,
        params.memberId,
        Number(rankingId),
        Number(entryId),
        params.entryApplication,
      ),
    {
      onSuccess: (response, { isWithdraw, entryApplication }) => {
        const toastMessage = isWithdraw
          ? {
              title: words.withdrawalCompleted,
              subtitle: words.referenceCancelSuccess,
            }
          : {
              title: words.reapplyCompleted,
              subtitle: words.reapplySuccess,
            };
        setReUpdateReference(response);
        clearReferenceFormPersistedData();
        setNotification({
          ...toastMessage,
          kind: 'success',
          timeout: TIMEOUT,
          handleClose: () => {
            setNotification(null);
            return false;
          },
        });
        if (entryApplication.main_approver_id) {
          handleAddNotitication(entryApplication.main_approver_id);
        }
      },
      onError: (error: any) => {
        if (error.response.status === 400) {
          navigate(0);
        }
        setNotification({
          kind: 'error',
          title: words.error,
          subtitle: words.errorOccuredToastSub,
          timeout: TIMEOUT,
          handleClose: () => {
            setNotification(null);
            return false;
          },
        });
      },
    },
  );

  const { mutate: deleteApplication } = useMutation(
    (params: { companyId: number; memberId: number }) =>
      deleteEntryApplication(
        params.companyId,
        params.memberId,
        Number(rankingId),
        Number(entryId),
      ),
    {
      onSuccess: () => {
        clearReferenceFormPersistedData();
        navigate('/reference', {
          state: {
            deleteData: { id: entryId },
            toastMessage: {
              kind: 'success',
              title: words.referenceDeleted,
              subtitle: words.referenceDeletedSuccess,
            },
          },
        });
      },
      onError: () => {
        setNotification({
          kind: 'error',
          title: words.error,
          subtitle: words.errorOccuredToastSub,
          timeout: TIMEOUT,
          handleClose: () => {
            setNotification(null);
            return false;
          },
        });
      },
    },
  );

  const updateStatus = (status: StatusTypes) => {
    if (currentUser) {
      updateApplication({
        companyId: currentUser.companyId,
        memberId: currentUser.memberId,
        entryApplication: {
          status,
        },
        isWithdraw: true,
      });
    }
  };

  const submitForm = (values: AppFormType) => {
    const indexValue = values.customFields?.find(
      customField => customField.is_basis,
    );

    const subApproversIdArray = (
      (values.subApprovers as {
        value: string;
        label: string;
      }[]) || []
    ).map(value => Number(value.value));

    const customFieldsArray = (values.customFields || [])
      .filter(
        customField =>
          (!customField.value_char && customField.value_decimal) ||
          (customField.value_char && !customField.value_decimal),
      )
      .map(customField => ({
        field_lookup_id: customField.field_lookup_id,
        value_char: customField.value_char,
        value_decimal: customField.value_decimal,
      }));

    const _sales_date = values?.salesDate
      ? typeof values.salesDate === 'string'
        ? values.salesDate
        : values.salesDate.toDateString()
      : undefined;

    const salesDate = _sales_date
      ? new Date(_sales_date)
      : (_sales_date as undefined);

    const entryApplication: CreateEntryApplication = {
      value: indexValue?.value_decimal,
      sales_date: salesDate,
      notes: values.notes || '',
      main_approver_id: values.mainApprover?.id,
      sub_approvers_id: subApproversIdArray,
      fields: customFieldsArray,
      status: values.status,
      application_date: values.applicationDate,
    };

    const dataToPersist = {
      applicationDate: new Date(),
      salesDate: salesDate,
      customFields: values.customFields,
      mainApprover: values.mainApprover,
      subApprovers: values.subApprovers,
      notes: values.notes || '',
    };

    if (currentUser) {
      window.localStorage.setItem(
        REFERENCE_FORM_KEY,
        JSON.stringify(dataToPersist),
      );
      window.localStorage.setItem(
        REFERENCE_DATA_KEY,
        JSON.stringify({
          rankingId: Number(rankingId),
          memberId: currentUser.memberId,
          entryId: Number(entryId),
        }),
      );
      updateApplication({
        companyId: currentUser.companyId,
        memberId: currentUser.memberId,
        entryApplication,
        isWithdraw: false,
      });
    }
  };

  const deleteEntry = () => {
    if (currentUser) {
      deleteApplication({
        companyId: currentUser.companyId,
        memberId: currentUser.memberId,
      });
    }
  };

  const applicationData = formSetupData || {
    rankingName: '',
    approvers: [] as GroupApprover[],
  };

  const initialApplicationDate =
    data &&
    application &&
    (data.status === 'withdraw' || data.status === 'rejected')
      ? new Date()
      : application?.applicationDate;

  const initValues = getApplicationInitValues(application);

  const handleCancel = () => {
    clearReferenceFormPersistedData();
    if (locationState?.previousPath) navigate(locationState.previousPath);
    else navigate(`/reference`);
  };

  return (
    <>
      {!isError ? (
        <>
          {isFetching && (
            <SubHeader
              title={applicationData.rankingName || ''}
              onBack={handleCancel}
              isStickyOnMobile
              hasBackOnMobile
            />
          )}
          <React.Suspense fallback={<div />}>
            {!isFetching ? (
              <Formik
                initialValues={{
                  ...initValues,
                  applicationDate: initialApplicationDate,
                }}
                validationSchema={RecordApplicationSchema}
                onSubmit={() => {}}>
                {({ initialValues, values }): React.ReactElement => {
                  const areValuesChanged = initialValues !== values;

                  return (
                    <>
                      <SubHeader
                        title={applicationData.rankingName || ''}
                        onBack={handleCancel}
                        backTooltipMessage={words.discardChangesEditBasicInfo}
                        backTooltipCancelText={words.cancelTooltipNo}
                        backTooltipConfirmText={words.recordAppTooltipConfirm}
                        hasBackOnMobileTooltip={areValuesChanged}
                        isStickyOnMobile
                        hasBackOnMobile
                      />
                      <ChildrenWrapper>
                        <MemberApplicationDetails
                          applicationStatus={data ? data.status : 'unapproved'}
                          applicationValues={values as AppFormType}
                          approverOptions={applicationData.approvers}
                          histories={application?.entryLogs || []}
                          mainApprover={values.mainApprover}
                          subApprovers={
                            values.subApprovers as PropsValue<
                              MultiSelectOptions
                            >
                          }
                          updateStatus={updateStatus}
                          submitForm={submitForm}
                          rankingName={applicationData.rankingName}
                          deleteEntry={deleteEntry}
                          originalData={data}
                          handleCancel={handleCancel}
                          areValuesChanged={areValuesChanged}
                        />
                      </ChildrenWrapper>
                    </>
                  );
                }}
              </Formik>
            ) : (
              <ChildrenWrapper isLoading>
                <PageLoader />
              </ChildrenWrapper>
            )}
          </React.Suspense>
        </>
      ) : null}
      {notification ? (
        <ToastNotification notification={notification} zIndex={902} />
      ) : null}
    </>
  );
};

export default Component;
