import { Member, UpdateMemberType } from '../../../domain/entities/member';
import MemberRepository from '../../ports/MemberRepository';

export type updateMemberUseCase = (
  companyId: number,
  memberId: number,
  payload: UpdateMemberType,
) => Promise<Member>;

export const buildUpdateMember = (dependencies: {
  memberRepo: MemberRepository;
}): updateMemberUseCase => {
  const { memberRepo } = dependencies;

  const updateMember: updateMemberUseCase = async (
    companyId,
    memberId,
    payload,
  ) => memberRepo.patch(companyId, memberId, payload);

  return updateMember;
};
