import { Notification } from '../../../domain/entities/notification';
import { PaginationQuery } from '../../../domain/entities/request';
import { PaginatedResponse } from '../../../domain/entities/response';
import NotificationRepository from './../../ports/NotificationRepository';

export type fetchNotificationsUseCase = (
  query: PaginationQuery & {
    companyId: number;
    userId?: number;
  },
) => Promise<PaginatedResponse & { results: Notification[] }>;

export const buildFetchNotifications = (dependencies: {
  notifRepo: NotificationRepository;
}): fetchNotificationsUseCase => {
  const { notifRepo } = dependencies;

  const fetchNotifications: fetchNotificationsUseCase = async query =>
    notifRepo.fetchNotifications(query);

  return fetchNotifications;
};
