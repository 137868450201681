import MemberRepository from '../../ports/MemberRepository';

export type batchDeleteMembersUseCase = (
  companyId: number,
  memberIds: string,
) => Promise<void>;

export const buildBatchDeleteMembers = (dependencies: {
  memberRepo: MemberRepository;
}): batchDeleteMembersUseCase => {
  const { memberRepo } = dependencies;

  const batchDeleteMembers: batchDeleteMembersUseCase = async (
    companyId,
    memberIds,
  ) => memberRepo.batchDeleteMembers(companyId, memberIds);

  return batchDeleteMembers;
};
