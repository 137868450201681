import React from 'react';
import styled from 'styled-components';

import { Loading } from '../../atoms/Loading';
import { theme } from '../../../config';

export type Props = {
  isLoading?: boolean;
  text?: string;
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

const StyledText = styled.p`
  font-size: 12px;
  line-height: 16px;
  margin-left: 8px;
  color: ${theme.colors.text02};
`;

const SubmitLoading = ({
  isLoading = true,
  text = '',
}: Props): React.ReactElement => {
  return (
    <Container>
      <Loading
        width={16}
        height={16}
        isActive={isLoading}
        withOverlay={false}
      />
      <StyledText>{text}</StyledText>
    </Container>
  );
};

export default SubmitLoading;
