import React from 'react';

const Component = (): React.ReactElement => {
  return (
    <svg
      width="19"
      height="16"
      viewBox="0 0 19 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.748 1.75L14.8668 2.63125L16.373 4.25H13.248V1.75C13.248 1.05964 12.6884 0.5 11.998 0.5H5.74805V1.75H11.998V10.5H13.248V5.5H16.373L14.8668 7.11875L15.748 8L18.873 4.875L15.748 1.75ZM8.87305 15.5H1.99805C1.30769 15.5 0.748047 14.9404 0.748047 14.25V4.875C0.748047 4.18464 1.30769 3.625 1.99805 3.625H8.87305C9.5634 3.625 10.123 4.18464 10.123 4.875V14.25C10.123 14.9404 9.5634 15.5 8.87305 15.5ZM1.99805 14.25V4.875H8.87305V14.25H1.99805Z"
        fill="white"
      />
    </svg>
  );
};

export default Component;
