import authHeader from 'api/auth-header';
import axios from 'axios';
import constants from 'constants/constants';
import urls from 'constants/urls';
import words from 'constants/words';
import { listGroupType } from 'type/member';
import { ValidationError } from 'yup';
import Swal from 'sweetalert2';

export function changeInfoCurrentUser(data: any): void {
  localStorage.setItem('user', JSON.stringify(data));
}

export function setCurrentUser(data: any): void {
  if (
    localStorage.getItem('user') !== undefined ||
    localStorage.getItem('user') !== null
  ) {
    const user = data.data;
    user.token = data.token;
    localStorage.setItem('user', JSON.stringify(user));
  }
  return;
}

export function getCurrentUser(): any {
  if (localStorage.getItem('user') === 'undefined') {
    return {};
  }
  if (
    localStorage.getItem('user') !== undefined ||
    localStorage.getItem('user') !== null
  ) {
    return JSON.parse(localStorage.getItem('user') || '{}');
  } else {
  }
  return {};
}

export function replaceUser(data: object): any {
  if (localStorage.getItem('user') === 'undefined') {
    return {};
  }
  if (
    localStorage.getItem('user') !== undefined ||
    localStorage.getItem('user') !== null ||
    localStorage.getItem('user') !== 'undefined'
  ) {
    localStorage.setItem('user', JSON.stringify(data));
  }
  return;
}

export function removeCurrentUser(): void {
  localStorage.removeItem('user');
}

export function isEmptyObject(data: object): boolean {
  if (Object.keys(data).length === 0 && data.constructor === Object) {
    return true;
  }
  return false;
}

export function extractError(data: object): string {
  let errorSv = '';
  for (const [key, value] of Object.entries(data)) {
    errorSv += key + ': ' + value + '<br />';
  }
  return errorSv;
}

export function getTitleName(data: string): string {
  if (data === '/home') {
    return words.homeTitle;
  }
  if (data === '/account-setting') {
    return words.accountSettingTitle;
  }
  if (data === '/member-list-settings') {
    return words.memberListTitlePage;
  }
  if (data === '/member-profile') {
    return 'マイページ';
  }
  if (data === '/sph') {
    return words.calendarMenu;
  }
  return '';
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function getErrors(items: ValidationError[]) {
  if (!items) {
    return {};
  }

  return items.reduce((error: object, e: ValidationError) => {
    return {
      ...error,
      [`${e.path}`]: e.message,
    };
  }, {});
}

export function getExtensionFile(data: string): string {
  return data.substring(data.lastIndexOf('.') + 1);
}

export function cutString(string: string, limit: number): string {
  const dots = '...';
  if (string.length > limit) {
    string = string.substring(0, limit) + dots;
  }
  return string;
}

export function getRoleName(string: string): string {
  if (string === 'member') {
    return words.applicant;
  }
  if (string === 'approver') {
    return words.approver;
  }
  if (string === 'admin') {
    return words.admin;
  }
  return '';
}

export function getRoleNameJP(string: string): string {
  if (string === '申請者') {
    return 'member';
  }
  if (string === '承認者') {
    return 'approver';
  }
  if (string === '管理者') {
    return 'admin';
  }
  return '';
}

export function validateEmail(emailField: string): boolean {
  const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

  if (reg.test(emailField) == false) {
    return false;
  }

  return true;
}

export function validateRole(role: string): boolean {
  const roleArr = ['申請者', '承認者'];
  if (roleArr.includes(role)) {
    return true;
  }
  return false;
}

export function checkInitDone(): string {
  const user = getCurrentUser();
  let stt = '';
  if (isEmptyObject(user)) {
    return constants.SIGN_IN;
  } else {
    axios
      .get(urls.member_info, {
        headers: authHeader(),
      })
      .then(res => {
        const data = res.data.data;
        data.token = user.token;
        data.company_id = user.company_id;
        replaceUser(data);
        if (data.is_done_initial) {
          stt = constants.DONE;
        } else stt = constants.SETTING;
      });
    return stt;
  }
}

export function connectStringWithComma(data: { full_name: string }[]): string {
  let string = '';
  data.map((item, index) => {
    if (index < 2) {
      string += item.full_name += '、';
    }
  });
  string = string.substring(0, string.length - 1);
  if (data.length > 2) {
    string += '...';
  }
  return string;
}

export function makeRandomKey(length: number): string {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function dateToString(date: Date): string {
  const year = date.toLocaleString('default', { year: 'numeric' });
  const month = date.toLocaleString('default', { month: '2-digit' });
  const day = date.toLocaleString('default', { day: '2-digit' });

  return `${year}年${month}月${day}日`;
}

export function dateToStringOnlyMonth(date: Date): string {
  const year = date.toLocaleString('default', { year: 'numeric' });
  const month = date.toLocaleString('default', { month: '2-digit' });

  return `${year}年${month}月`;
}

export function dateToStringOnlyDayMonth(date: Date): string {
  const day = date.toLocaleString('default', { day: 'numeric' });
  const month = date.toLocaleString('default', { month: '2-digit' });

  return `${month}月${day}日`;
}

export function jpStringToDate(strDate: string): string {
  const year = strDate.substring(0, 4);
  const month = strDate.substring(5, 7);
  const day = strDate.substring(8, 10);

  return `${year}/${month}/${day}`;
}

export function lastdayOfMonth(y: number, m: number): string {
  return new Date(y, m + 1, 0).getDate().toString();
}

export function getSubjectError(subject: string): string {
  if (subject === 'date') {
    return 'ENTRY_SALES_DATE';
  }
  if (subject === 'time_actual') {
    return 'TIME_ACTUAL';
  }
  if (subject === 'product_actual') {
    return 'PRODUCT_ACTUAL';
  }
  if (subject === 'entry_status') {
    return 'ENTRY_STATUS';
  }
  if (subject === 'author_entry') {
    return 'AUTHOR_ENTRY';
  }
  return '';
}

export function connectError(data: object): string {
  let errorSv = '';
  for (const [key, value] of Object.entries(data)) {
    errorSv += `${getSubjectError(key)} ${value}`;
  }
  return errorSv;
}

export function PopUpModal(msg: string, icon: boolean, title = ''): void {
  Swal.fire({
    title: title,
    text: msg,
    icon: icon ? 'success' : 'error',
    confirmButtonText: 'OK',
  });
}

export function getWeeksInMonth(year: number, month: number): any {
  const weeks: any[] = [],
    firstDate = new Date(year, month, 1),
    lastDate = new Date(year, month + 1, 0),
    numDays = lastDate.getDate();

  let dayOfWeekCounter = firstDate.getDay();

  for (let date = 1; date <= numDays; date++) {
    if (dayOfWeekCounter === 0 || weeks.length === 0) {
      weeks.push([]);
    }
    weeks[weeks.length - 1].push(date);
    dayOfWeekCounter = (dayOfWeekCounter + 1) % 7;
  }

  return weeks
    .filter(w => !!w.length)
    .map(w => ({
      start: w[0],
      end: w[w.length - 1],
      dates: w,
    }));
}

export function renderWeekNumber(week: number): string {
  if (week === 0) {
    return '一';
  }
  if (week === 1) {
    return '二';
  }
  if (week === 2) {
    return '三';
  }
  if (week === 3) {
    return '四';
  }
  if (week === 4) {
    return '五';
  }
  return '';
}
