import React from 'react';
import Select from 'react-select';
import CloseButton from 'assets/icons/close-button.svg';
import SearchButton from 'assets/icons/searchBtn.svg';
import './groupSettingModal.scss';
import { listGroupType } from 'type/member';
import { GroupItem } from '../GroupItem';
import AddBtn from 'assets/icons/addBtn.svg';

type PropType = {
  isShow: boolean;
  listGroup: listGroupType;
  onHideModal: () => void;
  onCreateModal: () => void;
  handleDeleteGroup: (_group_id: string) => void;
  handleUpdateGroup: (_group_id: string) => void;
  handleChooseGroup: (_group_id: string, _group_name: string) => void;
  filterGroupName: (_search: string) => void;
};

export default function GroupSettingModal(props: PropType) {
  const {
    isShow,
    onHideModal,
    listGroup,
    onCreateModal,
    handleDeleteGroup,
    handleUpdateGroup,
    handleChooseGroup,
    filterGroupName,
  } = props;
  return (
    <div className={`group-setting-pages ${isShow ? 'show' : 'hide'}`}>
      <div className="title-modal">
        <div className="modal-title">グループリスト</div>
        <div className="close-button" onClick={onHideModal}>
          <img src={CloseButton} />
        </div>
      </div>
      <div className="modal-content">
        <div className="content">
          <div className="inputSearch__wrap">
            <div className="searchBtn">
              <img src={SearchButton} />
            </div>
            <div className="inputSearch">
              <input
                className="form-control"
                placeholder="第一Group"
                onChange={e => filterGroupName(e.target.value)}
              />
            </div>
          </div>
          <div className="addBtn" onClick={onCreateModal}>
            <img src={AddBtn} />
          </div>
          <div className="list-group">
            {listGroup &&
              listGroup.map(item => (
                <GroupItem
                  key={item.id}
                  group={item}
                  handleDeleteGroup={group_id => handleDeleteGroup(group_id)}
                  handleUpdateGroup={group_id => handleUpdateGroup(group_id)}
                  handleChooseGroup={(group_id, group_name) =>
                    handleChooseGroup(group_id, group_name)
                  }
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}
