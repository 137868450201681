const ordinalSuffixOf = (num: number): string => {
  const NUM1 = num % 10,
    NUM2 = num % 100;
  if (NUM1 == 1 && NUM2 != 11) {
    return num + 'st';
  }
  if (NUM1 == 2 && NUM2 != 12) {
    return num + 'nd';
  }
  if (NUM1 == 3 && NUM2 != 13) {
    return num + 'rd';
  }
  return num + 'th';
};

export default ordinalSuffixOf;
