import React, { useEffect, useState } from 'react';
import './memberListSetting.scss';
import OrangeArrow from 'assets/icons/orangeArrow.svg';
import InviteImg from 'assets/icons/invite.svg';
import SearchButton from 'assets/icons/searchBtn.svg';
import MenuCircle from 'assets/icons/menu-circle.svg';
import OrangeTrash from 'assets/icons/orange-trash.svg';
import PencilTrash from 'assets/icons/orange-pencil.svg';
import { OrangeButton } from 'components/atoms/OrangeButton';
import { ListMember } from 'components/molecules/ListMember';
import { ChangeGroupModal } from 'components/molecules/ChangeGroupModal';
import { InviteMemberModal } from 'components/molecules/InviteMemberModal';
import BlackScreen from 'components/atoms/BlackScreen/BlackScreen';
import { MemberInGroupModal } from 'components/molecules/MemberInGroupModal';
import {
  checkInitDone,
  extractError,
  getCurrentUser,
  getRoleNameJP,
  makeRandomKey,
  PopUpModal,
} from 'utils/common';
import urls from 'constants/urls';
import axios from 'axios';
import authHeader from 'api/auth-header';
import ErrorCircle from 'assets/icons/error-circle.svg';
import {
  groupOptionType,
  groupType,
  listGroupOptionType,
  listMemberInvite,
  memberGroupType,
  memberInvite,
  memberProfileUpdate,
  listGroupType,
} from 'type/member';
import words from 'constants/words';
import LoadingWhenWait from 'components/atoms/LoadingWhenWait/LoadingWhenWait';
import { ConfirmChangeModal } from 'components/molecules/ConfirmChangeModal';
import { Loading } from 'components/molecules/Loading';
import { ConfirmDeleteModal } from 'components/molecules/ConfirmDeleteModal';
import { ConfirmMoveMemberModal } from 'components/molecules/ConfirmMoveMemberModal';
import { ConfirmSuccessModal } from 'components/molecules/ConfirmSuccessModal';
import { ConfirmDelMemberModal } from 'components/molecules/ConfirmDelMemberModal';
import { SignedUpSuccessModal } from 'components/templates/SignedUpSuccessModal';
import { useNavigate } from 'react-router';
import { GroupSettingModal } from 'components/molecules/GroupSettingModal';
import { AddGroupModal } from 'components/molecules/AddGroupModal';
import { ConfirmDelGroup } from 'components/molecules/ConfirmDelGroup';
import { EditGroupModal } from 'components/molecules/EditGroupModal';
import constants from 'constants/constants';

export default function MemberListSetting() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isShowOption, setIsShowOption] = useState<boolean>(false);
  const [isShowEditMember, setIsShowEditMember] = useState<boolean>(false);
  const [isShowInviteModal, setIsShowInviteModal] = useState<boolean>(false);
  const [isUpdateProfile, setIsUpdateProfile] = useState<boolean>(false);
  const [isShowBScreen, setIsShowBScreen] = useState<boolean>(false);
  const [isChangeModal, setIsChangeModal] = useState<boolean>(false);
  const [isDeleteModal, setIsDeleteModal] = useState<boolean>(false);
  const [isMoveMemberModal, setIsMoveMemberModal] = useState<boolean>(false);
  const [isDelMemberModal, setIsDelMemberModal] = useState<boolean>(false);
  const [isDelGroupModal, setIsDelGroupModal] = useState<boolean>(false);
  const [isGroupSettingModal, setIsGroupSettingModal] = useState<boolean>(
    false,
  );
  const [isCreateGroup, setIsCreateGroup] = useState<boolean>(false);
  const [mode, setMode] = useState<number>(1);
  const [listGroup, setListGroup] = useState<listGroupOptionType>();
  const [chooseUserGroup, setChooseUserGroup] = useState<groupOptionType>();
  const [currentGroup, setCurrentGroup] = useState<string>('');
  const [currentGroupName, setCurrentGroupName] = useState('');
  const [currentUser, setCurrentUser] = useState<number>(0);
  const [currentGroupEditDelete, setCurrentGroupEditDelete] = useState<
    number | null
  >(null);
  const [currentGroupNameEditDelete, setCurrentGroupNameEditDelete] = useState<
    string | null
  >(null);
  const [listUserOfGroup, setListUserOfGroup] = useState<any[]>([]);
  const [chooseUserData, setChooseUserData] = useState<memberGroupType | null>(
    null,
  );
  const [loadingList, setLoadingList] = useState(false);
  const [tempListUser, setTempListUser] = useState<any[]>([]);
  const [tempListApproverGroup, setTempListApproverGroup] = useState<any[]>([]);
  const [memberSelected, setMemberSelected] = useState<number[]>([]);
  const [deleteUser, setDeleteUser] = useState<number>();
  const [scTitle, setScTitle] = useState<string>('');
  const [scContent, setScContent] = useState<string>('');
  const [isScModal, setIsScModal] = useState<boolean>(false);
  const [isShowModalError, setIsShowModalError] = useState<string>('none');
  const [listMemberInvite, setListMemberInvite] = useState<listMemberInvite>(
    [],
  );
  const [dupEmail, setDupEmail] = useState<boolean>(false);
  const [linkCopied, setLinkCopied] = useState<string>('');
  const [dataCurrentMemberPF, setDataCurrentMemberPF] = useState<
    memberProfileUpdate
  >({
    full_name: '',
    group_id: '',
    role: '',
  });
  const [listGroupApprover, setListGroupApprover] = useState<listGroupType>([]);
  const [errorAddGroupFromServer, setErrorAddGroupFromServer] = useState('');
  const [errorEditGroupFromServer, setErrorEditGroupFromServer] = useState('');
  const [groupWantDelete, setGroupWantDelete] = useState<string>('');
  const [createGroupStatus, setCreateGroupStatus] = useState<string>(
    makeRandomKey(30),
  );
  const [editGroupStatus, setEditGroupStatus] = useState<string>(
    makeRandomKey(30),
  );
  const [isEditGroup, setIsEditGroup] = useState(false);
  const [groupWantEdit, setGroupWantEdit] = useState('');
  const [isApprover, setIsApprover] = useState(false);
  const [changeKeyTo, setChangeKeyTo] = useState(makeRandomKey(10));
  const navigate = useNavigate();
  const user = getCurrentUser();

  useEffect(() => {
    const data = {};
    axios
      .post(urls.check_approver, data, { headers: authHeader() })
      .then(res => {
        if (res.data.is_approver) {
          setIsApprover(true);
          getGroupOfApprover();
        } else {
          setIsApprover(false);
          navigate(urls.home);
        }
      })
      .catch();
  }, []);

  useEffect(() => {
    const doneStt = checkInitDone();
    if (doneStt == constants.SETTING) {
      setIsLoading(true);
      navigate(urls.account_setting);
      return;
    }
    if (doneStt == constants.SIGN_IN) {
      setIsLoading(true);
      navigate(urls.sign_in_page);
      return;
    }
    setIsLoading(false);
    // if (!['approver', 'admin'].includes(user.role)) {
    //   navigate(urls.home);
    // }
    if (user.company_id !== null) {
      const fetchData = async () => {
        await axios
          .get(`${urls.get_group_approver}?company_id=${user.company_id}`, {
            headers: authHeader(),
          })
          .then(res => {
            const data = res.data.data;
            setListGroupApprover(data);
            setTempListApproverGroup(data);
            const groupOption: listGroupOptionType = [];
            data &&
              data.map((item: groupType) => {
                const newData: groupOptionType = {
                  value: '',
                  label: '',
                };
                newData.value = item.id.toString();
                newData.label = item.name;
                groupOption.push(newData);
              });
            setListGroup(groupOption);
          })
          .catch(() => {
            setIsLoading(false);
            setIsShowBScreen(true);
            setIsShowModalError('block');
          });
        await axios
          .get(urls.member_info, {
            headers: authHeader(),
          })
          .then(res => {
            const data = res.data.data;
            if (data.group && data.group.id) {
              setCurrentGroup(data.group.id);
              setCurrentGroupEditDelete(data.group.id);
              setCurrentGroupName(data.group.name);
              setCurrentGroupNameEditDelete(data.group.name);
            } else {
              setChangeKeyTo(makeRandomKey(10));
            }
            setCurrentUser(data.id);
          })
          .catch(() => {
            setIsLoading(false);
            setIsShowBScreen(true);
            setIsShowModalError('block');
          });
      };
      fetchData();
    }
  }, []);

  useEffect(() => {
    if (listGroupApprover && listGroupApprover.length) {
      setCurrentGroup(listGroupApprover[0].id);
      setCurrentGroupEditDelete(parseInt(listGroupApprover[0].id));
      setCurrentGroupName(listGroupApprover[0].name);
      setCurrentGroupNameEditDelete(listGroupApprover[0].name);
    }
  }, [changeKeyTo]);

  // Get User when Current Group update
  useEffect(() => {
    if (currentGroup !== null) {
      setLoadingList(true);
      getListMemberInGroup();
    }
  }, [currentGroup]);

  function getListMemberInGroup() {
    if (currentGroup) {
      axios
        .get(`${urls.get_member_in_group}?group_id=${currentGroup}`, {
          headers: authHeader(),
        })
        .then(res => {
          const data = res.data.data;
          setListUserOfGroup(data);
          setTempListUser(data);
          setLoadingList(false);
        })
        .catch(() => {
          setIsLoading(false);
          setIsShowBScreen(true);
          setIsShowModalError('block');
        });
    }
  }

  function onSubmitChange() {
    setIsLoading(true);
    const data = {
      member_id: chooseUserData!.id,
      role: chooseUserData!.role,
      full_name: chooseUserData!.full_name,
      group_id: chooseUserData!.group!.id,
    };
    axios
      .post(urls.handle_update_change, data, { headers: authHeader() })
      .then(() => {
        getListMemberInGroup();
        setIsShowBScreen(true);
        setIsChangeModal(false);
        setIsUpdateProfile(false);
        setIsLoading(false);
        setScTitle('メンバー情報編集完了');
        setScContent('このメンバーの情報編集が完了しました。');
        setIsScModal(true);
      })
      .catch(() => {
        setIsLoading(false);
        setIsShowBScreen(true);
        setIsShowModalError('block');
        setIsChangeModal(false);
        setIsUpdateProfile(false);
      });
  }

  function removeAllModal() {
    setIsShowOption(false);
    setIsShowBScreen(false);
    setIsShowEditMember(false);
    setIsShowInviteModal(false);
    setIsUpdateProfile(false);
    setIsChangeModal(false);
    setIsDelMemberModal(false);
    setIsGroupSettingModal(false);
    setIsCreateGroup(false);
    setIsEditGroup(false);
    setIsDeleteModal(false);
  }

  function clickMenu() {
    setIsShowBScreen(true);
    setIsShowOption(true);
  }

  function confirmChangeModal() {
    if (chooseUserData!.full_name == '') {
      return;
    }
    setIsShowBScreen(true);
    setIsChangeModal(true);
  }

  function closeGroupModal() {
    setIsShowOption(false);
    setIsShowBScreen(false);
    setIsShowEditMember(false);
    setIsShowInviteModal(false);
    setIsUpdateProfile(false);
    setIsChangeModal(false);
    setCurrentGroupEditDelete(parseInt(currentGroup));
    setCurrentGroupNameEditDelete(currentGroupName);
    setMemberSelected([]);
  }

  function showInviteModal() {
    setIsShowBScreen(true);
    setIsShowInviteModal(true);
  }

  function closeInviteModal() {
    removeAllModal();
    setListMemberInvite([]);
  }

  function showGroupModal() {
    setMode(1);
    setIsShowBScreen(true);
    setIsShowEditMember(true);
    setIsShowInviteModal(false);
  }

  function showGroupDeleteModal() {
    setMode(2);
    setIsShowBScreen(true);
    setIsShowEditMember(true);
    setIsShowInviteModal(false);
  }

  function closeIsChangeModal() {
    setIsShowOption(false);
    setIsShowBScreen(true);
    setIsChangeModal(false);
    setIsDeleteModal(false);
    setIsMoveMemberModal(false);
  }

  function closeIsDelMemberModal() {
    setIsShowOption(false);
    setIsShowBScreen(false);
    setIsChangeModal(false);
    setIsDeleteModal(false);
    setIsDelMemberModal(false);
  }

  function clickOnBlackScreen() {
    removeAllModal();
  }

  function closeHandleUpdateProfileModal() {
    removeAllModal();
  }

  async function handleMoveGroup() {
    if (memberSelected.length > 0) {
      setIsLoading(true);
      const formData = new FormData();
      formData.append(
        'group_id',
        currentGroupEditDelete ? currentGroupEditDelete.toString() : '',
      );
      memberSelected.map(item => {
        formData.append('members[]', item.toString());
      });

      await axios
        .post(urls.move_member_group, formData, {
          headers: { ...authHeader(), 'content-type': 'multipart/form-data' },
        })
        .then(() => {
          getListMemberInGroup();
          setIsShowBScreen(true);
          setIsDeleteModal(false);
          setIsChangeModal(false);
          setIsMoveMemberModal(false);
          setIsShowEditMember(false);
          setIsUpdateProfile(false);
          setIsLoading(false);
          setIsShowOption(false);
          setMemberSelected([]);
          setScTitle('所属グループ変更完了');
          setScContent('選択したメンバーの所属グループが変更されました。');
          setIsScModal(true);
        })
        .catch(() => {
          setIsLoading(false);
          setIsShowBScreen(true);
          setIsShowModalError('block');
        });
    }
  }

  function handleUpdateValues(data: memberProfileUpdate) {
    setChooseUserGroup({ value: data.group_id, label: '' });
    if (chooseUserData && chooseUserData.company) {
      setChooseUserData(prevState => ({
        ...prevState,
        id: chooseUserData.id,
        name: chooseUserData.name,
        photo: chooseUserData.photo,
        email: chooseUserData.email,
        group: {
          id: data.group_id,
          name: '',
        },
        company: {
          id: chooseUserData.company!.id,
          name: chooseUserData.company!.name,
          representative: chooseUserData.company!.representative,
          email: chooseUserData.company!.email,
          contact_number: chooseUserData.company!.contact_number,
          email_verified: chooseUserData.company!.email_verified,
        },
        full_name: data!.full_name,
        role: data!.role,
        is_request_change: chooseUserData.is_request_change,
      }));
    }
  }

  async function handleShowUpdateProfileModal(data: number) {
    await axios
      .get(`${urls.get_member_info}?member_id=${data}`, {
        headers: authHeader(),
      })
      .then(res => {
        const data = res.data.data;
        const newData: groupOptionType = {
          value: '',
          label: '',
        };
        newData.value = data.group ? data.group.id : '';
        newData.label = data.group ? data.group.name : '';
        setChooseUserGroup(newData);
        setChooseUserData(data);
        setIsShowBScreen(true);
        setIsUpdateProfile(true);
        setDataCurrentMemberPF({
          full_name: data.full_name,
          group_id: data.group ? data.group.id : '',
          role: data.role,
        });
      })
      .catch(() => {
        setIsLoading(false);
        setIsShowBScreen(true);
        setIsShowModalError('block');
      });
  }

  function filterByName(searchValue: string) {
    if (searchValue.length > 0) {
      setListUserOfGroup(
        tempListUser.filter(item => item.full_name.includes(searchValue)),
      );
    } else {
      setListUserOfGroup(tempListUser);
    }
  }

  function filterByNameGroup(searchValue: string) {
    if (searchValue.length > 0) {
      setListGroupApprover(
        tempListApproverGroup.filter(item => item.name.includes(searchValue)),
      );
    } else {
      setListGroupApprover(tempListApproverGroup);
    }
  }

  async function onHandleApproveMember(member_id: number) {
    setIsLoading(true);
    const data = {
      member_id: member_id,
    };

    await axios
      .post(urls.approve_member, data, {
        headers: authHeader(),
      })
      .then(() => {
        getListMemberInGroup();
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        setIsShowBScreen(true);
        setIsShowModalError('block');
      });
  }

  async function onHandleRejectMember(member_id: number) {
    setIsLoading(true);
    const data = {
      member_id: member_id,
    };

    await axios
      .post(urls.reject_member, data, {
        headers: authHeader(),
      })
      .then(() => {
        getListMemberInGroup();
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        getListMemberInGroup();
        PopUpModal('REMOVE_USER_FAILED', false);
      });
  }

  function onHandleDeleteMember(member_id: number) {
    setIsDeleteModal(true);
    setIsShowBScreen(true);
    setDeleteUser(member_id);
  }

  function handleMoveMemberGroup() {
    setIsMoveMemberModal(true);
    setIsShowBScreen(true);
  }

  function handleSetDelMemberGroup() {
    setIsDelMemberModal(true);
    setIsShowBScreen(true);
  }

  async function onSubmitDelete() {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('group_id', currentGroup ? currentGroup : '');
    formData.append('members[]', deleteUser ? deleteUser.toString() : '');

    await axios
      .post(urls.remove_member, formData, {
        headers: { ...authHeader(), 'content-type': 'multipart/form-data' },
      })
      .then(() => {
        getListMemberInGroup();
        setIsLoading(false);
        setIsDeleteModal(false);
        setDeleteUser(0);
        setScTitle('メンバー情報削除完了');
        setScContent('このメンバーの情報削除が完了しました。');
        setIsScModal(true);
      })
      .catch(() => {
        setIsLoading(false);
        setIsShowBScreen(true);
        setIsShowModalError('block');
        setIsDeleteModal(false);
      });
  }

  function handleUpdateCurrentChoose(data: string) {
    setCurrentGroupEditDelete(parseInt(data));
    const values =
      listGroup &&
      listGroup.filter(item => {
        return item.value === data.toString();
      });
    if (values) {
      setCurrentGroupNameEditDelete(values[0].label);
      setMemberSelected([]);
    }
  }

  function submitChecked(memberId: number, checked: boolean) {
    if (!checked) {
      if (!memberSelected.includes(memberId)) {
        setMemberSelected(prevState => [...prevState, memberId]);
      }
    } else {
      if (memberSelected.includes(memberId)) {
        const index = memberSelected.indexOf(memberId);
        setMemberSelected([
          ...memberSelected.slice(0, index),
          ...memberSelected.slice(index + 1),
        ]);
      }
    }
  }

  async function handleDeleteMemberGroup() {
    if (memberSelected.length > 0) {
      setIsLoading(true);
      const formData = new FormData();
      formData.append(
        'group_id',
        currentGroupEditDelete ? currentGroupEditDelete.toString() : '',
      );
      memberSelected.map(item => {
        formData.append('members[]', item.toString());
      });

      await axios
        .post(urls.remove_member, formData, {
          headers: { ...authHeader(), 'content-type': 'multipart/form-data' },
        })
        .then(() => {
          getListMemberInGroup();
          setIsShowBScreen(true);
          setIsDeleteModal(false);
          setIsChangeModal(false);
          setIsMoveMemberModal(false);
          setIsShowEditMember(false);
          setIsUpdateProfile(false);
          setIsLoading(false);
          setIsShowOption(false);
          setMemberSelected([]);
          setScTitle('一括削除完了');
          setScContent('選択したメンバーが削除されました。');
          setIsScModal(true);
        })
        .catch(() => {
          setIsLoading(false);
          setIsShowBScreen(true);
          setIsShowModalError('block');
          setIsDelMemberModal(false);
        });
    }
  }

  function handleCloseScModal() {
    removeAllModal();
    setIsLoading(false);
    setIsShowBScreen(false);
    setIsScModal(false);
    setScTitle('');
    setScContent('');
  }

  function handleAddNewInvite(data: memberInvite) {
    const check = listMemberInvite.filter(item => item.email === data.email);
    if (check.length === 0) {
      data.role = getRoleNameJP(data.role);
      setListMemberInvite(prevState => [...prevState, data]);
      setDupEmail(false);
      return;
    }
    setDupEmail(true);
    return;
  }

  function handleCancel(data: string) {
    setListMemberInvite(prevState =>
      prevState.filter(item => item.email !== data),
    );
  }

  async function handleInviteMemberList() {
    if (listMemberInvite.length > 0) {
      setIsLoading(true);
      setDupEmail(false);
      const data = {
        group_id: currentGroup,
        members: listMemberInvite,
      };
      await axios
        .post(urls.invite_member_group, data, {
          headers: { ...authHeader() },
        })
        .then(res => {
          getListMemberInGroup();
          setIsShowBScreen(true);
          setIsDeleteModal(false);
          setIsChangeModal(false);
          setIsMoveMemberModal(false);
          setIsShowEditMember(false);
          setIsUpdateProfile(false);
          setIsLoading(false);
          setIsShowOption(false);
          setListMemberInvite([]);
          setLinkCopied(JSON.stringify(res.data.member_link));
        })
        .catch(() => {
          setIsLoading(false);
          setIsShowBScreen(true);
          setIsShowModalError('block');
        });
    }
  }

  function handleSetErrorPopup() {
    setIsShowModalError('none');
    setIsShowBScreen(false);
  }

  function handleOpenGroupModal() {
    setIsShowBScreen(true);
    setIsGroupSettingModal(true);
  }

  function handleCloseGroupSetting() {
    removeAllModal();
  }

  function closeAddGroupModal() {
    removeAllModal();
    setIsShowBScreen(true);
    setIsGroupSettingModal(true);
  }

  function onCreateModal() {
    removeAllModal();
    setIsShowBScreen(true);
    setIsCreateGroup(true);
  }

  async function getGroupOfApprover() {
    await axios
      .get(`${urls.get_group_approver}?company_id=${user.company_id}`, {
        headers: authHeader(),
      })
      .then(res => {
        setListGroupApprover(res.data.data);
      })
      .catch(() => {
        setIsLoading(false);
        setIsShowBScreen(true);
        setIsShowModalError('block');
      });
  }

  function handleAddNewGroup(
    groupName: string,
    listOwner: number[],
    listApprover: number[],
  ) {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('group_name', groupName);
    formData.append('company_id', user.company_id);
    listOwner.map(item => {
      formData.append('owners[]', item.toString());
    });
    listApprover.map(item => {
      formData.append('approvers[]', item.toString());
    });

    axios
      .post(urls.create_new_group, formData, { headers: authHeader() })
      .then(() => {
        setIsLoading(false);
        getGroupOfApprover();
        setIsScModal(true);
        setIsCreateGroup(false);
        setCreateGroupStatus(makeRandomKey(30));
        setScTitle('グループ作成完了');
        setScContent('このグループが作成されました。');
      })
      .catch(err => {
        setIsLoading(false);
        setErrorAddGroupFromServer(extractError(err.response.data.errors));
      });
  }

  function handleEditNewGroup(
    groupId: string,
    groupName: string,
    listOwner: number[],
    listApprover: number[],
  ) {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('group_name', groupName);
    formData.append('company_id', user.company_id);
    formData.append('group_id', groupId);
    listOwner.map(item => {
      formData.append('owners[]', item.toString());
    });
    listApprover.map(item => {
      formData.append('approvers[]', item.toString());
    });

    axios
      .post(urls.update_group, formData, { headers: authHeader() })
      .then(() => {
        setIsLoading(false);
        getGroupOfApprover();
        setIsScModal(true);
        setIsEditGroup(false);
        setEditGroupStatus(makeRandomKey(30));
        setScTitle('グループ編集完了');
        setScContent('このグループが編集されました。');
        setIsShowBScreen(true);
        if (currentGroup === groupId) {
          setCurrentGroup(groupId);
          setCurrentGroupName(groupName);
        }
        setGroupWantEdit('');
        setErrorEditGroupFromServer('');
      })
      .catch(err => {
        setIsLoading(false);
        setErrorEditGroupFromServer(extractError(err.response.data.errors));
      });
  }

  function handleDeleteGroup(group_id: string) {
    setIsDelGroupModal(true);
    setGroupWantDelete(group_id);
  }

  function closeIsDelGroupModal() {
    setIsDelGroupModal(false);
  }

  function handleDeleteGroupConfirmed() {
    setIsLoading(true);
    setIsDelGroupModal(false);
    axios
      .post(
        urls.delete_group,
        { group_id: groupWantDelete },
        { headers: authHeader() },
      )
      .then(() => {
        setIsLoading(false);
        setIsGroupSettingModal(false);
        getGroupOfApprover();
        setIsScModal(true);
        setScTitle('グループ削除完了');
        setScContent('このグループ削除が完了しました。');
        if (groupWantDelete === currentGroup) {
          setCurrentGroup(listGroupApprover[0].id);
          setCurrentGroupName(listGroupApprover[0].name);
        }
        setGroupWantDelete('');
      })
      .catch(() => {
        setIsLoading(false);
        setIsShowBScreen(true);
        setIsShowModalError('block');
      });
  }

  function handleChooseGroup(group_id: string, group_name: string) {
    removeAllModal();
    setIsGroupSettingModal(false);
    setCurrentGroup(group_id);
    setCurrentGroupName(group_name);
    setIsShowBScreen(false);
  }

  function handleUpdateGroup(group_id: string) {
    setIsEditGroup(true);
    setGroupWantEdit(group_id);
    setIsGroupSettingModal(false);
  }

  function handleCloseEditGroup() {
    removeAllModal();
    setIsShowBScreen(true);
    setIsGroupSettingModal(true);
  }

  return (
    <div className="member-list-st-pages">
      <Loading display={isLoading ? 'block' : 'none'} />
      <BlackScreen
        display={isShowBScreen}
        onClick={clickOnBlackScreen}
        style={{
          zIndex:
            isChangeModal ||
            isDeleteModal ||
            isMoveMemberModal ||
            isDelMemberModal ||
            isDelGroupModal
              ? '6'
              : '1',
        }}
      />
      {/* group-settings */}
      <div className="group-settings">
        <div
          className="selectGroup__in_member_list"
          onClick={handleOpenGroupModal}>
          <div className="group-name">{currentGroupName}</div>
          <div className="group-select">
            <img src={OrangeArrow} />
          </div>
        </div>
      </div>
      {/* groups-invite */}
      <div className="group-invite">
        <div className="groupInvite__wrap">
          <div className="iconInvite">
            <img src={InviteImg} />
          </div>
          <div className="btnInvite">
            <p>メンバーを招待する</p>
            <OrangeButton
              text="招待"
              style={{ width: '250px', height: '45px', lineHeight: '45px' }}
              onClick={showInviteModal}
            />
          </div>
        </div>
      </div>
      {/* member-group-list */}
      <div
        className="group-member-list"
        style={{ marginBottom: isShowOption ? '250px' : '' }}>
        <div className="groupMember__wrap">
          <div className="inputSearch__wrap">
            <div className="searchBtn">
              <img src={SearchButton} />
            </div>
            <div className="inputSearch">
              <input
                className="form-control"
                placeholder="Tanaka"
                onChange={e => filterByName(e.target.value)}
              />
            </div>
          </div>
          <div className="listMember">
            {loadingList ? (
              <LoadingWhenWait text={words.loadingMultiSelect} />
            ) : (
              <ListMember
                listType={1}
                onHandleUpdateProfile={data =>
                  handleShowUpdateProfileModal(data)
                }
                onHandleApproveMember={data => onHandleApproveMember(data)}
                onHandleRejectMember={data => onHandleRejectMember(data)}
                onHandleDeleteMember={data => onHandleDeleteMember(data)}
                submitChecked={() => console.info('Check on ListType 2')}
                listMember={listUserOfGroup}
                mode={0}
              />
            )}
          </div>
        </div>
      </div>
      {/* menu-member */}
      <div
        className="group-menu-member"
        onClick={clickMenu}
        style={{ display: isScModal ? 'none' : 'block' }}>
        <img src={MenuCircle} />
      </div>
      <div
        className="group-edit-all-member"
        style={{ display: isShowOption ? 'flex' : 'none' }}
        onClick={showGroupModal}>
        <span className="edit-member-text">所属グループ編集</span>{' '}
        <img src={PencilTrash} />
      </div>
      <div
        className="group-del-all-member"
        style={{ display: isShowOption ? 'flex' : 'none' }}
        onClick={showGroupDeleteModal}>
        <span className="del-member-text">一括削除</span>{' '}
        <img src={OrangeTrash} />
      </div>
      <ChangeGroupModal
        isShow={isShowEditMember}
        onHideModal={closeGroupModal}
        mode={mode}
        groupName={currentGroupNameEditDelete ?? ''}
        groupId={currentGroupEditDelete ?? 0}
        currentGroupName={currentGroupName ?? ''}
        listMember={listUserOfGroup}
        listGroup={listGroup ?? [{ value: 'A', label: 'BBB' }]}
        handleUpdateCurrentChoose={data => handleUpdateCurrentChoose(data)}
        submitChecked={(member_id, checked) =>
          submitChecked(member_id, checked)
        }
        memberSelected={memberSelected}
        handleMoveMemberGroup={handleMoveMemberGroup}
        handleDeleteMemberGroup={handleSetDelMemberGroup}
        current={currentUser}
      />
      <InviteMemberModal
        isShow={isShowInviteModal}
        onHideModal={closeInviteModal}
        handleAddNewInvite={handleAddNewInvite}
        listMemberInvite={listMemberInvite}
        dupEmail={dupEmail}
        handleCancel={data => handleCancel(data)}
        handleInviteMemberList={handleInviteMemberList}
        copyAllLink={() => navigator.clipboard.writeText(linkCopied)}
      />
      <MemberInGroupModal
        isShow={isUpdateProfile}
        onHideModal={closeHandleUpdateProfileModal}
        listGroup={listGroup ?? [{ value: 'A', label: 'BBB' }]}
        groupChooseUser={chooseUserGroup ?? { value: 'A', label: 'BBB' }}
        chooseUserData={chooseUserData}
        handleUpdateValues={data => handleUpdateValues(data)}
        dataCurrentMemberPF={dataCurrentMemberPF}
        handleConfirmChange={confirmChangeModal}
        handleUpdateAvatar={() => getListMemberInGroup()}
      />
      <GroupSettingModal
        isShow={isGroupSettingModal}
        onHideModal={handleCloseGroupSetting}
        listGroup={listGroupApprover ?? [{ id: 'A', name: 'BBB' }]}
        onCreateModal={onCreateModal}
        handleDeleteGroup={group_id => handleDeleteGroup(group_id)}
        handleChooseGroup={(group_id, group_name) =>
          handleChooseGroup(group_id, group_name)
        }
        filterGroupName={search => filterByNameGroup(search)}
        handleUpdateGroup={group_id => handleUpdateGroup(group_id)}
      />
      <AddGroupModal
        isShow={isCreateGroup}
        onHideModal={closeAddGroupModal}
        listUserOfGroup={listUserOfGroup}
        handleAddNewGroup={(groupName, listOwner, listApprover) =>
          handleAddNewGroup(groupName, listOwner, listApprover)
        }
        errorAddGroupFromServer={errorAddGroupFromServer}
        createGroupStatus={createGroupStatus}
      />

      <EditGroupModal
        isShow={isEditGroup}
        onHideModal={handleCloseEditGroup}
        listUserOfGroup={listUserOfGroup}
        group={groupWantEdit}
        handleEditNewGroup={(groupId, groupName, listOwner, listApprover) =>
          handleEditNewGroup(groupId, groupName, listOwner, listApprover)
        }
        editGroupStatus={editGroupStatus}
        errorEditGroupFromServer={errorEditGroupFromServer}
      />

      <ConfirmChangeModal
        isShow={isChangeModal}
        onHideModal={closeIsChangeModal}
        onOk={onSubmitChange}
      />
      <ConfirmDeleteModal
        isShow={isDeleteModal}
        onHideModal={closeIsChangeModal}
        onOk={onSubmitDelete}
      />
      <ConfirmMoveMemberModal
        isShow={isMoveMemberModal}
        onHideModal={closeIsChangeModal}
        onOk={handleMoveGroup}
      />
      <ConfirmDelMemberModal
        isShow={isDelMemberModal}
        onHideModal={closeIsDelMemberModal}
        onOk={handleDeleteMemberGroup}
      />
      <ConfirmDelGroup
        isShow={isDelGroupModal}
        onHideModal={closeIsDelGroupModal}
        onOk={handleDeleteGroupConfirmed}
      />
      <ConfirmSuccessModal
        isShow={isScModal}
        onHideModal={closeIsChangeModal}
        onOk={handleCloseScModal}
        title={scTitle}
        content={scContent}
      />
      <SignedUpSuccessModal
        display={isShowModalError}
        onClick={handleSetErrorPopup}
        icon={ErrorCircle}
        message="不明なエラーが発生しました。"
        textButton="OK"
      />
    </div>
  );
}
