import React from 'react';
import styled from 'styled-components';
import { theme } from '../../../config';
import words from '../../../constants/words';
import { AnchorTag } from '../../atoms/AnchorTag';

export type Props = {
  id: number;
  isAddingField: boolean;
  onPressEdit?: () => void;
  onPressDelete?: (id: number) => void;
  onPressSave?: () => void;
  onPressCancel?: (id: number) => void;
};

const DefaultButtonsWrapper = styled.div<{ isAddUpdateField: boolean }>`
  width: 180px;
  && {
    display: flex;
    div {
      font-size: ${props => (props.isAddUpdateField ? '16px' : '13px')};
    }

    div.cancelBtn {
      flex: 1;
    }
    div.saveBtn {
      flex: 1;
      div {
        color: ${theme.colors.anchorLinkColor};
      }
    }

    div:active {
      outline: none;
    }
  }
`;

const LoginFieldActions = ({
  id,
  isAddingField,
  onPressEdit = () => {},
  onPressSave = () => {},
  onPressCancel = () => {},
  onPressDelete = () => {},
}: Props): React.ReactElement => {
  return (
    <DefaultButtonsWrapper isAddUpdateField={isAddingField}>
      {isAddingField ? (
        <>
          <AnchorTag
            onPress={onPressSave}
            title={words.keep}
            type="edit"
            className="saveBtn"
          />
          <AnchorTag
            onPress={() => onPressCancel(id)}
            title={words.cancel}
            style={{ color: theme.colors.text02 }}
            className="cancelBtn"
          />
        </>
      ) : (
        <>
          <AnchorTag
            onPress={onPressEdit}
            title={words.edit}
            type="edit"
            className="saveBtn"
          />
          <AnchorTag
            onPress={() => onPressDelete(id)}
            title={words.delete}
            type="delete"
            className="cancelBtn"
          />
        </>
      )}
    </DefaultButtonsWrapper>
  );
};

export default LoginFieldActions;
