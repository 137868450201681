import { buildUpdateCompany, updateCompanyUseCase } from './update-company';
import { buildFindCompany, findCompanyUseCase } from './find-company';
import {
  buildVerifyCompanyEmail,
  verifyCompanyEmailUseCase,
} from './verify-company-email';
import {
  buildResendVerificationEmail,
  resendVerificationEmailUseCase,
} from './resend-verification-email';

import CompanyRepository from '../../ports/CompanyRepository';

export default (dependencies: {
  companyRepo: CompanyRepository;
}): {
  updateCompany: updateCompanyUseCase;
  findCompany: findCompanyUseCase;
  verifyCompanyEmail: verifyCompanyEmailUseCase;
  resendVerificationEmail: resendVerificationEmailUseCase;
} => {
  const { companyRepo } = dependencies;
  const updateCompany = buildUpdateCompany({ companyRepo });
  const findCompany = buildFindCompany({ companyRepo });
  const verifyCompanyEmail = buildVerifyCompanyEmail({ companyRepo });
  const resendVerificationEmail = buildResendVerificationEmail({ companyRepo });

  return {
    updateCompany,
    findCompany,
    verifyCompanyEmail,
    resendVerificationEmail,
  };
};
