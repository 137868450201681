import React, { useEffect, useState } from 'react';
import './InviteHandleMail.scss';
import { Loading } from 'components/molecules/Loading';
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import urls from 'constants/urls';
import authHeader from 'api/auth-header';

export default function LoginPage() {
  const [isLoading, setIsLoading] = useState('block');
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const uid = searchParams.get('uid');
  const token = searchParams.get('token');

  useEffect(() => {
    const data = { uid, token };
    axios
      .post(urls.handle_email_invite, data, { headers: authHeader() })
      .then(() => {
        navigate(urls.sign_in_page);
      })
      .catch(() => {
        navigate(urls.sign_in_page);
      });
  }, []);

  return (
    <div className="invite-handle-pages">
      <Loading display={isLoading} />
    </div>
  );
}
