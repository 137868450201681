import React from 'react';
import styled from 'styled-components';
import themes from '../../../config/themes';
import words from '../../../constants/words';
import { NotificationTable } from '../../organisms/DataTable';
import { NoNotif } from '../../atoms/Icons';
import { NotificationItem } from '../../organisms/DataTable/NotificationTable';

export const NotifTableContainer = styled.div`
  width: 100%;
  height: 100vh;
  padding: 2px;
  background: ${themes.main.colors.white};
  box-shadow: 0px 0px 8px rgba(236, 215, 192, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media ${themes.main.breakpoints.mobile} {
    min-height: 100%;
    height: 100%;
  }
`;

const NoNotifTitle = styled.text`
  font-size: 28px;
  line-height: 36px;
  color: ${themes.main.colors.black};
  padding-top: 15px;
  padding-bottom: 13px;
`;

const NoNotifLabel = styled.text`
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.16px;
  color: ${themes.main.colors.black};
`;

export type PropsNotifications = {
  notifRows: NotificationItem[];
  isLoading?: boolean;
  className?: string;
  handleNotifClick: (id: number) => void;
  page?: number;
  pageSize?: number;
  totalItems?: number;
  handlePageChange: (data: { page: number; pageSize: number }) => void;
};

const Component = ({
  notifRows,
  className,
  isLoading,
  page,
  pageSize,
  totalItems,
  handleNotifClick,
  handlePageChange,
}: PropsNotifications): React.ReactElement => {
  return notifRows.length < 1 ? (
    <NotifTableContainer>
      <NoNotif />
      <NoNotifTitle>{words.noNotifications}</NoNotifTitle>
      <NoNotifLabel>{words.noNotifLabel}</NoNotifLabel>
    </NotifTableContainer>
  ) : (
    <NotificationTable
      className={className}
      isLoading={isLoading}
      notifRows={notifRows}
      page={page}
      pageSize={pageSize}
      totalItems={totalItems}
      selectedRow={handleNotifClick}
      onChangePage={handlePageChange}
    />
  );
};

export default Component;
