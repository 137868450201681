import MemberRepository from '../../usecases/ports/MemberRepository';
import { HttpAdapter } from '../../usecases/ports/HttpAdapter';
import { PaginationQuery } from '../../domain/entities/request';
import {
  Member,
  CreateMemberType,
  UpdateMemberType,
  ValidateMemberRole,
  CheckUnassignedExistsType,
  CreateMemberField,
  MemberField,
} from '../../domain/entities/member';
import { PaginatedResponse } from '../../domain/entities/response';
import axios, { AxiosResponse } from 'axios';

export default class MemberRepositoryImpl implements MemberRepository {
  httpAdapter: HttpAdapter;
  urls: {
    [key: string]: (query: { [key: string]: string | number }) => string;
  };

  constructor(
    httpAdapter: HttpAdapter,
    urls: {
      [key: string]: (query: { [key: string]: string | number }) => string;
    },
  ) {
    this.httpAdapter = httpAdapter;
    this.urls = urls;
  }

  fetch = async (
    query: PaginationQuery & {
      companyId: number;
      groupId?: number | undefined;
      roleName?: string | undefined;
      name?: string | undefined;
    },
  ): Promise<PaginatedResponse & { results: Member[] }> => {
    const { companyId } = query;
    const response = await this.httpAdapter.get(
      this.urls.getMembers({
        companyId: `${companyId}`,
      }),
      {
        params: query,
      },
    );

    const members = {
      ...response.data,
      results: response.data.results.map(
        (
          member: Member & {
            group_id: number;
            company_id: number;
            role: string;
            last_login: string | null;
            is_done_initial?: boolean;
            is_last_admin?: boolean;
          },
        ) => {
          return {
            ...member,
            roleName: member.role,
            groupId: member.group_id,
            companyId: member.company_id,
            lastLogin: member.last_login,
            isDoneInitial: member.is_done_initial,
            isLastAdmin: member.is_last_admin,
          };
        },
      ),
    };
    return members;
  };

  fetchMultiSelect = async (
    query: PaginationQuery & {
      companyId: number;
      groupId?: number | undefined;
      roleName?: string | undefined;
      name?: string | undefined;
    },
  ): Promise<PaginatedResponse & { results: Member[] }> => {
    const { companyId, page_size } = query;
    const response = await this.httpAdapter.get(
      this.urls.getMembers({
        companyId: `${companyId}`,
      }),
      {
        params: query,
      },
    );

    return page_size
      ? {
          ...response.data,
          results: response.data.results.map(
            (
              member: Member & {
                group_id: number;
                company_id: number;
                role: string;
                last_login: string | null;
                is_done_initial?: boolean;
              },
            ) => {
              return {
                ...member,
                roleName: member.role,
                groupId: member.group_id,
                companyId: member.company_id,
                lastLogin: member.last_login,
                isDoneInitial: member.is_done_initial,
              };
            },
          ),
        }
      : {
          count: response.data.length,
          next: null,
          previous: null,
          results: response.data,
        };
  };

  create = async (
    params: CreateMemberType,
  ): Promise<Member & { temporary_password: string }> => {
    const { companyId, name, groupId, roleName } = params;
    const payload = {
      group: groupId,
      role: roleName,
      name,
    };

    const response = await this.httpAdapter.post(
      this.urls.createMember({ companyId: companyId.toString() }),
      payload,
    );

    return response.data;
  };

  delete = async (payload: {
    companyId: number;
    memberId: number;
  }): Promise<void> => {
    await this.httpAdapter.delete(
      this.urls.memberDetails({
        companyId: `${payload.companyId}`,
        memberId: `${payload.memberId}`,
      }),
      {},
    );
  };

  get = async (payload: {
    companyId: number;
    memberId: number;
  }): Promise<Member> => {
    const response = await this.httpAdapter.get(
      this.urls.memberDetails(payload),
      {},
    );
    const data = response.data;

    const member: Member = {
      ...data,
      groupId: data.group_id,
      companyId: data.company_id,
      roleName: data.role,
      lastLogin: data.last_login,
      photoUrl: data.photo,
      isDoneInitial: data.is_done_initial,
      isLastApproverInAssignedGroup: data.is_last_approver_in_assigned_group,
    };

    return member;
  };

  patch = async (
    companyId: number,
    memberId: number,
    payload: UpdateMemberType,
  ): Promise<Member> => {
    if (payload.photo) {
      const form_data = new FormData();
      form_data.append('photo', payload.photo);
      const response = await axios
        .patch(this.urls.memberDetails({ companyId, memberId }), form_data, {
          headers: {
            'content-type': 'multipart/form-data',
          },
        })
        .then(res => {
          const data = res.data;
          const member: Member = {
            ...data,
            groupId: data.group_id,
            companyId: data.company_id,
            roleName: data.role,
            lastLogin: data.last_login,
            photoUrl: data.photo,
          };
          return member;
        })
        .catch(err => {
          return err;
        });
      return response;
    } else {
      const response = await this.httpAdapter.patch(
        this.urls.memberDetails({ companyId, memberId }),
        payload,
      );
      return response;
    }
  };

  batchUpdateGroup = async (
    companyId: number,
    memberIds: number[],
    groupId: number,
  ): Promise<AxiosResponse> => {
    const convertedMemberIds = memberIds.join(',');
    let params = {};

    if (groupId === 0) {
      params = {
        memberIds: convertedMemberIds,
      };
    } else {
      params = {
        groupId,
        memberIds: convertedMemberIds,
      };
    }

    const response = await this.httpAdapter.patch(
      this.urls.updateMembersGroup({ companyId }),
      JSON.stringify(params),
    );

    return response;
  };

  checkUnassignedExists = async (
    companyId: number,
  ): Promise<CheckUnassignedExistsType> => {
    const response = await this.httpAdapter.get(
      this.urls.checkIfUnassignedExists({ companyId }),
      {},
    );

    return response.data;
  };

  generateTemporaryPassword = async (
    companyId: number,
    memberId: number,
  ): Promise<{ temporary_password: string }> => {
    const response = await this.httpAdapter.patch(
      this.urls.generateTemporaryPassword({ companyId, memberId }),
      {},
    );
    return response.data;
  };

  filterDeletableMembers = async (
    companyId: number,
    memberIds: string,
  ): Promise<string[]> => {
    const response = await this.httpAdapter.get(
      this.urls.filterDeletableMembers({ companyId, memberIds }),
      {},
    );
    return response.data;
  };

  batchDeleteMembers = async (
    companyId: number,
    memberIds: string,
  ): Promise<void> => {
    await this.httpAdapter.delete(
      this.urls.deleteMembers({ companyId, memberIds }),
      {},
    );
  };

  validateMemberRoleChangeable = async (
    companyId: number,
    memberId: number,
  ): Promise<ValidateMemberRole> => {
    const response = await this.httpAdapter.get(
      this.urls.validateMemberRoleChangeable({ companyId, memberId }),
      {},
    );

    return response.data;
  };

  createMemberFields = async (
    companyId: number,
    customFieldValues: CreateMemberField[],
    memberId: number,
  ): Promise<void> => {
    const profileImageField = customFieldValues.filter(field => field.photo);
    if (profileImageField.length > 0) {
      const form_data = new FormData();
      form_data.append('photo', profileImageField[0].photo as File);
      form_data.append('custom', JSON.stringify(customFieldValues));
      form_data.append('member_id', JSON.stringify(memberId));
      const response = await this.httpAdapter.post(
        this.urls.createMemberFields({ companyId }),
        form_data,
      );
      return response.data;
    } else {
      const response = await this.httpAdapter.post(
        this.urls.createMemberFields({ companyId }),
        {
          custom: JSON.stringify(customFieldValues),
          member_id: memberId,
        },
      );

      return response.data;
    }
  };

  updateMemberFields = async (
    companyId: number,
    customFields: MemberField[],
  ): Promise<void> => {
    const response = await this.httpAdapter.put(
      this.urls.updateMemberFields({ companyId }),
      {
        custom_fields: customFields,
      },
    );

    return response.data;
  };
}
