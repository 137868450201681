import { object, string } from 'yup';
import words from 'constants/words';
import {
  accountSettingSetCompanyErrType,
  accountSettingProfileErrType,
} from 'type/member';
import { getErrors } from 'utils/common';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export async function validateUpdateCompanyId(
  data: accountSettingSetCompanyErrType,
) {
  const schema = object().shape({
    company_id: string().required(words.memberSignupCompanyIdRequired),
    full_name: string()
      .min(6, words.minRequiredFullname)
      .required(words.memberSignupFullnameRequired),
    user_id: string().required(words.userIdRequired),
  });

  const result = await schema
    .validate(data, { abortEarly: false })
    .catch(err => err);
  return getErrors(result.inner) as accountSettingSetCompanyErrType;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export async function validateUpdateFullProfile(
  data: accountSettingProfileErrType,
) {
  const schema = object().shape({
    company_id: string().required(words.memberSignupCompanyIdRequired),
    full_name: string()
      .min(6, words.minRequiredFullname)
      .required(words.memberSignupFullnameRequired),
    role: string().required(words.roleRequired),
    group_id: string().required(words.groupRequired),
  });

  const result = await schema
    .validate(data, { abortEarly: false })
    .catch(err => err);
  return getErrors(result.inner) as accountSettingProfileErrType;
}
