import React from 'react';
import styled from 'styled-components';
import DefaultUserImage from '../../../assets/images/default-profile-photo.png';
import { Loading } from '../Loading';
import { theme } from '../../../config';
import BlurDefaulImage from '../../../assets/images/blur-default-profile-image.jpg';
import ProgressiveImage from 'react-progressive-graceful-image';

export type ImageDisplayProps = {
  imageSrc?: string;
  isLoading: boolean;
  width?: string;
  height?: string;
  loaderWidth?: number;
  loaderHeight?: number;
};

const Image = styled.img<{ width: string; height: string }>`
  border: 1px solid ${theme.colors.listBorder};
  box-sizing: border-box;
  height: ${props => props.height};
  width: ${props => props.width};
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
`;

export const LoadingImage = styled.div<{ width: string; height: string }>`
  width: ${props => props.width};
  height: ${props => props.height};
  border-radius: 34px;
  &&&&& {
    .bx--loading-overlay {
      position: inherit;
      background-color: #f5e7d8;
      border-radius: 100%;
      width: ${props => props.width};
      height: ${props => props.height};
    }
  }
`;

const ImageDisplay = ({
  imageSrc = DefaultUserImage,
  isLoading,
  width = '68px',
  height = '68px',
  loaderWidth,
  loaderHeight,
}: ImageDisplayProps): React.ReactElement => {
  return isLoading ? (
    <LoadingImage width={width} height={height}>
      <Loading width={loaderWidth} height={loaderHeight} />
    </LoadingImage>
  ) : (
    <ProgressiveImage src={imageSrc} placeholder={BlurDefaulImage}>
      {(src: string | undefined) => (
        <Image src={src} alt="prof pic" width={width} height={height} />
      )}
    </ProgressiveImage>
  );
};

export default ImageDisplay;
