import { Member } from '../../../domain/entities/member';
import { PaginationQuery } from '../../../domain/entities/request';
import { PaginatedResponse } from '../../../domain/entities/response';
import MemberRepository from '../../ports/MemberRepository';

export type fetchMembersUseCase = (
  query: PaginationQuery & {
    companyId: number;
    groupId?: number | undefined;
    roleName?: string | undefined;
    name?: string | undefined;
  },
) => Promise<PaginatedResponse & { results: Member[] }>;

export const buildFetchMembers = (dependencies: {
  memberRepo: MemberRepository;
}): fetchMembersUseCase => {
  const { memberRepo } = dependencies;

  const fetchMembers: fetchMembersUseCase = async query =>
    memberRepo.fetch(query);

  return fetchMembers;
};
