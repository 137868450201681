import React, { useState, useCallback } from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';

import words from '../../../constants/words';
import { theme } from '../../../config';
import data from '../../../constants/data';

import { navigateToErrorPage } from '../../../utils/handleErrorPage';

import { DataTable } from '../DataTable';
import { Dropdown } from '../../molecules/Dropdown';

import { useGlobalState } from '../../../hooks/global';
import { useRecordHooks } from '../../../hooks/record';
import { EntryApprover } from '../../../domain/entities/entry';
import { RecordApproval } from '../../../domain/entities/record';
import { EmptyResultDisplay } from '../EmptyResultDisplay/';
import { UpdatedRecord } from '../../pages/RecordApproval/RecordApproval';

import { useWindowDimensions } from '../../../utils/screenDimension';
import { MOBILE_MAX_SCREEN } from '../../../constants/breakpoints';

const Container = styled.div`
  background-color: ${theme.colors.white};
`;

const StyledEmptyDisplay = styled(EmptyResultDisplay)`
  & div {
    white-space: pre-wrap;
  }
  @media ${theme.breakpoints.mobile} {
    padding-top: 85px;
    padding-bottom: 35%;
    height: 100vh;
  }
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 12px 0;
  background-color: ${theme.colors.ui01};

  & > div {
    margin-left: 16px;
  }

  @media ${theme.breakpoints.mobile} {
    display: none;
    flex-direction: column;
    padding: 8px;
    border-bottom: 4px solid ${theme.colors.white};

    & > div {
      margin: 0px 0px 0px 0px;
    }
  }
`;

const DropdownWrapper = styled.div<{ paddingBottom?: string }>`
  width: 320px;

  &.dropdown-filter-all div.bx--list-box__menu > div:first-child > div {
    color: ${theme.colors.text03};
  }

  @media ${theme.breakpoints.mobile} {
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: ${(props): string =>
      props.paddingBottom ? props.paddingBottom : '1px'};
  }
`;

const StyledDataTable = styled(DataTable)`
  @media ${theme.breakpoints.mobile} {
    border-top: 4px solid ${theme.colors.white};

    thead,
    tbody {
      display: none;
    }

    .bx--pagination {
      overflow: hidden;
    }
  }
`;

const ApprovalStatusWrapper = styled.span`
  &.approval-status-unapproved {
    color: ${theme.colors.text02};
  }

  &.approval-status-approved {
    color: ${theme.colors.support02};
  }

  &.approval-status-rejected {
    color: ${theme.colors.support01};
  }

  @media ${theme.breakpoints.mobile} {
    &.approval-status-unapproved {
      color: ${theme.colors.ui04};
    }

    &.approval-status-approved {
      color: ${theme.colors.inverseSupport02};
    }

    &.approval-status-rejected {
      color: ${theme.colors.inverseSupport01};
    }
  }
`;

const MobileContainer = styled.div`
  @media ${theme.breakpoints.pc} {
    display: none;
  }

  @media ${theme.breakpoints.tablet} {
    display: none;
  }

  @media ${theme.breakpoints.mobile} {
    display: block;

    & > ${FilterContainer} {
      display: block;
    }
  }
`;

const MobileListItemData = styled.div`
  & > .approval-application-date {
    color: ${theme.colors.ui04};
    font-size: 12px;
    line-height: 16px;
  }

  & > .approval-applicant {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
  }

  & > .approval-ranking-name,
  & > .approval-main-approver {
    font-size: 13px;
    line-height: 18px;
  }
`;

const MobileListItem = styled.div`
  display: flex;
  flex-direction: row;
  padding: 13px 0 19px 0;
  background-color: ${theme.colors.ui01};

  & > ${MobileListItemData}:first-child {
    width: 46px;
    padding: 0 0 0 15px;
    font-size: 13px;
    line-height: 18px;
  }

  & > ${MobileListItemData}:not(:first-child):not(:last-child) {
    width: 1000px;
    padding: 0 30px;
  }

  & > ${MobileListItemData}:last-child {
    padding: 0 16px;
    text-align: right;
    font-size: 11px;
    line-height: 18px;
    font-weight: 500;
    width: 100%;
  }
`;

const MobileListContainer = styled.div`
  & > ${MobileListItem}:first-child {
    border-bottom: 2px solid ${theme.colors.white};
  }

  & > ${MobileListItem}:not(:first-child):not(:last-child) {
    border-top: 2px solid ${theme.colors.white};
    border-bottom: 2px solid ${theme.colors.white};
  }

  & > ${MobileListItem}:last-child {
    border-top: 2px solid ${theme.colors.white};
  }
`;

const MAIN_APPROVER_HEADERS = [
  { key: 'number', header: 'No' },
  { key: 'applicationDate', header: words.applicationDate },
  { key: 'applicant', header: words.applicant },
  { key: 'rankingName', header: words.rankingName },
  { key: 'status', header: words.status },
];

const SECONDARY_APPROVER_HEADERS = [
  { key: 'number', header: 'No' },
  { key: 'applicationDate', header: words.applicationDate },
  { key: 'applicant', header: words.applicant },
  { key: 'rankingName', header: words.rankingName },
  { key: 'mainApprover', header: words.mainApprover },
  { key: 'status', header: words.status },
];

const statusDropdownItems = [{ id: 'all', name: words.dropdownAll }].concat(
  data.approvalStatus.filter(e => e.id !== 'withdraw'),
);

export type RecordRowData = {
  id: string;
  number: number;
  applicationDate: string;
  applicant: string;
  rankingName: string;
  mainApprover: string;
  mainApproverId: number;
  allApprovers: EntryApprover[];
  status: JSX.Element;
};

export type Props = {
  isMainApprover?: boolean;
  newlyUpdatedRecord: UpdatedRecord | undefined;
};

const Component = ({
  isMainApprover = false,
  newlyUpdatedRecord,
}: Props): React.ReactElement => {
  const { width } = useWindowDimensions();
  const { pathname: locationPathname } = useLocation();
  const navigate = useNavigate();
  const {
    useFetchRecordApprovals,
    useFetchRecordApprovalsRankingNames,
  } = useRecordHooks();
  const { fetchRecordApprovals } = useFetchRecordApprovals();
  const {
    fetchRecordApprovalsRankingNames,
  } = useFetchRecordApprovalsRankingNames();

  const {
    useCurrentUser: { currentUser },
  } = useGlobalState();
  const companyId = currentUser?.companyId;
  const memberId = currentUser?.memberId;
  const [queryParams, setQueryParams] = useState<{
    page: number;
    pageSize: number;
    rankingName: string;
    status: string;
    approverType: 'main' | 'sub';
  }>({
    page: 1,
    pageSize: 10,
    rankingName: '',
    status: '',
    approverType: isMainApprover ? 'main' : 'sub',
  });

  const {
    data: rankingNameDropdownItems,
    isFetching: isFetchingRankingNames,
  } = useQuery(
    ['approval-ranking-name', queryParams],
    async () => {
      if (!companyId) {
        throw new Error(`'company id' not set`);
      }
      if (!memberId) {
        throw new Error(`'member id' not set`);
      }

      const response = await fetchRecordApprovalsRankingNames({
        companyId: companyId,
        memberId: memberId,
        approverType: queryParams?.approverType,
      });

      const rankingNames = [
        {
          id: 'all',
          name: words.dropdownAll,
        },
      ].concat(
        response.results.map((name, index) => ({
          id: `${index + 1}`,
          name: name,
        })),
      );
      return rankingNames;
    },
    {
      onError: (err: AxiosError) => {
        navigateToErrorPage(navigate, err.response?.status, locationPathname);
      },
    },
  );

  const {
    data: { results: approvalListResults, count: approvalListCount } = {
      results: [],
      count: 0,
    },
    isLoading: isApprovalListLoading,
  } = useQuery(
    ['approval-list', queryParams, newlyUpdatedRecord],
    async () => {
      if (!companyId) {
        throw new Error(`'company id' not set`);
      }
      if (!memberId) {
        throw new Error(`'member id' not set`);
      }

      const filterQuery: { [key: string]: number | string | undefined } = {};
      if (queryParams.rankingName !== '' && queryParams.rankingName !== 'all') {
        filterQuery.rankingName = rankingNameDropdownItems?.find(
          x => x.id === queryParams.rankingName,
        )?.name;
      }
      if (queryParams.status !== '' && queryParams.status !== 'all') {
        filterQuery.status = queryParams.status;
      }
      const query = {
        companyId: companyId,
        memberId: memberId,
        page: queryParams?.page,
        page_size: queryParams?.pageSize,
        approverType: queryParams?.approverType,
        ...filterQuery,
      };

      return fetchRecordApprovals(query);
    },
    {
      refetchOnMount: true,
      enabled: !!rankingNameDropdownItems,
      onError: (err: AxiosError) => {
        navigateToErrorPage(navigate, err.response?.status, locationPathname);
      },
    },
  );

  const approvalRows = approvalListResults.map(
    (approvalRow: RecordApproval, index) => {
      const mainApprover = approvalRow.entryApplication.main_approver.length
        ? approvalRow.entryApplication.main_approver[0]
        : undefined;
      return {
        id: approvalRow.entryApplication.entry_id,
        number: (queryParams.page - 1) * queryParams.pageSize + index + 1,
        applicationDate: moment(approvalRow.applicationDate).format(
          'YYYY/MM/DD',
        ),
        applicant: approvalRow.member.name,
        rankingName: approvalRow.ranking.name,
        mainApprover: mainApprover?.group_approver.member?.name,
        mainApproverId: mainApprover?.group_approver.id,
        allApprovers: approvalRow.entryApplication.approvers,
        status: (
          <ApprovalStatusWrapper
            className={`approval-status-${approvalRow.entryApplication.status}`}>
            {
              data.approvalStatus.find(
                x => x.id === approvalRow.entryApplication.status,
              )?.name
            }
          </ApprovalStatusWrapper>
        ),
        rankingId: approvalRow.ranking.id,
        memberId: approvalRow.member.id,
      };
    },
  );

  const handleChangeFilterRankingName = useCallback(
    ({ selectedItem }: { selectedItem: { id: string } }) => {
      setQueryParams({ ...queryParams, rankingName: selectedItem.id, page: 1 });
    },
    [queryParams],
  );

  const handleChangeFilterStatus = useCallback(
    ({ selectedItem }: { selectedItem: { id: string } }) => {
      setQueryParams({ ...queryParams, status: selectedItem.id, page: 1 });
    },
    [queryParams],
  );

  const onChangePage = (data: { page: number; pageSize: number }) => {
    window.scrollTo(0, 0);
    setQueryParams({
      ...queryParams,
      pageSize: data.pageSize,
      page: data.page,
    });
  };

  const hasFilter =
    (queryParams.rankingName !== '' && queryParams.rankingName !== 'all') ||
    (queryParams.status !== '' && queryParams.status !== 'all');

  const onClickRow = (id: string | number) => {
    window.scrollTo(0, 0);
    const recordIndex = approvalRows.findIndex(x => x.id.toString() == id);
    const rankingId = approvalRows[recordIndex].rankingId;
    const memberId = approvalRows[recordIndex].memberId;
    navigate(`/approval/${id}/ranking/${rankingId}/member/${memberId}`);
  };

  const noFilterDetails =
    width <= MOBILE_MAX_SCREEN
      ? words.noFilterResultsDetailsMobile
      : words.noFilterResultsDetails;

  return (
    <Container>
      <FilterContainer>
        <DropdownWrapper
          className={
            queryParams.rankingName === '' ? 'dropdown-filter-all' : ''
          }>
          <Dropdown
            id="approval-list-ranking-name-filter"
            label={words.rankingName}
            backgroundColor={theme.colors.white}
            styles={{ width: '100%' }}
            items={rankingNameDropdownItems || []}
            onChange={handleChangeFilterRankingName}
            dropdownProps={{
              selectedItem: rankingNameDropdownItems?.find(
                e => e.id === queryParams.rankingName,
              ),
              itemToString: (item: { name: string }) => item.name,
            }}
          />
        </DropdownWrapper>
        <DropdownWrapper
          className={queryParams.status === '' ? 'dropdown-filter-all' : ''}>
          <Dropdown
            id="approval-list-status-filter"
            label={words.status}
            backgroundColor={theme.colors.white}
            styles={{ width: '100%' }}
            items={statusDropdownItems}
            onChange={handleChangeFilterStatus}
            dropdownProps={{
              selectedItem: statusDropdownItems.find(
                e => e.id === queryParams.status,
              ) || { id: '', name: words.status },
              itemToString: (item: { name: string }) => item.name,
            }}
          />
        </DropdownWrapper>
      </FilterContainer>
      <MobileContainer>
        <FilterContainer>
          <DropdownWrapper
            paddingBottom="9px"
            className={
              queryParams.rankingName === '' ? 'dropdown-filter-all' : ''
            }>
            <Dropdown
              id="approval-list-ranking-name-filter"
              label={words.rankingName}
              backgroundColor={theme.colors.white}
              styles={{ width: '100%' }}
              items={rankingNameDropdownItems || []}
              onChange={handleChangeFilterRankingName}
              dropdownProps={{
                selectedItem: rankingNameDropdownItems?.find(
                  e => e.id === queryParams.rankingName,
                ),
                itemToString: (item: { name: string }) => item.name,
              }}
            />
          </DropdownWrapper>
          <DropdownWrapper
            className={queryParams.status === '' ? 'dropdown-filter-all' : ''}>
            <Dropdown
              id="approval-list-status-filter"
              label={words.status}
              backgroundColor={theme.colors.white}
              styles={{ width: '100%' }}
              items={statusDropdownItems}
              onChange={handleChangeFilterStatus}
              dropdownProps={{
                selectedItem: statusDropdownItems.find(
                  e => e.id === queryParams.status,
                ) || { id: '', name: words.status },
                itemToString: (item: { name: string }) => item.name,
              }}
            />
          </DropdownWrapper>
        </FilterContainer>
        {isApprovalListLoading || isFetchingRankingNames ? null : (
          <MobileListContainer>
            {approvalRows.map(item => {
              return (
                <MobileListItem
                  key={item.id}
                  onClick={() => onClickRow(item.id)}>
                  <MobileListItemData>{item.number}</MobileListItemData>
                  <MobileListItemData>
                    <div className="approval-application-date">
                      {item.applicationDate}
                    </div>
                    <div className="approval-applicant">{item.applicant}</div>
                    <div className="approval-ranking-name">
                      {item.rankingName}
                    </div>
                    {!isMainApprover && (
                      <div className="approval-main-approver">
                        {item.mainApprover}
                      </div>
                    )}
                  </MobileListItemData>
                  <MobileListItemData>{item.status}</MobileListItemData>
                </MobileListItem>
              );
            })}
          </MobileListContainer>
        )}
      </MobileContainer>
      {approvalListResults.length === 0 &&
      !isApprovalListLoading &&
      !isFetchingRankingNames ? (
        <StyledEmptyDisplay
          title={
            hasFilter
              ? words.noFilterResults
              : isMainApprover
              ? words.mainApprovalEmpty
              : words.secondaryApprovalEmpty
          }
          details={
            hasFilter
              ? noFilterDetails
              : isMainApprover
              ? words.mainApprovalEmptyDetails
              : words.secondaryApprovalEmptyDetails
          }
        />
      ) : (
        <StyledDataTable
          headers={
            isMainApprover ? MAIN_APPROVER_HEADERS : SECONDARY_APPROVER_HEADERS
          }
          rows={approvalRows}
          isLoading={isApprovalListLoading || isFetchingRankingNames}
          onChangePage={onChangePage}
          totalItems={approvalListCount}
          page={queryParams.page}
          pageSize={queryParams.pageSize}
          onClickRow={onClickRow}
        />
      )}
    </Container>
  );
};

export default Component;
