import React from 'react';

type Props = {
  [key: string]: string | number | Function;
};

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="144"
      height="16"
      viewBox="0 0 144 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M26.9855 11.2571V9.52221H32.0262H32.2058V9.34262V7.70271V7.52311H32.0262H26.9855V5.38632H30.6473H30.8269V5.20672V3.53409V3.35449H30.6473H25.0331H24.8535V3.53409V13.1093V13.2889H25.0331H34.1793H34.3589V13.1093V11.4367V11.2571H34.1793H26.9855Z"
        fill="#DB5134"
      />
      <path
        d="M46.9534 3.35449H46.7738V3.53409V5.20672V5.38632H46.9534H50.6152V7.52311H45.5745H45.3949V7.70271V9.34262V9.52221H45.5745H50.6152V11.2571H43.4218H43.2422V11.4367V13.1093V13.2889H43.4218H52.5676H52.7472V13.1093V3.53409V3.35449H52.5676H46.9534Z"
        fill="#DB5134"
      />
      <path
        d="M62.1384 10.2643C62.6673 10.0199 63.1149 9.62867 63.4279 9.1372C63.7975 8.56409 63.9866 7.85129 63.9866 7.01916C63.9866 6.46401 63.9028 5.95436 63.7376 5.50457C63.583 5.06974 63.335 4.67402 63.0112 4.34518C62.6856 4.01336 62.2891 3.75957 61.8514 3.60285C61.4008 3.42844 60.8856 3.33984 60.3201 3.33984H54.5654V13.301H56.5885V10.633H59.8292L62.2593 13.301H65.1468L62.1384 10.2643ZM56.5885 5.37646H60.3181C60.8481 5.37646 61.2592 5.51495 61.5401 5.78794L61.5437 5.79153C61.8295 6.05414 61.9744 6.47599 61.9744 7.0459C61.9744 7.62181 61.8347 8.06521 61.5613 8.36294C61.2899 8.6499 60.8716 8.79557 60.3181 8.79557H56.5885V5.37646Z"
        fill="#DB5134"
      />
      <path
        d="M102.815 5.37744H106.461V13.3016H108.497V5.37744H112.142V3.34082H102.815V5.37744Z"
        fill="#3D2034"
      />
      <path
        d="M133.986 3.35449V5.39071H137.631V13.3153H139.668V5.39071H143.313V3.35449H133.986Z"
        fill="#3D2034"
      />
      <path
        d="M132.518 8.10319C131.985 7.61628 131.198 7.36924 130.179 7.36924H127.101C126.775 7.36924 126.526 7.28543 126.341 7.11581C126.165 6.94699 126.079 6.70832 126.079 6.38665C126.079 6.06497 126.165 5.82791 126.343 5.65549C126.521 5.47749 126.774 5.39089 127.114 5.39089H132.868V3.35547H127.114C126.62 3.35547 126.171 3.4309 125.786 3.57737C125.415 3.70512 125.074 3.90749 124.784 4.17203C124.516 4.43224 124.308 4.74744 124.173 5.09595C124.04 5.45879 123.973 5.84282 123.977 6.2294C123.973 6.62659 124.044 7.02091 124.187 7.39159V7.39558C124.331 7.74246 124.542 8.05791 124.807 8.32429L124.811 8.32868C125.098 8.59026 125.432 8.79479 125.795 8.93132L125.8 8.93332C126.214 9.07616 126.65 9.14663 127.088 9.14165H130.179C130.58 9.14165 130.887 9.23304 131.089 9.41344L131.095 9.41823C131.3 9.58625 131.399 9.84327 131.399 10.2037C131.399 10.5333 131.297 10.7883 131.084 10.9855C130.882 11.1803 130.577 11.2788 130.179 11.2788H124.122V13.3143H130.179C130.7 13.3143 131.161 13.2392 131.549 13.0916C131.925 12.9582 132.267 12.7452 132.553 12.467C132.817 12.1921 133.02 11.8641 133.148 11.5047C133.281 11.1245 133.347 10.7242 133.343 10.3214C133.343 9.3404 133.065 8.59328 132.518 8.10319Z"
        fill="#3D2034"
      />
      <path
        d="M73.2484 3.69602C72.6695 3.91709 72.143 4.25637 71.7026 4.69224C71.2621 5.1281 70.9173 5.65098 70.6901 6.22752C70.4539 6.82618 70.3341 7.49787 70.3341 8.22303C70.3289 8.90838 70.4497 9.58885 70.6905 10.2305C70.9238 10.8293 71.2686 11.3783 71.7067 11.8485C72.1478 12.2974 72.6687 12.6601 73.2428 12.9181C73.8542 13.177 74.5121 13.3078 75.176 13.3024H79.569V11.267H75.176C74.7883 11.2704 74.404 11.1946 74.0466 11.0443C73.7173 10.9016 73.419 10.6959 73.1686 10.4388C72.9147 10.1643 72.7165 9.84326 72.5847 9.49338C72.4407 9.11325 72.3686 8.70971 72.372 8.30325C72.3672 7.89727 72.4391 7.49404 72.5839 7.11473C72.7157 6.77332 72.9137 6.46136 73.1666 6.19679C73.4152 5.94046 73.7141 5.73828 74.0446 5.60293C74.4025 5.45223 74.7874 5.37618 75.1757 5.37943H79.5686V3.34401H75.176C74.5169 3.33738 73.8626 3.45686 73.2484 3.69602Z"
        fill="#3D2034"
      />
      <path
        d="M99.4817 9.94974L94.0456 3.62757L94.0364 3.61759C93.9191 3.49415 93.7809 3.39253 93.6281 3.31746C93.4928 3.2545 93.3455 3.22181 93.1963 3.22168C92.877 3.22168 92.6196 3.33622 92.4256 3.56211C92.248 3.77124 92.1582 4.05461 92.1582 4.40502V13.3014H94.1138V6.77409L99.524 13.0001L99.5328 13.0093C99.8042 13.281 100.105 13.4187 100.426 13.4187C100.725 13.4187 100.971 13.309 101.156 13.0931C101.342 12.8843 101.437 12.5954 101.437 12.2354V3.26119H99.4817V9.94974Z"
        fill="#3D2034"
      />
      <path
        d="M89.3775 4.70114C88.9347 4.26307 88.4061 3.92121 87.825 3.697C87.2339 3.46073 86.5718 3.341 85.8566 3.341H85.7972C85.1378 3.33551 84.4835 3.45621 83.8695 3.6966C83.2905 3.91755 82.7639 4.2568 82.3233 4.69267C81.8828 5.12855 81.538 5.65148 81.3108 6.2281C81.075 6.82676 80.9552 7.49884 80.9552 8.22361C80.95 8.90893 81.0706 9.58938 81.3112 10.2311C81.5445 10.8299 81.8893 11.3789 82.3274 11.8491C82.7686 12.2981 83.2897 12.6608 83.8639 12.9186C84.4753 13.1775 85.1332 13.3084 85.7972 13.303H85.8566C86.5646 13.303 87.2239 13.1737 87.8182 12.9175C88.3947 12.6639 88.9163 12.3007 89.3539 11.8479C89.7972 11.3825 90.1422 10.8326 90.3685 10.2311C90.6178 9.59143 90.7431 8.91014 90.7376 8.22361C90.7376 7.50762 90.6179 6.84511 90.382 6.25564C90.1579 5.67377 89.8159 5.14452 89.3775 4.70114ZM85.8566 11.2652H85.7972C85.4093 11.2686 85.0248 11.1928 84.6673 11.0425C84.338 10.8997 84.0398 10.6941 83.7893 10.437C83.5354 10.1624 83.337 9.84141 83.205 9.49156C83.0613 9.11137 82.9893 8.70785 82.9927 8.30144C82.9876 7.89547 83.0594 7.49221 83.2042 7.11291C83.3363 6.77156 83.5344 6.45962 83.7873 6.19498C84.036 5.9387 84.3349 5.73653 84.6653 5.60111C85.0233 5.45046 85.4083 5.37441 85.7968 5.37762H85.8562C86.2496 5.37376 86.6396 5.45007 87.0024 5.60191C87.3381 5.73535 87.6419 5.93797 87.894 6.19657C88.1548 6.4614 88.357 6.77808 88.4875 7.12608C88.6318 7.50095 88.7039 7.89976 88.6998 8.30144C88.7031 8.70789 88.631 9.11142 88.4871 9.49156C88.3543 9.84292 88.1519 10.1638 87.892 10.435C87.6388 10.6946 87.3364 10.9009 87.0024 11.0421C86.6396 11.1936 86.2498 11.2695 85.8566 11.2652Z"
        fill="#3D2034"
      />
      <path
        d="M38.8806 10.2301L35.2919 3.34082H33.0078L37.8369 12.7943L37.8417 12.8027C37.9401 12.9785 38.0736 13.132 38.2341 13.2537C38.4034 13.3751 38.6076 13.4382 38.8159 13.4337C39.0199 13.4369 39.2194 13.3738 39.3843 13.2537C39.5449 13.1321 39.6786 12.9786 39.777 12.8027L44.6229 3.34082H42.457L38.8806 10.2301Z"
        fill="#3D2034"
      />
      <path
        d="M113.662 3.35352H113.482V3.53311V3.61972V3.79931V12.829V13.0086V13.1415V13.3211H113.662H122.537H122.717V13.1415V11.4457V11.2661H122.537H115.508V9.09859H119.888H120.068V8.91899V7.22361V7.04401H119.888H115.508V5.40849H122.537H122.717V5.2289V3.53311V3.35352H122.537H113.662Z"
        fill="#3D2034"
      />
      <path
        d="M0.0486904 15.3371C0.112613 15.53 0.235708 15.6979 0.400491 15.8169C0.565274 15.9359 0.763365 16 0.966625 16H17.1566C17.413 16 17.6589 15.8982 17.8402 15.7169C18.0216 15.5356 18.1235 15.2898 18.1236 15.0334V0.966625C18.1235 0.710223 18.0216 0.46436 17.8402 0.283094C17.6589 0.101828 17.413 -2.18365e-08 17.1566 0H0.966625C0.710293 0.00010577 0.464489 0.10198 0.283235 0.283235C0.10198 0.464489 0.00010577 0.710293 0 0.966625V10.8759L5.43537 5.3759L8.0459 7.83238L10.3743 5.50561L7.82839 3.13974H15.2034V10.3862H15.183L13.3156 8.51843L8.77107 13.0614L5.47568 9.84505L0.0486904 15.3371Z"
        fill="#DB5134"
      />
      <path
        d="M17.9214 0.374023C18.0529 0.543259 18.1241 0.751568 18.1238 0.965892V15.0322C18.1238 15.1592 18.0987 15.285 18.0501 15.4023C18.0016 15.5196 17.9303 15.6262 17.8405 15.716C17.7507 15.8058 17.6441 15.8771 17.5268 15.9257C17.4095 15.9743 17.2837 15.9993 17.1567 15.9993H0.966763C0.763453 15.9992 0.565326 15.9351 0.400537 15.816C0.235748 15.697 0.112682 15.529 0.0488281 15.336L5.47662 9.84431L8.17135 12.4504L12.6688 7.84761L15.1812 10.3863V3.141L17.9214 0.374023Z"
        fill="#F5C483"
      />
    </svg>
  );
};

export default Component;
