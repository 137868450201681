import LoginFieldRepository from '../../ports/LoginFieldsRepository';

export type deleteLoginFieldUseCase = (query: {
  companyId: number;
  fieldId: number;
}) => Promise<void>;

export const buildDeleteLoginField = (dependencies: {
  loginFieldRepo: LoginFieldRepository;
}): deleteLoginFieldUseCase => {
  const { loginFieldRepo } = dependencies;

  const deleteLoginField: deleteLoginFieldUseCase = async query =>
    loginFieldRepo.delete(query);

  return deleteLoginField;
};
