import {
  FetchRecordApprovalsParam,
  RecordApproval,
} from '../../../domain/entities/record';
import { PaginationQuery } from '../../../domain/entities/request';
import { PaginatedResponse } from '../../../domain/entities/response';
import RecordRepository from '../../ports/RecordRepository';

export type fetchRecordApprovalsUseCase = (
  query: PaginationQuery & FetchRecordApprovalsParam,
) => Promise<PaginatedResponse & { results: RecordApproval[] }>;

export const buildFetchRecordApprovals = (dependencies: {
  recordRepo: RecordRepository;
}): fetchRecordApprovalsUseCase => {
  const { recordRepo } = dependencies;

  const fetchRecordReference: fetchRecordApprovalsUseCase = async query =>
    recordRepo.fetchRecordApprovals(query);

  return fetchRecordReference;
};
