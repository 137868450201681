import React from 'react';
import { Formik } from 'formik';

import { ChangePasswordModalSchema } from '../ChangePasswordModal/validation';

import words from '../../../constants/words';
import themes from '../../../config/themes';

import { Modal } from '../../molecules/Modal';
import { ChangeTempPasswordForm } from '../../organisms/ChangeTempPasswordForm';
import styled from 'styled-components';

const ContentContainer = styled.div`
  .bx--modal-close {
    display: none;
  }
  @media ${themes.main.breakpoints.mobile} {
    .bx--modal-content .buttonsContainer {
      margin-bottom: 2px;
    }
    .bx--children-container {
      overflow: visible;
    }
    .buttonsContainer > button {
      height: 55px;
    }
`;

export type Props = {
  isOpen: boolean;
  onChangePassword: (values: object) => void;
};

const Component = ({ isOpen, onChangePassword }: Props): React.ReactElement => {
  const initialValues = {
    newPassword: '',
    confirmPassword: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ChangePasswordModalSchema}
      onSubmit={onChangePassword}>
      {({
        handleChange,
        handleSubmit,
        values,
        errors,
        isValid,
      }): React.ReactElement => {
        const { newPassword, confirmPassword } = values;
        const {
          newPassword: newPasswordError,
          confirmPassword: confirmPasswordError,
        } = errors;

        return (
          <ContentContainer>
            <Modal
              open={isOpen}
              title={words.changeTemporaryPassword}
              label={words.youLoggedWithATempPassword}
              confirmButtonText={words.submitTemporaryPassword}
              onConfirm={handleSubmit}
              onCancel={() => {}}
              onClose={() => {}}
              hideCancelButton={true}
              confirmButtonDisabled={
                !isValid || !newPassword || !confirmPassword
              }
              mergedWithButtons
              mobileButtonSpan>
              <ChangeTempPasswordForm
                newPassword={newPassword}
                confirmPassword={confirmPassword}
                onChangeNewPassword={handleChange('newPassword')}
                onChangeConfirmPassword={handleChange('confirmPassword')}
                newPasswordError={newPasswordError}
                confirmPasswordError={confirmPasswordError}
              />
            </Modal>
          </ContentContainer>
        );
      }}
    </Formik>
  );
};

export default Component;
