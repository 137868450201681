import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import { Grid, Row, Column } from 'carbon-components-react';

import { theme } from '../../../config';
import words from '../../../constants/words';

import { AnchorTag } from '../../atoms/AnchorTag';
import { Button } from '../../atoms/Button';
import { Notification } from '../../molecules/Notification';
import { TextInput } from '../../atoms/TextInput';
import { SubmitLoading } from '../../molecules/SubmitLoading';

const NotifWrapper = styled.div`
  margin-top: 3%;
  @media ${theme.breakpoints.mobileAndTablet} {
    margin-top: 4%;
  }

  .bx--inline-notification__subtitle {
    white-space: pre-wrap;
  }

  .bx--inline-notification__text-wrapper {
    display: block;
  }
`;

const BottomWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 5%;
  @media ${theme.breakpoints.mobileAndTablet} {
    flex-direction: column-reverse;
    padding-top: 25px;
  }
`;

const NoteWrapper = styled.div`
  display: flex;
  @media ${theme.breakpoints.pc} {
    justify-content: start;
  }
  @media ${theme.breakpoints.mobileAndTablet} {
    margin-top: 35px;
  }
`;

const Note = styled.text`
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.16px;
  color: ${theme.colors.interactive02};
`;

const ButtonWrapper = styled.div`
  width: 198px;
  align-items: center;
  @media ${theme.breakpoints.mobile} {
    height: 38px;
    width: 100%;
    margin: 0 auto;
  }

  & button {
    @media ${theme.breakpoints.mobile} {
      height: 38px !important;
      font-size: 14px !important;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.15px;
    }
  }
`;

const LoadingWrapper = styled.div`
  align-items: center;
  height: 48px;
  @media ${theme.breakpoints.mobile} {
    justify-content: center;
    width: 100%;
    height: 38px;
    margin: 2rem 0 0;
  }
`;

const MainWrapper = styled.div`
  @media ${theme.breakpoints.mobile} {
    .bx--grid {
      padding: 0 23px;
    }

    .bx--text-input__field-wrapper[data-invalid] ~ .bx--form-requirement {
      font-size: 10px;
    }
  }
`;

const ButtonStyle = {
  height: 48,
  padding: 0,
};

export type ForgetPasswordProps = {
  email?: string;
  onChangeEmail: (event: ChangeEvent) => void;
  onPressSendLink: () => void;
  onPressLogin: () => void;
  emailError?: string;
  successNotif?: boolean;
  errorNotif?: boolean;
  isLoading?: boolean;
  setToDefaultNotifState?: () => void;
};

const Component = ({
  email,
  onChangeEmail,
  onPressSendLink,
  onPressLogin,
  emailError,
  successNotif,
  errorNotif,
  isLoading,
  setToDefaultNotifState,
}: ForgetPasswordProps): React.ReactElement => {
  return (
    <MainWrapper>
      <Grid>
        <Row>
          <Column>
            <TextInput
              value={email}
              onChange={onChangeEmail}
              label={`${words.emailAdd} ${words.requiredLabel}`}
              id="email"
              invalid={!!emailError}
              invalidText={emailError}
            />
          </Column>
        </Row>
        {successNotif || errorNotif ? (
          <Row>
            <Column>
              <NotifWrapper>
                <Notification
                  kind={successNotif ? 'success' : 'error'}
                  title={
                    successNotif ? words.successSent : words.forgotPasswordError
                  }
                  subtitle={
                    successNotif
                      ? words.linkSent
                      : words.resetPasswordLinkExpired
                  }
                  inline
                  handleClose={() => {
                    setToDefaultNotifState && setToDefaultNotifState();
                    return false;
                  }}
                />
              </NotifWrapper>
            </Column>
          </Row>
        ) : null}
        <BottomWrapper>
          <NoteWrapper>
            <Note>{words.alreadyHaveAccount}</Note>
            <AnchorTag onPress={onPressLogin} title={words.alreadyLogin} />
          </NoteWrapper>
          {isLoading ? (
            <LoadingWrapper>
              <SubmitLoading text={words.submitting} />
            </LoadingWrapper>
          ) : (
            <ButtonWrapper>
              <Button
                style={ButtonStyle}
                onPress={onPressSendLink}
                title={words.sendResetLink}
                disabled={!!emailError || !!!email}
              />
            </ButtonWrapper>
          )}
        </BottomWrapper>
      </Grid>
    </MainWrapper>
  );
};

export default Component;
