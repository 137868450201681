import React from 'react';

type Props = {
  [key: string]: string | number | Function;
};

const ArrowLeft = (props: Props): React.ReactElement => {
  return (
    <svg
      width="14"
      height="10"
      viewBox="0 0 14 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 10L6.205 9.295L2.415 5.5H13.5V4.5H2.415L6.205 0.705L5.5 0L0.5 5L5.5 10Z"
        fill="#FF714F"
      />
    </svg>
  );
};

export default ArrowLeft;
