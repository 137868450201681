import React, { useState, useCallback } from 'react';
import { useQuery, useMutation } from 'react-query';
import { useGroupHooks } from '../../../hooks/group';
import { useMemberHooks } from '../../../hooks/member';
import { TextInput } from '../../atoms/TextInput';
import { Dropdown } from '../../molecules/Dropdown';
import * as Elements from './elements';
import {
  ViewMemberInfo,
  UpdateMemberType,
  UserRoles,
} from '../../../domain/entities/member';
import { Group } from '../../../domain/entities/group';
import { Props as INotification } from '../../molecules/Notification/Notification';
import words from '../../../constants/words';
import data, { Role } from '../../../constants/data';
import { theme } from '../../../config';
import { useGlobalState } from '../../../hooks/global';
import { UserImage } from '../../molecules/UserImage';
import { Tooltip } from '../../atoms/Tooltip';
import { Button } from '../../atoms/Button';

export type Props = {
  backOnPress: () => void;
  deleteOnPress?: () => void;
  editOnPress?: () => void;
  copyOnPress: (value: string | undefined) => void;
  isLoading: boolean;
  memberInfo: ViewMemberInfo;
  toastNotification: (toast: INotification) => void;
  isEdit?: boolean;
  password?: string;
  setNewlyUpdatedMember?: (
    member: (UpdateMemberType & { groupName?: string }) | undefined,
  ) => void;
  onSuccessGeneratePassword: (toast: INotification) => void;
  canEdit: boolean;
  setImage: (src: string | undefined) => void;
};

const Component = ({
  backOnPress,
  deleteOnPress = () => {},
  editOnPress = () => {},
  copyOnPress,
  isLoading,
  memberInfo,
  toastNotification,
  isEdit = false,
  password,
  setNewlyUpdatedMember = () => {},
  onSuccessGeneratePassword,
  canEdit,
  setImage,
}: Props): React.ReactElement => {
  const { useFetchGroups } = useGroupHooks();
  const {
    useFetchMembers,
    useUpdateMember,
    useGenerateTemporaryPassword,
  } = useMemberHooks();

  const { fetchGroups } = useFetchGroups();
  const { validateMemberRoleChangeable } = useFetchMembers();
  const { updateMember } = useUpdateMember();
  const { generateTemporaryPassword } = useGenerateTemporaryPassword();

  const {
    useCurrentUser: { currentUser },
  } = useGlobalState();

  const [selectedGroup, setSelectedGroup] = useState<Group | undefined>(
    undefined,
  );
  const [selectedRole, setSelectedRole] = useState<Role | undefined>(undefined);
  const [temporaryPassword, setTemporaryPassword] = useState<
    string | undefined
  >(password);
  const [openGeneratePassTooltip, setOpenGeneratePassTooltip] = useState<
    boolean
  >(false);
  const [roleChangeError, setRoleChangeError] = useState('');

  const disableEditSave = !selectedGroup || !selectedRole || !!roleChangeError;

  const dropdownStyles = {
    width: '100%',
  };

  const hasLoggedIn = memberInfo.lastLogin != null;

  const {
    data: { results: groupList } = {
      results: [],
    },
  } = useQuery(
    ['fetchGroups', currentUser, memberInfo.groupId, memberInfo.roleName],
    () => {
      if (!currentUser?.companyId) {
        throw new Error(`'company id' not set`);
      }
      const query = {
        companyId: currentUser.companyId,
      };
      return fetchGroups(query);
    },
    {
      onSuccess: () => {
        resetForm();
      },
      refetchOnMount: true,
    },
  );

  const { data: validatedMemberRole } = useQuery(
    ['validateMemberRoleChangeable', memberInfo.id],
    () => {
      if (currentUser?.companyId && memberInfo.id) {
        return validateMemberRoleChangeable(
          currentUser.companyId,
          memberInfo.id,
        );
      }
    },
  );

  const { mutate: mutateUpdateMember } = useMutation(
    (params: { memberId: number; payload: UpdateMemberType }) => {
      if (!memberInfo.companyId) {
        throw new Error(`'company id' not set`);
      }
      return updateMember(
        memberInfo.companyId,
        params.memberId,
        params.payload,
      );
    },
    {
      onSuccess: () => {
        toastNotification({
          kind: 'success',
          title: words.updateMemberToastTitleSuccess,
          subtitle: words.updateMemberToastSubSuccess,
        });
      },
    },
  );

  const handleChangeMemberGroup = useCallback(
    ({ selectedItem }: { selectedItem: Group }) => {
      setSelectedGroup(selectedItem);
    },
    [],
  );

  const handleChangeMemberRole = useCallback(
    ({ selectedItem }: { selectedItem: Role }) => {
      let roleChangeErrorMessage = '';
      if (selectedItem.id !== memberInfo.roleName) {
        if (validatedMemberRole?.is_last_admin)
          roleChangeErrorMessage = words.editMemberRoleLastAdminError;
        else if (
          validatedMemberRole?.is_last_approver &&
          selectedItem.id === 'member'
        )
          roleChangeErrorMessage = words.editMemberRoleLastApproverError;
      }

      setSelectedRole(selectedItem);
      setRoleChangeError(roleChangeErrorMessage);
    },
    [memberInfo, validatedMemberRole],
  );

  const handleSaveMember = useCallback(() => {
    const editedMember: UpdateMemberType = {};
    if (memberInfo.groupId !== selectedGroup?.id)
      editedMember.group = selectedGroup?.id;
    if (memberInfo.roleName !== selectedRole?.id)
      editedMember.role = selectedRole?.id;
    if (memberInfo.id == undefined) {
      throw new Error(`'member id' not set`);
    } else {
      mutateUpdateMember(
        {
          memberId: memberInfo.id,
          payload: editedMember,
        },
        {
          onSuccess: () => {
            setNewlyUpdatedMember({
              ...editedMember,
              groupName: selectedGroup?.name,
            });
          },
        },
      );
    }
  }, [selectedGroup, selectedRole]);

  const { mutate: mutateTemporaryPassword } = useMutation(
    async () => {
      if (!currentUser?.companyId || !memberInfo.id) {
        throw new Error(`'company id' or 'member id' not set`);
      }
      const response = await generateTemporaryPassword(
        currentUser.companyId,
        memberInfo.id,
      );
      setTemporaryPassword(response.temporary_password);
    },
    {
      onSuccess: () => {
        const newToast: INotification = {
          kind: 'success',
          title: words.successGeneratePassword,
          subtitle: words.tempPasswordSuccess,
        };
        onSuccessGeneratePassword(newToast);
      },
      onError: () => {
        throw new Error(`Something went wrong.`);
        // TODO; no design on error scenario in figma
      },
    },
  );

  const resetForm = useCallback(() => {
    setSelectedGroup(groupList.find(e => e.id === memberInfo.groupId));
    setSelectedRole(data.memberRoles.find(e => e.id === memberInfo.roleName));
    setRoleChangeError('');
  }, [groupList]);

  const handleCancel = () => {
    resetForm();
    backOnPress();
  };

  const selectRolesList = (role: UserRoles): Role[] => {
    switch (role) {
      case 'admin':
        return data.memberRoles;
      case 'approver':
        return data.approverOnlyRoles;
      case 'member':
        return data.memberOnlyRoles;
      default:
        return data.memberOnlyRoles;
    }
  };

  return (
    <Elements.ViewMemberTemplate>
      <Elements.Content>
        <Elements.Column>
          {hasLoggedIn || isEdit ? (
            <Elements.ImageContainer>
              <UserImage
                viewOnly={!canEdit}
                imageSrc={
                  memberInfo.profileImage ? memberInfo.profileImage : undefined
                }
                memberId={memberInfo.id}
                setNewlyUpdatedMember={setNewlyUpdatedMember}
                isLoading={isLoading}
                toastNotification={toastNotification}
                setImage={setImage}
              />
            </Elements.ImageContainer>
          ) : null}
          <Elements.TextInputContainer isColumn1={true}>
            <TextInput
              id={'name'}
              disabled={true}
              label={`${words.memberListHeaders.name}${
                isEdit ? ` ${words.requiredMark}` : ''
              }`}
              value={memberInfo.name}
            />
          </Elements.TextInputContainer>
          <Elements.TextInputContainer isColumn1={true}>
            {isEdit ? (
              <Dropdown
                id="member-group"
                label={memberInfo.groupName}
                titleText={`${words.group} ${words.requiredMark}`}
                items={groupList || []}
                onChange={handleChangeMemberGroup}
                styles={dropdownStyles}
                backgroundColor={theme.colors.white}
                dropdownProps={{
                  selectedItem: selectedGroup,
                  itemToString: (item: Group) => item.name,
                }}
              />
            ) : (
              <TextInput
                id={'group'}
                disabled={true}
                label={words.memberListHeaders.group}
                value={memberInfo.groupName || words.unassignedGroup}
              />
            )}
          </Elements.TextInputContainer>
          <Elements.TextInputContainer isColumn1={true}>
            {isEdit ? (
              <Dropdown
                id="member-role"
                label={
                  data.memberRoles.find(e => e.id === memberInfo.roleName)
                    ?.name || ''
                }
                titleText={`${words.role} ${words.requiredMark}`}
                styles={dropdownStyles}
                items={selectRolesList(currentUser?.roleName || 'member')}
                onChange={handleChangeMemberRole}
                backgroundColor={theme.colors.white}
                dropdownProps={{
                  selectedItem: selectedRole,
                  itemToString: (item: Role) => item.name,
                }}
                invalid={!!roleChangeError}
                invalidText={roleChangeError}
              />
            ) : (
              <TextInput
                id={'role'}
                disabled={true}
                label={words.memberListHeaders.role}
                value={
                  data.memberRoles.find(e => e.id === memberInfo.roleName)
                    ?.name || ''
                }
              />
            )}
          </Elements.TextInputContainer>
          {memberInfo.memberCustomFields?.map((customField, index) => (
            <Elements.TextInputContainer isColumn1={true} key={index}>
              <TextInput
                id={customField.name ? customField.name : index.toString()}
                disabled={true}
                label={customField.name}
                value={customField.value_char}
              />
            </Elements.TextInputContainer>
          ))}
          <Elements.ButtonsContainer
            className={
              !memberInfo.memberCustomFields?.length && hasLoggedIn
                ? 'btn-cont-adjust-margin'
                : ''
            }>
            <Elements.ButtonContainer isBack={true}>
              <Elements.StyledButton
                title={isEdit ? words.cancel : words.back}
                onPress={handleCancel}
                theme={'light'}
              />
            </Elements.ButtonContainer>
            {canEdit && (
              <Elements.ButtonContainer isBack={false}>
                <Elements.StyledButton
                  title={
                    isEdit
                      ? words.editMemberSaveButton
                      : hasLoggedIn
                      ? words.editMemberButton
                      : words.viewMemberDeleteButton
                  }
                  onPress={
                    isEdit
                      ? handleSaveMember
                      : hasLoggedIn
                      ? editOnPress
                      : deleteOnPress
                  }
                  theme={isEdit || hasLoggedIn ? 'primary' : 'secondary'}
                  disabled={isEdit && disableEditSave}
                />
              </Elements.ButtonContainer>
            )}
          </Elements.ButtonsContainer>
        </Elements.Column>
        <Elements.Column>
          {(hasLoggedIn || isEdit) && <Elements.Spacer />}
          <Elements.TextInputContainer isColumn1={false}>
            <TextInput
              id={'companyId'}
              disabled={true}
              label={words.companyId}
              value={String(memberInfo.companyId).padStart(3, '0')}
              onCopyPress={async () =>
                copyOnPress(String(memberInfo.companyId).padStart(3, '0'))
              }
              copyTooltipMessage={words.iCopiedIt}
              copyTooltipMenuOffset={{ top: 10, left: -13 }}
              copyTooltipDirection="left"
            />
          </Elements.TextInputContainer>
          <Elements.TextInputContainer isColumn1={false}>
            <TextInput
              id={'userId'}
              disabled={true}
              label={words.userId}
              value={String(memberInfo.userId).padStart(4, '0')}
              onCopyPress={async () =>
                copyOnPress(String(memberInfo.userId).padStart(4, '0'))
              }
              copyTooltipMessage={words.iCopiedIt}
              copyTooltipMenuOffset={{ top: 10, left: -13 }}
              copyTooltipDirection="left"
            />
          </Elements.TextInputContainer>
          {hasLoggedIn ? (
            <Elements.TextInputContainer isColumn1={false}>
              <TextInput
                id={'email'}
                disabled={true}
                label={words.emailAddOptional}
                value={memberInfo.email}
                onCopyPress={async () => copyOnPress(memberInfo.email)}
                copyTooltipMessage={words.iCopiedIt}
                copyTooltipMenuOffset={{ top: 10, left: -13 }}
                copyTooltipDirection="left"
              />
            </Elements.TextInputContainer>
          ) : null}
          {isLoading || !canEdit ? null : !temporaryPassword ? (
            <Elements.TextInputContainer isColumn1={false}>
              <Elements.TempPasswordLabel>
                {!hasLoggedIn ? words.temporaryPassword : words.password}
              </Elements.TempPasswordLabel>
              {hasLoggedIn ? (
                <Tooltip
                  message={words.generateNewPasswordTooltip}
                  isOpen={openGeneratePassTooltip}
                  triggerElement={
                    <Elements.GenerateNewPass
                      title={
                        !hasLoggedIn
                          ? words.generateNewPassword
                          : words.resetPassword
                      }
                      onPress={() => setOpenGeneratePassTooltip(true)}
                      type="edit"
                    />
                  }
                  menuOffset={{ top: 5, left: 40 }}
                  align="end"
                  autoOrientation>
                  <div className="bx--tooltip__footer">
                    <Elements.StyledLink
                      onClick={() => setOpenGeneratePassTooltip(false)}>
                      {words.cancel}
                    </Elements.StyledLink>
                    <Button
                      title={words.yesGeneratePassword}
                      onPress={() => mutateTemporaryPassword()}
                    />
                  </div>
                </Tooltip>
              ) : (
                <Elements.GenerateNewPass
                  title={
                    !hasLoggedIn
                      ? words.generateNewPassword
                      : words.resetPassword
                  }
                  onPress={() => mutateTemporaryPassword()}
                  type="edit"
                />
              )}
            </Elements.TextInputContainer>
          ) : (
            <Elements.TextInputContainer isColumn1={false}>
              <Elements.TempPasswordTextInput
                id={'password'}
                disabled={true}
                label={words.temporaryPassword}
                value={temporaryPassword}
                onCopyPress={async () => copyOnPress(temporaryPassword)}
                copyTooltipMessage={words.iCopiedIt}
                copyTooltipMenuOffset={{ top: 10, left: -13 }}
                copyTooltipDirection="left"
                fieldTooltipMessage={words.sendTempPassword}
                fieldTooltipMenuOffset={{ top: -10, left: 0 }}
              />
            </Elements.TextInputContainer>
          )}
        </Elements.Column>
      </Elements.Content>
      <Elements.GlobalStyle />
    </Elements.ViewMemberTemplate>
  );
};

export default Component;
