import React, { useState } from 'react';
import CloseButton from 'assets/icons/close-button.svg';
import './changePasswordModal.scss';
import { OrangeButton } from 'components/atoms/OrangeButton';
import { MemberChangePassword } from 'components/pages/MemberChangePassword';

type PropType = {
  isShow: boolean;
  onHideModal: () => void;
  hash: string;
  activeLoading: (_isLoading: boolean) => void;
};

export default function ChangePasswordModal(props: PropType) {
  const { isShow, onHideModal, hash, activeLoading } = props;

  return (
    <div className={`batch-edit-member-pages ${isShow ? 'show' : 'hide'}`}>
      <div className="title-modal">
        <div className="modal-title">パスワード変更</div>
        <div className="close-button" onClick={onHideModal}>
          <img src={CloseButton} />
        </div>
      </div>
      <div className="modal-content">
        <MemberChangePassword
          hash={hash}
          activeLoading={isLoading => activeLoading(isLoading)}
        />
      </div>
      {/* <hr /> */}
      {/* <div className="btnBox">
        <OrangeButton
          text="変更"
          style={{ width: '144px', height: '42px' }}
          onClick={() => console.log('2312321312321312123')}
        />
      </div> */}
    </div>
  );
}
