import React, { useEffect, useState } from 'react';
import {
  Tooltip as CarbonTooltip,
  TooltipOnChangeEvent,
} from 'carbon-components-react';
import styled from 'styled-components';
import themes from '../../../config/themes';
import { useWindowDimensions } from '../../../utils/screenDimension';
import { theme } from '../../../config';
import { MOBILE_MAX_SCREEN } from '../../../constants/breakpoints';

export type Props = {
  direction?: 'top' | 'bottom' | 'left' | 'right';
  defaultOpen?: boolean;
  triggerElement?: React.ReactElement;
  message?: string;
  isOpen?: boolean;
  children?: React.ReactElement;
  onChange?: (
    ev: TooltipOnChangeEvent<HTMLDivElement>,
    data: { open: boolean },
  ) => void;
  className?: string;
  tooltipClassName?: string;
  autoOrientation?: boolean;
  menuOffset?: { top?: number; left?: number };
  align?: 'start' | 'center' | 'end';
  preserveText?: boolean;
};

const Wrapper = styled.div`
  .bx--tooltip__label:focus {
    outline: none;
  }
`;

const Tooltip = styled(CarbonTooltip)<{
  $hasChildren?: boolean;
  $preserveText?: boolean;
}>`
  &&&&& {
    min-width: 79px !important;
    ${(props): string =>
      props.$preserveText ? `max-width: 100% !important;` : ``}

    button {
      width: auto;
      padding: 5px 13px 5px 13px;
    }

    .bx--tooltip__content {
      p {
        color: ${themes.main.colors.white};
        font-weight: 400;
        font-size: ${(props): string => (props.$hasChildren ? '14px' : '13px')};
        line-height: ${(props): string =>
          props.$hasChildren ? '20px' : '18px'};
      }
      a {
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;

        &:hover {
          color: ${themes.main.colors.primaryButtonHover};
        }
        &:focus {
          outline: none;
        }
      }
    }

    @media ${theme.breakpoints.mobile} {
      padding: 0.5rem;
      button {
        padding: 0;
      }
      .bx--tooltip {
        padding: 0.5rem;
      }
      .bx--tooltip__content {
        p {
          font-size: ${(props): string =>
            props.$hasChildren ? '13px' : '12px'};
          line-height: ${(props): string =>
            props.$hasChildren ? '19px' : '17px'};
        }
      }
      .bx--tooltip__footer {
        margin-top: 0.5rem;
      }
    }
  }
`;

const Message = styled.p<{
  $preserveText?: boolean;
}>`
  white-space: ${(props): string =>
    props.$preserveText ? 'break-spaces' : 'pre-line'} !important;
`;

const TooltipComponent = ({
  direction = 'bottom',
  defaultOpen = false,
  isOpen,
  message = '',
  triggerElement,
  children,
  onChange,
  className,
  tooltipClassName,
  autoOrientation,
  menuOffset,
  align,
  preserveText = false,
}: Props): React.ReactElement => {
  const { width } = useWindowDimensions();
  const tooltipMsg = (msg: string): string => {
    if (width <= MOBILE_MAX_SCREEN) {
      return msg.replace(/\n/g, ' ');
    }
    return msg;
  };
  const [formatedMsg, setFormatedMsg] = useState<string>(message);
  useEffect(() => {
    setFormatedMsg(tooltipMsg(message));
  }, [message]);

  return (
    <Wrapper className={className}>
      <Tooltip
        direction={direction}
        tabIndex={0}
        showIcon={false}
        defaultOpen={defaultOpen}
        onChange={onChange}
        triggerText={triggerElement}
        open={isOpen}
        autoOrientation={autoOrientation}
        $hasChildren={!!children}
        menuOffset={menuOffset}
        align={align}
        className={tooltipClassName}
        $preserveText={preserveText && width > MOBILE_MAX_SCREEN}>
        <Message $preserveText={preserveText && width > MOBILE_MAX_SCREEN}>
          {width > MOBILE_MAX_SCREEN ? formatedMsg : message}
        </Message>
        {children || null}
      </Tooltip>
    </Wrapper>
  );
};

export default TooltipComponent;
