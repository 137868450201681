export const fileToBase64 = (file: File): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    reader.onload = () =>
      resolve(reader.result ? reader.result.toString() : '');
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    reader.onerror = error => reject(error);
  });
};

export const base64ToFile = async (
  dataUrl: string,
  fileName: string,
): Promise<File> => {
  const res: Response = await fetch(dataUrl);
  const blob: Blob = await res.blob();
  return new File([blob], fileName, { type: 'image/png' });
};
