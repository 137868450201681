import { UpdateNotificationData } from '../../../domain/entities/notification';
import NotificationRepository from './../../ports/NotificationRepository';

export type updateNotificationUseCase = (
  payload: {
    companyId: number;
    notificationId: number;
  },
  data: UpdateNotificationData,
) => Promise<Response>;

export const buildUpdateNotification = (dependencies: {
  notifRepo: NotificationRepository;
}): updateNotificationUseCase => {
  const { notifRepo } = dependencies;

  const updateNotification: updateNotificationUseCase = async (query, data) =>
    notifRepo.updateNotification(query, data);

  return updateNotification;
};
