import styled from 'styled-components';
import themes from '../../../config/themes';

export const ScrollableUL = styled.ul`
  max-height: 185px;
  overflow-y: auto;
  margin-top: 40px;

  ::-webkit-scrollbar {
    width: 7px;
  }

  ::-webkit-scrollbar-track {
    background: ${themes.main.colors.text03};
  }

  ::-webkit-scrollbar-thumb {
    background: ${themes.main.colors.ui04};
    height: 47px;
  }
`;

export const Item = styled.li`
  background-color: ${themes.main.colors.ui01};
  border-top: thin solid ${themes.main.colors.listBorder};
  padding: 14px 0px 14px 0px;
  font-weight: 400;
  font-size: 13px;
  color: ${themes.main.colors.fontColor};
  line-height: 18px;
  display: flex;
  flex-direction: row;
`;

export const ItemNumber = styled.div`
  padding: 0px 48px 0px 16px;
`;
