import React from 'react';

type Props = {
  [key: string]: string | number | Function;
};

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9378 12.6875L15.2503 11V8.125C15.2462 4.91103 12.8211 2.2165 9.62531 1.875V0.625H8.37531V1.875C5.19058 2.23806 2.77705 4.91976 2.75031 8.125V11L1.06281 12.6875C0.939354 12.7989 0.870843 12.9588 0.875306 13.125V15C0.86413 15.1689 0.92638 15.3345 1.0461 15.4542C1.16582 15.5739 1.33137 15.6362 1.50031 15.625H5.87531C5.87531 17.3509 7.27442 18.75 9.00031 18.75C10.7262 18.75 12.1253 17.3509 12.1253 15.625H16.5003C16.6692 15.6362 16.8348 15.5739 16.9545 15.4542C17.0742 15.3345 17.1365 15.1689 17.1253 15V13.125C17.1298 12.9588 17.0613 12.7989 16.9378 12.6875ZM9.00031 17.5C7.96477 17.5 7.12531 16.6605 7.12531 15.625H10.8753C10.8753 16.6605 10.0358 17.5 9.00031 17.5Z"
        fill="#333333"
      />
    </svg>
  );
};

export default Component;
