import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { theme } from '../../../config';
import Select, { PropsValue, components } from 'react-select';
import { WarningFilled32 } from '@carbon/icons-react';
import { Checkbox } from '../../atoms/Checkbox';
import { Close } from '../../atoms/Icons';
import words from '../../../constants/words';
import { Member } from '../../../domain/entities/member';

const StyledErrorMessage = styled.div`
  font-size: 0.75rem;
  margin-top: 4px;
  color: ${theme.colors.errorColor};
`;

const NumberDiv = styled.div`
  display: flex;
  border-radius: 12px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: ${theme.colors.chipColor};
  text-align: center;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
`;

const RowDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const NumberText = styled.p`
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.32px;
  margin-right: 5px;
  color: ${theme.colors.white};
`;

const PlaceholderText = styled.p`
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.16px;
  color: ${theme.colors.placeHolderText};
  margin-left: 7px;
`;

const StyledIcons = styled(WarningFilled32)`
  width: 15px;
  position: absolute;
  right: 55px;
  top: 40px;
  fill: ${theme.colors.errorColor};
`;

const CheckboxDiv = styled.div<{ $isLastItem?: boolean }>`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  border-bottom: ${(props): string =>
    props.$isLastItem ? 'none !important' : ' 1px solid #FEC483'};
`;

const StyledMultiSelect = styled(Select)<IStyledMultiSelect>`
  .css-1insrsq-control {
    background-color: ${props =>
      props.isDisabled ? theme.colors.disabledButton1 : theme.colors.ui02};
  }
  .css-1s2u09g-control {
    border: ${props => (!props.isError ? `1px` : `2px`)} solid
      ${props =>
        !props.isError
          ? theme.colors.inputBorderColor
          : theme.colors.errorColor};
    border-radius: 8px;
  }
  div.css-1n7v3ny-option .labelContainer,
  div.css-yt9ioa-option .labelContainer {
    border-bottom: 1px solid ${theme.colors.ui03};
    padding-top: 11px;
    padding-bottom: 11px;
    margin-left: 15px;
  }
  .css-1pahdxg-control:hover {
    border: 1px solid ${theme.colors.inputBorderColor};
  }
  .css-xb97g8:hover {
    background-color: transparent;
    color: ${theme.colors.white};
    cursor: pointer;
  }
  .css-1pahdxg-control {
    box-shadow: unset;
    border-color: ${theme.colors.inputBorderColor};
  }
  .css-1rhbuit-multiValue {
    background-color: ${props =>
      props.isDisabled ? theme.colors.disabled01 : theme.colors.chipColor};
    border-radius: 20px;
  }
  .css-12jo7m5,
  .css-1rhbuit-multiValue {
    color: ${props =>
      props.isDisabled ? theme.colors.disabled02 : theme.colors.white};
  }
  .css-12jo7m5 {
    font-size: 14px;
  }
  .css-1okebmr-indicatorSeparator {
    display: none;
  }
  .css-1n7v3ny-option {
    background-color: ${theme.colors.multiselectOptionBg};
  }
  .css-1n7v3ny-option:active {
    background-color: ${theme.colors.multiselectOptionBg};
  }
  &&&& {
    .css-yt9ioa-option,
    .css-1n7v3ny-option {
      color: ${theme.colors.fontColor};
      font-size: 13px;
      margin-right: 0px;
      margin-left: 0px;
      padding-left: 0px;
      cursor: pointer;
      padding: 0px;
    }
  }
  &&&& {
    div.css-yt9ioa-option:last-child .labelContainer,
    div.css-1n7v3ny-option:last-child .labelContainer {
      border-bottom: unset;
    }
  }
  .css-4ljt47-MenuList {
    padding: 0px;
    margin-right: 15px;
  }

  .css-26l3qy-menu {
    margin: 0px;
    border-radius: unset;
    background-color: ${theme.colors.dropDownContainerBgColor};
  }
`;

const Option = (props: any) => {
  return (
    <components.Option {...props}>
      <CheckboxDiv
        $isLastItem={
          props.data === undefined
            ? true
            : props.data.label === props.options[props.options.length - 1].label
            ? true
            : false
        }>
        <Checkbox
          isChecked={props.isSelected}
          onChange={() => null}
          label={props.label}
        />
      </CheckboxDiv>
    </components.Option>
  );
};

const CustomStyle = {
  option: (base: any, state: any) => ({
    ...base,
    backgroundColor: state.isSelected
      ? theme.colors.disabledButton1
      : theme.colors.dropDownContainerBgColor,
    fontSize: 13,
    color: state.isSelected ? theme.colors.icon01 : theme.colors.fontColor,
    height: 40,
    paddingTop: 0,
    paddingBottom: 0,
    '&:hover': {
      backgroundColor: state.isSelected
        ? theme.colors.disabledButton1
        : theme.colors.multiselectOptionBg,
      cursor: 'pointer',
    },
  }),
  menuList: (base: any) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
    '::-webkit-scrollbar': {
      paddingRight: -20,
    },
  }),
};

const usePrevious = (value: any) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

type IStyledMultiSelect = {
  isError?: boolean;
};

export type IOptions = {
  value: string;
  label: string;
};

export type IMemberMultiSelect = {
  isMulti?: boolean;
  isDisabled?: boolean;
  placeholder?: string;
  label?: string;
  errorMessage?: string;
  isError?: boolean;
  defaultValue?: PropsValue<IOptions>;
  isLoading?: boolean;
  options: IOptions[];
  onChange: (selectedList: unknown) => void;
  onChangeValue: (optionSelected: IOptions[] | null) => void;
  onChangeSelectedValue: (allSelected: Object | any) => void;
  addButtonClicked?: boolean;
  currentGroup?: string;
  tableRows?: Member[];
  value?: IOptions[] | null;
  allSelectedValue: Object | any;
  showRemoveDiv?: boolean | null;
  groupMemberList?: any;
};

const Component = ({
  onChange,
  isMulti = true,
  isDisabled = false,
  isError = false,
  isLoading,
  placeholder = '',
  defaultValue,
  errorMessage = '',
  options,
  addButtonClicked,
  currentGroup,
  tableRows,
  value,
  allSelectedValue,
  onChangeValue,
  onChangeSelectedValue,
  showRemoveDiv,
  groupMemberList,
  ...props
}: IMemberMultiSelect): React.ReactElement => {
  const [openMenu, setOpenMenu] = useState<undefined | boolean>(undefined);
  const [valueDelivered, setValueDelivered] = useState<boolean>(false);

  const optionsWithSelectAll =
    options.length < 1
      ? [...options]
      : [{ value: 'all', label: words.selectAll }, ...options];
  const prevGroup = usePrevious(currentGroup);
  const prevAddClicked = usePrevious(addButtonClicked);

  useEffect(() => {
    if (addButtonClicked) {
      setValueDelivered(false);
    }
    if (prevAddClicked !== addButtonClicked && addButtonClicked) {
      setOpenMenu(false);
    }
    if (currentGroup !== prevGroup) {
      if (allSelectedValue && currentGroup in allSelectedValue) {
        onChangeValue(allSelectedValue[currentGroup]);
      } else onChangeValue(null);
    }
    if (currentGroup) {
      if (currentGroup !== words.dropdownAll) {
        let allGroupSelectValue: any = [];
        for (const groupSelectValue in allSelectedValue) {
          if (
            groupSelectValue !== words.dropdownAll &&
            groupSelectValue !== currentGroup
          ) {
            allGroupSelectValue = allGroupSelectValue.concat(
              allSelectedValue[groupSelectValue],
            );
          }
          if (groupSelectValue === currentGroup) {
            allGroupSelectValue = allGroupSelectValue.concat(value);
          }
        }
        allGroupSelectValue = allGroupSelectValue?.filter((object: any) => {
          return object?.value !== 'all';
        });
        const lenTableRows = tableRows?.length as number;
        if (
          allGroupSelectValue.length ==
          groupMemberList['All'].length - lenTableRows
        ) {
          allGroupSelectValue = allGroupSelectValue.concat([
            {
              value: 'all',
              label: words.selectAll,
            },
          ]);
        }
        onChangeSelectedValue({
          ...allSelectedValue,
          [currentGroup]: value,
          All: allGroupSelectValue,
        });
      } else {
        const selectedValue: any = {};
        for (const group in groupMemberList) {
          let items: any = [];
          if (value?.length === 0) {
            selectedValue[group] = [];
          }
          value?.map((item: any) => {
            const valueInGroup = groupMemberList[group].filter(
              (member: any) => {
                return member.id == item?.value;
              },
            );
            if (valueInGroup.length > 0 && group !== words.dropdownAll) {
              items = items.concat([item]);
              const tableAdded: any = tableRows?.filter((row: any) => {
                return row.group.name == group;
              });
              if (
                items.length ==
                groupMemberList[group].length - tableAdded?.length
              ) {
                items = items.concat([
                  {
                    value: 'all',
                    label: words.selectAll,
                  },
                ]);
              }
              selectedValue[group] = items;
            }
          });
        }
        onChangeSelectedValue({
          ...allSelectedValue,
          [currentGroup]: value,
          ...selectedValue,
        });
      }
    }
  }, [addButtonClicked, currentGroup, value, showRemoveDiv]);

  const handleChange = (selected: any) => {
    const finalSelected = selected;
    let trimSelected = undefined;
    let editSelected: any[] = [];
    if (finalSelected === 'clear') {
      onChangeValue(null);
      onChangeSelectedValue(null);
      onChange([{ value: '', label: '' }]);
    } else {
      setValueDelivered(false);
      if (
        selected.find((option: any) => option.value === 'all') &&
        value?.every(ai => selected.includes(ai)) === false
      ) {
        trimSelected = selected?.filter(function(obj: any) {
          return obj.value !== 'all';
        });
      }
      if (
        selected.length === options.length &&
        value?.length === optionsWithSelectAll.length
      ) {
        editSelected = [];
      } else {
        if (selected.length === options.length) {
          editSelected = optionsWithSelectAll;
        } else {
          editSelected = options.filter(a =>
            selected.some((b: any) => a.value === b.value),
          );
        }
      }
      if (selected.length > 0) {
        onChangeValue(
          selected.find((option: any) => option.value === 'all')
            ? trimSelected == undefined
              ? optionsWithSelectAll
              : trimSelected
            : editSelected,
        );
      } else {
        onChangeValue([]);
      }
    }
  };

  const ValueContainer: React.FC<any> = ({ children, getValue, ...props }) => {
    let nullCount = 0;
    let allCount = 0;
    let count = 0;
    const allSelected: any = [];
    if (allSelectedValue) {
      const flatAllSelectedValue: any = Object.values(allSelectedValue).flat();
      flatAllSelectedValue.map((e: any) => {
        if (!allSelected.includes(e?.value)) {
          allSelected.push(e?.value);
        }
      });
      if (allSelected.includes(undefined)) {
        nullCount = 1;
      }
      if (allSelected.includes('all')) {
        allCount = 1;
      }
      count = allSelected.length - (nullCount + allCount);
    }

    if (count > 0) {
      return (
        <components.ValueContainer {...props}>
          <RowDiv>
            <NumberDiv
              onClick={() => {
                handleChange('clear');
              }}>
              <NumberText>{count}</NumberText>
              <Close color={theme.colors.white} width="8px" height="8px" />
            </NumberDiv>
            <PlaceholderText>{words.searchMember}</PlaceholderText>
            {React.Children.map(children, child => {
              return child.props.id === 'clickableInput' ? child : null;
            })}
          </RowDiv>
        </components.ValueContainer>
      );
    }

    return (
      <components.ValueContainer {...props}>
        {children}
      </components.ValueContainer>
    );
  };

  const handleOpenMenu = () => {
    setOpenMenu(undefined);
  };
  if (
    allSelectedValue &&
    currentGroup &&
    allSelectedValue[currentGroup] &&
    valueDelivered === false &&
    value?.length === allSelectedValue[currentGroup].length
  ) {
    const trim = Object.values(allSelectedValue)
      .flat()
      .filter(function(obj: any) {
        return obj !== null && obj.value !== 'all';
      });
    if (
      trim.every((v: any) => tableRows?.some(w => w.id.toString() === v.value))
    ) {
      onChangeSelectedValue(null);
      onChangeValue(null);
    } else onChange(trim);
    setValueDelivered(true);
  }
  if (
    allSelectedValue &&
    currentGroup &&
    value === null &&
    valueDelivered === false &&
    allSelectedValue[currentGroup] === null
  ) {
    onChangeValue(null);
    if (addButtonClicked) {
      onChangeSelectedValue(null);
    }
  }
  return (
    <>
      <StyledMultiSelect
        inputId="clickableInput"
        value={value}
        isSearchable={false}
        options={optionsWithSelectAll}
        isMulti
        isClearable={false}
        onChange={handleChange}
        isDisabled={isDisabled}
        placeholder={placeholder}
        isError={isError}
        isLoading={isLoading}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        styles={CustomStyle}
        menuIsOpen={!openMenu ? openMenu : undefined}
        onMenuOpen={handleOpenMenu}
        components={{
          Option,
          ValueContainer,
        }}
        noOptionsMessage={() => words.noMembersAvailable}
        loadingMessage={() => words.loadingMultiSelect}
        {...props}
      />
      {isError ? (
        <>
          <StyledIcons className="errorIcon" />
          <StyledErrorMessage className="errorLabel">
            <span>{errorMessage}</span>
          </StyledErrorMessage>
        </>
      ) : (
        ''
      )}
    </>
  );
};

export default Component;
