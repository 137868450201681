import React from 'react';
import { Tab, Tabs } from 'carbon-components-react';
import styled from 'styled-components';
import { theme } from '../../../config';
import { Props } from './Tabs';

const TabsWrapper = styled.div`
  .bx--tabs--scrollable {
    background-color: ${theme.colors.ui03};
    padding-left: 70px;
  }

  .bx--tabs--scrollable button {
    border-bottom: 2px solid ${theme.colors.ui01};
  }

  .bx--tabs--scrollable
    .bx--tabs--scrollable__nav-item--selected
    .bx--tabs--scrollable__nav-link {
    border-bottom: 2px solid ${theme.colors.primaryButton1};
  }

  .bx--tabs--scrollable .bx--tabs--scrollable__nav-link:active,
  .bx--tabs--scrollable .bx--tabs--scrollable__nav-link:focus {
    outline: 2px solid transparent;
  }
`;

const Component = ({
  tabs,
  className,
  onSelectionChange,
}: Props): React.ReactElement => {
  return (
    <TabsWrapper>
      <Tabs className={className} onSelectionChange={onSelectionChange}>
        {tabs.map(tab => (
          <Tab
            key={tab.id}
            id={tab.id}
            label={tab.label}
            disabled={tab.disabled}>
            {tab.element}
          </Tab>
        ))}
      </Tabs>
    </TabsWrapper>
  );
};

export default Component;
