import { Group } from '../../../domain/entities/group';
import { PaginationQuery } from '../../../domain/entities/request';
import { PaginatedResponse } from '../../../domain/entities/response';
import GroupRepository from '../../ports/GroupRepository';

export type fetchGroupsUseCase = (
  query: PaginationQuery & { companyId: number },
) => Promise<PaginatedResponse & { results: Group[] }>;

export const buildFetchGroups = (dependencies: {
  groupRepo: GroupRepository;
}): fetchGroupsUseCase => {
  const { groupRepo } = dependencies;

  const fetchGroups: fetchGroupsUseCase = async query => groupRepo.fetch(query);

  return fetchGroups;
};
