import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  DataTable,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  DataTableCustomRenderProps,
  DataTableHeader,
  DataTableRow,
} from 'carbon-components-react';
import { theme } from '../../../config';
import { EmptyResultDisplay } from '../../organisms/EmptyResultDisplay';
import words from '../../../constants/words';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Drag } from '../../atoms/Icons';

const StyledTable = styled(Table)`
  thead {
    font-size: 13px;
  }
  tbody {
    font-size: 13px;
  }
  th {
    background-color: ${theme.colors.ui03};
    width: 100vw;
  }
  && {
    td {
      background-color: ${theme.colors.ui01};
    }
    .bx--data-table--selected td {
      background-color: ${theme.colors.disabledButton1};
    }
    tr {
      :hover td {
        background-color: ${theme.colors.hoverui};
      }
    }
  }
`;

const StyledCell = styled.div`
  display: flex;
  flex-direction: row;
  width: 50px;
  justify-content: space-between;
`;

const StyledBasicCell = styled.div<{ $isHeader?: boolean }>`
  width: ${(props): string => (props.$isHeader ? '30px' : '50px')};
  display: flex;
  justify-content: flex-end;
`;

const StyledBasicTable = styled.table``;

export type TableHeaderType = DataTableHeader;

export type TableRowType = DataTableRow;

export type Props = {
  headers: DataTableHeader[];
  rows: DataTableRow[];
  className?: string;
  disabledColumns?: boolean;
  emptyDataLabelTitle?: string;
  emptyDataLabelDetails?: string;
  onDrag?: (order: any[]) => void;
  disableDrag?: boolean;
  indexObject?: Object;
};

const Component = ({
  headers,
  rows,
  className,
  emptyDataLabelTitle = '',
  emptyDataLabelDetails = '',
  onDrag,
  disableDrag,
  indexObject,
}: Props): React.ReactElement => {
  const handleOnDragEnd = (result: any, rows: DataTableRow[]) => {
    if (!result.destination) return;

    const items = Array.from(rows);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    onDrag && onDrag(items);
  };
  const arr: any[] = rows;
  const currIndex: any = indexObject;

  return (
    <DataTable rows={rows} headers={headers}>
      {({ rows: _rows, headers: _headers }: DataTableCustomRenderProps) => (
        <DragDropContext
          onDragEnd={e => {
            handleOnDragEnd(e, rows);
          }}>
          <Droppable droppableId="Table">
            {provided => (
              <StyledBasicTable
                ref={provided.innerRef}
                {...provided.droppableProps}>
                <StyledTable className={className}>
                  <TableHead>
                    <TableRow>
                      {_headers.map(header => (
                        <>
                          {header.key === 'number' && !disableDrag ? (
                            <TableHeader key={header.key}>
                              <StyledBasicCell $isHeader={true}>
                                {header.header}
                              </StyledBasicCell>
                            </TableHeader>
                          ) : (
                            <TableHeader key={header.key}>
                              {header.header}
                            </TableHeader>
                          )}
                        </>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {_rows.length ? (
                      _rows.map((row: any, index: number) => (
                        <>
                          {index <= 3 ? (
                            <>
                              {index === 3 ? (
                                <>
                                  {indexObject &&
                                  Number(arr[3].id) === currIndex.id ? (
                                    <TableRow key={row.id}>
                                      {row.cells.map((cell: any) => (
                                        <>
                                          {cell.info.header === 'number' &&
                                          !disableDrag ? (
                                            <TableCell key={cell.id}>
                                              <StyledBasicCell
                                                $isHeader={false}>
                                                {cell.value}
                                              </StyledBasicCell>
                                            </TableCell>
                                          ) : (
                                            <TableCell key={cell.id}>
                                              {cell.value}
                                            </TableCell>
                                          )}
                                        </>
                                      ))}
                                    </TableRow>
                                  ) : (
                                    <Draggable
                                      key={row.id}
                                      draggableId={row.id}
                                      index={index}
                                      isDragDisabled={disableDrag}>
                                      {(provided, snapshot) => (
                                        <tr
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}>
                                          {row.cells.map((cell: any) => (
                                            <>
                                              {cell.info.header === 'number' &&
                                              !disableDrag ? (
                                                <TableCell key={cell.id}>
                                                  <StyledCell>
                                                    <Drag />
                                                    {cell.value}
                                                  </StyledCell>
                                                </TableCell>
                                              ) : (
                                                <TableCell key={cell.id}>
                                                  {cell.value}
                                                </TableCell>
                                              )}
                                            </>
                                          ))}
                                        </tr>
                                      )}
                                    </Draggable>
                                  )}
                                </>
                              ) : (
                                <TableRow key={row.id}>
                                  {row.cells.map((cell: any) => (
                                    <>
                                      {cell.info.header === 'number' &&
                                      !disableDrag ? (
                                        <TableCell key={cell.id}>
                                          <StyledBasicCell $isHeader={false}>
                                            {cell.value}
                                          </StyledBasicCell>
                                        </TableCell>
                                      ) : (
                                        <TableCell key={cell.id}>
                                          {cell.value}
                                        </TableCell>
                                      )}
                                    </>
                                  ))}
                                </TableRow>
                              )}
                            </>
                          ) : (
                            <Draggable
                              key={row.id}
                              draggableId={row.id}
                              index={index}
                              isDragDisabled={disableDrag}>
                              {(provided, snapshot) => (
                                <tr
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}>
                                  {row.cells.map((cell: any) => (
                                    <>
                                      {cell.info.header === 'number' &&
                                      !disableDrag ? (
                                        <TableCell key={cell.id}>
                                          <StyledCell>
                                            <Drag />
                                            {cell.value}
                                          </StyledCell>
                                        </TableCell>
                                      ) : (
                                        <TableCell key={cell.id}>
                                          {cell.value}
                                        </TableCell>
                                      )}
                                    </>
                                  ))}
                                </tr>
                              )}
                            </Draggable>
                          )}
                        </>
                      ))
                    ) : (
                      <TableRow key={_rows.length}>
                        <TableCell colSpan={_headers.length}>
                          <EmptyResultDisplay
                            title={emptyDataLabelTitle}
                            details={emptyDataLabelDetails}
                          />
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                  {provided.placeholder}
                </StyledTable>
              </StyledBasicTable>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </DataTable>
  );
};

export default Component;
