import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FormikErrors } from 'formik';

import words from '../../../constants/words';
import { theme } from '../../../config';

import { TextInput } from '../../atoms/TextInput';
import { Button } from '../../atoms/Button';
import { Checkbox } from '../../atoms/Checkbox';
import { AnchorTag } from '../../atoms/AnchorTag';
import { Dropdown } from '../../molecules/Dropdown';
import { UserImage } from '../../molecules/UserImage';
import { Props as INotification } from '../../molecules/Notification/Notification';
import {
  ViewMemberInfo,
  UpdateMemberType,
  MemberField,
} from '../../../domain/entities/member';
import data, { Role } from '../../../constants/data';
import { Group } from '../../../domain/entities/group';
import { CheckboxProps } from 'carbon-components-react';

const MainContainer = styled.div`
  margin-bottom: 21px;
  display: flex;
  flex-direction: column;
`;

const ContentContainers = styled.div`
  flex: 0.5;

  .bx--dropdown__wrapper.bx--list-box__wrapper {
    padding-bottom: 19px;
  }

  @media ${theme.breakpoints.mobile} {
    .bx--dropdown {
      height: 32px;
    }
  }

  & > div:first-child {
    margin-top: 40px;
    @media ${theme.breakpoints.mobile} {
      margin-top: 25px;
    }
  }

  & > div:not(:first-child) {
    margin-top: 25px;
    @media ${theme.breakpoints.mobile} {
      margin-top: 5px;
    }
  }

  .bx--list-box__label {
    font-size: 13px;
  }
`;

const FieldContainer = styled.div`
  display: flex;
  flex-directon: row;
  @media ${theme.breakpoints.mobile} {
    flex-direction: column;
  }
`;

const LeftContainer = styled(ContentContainers)`
  padding-right: 20px;
  @media ${theme.breakpoints.mobile} {
    padding-right: 0px;
  }
`;

const LeftContainerCustomFields = styled(ContentContainers)`
  padding-top: -20px;
  & > div:first-child {
    @media ${theme.breakpoints.mobile} {
      margin-top: 5px;
    }
  }
`;

const LeftContainerImage = styled(LeftContainer)`
  display: flex;
  align-items: center;
  justify-content: center;
  @media ${theme.breakpoints.mobile} {
    margin-bottom: 15px;
  }
`;

const RightContainer = styled(ContentContainers)`
  padding-left: 20px;

  & > div:first-child {
    @media ${theme.breakpoints.mobile} {
      margin-top: 5px;
    }
  }

  & > div:last-child {
    margin-top: 0px;
  }
  @media ${theme.breakpoints.mobile} {
    padding-left: 0px;
  }
`;

const PasswordDiv = styled.div<{ emailInvalid: boolean }>`
  margin-top: 20px !important;
  display: flex;
  flex-direction: column;
  width: fit-content;
`;

const PasswordText = styled.p`
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.32px;
  color: ${theme.colors.disabled02};
  padding-bottom: 18px;
  @media ${theme.breakpoints.mobile} {
    padding-bottom: 8px;
  }
`;

const StyledAnchorTag = styled(AnchorTag)`
  @media ${theme.breakpoints.mobile} {
    margin-bottom: 20px;
  }
`;

const SubmitButton = styled(Button)`
  width: 141px;
  height: 48px;
  @media ${theme.breakpoints.mobile} {
    width: 100%;
  }
`;

const StyledCheckbox = styled(Checkbox)<
  Partial<CheckboxProps> & { emailInvalid: boolean }
>`
  margin-top: ${props => (props.emailInvalid ? '-20px;' : '-35px;')};
  @media ${theme.breakpoints.mobile} {
    margin-top: ${props => (props.emailInvalid ? '5px;' : '-15px;')};
  }
`;

const ButtonsDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 300px;
  justify-content: space-between;
  @media ${theme.breakpoints.mobile} {
    flex-direction: column-reverse;
    width: 100%;
    gap: 15px;
  }
`;

export type UserProfile = Pick<
  ViewMemberInfo,
  Exclude<keyof ViewMemberInfo, 'memberCustomFields' | 'email'>
> & {
  userId: number;
  emailNotif: boolean;
  email: string;
  memberCustomFields: Array<MemberField & { isRequired: boolean }> | undefined;
};

export type Props = UserProfile & {
  isUserImageLoading: boolean;
  isLoading: boolean;
  toastNotification: (toast: INotification) => void;
  handleChange: (e: React.ChangeEvent) => void;
  errors: FormikErrors<UserProfile>;
  onClickButton: () => void;
  onClickCancel: () => void;
  onOpenChangePasswordModal: () => void;
  isEdit?: boolean;
  setFieldValue: (
    field: string,
    value: unknown,
    shouldValidate?: boolean | undefined,
  ) => void;
  memberInfo: UserProfile;
  previousValues?: UserProfile;
  handleChangeRole: ({ selectedItem }: { selectedItem: Role }) => void;
  handleChangeGroup: ({ selectedItem }: { selectedItem: Group }) => void;
  handleChangeEmail: (e: React.ChangeEvent) => void;
  selectedRole?: Role;
  selectedGroup?: Group;
  memberEmailRes?: string;
  roleChangeError?: string;
  groupItems: Array<Group>;
  isChangingEmail?: boolean;
  setImage?: (src: string | undefined) => void;
};

const Component = ({
  name,
  companyId,
  userId,
  id,
  email,
  groupName,
  roleName,
  profileImage,
  emailNotif,
  handleChange,
  setFieldValue,
  isEdit = false,
  onClickButton,
  onClickCancel,
  toastNotification,
  isUserImageLoading,
  onOpenChangePasswordModal,
  errors,
  memberCustomFields,
  handleChangeRole = () => {},
  handleChangeGroup = () => {},
  handleChangeEmail = () => {},
  selectedRole,
  selectedGroup,
  memberEmailRes,
  roleChangeError,
  groupItems,
  previousValues,
  isChangingEmail = false,
  setImage,
}: Props): React.ReactElement => {
  const [customFieldsErrors, setCustomFieldsErrors] = useState<number[]>([]);

  const handleChangeCheckbox = (isChecked: boolean): void =>
    setFieldValue('emailNotif', isChecked);

  const handleChangeCustomField = (index: number, isRequired: boolean) => (
    event: React.ChangeEvent,
  ) => {
    const fieldValue = (event.target as HTMLInputElement).value;
    setFieldValue(`memberCustomFields[${index}].value_char`, fieldValue || '');
    if (!fieldValue && isRequired && !customFieldsErrors.includes(index)) {
      setCustomFieldsErrors([...customFieldsErrors, index]);
    } else if (fieldValue && isRequired && customFieldsErrors.includes(index)) {
      setCustomFieldsErrors(customFieldsErrors.filter(item => item !== index));
    }
  };

  const selectRolesList = (role: string): Role[] => {
    switch (role) {
      case 'admin':
        return data.memberRoles;
      case 'approver':
        return data.approverOnlyRoles;
      case 'member':
        return data.memberOnlyRoles;
      default:
        return data.memberOnlyRoles;
    }
  };

  useEffect(() => {
    if (!isEdit) {
      setCustomFieldsErrors([]);
    } else if (typeof memberCustomFields !== undefined) {
      memberCustomFields?.map((customField, index) => {
        if (customField.isRequired && !customField.value_char) {
          setCustomFieldsErrors([...customFieldsErrors, index]);
        }
      });
    }
  }, [isEdit]);

  return (
    <MainContainer>
      <FieldContainer>
        <LeftContainerImage>
          <UserImage
            imageSrc={profileImage ? profileImage : undefined}
            memberId={id}
            isLoading={isUserImageLoading}
            toastNotification={toastNotification}
            setNewlyUpdatedMember={(member: UpdateMemberType | undefined) =>
              setFieldValue('profileImage', member?.photoUrl || '')
            }
            setImage={setImage}
          />
        </LeftContainerImage>
        <RightContainer />
      </FieldContainer>

      <FieldContainer>
        <LeftContainer>
          <TextInput
            id="name"
            label={`${words.name}${'\u00A0'} ${words.requiredLabel}`}
            disabled={!isEdit}
            value={name}
            onChange={handleChange}
            invalid={!!errors?.name}
          />
          <Dropdown
            id="groupName"
            label={groupName || ''}
            titleText={`${words.group}${'\u00A0'} ${words.requiredLabel}`}
            items={groupItems || []}
            disabled={!isEdit || previousValues?.roleName === 'member'}
            onChange={handleChangeGroup}
            backgroundColor={theme.colors.white}
            styles={{ width: '100%' }}
            dropdownProps={{
              selectedItem: selectedGroup,
              itemToString: (item: Group) => item.name,
            }}
          />
          <Dropdown
            id="roleName"
            label={data.memberRoles.find(e => e.id === roleName)?.name || ''}
            titleText={`${words.role}${'\u00A0'} ${words.requiredLabel}`}
            items={selectRolesList(
              previousValues?.roleName || selectedRole?.id || 'member',
            )}
            disabled={!isEdit || previousValues?.roleName === 'member'}
            onChange={handleChangeRole}
            backgroundColor={theme.colors.white}
            styles={{ width: '100%' }}
            dropdownProps={{
              selectedItem: selectedRole,
              itemToString: (item: Role) => item.name,
            }}
            invalid={!!roleChangeError}
            invalidText={roleChangeError}
          />
          {typeof memberCustomFields !== undefined && (
            <LeftContainerCustomFields>
              {memberCustomFields?.map((customField, index) => (
                <TextInput
                  key={customField.name ? customField.name : index.toString()}
                  id={customField.name ? customField.name : index.toString()}
                  disabled={!isEdit}
                  maxLength={50}
                  label={
                    customField.isRequired
                      ? `${customField.name}${'\u00A0'} ${words.requiredLabel}`
                      : `${customField.name}${'\u00A0'} ${words.optional}`
                  }
                  value={customField.value_char}
                  invalid={customFieldsErrors.includes(index)}
                  onChange={handleChangeCustomField(
                    index,
                    customField?.isRequired,
                  )}
                />
              ))}
            </LeftContainerCustomFields>
          )}
        </LeftContainer>
        <RightContainer>
          <TextInput
            id="companyId"
            label={words.companyId}
            value={String(companyId).padStart(3, '0')}
            onCopyPress={async () => {
              if ('clipboard' in navigator) {
                await navigator.clipboard.writeText(
                  String(companyId).padStart(3, '0'),
                );
              }
            }}
            disabled
          />
          <TextInput
            id="userId"
            label={words.userId}
            value={String(userId).padStart(4, '0')}
            onCopyPress={async () => {
              if ('clipboard' in navigator) {
                await navigator.clipboard.writeText(
                  String(userId).padStart(4, '0'),
                );
              }
            }}
            disabled
          />
          <TextInput
            id="email"
            label={`${words.userEmailAddress}${'\u00A0'} ${words.optional}`}
            disabled={!isEdit}
            value={email}
            onChange={handleChangeEmail}
            invalid={!!errors.email || !!memberEmailRes}
            invalidText={errors?.email || memberEmailRes}
            onCopyPress={async () => {
              if ('clipboard' in navigator) {
                await navigator.clipboard.writeText(`${email}`);
              }
            }}
          />
          <StyledCheckbox
            id={'emailNotif'}
            name={'emailNotif'}
            label={words.emailNotif}
            onChange={handleChangeCheckbox}
            isChecked={emailNotif}
            disabled={!isEdit || !!!email}
            emailInvalid={!!errors.email || !!memberEmailRes}
          />
          <PasswordDiv emailInvalid={!!errors.email || !!memberEmailRes}>
            <PasswordText>{words.password}</PasswordText>
            <StyledAnchorTag
              onPress={onOpenChangePasswordModal}
              title={words.changePassword}
              type="edit"
            />
          </PasswordDiv>
        </RightContainer>
      </FieldContainer>
      <FieldContainer>
        <LeftContainer>
          <ButtonsDiv>
            {isEdit && (
              <SubmitButton
                onPress={onClickCancel}
                theme="light"
                title={words.cancel}
              />
            )}
            <SubmitButton
              onPress={onClickButton}
              title={isEdit ? words.save : words.editUserProfile}
              disabled={
                isEdit &&
                (!!errors?.name ||
                  !!errors?.email ||
                  !!!name ||
                  !selectedGroup ||
                  !selectedRole ||
                  !!roleChangeError ||
                  (!!memberEmailRes && email.length > 1) ||
                  customFieldsErrors.length > 0 ||
                  isChangingEmail)
              }
            />
          </ButtonsDiv>
        </LeftContainer>
      </FieldContainer>
    </MainContainer>
  );
};

export default Component;
