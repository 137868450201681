import React from 'react';

type PropType = {
  text: string;
};

export default function LoadingWhenWait(props: PropType) {
  const { text } = props;
  return <div style={{ padding: '15px 0 0 15px' }}>{text}</div>;
}
