import { RankingFieldsLookup } from '../../../domain/entities/ranking';
import RankingRepository from '../../ports/RankingRepository';

export type fetchRankingFieldsLookupUseCase = (query: {
  companyId: number;
  rankingId: number;
}) => Promise<RankingFieldsLookup[]>;

export const buildFetchRankingFieldsLookup = (dependencies: {
  rankingRepo: RankingRepository;
}): fetchRankingFieldsLookupUseCase => {
  const { rankingRepo } = dependencies;

  const fetchRankingFieldsLookup: fetchRankingFieldsLookupUseCase = async query =>
    rankingRepo.fetchRankingFieldsLookup(query);

  return fetchRankingFieldsLookup;
};
