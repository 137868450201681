import React from 'react';

type Props = {
  [key: string]: string | number | Function;
};

const ReceivedApplicationNotif = (props: Props): React.ReactElement => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="16" cy="16" r="16" fill="#DEFBE6" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.35 9.14992L20.85 12.6499C20.9488 12.739 21.0036 12.8669 21 12.9999V21.9999C21 22.5522 20.5523 22.9999 20 22.9999H12C11.4477 22.9999 11 22.5522 11 21.9999V9.99992C11 9.44764 11.4477 8.99992 12 8.99992H17C17.133 8.99635 17.2609 9.05116 17.35 9.14992ZM16.496 17.4958H18.496V18.4958H16.496V20.4958H15.496V18.4958H13.496V17.4958H15.496V15.4958H16.496V17.4958ZM17 10.1999L19.8 12.9999H17V10.1999ZM12 21.9999H20V13.9999H17C16.4477 13.9999 16 13.5522 16 12.9999V9.99992H12V21.9999Z"
        fill="#27AE60"
      />
    </svg>
  );
};

export default ReceivedApplicationNotif;
