import React, { useCallback, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAuthHooks } from '../../../hooks/auth';
import { ForgetPassword } from '../../templates/ForgetPassword';

type Props = {};

const Component = ({}: Props): React.ReactElement => {
  const navigate = useNavigate();
  const location = useLocation();

  const { useResetPassword } = useAuthHooks();

  const { forgetPassword } = useResetPassword();

  const [isSuccessNotif, setIsSuccessNotif] = useState<boolean>(false);
  const [isErrorNotif, setIsErrorNotif] = useState<boolean>(false);
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
  const { mutate: forgetPasswordMutation } = useMutation((email: string) => {
    return forgetPassword(email);
  });

  const onSubmit = useCallback(
    (values: { email: string }, resetForm?: () => void): void => {
      const { email } = values;
      setIsBtnLoading(true);
      forgetPasswordMutation(email, {
        onSuccess: () => {
          setIsSuccessNotif(true);
          setIsErrorNotif(false);
        },
        onError: () => {
          if (location.state && location?.state?.isTokenExpired) {
            setIsSuccessNotif(false);
            return setIsErrorNotif(true);
          }
          setIsSuccessNotif(true);
          setIsErrorNotif(false);
        },
        onSettled: () => {
          setIsBtnLoading(false);
          resetForm && resetForm();
        },
      });
    },
    [],
  );

  const redirectLogin = () => {
    navigate('/login');
  };

  const setToDefaultNotifState = () => {
    setIsSuccessNotif(false);
    setIsErrorNotif(false);
  };

  useEffect(() => {
    if (location.state && location?.state?.isTokenExpired) {
      setIsErrorNotif(true);
    }
  }, [location.state]);

  return (
    <ForgetPassword
      successNotif={isSuccessNotif}
      errorNotif={isErrorNotif}
      forgetPassword={onSubmit}
      handleLogin={redirectLogin}
      isBtnLoading={isBtnLoading}
      setToDefaultNotifState={setToDefaultNotifState}
    />
  );
};

export default Component;
