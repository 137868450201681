import { Ranking } from '../../../domain/entities/rankings';
import RankingRepository from '../../ports/RankingRepository';

export type fetchRankingDetailsUseCase = (identifiers: {
  companyId: number;
  rankingId: number;
}) => Promise<Ranking>;

export const buildFetchRankingDetails = (dependencies: {
  rankingRepo: RankingRepository;
}): fetchRankingDetailsUseCase => {
  const { rankingRepo } = dependencies;

  const fetchRankingDetails: fetchRankingDetailsUseCase = async identifiers =>
    rankingRepo.fetchRankingDetails(identifiers);

  return fetchRankingDetails;
};
