import { CreateEntryApplication } from '../../../domain/entities/entry';
import EntryRepository from '../../ports/EntryRepository';

export type createEntryApplicationUseCase = (
  companyId: number,
  memberId: number,
  rankingId: number,
  payload: CreateEntryApplication,
) => Promise<Response>;

export const buildCreateEntryApplication = (dependencies: {
  entryRepo: EntryRepository;
}): createEntryApplicationUseCase => {
  const { entryRepo } = dependencies;

  const createEntryApplication: createEntryApplicationUseCase = async (
    companyId,
    memberId,
    rankingId,
    payload,
  ) =>
    entryRepo.createEntryApplication(companyId, memberId, rankingId, payload);

  return createEntryApplication;
};
