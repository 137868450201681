import { RankingResult } from '../../../domain/entities/ranking';
import { PaginationQuery } from '../../../domain/entities/request';
import { PaginatedResponse } from '../../../domain/entities/response';
import RankingRepository from '../../ports/RankingRepository';

export type fetchRankingResultUseCase = (
  query: PaginationQuery & {
    companyId: number;
    rankingId: number;
    rankingPeriodStart?: Date;
    rankingPeriodEnd?: Date;
  },
) => Promise<PaginatedResponse & { results: RankingResult[] }>;

export const buildFetchRankingResult = (dependencies: {
  rankingRepo: RankingRepository;
}): fetchRankingResultUseCase => {
  const { rankingRepo } = dependencies;

  const fetchRankingResult: fetchRankingResultUseCase = async query =>
    rankingRepo.fetchRankingResult(query);

  return fetchRankingResult;
};
