import React from 'react';

type Props = {
  [key: string]: string | number | Function;
};

const DeclineNotif = (props: Props): React.ReactElement => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <circle cx="16" cy="16" r="16" fill="#FFF1F1" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.35 9.15L20.85 12.65C20.9488 12.7391 21.0036 12.867 21 13V22C21 22.5523 20.5523 23 20 23H12C11.4477 23 11 22.5523 11 22V10C11 9.44771 11.4477 9 12 9H17C17.133 8.99643 17.2609 9.05123 17.35 9.15ZM18.496 17.5038H13.496V18.5038H18.496V17.5038ZM17 10.2L19.8 13H17V10.2ZM12 22H20V14H17C16.4477 14 16 13.5523 16 13V10H12V22Z"
        fill="#DA1E28"
      />
    </svg>
  );
};

export default DeclineNotif;
