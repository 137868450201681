import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import './arrowShowCalendar.scss';
import ArrowImage from 'assets/images/arrow.svg';

type PropType = {
  handleClickArrow: () => void;
};

export default function ArrowShowCalendar(props: PropType) {
  const { handleClickArrow } = props;
  const [expand, setExpand] = useState(true);
  return (
    <div className="arrowCalendar">
      <img
        src={ArrowImage}
        className={'arrowImg ' + `${!expand ? 'transformArrow' : ''}`}
        onClick={() => {
          handleClickArrow();
          setExpand(!expand);
        }}
      />
    </div>
  );
}
