import { FetchEntryFieldProps } from '../domain/entities/entry';
import { RankingFields } from '../domain/entities/rankings';

type ObjectWithId = { id: number } & Object;

export const isEqualIgnoreOrderUsingIds = (
  first: ObjectWithId[],
  second: ObjectWithId[],
): boolean => {
  if (first.length !== second.length) return false;

  const firstIds = first.map(item => item.id);
  const secondIds = second.map(item => item.id);

  const uniqueIds = new Set([...firstIds, ...secondIds]);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  for (const uniqueId of uniqueIds) {
    const firstCount = first.filter(item => item.id === uniqueId).length;
    const secondCount = second.filter(item => item.id === uniqueId).length;

    if (firstCount !== secondCount) return false;
  }

  return true;
};

export const moveIsBasisFieldAtFirstPosition = (
  fields: FetchEntryFieldProps[],
): FetchEntryFieldProps[] => {
  fields.forEach((field, index) => {
    if (field.is_basis === true) {
      fields.splice(index, 1);
      fields.unshift(field);
    }
  });
  return fields;
};

export const moveIsBasisFieldAtFirstPositionRecordApproval = (
  fields: RankingFields[] | undefined,
): RankingFields[] => {
  fields?.forEach((field, index) => {
    if (field.is_basis === true) {
      fields.splice(index, 1);
      fields.unshift(field);
    }
  });
  return fields || ([] as RankingFields[]);
};
