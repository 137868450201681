import React, { ReactElement } from 'react';

import { ViewUserProfile as AccountConfig } from '../../templates/ViewUserProfile';
import { SubHeader } from '../../atoms/Subheader';

import words from '../../../constants/words';

type Props = {
  setImage: (src: string | undefined) => void;
};

const Component = ({ setImage }: Props): ReactElement => {
  return (
    <>
      <SubHeader title={words.accountInformation} />
      <AccountConfig setImage={setImage} />
    </>
  );
};

export default Component;
