import React, { useEffect, useState } from 'react';
import { ProgressBar } from 'react-bootstrap';
import { sphForMonthPerson } from 'type/sph';
import { dateToStringOnlyMonth, renderWeekNumber } from 'utils/common';
import './personalWeekTemplates.scss';

type PropType = {
  dateCalendar: Date;
  sphForWeekData: sphForMonthPerson;
  currentWeek: number;
};

export default function PersonalWeekTemplates(props: PropType) {
  const { sphForWeekData, dateCalendar, currentWeek } = props;

  useEffect(() => {
    console.log(sphForWeekData);
  }, [sphForWeekData]);

  return (
    <div className="personalDayTemplates">
      <div className="option-bar">
        <div className="day-title">
          {dateToStringOnlyMonth(dateCalendar)}第{renderWeekNumber(currentWeek)}
          週
        </div>
        <div className="progress-bar">
          <ProgressBar
            animated
            now={
              parseInt(sphForWeekData.sum_process_percent) > 100
                ? 100
                : parseInt(sphForWeekData.sum_process_percent)
            }
            label={`${
              parseInt(sphForWeekData.sum_process_percent) > 100
                ? 100
                : parseInt(sphForWeekData.sum_process_percent)
            }%`}
            variant="success"
            className="progress"
          />
        </div>
      </div>
      <div className="data-bar">
        <div className="working-time">
          稼働時間（計画/結果）：{' '}
          <span className="value-data">
            {sphForWeekData.sum_time_expect} / {sphForWeekData.sum_time_actual}
          </span>
        </div>
        <div className="number-of-product">
          獲得件数（結果）：
          <span className="value-data">
            {sphForWeekData.plan_month_number_of_order} /{' '}
            {sphForWeekData.sum_product_actual}
          </span>{' '}
          （取切：
          <span className="value-data">
            {sphForWeekData.sum_number_of_withdrawals}
          </span>{' '}
          ＆ 前確：
          <span className="value-data">
            {sphForWeekData.sum_pre_probable_number}
          </span>
          )
        </div>
        <div className="sph-result">
          SPH（結果）：
          <span className="value-data">
            {(
              parseFloat(sphForWeekData.plan_month_number_of_order) /
              parseFloat(sphForWeekData.sum_time_expect)
            ).toFixed(4)}{' '}
            / {parseFloat(sphForWeekData.sum_sph_actual).toFixed(4)}
          </span>
        </div>
        <div className="number-of-product-result">
          トス件数（結果）：
          <span className="value-data">
            {sphForWeekData.sum_number_of_tosses}
          </span>
        </div>
        <div className="sph-tosses-result">
          トスSPH（結果）：
          <span className="value-data">
            {parseFloat(sphForWeekData.sum_number_of_tosses) /
              parseFloat(sphForWeekData.sum_number_of_tosses)}
          </span>
        </div>
        <div className="tosses-rate">
          トス通過率（結果）：
          <span className="value-data">
            {sphForWeekData.plan_month_number_of_order} /{' '}
            {(
              parseFloat(sphForWeekData.sum_product_actual) /
              parseFloat(sphForWeekData.sum_number_of_tosses)
            ).toFixed(4)}
            （%）
          </span>
        </div>
      </div>
    </div>
  );
}
