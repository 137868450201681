import React from 'react';

type Props = {
  [key: string]: string | number | Function;
};

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M23.2723 1.2H19.6285V0H5.05319V1.2H1.40937C0.680606 1.2 0.194763 1.68 0.194763 2.4V5.28C0.194763 8.04 2.25959 10.32 5.05319 10.68V10.8C5.05319 14.28 7.4824 17.16 10.7618 17.88L9.91162 20.4H7.11802C6.63218 20.4 6.14634 20.76 6.02488 21.24L5.05319 24H19.6285L18.6568 21.24C18.5353 20.76 18.0495 20.4 17.5636 20.4H14.77L13.9198 17.88C17.1993 17.16 19.6285 14.28 19.6285 10.8V10.68C22.4221 10.32 24.4869 8.04 24.4869 5.28V2.4C24.4869 1.68 24.0011 1.2 23.2723 1.2ZM5.05319 8.28C3.71712 7.92 2.62398 6.72 2.62398 5.28V3.6H5.05319V8.28ZM14.77 12L12.3408 10.68L9.91162 12L10.5189 9.6L8.69701 7.2H11.2477L12.3408 4.8L13.434 7.2H15.9847L14.1627 9.6L14.77 12ZM22.0577 5.28C22.0577 6.72 20.9645 8.04 19.6285 8.28V3.6H22.0577V5.28Z"
        fill="#E3A21F"
      />
    </svg>
  );
};

export default Component;
