import React, { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './displayCalendar.scss';

type PropType = {
  display: boolean;
  dateCalendar: Date;
  handleChangeDate: (_date: Date) => void;
  listHaveData: string[];
};

export default function DisplayCalendar(props: PropType) {
  const { display, dateCalendar, handleChangeDate, listHaveData } = props;
  function formatDate(date: Date) {
    return date.getDate().toString();
  }

  return (
    <div className={`displayCalendarContainer ${display ? 'block' : 'hide'}`}>
      <Calendar
        locale="ja-JP"
        view="month"
        calendarType="US"
        formatDay={(locale, date) => formatDate(date)}
        allowPartialRange={false}
        value={dateCalendar}
        onChange={(e: Date) => handleChangeDate(e)}
        tileClassName={({ date }) => {
          const dateEach = `${date.getFullYear()}/${date.getMonth() +
            1}/${date.getDate()}`;
          if (listHaveData && listHaveData.includes(dateEach)) {
            return 'akigreen';
          } else {
            return 'akiblue';
          }
        }}
      />
    </div>
  );
}
