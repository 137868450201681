import { createContext, useContext } from 'react';
import {
  CreateLoginFieldProps,
  FetchLoginFieldProps,
  LoginField,
  UpdateLoginFieldProps,
} from '../../domain/entities/field';

export type LoginFieldHooks = {
  useFetchLoginFields: () => {
    fetchFields: (payload: {
      companyId: number;
    }) => Promise<FetchLoginFieldProps[]>;
  };
  useCreateLoginField: () => {
    createLoginField: (
      payload: {
        companyId: number;
      },
      data: CreateLoginFieldProps,
    ) => Promise<LoginField>;
  };
  useUpdateLoginField: () => {
    updateLoginField: (
      payload: {
        companyId: number;
        fieldId: number;
      },
      data: UpdateLoginFieldProps,
    ) => Promise<LoginField>;
  };
  useDeleteLoginField: () => {
    deleteLoginField: (query: {
      companyId: number;
      fieldId: number;
    }) => Promise<void>;
  };
  useCheckDuplicateFieldName: () => {
    checkDuplicate: (params: {
      companyId: number;
      fieldName: string;
    }) => Promise<FetchLoginFieldProps>;
  };
};

export const LoginFieldHooksContext = createContext<LoginFieldHooks | null>(
  null,
);

export const useLoginFieldHooks = (): LoginFieldHooks => {
  const context = useContext(LoginFieldHooksContext);
  if (!context) {
    throw new Error(`Context not instantiated`);
  }
  return context;
};
