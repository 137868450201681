import React, { useState, useEffect, ChangeEvent } from 'react';
import { useMutation, useQuery } from 'react-query';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router';
import { theme } from '../../../config';
import { AxiosError } from 'axios';

import { debouncer } from '../../../utils/debouncer';
import { navigateToErrorPage } from '../../../utils/handleErrorPage';
import words from '../../../constants/words';
import { RankingFields } from '../../../domain/entities/rankings';
import { useGlobalState } from '../../../hooks/global';
import { useRankingHooks } from '../../../hooks/rankings';

import { SubHeader } from '../../atoms/Subheader';
import { EditIcon } from '../../atoms/Icons';
import { Button } from '../../atoms/Button';
import { Plus } from '../../atoms/Icons';
import { Modal } from '../../molecules/Modal';
import { Props as INotification } from '../../molecules/Notification/Notification';
import { ChangeLogs } from '../../organisms/ChangeLogs';
import { PublishPane } from '../../organisms/PublishPane';
import { Tabs } from '../../organisms/Tabs';
import { RankingColumnsTable } from '../RankingColumnsTable';
import { RankingColumn } from '../RankingColumnsTable/RankingColumnsTable';
import { TextInput } from '../../atoms/TextInput';
import { Tooltip } from '../../atoms/Tooltip';
import { AnchorTag } from '../../atoms/AnchorTag';
import { RankingLogs } from '../../../domain/entities/ranking';
import { generateRandomID } from '../../../utils/generateRandomID';

export type Props = {
  rankingId: number;
  rankingNameProp: string;
  toastNotification: (toast: INotification) => void;
  redirectedFromRankingDetails?: boolean;
};

export const MAX_CUSTOM_FIELDS = 5;

export const CloseCircle = styled.div`
  position: relative;
  width: 16px;
  height: 15px;
  margin-left: 2px;
  margin-top: 13px;
  cursor: pointer;
`;

const Wrapper = styled.div<{ $isAdding?: boolean; $logScroll?: boolean }>`
  padding: 40px 70px 0 70px;
  padding-bottom: ${(props): string =>
    props.$isAdding && !props.$logScroll ? '220px' : '100px'};
  @media ${theme.breakpoints.mobile} {
    padding: 20px;
    width: 100vw;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Header = styled.div``;

const HeaderLabel = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 36px;
  color: ${theme.colors.icon01};

  margin-bottom: 30px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
`;

const StyledButton = styled(Button)`
  width: auto;
  min-width: 110px;
  margin-left: 17px;
  height: 47px;
  display: flex;
`;

const StyledChangeLogs = styled(ChangeLogs)`
  margin-top: 0px;
`;

const StyledRankingColumnsTable = styled(RankingColumnsTable)``;

const TabsWrapper = styled.div`
  margin-right: 32.5px;
  flex: 1;
  flex-direction: column;
  min-width: 754px;
`;

const TableWrapper = styled.div`
  background: ${theme.colors.ui02};
  box-shadow: 0px 0px 8px rgba(236, 215, 192, 0.8);
  padding: 2px;
  .bx--tab-content {
    padding: 0;
  }
  .bx--tab-content:focus {
    outline: 0;
  }
  .bx--data-table {
    margin: 0;
  }
  .bx--data-table-content {
    box-shadow: none;
  }
  .bx--tabs--scrollable__nav-link {
    background-color: ${theme.colors.field01};
  }
  .bx--tabs--scrollable__nav-item--selected .bx--tabs--scrollable__nav-link {
    background-color: ${theme.colors.ui03};
  }
`;

const AddButton = styled(Button)`
  padding-right: 60px;
  width: auto;

  .bx--btn__icon {
    height: 12px !important;
    width: 12px !important;
    top: 18px;
  }
`;

const StyledTabs = styled(Tabs)`
  &&&&& {
    .bx--tabs--scrollable__nav-link:focus {
      outline: none;
      border-top: 2px solid ${theme.colors.primaryButton1};
      background-color: ${theme.colors.ui03};
    }
  }

  .bx--tabs--scrollable__nav-item--selected {
    .bx--tabs--scrollable__nav-link {
      &:hover {
        background-color: ${theme.colors.ui03};
      }
    }
  }
`;

const StyledTooltip = styled(Tooltip)`
  flex: 1;
`;

const AddFieldWrapper = styled.div<{ isDisabled: boolean }>`
  padding-top: 50px;

  ${props =>
    props.isDisabled
      ? `.field-button, .field-button:hover {
    background-color: ${theme.colors.disabledButton1} !important;
    border-color: ${theme.colors.disabledButton2} !important;
    color: ${theme.colors.disabledButton2} !important;
    cursor: not-allowed !important;
  }
  `
      : ``}
`;

const CancelLink = styled(AnchorTag)`
  div {
    color: ${theme.colors.anchorTagModal};
    @media ${theme.breakpoints.mobile} {
      padding-left: 10px;
    }
  }
`;

const UnderLabel = styled.div`
  top: -15px;
  position: relative;
  height: 0px;
  letter-spacing: 0.32px;
  color: ${theme.colors.text02};
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
`;

const Component = ({
  rankingId,
  rankingNameProp,
  toastNotification,
  redirectedFromRankingDetails = false,
}: Props): React.ReactElement => {
  const [isAdding, setIsAdding] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [isMaxFields, setIsMaxFields] = useState(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [rankingName, setRankingName] = useState<string>('');
  const [editRankingName, setEditRankingName] = useState<string>('');
  const [isEditRankingName, setIsEditRankingName] = useState<boolean>(false);
  const [isRankingNameUpdated, setIsRankingNameUpdated] = useState<boolean>(
    false,
  );
  const [isCheckingRankingName, setIsCheckingRankingName] = useState<boolean>(
    false,
  );
  const [isBasicInfoUpdated, setIsBasicInfoUpdated] = useState<boolean>(false);
  const [showMaxFieldsInfo, setShowMaxFieldsInfo] = useState<boolean>(false);
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [showDiscardChangesInfo, setShowDiscardChangesInfo] = useState<boolean>(
    false,
  );

  const [indexed, setIndexed] = useState<number | undefined>();
  const [initialFields, setInitialFields] = useState<RankingColumn[]>([]);
  const [initialCopy, setInitialCopy] = useState<RankingColumn[]>([]);
  const [newRankings, setNewRankings] = useState<RankingColumn[]>([]);
  const [newRankingsFlag, setNewRankingsFlag] = useState<RankingColumn[]>([]);
  const [duplicatedIds, setDuplicatedIds] = useState<{
    [key: number]: number;
  }>({});
  const [deletedRankingIds, setDeletedRankingIds] = useState<number[]>([]);
  const [disabledRankings, setDisabledRankings] = useState<number[]>([]);
  const [fieldRankingNameError, setRankingNameError] = useState<string>('');

  const {
    useSetRankings,
    useCheckDuplicateFieldRankingName,
    useGetRankingLogs,
  } = useRankingHooks();
  const { getRanking, updateRanking } = useSetRankings();
  const { checkDuplicateRankingName } = useCheckDuplicateFieldRankingName();
  const { fetchRankingLogs } = useGetRankingLogs();
  const {
    useCurrentUser: { currentUser },
  } = useGlobalState();
  const { pathname: locationPathname } = useLocation();
  const navigate = useNavigate();

  const basicFields: RankingColumn[] = [...initialFields, ...newRankings]
    .filter(ranking => !deletedRankingIds.includes(ranking.id))
    .map(ranking => ({
      ...ranking,
      isIndex: ranking.id === indexed,
    }));

  useEffect(() => {
    const customFields = basicFields.filter(
      ranking =>
        ranking.isDefault !== true &&
        !deletedRankingIds.includes(ranking.id) &&
        !disabledRankings.includes(ranking.id),
    );
    const isFieldsUpdated =
      JSON.stringify(initialCopy) !== JSON.stringify(basicFields);

    setIsBasicInfoUpdated(isFieldsUpdated);
    setIsMaxFields(customFields.length >= MAX_CUSTOM_FIELDS);
  }, [basicFields]);

  useEffect(() => {
    isMaxFields && setShowMaxFieldsInfo(true);
  }, [isMaxFields]);

  useEffect(() => {
    setHasChanges(!(!isBasicInfoUpdated && !isRankingNameUpdated));
  }, [isBasicInfoUpdated, isRankingNameUpdated]);

  const {
    data: ranking_details,
    refetch: refetchRankingDetails,
    isFetching,
  } = useQuery(
    ['ranking-details'],
    () => {
      if (!currentUser?.companyId) {
        throw new Error(`'company id' field not set`);
      }
      return getRanking({
        companyId: currentUser.companyId,
        rankingId: rankingId,
      });
    },
    {
      onError: (err: AxiosError) => {
        navigateToErrorPage(navigate, err.response?.status, locationPathname);
      },
    },
  );

  const {
    data: ranking_logs,
    refetch: refetchLogs,
    isFetching: isfetchingLogs,
  } = useQuery(
    ['ranking-logs'],
    () => {
      return fetchRankingLogs(rankingId);
    },
    {
      onError: (err: AxiosError) => {
        navigateToErrorPage(navigate, err.response?.status, locationPathname);
      },
    },
  );

  const { mutate: updateBasicInfo, isLoading: upDateLoading } = useMutation(
    ([id, ranking_fields_lookup]: [number, RankingFields[]]) => {
      // newly created customFields should not have id's since the DB will provide its id.
      // in this block we have to concat the newly created custom fields without the id property.
      // we only deal in this block because before this block the newly created should have a temp id to handle
      // UI functions
      const newlyAddedCustomFields = ranking_fields_lookup.filter(
        item => item.transaction_type === 'create',
      );
      const mapNewCreatedCustomFields = newlyAddedCustomFields.map(item => {
        return {
          data_type: item.data_type,
          is_basis: item.is_basis,
          is_default: item.is_default,
          is_disabled: item.is_disabled,
          is_required: item.is_required,
          name: item.name,
          ranking_id: item.ranking_id,
          transaction_type: item.transaction_type,
          member_field_lookup_id: item.member_field_lookup_id,
          order_id: item.order_id,
        };
      });
      const existingCustomFields = ranking_fields_lookup.filter(
        item => item.transaction_type !== 'create',
      );
      const mergedCustomFields = existingCustomFields.concat(
        mapNewCreatedCustomFields as RankingFields[],
      );
      return updateRanking(id, {
        name: rankingName,
        ranking_fields: mergedCustomFields,
      });
    },
    {
      onSuccess: () => {
        handleCancelModal();
        toastNotification({
          kind: 'success',
          title: words.success,
          subtitle: words.successfullyUpdated,
        });
        refetchRankingDetails();
        refetchLogs();
      },
      onError: () => {
        handleCancelModal();
        toastNotification({
          kind: 'error',
          title: words.error,
          subtitle: words.errorOccured,
        });
        refetchRankingDetails();
      },
    },
  );

  const { mutate: checkRankingName } = useMutation(
    ({
      companyId,
      rankingName,
    }: {
      companyId: number;
      rankingName: string;
    }) => {
      return checkDuplicateRankingName({ companyId, rankingName });
    },
    {
      onSuccess: () => {
        setRankingNameError(words.fieldNameAlreadyTaken);
        setIsCheckingRankingName(false);
      },
      onError: () => {
        editRankingName !== '' && setRankingNameError('');
        setIsCheckingRankingName(false);
      },
    },
  );

  useEffect(() => {
    setRankingName(rankingNameProp);
    refetchRankingDetails();
    refetchLogs();
  }, [rankingId]);

  useEffect(() => {
    if (ranking_details) {
      handleInitialValues();
    }
  }, [ranking_details]);

  const handleInitialValues = () => {
    if (ranking_details) {
      const initial_basic_field = ranking_details.ranking_fields.map(value => ({
        id: value.id,
        name: value.name,
        dataType: value.data_type,
        isDefault: !!value.is_default,
        isIndex: !!value.is_basis,
        isDisabled: !!value.is_disabled,
        isRequired: !!value.is_required,
        orderId: Number(value.order_id),
      })) as RankingColumn[];

      const index_field = initial_basic_field.filter(item => item.isIndex);

      const disabledFields = initial_basic_field
        .filter(item => item.isDisabled === true)
        .map(item => {
          return item.id;
        });

      setRankingName(ranking_details.name);
      setIndexed(index_field[0] ? index_field[0].id : undefined);
      setDisabledRankings(disabledFields);
      const sortTable =
        initial_basic_field.reduce(
          (accum, item) => (item.orderId ? accum + item.orderId : accum + 0),
          0,
        ) < 1
          ? false
          : true;
      if (sortTable) {
        setInitialFields(
          initial_basic_field.sort(
            (a, b) => a.orderId - b.orderId,
          ) as RankingColumn[],
        );
        setInitialCopy(
          initial_basic_field.sort(
            (a, b) => a.orderId - b.orderId,
          ) as RankingColumn[],
        );
      } else {
        setInitialFields(initial_basic_field as RankingColumn[]);
        setInitialCopy(initial_basic_field as RankingColumn[]);
      }

      setNewRankings([]);
      setNewRankingsFlag([]);
      setDuplicatedIds({});
      setDeletedRankingIds([]);
      setIsEditRankingName(false);
      setIsRankingNameUpdated(false);
    }
  };

  const handleCreate = (fieldParams: Omit<RankingColumn, 'id'>) => {
    setIsAdding(false);
    const tempID = generateRandomID();
    setNewRankings([
      ...newRankings,
      {
        id: tempID,
        ...fieldParams,
        isIndex: false,
        isDefault: false,
        isDisabled: false,
        isBasis: false,
        isRequired: false,
      },
    ]);
    setNewRankingsFlag([
      ...newRankingsFlag,
      {
        id: tempID,
        ...fieldParams,
        isIndex: false,
        isDefault: false,
        isDisabled: false,
        isBasis: false,
        isRequired: false,
      },
    ]);
  };

  const handleDuplicate = (fieldParams: RankingColumn) => {
    const index_dpl = initialFields.findIndex(obj => obj.id === fieldParams.id);
    const tempID = generateRandomID();
    if (index_dpl === -1) {
      const insert_new_index = newRankings.findIndex(
        obj => obj.id === fieldParams.id,
      );
      setNewRankingsFlag([
        ...newRankingsFlag.slice(0, insert_new_index + 1),
        {
          id: tempID,
          name: fieldParams.name,
          dataType: fieldParams.dataType,
          memberFieldLookupId: fieldParams.memberFieldLookupId,
          isIndex: false,
          isDefault: false,
          isDisabled: false,
          isBasis: false,
          isRequired: false,
          orderId: 0,
        },
        ...newRankings.slice(insert_new_index + 1),
      ]);
      return setNewRankings([
        ...newRankings.slice(0, insert_new_index + 1),
        {
          id: tempID,
          name: fieldParams.name,
          dataType: fieldParams.dataType,
          memberFieldLookupId: fieldParams.memberFieldLookupId,
          isIndex: false,
          isDefault: false,
          isDisabled: false,
          isBasis: false,
          isRequired: false,
          orderId: 0,
        },
        ...newRankings.slice(insert_new_index + 1),
      ]);
    }
    const duplicated_field = [
      ...initialFields.slice(0, index_dpl + 1),
      {
        id: tempID,
        name: fieldParams.name,
        dataType: fieldParams.dataType,
        memberFieldLookupId: fieldParams.memberFieldLookupId,
        isIndex: false,
        isDefault: false,
        isDisabled: false,
        isBasis: false,
        isRequired: false,
        orderId: 0,
      },
      ...initialFields.slice(index_dpl + 1),
    ];
    const checkDuplicate = Object.fromEntries(
      Object.entries(duplicatedIds).filter(
        ([key, value]) => parseInt(key) === fieldParams.id,
      ),
    );

    const dpl_id = Object.values(checkDuplicate);

    setDuplicatedIds({
      ...duplicatedIds,
      [tempID]: dpl_id[0] !== undefined ? dpl_id[0] : fieldParams.id,
    });
    setInitialFields(duplicated_field);
  };

  const handleDelete = (fieldId: number) => {
    if (
      newRankings.filter(newRanking => newRanking.id === fieldId).length !== 0
    ) {
      const tempNewRankings = [...newRankings].filter(
        newRanking => newRanking.id !== fieldId,
      );
      setNewRankings(tempNewRankings);
      setNewRankingsFlag(tempNewRankings);
    } else if (
      Object.keys(duplicatedIds).find(id => parseInt(id) === fieldId)
    ) {
      const newInitialRankings = [...initialFields].filter(
        ranking => ranking.id !== fieldId,
      );
      setInitialFields(newInitialRankings);

      const tempDuplicatedIds = duplicatedIds;
      delete tempDuplicatedIds[fieldId];
      setDuplicatedIds(tempDuplicatedIds);
    } else {
      setDeletedRankingIds([...deletedRankingIds, fieldId]);
    }
    setIsAdding(false);
    toastNotification({
      kind: 'warning',
      title: words.warning,
      subtitle: words.warningDeleteColumnDatails,
    });
  };

  const handleDisable = (fieldId: number) => {
    setDisabledRankings([...disabledRankings, fieldId]);
    updateIsDisabledStatus(fieldId, true);
  };

  const updateIsDisabledStatus = (fieldId: number, status: boolean) => {
    if (initialFields.find(obj => obj.id === fieldId)) {
      let updatedFields = initialFields.map(obj =>
        obj.id === fieldId ? { ...obj, isDisabled: status } : obj,
      );
      const indexObject = updatedFields.find(x => x.id === fieldId);
      if (!status && indexObject) {
        updatedFields = updatedFields.filter(item => item.id !== fieldId);
        if (newRankings.length > 0) {
          setNewRankings([...newRankings, indexObject]);
          setNewRankingsFlag([...newRankingsFlag, indexObject]);
        } else updatedFields = [...updatedFields, indexObject];
      }
      return setInitialFields(updatedFields);
    }
    let updatedNewFields = newRankings.map(obj =>
      obj.id === fieldId ? { ...obj, isDisabled: status } : obj,
    );
    const indexObject = updatedNewFields.find(x => x.id === fieldId);
    if (!status && indexObject) {
      updatedNewFields = updatedNewFields.filter(item => item.id !== fieldId);
      updatedNewFields = [...updatedNewFields, indexObject];
    }
    setNewRankingsFlag(updatedNewFields);
    return setNewRankings(updatedNewFields);
  };

  const handleActivate = (fieldId: number) => {
    const newList = disabledRankings.filter(rankingId => rankingId !== fieldId);
    setDisabledRankings([...newList]);
    updateIsDisabledStatus(fieldId, false);
  };

  const handleIndex = (fieldId: number) => {
    setIndexed(fieldId);
    const filteredInitialFields = initialFields.filter(
      ranking =>
        !disabledRankings.includes(ranking.id) &&
        !deletedRankingIds.includes(ranking.id),
    );
    let indexObject = filteredInitialFields.find(x => x.id === fieldId);
    if (indexObject) {
      setInitialFields([
        ...initialFields.slice(0, 3),
        indexObject,
        ...initialFields.filter(
          item => item.id !== fieldId && item.isDefault === false,
        ),
      ]);
    } else {
      indexObject = newRankings
        .filter(
          ranking =>
            !disabledRankings.includes(ranking.id) &&
            !deletedRankingIds.includes(ranking.id),
        )
        .find(x => x.id === fieldId);
      if (indexObject) {
        delete indexObject.isBasis;
        delete indexObject.memberFieldLookupId;
        setInitialFields([
          ...initialFields.slice(0, 3),
          indexObject,
          ...initialFields.filter(item => item.isDefault === false),
        ]);
        setNewRankings([...newRankings.filter(item => item.id !== fieldId)]);
      }
    }
  };

  const handleSaveBasicInfo = () => {
    setOpenModal(true);
  };

  const handleConfirmChanges = () => {
    if (openModal) {
      const basicInfo: RankingFields[] = [...initialFields, ...newRankings].map(
        (obj, index) => {
          let transaction_type:
            | 'duplicate'
            | 'create'
            | 'remove'
            | undefined = undefined;
          if (newRankingsFlag.find(e => e.id === obj.id)) {
            transaction_type = 'create';
          } else if (
            Object.keys(duplicatedIds).find(id => parseInt(id) === obj.id)
          ) {
            transaction_type = 'duplicate';
          } else if (deletedRankingIds.includes(obj.id)) {
            transaction_type = 'remove';
          }
          return {
            transaction_type: transaction_type,
            id:
              transaction_type === 'duplicate' ? duplicatedIds[obj.id] : obj.id,
            name: obj.name,
            data_type: obj.dataType,
            ranking_id: rankingId,
            is_basis: obj.id === indexed,
            is_disabled: !!obj.isDisabled,
            is_required:
              transaction_type === 'create'
                ? true
                : obj.isDisabled
                ? false
                : true,
            is_default: !!obj.isDefault,
            member_field_lookup_id:
              obj.memberFieldLookupId !== 0
                ? obj.memberFieldLookupId
                : undefined,
            order_id: index,
          };
        },
      );
      updateBasicInfo([rankingId, basicInfo]);
    } else if (isEditRankingName) {
      setRankingName(editRankingName);
    }
    setOpenModal(false);
    setIsEditRankingName(false);
    setIsRankingNameUpdated(true);
  };

  const handleRankingName = (value: string) => {
    setIsCheckingRankingName(true);
    setEditRankingName(value);

    const formattedText = removeSpaces(value);
    if (formattedText === '') {
      setIsCheckingRankingName(false);
      return setRankingNameError(words.fieldNameIsRequired);
    }
    debounceUserInput(formattedText);
  };

  const debounceUserInput = debouncer((text: string) => {
    const queryCheckDuplicate: { companyId: number; rankingName: string } = {
      companyId: currentUser ? currentUser.companyId : 0,
      rankingName: text,
    };
    if (!currentUser?.companyId) {
      throw new Error(`'company id' field not set`);
    }
    checkRankingName(queryCheckDuplicate);
  }, 500);

  const removeSpaces = (name: string) => name.trim();

  const handleCancelModal = () => {
    setEditRankingName('');
    setOpenModal(false);
    setIsEditRankingName(false);
  };

  const handleCancel = () => {
    //change redirection if coming from the Ranking Details
    if (redirectedFromRankingDetails) {
      navigate(`/ranking-details/${rankingId}`);
    } else {
      navigate('/ranking-list');
    }
  };

  const handleOnDrag = (order: any[]) => {
    const toBeAddedNew = newRankings.filter(({ id: id1 }) =>
      order.some(({ id: id2 }) => id1 === Number(id2)),
    );
    toBeAddedNew.forEach(v => {
      delete v.isBasis;
      delete v.memberFieldLookupId;
    });
    const temp = [...initialFields, ...toBeAddedNew];
    for (let i = 0; i < order.length; i++) {
      for (let l = 0; l < temp.length; l++) {
        if (Number(order[i].id) === temp[l].id) {
          temp[l].orderId = i;
          break;
        }
      }
    }
    setInitialFields([
      ...(temp.sort((a, b) => a.orderId - b.orderId) as RankingColumn[]),
    ]);
    setNewRankings([
      ...newRankings.filter(
        ({ id: id1 }) => !toBeAddedNew.some(({ id: id2 }) => id1 === id2),
      ),
    ]);
  };

  const arr = ranking_logs as RankingLogs[];
  return (
    <>
      <SubHeader title={!isFetching ? rankingName : ''}>
        <CloseCircle
          onClick={() => {
            setIsEditRankingName(true);
            setEditRankingName(rankingName);
          }}>
          <EditIcon />
        </CloseCircle>
      </SubHeader>
      <Wrapper $isAdding={isAdding} $logScroll={arr && arr.length >= 7}>
        <Row>
          <Header>
            <HeaderLabel>{words.editBasicInfo}</HeaderLabel>
          </Header>
          <ButtonsWrapper>
            <StyledTooltip
              message={words.discardChangesEditBasicInfo}
              direction="bottom"
              tooltipClassName="tooltip"
              isOpen={showDiscardChangesInfo}
              preserveText
              onChange={(ev, { open }) => {
                if (!open) {
                  setShowDiscardChangesInfo(!showDiscardChangesInfo);
                }
              }}
              triggerElement={
                <StyledButton
                  title={words.cancel}
                  onPress={() =>
                    hasChanges
                      ? setShowDiscardChangesInfo(true)
                      : handleCancel()
                  }
                  theme="light"
                />
              }>
              <div className="bx--tooltip__footer">
                <CancelLink
                  onPress={() => {
                    setShowDiscardChangesInfo(!setShowDiscardChangesInfo);
                  }}
                  title={words.cancelDiscard}
                />
                <Button
                  onPress={() => {
                    handleCancel();
                  }}
                  style={{
                    fontSize: '13px',
                    width: '117px',
                    height: '32px',
                  }}
                  title={words.proceedDiscard}
                />
              </div>
            </StyledTooltip>
            {/* <StyledButton
              title={words.cancel}
              onPress={() => handleCancel()}
              theme="light"
            /> */}
            <StyledButton
              title={words.save}
              onPress={() => handleSaveBasicInfo()}
              disabled={!hasChanges}
            />
          </ButtonsWrapper>
        </Row>
        <Row>
          <TabsWrapper>
            <TableWrapper>
              <StyledTabs
                onSelectionChange={(index: number) => {
                  if (index === 1) {
                    setIsAdding(true);
                  } else {
                    setIsAdding(false);
                  }
                  setTabIndex(index);
                }}
                tabs={[
                  {
                    id: 'revision-active',
                    label: words.activeColumns,
                    element: (
                      <StyledRankingColumnsTable
                        initialData={basicFields.filter(
                          ranking =>
                            !disabledRankings.includes(ranking.id) &&
                            !deletedRankingIds.includes(ranking.id),
                        )}
                        type="active"
                        isMaxFields={isMaxFields}
                        isAdding={isAdding}
                        isLoading={isFetching || upDateLoading}
                        fieldNames={basicFields.map(
                          (field: RankingColumn): string => {
                            return field['name'];
                          },
                        )}
                        duplicatedIds={Object.keys(duplicatedIds).map(Number)}
                        onIndex={(fieldId: number) => handleIndex(fieldId)}
                        onCreate={(fieldParams: Omit<RankingColumn, 'id'>) =>
                          handleCreate(fieldParams)
                        }
                        onCancelAdd={() => {
                          setIsAdding(false);
                        }}
                        onDuplicate={(fieldParams: RankingColumn) =>
                          handleDuplicate(fieldParams)
                        }
                        onDisable={(fieldId: number) => handleDisable(fieldId)}
                        onDrag={handleOnDrag}
                        isDraggable
                      />
                    ),
                  },
                  {
                    id: 'revision-disabled',
                    label: words.disabledColumns,
                    element: (
                      <StyledRankingColumnsTable
                        initialData={basicFields.filter(ranking =>
                          disabledRankings.includes(ranking.id),
                        )}
                        type="inactive"
                        isMaxFields={isMaxFields}
                        isLoading={isFetching || upDateLoading}
                        onActivate={(fieldId: number) => {
                          handleActivate(fieldId);
                        }}
                        onDelete={(fieldId: number) => handleDelete(fieldId)}
                        emptyDataLabelTitle={words.noDisabledColumnsTitle}
                        emptyDataLabelDetails={words.noDisabledColumnsDetials}
                      />
                    ),
                  },
                ]}
              />
            </TableWrapper>
            {!isAdding && tabIndex === 0 && (
              <AddFieldWrapper isDisabled={isMaxFields}>
                <StyledTooltip
                  message={words.didJustReachLimit}
                  direction="bottom"
                  tooltipClassName="tooltip"
                  preserveText
                  isOpen={!isAdding && showMaxFieldsInfo && !isFetching}
                  onChange={(ev, { open }) => {
                    if (!open) {
                      setShowMaxFieldsInfo(!showMaxFieldsInfo);
                    }
                  }}
                  triggerElement={
                    <AddButton
                      onPress={() => {
                        isMaxFields
                          ? setShowMaxFieldsInfo(isMaxFields)
                          : setIsAdding(true);
                      }}
                      title={words.addRankingColumn}
                      icon={Plus}
                      theme="tertiary"
                      className="field-button"
                    />
                  }
                />
              </AddFieldWrapper>
            )}
          </TabsWrapper>
          <div>
            {/* <PublishPane publishOption="now" onChangePublishOption={() => {}} /> */}
            <StyledChangeLogs
              logs={!isfetchingLogs ? (ranking_logs as RankingLogs[]) : []}
            />
          </div>
        </Row>
        <Modal
          open={openModal || isEditRankingName}
          title={
            isEditRankingName ? words.editRankingName : words.saveEditBasicInfo
          }
          label={
            isEditRankingName ? words.enterRankingName : words.confirmToSave
          }
          confirmButtonText={isEditRankingName ? words.save : words.confirm}
          onConfirm={() => handleConfirmChanges()}
          onCancel={() => handleCancelModal()}
          onClose={() => handleCancelModal()}
          preventCloseOnClickOutside={upDateLoading}
          confirmButtonDisabled={
            isCheckingRankingName ||
            upDateLoading ||
            (fieldRankingNameError !== '' && isEditRankingName) ||
            (editRankingName === '' && isEditRankingName) ||
            (editRankingName == rankingName && isEditRankingName)
          }
          cancelButtonDisabled={upDateLoading}>
          {isEditRankingName && (
            <TextInput
              value={editRankingName}
              onChange={(event: ChangeEvent) =>
                handleRankingName((event.target as HTMLInputElement).value)
              }
              label={`${words.rankingName} ${words.requiredLabel}`}
              id="editRankingName"
              type="text"
              invalid={fieldRankingNameError !== ''}
              invalidText={fieldRankingNameError}
            />
          )}
          {!fieldRankingNameError && (
            <UnderLabel>{words.rankNameUnique}</UnderLabel>
          )}
        </Modal>
      </Wrapper>
    </>
  );
};

export default Component;
