import React, { useEffect, useState } from 'react';
import './memberLogin.scss';
import LogoSelfPro from 'assets/icons/self-pro-logo.svg';
import { OrangeButton } from 'components/atoms/OrangeButton';
import words from 'constants/words';
import { memberLoginType } from 'type/member';
import { LoginForm } from 'components/templates/LoginForm';
import { validateLogin } from 'components/templates/LoginForm/validates';
import { Loading } from 'components/molecules/Loading';
import url from 'constants/urls';
import axios from 'axios';
import {
  extractError,
  getCurrentUser,
  isEmptyObject,
  setCurrentUser,
} from 'utils/common';
import { useNavigate } from 'react-router-dom';
import { gapi } from 'gapi-script';
import GoogleLogin from 'react-google-login';
import urls from 'constants/urls';
import LoginImg from 'assets/images/login-img.jpeg';

const defaultValue = {
  company_id: '',
  email: '',
  password: '',
};

export default function LoginPage() {
  const [errors, setErrors] = useState(defaultValue);
  const [isLoading, setIsLoading] = useState('none');
  const [errorResponse, setErrorResponse] = useState('');
  const [valueSignIn, setValueSignIn] = useState<memberLoginType>(defaultValue);
  const navigate = useNavigate();

  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        scope: '',
      });
    };
    gapi.load('client:auth2', initClient);
  }, []);

  async function onSubmitLogin() {
    setErrorResponse('');
    const listError = await validateLogin(valueSignIn);
    if (Object.entries(listError).length !== 0) {
      setErrors(listError);
      return;
    }
    // Handle Login
    setErrors(defaultValue);
    setIsLoading('block');
    await axios
      .post(url.member_sign_in, valueSignIn)
      .then(res => {
        if (res.data.status_code === 400) {
          const error = extractError(res.data.errors);
          setErrorResponse(error);
        }
        if (res.data.status_code === 422) {
          const error = extractError(res.data.errors);
          setErrorResponse(error);
        }
        if (res.data.status_code === 200) {
          setCurrentUser(res.data);
          setErrorResponse('');
          navigate('/');
        }
        setIsLoading('none');
      })
      .catch(err => {
        const error = extractError(err.response.data.errors);
        setErrorResponse(error);
        setIsLoading('none');
      });
  }

  function handleUpdateValues(data: memberLoginType) {
    setValueSignIn(data);
  }

  async function responseGoogle(response: any) {
    const valueSns = {
      full_name: response.profileObj.name,
      sns_id: response.googleId,
      email: response.profileObj.email,
    };
    setIsLoading('block');
    setErrorResponse('');
    await axios
      .post(urls.sns_member_sign_in, valueSns)
      .then(res => {
        const user = getCurrentUser();
        if (res.data.status_code === 400) {
          const error = extractError(res.data.errors);
          setErrorResponse(error);
          setIsLoading('none');
          return;
        }
        setIsLoading('none');
        if (isEmptyObject(user)) {
          setCurrentUser(res.data);
          navigate('/');
        }
      })
      .catch(err => {
        if (err.response.data.errors) {
          const errorSv = extractError(err.response.data.errors);
          setErrorResponse(errorSv);
        }
        setIsLoading('none');
      });
  }

  return (
    <div className="login-pages">
      <Loading display={isLoading} />
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-sm-6 div-left">
            <div className="self-pro-logo">
              <img src={LogoSelfPro} />
            </div>
            <div className="sign-up-title">
              <p>{words.memberSignInTitle}</p>
            </div>
            <LoginForm
              handleUpdateValues={data => handleUpdateValues(data)}
              listError={errors}
            />
            <p
              className="errorFromServer"
              dangerouslySetInnerHTML={{ __html: errorResponse }}></p>
            <OrangeButton
              text={words.memberSignInTitle}
              onClick={onSubmitLogin}
            />
            <div className="go-to-login">
              {words.memberForgotPassword}
              <a href={url.forgot}> {words.here}</a>
            </div>
            <div className="fieldset-line">
              {words.memberSignUpAnotherMethod}
            </div>
            <div className="buttonGoogleBox">
              <GoogleLogin
                clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}
                buttonText={words.googleButtonSignIn}
                onSuccess={responseGoogle}
                cookiePolicy={'single_host_origin'}
                className="buttonGoogle"
              />
            </div>
            <div className="go-to-login">
              {words.memberHaventAccount}
              <a href={url.signUp}> {words.registerHere}</a>
            </div>
          </div>
          <div className="col-12 col-sm-6 image-right">
            <img src={LoginImg} alt="Login Img" className="img-fluid" />
          </div>
        </div>
      </div>
    </div>
  );
}
