import React, { useCallback, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGlobalState } from '../../../hooks/global';
import { useRankingHooks } from '../../../hooks/rankings';
import { Props as INotification } from '../../molecules/Notification/Notification';
import { TIMEOUT } from '../../../constants/timeout';
import {
  StyledEditIcon,
  StyledSkeletonText,
} from '../EditRankingMember/EditRankingMember';
import words, { languageUsed } from '../../../constants/words';
import ToastNotification from '../../molecules/Notification/ToastNotification';
import { SetRankingModal } from '../../templates/SetRankingModal';
import { SubHeader } from '../../atoms/Subheader';
import { AddPeriod } from '../../organisms/AddPeriod';
import { IAddPeriodProps } from '../../organisms/AddPeriod/AddPeriod';
import { Formik } from 'formik';
import {
  ButtonsDiv,
  HeaderText,
  StyledHeaderButton,
  TitleHeaderDiv,
} from '../../templates/MemberRankingList/elements';
import styled, { createGlobalStyle } from 'styled-components';
import { RankingPeriod } from '../../../domain/entities/rankings';
import { Tooltip } from '../../atoms/Tooltip';
import { AnchorTag } from '../../atoms/AnchorTag';
import { theme } from '../../../config';
import { Button } from '../../atoms/Button';
import { TooltipOnChangeEvent } from 'carbon-components-react';
import { AxiosError } from 'axios';
import { navigateToErrorPage } from '../../../utils/handleErrorPage';

export const GlobalStyle = createGlobalStyle`
  .tooltip {
    &&&&& {
      min-width: 256px;
      max-width: 256px;
    }
  }
`;

export const StyledEditRankingDiv = styled.div`
  && {
    .bx--data-table-content {
      padding-left: 82px;
      padding-right: 55px;

      tbody tr td:nth-child(3),
      tbody tr td:nth-child(4),
      thead tr th:nth-child(3),
      thead tr th:nth-child(4) {
        text-align: initial;
        padding: 0 10px;
      }
    }
    .addPeriodBtn {
      padding-left: 85px;
    }
  }
`;

const StyledTooltip = styled(Tooltip)``;

const StyledAnchorTag = styled(AnchorTag)`
  &&&& {
    div {
      color: ${theme.colors.anchorTagModal};
    }
  }
`;

const ActionButtons = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1.5em;
`;

type IRankingPeriodData = {
  rankingID: number;
  periods: RankingPeriod[];
};

const initialValues = {
  id: 0,
  rankingPeriodName: '',
  startDate: new Date(),
  endDate: new Date(),
  visibility: false,
  disabled: false,
} as IAddPeriodProps;

type Props = {};

const Component = ({}: Props): React.ReactElement => {
  const { pathname: locationPathname, search: locationSearch } = useLocation();
  const isRedirected =
    new URLSearchParams(locationSearch).get('isRedirected') === 'true';
  const { id: rankingId } = useParams();
  const {
    useCurrentUser: { currentUser },
  } = useGlobalState();
  const { useSetRankings } = useRankingHooks();

  const { getRanking, updateRanking } = useSetRankings();
  const navigate = useNavigate();

  const [notification, setNotification] = useState<INotification | null>(null);
  const [rankingName, setRankingName] = useState<string>('');
  const [initialRankingName, setInitialRankingName] = useState<string>('');
  const [isEditingRankingName, setIsEditingRankingName] = useState(false);
  const [rankingPeriodData, setRankingPeriodData] = useState<IAddPeriodProps[]>(
    [initialValues],
  );
  const [initialRankingPeriodData, setInitialRankingPeriodData] = useState<
    IAddPeriodProps[]
  >([initialValues]);
  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);
  const [hasChanges, setHasChanges] = useState<boolean>(false);

  useEffect(() => {
    const isFieldsUpdated =
      JSON.stringify(initialRankingPeriodData) !==
      JSON.stringify(rankingPeriodData);

    setHasChanges(isFieldsUpdated);
  }, [rankingPeriodData]);

  useEffect(() => {
    setHasChanges(initialRankingName !== rankingName);
  }, [rankingName]);

  const toastNotification = useCallback((toast: INotification) => {
    setNotification({
      ...toast,
      timeout: TIMEOUT,
      handleClose: () => {
        setNotification(null);
        return false;
      },
    });
  }, []);

  const { mutate: updateRankingMembers } = useMutation(
    ({ rankingID, periods }: IRankingPeriodData) => {
      return updateRanking(rankingID, {
        name: rankingName,
        periods: periods,
        company_id: currentUser?.companyId,
      });
    },
    {
      onSuccess: () => {
        setHasChanges(false);
        setInitialRankingPeriodData(rankingPeriodData);
        setInitialRankingName(rankingName);
        toastNotification({
          kind: 'success',
          title: words.notificationTitlePeriod,
          subtitle: words.successfullyUpdatedRankingPeriods,
        });
      },
      onError: () =>
        toastNotification({
          kind: 'error',
          title: words.error,
          subtitle: words.errorOccured,
        }),
    },
  );

  const { isFetching: isLoadingPeriodTable } = useQuery(
    [],
    async () => {
      if (!currentUser?.companyId) {
        throw new Error(`'company id' field not set`);
      }

      const response = await getRanking({
        companyId: currentUser.companyId,
        rankingId: rankingId ? +rankingId : 0,
        isActive: true,
      });

      const rankingPeriod: IAddPeriodProps[] = response.periods.map(item => {
        return {
          id: item.id as number,
          rankingPeriodName: item.name,
          disabled: false,
          endDate: item.end_date,
          startDate: item.start_date,
          visibility: item.is_visible,
        };
      });
      setRankingPeriodData(rankingPeriod);
      setInitialRankingPeriodData(rankingPeriod);
      setRankingName(response.name);
      setInitialRankingName(response.name);
      setHasChanges(false);
      return { rankingData: rankingPeriod };
    },
    {
      onError: (err: AxiosError) => {
        navigateToErrorPage(navigate, err.response?.status, locationPathname);
      },
    },
  );

  const handleSubmitPeriod = (newData: IAddPeriodProps) => {
    const newDataArr = rankingPeriodData.filter(item => item.id === newData.id);
    const rankingPeriodDataArr = [...rankingPeriodData];
    if (newDataArr.length > 0) {
      for (const item of rankingPeriodDataArr) {
        if (item.id === newData.id) {
          const index = rankingPeriodDataArr.findIndex(object => {
            return object.id === newData.id;
          });
          rankingPeriodDataArr[index] = newData;
        }
      }
      setRankingPeriodData(rankingPeriodDataArr);
    } else {
      setRankingPeriodData([...rankingPeriodData, newData]);
    }
  };

  //change redirection if coming from the Ranking Details
  const onClickCancel = () => {
    if (isRedirected) {
      navigate(`/ranking-details/${rankingId}`);
    } else {
      navigate(`/ranking-list`);
    }
  };

  const onClickSave = () => {
    const rankingPeriodArr: RankingPeriod[] = rankingPeriodData.map(item => {
      return {
        name: item.rankingPeriodName,
        end_date: item.endDate,
        start_date: item.startDate,
        is_visible: item.visibility,
        id: item.id,
      };
    });

    updateRankingMembers({
      rankingID: parseInt(rankingId as string),
      periods: rankingPeriodArr,
    });
  };

  const onHandleDelete = (id: number | null) => {
    setRankingPeriodData(rankingPeriodData.filter(item => item.id !== id));
  };

  return (
    <StyledEditRankingDiv className="editRankingPage">
      <GlobalStyle />
      <SubHeader
        title={
          <div>
            {!isLoadingPeriodTable ? rankingName : ''}
            {!isLoadingPeriodTable ? (
              <StyledEditIcon
                onClick={() => {
                  setIsEditingRankingName(true);
                }}
              />
            ) : (
              <StyledSkeletonText width="" />
            )}
          </div>
        }
      />
      <TitleHeaderDiv>
        <HeaderText>{words.editRankingPeriod}</HeaderText>
        <ActionButtons>
          <StyledTooltip
            message={words.cancelEditPeriodTooltip}
            direction="bottom"
            tooltipClassName="tooltip"
            onChange={(ev: TooltipOnChangeEvent<HTMLDivElement>, { open }) => {
              if (!open) {
                setIsTooltipOpen(open);
              }
            }}
            isOpen={isTooltipOpen}
            triggerElement={
              <ButtonsDiv>
                <StyledHeaderButton
                  onPress={() => {
                    if (hasChanges) {
                      setIsTooltipOpen(true);
                    } else {
                      onClickCancel();
                    }
                  }}
                  theme="light"
                  title={words.cancel}
                />
              </ButtonsDiv>
            }>
            <div className="bx--tooltip__footer">
              <StyledAnchorTag
                onPress={() => setIsTooltipOpen(false)}
                title={words.cancel}
              />
              <Button
                onPress={onClickCancel}
                style={{
                  fontSize: languageUsed === 'en' ? '16px' : '13px',
                  width: languageUsed === 'en' ? 'auto' : '117px',
                  height: languageUsed === 'en' ? 'auto' : '32px',
                }}
                title={words.proceedCancelEditPeriod}
              />
            </div>
          </StyledTooltip>
          <StyledHeaderButton
            onPress={() => onClickSave()}
            theme="primary"
            title={words.saveRankingMember}
            disabled={!hasChanges}
          />
        </ActionButtons>
      </TitleHeaderDiv>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={handleSubmitPeriod}>
        <AddPeriod
          data={rankingPeriodData}
          onDeleteRanking={id => onHandleDelete(id)}
          onToggledSwitch={() => setHasChanges(true)}
          isLoading={isLoadingPeriodTable}
        />
      </Formik>
      {notification ? <ToastNotification notification={notification} /> : null}
      <SetRankingModal
        isOpen={isEditingRankingName}
        isEdit
        onSubmit={ranking => {
          setRankingName(ranking.rankingName);
          setIsEditingRankingName(false);
        }}
        onClose={() => {
          setIsEditingRankingName(false);
        }}
        initialValues={{ rankingName }}
      />
    </StyledEditRankingDiv>
  );
};

export default Component;
