import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

import { ChevronLeft24 } from '@carbon/icons-react';
import { Link } from 'carbon-components-react';

import { theme } from '../../../config';

import { Tooltip } from '../Tooltip';
import { Button } from '../Button';

const SubHeader = styled.div`
  position: relative;
  width: 100%;
  background-color: ${theme.colors.ui03};

  display: flex;
  align-items: center;

  padding: 26px 78px;

  @media ${theme.breakpoints.mobile} {
    padding: 15px 21px;
    height: auto;

    &.subheader-sticky {
      z-index: 901;
      position: fixed;
      top: 0;
    }
  }
`;

const StickySubHeaderSpace = styled.div<{ $height?: number }>`
  display: none;

  @media ${theme.breakpoints.mobile} {
    display: block;
    height: ${({ $height }) => $height}px;
  }
`;

const BackButtonContainer = styled.div`
  display: none;
  margin-right: 11px;

  @media ${theme.breakpoints.mobile} {
    display: flex;
  }
`;

const StyledChevronLeft = styled(ChevronLeft24)`
  cursor: pointer;
`;

const BackTooltip = styled(Tooltip)``;

const StyledLink = styled(Link)`
  &:hover {
    cursor: pointer;
  }
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  color: ${theme.colors.text01};

  @media ${theme.breakpoints.mobile} {
    font-size: 20px;
    line-height: 26px;
  }
`;

export type Props = {
  title: string | React.ReactElement;
  children?: React.ReactElement;
  onBack?: () => void;
  isStickyOnMobile?: boolean;
  hasBackOnMobile?: boolean;
  hasBackOnMobileTooltip?: boolean;
  backTooltipMessage?: string;
  backTooltipCancelText?: string;
  backTooltipConfirmText?: string;
};

const Component = ({
  title = '',
  children,
  onBack = () => {},
  isStickyOnMobile,
  hasBackOnMobile,
  hasBackOnMobileTooltip,
  backTooltipMessage,
  backTooltipCancelText,
  backTooltipConfirmText,
}: Props): React.ReactElement => {
  const [isSubHeaderSticky, setIsSubHeaderSticky] = useState(false);
  const [subHeaderHeight, setSubHeaderHeight] = useState<number | undefined>(
    undefined,
  );
  const [isBackTooltipOpen, setIsBackTooltipOpen] = useState(false);

  const subHeaderRef = useRef<HTMLDivElement>(null);

  const handleWindowScroll = () => {
    const scrollY = window.scrollY;
    if (scrollY > 49) setIsSubHeaderSticky(true);
    else setIsSubHeaderSticky(false);
    if (isBackTooltipOpen) setIsBackTooltipOpen(false);
  };

  const handleOnBack = (e: React.MouseEvent<React.ReactSVGElement>) => {
    e.stopPropagation();
    if (hasBackOnMobileTooltip) setIsBackTooltipOpen(true);
    else onBack();
  };

  useEffect(() => {
    if (isStickyOnMobile)
      setSubHeaderHeight(subHeaderRef.current?.offsetHeight);
  }, []);

  useEffect(() => {
    if (isStickyOnMobile) {
      window.addEventListener('scroll', handleWindowScroll, { passive: true });
      return () => window.addEventListener('scroll', handleWindowScroll);
    }
  }, [isBackTooltipOpen]);

  return (
    <>
      <SubHeader
        className={isSubHeaderSticky ? 'subheader-sticky' : undefined}
        ref={subHeaderRef}>
        {hasBackOnMobile && (
          <BackButtonContainer>
            <BackTooltip
              tooltipClassName="subheader-back-tooltip"
              message={backTooltipMessage}
              isOpen={isBackTooltipOpen}
              onChange={(ev, { open }) => {
                if (!open) {
                  setIsBackTooltipOpen(false);
                }
              }}
              triggerElement={<StyledChevronLeft onClick={handleOnBack} />}
              align="start">
              <div className="bx--tooltip__footer">
                <StyledLink onClick={() => setIsBackTooltipOpen(false)}>
                  {backTooltipCancelText}
                </StyledLink>
                <Button
                  style={{ fontSize: '14px', lineHeight: '16px' }}
                  title={backTooltipConfirmText}
                  onPress={onBack}
                />
              </div>
            </BackTooltip>
          </BackButtonContainer>
        )}
        <Title>{title}</Title>
        {children}
      </SubHeader>
      {isSubHeaderSticky && <StickySubHeaderSpace $height={subHeaderHeight} />}
    </>
  );
};

export default Component;
