import React, { useCallback, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Grid } from 'carbon-components-react';
import { useNavigate } from 'react-router';

import * as Elements from './elements';
import words from '../../../constants/words';

import { TextInput } from '../../atoms/TextInput';
import { Banner } from '../../molecules/Banner';
import { FileInput } from '../../molecules/FileInput';
import BannerImage from '../../../assets/images/team-handshake.jpg';
import LogoImage from '../../../assets/images/ever-contest.png';

import { useGlobalState } from '../../../hooks/global';
import { Button } from '../../atoms/Button';
import { useAuthHooks } from '../../../hooks/auth';
import { useMemberHooks } from '../../../hooks/member';
import { useMutation } from 'react-query';
import { CreateMemberField, UserRoles } from '../../../domain/entities/member';
import {
  FILE_SIZE_LIMIT,
  SUPPORTED_FILE_FORMATS,
} from '../../../constants/profile';
import { debouncer } from '../../../utils/debouncer';
import { ToastNotification } from '../../molecules/Notification';
import { Props as INotification } from '../../molecules/Notification/Notification';
import { TIMEOUT } from '../../../constants/timeout';

type ResponseVal = {
  password: string;
  emailAddress: string;
  error: boolean;
  msg: string;
  linkExpired: boolean;
};

type Props = {
  setResetPasswordModal: Function;
};

const Component = ({ setResetPasswordModal }: Props): React.ReactElement => {
  const navigate = useNavigate();
  const { useLogout, useSignUp } = useAuthHooks();
  const { logout } = useLogout();
  const { useCreateMemberFields } = useMemberHooks();
  const { createMemberFields } = useCreateMemberFields();
  const {
    useCurrentUser: { currentUser, setCurrentUser },
  } = useGlobalState();
  const { verifyEmail } = useSignUp();
  const companyId = currentUser ? currentUser.companyId : 0;
  const [emailError, setEmailError] = useState('');
  const [notification, setNotification] = useState<INotification | null>(null);

  const fields = currentUser?.emptyCustomFields;

  const { mutate: createMemberFieldValues, isLoading } = useMutation(
    (data: CreateMemberField[]) =>
      createMemberFields(
        companyId,
        data,
        currentUser ? currentUser.memberId : 0,
      ),
    {
      onSuccess: () => {
        if (currentUser) {
          const newUser = {
            companyId: currentUser?.companyId,
            userId: currentUser?.userId,
            memberId: currentUser?.memberId,
            name: currentUser?.name,
            access_token: currentUser?.access_token,
            refresh_token: currentUser?.refresh_token,
            isLoggedIn: true,
            roleName: currentUser?.roleName as UserRoles,
          };
          navigate('/');
          setCurrentUser(newUser);
        }
      },
      onError: () => {
        setNotification({
          kind: 'error',
          title: words.error,
          subtitle: words.errorOccured,
          timeout: TIMEOUT,
          handleClose: () => {
            setNotification(null);
            return false;
          },
        });
      },
    },
  );

  const { mutate: verifyEmailMutation } = useMutation(
    ([user_id, email]: [string, string]) => {
      return verifyEmail(user_id, email);
    },
  );

  const checkEmail = useCallback((member_id: number, email: string): void => {
    verifyEmailMutation([`${member_id}`, email], {
      onSuccess: response => {
        const res = response as ResponseVal;
        setEmailError(res.error ? res.msg : '');
      },
      onError: () => {
        setEmailError('');
      },
    });
  }, []);

  const customFieldsValidation = () => {
    return fields
      ?.filter(field => field.field_name !== words.profileSetting)
      .reduce((acc, cur) => {
        if (cur.field_name === words.emailSetting) {
          return {
            ...acc,
            [cur.field_lookup_id]: Yup.string().email(
              `${words.validEmailAddress}`,
            ),
          };
        } else {
          return {
            ...acc,
            [cur.field_lookup_id]: cur.required
              ? Yup.string().required(words.customFieldRequired)
              : Yup.string(),
          };
        }
      }, {});
  };

  const getValidation = () => {
    const photoField = fields
      ?.filter(field => field.is_default)
      .filter(field => field.field_name === words.profileSetting);

    return photoField && photoField?.length > 0
      ? Yup.object().shape({
          [photoField[0].field_lookup_id]: Yup.mixed()
            .test('fileSize', words.fileErrorMsg, value =>
              value && value.length > 0
                ? value[0].size <= FILE_SIZE_LIMIT
                : true,
            )
            .test('fileType', words.unsupportedFileFormat, value =>
              value && value.length > 0
                ? SUPPORTED_FILE_FORMATS.includes(value[0].type)
                : true,
            ),
          ...customFieldsValidation(),
        })
      : Yup.object().shape({
          ...customFieldsValidation(),
        });
  };

  const onPressSave = (values: any) => {
    if (currentUser) {
      const fieldsObjByID = fields
        ? fields.reduce((newObj: { [key: string]: any }, item: any) => {
            newObj[item.field_lookup_id] = item;
            return newObj;
          }, {})
        : {};
      const customValueKeys = Object.keys(values);

      const customFieldValues = customValueKeys.map(lookupId => {
        const isEmail =
          fieldsObjByID[lookupId].is_default &&
          fieldsObjByID[lookupId].field_name === words.emailSetting;
        if (isEmail) {
          return {
            member_id: currentUser.memberId,
            email: values[lookupId] || '',
            field_lookup_id: Number(lookupId),
            value_char: '',
          };
        }

        const isProfileImage =
          fieldsObjByID[lookupId].is_default &&
          fieldsObjByID[lookupId].field_name === words.profileSetting;
        if (isProfileImage) {
          const file = values[lookupId][0];
          return {
            member_id: currentUser.memberId,
            photo: file,
            field_lookup_id: Number(lookupId),
            value_char: '',
          };
        }

        return {
          member_id: currentUser.memberId,
          value_char: values[lookupId],
          field_lookup_id: Number(lookupId),
        };
      });
      createMemberFieldValues(customFieldValues);
    }
  };

  const debounceEmailInput = debouncer((text: string) => {
    if (currentUser && text !== '') checkEmail(currentUser?.memberId, text);
  }, 700);

  const renderInput = (
    type: 'email' | 'photo' | 'default',
    values: any,
    handleChange: Function,
    label: string,
    error: string,
    index: number,
    lookupId: number,
    setFieldValue: Function,
  ) => {
    if (type === 'email') {
      return (
        <Elements.InputWrapper>
          <TextInput
            id={'email'}
            onChange={event => {
              setEmailError('');
              setFieldValue(
                `${lookupId}`,
                (event.target as HTMLTextAreaElement).value,
              );
              debounceEmailInput((event.target as HTMLTextAreaElement).value);
            }}
            label={label}
            value={values[lookupId]}
            invalid={!!error || !!emailError}
            invalidText={error || emailError}
          />
        </Elements.InputWrapper>
      );
    }
    if (type === 'photo') {
      return (
        <Elements.FileInputWraper>
          <FileInput
            id={'photo'}
            photo={values[lookupId]}
            label={label}
            description={words.fileInputLabel}
            onAttachFile={(file: File[], remove?: boolean) => {
              setFieldValue(`${lookupId}`, remove ? undefined : file);
            }}
            errorMessage={error}
            fileInputDesc={words.fileInputDesc}
            multiple
            isLoading={isLoading}
          />
        </Elements.FileInputWraper>
      );
    }
    return (
      <Elements.InputWrapper>
        <TextInput
          value={values[lookupId]}
          onChange={handleChange(`${lookupId}`)}
          label={label}
          id="companyId"
          key={index}
        />
      </Elements.InputWrapper>
    );
  };

  return (
    <Elements.Container>
      <Elements.Column>
        <Grid>
          <Elements.TextHeader>
            <Elements.Logo imageUrl={LogoImage} />
            <Elements.LoginTitle>
              {words.additionalInfoRequired}
            </Elements.LoginTitle>
            <Elements.Subtitle>
              {words.additionalInfoSubtitle}
            </Elements.Subtitle>
          </Elements.TextHeader>
          <Formik
            initialValues={{}}
            onSubmit={values => onPressSave(values)}
            validationSchema={getValidation()}>
            {({
              handleChange,
              values,
              errors,
              handleSubmit,
              setFieldValue,
            }): React.ReactElement => {
              const requiredFields =
                fields && fields.filter(field => field.required);
              const hasEmptyRequiredFields = requiredFields?.some(
                field => !(values as any)[`${field.field_lookup_id}`],
              );

              return (
                <>
                  {fields?.map((item, index) => {
                    const error = ((errors as unknown) as {
                      [key: string]: string;
                    })[`${item.field_lookup_id}`];
                    const label = `${item.field_name} ${
                      item.required ? words.requiredLabel : words.optional
                    }`;
                    const type = item.is_default
                      ? item.field_name === words.emailSetting
                        ? 'email'
                        : 'photo'
                      : 'default';
                    return renderInput(
                      type,
                      values,
                      handleChange,
                      label,
                      error,
                      index,
                      item.field_lookup_id,
                      setFieldValue,
                    );
                  })}

                  <Elements.BottomWrapper>
                    <Elements.LogoutButton
                      onPress={() => {
                        setResetPasswordModal(false);
                        logout(navigate);
                      }}
                      title={words.logout}
                    />
                    <Elements.ButtonWrapper>
                      <Button
                        onPress={() => handleSubmit()}
                        title={words.save}
                        disabled={
                          Object.keys(errors).length > 0 ||
                          Boolean(emailError) ||
                          hasEmptyRequiredFields
                        }
                      />
                    </Elements.ButtonWrapper>
                  </Elements.BottomWrapper>
                </>
              );
            }}
          </Formik>
        </Grid>
      </Elements.Column>
      <Elements.Column isBanner>
        <Banner imageUrl={BannerImage} label={words.bannerLabel} />
      </Elements.Column>
      {notification ? <ToastNotification notification={notification} /> : null}
    </Elements.Container>
  );
};

export default Component;
