import * as Yup from 'yup';
import {
  FILE_SIZE_LIMIT,
  SUPPORTED_FILE_FORMATS,
} from '../../../constants/profile';
import words from '../../../constants/words';

export const AdminFirstLoginSchema = (
  otherFields: { isRequired: boolean }[],
): Object => {
  const customFieldsValidation = otherFields?.reduce((acc: any, cur: any) => {
    return {
      ...acc,
      [cur.identifier]: cur.isRequired ? Yup.string().required() : Yup.string(),
    };
  }, {});
  return Yup.object().shape({
    password: Yup.string().min(6, words.passwordMin),
    password2: Yup.string().oneOf(
      [Yup.ref('password')],
      words.passwordMismatch,
    ),
    email: Yup.string().email(`${words.validEmailAddress}`),
    photo: Yup.mixed()
      .test('fileSize', words.fileErrorMsg, value =>
        value && value.length > 0 ? value[0].size <= FILE_SIZE_LIMIT : true,
      )
      .test('fileType', words.unsupportedFileFormat, value =>
        value && value.length > 0
          ? SUPPORTED_FILE_FORMATS.includes(value[0].type)
          : true,
      ),
    ...customFieldsValidation,
  });
};
