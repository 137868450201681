import NotificationRepository from '../../ports/NotificationRepository';
import {
  addNotificationUseCase,
  buildAddNotification,
} from './add-notification';
import {
  buildCheckNotificationCount,
  checkNotificationCountUseCase,
} from './check-notification-count';
import {
  buildFetchNotifications,
  fetchNotificationsUseCase,
} from './fetch-notifications';
import {
  buildUpdateNotification,
  updateNotificationUseCase,
} from './update-notification';

export default (dependencies: {
  notifRepo: NotificationRepository;
}): {
  fetchNotifications: fetchNotificationsUseCase;
  addNotification: addNotificationUseCase;
  updateNotification: updateNotificationUseCase;
  checkNotificationCount: checkNotificationCountUseCase;
} => {
  const { notifRepo } = dependencies;
  const fetchNotifications = buildFetchNotifications({ notifRepo });
  const addNotification = buildAddNotification({ notifRepo });
  const updateNotification = buildUpdateNotification({ notifRepo });
  const checkNotificationCount = buildCheckNotificationCount({ notifRepo });

  return {
    fetchNotifications,
    addNotification,
    updateNotification,
    checkNotificationCount,
  };
};
