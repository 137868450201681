import React, { useCallback } from 'react';
import { Dropdown } from '../../molecules/Dropdown';
import { Group } from '../../../domain/entities/group';
import words from '../../../constants/words';
import { theme } from '../../../config';
import { MoveMemberToGroupFormType } from './types';

export type Props = MoveMemberToGroupFormType & {
  groupList: Group[];
  onChangeMemberGroup: (value: number) => void;
};

const Component = ({
  memberGroupId,
  groupList,
  onChangeMemberGroup,
}: Props): React.ReactElement => {
  const dropdownStyles = {
    width: '100%',
  };

  const handleChangeMemberGroup = useCallback(
    ({ selectedItem }: { selectedItem: { id: number } }) => {
      onChangeMemberGroup(selectedItem.id);
    },
    [],
  );

  return (
    <Dropdown
      id="member-group"
      label=""
      titleText={words.group}
      styles={dropdownStyles}
      items={groupList}
      onChange={handleChangeMemberGroup}
      backgroundColor={theme.colors.white}
      dropdownProps={{
        selectedItem: groupList.find(e => e.id === memberGroupId) || {},
        itemToString: (item: Group) => item.name,
      }}
    />
  );
};

export default Component;
