import { createContext, useContext } from 'react';

export type CurrentUser = {
  userId: number;
  companyId: number;
  memberId: number;
  access_token?: string;
  name?: string;
  refresh_token?: string;
  isLoggedIn?: boolean;
  emptyCustomFields?: {
    field_lookup_id: number;
    field_name: string;
    required: boolean;
    is_default: boolean;
  }[];
  roleName: 'flatadmin' | 'admin' | 'approver' | 'member' | '';
};

export type GlobalState = {
  useCurrentUser: {
    currentUser: CurrentUser | undefined;
    setCurrentUser: (user?: CurrentUser) => void;
  };
};

export const GlobalStateContext = createContext<GlobalState | null>(null);

export const useGlobalState = (): GlobalState => {
  const context = useContext(GlobalStateContext);
  if (!context) {
    throw new Error(`Context not instantiated`);
  }
  return context;
};
