import * as Yup from 'yup';
import words from '../../../constants/words';

export const LoginSchema = Yup.object().shape({
  companyId: Yup.string()
    .matches(/^[0-9]*$/, `${words.invalidCompanyIdFormat}`)
    .min(3, `${words.invalidCompanyIdFormat}`)
    .max(3, `${words.invalidCompanyIdFormat}`),
  userId: Yup.string()
    .matches(/^[0-9]*$/, `${words.invalidUserIdFormat}`)
    .min(4, `${words.invalidCompanyIdFormat}`)
    .max(4, `${words.invalidCompanyIdFormat}`),
  password: Yup.string()
    .matches(/^[^\uFF01-\uFF5E]*$/, `${words.passwordMin}`)
    .min(6, `${words.passwordMin}`),
});

export const GroupModalSchema = Yup.object().shape({
  name: Yup.string().required(),
  approvers: Yup.array()
    .min(1)
    .required(),
});
