/* eslint-disable @typescript-eslint/explicit-function-return-type */
import styled from 'styled-components';
import {
  DataTableSkeleton,
  TableSelectAll,
  TableSelectRow,
  TableHeader,
  TableRow,
  TableCell,
  Pagination,
  TableToolbarContent,
  TableBatchActions,
} from 'carbon-components-react';
import themes from '../../../config/themes';
import { Checkbox } from '../../atoms/Checkbox';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  ${'.bx--select__item-count'} {
    border-right: thin solid ${themes.main.colors.ui03};
  }
  ${'.bx--select-input'} {
    background-color: ${themes.main.colors.ui01};
  }
  ${'.bx--select__page-number'} {
    border-left: thin solid ${themes.main.colors.ui03};
  }
  ${'.bx--btn.bx--btn--icon-only.bx--tooltip__trigger'} {
    border-left: thin solid ${themes.main.colors.ui03};
  }
  && {
    td {
      background-color: ${themes.main.colors.ui01};
    }
    .bx--data-table--selected td {
      background-color: ${themes.main.colors.disabledButton1};
    }
    tr {
      :hover td {
        background-color: ${themes.main.colors.hoverui};
      }
    }
    tr.bx--data-table--selected {
      :hover td {
        background-color: ${themes.main.colors.disabledButton1};
      }
    }
  }
`;

export const NPContainer = styled.div`
  width: 100%;
  height: 100%;
  ${'.bx--select__item-count'} {
    border-right: thin solid ${themes.main.colors.ui03};
  }
  ${'.bx--select-input'} {
    background-color: ${themes.main.colors.ui01};
  }
  ${'.bx--select__page-number'} {
    border-left: thin solid ${themes.main.colors.ui03};
  }
  ${'.bx--btn.bx--btn--icon-only.bx--tooltip__trigger'} {
    border-left: thin solid ${themes.main.colors.ui03};
  }
  && {
    td {
      background-color: ${themes.main.colors.ui01};
    }
    .bx--data-table--selected td {
      background-color: ${themes.main.colors.disabledButton1};
    }
    tbody tr td:nth-child(2) {
      width: 200px;
    }
    tbody tr td:nth-child(3) {
      width: 400px;
    }
    tbody tr td:nth-child(4) {
      width: 300px;
    }
    tbody tr td:nth-child(5) {
      width: 100px;
    }
  }
`;

export const NotifTableContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 2px;
  background: ${themes.main.colors.white};
  box-shadow: 0px 0px 8px rgba(236, 215, 192, 0.8);
  ${'.bx--select__item-count'} {
    border-right: thin solid ${themes.main.colors.ui03};
  }
  ${'.bx--select-input'} {
    background-color: ${themes.main.colors.ui01};
  }
  ${'.bx--select__page-number'} {
    border-left: thin solid ${themes.main.colors.ui03};
  }
  ${'.bx--btn.bx--btn--icon-only.bx--tooltip__trigger'} {
    border-left: thin solid ${themes.main.colors.ui03};
  }
  && {
    .bx--data-table--selected td {
      background-color: ${themes.main.colors.disabledButton1};
    }
    tbody > div {
      overflow: hidden;
      background-color: ${themes.main.colors.white};
    }
    tr {
      :hover td {
        background-color: ${themes.main.colors.hoverui};
      }
    }
    tbody tr td:nth-child(1) {
      width: 2%;
    }
    tbody tr td:nth-child(3) {
      width: 180px;
    }
    tbody tr:first-child td {
      border-top: 0px;
    }
    tbody tr:last-child td {
      border-bottom: 0px;
    }
  }
`;

export const CustomPagination = styled(Pagination)`
  &&&& {
    background-color: ${themes.main.colors.ui01};
    border: none;
    border-top: thin solid ${themes.main.colors.ui03};

    .bx--select-input {
      padding: 0 2rem 0 var(--cds-spacing-05, 1rem);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      &:focus {
        outline: none;
      }
    }

    .bx--select-input .bx--select-option {
      background-color: ${themes.main.colors.paginationDropdownBackground};
      color: ${themes.main.colors.text01};
      border: thin solid ${themes.main.colors.paginationDropdownBorder};
      border-radius: 6px;

      &:hover {
        background-color: ${themes.main.colors.paginationDropdownHover};
      }
    }

    .bx--pagination__button,
    .bx--btn--ghost.bx--pagination__button {
      width: 3rem;

      &:focus {
        outline: none;
      }
    }

    .bx--btn--ghost:focus {
      box-shadow: none;
    }

    .bx--pagination__right .bx--pagination__text {
      font-size: 13px;
      line-height: 18px;
      color: ${themes.main.colors.text01};
    }

    .bx--btn.bx--btn--icon-only.bx--tooltip__trigger::before,
    .bx--btn.bx--btn--icon-only.bx--tooltip__trigger .bx--assistive-text {
      display: ${props =>
        !props.backwardText && !props.forwardText ? 'none' : ''};
    }
  }
`;

export const CustomTableHeader = styled(TableHeader)`
  &&&& {
    background-color: ${themes.main.colors.ui03};
    .bx--table-header-label {
      font-weight: 500;
      font-size: 13px;
      line-height: 18px;
    }
  }
`;

export const CustomTableSelectAll = styled(TableSelectAll)`
  &&&& {
    background-color: ${themes.main.colors.ui03};

    ${'.bx--checkbox:checked+.bx--checkbox-label::before'} {
      border: thin solid ${themes.main.colors.primaryButton1};
      background-color: ${themes.main.colors.primaryButton1};
    }
    ${'.bx--checkbox:indeterminate+.bx--checkbox-label::before'} {
      border: thin solid ${themes.main.colors.primaryButton1};
      background-color: ${themes.main.colors.primaryButton1};
    }
    ${'bx--table-header-label'} {
      font-weight: 500;
      font-size: 13px;
      line-height: 18px;
    }
    ${'.bx--checkbox:focus+.bx--checkbox-label::before'} {
      outline: none;
    }
  }
`;

export const CustomTableRow = styled(TableRow)<{
  $isRead?: boolean;
  disabled: boolean;
}>`
  cursor: pointer;
`;

export const CustomTableRowNP = styled(TableRow)<{ $isRead?: boolean }>`
  cursor: pointer;
  && {
    :hover td {
      background-color: ${(props): string =>
        props.$isRead ? '#F5E7D8 !important' : '#FBF7F4'};
    }
  }
`;

export const CustomTableRowNotif = styled(TableRow)<{
  $isRead?: boolean;
}>`
  cursor: pointer;

  &.notif-item-enter {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    display: block;
  }

  &.notif-item-enter-active {
    opacity: 1;
    max-height: 3rem;
    transition: all 300ms ease;
  }

  &.notif-item-exit {
    opacity: 0;
    max-height: 3rem;
    overflow: hidden;
    display: block;
  }

  &.notif-item-exit-active {
    opacity: 1;
    max-height: 0;
    transition: all 300ms ease;
  }

  &.notif-item-enter > td,
  &.notif-item-exit > td {
    height: 3rem;
  }

  && {
    td {
      background-color: ${(props): string =>
        props.$isRead ? '#FFFFFF' : '#FFF8EF'};
    }
  }
`;

export const CustomTableToolbarContent = styled(TableToolbarContent)`
  &&&& {
    height: max-content;
    position: relative;
    z-index: 99;
  }
`;

export const CustomBatchActions = styled(TableBatchActions)`
  &&&& {
    height: 100%;
    background-color: ${themes.main.colors.primaryButton1};
    z-index: 99;

    .bx--batch-summary {
      background-color: ${themes.main.colors.primaryButton1};

      .bx--batch-summary__para {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: ${themes.main.colors.white};
      }
    }
    .bx--action-list {
      .bx--batch-summary__cancel.bx--btn.bx--btn--primary {
        margin-left: 15px;
        width: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      button {
        background-color: ${themes.main.colors.primaryButton1};
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: ${themes.main.colors.white};
        outline: none;
        border: none;
        box-shadow: none;

        &:hover {
          background-color: ${themes.main.colors.primaryButtonHover};
        }

        svg {
          margin-left: 10px;
        }
      }
    }
  }
`;

export const CustomTableSelectRow = styled(TableSelectRow)<{
  isSelected?: boolean;
}>`
  &&&& {
    ${'.bx--checkbox:checked+.bx--checkbox-label::before'} {
      border: thin solid;
      border-color: ${props =>
        props.isSelected ? themes.main.colors.primaryButton1 : 'transparent'};
      background-color: ${themes.main.colors.primaryButton1};
    }
    ${'.bx--checkbox:focus+.bx--checkbox-label::before'} {
      outline: none;
    }
  }
`;

export const EmptyColumn = styled.td`
  width: 2.5rem;
  padding-right: 0.25rem;
  padding-left: 1rem;
`;

export const NPTCustomTableSelectRow = styled(TableSelectRow)<{
  isSelected?: boolean;
}>`
  &&&& {
    ${'.bx--checkbox:checked+.bx--checkbox-label::before'} {
      border: thin solid;
      border-color: ${props =>
        props.isSelected ? themes.main.colors.primaryButton1 : 'transparent'};
      background-color: ${themes.main.colors.primaryButton1};
    }
    ${'.bx--checkbox:focus+.bx--checkbox-label::before'} {
      outline: none;
    }
  }
`;

export const CustomTableCell = styled(TableCell)<{ isSelected?: boolean }>`
  &&&& {
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
  }
`;

export const StoryContainer = styled.div`
  width: 300px;
`;

export const ActionsContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const RemoveText = styled.p`
  width: 50%;
  color: ${themes.main.colors.errorColor};
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const RemoveDiv = styled.div`
  background-color: ${themes.main.colors.primaryButton1};
  width: 100%;
  margin-bottom: 20px;
  height: 63px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 27px;
  padding-right: 27px;
  align-content: center;
`;

export const MembersSelectedText = styled.p`
  color: ${themes.main.colors.white};
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.16px;
`;

export const ActionsDiv = styled.div`
  display: flex;
`;

export const TrashDiv = styled.div`
  border-right: 1px solid #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 2px;
  padding-bottom: 2px;
  justify-content: space-between;
  padding-right: 30px;
  &:hover {
    cursor: pointer;
  }
`;

export const ActionsText = styled.p`
  color: ${themes.main.colors.white};
  font-size: 13px;
  line-height: 18px;
  padding-left: 30px;
`;

export const RemoveText1 = styled.p`
  color: ${themes.main.colors.white};
  font-size: 13px;
  line-height: 18px;
  margin-right: 15px;
`;

export const TableWrapper = styled.div`
  background-color: ${themes.main.colors.ui01};
  padding-left: 10px;
  padding-right: 10px;
  height: 80vh;
  width: 100%;
`;

export const NPTableWrapper = styled.div<{
  $isNotEmpty: boolean;
}>`
  background-color: ${themes.main.colors.ui01};
  padding-left: 10px;
  padding-right: 10px;
  height: ${({ $isNotEmpty }) => ($isNotEmpty ? '530px' : '520px')};
  width: 100%;
`;

export const ContentTable = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const CancelDiv = styled.div`
  height: 100%;
  padding-top: 2px;
  &:hover {
    cursor: pointer;
  }
`;

export const TableTextDropdownDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: ${props => (props.theme.toggleRemoveDiv ? '96.5%' : '87%')};
  padding-left: 17px;
  justify-content: space-between;
  align-items: center;
`;

export const TableTitleText = styled.p`
  color: ${themes.main.colors.fontColor};
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.16px;
  width: 100%;
`;

export const DropDownTextDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  p.groupLabel {
    width: 40%;
    text-align: right;
  }
`;

export const StyledTable = styled.div<{
  $dataLength: number;
}>`
  &&& {
    .bx--data-table-content {
      max-height: 490px;
      background: ${({ $dataLength }) =>
        $dataLength > 5 ? themes.main.colors.ui03 : ''};

      /* width */
      ::-webkit-scrollbar {
        width: 7px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        background: ${themes.main.colors.text03};
        margin-top: 48px;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: ${themes.main.colors.ui04};
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: ${themes.main.colors.ui04};
      }
    }
    thead {
      tr {
        th {
          position: sticky;
          top: 0;
          z-index: 1;
        }
      }
    }

    thead {
      tr {
        th:nth-child(1) {
          z-index: 1;
        }
      }
    }
  }
`;

export const NoMemberDiv = styled.div`
  background-color: ${themes.main.colors.ui01};
  height: 100%;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  white-space: pre-wrap;
`;

export const InfoIconDiv = styled.div``;

export const ChooseGroupLabel = styled.p`
  color: ${themes.main.colors.black};
  font-size: 28px;
  line-height: 36px;
  text-align: center;
  padding-top: 1.5%;
`;

export const ChooseGroupText = styled.p`
  color: ${themes.main.colors.black};
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.16px;
  text-align: center;
  width: 487px;
  padding-top: 1.5%;
`;

export const CustomCheckbox = styled(Checkbox)`
  position: absolute;
  z-index: 1;
  top: 12px;
  left: 16px;

  &&&&&::before {
    border-radius: 1px;
  }
`;

export const CustomDataTableSkeletonContainer = styled.div`
  position: relative;

  & > .bx--skeleton.bx--data-table-container {
    padding-top: 0;
  }

  && > div > .bx--checkbox-wrapper {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

export const CustomDataTableSkeleton = styled(DataTableSkeleton)<{
  $isSelectable?: boolean;
}>`
  th {
    background-color: ${themes.main.colors.ui03};
  }

  th:first-child,
  td:first-child {
    padding-left: ${props => (props.$isSelectable ? 'calc(40px + 1rem)' : '')};
  }

  .bx--table-header-label {
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
  }
`;

export const NotifTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  @media ${themes.main.breakpoints.mobile} {
    white-space: pre-wrap;
  }
`;

export const NotifText = styled.p<{ $isBold?: boolean }>`
  color: ${themes.main.colors.text02};
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.16px;
  font-weight: ${(props): string => (props.$isBold ? 'bold' : '700px')};
  @media ${themes.main.breakpoints.pc} {
    margin-right: 5px;
  }
`;

export const NotifTextNoWrap = styled.p<{ $isBold?: boolean }>`
  color: ${themes.main.colors.text02};
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.16px;
  font-weight: ${(props): string => (props.$isBold ? 'bold' : '700px')};
  white-space: nowrap;
  margin-right: 5px;
`;

export const NotifMobileWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const NotifMobileColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

export const NotifMobileDateWrapper = styled.div`
  margin-left: 42px;
`;

export const NotifMobileRowWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`;
