import { useCallback } from 'react';
import { AxiosResponse } from 'axios';
import { MemberHooks } from '.';
import { useDependencies } from '..';
import {
  Member,
  CreateMemberType,
  UpdateMemberType,
  ValidateMemberRole,
  CheckUnassignedExistsType,
  CreateMemberField,
  MemberField,
} from '../../domain/entities/member';
import { PaginationQuery } from '../../domain/entities/request';
import { PaginatedResponse } from '../../domain/entities/response';

export const useFetchMembers: MemberHooks['useFetchMembers'] = (): {
  fetchMembers: (
    query: PaginationQuery & {
      groupId?: number;
      roleName?: string;
      name?: string;
      companyId: number;
    },
  ) => Promise<PaginatedResponse & { results: Member[] }>;
  fetchMember: (payload: {
    companyId: number;
    memberId: number;
  }) => Promise<Member | undefined>;
  fetchMultiSelect: (
    query: PaginationQuery & {
      groupId?: number;
      roleName?: string;
      name?: string;
      companyId: number;
    },
  ) => Promise<PaginatedResponse & { results: Member[] }>;
  validateMemberRoleChangeable: (
    companyId: number,
    memberId: number,
  ) => Promise<ValidateMemberRole>;
} => {
  const { memberInteractor } = useDependencies();
  const fetchMembers = useCallback(
    async (
      query: PaginationQuery & {
        companyId: number;
        groupId?: number;
        roleName?: string;
        name?: string;
      },
    ) => {
      const response = await memberInteractor.fetchMembers(query);

      return response;
    },
    [],
  );

  const fetchMember = useCallback(
    async (payload: { companyId: number; memberId: number }) => {
      const response = await memberInteractor.fetchMember(payload);
      return response;
    },
    [],
  );

  const fetchMultiSelect = useCallback(
    async (
      query: PaginationQuery & {
        companyId: number;
        groupId?: number;
        roleName?: string;
        name?: string;
      },
    ) => {
      const response = await memberInteractor.fetchMultiSelect(query);

      return response;
    },
    [],
  );

  const validateMemberRoleChangeable = useCallback(
    async (companyId, memberId) => {
      const response = await memberInteractor.validateMemberRoleChangeable(
        companyId,
        memberId,
      );
      return response;
    },
    [],
  );

  return {
    fetchMembers,
    fetchMember,
    fetchMultiSelect,
    validateMemberRoleChangeable,
  };
};

export const useCreateMember: MemberHooks['useCreateMember'] = (): {
  createMember: (
    params: CreateMemberType,
  ) => Promise<Member & { temporary_password: string }>;
} => {
  const { memberInteractor } = useDependencies();
  const createMember = useCallback(async (params: CreateMemberType) => {
    const member = await memberInteractor.createMember(params);

    return member;
  }, []);

  return { createMember };
};

export const useDeleteMember: MemberHooks['useDeleteMember'] = (): {
  deleteMember: (payload: {
    companyId: number;
    memberId: number;
  }) => Promise<void>;
} => {
  const { memberInteractor } = useDependencies();
  const deleteMember = useCallback(
    async (payload: { companyId: number; memberId: number }) => {
      await memberInteractor.deleteMember(payload);
    },
    [],
  );

  return { deleteMember };
};

export const useUpdateMember: MemberHooks['useUpdateMember'] = (): {
  updateMember: (
    companyId: number,
    memberId: number,
    payload: UpdateMemberType,
  ) => Promise<Member>;
} => {
  const { memberInteractor } = useDependencies();
  const updateMember = useCallback(
    async (companyId: number, memberId: number, payload: UpdateMemberType) => {
      const member: Member = await memberInteractor.updateMember(
        companyId,
        memberId,
        payload,
      );

      return member;
    },
    [],
  );

  return { updateMember };
};

export const useBatchUpdateMembersGroup: MemberHooks['useBatchUpdateMembersGroup'] = (): {
  batchUpdateMembersGroup: (
    companyId: number,
    memberIds: number[],
    groupId: number,
  ) => Promise<AxiosResponse>;
} => {
  const { memberInteractor } = useDependencies();
  const batchUpdateMembersGroup = useCallback(
    async (companyId: number, memberIds: number[], groupId: number) => {
      const response: AxiosResponse = await memberInteractor.batchUpdateMembersGroup(
        companyId,
        memberIds,
        groupId,
      );

      return response;
    },
    [],
  );

  return { batchUpdateMembersGroup };
};

export const useGenerateTemporaryPassword: MemberHooks['useGenerateTemporaryPassword'] = (): {
  generateTemporaryPassword: (
    companyId: number,
    memberId: number,
  ) => Promise<{ temporary_password: string }>;
} => {
  const { memberInteractor } = useDependencies();
  const generateTemporaryPassword = useCallback(
    async (companyId: number, memberId: number) => {
      const generate: {
        temporary_password: string;
      } = await memberInteractor.generateTemporaryPassword(companyId, memberId);

      return generate;
    },
    [],
  );

  return { generateTemporaryPassword };
};

export const useFilterDeletableMembers: MemberHooks['useFilterDeletableMembers'] = (): {
  filterDeletableMembers: (
    companyId: number,
    memberIds: string,
  ) => Promise<string[]>;
} => {
  const { memberInteractor } = useDependencies();
  const filterDeletableMembers = useCallback(
    async (companyId: number, memberIds: string) => {
      const deletableMembers: string[] = await memberInteractor.filterDeletableMembers(
        companyId,
        memberIds,
      );

      return deletableMembers;
    },
    [],
  );

  return { filterDeletableMembers };
};

export const useBatchDeleteMembers: MemberHooks['useBatchDeleteMembers'] = (): {
  batchDeleteMembers: (companyId: number, memberIds: string) => Promise<void>;
} => {
  const { memberInteractor } = useDependencies();
  const batchDeleteMembers = useCallback(
    async (companyId: number, memberIds: string) => {
      await memberInteractor.batchDeleteMembers(companyId, memberIds);

      return;
    },
    [],
  );

  return { batchDeleteMembers };
};

export const useCheckUnassignedExists: MemberHooks['useCheckUnassignedExists'] = (): {
  checkUnassignedExists: (
    companyId: number,
  ) => Promise<CheckUnassignedExistsType>;
} => {
  const { memberInteractor } = useDependencies();
  const checkUnassignedExists = useCallback(async (companyId: number) => {
    return await memberInteractor.checkUnassignedExists(companyId);
  }, []);

  return { checkUnassignedExists };
};

export const useCreateMemberFields: MemberHooks['useCreateMemberFields'] = (): {
  createMemberFields: (
    companyId: number,
    customFieldValues: CreateMemberField[],
    memberId: number,
  ) => Promise<void>;
} => {
  const { memberInteractor } = useDependencies();
  const createMemberFields = useCallback(
    async (
      companyId: number,
      customFieldValues: CreateMemberField[],
      memberId: number,
    ) => {
      return await memberInteractor.createMemberFields(
        companyId,
        customFieldValues,
        memberId,
      );
    },
    [],
  );
  return { createMemberFields };
};

export const useUpdateMemberFields: MemberHooks['useUpdateMemberFields'] = (): {
  updateMemberFields: (
    companyId: number,
    customFields: MemberField[],
  ) => Promise<void>;
} => {
  const { memberInteractor } = useDependencies();
  const updateMemberFields = useCallback(
    async (companyId: number, customFields: MemberField[]) => {
      return await memberInteractor.updateMemberFields(companyId, customFields);
    },
    [],
  );
  return { updateMemberFields };
};
