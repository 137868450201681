import React, { useCallback, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { useAuthHooks } from '../../../hooks/auth';
import { ResetPassword } from '../../templates/ResetPassword';

const Component = (): React.ReactElement => {
  const navigate = useNavigate();

  const { useResetPassword } = useAuthHooks();

  const { forgetPassword, resetPassword } = useResetPassword();

  const { pk, token } = useParams();

  const [isPageLoading, setIsPageLoading] = useState<boolean>(false);

  const { mutate: resetPasswordMutation, isLoading } = useMutation(
    ([password, password2]: [string, string]) => {
      return resetPassword(pk || '', token || '', password, password2);
    },
  );

  const { mutate: forgetPasswordMutation } = useMutation(
    ([email, email_token]: [string, string]) => {
      return forgetPassword(email, email_token);
    },
  );

  const onSubmit = useCallback(
    (values: { password: string; password2: string }): void => {
      const { password, password2 } = values;

      resetPasswordMutation([password, password2], {
        onSuccess: () => {
          navigate('/login', { state: { resetPasswordStatus: 'success' } });
        },
        onError: () => {
          navigate('/login', { state: { resetPasswordStatus: 'error' } });
        },
      });
    },
    [],
  );

  const verifyTokenExpiration = useCallback(
    (values: { email?: string; email_token: string }): void => {
      const { email_token } = values;
      setIsPageLoading(true);
      forgetPasswordMutation(['', email_token], {
        onSuccess: () => {
          setIsPageLoading(false);
        },
        onError: error => {
          const err = error as any;
          navigate('/forgot-password', {
            state: { isTokenExpired: true },
          });
        },
      });
    },
    [],
  );

  useEffect(() => {
    verifyTokenExpiration({ email_token: token as string });
  }, []);

  useEffect(() => {
    window.addEventListener('beforeunload', promptUser);
    return () => {
      window.removeEventListener('beforeunload', promptUser);
    };
  });

  const promptUser = (event: any) => {
    event.preventDefault();
    event.returnValue = '';
  };

  return (
    <>
      {!isPageLoading && (
        <ResetPassword
          onResetPassword={onSubmit}
          isLoading={isLoading}
          onPressLogin={() => navigate('/login')}
        />
      )}
    </>
  );
};

export default Component;
