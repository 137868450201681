import React, { ChangeEvent, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';

import { Banner } from '../../molecules/Banner';
import { AdminSignUpForm } from '../../organisms/AdminSignUpForm';

import { AdminSignUpSchema } from './validation';

import BannerImage from '../../../assets/images/team-handshake.jpg';
import LogoImage from '../../../assets/images/ever-contest.png';
import { theme } from '../../../config';
import words from '../../../constants/words';

const Container = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  min-height: 100vh;
  background-color: ${theme.colors.ui01};

  @media ${theme.breakpoints.mobileAndTablet} {
    grid-template-columns: 100%;
  }
`;

const Column = styled.div<{ isBanner?: boolean }>`
  padding: ${props => (props.isBanner ? '0' : '60px 5%')};
  @media ${theme.breakpoints.mobileAndTablet} {
    ${props => props.isBanner && 'display: none'};
  }
  @media ${theme.breakpoints.mobile} {
    padding: ${props => (props.isBanner ? '0' : '23px 0')};
  }
`;

const TextHeader = styled.div`
  display: flex;
  margin-bottom: 5%;
  flex-direction: column;
  justify-content: space-between;
  @media ${theme.breakpoints.mobile} {
    margin-bottom: 30px;
  }
`;

const Logo = styled.div<{ imageUrl: string }>`
  background-image: url(${props => props.imageUrl});
  background-repeat: no-repeat;
  background-size: contain;
  height: 23px;
  margin-bottom: 5%;
  margin-left: 2rem;
  @media ${theme.breakpoints.mobile} {
    height: 18px;
    margin-left: 1rem;
  }
`;

const SignUpTitle = styled.text`
  font-size: 28px;
  line-height: 36px;
  padding-left: 2rem;
  color: ${theme.colors.blackText};
  @media ${theme.breakpoints.mobile} {
    font-size: 20px;
    line-height: 26px;
    font-weight: 500;
    padding-left: 1rem;
  }
`;

export interface AdminSignUpValues {
  companyName: string;
  companyTelephone: string;
  groupName: string;
  representativeEmailAddress: string;
  representativeName: string;
  companyEmail: string;
}

export type AdminSignUpProps = {
  register: (values: AdminSignUpValues) => void;
  handleLoginRedirect: () => void;
  companyEmailRes?: string;
  memberEmailRes?: string;
  signupError?: boolean;
  checkRepEmail: (value: string) => void;
  checkCompEmail: (value: string) => void;
  isLoading?: boolean;
};

const Component = ({
  register,
  handleLoginRedirect,
  companyEmailRes,
  memberEmailRes,
  signupError,
  checkRepEmail,
  checkCompEmail,
  isLoading,
}: AdminSignUpProps): React.ReactElement => {
  const initialValues = {
    companyName: '',
    groupName: '',
    representativeName: '',
    companyEmail: '',
    representativeEmailAddress: '',
    companyTelephone: '',
  };
  const [repEmail, setRepEmail] = useState('');
  const [compEmail, setCompEmail] = useState('');

  const handleEmailChange = (value: string, key?: string) => {
    if (key === 'email') {
      setRepEmail(value);
    } else {
      setCompEmail(value);
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      repEmail.length > 1 && checkRepEmail(repEmail);
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [repEmail]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      compEmail.length > 1 && checkCompEmail(compEmail);
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [compEmail]);

  return (
    <Container>
      <Column>
        <TextHeader>
          <Logo imageUrl={LogoImage} />
          <SignUpTitle>{words.createAnAccount}</SignUpTitle>
        </TextHeader>

        <Formik
          initialValues={initialValues}
          validationSchema={AdminSignUpSchema}
          onSubmit={register}>
          {({
            handleSubmit,
            handleChange,
            values,
            errors,
          }): React.ReactElement => {
            const {
              companyName,
              groupName,
              representativeName,
              companyEmail,
              representativeEmailAddress,
              companyTelephone,
            } = values;
            const {
              companyName: companyNameError,
              groupName: groupNameError,
              representativeName: representativeNameError,
              companyEmail: companyEmailError,
              representativeEmailAddress: representativeEmailAddressError,
              companyTelephone: companyTelephoneError,
            } = errors;
            return (
              <AdminSignUpForm
                companyName={companyName}
                onChange={handleChange}
                groupName={groupName}
                representativeName={representativeName}
                companyEmail={companyEmail}
                representativeEmailAddress={representativeEmailAddress}
                companyTelephone={companyTelephone}
                isLoading={isLoading}
                buttonDisable={
                  !!companyNameError ||
                  !!groupNameError ||
                  !!representativeNameError ||
                  !!companyEmailError ||
                  !!representativeEmailAddressError ||
                  !!companyTelephoneError ||
                  !!companyEmailRes ||
                  (!!memberEmailRes && representativeEmailAddress.length > 1) ||
                  !!!companyName ||
                  !!!groupName ||
                  !!!representativeName ||
                  !!!companyEmail ||
                  !!!companyTelephone ||
                  !!isLoading
                }
                onPressRegister={handleSubmit}
                onPressLogin={handleLoginRedirect}
                companyNameError={companyNameError}
                groupNameError={groupNameError}
                representativeNameError={representativeNameError}
                companyEmailError={companyEmailError}
                representativeEmailAddressError={
                  representativeEmailAddressError
                }
                companyTelephoneError={companyTelephoneError}
                companyEmailRes={companyEmailRes}
                memberEmailRes={
                  representativeEmailAddress.length > 1 ? memberEmailRes : ''
                }
                signupError={signupError}
                onChangeCompEmail={(event: ChangeEvent) => {
                  handleChange(event);
                  !companyEmailError &&
                    handleEmailChange(
                      (event.target as HTMLTextAreaElement).value,
                    );
                }}
                onChangeRepEmail={(event: ChangeEvent) => {
                  handleChange(event);
                  !representativeEmailAddressError &&
                    handleEmailChange(
                      (event.target as HTMLTextAreaElement).value,
                      'email',
                    );
                }}
              />
            );
          }}
        </Formik>
      </Column>
      <Column isBanner>
        <Banner label={words.bannerLabel} imageUrl={BannerImage} />
      </Column>
    </Container>
  );
};

export default Component;
