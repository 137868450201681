import AuthService from '../../ports/AuthService';

export type verifyCompanyEmailUseCase = (
  email: string,
) => Promise<object | null>;

export const buildVerifyCompanyEmail = (dependencies: {
  authService: AuthService;
}): verifyCompanyEmailUseCase => {
  const { authService } = dependencies;

  const verifyCompanyEmail: verifyCompanyEmailUseCase = email =>
    authService.verifyCompanyEmail(email);

  return verifyCompanyEmail;
};
