export const defaultSphForDay = {
  start_date: '',
  sum_time_actual: '',
  sum_time_expect: '',
  sum_sph_actual: '',
  sum_process_percent: '',
  sum_pre_probable_number: '',
  sum_number_of_withdrawals: '',
  sum_number_of_tosses: '',
  sum_product_actual: '',
};

export const defaultSphForMonth = {
  start_date: '',
  end_date: '',
  sum_time_actual: '',
  sum_time_expect: '',
  sum_sph_actual: '',
  sum_process_percent: '',
  sum_pre_probable_number: '',
  sum_number_of_withdrawals: '',
  sum_number_of_tosses: '',
  sum_product_actual: '',
  plan_month_number_of_order: '',
  toss_rate_month: '',
};
