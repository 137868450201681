const RANKING_FORM_KEY = 'ranking-form';
const REFERENCE_FORM_KEY = 'refererence-form';
const REFERENCE_DATA_KEY = 'entry-data';
const RANKING_DATA = 'ranking-data';

export {
  RANKING_FORM_KEY,
  REFERENCE_FORM_KEY,
  REFERENCE_DATA_KEY,
  RANKING_DATA,
};
