import { AxiosResponse } from 'axios';
import AuthService from '../../ports/AuthService';

export type signInUserUseCase = (
  companyId: string,
  userId: string,
  password: string,
) => Promise<AxiosResponse<any>>;

export const buildSignInUser = (dependencies: {
  authService: AuthService;
}): signInUserUseCase => {
  const { authService } = dependencies;

  const signInUser: signInUserUseCase = (companyId, userId, password) =>
    authService.signIn(companyId, userId, password);

  return signInUser;
};
