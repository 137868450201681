import React, { useEffect, useState } from 'react';
import './approveChangeInfo.scss';
import { Loading } from 'components/molecules/Loading';
import { useNavigate, useSearchParams } from 'react-router-dom';
import urls from 'constants/urls';
import { getCurrentUser, isEmptyObject } from 'utils/common';
import axios from 'axios';
import authHeader from 'api/auth-header';
import { SignedUpSuccessModal } from 'components/templates/SignedUpSuccessModal';
import CheckCircle from 'assets/icons/check-circle.svg';
import ErrorCircle from 'assets/icons/error-circle.svg';
import words from 'constants/words';

export default function MemberAccountSetting() {
  const [isLoading, setIsLoading] = useState('block');
  const [isShowSuccessModal, setIsShowSuccessModal] = useState('none');
  const [verifyMode, setVerifyMode] = useState(true);
  const [rejectMode, setRejectMode] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const uid = searchParams.get('uid');
  const token = searchParams.get('token');
  const reject_flg = searchParams.get('reject_flg');

  useEffect(() => {
    let data;
    const user = getCurrentUser();
    if (uid === null || token === null) {
      navigate(urls.home);
      return;
    }
    if (isEmptyObject(user)) {
      navigate(urls.sign_in_page);
      return;
    }
    if (reject_flg && reject_flg == '1') {
      data = { uid, token, reject_flg };
      setRejectMode(true);
    } else {
      data = { uid, token };
    }
    axios
      .post(urls.handle_confirm_update_info, data, { headers: authHeader() })
      .then(() => {
        setIsLoading('none');
        setVerifyMode(true);
        setIsShowSuccessModal('block');
      })
      .catch(() => {
        setIsLoading('none');
        setVerifyMode(false);
        setIsShowSuccessModal('block');
      });
  }, []);

  return (
    <div className="approve-member-change">
      <Loading display={isLoading} />
      <SignedUpSuccessModal
        display={isShowSuccessModal}
        onClick={() => navigate(urls.home)}
        icon={verifyMode ? CheckCircle : ErrorCircle}
        message={
          verifyMode
            ? rejectMode
              ? words.changeRequestRejected
              : words.changeRequestApproved
            : words.changeRequestFailed
        }
        textButton={verifyMode ? words.backToProfile : words.close}
      />
    </div>
  );
}
