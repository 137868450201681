import React from 'react';

type PropType = {
  display: boolean;
  onClick: () => void;
  style?: React.CSSProperties;
};

export default function BlackScreen(props: PropType) {
  const { display, onClick, style } = props;
  return (
    <div
      className="black-screen-pages"
      onClick={onClick}
      style={{
        ...style,
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgb(0 0 0 / 78%)',
        display: display ? 'block' : 'none',
      }}></div>
  );
}
