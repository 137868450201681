import EntryRepository from '../../ports/EntryRepository';

export type deleteEntryApplicationUseCase = (
  companyId: number,
  memberId: number,
  rankingId: number,
  entryId: number,
) => Promise<void>;

export const buildDeleteEntryApplication = (dependencies: {
  entryRepo: EntryRepository;
}): deleteEntryApplicationUseCase => {
  const { entryRepo } = dependencies;

  const deleteEntryApplication: deleteEntryApplicationUseCase = async (
    companyId,
    memberId,
    rankingId,
    entryId,
  ) => entryRepo.deleteEntry(companyId, memberId, rankingId, entryId);

  return deleteEntryApplication;
};
