import { useMemo } from 'react';
import { URLSearchParams as URLSearchParamsType } from 'url';
import { useLocation } from 'react-router-dom';

import { NavigationHooks } from '.';

export const useNavigation: NavigationHooks['useNavigation'] = (): {
  getUrlQuery: () => URLSearchParamsType;
} => {
  const getUrlQuery = (): URLSearchParamsType => {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  };

  return { getUrlQuery };
};
