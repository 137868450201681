import React, { useState, useCallback, useEffect } from 'react';

import { ViewMember } from '../../templates/ViewMember';
import { useMemberHooks } from '../../../hooks/member';
import { useLoginFieldHooks } from '../../../hooks/field';
import { useQuery } from 'react-query';
import { AxiosError } from 'axios';

import {
  Member,
  MemberField,
  UpdateMemberType,
  ViewMemberInfo,
} from '../../../domain/entities/member';
import { useParams } from 'react-router-dom';
import { useGlobalState } from '../../../hooks/global';
import { Props as INotification } from '../../molecules/Notification/Notification';

import { navigateToErrorPage } from '../../../utils/handleErrorPage';

import { DeleteMemberModal } from '../../templates/DeleteMemberModal';
import { SubHeader } from '../../atoms/Subheader';
import { useNavigate, useLocation } from 'react-router-dom';
import words from '../../../constants/words';
import data from '../../../constants/data';
import { ToastNotification } from '../../molecules/Notification';
import { TIMEOUT } from '../../../constants/timeout';
import { PageLoader } from '../../atoms/Loading';

type Props = {
  isEdit?: boolean;
  setImage: (src: string | undefined) => void;
};

const Component = ({ isEdit = false, setImage }: Props): React.ReactElement => {
  const navigate = useNavigate();
  const { state: locationState, pathname: locationPathname } = useLocation();

  const {
    useCurrentUser: { currentUser },
  } = useGlobalState();

  const { id: memberId } = useParams();
  const companyId = currentUser?.companyId;

  const { useFetchMembers } = useMemberHooks();
  const { fetchMember } = useFetchMembers();
  const { useFetchLoginFields } = useLoginFieldHooks();
  const { fetchFields } = useFetchLoginFields();

  const [memberToDelete, setMemberToDelete] = useState<Member | undefined>(
    undefined,
  );
  const [canEdit, setCanEdit] = useState<boolean>(true);
  const [newlyUpdatedMember, setNewlyUpdatedMember] = useState<
    (UpdateMemberType & { groupName?: string }) | undefined
  >();

  const [notification, setNotification] = useState<INotification | null>(
    locationState?.toastMessage
      ? {
          ...locationState?.toastMessage,
          timeout: TIMEOUT,
          handleClose: () => {
            setNotification(null);
            return false;
          },
        }
      : null,
  );

  const {
    data: memberDefault,
    isFetching: isFetchingMember,
    isError: isErrorFetchMember,
  } = useQuery({
    queryKey: ['fetchMember', companyId, memberId],
    queryFn: async () => {
      if (companyId && memberId) {
        return await fetchMember({ companyId, memberId: Number(memberId) });
      } else {
        throw new Error(`company id or member id not set`);
      }
    },
    refetchOnMount: true,
    onError: (err: AxiosError) => {
      navigateToErrorPage(navigate, err.response?.status, locationPathname);
    },
  });

  const {
    data: memberFieldsLookup,
    isFetching: isFetchingMemberFieldsLookup,
    isError: isErrorFetchMemberFields,
  } = useQuery({
    queryKey: ['fetchFields', companyId],
    queryFn: async () => {
      if (companyId) {
        return await fetchFields({ companyId: Number(companyId) });
      } else {
        throw new Error(`company id' not set`);
      }
    },
    refetchOnMount: true,
  });

  const isLoading = isFetchingMember || isFetchingMemberFieldsLookup;
  const isError = isErrorFetchMember || isErrorFetchMemberFields;
  const memberFields = memberDefault?.fields;
  const memberCustomFields = memberFieldsLookup
    ?.filter(e => !e.is_default && e.visible)
    .map(field => {
      const memberField = memberFields?.find(
        e => e.field_lookup_id === field.id,
      );

      const mappedMemberField: MemberField = {
        id: memberField?.id || 0,
        field_lookup_id: field.id,
        name: field.name,
        value_char: memberField?.value_char || '',
        member_id: memberDefault?.id || 0,
      };

      return mappedMemberField;
    });

  const toastNotification = useCallback(
    (toast: INotification) => {
      setNotification({
        ...toast,
        timeout: TIMEOUT,
        handleClose: () => {
          setNotification(null);
          return false;
        },
      });
    },
    [notification],
  );

  const backOnpress = () => {
    if (locationState?.previousPath) navigate(locationState.previousPath);
    else navigate(`/members`);
  };
  const deleteOnPress = useCallback(() => {
    setMemberToDelete(memberDefault);
  }, [memberDefault]);
  const editOnPress = () => {
    navigate(`/members/${memberId}/edit`, {
      state: { previousPath: `/members/${memberId}` },
    });
  };
  const copyOnPress = (value: string | undefined) => {
    if ('clipboard' in navigator) {
      if (!!value) {
        navigator.clipboard.writeText(value);
      }
    }
  };
  const closeDeleteMemberModal = useCallback(() => {
    setMemberToDelete(undefined);
  }, []);

  const memberInfo: ViewMemberInfo = {
    name: memberDefault?.name || '',
    groupName:
      newlyUpdatedMember?.groupName || memberDefault?.group?.name || '',
    roleName: newlyUpdatedMember?.role || memberDefault?.roleName || '',
    groupId: newlyUpdatedMember?.group || memberDefault?.groupId,
    id: Number(memberDefault?.id) || 0,
    companyId: memberDefault?.companyId || 0,
    memberCustomFields: memberCustomFields,
    email: memberDefault?.email,
    profileImage: newlyUpdatedMember?.photoUrl || memberDefault?.photoUrl,
    lastLogin: memberDefault?.lastLogin || undefined,
    userId:
      Number(
        memberDefault?.username
          .substring(memberDefault.username.indexOf('A'))
          ?.substring(1),
      ) || 0,
  };

  useEffect(() => {
    isEdit && window.addEventListener('beforeunload', promptUser);
    return () => {
      isEdit && window.removeEventListener('beforeunload', promptUser);
    };
  }, [isEdit]);

  const promptUser = (event: any) => {
    event.preventDefault();
    event.returnValue = '';
  };

  const onSubmitDeleteModal = (
    newDeletedMember: Member | undefined,
    toast: INotification,
  ) => {
    const deleteData = { deletedMember: newDeletedMember, toast: toast };
    navigate('/members', { state: { deleteData: deleteData } });
  };

  useEffect(() => {
    if (currentUser?.roleName) {
      const userRole = data.memberRoles.find(
        e => e.id === currentUser.roleName,
      );
      const viewedMemberRole = data.memberRoles.find(
        e => e.id === memberInfo.roleName,
      );

      if (userRole?.id === 'member') {
        if (locationState?.previousPath) navigate(locationState.previousPath);
        else navigate('/');
      } else if (
        userRole?.id === 'approver' &&
        viewedMemberRole?.id === 'admin'
      ) {
        if (locationState?.previousPath) navigate(locationState.previousPath);
        else navigate('/');
      }
    }
  }, [isEdit, currentUser, memberInfo]);

  return isError ? (
    <h2>Error fetching member data</h2> // TODO; replace with error fetching member toast
  ) : (
    <React.Suspense fallback={<div />}>
      <SubHeader
        title={isEdit ? words.editMemberSubHeader : words.viewMemberSubHeader}
      />
      {isFetchingMember || isFetchingMemberFieldsLookup ? (
        <PageLoader />
      ) : (
        <ViewMember
          isLoading={isLoading}
          backOnPress={backOnpress}
          deleteOnPress={deleteOnPress}
          editOnPress={editOnPress}
          copyOnPress={copyOnPress}
          memberInfo={memberInfo}
          toastNotification={toastNotification}
          canEdit={canEdit}
          isEdit={isEdit}
          password={locationState?.temporary_password || undefined}
          setNewlyUpdatedMember={setNewlyUpdatedMember}
          onSuccessGeneratePassword={toastNotification}
          setImage={setImage}
        />
      )}
      <DeleteMemberModal
        isOpen={!!memberToDelete}
        onClose={closeDeleteMemberModal}
        onSubmit={onSubmitDeleteModal}
        memberToDelete={memberToDelete}
      />
      {notification ? <ToastNotification notification={notification} /> : null}
    </React.Suspense>
  );
};

export default Component;
