import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Accordion } from 'carbon-components-react';

import { AccordionItem } from '../../molecules/AccordionItem';
import { theme } from '../../../config';
import { useLocation } from 'react-router';

const StyledAccordion = styled(Accordion)`
  padding-top: 15px;
`;

const StyledContainer = styled.div`
  width: 256px;
  background: ${theme.colors.white};
  min-height: 100vh;
`;

export type RouteItem = {
  label: string;
  to?: string;
  onPress?: () => void;
  subroutes?: {
    label: string;
    to: string;
    onPress?: () => void;
  }[];
};

export type NavigationSidebarProps = {
  routes?: RouteItem[];
  className?: string;
  toggleSideBarClick: (e: any) => void;
  toggleSideBarHome: (e: any) => void;
};

const Component = ({
  routes,
  className,
  toggleSideBarClick,
  toggleSideBarHome,
}: NavigationSidebarProps): React.ReactElement => {
  const [activeClass, setActiveClass] = useState<string>('');
  const location = useLocation();
  const currLoc = location.pathname;

  useEffect(() => {
    onRouteClick();
  });
  const onRouteClick = (e?: React.MouseEvent) => {
    if (e) {
      const selectedElement = e?.nativeEvent.target as any;
      if (
        selectedElement.innerHTML === 'Home' ||
        (selectedElement.classList[0] === 'bx--accordion__title' &&
          activeClass !== '')
      ) {
        setActiveClass('active');
      } else {
        setActiveClass('');
      }
    } else {
      if (
        currLoc === '/notifications' &&
        document.getElementsByClassName('active').length
      ) {
        setActiveClass('');
      } else {
        if (
          !document.getElementsByClassName('active').length &&
          location.pathname !== '/notifications'
        ) {
          setActiveClass('active');
        }
      }
    }
  };
  const renderItems = () => {
    return routes?.map(route => {
      return (
        <AccordionItem
          key={`${route.label}-${route.to}`}
          to={route.to}
          label={route.label}
          onPress={e => {
            onRouteClick(e), route.onPress, toggleSideBarHome(route);
          }}
          subroutes={route.subroutes}
          activeClass={activeClass}
          toggleItemClick={toggleSideBarClick}
        />
      );
    });
  };
  return (
    <StyledContainer className={className}>
      <StyledAccordion>{renderItems()}</StyledAccordion>
    </StyledContainer>
  );
};

export default Component;
