import { RecordReference } from '../../../domain/entities/record';
import RecordRepository from '../../ports/RecordRepository';

export type fetchReferenceDetailsUseCase = (payload: {
  companyId: number;
  memberId: number;
  rankingId: number;
  entryId: number;
}) => Promise<RecordReference>;

export const buildFetchReferenceDetails = (dependencies: {
  recordRepo: RecordRepository;
}): fetchReferenceDetailsUseCase => {
  const { recordRepo } = dependencies;

  const fetchReferenceDetails: fetchReferenceDetailsUseCase = async payload =>
    recordRepo.fetchReferenceDetails(payload);

  return fetchReferenceDetails;
};
