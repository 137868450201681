import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

import { theme } from '../../../config';

import { HoverTooltip } from '../Tooltip';
import { TooltipProps } from 'carbon-components-react';

const EllipsisContainer = styled.div`
  color: ${theme.colors.text01};
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
`;

export type Props = {
  children?: React.ReactNode;
  hasTooltip?: boolean;
  autoTooltip?: boolean;
  tooltipDirection?: TooltipProps['direction'];
  tooltipAutoOrientation?: boolean;
  tooltipMenuOffset?: TooltipProps['menuOffset'];
  tooltipAlign?: TooltipProps['align'];
};

const Component = ({
  children,
  hasTooltip,
  autoTooltip,
  tooltipDirection,
  tooltipAutoOrientation,
  tooltipMenuOffset,
  tooltipAlign,
}: Props): React.ReactElement => {
  const [isEllipsisShown, setIsEllipsisShown] = useState(false);

  const ellipsisRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ellipsisRef.current) {
      if (ellipsisRef.current?.offsetWidth < ellipsisRef.current?.scrollWidth)
        setIsEllipsisShown(true);
      else setIsEllipsisShown(false);
    } else setIsEllipsisShown(false);
  }, [ellipsisRef]);

  return hasTooltip ? (
    <HoverTooltip
      wrapperClassName="ellipsis-tooltip-wrapper"
      tooltipClassName="ellipsis-tooltip"
      triggerWrapperClassName="ellipsis-tooltip-trigger-wrapper"
      direction={tooltipDirection}
      autoOrientation={tooltipAutoOrientation}
      menuOffset={tooltipMenuOffset}
      align={tooltipAlign}
      stopOpen={autoTooltip && !isEllipsisShown}
      triggerNode={
        <EllipsisContainer className="ellipsis-container" ref={ellipsisRef}>
          {children}
        </EllipsisContainer>
      }>
      {children}
    </HoverTooltip>
  ) : (
    <EllipsisContainer className="ellipsis-container">
      {children}
    </EllipsisContainer>
  );
};

export default Component;
