import React, { useState, useCallback, useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';
import styled from 'styled-components';
import { Formik } from 'formik';
import { useLocation } from 'react-router-dom';

import { Props as NotificationType } from '../../molecules/Notification/Notification';
import { ToastNotification } from '../../molecules/Notification';
import { ViewUserProfileForm } from '../../organisms/ViewUserProfileForm';
import { UserProfile } from '../../organisms/ViewUserProfileForm/ViewUserProfileForm';
import { ChangePasswordModal } from '../../templates/ChangePasswordModal';
import { UserProfileSchema, EmailValidation } from './validation';
import { useMemberHooks } from '../../../hooks/member';
import { useGroupHooks } from '../../../hooks/group';
import { useAuthHooks } from '../../../hooks/auth';
import { useLoginFieldHooks } from '../../../hooks/field';
import { useGlobalState } from '../../../hooks/global';
import words from '../../../constants/words';
import theme from '../../../config/themes/main';
import { TIMEOUT } from '../../../constants/timeout';
import data, { Role } from '../../../constants/data';
import { Group } from '../../../domain/entities/group';
import {
  UpdateAccountType,
  MemberField,
} from '../../../domain/entities/member';
import { PageLoader } from '../../atoms/Loading';

const ContentContainer = styled.div`
  background-color: ${theme.colors.dropDownContainerBgColor};
  padding-left: 80px;
  padding-right: 80px;
  height: 100%;
  @media ${theme.breakpoints.mobile} {
    padding: 0 23px;
  }
`;

const FormWrapper = styled.div``;

type Props = {
  setImage?: (src: string | undefined) => void;
};

type APIResponse = {
  error: boolean;
  company_email: string;
  memberEmail: string;
  id: string;
  username: string;
  company: string;
  representative: string;
  group: string;
  user_email: string;
  msg: string;
};

const Component = ({ setImage }: Props): React.ReactElement => {
  const { state: locationState } = useLocation();
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const promptUser = (event: any) => {
    event.preventDefault();
    event.returnValue = '';
  };

  const [previousValues, setPreviousValues] = useState<UserProfile | undefined>(
    undefined,
  );
  const [isChangingEmail, setIsChangingEmail] = useState<boolean>(false);
  const [emailVal, setEmail] = useState<string | null>(null);
  const [memberEmailRes, setMemberEmailRes] = useState<string>('');
  const [currentPasswordError, setCurrentPasswordError] = useState<string>('');
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [notification, setNotification] = useState<NotificationType | null>(
    locationState?.toastMessage
      ? {
          ...locationState?.toastMessage,
          timeout: TIMEOUT,
          handleClose: () => {
            setNotification(null);
            return false;
          },
        }
      : null,
  );
  const [selectedGroup, setSelectedGroup] = useState<Group | undefined>(
    undefined,
  );
  const [selectedRole, setSelectedRole] = useState<Role | undefined>(undefined);
  const [roleChangeError, setRoleChangeError] = useState('');

  const {
    useUpdateMember,
    useFetchMembers,
    useUpdateMemberFields,
  } = useMemberHooks();
  const { updateMemberFields } = useUpdateMemberFields();
  const { fetchMember, validateMemberRoleChangeable } = useFetchMembers();
  const { updateMember } = useUpdateMember();
  const { useFetchGroups } = useGroupHooks();
  const { fetchGroups } = useFetchGroups();
  const { useFetchLoginFields } = useLoginFieldHooks();
  const { fetchFields } = useFetchLoginFields();
  const { useLogin, useResetPassword, useSignUp } = useAuthHooks();
  const { verifyPassword } = useLogin();
  const { changePassword } = useResetPassword();
  const { verifyEmail } = useSignUp();

  const {
    useCurrentUser: { currentUser, setCurrentUser },
  } = useGlobalState();

  const toggleIsEdit = useCallback(() => {
    setIsEdit(!isEdit);
  }, [isEdit]);

  const {
    data: memberData = {
      id: 0,
      companyId: 0,
      userId: 0,
      name: '',
      email: '',
      groupId: 0,
      groupName: '',
      roleName: '',
      profileImage: require('../../../assets/images/default-profile-photo.png'),
      emailNotif: false,
      memberCustomFields: [],
      fields: [],
    },
    isFetching: isFetchingUserProfile,
  } = useQuery(['fetchUserProfile', currentUser], async () => {
    const companyId = currentUser?.companyId;
    const memberId = currentUser?.memberId;
    if (!companyId || !memberId) {
      throw new Error(`fields missing`);
    }
    const response = await fetchMember({
      companyId,
      memberId,
    });
    if (!response) {
      throw new Error(`User '${memberId}' at Company '${companyId}' not found`);
    }

    if (groupItems && groupItems.length > 0) {
      setSelectedGroup(groupItems.find(e => e.id === response.groupId));
    }
    setSelectedRole(data.memberRoles.find(e => e.id === response.roleName));

    return {
      id: Number(response.id) || 0,
      companyId: companyId,
      memberId: memberId,
      userId: currentUser?.userId,
      name: response.name,
      groupId: response.groupId,
      groupName: response.group?.name || words.unassignedGroup,
      roleName: response.roleName,
      email: response.email,
      profileImage: response.photoUrl,
      emailNotif: true,
      fields: response.fields,
    };
  });

  const {
    data: memberFieldsLookup,
    isFetching: isFetchingMemberFieldsLookup,
    // isError: isErrorFetchMemberFields,
  } = useQuery({
    queryKey: ['fetchFields', currentUser?.companyId],
    queryFn: async () => {
      if (currentUser?.companyId) {
        return await fetchFields({ companyId: Number(currentUser.companyId) });
      } else {
        throw new Error(`company id' not set`);
      }
    },
    refetchOnMount: true,
  });

  const memberFields = memberData?.fields;
  const memberCustomFields = memberFieldsLookup
    ?.filter(e => !e.is_default && e.visible)
    .map(field => {
      const memberField = memberFields?.find(
        e => e.field_lookup_id === field.id,
      );

      const mappedMemberField: MemberField & { isRequired: boolean } = {
        id: memberField?.id || 0,
        field_lookup_id: field.id,
        name: field.name,
        value_char: memberField?.value_char || '',
        member_id: memberData?.id || 0,
        isRequired: field.required,
      };

      return mappedMemberField;
    });

  const memberInfo = {
    ...memberData,
    memberCustomFields: memberCustomFields,
  };

  const {
    data: { results: groupItems } = {
      results: [],
    },
    isFetching: isFetchingGroup,
  } = useQuery(
    ['fetchUserGroups', currentUser?.companyId, memberData?.groupId],
    () => {
      const companyId = currentUser?.companyId || 0;

      if (!companyId) {
        throw new Error(`'company id' not set`);
      }

      return fetchGroups({
        companyId,
      });
    },
    {
      onSuccess: response => {
        const groups = response.results;
        setSelectedGroup(groups.find(e => e.id === memberData.groupId));
      },

      refetchOnMount: true,
    },
  );

  const { data: validatedMemberRole } = useQuery(
    ['validateMemberRoleChangeable', currentUser?.memberId],
    () => {
      if (currentUser?.companyId && currentUser?.memberId) {
        return validateMemberRoleChangeable(
          currentUser?.companyId,
          currentUser?.memberId,
        );
      }
    },
  );

  const toastNotification = useCallback(
    (toast: NotificationType) => {
      setNotification({
        ...toast,
        timeout: TIMEOUT,
        handleClose: () => {
          setNotification(null);
          return false;
        },
      });
    },
    [notification],
  );

  const { mutate: saveChanges } = useMutation(
    async (values: UserProfile) => {
      let payload: UpdateAccountType = {};

      if (values.groupId !== selectedGroup?.id)
        payload.group = selectedGroup?.id;
      if (values.roleName !== selectedRole?.id) payload.role = selectedRole?.id;
      if (values.id == undefined) {
        throw new Error(`'member id' not set`);
      } else {
        if (!values.companyId || !values.id) {
          throw new Error(`field 'company id' not set`);
        }
        if (values.memberCustomFields && values.memberCustomFields.length > 0) {
          await updateMemberFields(values.companyId, values.memberCustomFields);
        }
        payload = {
          ...payload,
          roleName: values.roleName,
          email: values.email,
          name: values.name,
        };
        return updateMember(values.companyId, values.id, payload);
      }
    },
    {
      onSuccess: () => {
        toggleIsEdit();
        toastNotification({
          kind: 'success',
          title: words.updateMemberToastTitleSuccess,
          timeout: TIMEOUT,
          subtitle: words.updateMemberToastSubSuccess,
        });

        if (currentUser) {
          setCurrentUser({
            ...currentUser,
            roleName: selectedRole?.id || currentUser.roleName,
          });
        }
      },
      onError: () => {
        toastNotification({
          kind: 'error',
          title: words.error,
          timeout: TIMEOUT,
          subtitle: words.errorOccured,
        });
      },
    },
  );

  const handleFormSubmission = useCallback((values: UserProfile) => {
    saveChanges(values);
  }, []);

  const onOpenModal = () => {
    setOpenModal(true);
  };

  const handleChangeRole = useCallback(
    ({ selectedItem }: { selectedItem: Role }) => {
      let roleChangeErrorMessage = '';
      if (selectedItem.id !== memberData.roleName) {
        if (validatedMemberRole?.is_last_admin)
          roleChangeErrorMessage = words.editMemberRoleLastAdminError;
        else if (
          validatedMemberRole?.is_last_approver &&
          selectedItem.id === 'member'
        )
          roleChangeErrorMessage = words.editMemberRoleLastApproverError;
      }

      setSelectedRole(selectedItem);
      setRoleChangeError(roleChangeErrorMessage);
    },
    [memberData, validatedMemberRole],
  );

  const handleChangeGroup = useCallback(
    ({ selectedItem }: { selectedItem: Group }) => {
      setSelectedGroup(selectedItem);
    },
    [],
  );

  const { mutate: onChangePassword } = useMutation(
    async (values: {
      currentPassword: string;
      newPassword: string;
      confirmPassword: string;
    }) => {
      const { currentPassword, confirmPassword } = values;

      await verifyPassword(
        currentUser?.userId.toString() || '0',
        currentUser?.companyId.toString() || '0',
        currentPassword,
      );
      await changePassword(confirmPassword);
    },
    {
      onSuccess: () => {
        setOpenModal(false);
        setCurrentPasswordError('');
        toastNotification({
          kind: 'success',
          title: words.changedPasswordSuccessNotifTitle,
          timeout: TIMEOUT,
          subtitle: words.changePasswordSuccessNotifSubtitle,
        });
      },
      onError: (e: Error) => {
        if (e && e?.message) {
          setCurrentPasswordError(words.oldPasswordError);
        }
        toastNotification({
          kind: 'error',
          title: words.error,
          timeout: TIMEOUT,
          subtitle: words.errorOccured,
        });
      },
    },
  );

  const { mutate: verifyMemberEmailMutation } = useMutation(
    ([user_id, email]: [string, string]) => {
      return verifyEmail(user_id, email);
    },
  );

  const checkMemberEmail = useCallback(
    async (email: string): Promise<void> => {
      return new Promise((resolve, reject) => {
        verifyMemberEmailMutation(['0', email], {
          onSuccess: response => {
            const res = response as APIResponse;
            const passedEmail = email || '';

            if (passedEmail.trim() !== memberData.email) {
              // Prevent previous email from being verified
              setMemberEmailRes(res.error ? res.msg : '');
            } else {
              setMemberEmailRes('');
            }
            resolve();
          },
        });
      });
    },
    [memberData],
  );

  const handleEmailChange = (value: string) => {
    setEmail(value);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      emailVal &&
        emailVal.length > 1 &&
        (await EmailValidation.validate(emailVal)) &&
        (await checkMemberEmail(emailVal));
      setIsChangingEmail(false);
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [emailVal]);

  useEffect(() => {
    if (isEdit) {
      isEdit && window.addEventListener('beforeunload', promptUser);
    }
    return () => {
      isEdit && window.removeEventListener('beforeunload', promptUser);
    };
  }, [isEdit]);

  const isLoading =
    isFetchingMemberFieldsLookup || isFetchingUserProfile || isFetchingGroup;

  return (
    <>
      <ContentContainer>
        {isLoading ? (
          <PageLoader />
        ) : (
          <FormWrapper>
            <Formik
              enableReinitialize
              initialValues={memberInfo}
              validationSchema={UserProfileSchema}
              onSubmit={handleFormSubmission}>
              {({
                handleChange,
                handleSubmit,
                setFieldValue,
                setValues,
                values,
                errors,
              }): React.ReactElement => {
                return (
                  <ViewUserProfileForm
                    {...values}
                    setImage={setImage}
                    memberInfo={values}
                    previousValues={previousValues}
                    isUserImageLoading={isFetchingUserProfile}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    isEdit={isEdit}
                    errors={errors}
                    handleChangeRole={handleChangeRole}
                    handleChangeGroup={handleChangeGroup}
                    handleChangeEmail={(event: React.ChangeEvent) => {
                      setIsChangingEmail(true);
                      handleChange(event);
                      handleEmailChange(
                        (event.target as HTMLTextAreaElement).value,
                      );
                    }}
                    isChangingEmail={isChangingEmail}
                    selectedRole={selectedRole}
                    selectedGroup={selectedGroup}
                    memberEmailRes={
                      values.email?.length > 1 ? memberEmailRes : ''
                    }
                    roleChangeError={roleChangeError}
                    groupItems={groupItems}
                    onClickButton={
                      isEdit
                        ? handleSubmit
                        : () => {
                            setPreviousValues({
                              ...values,
                              roleName: selectedRole?.id || 'member',
                            });
                            toggleIsEdit();
                          }
                    }
                    onOpenChangePasswordModal={() => onOpenModal()}
                    onClickCancel={() => {
                      if (previousValues) {
                        setSelectedGroup(
                          groupItems.find(e => e.id === previousValues.groupId),
                        );
                        setSelectedRole(
                          data.memberRoles.find(
                            e => e.id === previousValues.roleName,
                          ),
                        );
                        setRoleChangeError('');
                        setMemberEmailRes('');
                        setValues(previousValues);
                      }
                      setIsEdit(false);
                    }}
                    toastNotification={toastNotification}
                    isLoading={
                      isFetchingUserProfile || isFetchingMemberFieldsLookup
                    }
                  />
                );
              }}
            </Formik>
          </FormWrapper>
        )}
      </ContentContainer>
      <ChangePasswordModal
        isOpen={openModal}
        onChangePassword={onChangePassword}
        onClose={() => {
          setCurrentPasswordError('');
          setOpenModal(false);
        }}
        currentPasswordError={currentPasswordError}
      />
      {notification ? <ToastNotification notification={notification} /> : null}
    </>
  );
};

export default Component;
