const colors = {
  black: '#000000',
  text01: '#161616',
  text02: '#525252',
  text03: '#a8a8a8',
  textLingOrange: '#FF714F',
  primaryButton1: '#FF714F',
  primaryButton2: '#D64D30',
  secondaryButton: '#DA1E28',
  disabledButton1: '#F5E7D8',
  disabledButton2: '#C7B29B',
  disabled01: '#F4F4F4',
  disabled02: '#c6c6c6',
  disabledBorder: '#E8E8E8',
  inputBorderColor: '#C7B29B',
  errorColor: '#DA1E28',
  chipColor: '#3B1F34',
  white: '#FFFFFF',
  ui01: '#FFF8EF',
  ui02: '#FFFFFF',
  ui03: '#FEC483',
  ui04: '#8D8D8D',
  fontColor: '#525252',
  dropDownContainerBgColor: '#FBF7F4',
  edit: '#0F62FE',
  ui05: '#161616',
  hoverui: '#FBF7F4',
  field01: '#FBF7F4',
  icon03: '#C7B29B',
  interactive02: '#3B1F34',
  interactive04: '#0F62FE',
  disabledToggle1: '#FFE2C1',
  disabledToggle2: '#ECDAC7',
  notificationSuccessBackgroundColor: '#DEFBE6',
  notificationErrorBackgroundColor: '#FFF1F1',
  notificationInfoBackgroundColor: '#EDF5FF',
  notificationWarningBackgroundColor: '#FFF8E1',
  support01: '#DA1E28',
  support02: '#24A148',
  support03: '#F1C21B',
  support04: '#0043CE',
  inverseSupport04: '#4589FF',
  icon01: '#161616',
  background: '#FFF8EF',
  blackText: '#000000',
  iconNotifHover: '#74616F',
  primaryButtonHover: '#E76647',
  secondaryButtonHover: '#BA1B23',
  tertiaryButtonHover: '#54394D',
  secondaryButtonActive: '#750E13',
  paginationDropdownHover: '#006BDE',
  paginationDropdownBackground: '#F0F0F0',
  paginationDropdownBorder: '#A3A5A7',
  multiselectOptionBg: '#F4F4F4',
  placeHolderText: '#6F6F6F',
  scrollHandleColor: '#F4F4F4',
  inverseSupport02: '#42BE65',
  inverseSupport01: '#FA4D56',
  anchorTagModal: '#78a9ff',
  rankingbgColor: 'rgba(36, 161, 72)',
  rankingHover: 'rgba(251, 247, 244)',
  anchorLinkColor: 'rgb(15, 98, 254)',
  uiBackground: '#FBF7F4',
  dropdownColor1: 'rgba(255, 255, 255)',
  listBorder: '#E0E0E0',
  firstRankingResult: '#FFE8BB',
  secondRankingResult: '#EBEBEB',
  thirdRankingResult: '#FFDABD',
  gold: '#E3A21F',
  silver: '#A3A4A8',
  bronze: '#DA9865',
  gray: '#8D8D8D',
  grayBgColor: '#F0F2F5',
  whiteLightColor: '#F5F5f5',
};

// Sample usage: @media ${theme.breakpoints.mobileAndTablet} { ...styling }
const breakpoints = {
  mobile: '(min-width: 320px) and (max-width: 576px)',
  tablet: '(min-width: 577px) and (max-width: 922px)',
  pc: '(min-width: 923px)',
  wideScreen: '(min-width: 1400px)',
  mobileAndTablet: '(min-width: 320px) and (max-width: 1055px)',
  landscape: {
    mobile: '(min-height: 320px) and (max-height: 576px)',
    tablet: '(min-height: 577px) and (max-height: 922px)',
    pc: '(min-height: 923)',
    mobileAndTablet: '(min-height: 320px) and (max-height: 1055px)',
  },
  smallMobileWidth: 377,
};

export default {
  colors,
  breakpoints,
};
