import { MonthSwiper } from 'components/molecules/MonthSwiper';
import { YearSwiper } from 'components/molecules/YearSwiper';
import React, { useState } from 'react';
import './dateScrollBarStyle.scss';

type PropType = {
  currentMonth: string;
  handleSetMonth: (_month: number) => void;
};

export default function DateScrollBar(props: PropType) {
  const { currentMonth, handleSetMonth } = props;
  return (
    <div className="dateScrollBarContainer">
      <YearSwiper />
      <MonthSwiper
        currentMonth={currentMonth}
        handleSetMonth={month => handleSetMonth(month)}
      />
    </div>
  );
}
