import React, { useState, useEffect } from 'react';
import './editAccountMemForm.scss';
import words from 'constants/words';
import {
  accountSettingErrType,
  listGroupOptionType,
  groupOptionType,
  memberGroupType,
  memberProfileUpdate,
} from 'type/member';
import Select from 'react-select';

type PropType = {
  handleUpdateValues: (object: memberProfileUpdate) => void;
  listError: accountSettingErrType;
  isSetCompanyId: boolean;
  userInfo: memberGroupType | null;
  listGroup: listGroupOptionType;
  groupChooseUser: groupOptionType;
  chooseUserData: memberGroupType | null;
  dataCurrentMemberPF: memberProfileUpdate;
};

export default function EditAccountMemForm(props: PropType) {
  const {
    handleUpdateValues,
    listError,
    isSetCompanyId,
    userInfo,
    listGroup,
    groupChooseUser,
    chooseUserData,
    dataCurrentMemberPF,
  } = props;
  const [listOption, setListOption] = useState<listGroupOptionType>([]);
  const [value, setValue] = useState<memberProfileUpdate>({
    full_name: '',
    group_id: '',
    role: '',
  });
  const [fnError, setFnError] = useState(false);

  const roleOptions = [
    { value: 'member', label: 'Applicant' },
    { value: 'approver', label: 'Approver' },
    { value: 'admin', label: 'Admin', isDisabled: true },
  ];

  function newValueUpdate(data: string, type: string) {
    setValue(prevState => ({ ...prevState, [type]: data.toString() }));
  }

  useEffect(() => {
    if (value) {
      value.full_name == '' ? setFnError(true) : setFnError(false);
      handleUpdateValues(value);
    }
  }, [value]);

  useEffect(() => {
    setValue(dataCurrentMemberPF);
  }, [dataCurrentMemberPF]);

  useEffect(() => {
    setListOption(listGroup);
  }, [listGroup]);

  return (
    <div className="form-account-setting">
      <table>
        <tbody>
          {/* Full name field */}
          <tr>
            <td colSpan={2}>
              <label
                className={
                  chooseUserData &&
                  chooseUserData.full_name &&
                  chooseUserData.full_name.length > 0
                    ? 'filled'
                    : 'not-filled'
                }>
                {words.fullNameRequired}
              </label>
              <br />
              <input
                className="input-signup"
                type="text"
                placeholder={words.fullNamePlaceholder}
                value={chooseUserData ? chooseUserData.full_name : ''}
                onChange={e => newValueUpdate(e.target.value, 'full_name')}
                required
                autoComplete="new-password"
              />
              {fnError ? (
                <div
                  style={{
                    color: 'red',
                    marginTop: '10px',
                    marginBottom: '10px',
                  }}>
                  FULL_NAME_IS_REQUIRED
                </div>
              ) : (
                ''
              )}
            </td>
          </tr>
          <tr>
            <td
              className={
                listError.full_name && listError.full_name.length > 0
                  ? 'errorMessage'
                  : 'noMessage'
              }
              colSpan={2}>
              {listError.full_name && listError.full_name.length > 0
                ? listError.full_name
                : ''}
            </td>
          </tr>
          {/* CompanyID & User ID Filed */}
          <tr>
            {/* CompanyID */}
            <td colSpan={1} className="colSpan1">
              <label
                className={
                  (chooseUserData &&
                    chooseUserData.company &&
                    chooseUserData.company.id.toString().length > 0) ||
                  isSetCompanyId
                    ? 'filled'
                    : 'not-filled'
                }>
                {words.companyId}
              </label>
              <br />
              <input
                className="input-signup"
                type="text"
                placeholder="111"
                value={
                  chooseUserData && chooseUserData.company
                    ? chooseUserData.company.id
                    : ''
                }
                onChange={e => newValueUpdate(e.target.value, 'company_id')}
                required
                autoComplete="new-password"
                disabled={isSetCompanyId ? true : false}
              />
            </td>
            {/* User ID */}
            <td colSpan={1} className="colSpan1">
              <label className="disabledLabel">{words.userId}</label>
              <br />
              <input
                className="input-signup"
                type="text"
                placeholder="11"
                value={chooseUserData ? chooseUserData.id : ''}
                onChange={e => newValueUpdate(e.target.value, 'user_id')}
                required
                autoComplete="new-password"
                disabled
              />
            </td>
          </tr>
          <tr>
            <td
              className={
                listError.company_id && listError.company_id.length > 0
                  ? 'errorMessageSplit'
                  : 'noMessage'
              }
              colSpan={1}>
              {listError.company_id && listError.company_id.length > 0
                ? listError.company_id
                : ''}
            </td>
            <td
              className={
                listError.user_id && listError.user_id.length > 0
                  ? 'errorMessageSplit'
                  : 'noMessage'
              }
              colSpan={1}>
              {listError.user_id && listError.user_id.length > 0
                ? listError.user_id
                : ''}
            </td>
          </tr>
          {/* Group Fields */}
          <tr>
            <td colSpan={2}>
              <label
                className={isSetCompanyId === false ? 'filled' : 'not-filled'}>
                {words.groupName}
              </label>
              <br />
              {groupChooseUser && groupChooseUser.value ? (
                <Select
                  value={listOption.filter(
                    option => option.value === groupChooseUser.value.toString(),
                  )}
                  defaultValue={listOption}
                  options={listGroup}
                  className="selectGroup"
                  classNamePrefix="select"
                  placeholder={
                    <span className="placeholder-option">
                      {words.groupName}
                    </span>
                  }
                  isSearchable={false}
                  isDisabled={!isSetCompanyId}
                  onChange={e => newValueUpdate(e!.value, 'group_id')}
                />
              ) : (
                '-'
              )}
            </td>
          </tr>
          <tr>
            <td
              className={
                listError.role && listError.role.length > 0
                  ? 'errorMessage'
                  : 'noMessage'
              }
              colSpan={2}>
              {listError.role && listError.role.length > 0
                ? listError.role
                : ''}
            </td>
          </tr>
          {/* Role field */}
          <tr>
            <td colSpan={2}>
              <label
                className={isSetCompanyId === false ? 'filled' : 'not-filled'}>
                {words.roleLabel}
              </label>
              <br />
              {chooseUserData && chooseUserData.role ? (
                <Select
                  value={roleOptions.filter(
                    option => option.value === chooseUserData.role,
                  )}
                  options={roleOptions}
                  className="roleOption"
                  classNamePrefix="select"
                  placeholder={
                    <span className="placeholder-option">
                      {words.roleLabel}
                    </span>
                  }
                  isSearchable={false}
                  isOptionDisabled={option => option.label === 'Admin'}
                  isDisabled={!isSetCompanyId}
                  onChange={e => newValueUpdate(e!.value, 'role')}
                />
              ) : (
                'Loading'
              )}
            </td>
          </tr>
          <tr>
            <td
              className={
                listError.role && listError.role.length > 0
                  ? 'errorMessage'
                  : 'noMessage'
              }
              colSpan={2}>
              {listError.role && listError.role.length > 0
                ? listError.role
                : ''}
            </td>
          </tr>
          {/* Email field */}
          <tr>
            <td colSpan={2}>
              <label className="disabledLabel">{words.emailAdd}</label>
              <br />
              <input
                className="input-signup"
                type="text"
                placeholder="emailuser@gmail.com"
                value={chooseUserData ? chooseUserData.email : ''}
                required
                autoComplete="new-password"
                disabled
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
