import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { LoginPage } from '../../components/pages/Login';
import { AdminFirstLogin } from '../../components/pages/AdminFirstLogin';
import { SetUpViaLogin } from '../../components/pages/SetUpViaLogin';
import { AdminSignUp } from '../../components/pages/AdminSignUp';
import { ApplicationSentPage } from '../../components/pages/ApplicationSent';
import { ForgetPassword } from '../../components/pages/ForgetPassword';
import { ResetPassword } from '../../components/pages/ResetPassword';
import { VerificationLinkExpired } from '../../components/pages/VerificationLinkExpired';
import { SignUpPage } from '../../components/pages/SignUp';
import { SignUpConfirm } from 'components/pages/SignUpConfirm';
import { MemberLogin } from 'components/pages/MemberLogin';
import { MemberForgotPass } from 'components/pages/MemberForgotPass';
import { MemberForgotInputPass } from 'components/pages/MemberForgotInputPass';
import { MemberProfile } from 'components/pages/MemberProfile';
import { InviteHandleMail } from 'components/pages/InviteHandleMail';

export const UnprotectedRoutes = () => (
  <Routes>
    <Route index element={<Navigate to="/sign-in" />} />
    <Route path="*" element={<Navigate to="/page-not-found" />} />
    <Route path="login" element={<LoginPage />} />
    <Route path="admin-signup" element={<AdminSignUp />} />
    <Route path="application-sent" element={<ApplicationSentPage />} />
    <Route path="setup-credentials/:pk" element={<AdminFirstLogin />} />
    <Route path="setup-credentials" element={<SetUpViaLogin />} />
    <Route path="forgot-password" element={<ForgetPassword />} />
    <Route path="reset-password/:pk/:token" element={<ResetPassword />} />
    <Route
      path="verification-link-expired"
      element={<VerificationLinkExpired />}
    />
    <Route path="sign-up" element={<SignUpPage />} />
    <Route path="sign-up-confirm" element={<SignUpConfirm />} />
    <Route path="sign-in" element={<MemberLogin />} />
    <Route path="forgot" element={<MemberForgotPass />} />
    <Route path="forgot-input" element={<MemberForgotInputPass />} />
    <Route path="login/callback" element={<MemberProfile />} />
    <Route path="member-profile" element={<Navigate to="/sign-in" />} />
    <Route path="member-list-settings" element={<Navigate to="/sign-in" />} />
    <Route path="handle-invite-list-email" element={<InviteHandleMail />} />
    <Route path="change-password" element={<Navigate to="/sign-in" />} />
  </Routes>
);
