import React, { useState } from 'react';
import CloseButton from 'assets/icons/close-button.svg';
import './confirmChangeModal.scss';
import words from 'constants/words';
import { OrangeButton } from 'components/atoms/OrangeButton';

type PropType = {
  isShow: boolean;
  onHideModal: () => void;
  onOk: () => void;
};

export default function ConfirmChangeModal(props: PropType) {
  const { isShow, onHideModal, onOk } = props;
  return (
    <div className={`confirm-change ${isShow ? 'show' : 'hide'}`}>
      <div className="modalTotal">確認</div>
      <div className="modal-content">
        このメンバーの情報を編集します。
        <br />
        よろしいですか？
      </div>
      <div className="btnBox">
        <div className="cancel-button" onClick={onHideModal}>
          キャンセル
        </div>
        <OrangeButton
          text="OK"
          style={{ width: '144px', height: '42px' }}
          onClick={onOk}
        />
      </div>
    </div>
  );
}
