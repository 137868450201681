import React, { useCallback, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useAuthHooks } from '../../../hooks/auth';
import { AdminSignUp } from '../../templates/AdminSignUp';
import { AdminSignUpValues } from '../../templates/AdminSignUp/AdminSignUp';

type Props = {};

type APIResponse = {
  error: boolean;
  company_email: string;
  memberEmail: string;
  id: string;
  username: string;
  company: string;
  representative: string;
  group: string;
  user_email: string;
  msg: string;
};

const Component = ({}: Props) => {
  const navigate = useNavigate();
  const { useSignUp } = useAuthHooks();
  const { adminSignUp, verifyEmail, verifyCompanyEmail } = useSignUp();

  const [companyEmailRes, setCompanyEmailRes] = useState<string>('');
  const [memberEmailRes, setMemberEmailRes] = useState<string>('');
  const [signupError, setSignupError] = useState<boolean>(false);

  const handleResponse = (values: APIResponse) => {
    if (values.error) {
      setCompanyEmailRes(values.company_email);
      setMemberEmailRes(values.user_email);
    } else {
      // navigate('/setup-credentials', { state: values });
      navigate('/application-sent', { state: { email: values.company_email } });
      window.scrollTo(0, 0);
    }
  };

  const { mutate: signUpMutation, isLoading } = useMutation(
    ([
      companyName,
      groupName,
      representativeName,
      companyEmail,
      representativeEmailAddress,
      companyTelephone,
    ]: [string, string, string, string, string, string]) => {
      return adminSignUp(
        companyName,
        groupName,
        representativeName,
        companyEmail,
        representativeEmailAddress,
        companyTelephone,
      );
    },
  );

  const { mutate: verifyEmailMutation } = useMutation(
    ([user_id, email]: [string, string]) => {
      return verifyEmail(user_id, email);
    },
  );

  const { mutate: verifyCompanyEmailMutation } = useMutation(
    ([email]: [string]) => {
      return verifyCompanyEmail(email);
    },
  );

  const onSignUp = useCallback((values: AdminSignUpValues): void => {
    const {
      companyName,
      groupName,
      representativeName,
      companyEmail,
      representativeEmailAddress,
      companyTelephone,
    } = values;

    setSignupError(false);
    signUpMutation(
      [
        companyName,
        groupName,
        representativeName,
        companyEmail,
        representativeEmailAddress,
        companyTelephone,
      ],
      {
        onError: () => {
          setSignupError(true);
        },
        onSuccess: response => {
          handleResponse(response as APIResponse);
        },
      },
    );
  }, []);

  const checkRepEmail = useCallback((email: string): void => {
    setSignupError(false);
    verifyEmailMutation(['0', email], {
      onSuccess: response => {
        const res = response as APIResponse;
        setMemberEmailRes(res.error ? res.msg : '');
      },
    });
  }, []);

  const checkCompanyEmail = useCallback((email: string): void => {
    setSignupError(false);
    verifyCompanyEmailMutation([email], {
      onError: response => {
        const res = response as APIResponse;
        setCompanyEmailRes(res.error ? res.msg : '');
      },
      onSuccess: response => {
        const res = response as APIResponse;
        setCompanyEmailRes(res.error ? res.msg : '');
      },
    });
  }, []);

  useEffect(() => {
    window.addEventListener('beforeunload', promptUser);
    return () => {
      window.removeEventListener('beforeunload', promptUser);
    };
  });

  const promptUser = (event: any) => {
    event.preventDefault();
    event.returnValue = '';
  };

  const redirectLogin = () => {
    navigate('/login');
  };

  return (
    <AdminSignUp
      register={onSignUp}
      handleLoginRedirect={redirectLogin}
      companyEmailRes={companyEmailRes}
      memberEmailRes={memberEmailRes}
      signupError={signupError}
      checkRepEmail={checkRepEmail}
      checkCompEmail={checkCompanyEmail}
      isLoading={isLoading}
    />
  );
};

export default Component;
