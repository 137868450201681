import { useCallback } from 'react';
import { EntryHooks } from '.';
import { useDependencies } from '..';
import {
  EntryApplicationForm,
  CreateEntryApplication,
} from '../../domain/entities/entry';

export const useFetchEntry: EntryHooks['useFetchEntry'] = (): {
  fetchFormFieldsApprovers: (
    companyId: number,
    memberId: number,
    rankingId: number,
  ) => Promise<EntryApplicationForm | undefined>;
} => {
  const { entryInteractor } = useDependencies();

  const fetchFormFieldsApprovers = useCallback(
    async (companyId: number, memberId: number, rankingId: number) => {
      const response = await entryInteractor.fetchFormFieldsApprovers(
        companyId,
        memberId,
        rankingId,
      );

      return response;
    },
    [],
  );

  return { fetchFormFieldsApprovers };
};

export const useCreateEntry: EntryHooks['useCreateEntry'] = (): {
  createEntryApplication(
    companyId: number,
    memberId: number,
    rankingId: number,
    payload: CreateEntryApplication,
  ): Promise<Response>;
} => {
  const { entryInteractor } = useDependencies();

  const createEntryApplication = useCallback(
    async (
      companyId: number,
      memberId: number,
      rankingId: number,
      payload: CreateEntryApplication,
    ) => {
      const response = await entryInteractor.createEntryApplication(
        companyId,
        memberId,
        rankingId,
        payload,
      );

      return response;
    },
    [],
  );

  return { createEntryApplication };
};

export const useUpdateEntry: EntryHooks['useUpdateEntry'] = (): {
  updateEntryApplication(
    companyId: number,
    memberId: number,
    rankingId: number,
    entryId: number,
    payload: CreateEntryApplication,
  ): Promise<Response>;
} => {
  const { entryInteractor } = useDependencies();

  const updateEntryApplication = useCallback(
    async (
      companyId: number,
      memberId: number,
      rankingId: number,
      entryId: number,
      payload: CreateEntryApplication,
    ) => {
      const response = await entryInteractor.updateEntryApplication(
        companyId,
        memberId,
        rankingId,
        entryId,
        payload,
      );

      return response;
    },
    [],
  );

  return { updateEntryApplication };
};

export const useDeleteEntry: EntryHooks['useDeleteEntry'] = (): {
  deleteEntryApplication(
    companyId: number,
    memberId: number,
    rankingId: number,
    entryId: number,
  ): Promise<void>;
} => {
  const { entryInteractor } = useDependencies();

  const deleteEntryApplication = useCallback(
    async (
      companyId: number,
      memberId: number,
      rankingId: number,
      entryId: number,
    ) => {
      await entryInteractor.deleteEntryApplication(
        companyId,
        memberId,
        rankingId,
        entryId,
      );
    },
    [],
  );

  return { deleteEntryApplication };
};
