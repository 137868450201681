import { Member, CreateMemberType } from '../../../domain/entities/member';
import MemberRepository from '../../ports/MemberRepository';

export type createMemberUseCase = (
  params: CreateMemberType,
) => Promise<Member & { temporary_password: string }>;

export const buildCreateMember = (dependencies: {
  memberRepo: MemberRepository;
}): createMemberUseCase => {
  const { memberRepo } = dependencies;

  const createMember: createMemberUseCase = async params =>
    memberRepo.create(params);

  return createMember;
};
