import React, { useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import words from '../../constants/words';
import { Homepage } from '../../components/pages/Homepage';
import { GroupsPage } from '../../components/pages/Groups';
import { MembersPage } from '../../components/pages/Members';
import { useGlobalState } from '../../hooks/global';
import { RibbonTabs } from '../../components/organisms/RibbonTabs';
import { LoginSettingPage } from '../../components/pages/LoginSetting';
import { CompanyPage } from '../../components/pages/Company';
import { ViewMemberPage } from '../../components/pages/ViewMember';
import { RankingListPage } from '../../components/pages/RankingList';
import { SetRankingPage } from '../../components/pages/SetRanking';
import { RecordApplicationPage } from '../../components/pages/RecordApplication';
import { RankingApplicationListPage } from '../../components/pages/RankingApplicationList';
import { EditRankingMember } from '../../components/pages/EditRankingMember';
import { EditBasicInfo } from '../../components/pages/EditBasicInfo';
import { EditRankingPeriod } from '../../components/pages/EditRankingPeriod';
import { RecordReferenceListPage } from '../../components/pages/RecordReferenceList';
import { RankingTable } from '../../components/pages/RankingTable';
import { RankingDetailsPage } from '../../components/pages/RankingDetails';
import { RecordApprovalsPage } from '../../components/pages/RecordApproval';
import { RecordReferenceDetails } from '../../components/pages/RecordReferenceDetails';
import { AccountConfig } from '../../components/pages/AccountConfig';
import { NotificationPage } from '../../components/pages/Notification';
import { RecordApprovalFormPage } from '../../components/pages/RecordApprovalForm';
import { useQuery } from 'react-query';
import { useMemberHooks } from '../../hooks/member';
import { StateButton } from '../../components/molecules/StateButton';
import { DateScrollBar } from '../../components/organisms/DateScrollBar';
import { SPHTemplate } from '../../components/templates/SPH';
import { MemberProfile } from 'components/pages/MemberProfile';
import MemberAccountSetting from 'components/pages/MemberAccountSetting/MemberAccountSetting';
import { ApproveChangeInfo } from 'components/pages/\bApproveChangeInfo';
import { MemberListSetting } from 'components/pages/MemberListSetting';
import { InviteHandleMail } from 'components/pages/InviteHandleMail';

type UserTypes = 'flatadmin' | 'admin' | 'approver' | 'member';

const NAV_ITEMS = [
  {
    label: words.home,
    to: '/',
    onPress: () => {},
  },
  {
    label: words.record,
    onPress: () => {},
    subroutes: [
      {
        label: words.recordApplication,
        to: '/application',
      },
      {
        label: words.recordReference,
        to: '/reference',
      },
      {
        label: words.recordApproval,
        to: '/approval',
      },
    ],
  },
  {
    label: words.ranking,
    subroutes: [
      {
        label: words.rankingTable,
        to: '/ranking-table',
      },
      // {
      //   label: words.graph,
      //   to: '/graph',
      // },
    ],
  },
  {
    label: words.manageRanking,
    subroutes: [
      {
        label: words.rankingList,
        to: '/ranking-list',
      },
    ],
  },
  {
    label: words.config,
    onPress: () => {},
    subroutes: [
      {
        label: words.groupManagement,
        to: '/groups',
      },
      {
        label: words.memberManagement,
        to: '/members',
      },
      {
        label: words.companyInfo,
        to: '/company',
      },
      {
        label: words.accountConfig,
        to: '/account-info',
      },
      {
        label: 'NEW COMPONENT',
        to: '/new-comp',
      },
    ],
  },
];

// Page Restriction based on User Type, append/update accordingly
const UserRestrictions = {
  flatadmin: {
    pageRestrictions: [''],
    sideBar: [''],
  },
  admin: {
    pageRestrictions: [''],
    sideBar: [''],
  },
  approver: {
    pageRestrictions: ['company-info', 'graph'],
    sideBar: [words.graph, words.companyInfo],
  },
  member: {
    pageRestrictions: [
      'company-info',
      'graph',
      'ranking-list',
      'edit-basic-info',
      'groups',
      'members',
      'approval',
    ],
    sideBar: [
      words.manageRanking,
      words.companyInfo,
      words.graph,
      words.groupManagement,
      words.memberManagement,
      words.recordApproval,
    ],
  },
};

const ProtectedPath = ({
  pathName,
  children,
}: {
  pathName: string;
  children: React.ReactElement;
}): React.ReactElement => {
  const {
    useCurrentUser: { currentUser },
  } = useGlobalState();

  if (
    currentUser?.roleName &&
    UserRestrictions[currentUser?.roleName].pageRestrictions.includes(pathName)
  ) {
    return <Navigate to="/home" />;
  }
  return children;
};

export const ProtectedRoutes = () => {
  const {
    useCurrentUser: { currentUser },
  } = useGlobalState();

  const role = currentUser?.roleName as UserTypes;
  const navItem =
    role !== undefined
      ? [...NAV_ITEMS]
          .filter(
            route => !UserRestrictions[role].sideBar.includes(route.label),
          )
          .map(obj => {
            return {
              ...obj,
              subroutes: obj?.subroutes
                ? obj?.subroutes?.filter(
                    subRoute =>
                      !UserRestrictions[role].sideBar.includes(subRoute.label),
                  )
                : [],
            };
          })
      : NAV_ITEMS;
  const { useFetchMembers } = useMemberHooks();
  const { fetchMember } = useFetchMembers();
  const [newProfileImage, setNewProfileImage] = useState<string | undefined>(
    '',
  );
  const companyId = currentUser?.companyId;
  const memberId = currentUser?.memberId;
  const {
    data: userProfileImage,
    isFetching: isFetchingProfileImage,
  } = useQuery({
    queryKey: ['member-data', companyId, memberId, newProfileImage],
    queryFn: async () => {
      if (companyId && memberId) {
        const memberData = await fetchMember({
          companyId,
          memberId: Number(memberId),
        });
        return memberData?.photoUrl;
      } else {
        console.info('query param not set');
        // throw new Error(`query param not set`);
      }
    },
    refetchOnMount: true,
  });
  const setImage = (src: string | undefined) => {
    setNewProfileImage(src);
  };

  return (
    <RibbonTabs
      navItems={navItem}
      profileImage={userProfileImage}
      isFetchingProfileImage={isFetchingProfileImage}>
      <Routes>
        <Route index element={<Navigate to="/home" />} />
        <Route path="login" element={<Navigate to="/" />} />
        <Route path="*" element={<Navigate to="/page-not-found" />} />
        <Route path="home" element={<Homepage />} />
        <Route
          path="groups"
          element={
            <ProtectedPath pathName={'groups'}>
              <GroupsPage />
            </ProtectedPath>
          }
        />
        <Route path="members">
          <Route
            path=""
            element={
              <ProtectedPath pathName={'members'}>
                <MembersPage />
              </ProtectedPath>
            }
          />
          <Route path="setting" element={<LoginSettingPage />} />
          <Route path=":id" element={<ViewMemberPage setImage={setImage} />} />
          <Route
            path=":id/edit"
            element={<ViewMemberPage isEdit setImage={setImage} />}
          />
        </Route>
        <Route
          path="company"
          element={
            <ProtectedPath pathName={'company-info'}>
              <CompanyPage />
            </ProtectedPath>
          }
        />
        <Route path="ranking-list">
          <Route
            path=""
            element={
              <ProtectedPath pathName={'ranking-list'}>
                <RankingListPage />
              </ProtectedPath>
            }
          />
          <Route path="create" element={<SetRankingPage />} />
          <Route path="member/:id/edit" element={<EditRankingMember />} />
          <Route path="ranking-details/:id" element={<RankingDetailsPage />} />
          <Route path="edit-period/:id" element={<EditRankingPeriod />} />
          <Route
            path="edit-basicinfo"
            element={
              <ProtectedPath pathName={'edit-basic-info'}>
                <EditBasicInfo />
              </ProtectedPath>
            }
          />
        </Route>
        {/*this is an absolute routes for the redirection from the Ranking Details Page  */}
        <Route path="/edit-basicinfo" element={<EditBasicInfo />} />
        <Route path="/member/:id/edit" element={<EditRankingMember />} />
        <Route path="/edit-period/:id" element={<EditRankingPeriod />} />
        <Route path="/ranking-details/:id" element={<RankingDetailsPage />} />
        <Route path="application">
          <Route path="" element={<RankingApplicationListPage />} />
          <Route path=":rankingId/create" element={<RecordApplicationPage />} />
        </Route>
        <Route path="reference">
          <Route path="" element={<RecordReferenceListPage />} />
          <Route
            path="ranking/:rankingId/entry/:id"
            element={<RecordReferenceDetails />}
          />
          <Route path=":id" element={<RecordReferenceDetails />} />
        </Route>
        <Route path="ranking-table">
          <Route path="" element={<RankingTable />} />
        </Route>
        <Route path="approval">
          <Route
            path=""
            element={
              <ProtectedPath pathName={'approval'}>
                <RecordApprovalsPage />
              </ProtectedPath>
            }
          />
          <Route
            path=":recordId/ranking/:rankingId/member/:memberId"
            element={<RecordApprovalFormPage />}
          />
        </Route>
        <Route
          path="account-info"
          element={<AccountConfig setImage={setImage} />}
        />
        <Route path="sph" element={<SPHTemplate />} />
        <Route path="notifications" element={<NotificationPage />} />
        {/* New */}
        <Route path="sign-up" element={<Navigate to="/" />} />
        <Route path="sign-in" element={<Navigate to="/" />} />
        <Route path="sign-up-confirm" element={<Navigate to="/" />} />
        <Route path="forgot" element={<Navigate to="/" />} />
        <Route path="forgot-input" element={<Navigate to="/" />} />
        <Route path="member-profile" element={<MemberProfile />} />
        <Route path="account-setting" element={<MemberAccountSetting />} />
        <Route path="confirmed-info-change" element={<ApproveChangeInfo />} />
        <Route path="member-list-settings" element={<MemberListSetting />} />
        <Route path="handle-invite-list-email" element={<InviteHandleMail />} />
      </Routes>
    </RibbonTabs>
  );
};
