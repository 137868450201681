import React, { useCallback, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router';
import { useSignUp } from '../../../hooks/auth/hooks';
import { useCompanyHooks } from '../../../hooks/company';
import { VerificationLinkExpired } from '../../templates/VerificationLinkExpired';

type Props = {};

type ResponseVal = {
  message: string;
};

const Component = ({}: Props): React.ReactElement => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showErrorNotif, setShowErrorNotif] = useState(true);
  const [emailSentSuccess, setEmailSentSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isCompanyEmail, setIsCompanyEmail] = useState(false);

  const { adminEmailVerification } = useSignUp();
  const { useVerifyCompanyEmail } = useCompanyHooks();
  const { resendCompanyEmailVerification } = useVerifyCompanyEmail();

  const { mutate: adminEmailVerificationMutation } = useMutation(
    ([email]: [string]) => {
      return adminEmailVerification(email);
    },
  );

  const { mutate: resendCompanyEmailVerificationMutation } = useMutation(
    ([email]: [string]) => {
      return resendCompanyEmailVerification(email);
    },
  );

  const checkEmail = useCallback(
    (data: { email: string }, resetForm?: () => void): void => {
      const { email } = data;
      setIsLoading(true);
      adminEmailVerificationMutation([email], {
        onSuccess: response => {
          const res = response as ResponseVal;
          if (res.message) {
            setEmailSentSuccess(true);
            setShowErrorNotif(false);
            resetForm && resetForm();
          }
        },
        onError: () => {
          setEmailSentSuccess(false);
          setShowErrorNotif(true);
        },
        onSettled: () => {
          return setIsLoading(false);
        },
      });
    },
    [],
  );

  const checkCompanyEmail = useCallback(
    (data: { email: string }, resetForm?: () => void): void => {
      const { email } = data;
      setIsLoading(true);
      resendCompanyEmailVerificationMutation([email], {
        onSuccess: () => {
          setEmailSentSuccess(true);
          setShowErrorNotif(false);
          resetForm && resetForm();
        },
        onError: () => {
          setEmailSentSuccess(false);
          setShowErrorNotif(true);
        },
        onSettled: () => {
          return setIsLoading(false);
        },
      });
    },
    [],
  );

  const backToLogin = () => {
    return navigate('/login');
  };

  const onCloseNotif = () => {
    setEmailSentSuccess(false);
    setShowErrorNotif(false);
  };

  useEffect(() => {
    const prevRoute = location.state?.from;
    !prevRoute.includes('/setup-credentials/') ||
      (!prevRoute.includes('/company-email-verification/') && backToLogin());

    if (prevRoute.includes('/company-email-verification/')) {
      setIsCompanyEmail(true);
    }
  }, []);

  return (
    <>
      <VerificationLinkExpired
        onClickSubmit={isCompanyEmail ? checkCompanyEmail : checkEmail} // check here if it is from company email
        onPressBackToLogin={backToLogin}
        showErrorNotif={showErrorNotif}
        emailSentSuccess={emailSentSuccess}
        isLoading={isLoading}
        onCloseNotif={onCloseNotif}
        isCompanyEmail={isCompanyEmail}
      />
    </>
  );
};

export default Component;
