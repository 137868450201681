import React, { ChangeEvent, useState } from 'react';
import styled from 'styled-components';
import { Modal } from '../../molecules/Modal';
import { TextInput } from '../../atoms/TextInput';
import { MultiSelectDropdown } from '../../organisms/MultiSelectDropdown';
import { IOptions } from '../../organisms/MultiSelectDropdown/MultiSelectDropdown';
import { PropsValue } from 'react-select';
import { Formik } from 'formik';
import { GroupModalSchema } from '../Login/validation';
import { EditGroupData } from '../GroupList/GroupList';
import words from '../../../constants/words';

const InputContainer = styled.div`
  &&&& {
    margin-bottom: 16px;
    margin-top: 14px;
  }
`;
const MultiSelectContainer = styled.div`
  &&&& {
    margin-bottom: 38px;

    svg.errorIcon {
      width: 16px;
      position: relative;
      top: -35px;
      float: right;
      right: 35px;
    }
  }
`;

const StyledModal = styled(Modal)<{
  $isModalOpen: boolean;
}>`
  &&&& {
    display: ${({ $isModalOpen }) => ($isModalOpen ? '' : 'none')};
  }
`;

export type IGroupModal = {
  open: boolean;
  title: string;
  confirmButtonText: string;
  label: string;
  idInput: string;
  invalidTextInput?: string;
  labelInput?: string;
  placeholderInput?: string;
  options: IOptions[];
  invalidInput?: boolean;
  invalidMultiSelect?: boolean;
  labelMultiSelect?: string;
  placeholderMultiSelect?: string;
  errorMessageMultiSelect?: string;
  disableInput?: boolean;
  isCheckingDuplicate?: boolean;
  disableMultiSelect?: boolean;
  defaultValue?: PropsValue<IOptions>;
  approverValue?: IOptions[];
  isLoading?: boolean;
  isEditMode?: boolean;
  textValue?: string;
  editGroupData?: EditGroupData;
  onClose: () => void;
  onCancel: () => void;
  onSubmitSetGroupModal: (values: object) => void;
  onChangeNameInput: (
    name: string,
    groupData: EditGroupData | undefined,
  ) => void;
};

const Component = ({
  open,
  title,
  confirmButtonText,
  label,
  idInput,
  labelInput,
  placeholderInput,
  options,
  disableInput = false,
  isCheckingDuplicate,
  disableMultiSelect = false,
  isLoading = false,
  labelMultiSelect,
  placeholderMultiSelect,
  textValue,
  approverValue,
  invalidInput = false,
  invalidTextInput = '',
  editGroupData,
  isEditMode = false,
  onClose,
  onCancel,
  onSubmitSetGroupModal,
  onChangeNameInput,
}: IGroupModal): React.ReactElement => {
  const initialValues = {
    name: textValue ? textValue : '',
    approvers: approverValue && approverValue.length > 0 ? approverValue : [],
  };
  const [disableOnEdit, setDisableOnEdit] = useState<boolean>(isEditMode);
  const [errorMessage, seterrorMessage] = useState<string>('');

  return (
    <>
      <Formik
        validateOnMount
        initialValues={initialValues}
        validationSchema={GroupModalSchema}
        enableReinitialize
        onSubmit={(values, { resetForm, validateForm }) => {
          onSubmitSetGroupModal(values);
          resetForm();
          validateForm({
            name: '',
            approvers: [],
          });
        }}>
        {({
          handleSubmit,
          setFieldValue,
          errors,
          resetForm,
          validateForm,
          ...props
        }): React.ReactElement => {
          const handleInputChange = (event: ChangeEvent) => {
            const target = event.target as HTMLInputElement;
            onChangeNameInput(target.value, editGroupData);
            setDisableOnEdit(false);
            setFieldValue('name', target.value, true);
          };

          const handleMultiSelectChange = (event: IOptions[]) => {
            if (event.length > 0 || idInput === 'GroupNameAdd') {
              setFieldValue('approvers', event, true);
              setDisableOnEdit(false);
              seterrorMessage('');
            } else {
              seterrorMessage(words.errorMessageMultiSelect);
            }
          };

          const handleOnClose = () => {
            resetForm();
            validateForm({
              name: isEditMode ? props.values.name : '',
              approvers: isEditMode ? props.values.approvers : [],
            });
            onClose();
            setDisableOnEdit(true);
            seterrorMessage('');
          };

          const handleOnCancel = () => {
            resetForm();
            validateForm({
              name: isEditMode ? props.values.name : '',
              approvers: isEditMode ? props.values.approvers : [],
            });
            onCancel();
            setDisableOnEdit(true);
            seterrorMessage('');
          };

          const handleGroupSubmit = () => {
            seterrorMessage('');
            setDisableOnEdit(true);
            handleSubmit();
          };

          return (
            <StyledModal
              $isModalOpen={open}
              label={label}
              open={open}
              title={title}
              confirmButtonText={confirmButtonText}
              hasScrollingContent={false}
              confirmButtonDisabled={
                !!Object.keys(errors).length ||
                invalidInput ||
                disableOnEdit ||
                isCheckingDuplicate // isValid in formik returns an unstable response so i used error instead see: https://github.com/jaredpalmer/formik/issues/1116
              }
              onConfirm={handleGroupSubmit}
              onClose={handleOnClose}
              onCancel={handleOnCancel}>
              <InputContainer>
                <TextInput
                  id={idInput}
                  label={labelInput}
                  onChange={e => handleInputChange(e)}
                  placeholder={placeholderInput}
                  disabled={disableInput}
                  invalid={invalidInput}
                  invalidText={invalidTextInput}
                  value={props.values.name}
                />
              </InputContainer>
              <MultiSelectContainer>
                <MultiSelectDropdown
                  value={props.values.approvers}
                  label={labelMultiSelect}
                  placeholder={placeholderMultiSelect}
                  options={options}
                  onChange={e => handleMultiSelectChange(e as IOptions[])}
                  isDisabled={disableMultiSelect}
                  isLoading={isLoading}
                  errorMessage={errorMessage}
                  isError={errorMessage !== ''}
                  noOptionsText={words.noApproverFound}
                />
              </MultiSelectContainer>
            </StyledModal>
          );
        }}
      </Formik>
    </>
  );
};

export default Component;
