import { FetchRecordApprovalsRankingNamesParams } from '../../../domain/entities/record';
import RecordRepository from '../../ports/RecordRepository';

export type fetchRecordApprovalsRankingNamesUseCase = (
  query: FetchRecordApprovalsRankingNamesParams,
) => Promise<{ results: string[] }>;

export const buildFetchRecordApprovalsRankingNames = (dependencies: {
  recordRepo: RecordRepository;
}): fetchRecordApprovalsRankingNamesUseCase => {
  const { recordRepo } = dependencies;

  const fetchRecordApprovalsRankingNames: fetchRecordApprovalsRankingNamesUseCase = async query =>
    recordRepo.fetchRecordApprovalsRankingNames(query);

  return fetchRecordApprovalsRankingNames;
};
