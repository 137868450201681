import React from 'react';

type Props = {
  [key: string]: string | number | Function;
};

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M12 0C5.38255 0 0 5.38255 0 12C0 18.6175 5.38255 24 12 24C18.6153 24 24 18.6175 24 12C24 5.38255 18.6153 0 12 0ZM18.3076 10.3025L16.2349 12.8989L16.4618 16.2916C16.488 16.6691 16.3156 17.0335 16.0036 17.2538C15.8138 17.3869 15.5935 17.4545 15.3709 17.4545C15.2269 17.4545 15.0807 17.4262 14.9455 17.3673L12 16.1171L9.05673 17.3673C8.70764 17.5156 8.30618 17.4742 7.99855 17.2538C7.68873 17.0335 7.51636 16.6691 7.54036 16.2916L7.76727 12.8989L5.69455 10.3025C5.46327 10.0145 5.39564 9.62836 5.51127 9.27709C5.62691 8.92582 5.91273 8.65964 6.27055 8.56582L9.36436 7.75636L11.0618 4.89818C11.4545 4.23491 12.5455 4.23491 12.936 4.89818L14.6335 7.75636L17.7295 8.56582C18.0851 8.65964 18.3709 8.928 18.4887 9.27709C18.6065 9.62618 18.5367 10.0124 18.3076 10.3025Z"
        fill="#A3A4A8"
      />
    </svg>
  );
};

export default Component;
