import React, { ChangeEvent, useState, useEffect } from 'react';
import styled from 'styled-components';
import { theme } from '../../../config';
import words from '../../../constants/words';

import { AnchorTag } from '../../atoms/AnchorTag';
import { Button } from '../../atoms/Button';
import { Notification } from '../../molecules/Notification';
import { TextInput } from '../../atoms/TextInput';

const MainWrapper = styled.div`
  display: flex;
  margin: 0px;
  padding: 0px;
  flex-direction: column;
`;

const IdFieldsWrapper = styled.div`
  @media ${theme.breakpoints.pc} {
    display: flex;
    align-items: center;
    column-gap: 5%;
    justify-content: space-between;
    padding-bottom: 4%;
  }
`;

const FieldWrapper = styled.div`
  width: 100%;
  @media ${theme.breakpoints.tablet} {
    margin-bottom: 5%;
  }
  @media ${theme.breakpoints.mobile} {
    margin-bottom: 3%;
  }
`;

const ForgetPassWrapper = styled.div`
  width: 100%;
  @media ${theme.breakpoints.mobileAndTablet} {
    margin-top: 0;
  }
`;

const ToastNotifWrapper = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  margin-right: 9px;

  @media ${theme.breakpoints.mobileAndTablet} {
    margin: 0;
    .bx--toast-notification:first-child {
      margin: 8px 10px 0;
    }
  }
`;

const NotifWrapper = styled.div`
  margin-top: 3%;
  @media ${theme.breakpoints.mobileAndTablet} {
    margin-top: 4%;
  }
`;

const BottomWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 8%;
  @media ${theme.breakpoints.mobileAndTablet} {
    flex-direction: column-reverse;
    margin-top: 4%;
  }
`;

const NoteWrapper = styled.div`
  display: flex;
  @media ${theme.breakpoints.pc} {
    justify-content: start;
  }
  @media ${theme.breakpoints.mobileAndTablet} {
    margin-top: 8%;
    white-space: nowrap;
  }
`;

const Note = styled.div`
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.16px;
  color: ${theme.colors.interactive02};
  padding-right: 2px;
`;

const ButtonWrapper = styled.div`
  width: 141px;
  height: 38px;
  @media ${theme.breakpoints.mobileAndTablet} {
    width: 100%;
  }
`;

export type LoginFormProps = {
  companyId?: string;
  userId?: string;
  password?: string;
  onChangeCompanyId: (event: ChangeEvent) => void;
  onChangeUserId: (event: ChangeEvent) => void;
  onChangePassword: (event: ChangeEvent) => void;
  onPressLogin: () => void;
  onPressForgotPass: () => void;
  onPressRegister: () => void;
  companyIdError?: string;
  userIdError?: string;
  passwordError?: string;
  loginError?: unknown;
  signUpSuccess?: boolean;
  resetPasswordStatus?: string;
};

const Component = ({
  companyId,
  userId,
  password,
  onChangeCompanyId,
  onChangeUserId,
  onChangePassword,
  onPressLogin,
  onPressForgotPass,
  onPressRegister,
  companyIdError,
  userIdError,
  passwordError,
  loginError,
  signUpSuccess,
  resetPasswordStatus,
}: LoginFormProps): React.ReactElement => {
  const [showLoginError, setShowLoginError] = useState(false);
  const [signUpSuccessAdmin, setSignUpSuccess] = useState(false);
  const [resetPasswordStatusAdmin, setResetPasswordStatus] = useState('');

  useEffect(() => {
    if (loginError) {
      setShowLoginError(true);
    }
  }, [loginError]);

  useEffect(() => {
    if (signUpSuccess) {
      setSignUpSuccess(true);
    }
  }, [signUpSuccess]);

  useEffect(() => {
    if (resetPasswordStatus) {
      setResetPasswordStatus(resetPasswordStatus);
    }
  }, [resetPasswordStatus]);

  const handleClose = () => {
    setShowLoginError(false);
    setSignUpSuccess(false);
    setResetPasswordStatus('');
    return false;
  };

  return (
    <MainWrapper>
      {resetPasswordStatusAdmin ? (
        resetPasswordStatusAdmin === 'success' ? (
          <ToastNotifWrapper>
            <Notification
              kind="success"
              title={words.changedPasswordSuccessNotifTitle}
              subtitle={words.changedPassword}
              handleClose={handleClose}
              timeout={5000}
            />
          </ToastNotifWrapper>
        ) : (
          <ToastNotifWrapper>
            <Notification
              kind="error"
              title={words.error}
              subtitle={words.errorOccuredToastSub}
              handleClose={handleClose}
              timeout={5000}
            />
          </ToastNotifWrapper>
        )
      ) : null}

      {signUpSuccessAdmin && (
        <ToastNotifWrapper>
          <Notification
            kind="success"
            title={words.accountCreated}
            subtitle={words.successfullyCreated}
            handleClose={handleClose}
            timeout={5000}
          />
        </ToastNotifWrapper>
      )}
      <IdFieldsWrapper>
        <FieldWrapper>
          <TextInput
            value={companyId}
            onChange={onChangeCompanyId}
            label={`${words.companyId}\u00A0\u00A0${words.requiredLabel}`}
            id="companyId"
            invalid={!!companyIdError}
            invalidText={companyIdError}
          />
        </FieldWrapper>
        <FieldWrapper>
          <TextInput
            value={userId}
            onChange={onChangeUserId}
            label={`${words.userId}\u00A0\u00A0${words.requiredLabel}`}
            id="userId"
            invalid={!!userIdError}
            invalidText={userIdError}
          />
        </FieldWrapper>
      </IdFieldsWrapper>
      <TextInput
        value={password}
        onChange={onChangePassword}
        label={`${words.password}\u00A0\u00A0${words.requiredLabel}`}
        id="password"
        showPasswordLabel={words.showPassword}
        hidePasswordLabel={words.hidePassword}
        type="password"
      />
      <ForgetPassWrapper>
        <AnchorTag
          onPress={onPressForgotPass}
          title={words.forgotPassword}
          style={{ float: 'right' }}
        />
      </ForgetPassWrapper>
      {showLoginError && (
        <NotifWrapper>
          <Notification
            kind="error"
            title={words.loginErrorTitle}
            subtitle={words.loginError}
            handleClose={handleClose}
            inline
          />
        </NotifWrapper>
      )}
      <BottomWrapper>
        <NoteWrapper>
          <Note>{words.noAccountYet}</Note>
          <AnchorTag onPress={onPressRegister} title={words.registerHere} />
        </NoteWrapper>
        <ButtonWrapper>
          <Button
            onPress={onPressLogin}
            title={words.login}
            disabled={
              !!userIdError ||
              !!companyIdError ||
              !!passwordError ||
              !!!companyId ||
              !!!userId ||
              !!!password
            }
          />
        </ButtonWrapper>
      </BottomWrapper>
    </MainWrapper>
  );
};

export default Component;
