import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import words from '../../../constants/words';
import { TIMEOUT } from '../../../constants/timeout';

import { SubHeader } from '../../atoms/Subheader';
import { RankingAppList } from '../../templates/RankingAppList';
import { ToastNotification } from '../../molecules/Notification';
import { Props as INotification } from '../../molecules/Notification/Notification';
import { clearRankingFormPersistedData } from '../../templates/RecordApplication/RecordApplication';

type Props = {};

const Component = ({}: Props): React.ReactElement => {
  const navigate = useNavigate();
  const { state: locationState } = useLocation();

  const [notification, setNotification] = useState<INotification | null>(
    locationState?.toastMessage
      ? {
          ...locationState?.toastMessage,
          timeout: TIMEOUT,
          handleClose: () => {
            setNotification(null);
            return false;
          },
        }
      : null,
  );

  const onClickRecordNewApp = (rankingId: number) => {
    window.scrollTo(0, 0);
    navigate(`/application/${rankingId}/create`);
    clearRankingFormPersistedData();
  };

  return (
    <>
      <SubHeader title={words.rankingAppListPageHeader} />
      <RankingAppList onClickRecordNewApp={onClickRecordNewApp} />
      {notification ? <ToastNotification notification={notification} /> : null}
    </>
  );
};

export default Component;
