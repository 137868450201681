import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { FormikErrors } from 'formik';

import words from '../../../constants/words';
import { TextInput } from '../../atoms/TextInput';
import { Button } from '../../atoms/Button';
import { theme } from '../../../config';
import { Loading } from '../../atoms/Loading';

export const GlobalStyle = createGlobalStyle`
  & {
    .copy-text-input-tooltip {
      padding: 2px 16px;
      max-width: 360px;

      .bx--tooltip__caret {
        margin-top: 3px;
        @media ${theme.breakpoints.mobile} {
          margin-top: -9px;
        }
      }
      @media ${theme.breakpoints.mobile} {
        margin-top: 7px;
      }
    }
  }
`;

const ContentContainer = styled.div`
  margin-bottom: 21px;

  .bx--dropdown__wrapper.bx--list-box__wrapper {
    padding-bottom: 19px;
  }

  & > div:first-child {
    margin-top: 30px;
  }

  & > div:not(:first-child) {
    margin-top: 35px;
    @media ${theme.breakpoints.mobile} {
      margin-top: 14px;
    }
  }

  .bx--list-box__label {
    font-size: 13px;
  }

  .bx--tooltip__label {
    width: 100%;
  }

  #company-email-address {
    margin-bottom: 7px;
  }
`;

const ButtonsRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 39px;
  @media ${theme.breakpoints.mobile} {
    flex-direction: column-reverse;
  }
`;

const CancelButton = styled(Button)`
  width: 141px;
  margin-right: 16px;
  @media ${theme.breakpoints.mobile} {
    width: 100%;
    margin-top: 15px;
  }
  :hover {
    border-color: ${theme.colors.primaryButton1};
  }
`;

const SubmitButton = styled(Button)`
  width: 141px;
  @media ${theme.breakpoints.mobile} {
    width: 100%;
  }
`;

const VerifyEmailBody = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 400;
  margin: 0 !important;
`;

const VerificationEmailText = styled.p`
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.32;
  color: ${theme.colors.primaryButton1};
`;

const ResendText = styled.p`
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.16;
  color: ${theme.colors.interactive04};
  cursor: pointer;
`;

export type CompanyInfo = {
  name: string;
  repName: string;
  email: string;
  telephone: string;
  email_verified?: boolean;
};

export type Props = CompanyInfo & {
  onChangeName: (text: string) => void;
  onChangeRepName: (text: string) => void;
  onChangeEmail: (text: string) => void;
  onChangeTelephone: (text: string) => void;
  errors?: FormikErrors<CompanyInfo>;
  onClickButton: () => void;
  onCancel?: () => void;
  isSubmitDisabled?: boolean;
  isEdit?: boolean;
  isEmailVerified?: boolean;
  companyEmailRes: string;
  onPressResendEmail: () => void;
  isResendingEmail: boolean;
};

const Component = ({
  name,
  repName,
  email,
  telephone,
  onChangeName,
  onChangeRepName,
  onChangeEmail,
  onChangeTelephone,
  isEdit = false,
  onClickButton,
  onCancel = () => {},
  isSubmitDisabled = false,
  errors,
  isEmailVerified = true,
  companyEmailRes,
  onPressResendEmail,
  isResendingEmail,
}: Props): React.ReactElement => {
  const handleChangeName = (event: React.ChangeEvent<Element>) => {
    onChangeName((event.target as HTMLInputElement).value);
  };

  const handleChangeRepName = (event: React.ChangeEvent<Element>) => {
    onChangeRepName((event.target as HTMLInputElement).value);
  };

  const handleChangeEmail = (event: React.ChangeEvent<Element>) => {
    onChangeEmail((event.target as HTMLInputElement).value);
  };

  const handleChangeTelephone = (event: React.ChangeEvent<Element>) => {
    onChangeTelephone((event.target as HTMLInputElement).value);
  };

  return (
    <ContentContainer>
      <TextInput
        id="company-name"
        label={`${words.companyName} ${words.requiredLabel}`}
        disabled={!isEdit}
        value={name}
        onChange={handleChangeName}
        invalid={!!errors?.name}
        invalidText={errors?.name}
      />
      <TextInput
        id="company-rep-name"
        label={`${words.representativeName} ${words.requiredLabel}`}
        disabled={!isEdit}
        value={repName}
        onChange={handleChangeRepName}
        invalid={!!errors?.repName}
        invalidText={errors?.repName}
      />
      <TextInput
        id="company-email-address"
        label={`${words.companyEmail} ${words.requiredLabel}`}
        disabled={!isEdit}
        value={email}
        onChange={handleChangeEmail}
        invalid={!!errors?.email || !!companyEmailRes}
        invalidText={errors?.email || companyEmailRes}
        onCopyPress={async () => {
          if ('clipboard' in navigator) {
            await navigator.clipboard.writeText(email);
          }
        }}
        showCopyIfInvalid={true}
        showUnverifiedCompanyEmail={!isEmailVerified && !isEdit}
        copyTooltipMessage={words.companyEmailCopyTooltip}
        copyTooltipMenuOffset={{ left: -8 }}
      />
      {!isEmailVerified && !isEdit && (
        <VerifyEmailBody>
          <VerificationEmailText>
            {words.verificationSentThisEmail}
          </VerificationEmailText>
          {isResendingEmail ? (
            <Loading isSmall withOverlay={false} height={13} width={13} />
          ) : (
            <ResendText onClick={onPressResendEmail}>{words.resend}</ResendText>
          )}
        </VerifyEmailBody>
      )}
      <TextInput
        id="company-telephone"
        label={`${words.companyTelephone} ${words.requiredLabel}`}
        disabled={!isEdit}
        value={telephone}
        onChange={handleChangeTelephone}
        invalid={!!errors?.telephone}
        invalidText={errors?.telephone}
      />
      <ButtonsRow>
        {isEdit && (
          <CancelButton theme="light" title={words.cancel} onPress={onCancel} />
        )}
        <SubmitButton
          onPress={onClickButton}
          title={isEdit ? words.toUpdate : words.toEdit}
          disabled={isSubmitDisabled}
        />
      </ButtonsRow>
      <GlobalStyle />
    </ContentContainer>
  );
};

export default Component;
