import React, { ChangeEvent } from 'react';
import styled from 'styled-components';

import words from '../../../constants/words';
import themes from '../../../config/themes';

import { TextInput } from '../../atoms/TextInput';

const ContentContainer = styled.div`
  margin-bottom: 21px;

  @media ${themes.main.breakpoints.mobile} {
    margin-bottom: 0px;
  }

  .bx--dropdown__wrapper.bx--list-box__wrapper {
    padding-bottom: 19px;
  }

  & > div:first-child {
    margin-top: 30px;
    @media ${themes.main.breakpoints.mobile} {
      margin-top: 20px;
      padding: 0px 2px 0px 2px;
    }
  }

  & > div:not(:first-child) {
    margin-top: 25px;
    @media ${themes.main.breakpoints.mobile} {
      margin-top: 5px;
      padding: 0px 2px 0px 2px;
    }
  }

  .bx--list-box__label {
    font-size: 13px;
  }
`;

export type Props = {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
  onChangeCurrentPassword: (event: ChangeEvent) => void;
  onChangeNewPassword: (event: ChangeEvent) => void;
  onChangeConfirmPassword: (event: ChangeEvent) => void;
  currentPasswordError?: string;
  newPasswordError?: string;
  confirmPasswordError?: string;
};

const Component = ({
  currentPassword,
  newPassword,
  confirmPassword,
  onChangeCurrentPassword,
  onChangeNewPassword,
  onChangeConfirmPassword,
  currentPasswordError,
  newPasswordError,
  confirmPasswordError,
}: Props): React.ReactElement => {
  return (
    <ContentContainer>
      <TextInput
        value={currentPassword}
        onChange={onChangeCurrentPassword}
        label={`${words.currentPassword}${'\u00A0'} ${words.requiredLabel}`}
        id="currentPassword"
        showPasswordLabel={words.showPassword}
        hidePasswordLabel={words.hidePassword}
        type="password"
        invalid={!!currentPasswordError}
        invalidText={currentPasswordError}
      />
      <TextInput
        value={newPassword}
        onChange={onChangeNewPassword}
        label={`${words.newPassword}${'\u00A0'} ${words.requiredLabel}`}
        id="newPassword"
        showPasswordLabel={words.showPassword}
        hidePasswordLabel={words.hidePassword}
        type="password"
        invalid={!!newPasswordError}
        invalidText={newPasswordError}
      />
      <TextInput
        value={confirmPassword}
        onChange={onChangeConfirmPassword}
        label={`${words.newPasswordConfirm} ${words.requiredLabel}`}
        id="confirmPassword"
        showPasswordLabel={words.showPassword}
        hidePasswordLabel={words.hidePassword}
        type="password"
        invalid={!!confirmPasswordError}
        invalidText={confirmPasswordError}
      />
    </ContentContainer>
  );
};

export default Component;
