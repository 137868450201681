import React, { useEffect, useState } from 'react';
import CloseButton from 'assets/icons/close-button.svg';
import './addGroupModal.scss';
import Select from 'react-select';
import { OrangeButton } from 'components/atoms/OrangeButton';
import { listGroupOptionType } from 'type/member';
import axios from 'axios';
import { getCurrentUser } from 'utils/common';
import authHeader from 'api/auth-header';
import urls from 'constants/urls';

type PropType = {
  isShow: boolean;
  onHideModal: () => void;
  listUserOfGroup: any[];
  handleAddNewGroup: (
    _groupName: string,
    _listOwner: number[],
    _listApprover: number[],
  ) => void;
  errorAddGroupFromServer?: string;
  createGroupStatus: string;
};

export default function AddGroupModal(props: PropType) {
  const {
    isShow,
    onHideModal,
    listUserOfGroup,
    handleAddNewGroup,
    errorAddGroupFromServer,
    createGroupStatus,
  } = props;
  const [groupName, setGroupName] = useState('');
  const [listOption, setListOption] = useState<any[]>([]);
  const [selectedOwner, setSelectedOwner] = useState<number[]>([]);
  const [errorGroupName, setErrorGroupName] = useState(false);
  const [errorGroupOwner, setErrorGroupOwner] = useState(false);
  const [errorGroupApprover, setErrorGroupApprover] = useState(false);
  const [selectedInsideOwner, setSelectedInsideOwner] = useState<
    listGroupOptionType
  >([]);
  const [listCanSelect, setListCanSelect] = useState<listGroupOptionType>([]);
  const [listTemp, setListTemp] = useState<listGroupOptionType>([]);
  const [selectedApprover, setSelectedApprover] = useState<number[]>([]);
  const [selectedInsideApprover, setSelectedInsideApprover] = useState<
    listGroupOptionType
  >([]);

  function handleAddNewGroupInComp() {
    setErrorGroupName(false);
    setErrorGroupApprover(false);
    if (groupName.length === 0) {
      setErrorGroupName(true);
      return;
    }
    if (selectedOwner.length === 0) {
      setErrorGroupOwner(true);
      return;
    }
    if (selectedApprover.length === 0) {
      setErrorGroupApprover(true);
      return;
    }
    handleAddNewGroup(groupName, selectedOwner, selectedApprover);
  }

  useEffect(() => {
    const user = getCurrentUser();
    axios
      .post(
        urls.get_all_member_company,
        { company_id: user.company_id },
        { headers: authHeader() },
      )
      .then(res => {
        const data: any[] = [];
        res.data.data &&
          res.data.data.map((item: { id: number; full_name: string }) => {
            const newItem = {
              value: item.id.toString(),
              label: item.full_name,
            };
            data.push(newItem);
          });
        setListOption(data);
      })
      .catch();
  }, [listUserOfGroup]);

  useEffect(() => {
    setListCanSelect(listOption);
    setListTemp(listOption);
  }, [listOption]);

  function onHideModalHandle() {
    setSelectedOwner([]);
    setSelectedApprover([]);
    setSelectedInsideApprover([]);
    setSelectedInsideOwner([]);
    setGroupName('');
    onHideModal();
  }

  useEffect(() => {
    setSelectedOwner([]);
    setSelectedApprover([]);
    setSelectedInsideApprover([]);
    setSelectedInsideOwner([]);
    setGroupName('');
  }, [createGroupStatus]);

  useEffect(() => {
    const data = [...selectedOwner, ...selectedApprover];
    setListCanSelect(listTemp);
    data.map(item => {
      setListCanSelect(prev => prev.filter(i => parseInt(i.value) !== item));
    });
  }, [selectedOwner, selectedApprover]);

  function handleChange(value: any) {
    const owner = { value };
    if (owner.value.length > 0) {
      const listApprover: number[] = [];
      owner.value.map((item: { value: string; label: string }) => {
        listApprover.push(parseInt(item.value));
      });
      setSelectedOwner(listApprover);
      setSelectedInsideOwner(owner.value);
    } else {
      setSelectedOwner([]);
      setSelectedInsideOwner([]);
    }
  }

  function handleChangeApprover(value: any) {
    const approvers = { value };
    if (approvers.value.length > 0) {
      const listApprover: number[] = [];
      approvers.value.map((item: { value: string; label: string }) => {
        listApprover.push(parseInt(item.value));
      });
      setSelectedApprover(listApprover);
      setSelectedInsideApprover(approvers.value);
    } else {
      setSelectedApprover([]);
      setSelectedInsideApprover([]);
    }
  }

  return (
    <div className={`add-group-pages ${isShow ? 'show' : 'hide'}`}>
      <div className="title-modal">
        <div className="modal-title">グループ追加</div>
        <div className="close-button" onClick={onHideModalHandle}>
          <img src={CloseButton} />
        </div>
      </div>
      <div className="modal-content">
        <label
          className={
            groupName && groupName.length > 0 ? 'filled' : 'not-filled'
          }>
          グループ名 (必須)
        </label>
        <br />
        <input
          className="input-signup"
          type="text"
          placeholder="グループ名 (必須)"
          value={groupName}
          onChange={e => setGroupName(e.target.value)}
          required
          autoComplete="new-password"
        />
        {errorGroupName && (
          <div className="errorFormat">
            グループ名を空にすることはできません。254
            文字以下にする必要があります。
          </div>
        )}
        <br />
        <br />
        <label
          className={
            groupName && groupName.length > 0 ? 'filled' : 'not-filled'
          }>
          グループ所有者 (必須)
        </label>
        <Select
          value={selectedInsideOwner}
          placeholder="グループ所有者"
          options={listCanSelect}
          isMulti
          className="select-group"
          classNamePrefix="select-group"
          menuPlacement="top"
          noOptionsMessage={() => '空の'}
          onChange={handleChange}></Select>
        {errorGroupOwner && (
          <div className="errorFormat">
            所有者を少なくとも 1 人選択してください。
          </div>
        )}
        <br />
        <br />
        <label
          className={
            groupName && groupName.length > 0 ? 'filled' : 'not-filled'
          }>
          承認者 (必須)
        </label>
        <Select
          value={selectedInsideApprover}
          placeholder="承認者"
          options={listCanSelect}
          isMulti
          className="select-group"
          classNamePrefix="select-group"
          menuPlacement="top"
          noOptionsMessage={() => '空の'}
          onChange={handleChangeApprover}></Select>
        {errorGroupApprover && (
          <div className="errorFormat">
            少なくとも 1 人の承認者を選択してください。
          </div>
        )}
        <p
          className="errorFromServer"
          style={{
            display: errorAddGroupFromServer !== '' ? 'block' : 'none',
          }}
          dangerouslySetInnerHTML={{
            __html: errorAddGroupFromServer ? errorAddGroupFromServer : '',
          }}></p>
        <OrangeButton
          text="追加"
          onClick={handleAddNewGroupInComp}
          style={{ width: '144px', height: '42px', marginTop: '20px' }}
        />
      </div>
    </div>
  );
}
