import {
  CheckNotificationCount,
  Notification,
  NotificationData,
  UpdateNotificationData,
} from '../../domain/entities/notification';
import { PaginationQuery } from '../../domain/entities/request';
import { PaginatedResponse } from '../../domain/entities/response';
import company from '../../usecases/modules/company';
import NotificationRepository from '../../usecases/ports/NotificationRepository';
import { HttpAdapter } from './../../usecases/ports/HttpAdapter';

export default class NotificationRepositoryImpl
  implements NotificationRepository {
  httpAdapter: HttpAdapter;
  urls: {
    [key: string]: (query: {
      [key: string]: string | number | boolean;
    }) => string;
  };

  constructor(
    httpAdapter: HttpAdapter,
    urls: {
      [key: string]: (query: {
        [key: string]: string | number | boolean;
      }) => string;
    },
  ) {
    this.httpAdapter = httpAdapter;
    this.urls = urls;
  }

  fetchNotifications = async (
    query: PaginationQuery & {
      companyId: number;
      userId?: number;
    },
  ): Promise<PaginatedResponse & { results: Notification[] }> => {
    const { companyId } = query;
    const response = await this.httpAdapter.get(
      this.urls.getNotifications({ companyId }),
      { params: query },
    );

    const notifications = {
      ...response.data,
      results: response.data.results.map(
        (
          notification: Notification & {
            is_read: boolean;
            is_approved: boolean | null;
            approver_name: string;
            applicant_name: string;
            ranking_name: string;
            member: number;
            ranking: number;
            entry: number;
            created_at: Date;
            updated_at: Date;
            approver_id: number;
            applicant_id: number;
          },
        ) => {
          return {
            ...notification,
            approverName: notification.approver_name,
            isRead: notification.is_read,
            isApproved: notification.is_approved,
            rankingName: notification.ranking_name,
            rankingId: notification.ranking,
            applicantName: notification.applicant_name,
            memberId: notification.member,
            entryId: notification.entry,
            createdAt: notification.created_at,
            updatedAt: notification.updated_at,
            approverId: notification.approver_id,
            applicantId: notification.applicant_id,
          };
        },
      ),
    };
    return notifications;
  };

  addNotification = async (
    { companyId }: { companyId: number },
    data: NotificationData,
  ): Promise<Response> => {
    const {
      rankingId,
      entryId,
      memberId,
      approverId,
      applicantId,
      ...extras
    } = data;
    const payload = {
      ranking: rankingId,
      entry: entryId,
      member: memberId,
      approver_id: approverId,
      applicant_id: applicantId,
      ...extras,
    };
    const response = await this.httpAdapter.post(
      this.urls.addNotification({ companyId: `${companyId}` }),
      payload,
    );
    return response;
  };

  updateNotification = async (
    {
      companyId,
      notificationId,
    }: { companyId: number; notificationId: number },
    data: UpdateNotificationData,
  ): Promise<Response> => {
    const response = await this.httpAdapter.patch(
      this.urls.updateNotification({
        companyId: companyId,
        notificationId: notificationId,
      }),
      data,
    );
    return response;
  };

  checkNotificationCount = async (query: {
    companyId: number;
    isRead?: boolean;
    userId?: number;
  }): Promise<CheckNotificationCount> => {
    const { companyId } = query;
    const response = await this.httpAdapter.get(
      this.urls.checkNotificationCount({
        companyId: companyId,
      }),
      {
        params: query,
      },
    );
    return response.data;
  };
}
