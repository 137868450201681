import React, { useEffect, useState } from 'react';
import './sphTemplates.scss';
import { DateScrollBar } from '../../organisms/DateScrollBar';
import { StateButton } from '../../molecules/StateButton';
import { ArrowShowCalendar } from 'components/molecules/ArrowShowCalendar';
import { TypeDetailButton } from 'components/molecules/TypeDetailButton';
import { DisplayCalendar } from 'components/molecules/DisplayCalendar';
import {
  dateToString,
  getCurrentUser,
  getWeeksInMonth,
  isEmptyObject,
  jpStringToDate,
  lastdayOfMonth,
  makeRandomKey,
} from 'utils/common';
import { Loading } from 'components/molecules/Loading';
import BlackScreen from 'components/atoms/BlackScreen/BlackScreen';
import PersonalDayTemplates from './DataComponent/PersonalDayComponent';
import PersonalWeekTemplates from './DataComponent/PersonalWeekComponent';
import PersonalMonthTemplates from './DataComponent/PersonalMonthComponent';
import axios from 'axios';
import urls from 'constants/urls';
import authHeader from 'api/auth-header';
import { sphForDayPerson, sphForMonthPerson } from 'type/sph';
import { defaultSphForDay, defaultSphForMonth } from 'value/sph';
import { WeekCalendar } from 'components/molecules/WeekCalendar';
import { WorkingTimeRegisterModal } from 'components/molecules/WorkingTimeRegisterModal';

export default function SPHTemplate() {
  const today = new Date();
  const [currentUserName, setCurrentUserName] = useState<string>('');
  const [groupOfUser, setGroupOfUser] = useState<string>('');
  const [companyUser, setCompanyUser] = useState<string>('');
  const [showBoard, setShowBoard] = useState<boolean>(true);
  const [isShowBScreen, setIsShowBScreen] = useState<boolean>(false);
  const [showInput, setShowInput] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentMonth, setCurrentMonth] = useState<string>(
    (today.getMonth() + 1).toString(),
  );
  // 0 is DayMode - 1 is MonthMode
  // 2 is DayGroup - 3 is MonthGroup
  const [personalViewMode, setPersonalViewMode] = useState(0);
  const user = getCurrentUser();

  const [dateCalendar, setDateCalendar] = useState(new Date());

  // 0 Today 1 Month 2 Week 3 Day
  const [modeSee, setModeSee] = useState(0);

  const [weekMode, setWeekMode] = useState(false);
  const [currentWeek, setCurrentWeek] = useState(0);

  // Personal
  const [haveDayData, setHaveDayData] = useState(false);
  const [haveMonthData, setHaveMonthData] = useState(false);
  const [haveWeekData, setHaveWeekData] = useState(false);
  const [sphForDayData, setSphForDayData] = useState<sphForDayPerson>(
    defaultSphForDay,
  );
  const [sphForMonthData, setSphForMonthData] = useState<sphForMonthPerson>(
    defaultSphForMonth,
  );
  const [sphForWeekData, setSphForWeekData] = useState<sphForMonthPerson>(
    defaultSphForMonth,
  );

  const [eventCloseWorking, setEventCloseWorking] = useState<string>(
    makeRandomKey(10),
  );
  const [errorResponse, setErrorResponse] = useState('');
  const [listHaveData, setListHaveData] = useState<string[]>([]);

  function removeAllModal() {
    setPersonalViewMode(0);
    setShowInput(false);
    setIsShowBScreen(false);
  }

  // Handle set month
  function handleSetMonth(month: number) {
    console.log('SET MONTH', month);
  }

  function handleChooseMode(mode: number) {
    setPersonalViewMode(mode);
  }

  function getResultFromDay(date: Date) {
    setIsLoading(true);
    axios
      .get(`${urls.sph_get}?start_date=${jpStringToDate(dateToString(date))}`, {
        headers: authHeader(),
      })
      .then(res => {
        setIsLoading(false);
        setDateCalendar(date);
        if (res.data.data === null) {
          setHaveDayData(false);
          setShowInput(true);
          setIsShowBScreen(true);
        } else {
          setHaveDayData(true);
          setSphForDayData(res.data);
        }
      })
      .catch();
  }

  function getResultFromMonth() {
    setIsLoading(true);
    axios
      .get(
        `${
          urls.sph_get
        }?start_date=${dateCalendar.getFullYear()}/${dateCalendar.getMonth() +
          1}/01&end_date=${dateCalendar.getFullYear()}/${dateCalendar.getMonth() +
          1}/${lastdayOfMonth(
          dateCalendar.getFullYear(),
          dateCalendar.getMonth(),
        )}`,
        {
          headers: authHeader(),
        },
      )
      .then(res => {
        setIsLoading(false);
        if (res.data.data === null) {
          setHaveMonthData(false);
        } else {
          setHaveMonthData(true);
          setSphForMonthData(res.data);
          setListHaveData(res.data.have);
        }
      })
      .catch();
  }

  function getResultFromWeek() {
    setIsLoading(true);
    const currentYear = dateCalendar.getFullYear();
    const currentMonth = dateCalendar.getMonth();
    const it = getWeeksInMonth(currentYear, currentMonth);
    const startDate = new Date(
      currentYear,
      currentMonth,
      it[currentWeek].start,
    );
    const endDate = new Date(currentYear, currentMonth, it[currentWeek].end);
    console.log(startDate, endDate);
    axios
      .get(
        `${
          urls.sph_get
        }?start_date=${startDate.getFullYear()}/${startDate.getMonth() +
          1}/${startDate.getDate()}&end_date=${endDate.getFullYear()}/${endDate.getMonth() +
          1}/${endDate.getDate()}`,
        {
          headers: authHeader(),
        },
      )
      .then(res => {
        setIsLoading(false);
        if (res.data.data === null) {
          setHaveWeekData(false);
        } else {
          setHaveWeekData(true);
          setSphForWeekData(res.data);
        }
      })
      .catch();
  }

  function handleChangeDate(date: Date) {
    setModeSee(3);
    getResultFromDay(date);
    setDateCalendar(date);
  }

  function handleChooseModeSee(mode: number) {
    if (mode === 1) {
      setShowBoard(false);
      setWeekMode(false);
      getResultFromMonth();
    }
    if (mode === 2) {
      setWeekMode(true);
      getResultFromWeek();
      setCurrentWeek(0);
    }
    if ([0, 3].includes(mode)) {
      setShowBoard(true);
      setWeekMode(false);
      getResultFromDay(dateCalendar);
    }
    setModeSee(mode);
  }

  function handleCreateNewPlanned(
    sales_date: string,
    working_planned: string,
    working_actual: string,
    number_withdrawals: string,
    number_pre_probable: string,
    number_tosses: string,
  ) {
    const data = {
      date: `${dateCalendar.getFullYear()}/${dateCalendar.getMonth() +
        1}/${dateCalendar.getDate()}`,
      time_expect: working_planned,
      time_actual: working_actual,
      number_of_tosses: number_tosses,
      number_of_withdrawals: number_withdrawals,
      pre_probable_number: number_pre_probable,
    };
    setIsLoading(true);
    axios
      .post(`${urls.input_entry}`, data, { headers: authHeader() })
      .then(() => {
        removeAllModal();
        getResultFromDay(dateCalendar);
        setIsLoading(false);
        setShowInput(false);
      })
      .catch();
  }

  function handleClose() {
    setIsShowBScreen(false);
    setShowInput(false);
    setEventCloseWorking(makeRandomKey(10));
    setErrorResponse('');
  }

  useEffect(() => {
    getResultFromDay(dateCalendar);
    getResultFromMonth();
  }, []);

  useEffect(() => {
    getResultFromWeek();
  }, [currentWeek]);

  useEffect(() => {
    if (!isEmptyObject(user)) {
      setCurrentUserName(user.full_name);
      setGroupOfUser(user.group.name);
      setCompanyUser(
        user.company && user.company.name ? user.company.name : user.company_id,
      );
    }
  }, [user]);

  return (
    <div className="sphTemplates">
      <Loading display={isLoading ? 'block' : 'none'} />
      <BlackScreen
        display={isShowBScreen}
        onClick={removeAllModal}
        style={{ zIndex: 9 }}
      />
      <DateScrollBar
        currentMonth={currentMonth}
        handleSetMonth={month => handleSetMonth(month)}
      />
      <ArrowShowCalendar handleClickArrow={() => setShowBoard(!showBoard)} />
      <StateButton
        display={true}
        mode={modeSee}
        handleChooseModeSee={handleChooseModeSee}
      />
      {!weekMode ? (
        <DisplayCalendar
          display={showBoard}
          dateCalendar={dateCalendar}
          handleChangeDate={handleChangeDate}
          listHaveData={listHaveData}
        />
      ) : (
        <WeekCalendar
          display={true}
          dateCalendar={dateCalendar}
          handleChangeDate={handleChangeDate}
          currentWeek={currentWeek}
          nextWeek={() => setCurrentWeek(currentWeek + 1)}
          prevWeek={() => setCurrentWeek(currentWeek - 1)}
        />
      )}

      <TypeDetailButton
        user={currentUserName ? currentUserName : ''}
        group={groupOfUser ? groupOfUser : ''}
        company={companyUser ? companyUser : ''}
        mode={personalViewMode}
        handleChooseMode={mode => handleChooseMode(mode)}
      />
      {[0, 3].includes(modeSee) && haveDayData && (
        <PersonalDayTemplates
          dateCalendar={dateCalendar}
          sphForDayData={sphForDayData}
        />
      )}
      {modeSee == 2 && haveWeekData && (
        <PersonalWeekTemplates
          sphForWeekData={sphForWeekData}
          dateCalendar={dateCalendar}
          currentWeek={currentWeek}
        />
      )}
      {modeSee == 1 && haveMonthData && (
        <PersonalMonthTemplates
          sphForMonthData={sphForMonthData}
          dateCalendar={dateCalendar}
        />
      )}
      <WorkingTimeRegisterModal
        isShow={showInput}
        dateCalendar={dateCalendar}
        onHideModal={handleClose}
        eventCloseWorking={eventCloseWorking}
        errorResponse={errorResponse}
        handleCreateNewPlanned={(
          sales_date: string,
          working_planned: string,
          working_actual: string,
          number_withdrawals: string,
          number_pre_probable: string,
          number_tosses: string,
        ) =>
          handleCreateNewPlanned(
            sales_date,
            working_planned,
            working_actual,
            number_withdrawals,
            number_pre_probable,
            number_tosses,
          )
        }
      />
      {/* <WorkingTimeEditModal
        isShow={isWorkingTimeEdit}
        onHideModal={hideWorkingTimeEditModal}
        eventCloseWorking={eventCloseWorking}
        errorResponse={errorResponse}
        personalData={personalData}
        handleEditPlanned={(
          sales_date: string,
          working_planned: string,
          product_planned: string,
        ) => handleEditPlanned(sales_date, working_planned, product_planned)}
      /> */}
      {/* <WorkingTimeApplyModal
        isShow={isWorkingTimeApply}
        onHideModal={hideWorkingTimeAppModal}
        eventCloseWorking={eventCloseWorking}
        errorResponse={errorResponse}
        personalData={personalData}
        handleUpdateResult={(
          working_actual: string,
          product_actual: string,
          approver_ids: { label: string; value: string }[],
          note: string,
        ) =>
          handleUpdateResult(working_actual, product_actual, approver_ids, note)
        }
        mainGroup={mainGroup.toString()}
      /> */}
    </div>
  );
}
