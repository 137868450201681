import React from 'react';
import {
  DataTable,
  DataTableSkeletonHeader,
  Table,
  TableHead,
  TableRow,
  TableBody,
  DataTableRow,
  DataTableHeader,
  DataTableCustomRenderProps,
  DenormalizedRow,
} from 'carbon-components-react';
import {
  CustomTableHeader,
  CustomTableSelectAll,
  CustomTableRow,
  CustomTableSelectRow,
  CustomTableCell,
  CustomPagination,
  Container,
  CustomCheckbox,
  CustomDataTableSkeletonContainer,
  CustomDataTableSkeleton,
} from './elements';
import words from '../../../constants/words';
import { TableLoader } from '../../atoms/Loading';

export type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rows: DataTableRow<any>[];
  headers: DataTableHeader<string>[];
  isSelectable?: boolean;
  isLoading?: boolean;
  page?: number;
  pageSize?: number;
  totalItems?: number;
  className?: string;
  unclickableColumns?: string[];
  onSelectRows?: (selectedRows: DenormalizedRow[]) => void;
  onChangePage: (data: { page: number; pageSize: number }) => void;
  onClickRow?: (id: string) => void;
};

const Component = ({
  rows,
  headers,
  isSelectable,
  isLoading,
  onSelectRows,
  page,
  pageSize,
  totalItems,
  onChangePage,
  className,
  onClickRow,
  unclickableColumns,
}: Props): React.ReactElement => {
  return isLoading ? (
    <Container>
      <CustomDataTableSkeletonContainer>
        {isSelectable && <CustomCheckbox label="" onChange={() => {}} />}
        <CustomDataTableSkeleton
          showToolbar={false}
          showHeader={false}
          headers={headers as DataTableSkeletonHeader[]}
          columnCount={headers.length}
          className={className}
          $isSelectable={isSelectable}
          rowCount={0}
        />
        <TableLoader />
      </CustomDataTableSkeletonContainer>
    </Container>
  ) : (
    <Container className={className}>
      <DataTable rows={rows} headers={headers}>
        {({
          rows,
          headers,
          getTableProps,
          getHeaderProps,
          getRowProps,
          getSelectionProps,
          selectedRows,
        }: DataTableCustomRenderProps) => {
          if (onSelectRows) {
            onSelectRows(selectedRows as DenormalizedRow[]);
          }

          return (
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  {isSelectable && (
                    <CustomTableSelectAll {...getSelectionProps()} />
                  )}
                  {headers.map((header: DataTableHeader<string>) => (
                    <CustomTableHeader
                      {...getHeaderProps({ header })}
                      key={header.key}>
                      {header.header}
                    </CustomTableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map(row => (
                  // eslint-disable-next-line react/jsx-key
                  <CustomTableRow {...getRowProps({ row })} key={row.id}>
                    {isSelectable && (
                      <CustomTableSelectRow
                        {...getSelectionProps({ row })}
                        isSelected={selectedRows.some(
                          item => item.id === row.id,
                        )}
                      />
                    )}
                    {row.cells.map(cell => (
                      <CustomTableCell
                        key={cell.id}
                        onClick={() =>
                          onClickRow &&
                          !unclickableColumns?.includes(cell.info.header) &&
                          onClickRow(row.id)
                        }
                        isSelected={selectedRows.some(
                          item => item.id === row.id,
                        )}>
                        {cell.value}
                      </CustomTableCell>
                    ))}
                  </CustomTableRow>
                ))}
              </TableBody>
            </Table>
          );
        }}
      </DataTable>
      <CustomPagination
        pageSizes={[10, 20, 30, 40, 50]}
        page={page}
        pageSize={pageSize}
        totalItems={totalItems}
        onChange={onChangePage}
        itemRangeText={(min: number, max: number, total: number) =>
          words.pagination(min, max, total)
        }
        itemsPerPageText={words.itemsPerPage}
        pageRangeText={(_current: number, total: number) =>
          `${words.pageNumber[0]} ${total} ${words.pageNumber[1]}`
        }
      />
    </Container>
  );
};

Component.defaultProps = {
  isSelectable: false,
  isLoading: false,
  page: 1,
  pageSize: 10,
  totalItems: 10,
  unclickableColumns: [],
};

export default Component;
