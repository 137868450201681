import { StatusTypes } from '../../../components/templates/RecordReferenceList/types';
import { RecordReference } from '../../../domain/entities/record';
import { PaginationQuery } from '../../../domain/entities/request';
import { PaginatedResponse } from '../../../domain/entities/response';
import RecordRepository from '../../ports/RecordRepository';

export type fetchRecordReferencesUseCase = (
  query: PaginationQuery & {
    companyId: number;
    memberId: number;
    rankingId?: number;
    status?: StatusTypes;
  },
) => Promise<PaginatedResponse & { results: RecordReference[] }>;

export const buildFetchRecordReferences = (dependencies: {
  recordRepo: RecordRepository;
}): fetchRecordReferencesUseCase => {
  const { recordRepo } = dependencies;

  const fetchRecordReferences: fetchRecordReferencesUseCase = async query =>
    recordRepo.fetch(query);

  return fetchRecordReferences;
};
