import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { theme } from '../../../config';
import words from '../../../constants/words';
import { Header } from '../Header';
import { NavigationSidebar } from '../NavigationSidebar';
import { useAuthHooks } from '../../../hooks/auth';
import { RouteItem } from '../NavigationSidebar/NavigationSidebar';
import { BurgerMenu, CloseMenu } from '../../atoms/Icons';
import { useNavigate } from 'react-router';
import { useGlobalState } from '../../../hooks/global';
import { useMutation, useQueryClient } from 'react-query';
import { useMemberHooks } from '../../../hooks/member';
import { UpdateMemberType } from '../../../domain/entities/member';
import { MemberHeader } from '../MemberHeader';
import { MemberFooter } from '../MemberFooter';
import { useLocation } from 'react-router-dom';
import { MemberTopPopUp } from '../MemberTopPopUp';
import urls from 'constants/urls';
import { getCurrentUser, removeCurrentUser, replaceUser } from 'utils/common';
import axios from 'axios';
import authHeader from 'api/auth-header';

export type Props = {
  children?: React.ReactElement | React.ReactElement[];
  navItems?: RouteItem[];
  profileImage: string | undefined;
  isFetchingProfileImage: boolean;
};

const Wrapper = styled.div`
  background: ${theme.colors.whiteLightColor};
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;

  @media ${theme.breakpoints.mobile} {
    // border-bottom: solid #b0adab 0.5px;
  }
`;

const BurgerIcon = styled(BurgerMenu)`
  display: none;
  cursor: pointer;
  margin: 0 21px 0 21px;
  @media ${theme.breakpoints.mobile} {
    display: block;
  }
`;

const CloseIcon = styled(CloseMenu)`
  display: none;
  cursor: pointer;
  margin: 0 21px 0 21px;
  @media ${theme.breakpoints.mobile} {
    display: block;
  }
`;

const ContentsWrapper = styled.div`
  display: flex;
  background: ${theme.colors.whiteLightColor};
  flex-direction: row;
  min-height: calc(100vh - 49px);
  padding-top: 55px;
  @media ${theme.breakpoints.mobile} {
    .ribbon-hide-sidebar {
      width: 0;
      overflow: hidden;
      -webkit-transition: height, 0.2s linear;
      -moz-transition: height, 0.2s linear;
      -ms-transition: height, 0.2s linear;
      -o-transition: height, 0.2s linear;
      transition: height, 0.2s linear;
    }
  }
`;
const StyledSidebar = styled(NavigationSidebar)`
  width: 256px;
  @media ${theme.breakpoints.mobile} {
    width: 100vw;
    z-index: 100;
    position: absolute;
    -webkit-transition: height, 0.2s linear;
    -moz-transition: height, 0.2s linear;
    -ms-transition: height, 0.2s linear;
    -o-transition: height, 0.2s linear;
    transition: height, 0.2s linear;
  }
`;

const PageContentsWrapper = styled.div<{ $isSideBarShowing?: boolean }>`
  flex: 1;
  background: ${theme.colors.whiteLightColor};
  overflow: hidden;
  @media ${theme.breakpoints.mobile} {
    display: ${(props): string => (props.$isSideBarShowing ? 'none' : 'auto')};
  }
`;

const NAV_ITEMS = [
  {
    label: words.home,
    to: '/',
    onPress: () => {},
  },
  {
    label: words.record,
    onPress: () => {},
    subroutes: [
      {
        label: words.recordApplication,
        to: '/application',
      },
      {
        label: words.recordReference,
        to: '/reference',
      },
      {
        label: words.recordApproval,
        to: '/approval',
      },
    ],
  },
  {
    label: words.ranking,
    subroutes: [
      {
        label: words.rankingTable,
        to: '/ranking-table',
      },
      {
        label: words.graph,
        to: '/graph',
      },
    ],
  },
  {
    label: words.manageRanking,
    subroutes: [
      {
        label: words.rankingList,
        to: '/ranking-list',
      },
    ],
  },
  {
    label: words.config,
    onPress: () => {},
    subroutes: [
      {
        label: words.groupManagement,
        to: '/groups',
      },
      {
        label: words.memberManagement,
        to: '/members',
      },
      {
        label: words.companyInfo,
        to: '/company',
      },
      {
        label: words.accountConfig,
        to: '/account-info',
      },
    ],
  },
];

const Component = ({
  children,
  navItems = NAV_ITEMS,
  profileImage,
  isFetchingProfileImage,
}: Props): React.ReactElement => {
  const {
    useCurrentUser: { currentUser },
  } = useGlobalState();
  const { useLogout } = useAuthHooks();
  const { logout } = useLogout();
  const navigate = useNavigate();
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const { useUpdateMember } = useMemberHooks();
  const { updateMember } = useUpdateMember();
  const localtion = useLocation();
  const [activeMenu, setActiveMenu] = useState('');
  const [popupUser, setPopupUser] = useState(false);
  const queryClient = useQueryClient();
  const { mutate: mutateUpdateMember } = useMutation(
    (params: { memberId: number; payload: UpdateMemberType }) => {
      if (!currentUser?.companyId) {
        throw new Error(`'company id' not set`);
      }
      return updateMember(
        currentUser.companyId,
        params.memberId,
        params.payload,
      );
    },
    {
      onSuccess: async () => {
        queryClient.refetchQueries('notification-count');
      },
    },
  );

  const toggleSideBar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };
  const handleToggleSideBar = (e: any) => {
    if (e) {
      setIsSidebarVisible(!isSidebarVisible);
    }
  };

  const handleToggleSideBarHome = (e: any) => {
    if (e.label === words.home) {
      setIsSidebarVisible(!isSidebarVisible);
    }
  };

  const handleOnPressNotif = () => {
    if (currentUser?.memberId) {
      mutateUpdateMember({
        memberId: currentUser.memberId,
        payload: { notification_badge_count: 0 },
      });
    }
    navigate('/notifications');
  };

  const onPressTitle = () => {
    navigate('/');
  };

  useEffect(() => {
    setActiveMenu(location.pathname);
  }),
    [location];

  useEffect(() => {
    const user = getCurrentUser();
    axios
      .get(urls.member_info, { headers: authHeader() })
      .then(res => {
        user.group = res.data.data.group;
        user.company = res.data.data.company;
        replaceUser(user);
      })
      .catch();
  }, []);

  function handleClickPopUp(value: number) {
    setPopupUser(false);
    if (value === 1) {
      navigate(urls.member_profile);
    } else {
      removeCurrentUser();
      navigate(urls.sign_in_page);
    }
  }

  return (
    <Wrapper>
      <HeaderWrapper>
        {/* {isSidebarVisible ? (
          <CloseIcon onClick={toggleSideBar} />
        ) : (
          <BurgerIcon onClick={toggleSideBar} />
        )} */}
        <MemberHeader onClick={() => setPopupUser(!popupUser)} />
        <MemberTopPopUp
          onClick={value => handleClickPopUp(value)}
          display={popupUser}
        />
        {/* <Header
          onPressNotif={handleOnPressNotif}
          onPressAccount={() => {
            logout(navigate);
          }}
          onPressTitle={onPressTitle}
          accountProfileImage={profileImage}
          isLoadingProfileImage={isFetchingProfileImage}
        /> */}
      </HeaderWrapper>
      <ContentsWrapper>
        <StyledSidebar
          routes={navItems}
          className={!isSidebarVisible ? 'ribbon-hide-sidebar' : undefined}
          toggleSideBarClick={handleToggleSideBar}
          toggleSideBarHome={handleToggleSideBarHome}
        />
        <PageContentsWrapper $isSideBarShowing={isSidebarVisible}>
          {children}
        </PageContentsWrapper>
        <MemberFooter path={activeMenu} />
      </ContentsWrapper>
    </Wrapper>
  );
};

export default Component;
