import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import words from '../../../constants/words';
import { Button } from '../../atoms/Button';
import { Plus } from '../../atoms/Icons';
import { Tooltip } from '../../atoms/Tooltip';
import { RankingColumnsTable } from '../RankingColumnsTable';
import { RankingColumn } from '../RankingColumnsTable/RankingColumnsTable';
import { theme } from '../../../config';

const Wrapper = styled.div<{
  withTooltip: boolean;
  isAdding: boolean;
  isEditing: boolean;
}>`
  padding-bottom: ${({ withTooltip, isAdding, isEditing }) =>
    withTooltip ? '60px' : isAdding ? '110px' : isEditing ? '95px' : '0'};
`;

const ScrollableDiv = styled.div`
  padding-bottom: 80px;
  display: flex;
`;

const AddRankingButton = styled(Button)`
  padding-right: 64px;
  margin-top: -47px;
  width: auto;
  display: flex;
  &&& svg {
    height: 9px;
    width: 9px;
  }
`;

const AddFieldWrapper = styled.div<{ isDisabled: boolean }>`
  ${props =>
    props.isDisabled
      ? `.field-button, .field-button:hover {
    background-color: ${theme.colors.disabledButton1} !important;
    border-color: ${theme.colors.disabledButton2} !important;
    color: ${theme.colors.disabledButton2} !important;
    cursor: not-allowed !important;
  }
  `
      : ``}
`;

export type Props = {
  fields: any[];
  isAddingRanking: boolean;
  isEditingRanking: boolean;
  canAddRanking?: boolean;
  handleToggleAdd: () => void;
  handleToggleEdit: () => void;
  handleCreateRanking: (ranking: any) => void;
  handleUpdateRanking: (
    fieldId: number,
    ranking: RankingColumn,
    prevEdit?: RankingColumn,
    memberLookupId?: number,
  ) => void;
  handleDeleteRanking: (fieldId: number) => void;
  handleSetIndexRankingId: (fieldId?: number) => void;
  onDrag?: (order: any[]) => void;
};

const Component = ({
  fields,
  isAddingRanking,
  isEditingRanking,
  handleToggleAdd,
  handleToggleEdit,
  canAddRanking = true,
  handleCreateRanking,
  handleUpdateRanking,
  handleDeleteRanking,
  handleSetIndexRankingId,
  onDrag,
}: Props): React.ReactElement => {
  const [isCreateTipOpen, setIsCreateTipOpen] = useState<boolean>(false);
  useEffect(() => {
    const didJustReachLimit =
      fields.filter(field => !field.isDefault).length === 5;
    if (didJustReachLimit) {
      setIsCreateTipOpen(true);
    }
  }, [fields.length]);

  const limitReached = fields.filter(field => !field.isDefault).length === 5;
  return (
    <Wrapper
      withTooltip={isCreateTipOpen}
      isEditing={isEditingRanking}
      isAdding={isAddingRanking}>
      <ScrollableDiv>
        <RankingColumnsTable
          initialData={fields}
          type="default"
          isAdding={isAddingRanking}
          isEditing={isEditingRanking}
          onCancelAdd={handleToggleAdd}
          toggleEdit={handleToggleEdit}
          onCreate={ranking => handleCreateRanking(ranking)}
          onUpdate={(fieldId, ranking, prevEdited, memberLookupId) =>
            handleUpdateRanking(fieldId, ranking, prevEdited, memberLookupId)
          }
          onDelete={fieldId => handleDeleteRanking(fieldId)}
          onIndex={handleSetIndexRankingId}
          fieldNames={fields.map((field: RankingColumn): string => {
            return field['name'];
          })}
          onDrag={onDrag}
          isFromAddRanking
          isDraggable
        />
      </ScrollableDiv>
      {!isAddingRanking ? (
        <AddFieldWrapper isDisabled={limitReached}>
          {limitReached ? (
            <Tooltip
              message={words.didJustReachLimit}
              direction="bottom"
              isOpen={isCreateTipOpen}
              preserveText
              onChange={(ev, { open }) => {
                if (!open) {
                  setIsCreateTipOpen(false);
                }
              }}
              triggerElement={
                <AddRankingButton
                  title={words.addRankingColumn}
                  theme="tertiary"
                  icon={Plus}
                  onPress={() => setIsCreateTipOpen(true)}
                  className="field-button"
                />
              }
            />
          ) : (
            <AddRankingButton
              title={words.addRankingColumn}
              theme="tertiary"
              icon={Plus}
              onPress={() => {
                handleToggleAdd();
                if (isEditingRanking) {
                  handleToggleEdit();
                }
              }}
              className="field-button"
            />
          )}
        </AddFieldWrapper>
      ) : null}
    </Wrapper>
  );
};

export default Component;
