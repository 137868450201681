import React, { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './weekCalendar.scss';
import PrevImg from 'assets/icons/prevBtn.svg';
import NextImg from 'assets/icons/nextBtn.svg';
import { dateToStringOnlyDayMonth, getWeeksInMonth } from 'utils/common';

type PropType = {
  display: boolean;
  dateCalendar: Date;
  currentWeek: number;
  handleChangeDate: (_date: Date) => void;
  nextWeek: () => void;
  prevWeek: () => void;
};

export default function WeekCalendar(props: PropType) {
  const {
    display,
    dateCalendar,
    handleChangeDate,
    currentWeek,
    nextWeek,
    prevWeek,
  } = props;
  const [currentYear, setCurrentYear] = useState(dateCalendar.getFullYear());
  // Now Month please make sure + 1
  const [currentMonth, setCurrentMonth] = useState(dateCalendar.getMonth());
  const [weekCollect, setWeekCollect] = useState([]);
  const [startWeekDate, setStartWeekDate] = useState<Date>(new Date());
  const [endWeekDate, setWeekDate] = useState<Date>(new Date());

  useEffect(() => {
    const it = getWeeksInMonth(currentYear, currentMonth);
    setWeekCollect(it);
    setStartWeekDate(
      new Date(currentYear, currentMonth, it[currentWeek].start),
    );
    setWeekDate(new Date(currentYear, currentMonth, it[currentWeek].end));
  }, [currentYear, currentMonth, currentWeek]);

  function formatDate(date: Date) {
    return date.getDate().toString();
  }

  function nextWeekComp() {
    if (currentWeek < weekCollect.length - 1) {
      nextWeek();
    }
  }

  return (
    <div
      className={`displayCalendarContainer week ${display ? 'block' : 'hide'}`}>
      <Calendar
        locale="ja-JP"
        view="month"
        calendarType="US"
        formatDay={(locale, date) => formatDate(date)}
        allowPartialRange={false}
        // value={dateCalendar}
        // onChange={(e: Date) => handleChangeDate(e)}
        className="week-calendar"
        activeStartDate={startWeekDate}
        minDate={startWeekDate}
        maxDate={endWeekDate}
        tileClassName={({ date }) => {
          const isChosenWeek =
            startWeekDate.getTime() <= date.getTime() &&
            date.getTime() <= endWeekDate.getTime();

          if (!isChosenWeek) {
            return 'hiddenWeek';
          }

          return 'visibleWeek';
        }}
      />
      <div className="weekInfo">
        <div
          className="prevBtn"
          onClick={() => {
            if (currentWeek > 0) {
              prevWeek();
            }
          }}>
          <img src={PrevImg} />
        </div>
        <div className="weekRange">
          {dateToStringOnlyDayMonth(startWeekDate)}～
          {dateToStringOnlyDayMonth(endWeekDate)}
        </div>
        <div className="nextBtn" onClick={nextWeekComp}>
          <img src={NextImg} />
        </div>
      </div>
    </div>
  );
}
