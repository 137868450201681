import React, { useEffect, useRef, useState } from 'react';
import './memberAccountSetting.scss';
import DefaultAvatar from 'assets/icons/default-avatar.svg';
import EditAvatar from 'assets/icons/edit-avatar.svg';
import { AccountSettingForm } from 'components/templates/AccountSettingForm';
import {
  accountSettingType,
  accountSettingSetCompanyErrType,
} from 'type/member';
import { OrangeButton } from 'components/atoms/OrangeButton';
import axios from 'axios';
import urls from 'constants/urls';
import authHeader from 'api/auth-header';
import {
  validateUpdateCompanyId,
  validateUpdateFullProfile,
} from './validates';
import {
  changeInfoCurrentUser,
  extractError,
  getCurrentUser,
  isEmptyObject,
  makeRandomKey,
  removeCurrentUser,
  replaceUser,
} from 'utils/common';
import { Loading } from 'components/molecules/Loading';
import words from 'constants/words';
import { useNavigate } from 'react-router-dom';
import { ChangePasswordModal } from 'components/molecules/ChangePasswordModal';
import BlackScreen from 'components/atoms/BlackScreen/BlackScreen';

const defaultValue = {
  full_name: '',
  company_id: '',
  user_id: '',
  group_id: '',
  role: '',
  email: '',
};

const defaultSetCompanyId = {
  full_name: '',
  company_id: '',
  user_id: '',
};

type PropType = {
  modeSee?: boolean;
};

export default function MemberAccountSetting(props: PropType) {
  const { modeSee } = props;
  const [isSetCompanyId, setIsSetCompanyId] = useState<boolean>(false);
  const [valueUser, setValueUser] = useState<accountSettingType>(defaultValue);
  const [errorResponse, setErrorResponse] = useState('');
  const [isLoading, setIsLoading] = useState('none');
  const inputRef = useRef<HTMLInputElement>(null);
  const [picture, setPicture] = useState<string | undefined>(undefined);
  const acceptedType = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'];
  const maxFileSize = 5 * 1024 * 1024; //5MB
  const navigate = useNavigate();
  const [roleName, setRoleName] = useState('');
  const [errors, setErrors] = useState<accountSettingSetCompanyErrType>(
    defaultSetCompanyId,
  );
  const [isApprover, setIsApprover] = useState(false);
  const [showChangePass, setShowChangePass] = useState(false);
  const [hash, setHash] = useState(makeRandomKey(10));

  useEffect(() => {
    const user = getCurrentUser();
    if (isEmptyObject(user)) {
      navigate(urls.member_sign_in);
    } else {
      setRoleName(user.role);
    }
  }, []);

  useEffect(() => {
    const data = {};
    axios
      .post(urls.check_approver, data, { headers: authHeader() })
      .then(res =>
        res.data.is_approver ? setIsApprover(true) : setIsApprover(false),
      )
      .catch();
  }, []);

  function handleUpdateValues(data: accountSettingType) {
    setValueUser(data);
  }

  async function updateCompanyId() {
    const listError = await validateUpdateCompanyId(valueUser);
    if (Object.entries(listError).length !== 0) {
      setErrors(listError);
      return;
    }
    setIsLoading('block');
    setErrors(defaultValue);

    const data = {
      company_id: valueUser.company_id,
      full_name: valueUser.full_name,
    };

    await axios
      .post(urls.member_update_info, data, {
        headers: authHeader(),
      })
      .then(() => {
        setErrorResponse('');
        setErrors(defaultValue);
        setIsLoading('none');
        const user = getCurrentUser();
        user.company_id = parseInt(valueUser.company_id);
        user.full_name = valueUser.full_name;
        changeInfoCurrentUser(user);
        window.location.reload();
      })
      .catch(err => {
        if (err.response.data.errors) {
          const errorSv = extractError(err.response.data.errors);
          setErrorResponse(errorSv);
        }
        setIsLoading('none');
      });
  }

  async function updateFullProfile() {
    const listError = await validateUpdateFullProfile(valueUser);
    if (Object.entries(listError).length !== 0) {
      setErrors(listError);
      return;
    }
    setIsLoading('block');
    setErrors(defaultValue);

    const data = {
      company_id: valueUser.company_id,
      full_name: valueUser.full_name,
      role: valueUser.role,
      group_id: parseInt(valueUser.group_id),
    };

    await axios
      .post(urls.member_update_info, data, {
        headers: authHeader(),
      })
      .then(() => {
        setErrorResponse('');
        setErrors(defaultValue);
        setIsLoading('none');
        const user = getCurrentUser();
        user.full_name = valueUser.full_name;
        user.temp_role = valueUser.temp_role;
        user.group_id = valueUser.group_id;
        changeInfoCurrentUser(user);
        // window.location.reload();
      })
      .catch(err => {
        if (err.response.data.errors) {
          const errorSv = extractError(err.response.data.errors);
          setErrorResponse(errorSv);
        }
        setIsLoading('none');
      });
  }

  const handleClickChangeAvatar = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  function handleFileChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }

    if (fileObj.size > maxFileSize) {
      setErrorResponse(words.fileSizeAccepted);
      return;
    }

    if (!acceptedType.includes(fileObj.type)) {
      setErrorResponse(words.fileTypeNotAllowed);
      return;
    }

    setErrorResponse('');
    setIsLoading('block');
    if (event.target.files) {
      setPicture(URL.createObjectURL(event.target.files[0]));
      const formData = new FormData();
      formData.append('image', event.target.files[0]);
      axios
        .post(urls.handle_update_avatar, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            ...authHeader(),
          },
        })
        .then(() => {
          setIsLoading('none');
        })
        .catch(err => {
          if (err.response.data.errors) {
            const errorSv = extractError(err.response.data.errors);
            setErrorResponse(errorSv);
          }
          setIsLoading('none');
        });
    }
  }

  function handleRemoveAndGotoForgot() {
    removeCurrentUser();
    navigate(urls.forgot_page);
  }

  function activeLoading(isLoadingProps: boolean) {
    if (isLoadingProps) {
      setIsLoading('block');
    } else {
      setIsLoading('none');
      setShowChangePass(false);
      setHash(makeRandomKey(10));
    }
  }

  useEffect(() => {
    setIsLoading('block');
    const fetchData = async () => {
      await axios
        .get(urls.member_info, { headers: authHeader() })
        .then(res => {
          const data = res.data.data;
          if (data.company === null) {
            setIsSetCompanyId(false);
          } else {
            setIsSetCompanyId(true);
          }
          setValueUser({
            full_name: data.full_name,
            company_id: data.company ? data.company.id : '',
            user_id: data.id,
            group_id: data.group ? data.group.id.toString() : '',
            role: data.role,
            email: data.email,
          });
          setIsLoading('none');
          const user = getCurrentUser();
          user.role = data.role;
          user.full_name = data.full_name;
          user.is_done_initial = data.is_done_initial;
          replaceUser(user);
          if (data.photo) {
            setPicture(`${process.env.REACT_APP_S3_FRONT}${data.photo}`);
          }
        })
        .catch(err => {
          if (err.response.data.errors) {
            const errorSv = extractError(err.response.data.errors);
            setErrorResponse(errorSv);
          }
          setIsLoading('none');
        });
    };

    fetchData();
    setErrors(defaultValue);
  }, []);

  return (
    <div className="member-account-st-page">
      <Loading display={isLoading} />
      <BlackScreen
        display={showChangePass}
        onClick={() => {
          setShowChangePass(false);
          setHash(makeRandomKey(10));
        }}
        style={{
          zIndex: showChangePass ? '2' : '1',
        }}
      />
      <div className="member-image">
        <div className="image-profile">
          <img
            src={
              picture && typeof picture !== 'undefined'
                ? picture
                : DefaultAvatar
            }
          />
          <input
            style={{ display: 'none' }}
            ref={inputRef}
            type="file"
            onChange={handleFileChange}
            accept="image/png, image/jpeg, image/jpg, image/gif"
          />
          <div
            className="member-edit"
            onClick={handleClickChangeAvatar}
            style={{ display: modeSee ? 'none' : 'block' }}>
            <img src={EditAvatar} />
          </div>
        </div>
      </div>
      <AccountSettingForm
        handleUpdateValues={data => handleUpdateValues(data)}
        listError={errors}
        isSetCompanyId={isSetCompanyId}
        userInfo={valueUser}
        modeSee={modeSee}
      />
      <p
        className="errorFromServer"
        dangerouslySetInnerHTML={{ __html: errorResponse }}></p>
      {!modeSee ? (
        <div className="forgot-text">
          <span onClick={() => setShowChangePass(true)}>
            パスワードを変更する
          </span>
        </div>
      ) : (
        ''
      )}
      {modeSee ? (
        <OrangeButton
          text="編集"
          disabled={isLoading === 'block' ? true : false}
          onClick={() => navigate(urls.account_setting)}
          style={{ width: '144px', height: '42px', marginTop: '15px' }}
        />
      ) : (
        <>
          {/* {roleName !== '' && !['approver', 'admin'].includes(roleName) ? ( */}
          {!isApprover ? (
            <OrangeButton
              text="保存"
              disabled={isLoading === 'block' ? true : false}
              onClick={() =>
                !isSetCompanyId ? updateCompanyId() : updateFullProfile()
              }
              style={{ width: '144px', height: '42px', marginTop: '15px' }}
            />
          ) : (
            <div className="d-flex">
              <div style={{ width: '50%' }}>
                <OrangeButton
                  text="リストへ戻る"
                  disabled={isLoading === 'block' ? true : false}
                  onClick={() => navigate(urls.member_list)}
                  style={{
                    width: '164px',
                    height: '42px',
                    marginTop: '15px',
                    background: 'unset',
                    backgroundColor: '#7B809A',
                  }}
                />
              </div>
              <div style={{ width: '50%' }}>
                <OrangeButton
                  text="保存"
                  disabled={isLoading === 'block' ? true : false}
                  onClick={() =>
                    !isSetCompanyId ? updateCompanyId() : updateFullProfile()
                  }
                  style={{ width: '144px', height: '42px', marginTop: '15px' }}
                />
              </div>
            </div>
          )}
        </>
      )}
      <ChangePasswordModal
        isShow={showChangePass}
        onHideModal={() => {
          setShowChangePass(false);
          setHash(makeRandomKey(10));
        }}
        hash={hash}
        activeLoading={isLoadingProps => activeLoading(isLoadingProps)}
      />
    </div>
  );
}
