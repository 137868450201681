import { HttpAdapter } from '../../usecases/ports/HttpAdapter';
import { PaginationQuery } from '../../domain/entities/request';
import { PaginatedResponse } from '../../domain/entities/response';
import RecordRepository from '../../usecases/ports/RecordRepository';
import {
  RecordReference,
  RecordApproval,
  RecordApprovalEntryApplication,
  FetchRecordApprovalsParam,
  FetchRecordApprovalsRankingNamesParams,
  RecordApprovalRanking,
} from '../../domain/entities/record';

export default class RecordRepositoryImpl implements RecordRepository {
  httpAdapter: HttpAdapter;
  urls: {
    [key: string]: (query: { [key: string]: string | number }) => string;
  };

  constructor(
    httpAdapter: HttpAdapter,
    urls: {
      [key: string]: (query: { [key: string]: string | number }) => string;
    },
  ) {
    this.httpAdapter = httpAdapter;
    this.urls = urls;
  }

  fetchReferenceDetails = async (payload: {
    companyId: number;
    memberId: number;
    rankingId: number;
    entryId: number;
  }): Promise<RecordReference> => {
    const response = await this.httpAdapter.get(
      this.urls.getRecordReferenceDetails(payload),
      {},
    );

    const data = response.data;

    return data;
  };

  fetch = async (
    query: PaginationQuery & { companyId: number; memberId: number },
  ): Promise<PaginatedResponse & { results: RecordReference[] }> => {
    const { companyId, memberId } = query;
    const response = await this.httpAdapter.get(
      this.urls.getRecordReferences({
        companyId: `${companyId}`,
        memberId: `${memberId}`,
      }),
      {
        params: query,
      },
    );

    return response.data;
  };

  fetchRecordApprovals = async (
    query: PaginationQuery & FetchRecordApprovalsParam,
  ): Promise<PaginatedResponse & { results: RecordApproval[] }> => {
    const { companyId, memberId } = query;
    const response = await this.httpAdapter.get(
      this.urls.getRecordApprovals({
        companyId: `${companyId}`,
        memberId: `${memberId}`,
      }),
      {
        params: query,
      },
    );

    const records = {
      ...response.data,
      results: response.data.results.map(
        (
          record: RecordApproval & {
            sales_date: string;
            entry_application: RecordApprovalEntryApplication[];
            application_date: Date;
          },
        ) => {
          return {
            ...record,
            salesDate: record.sales_date,
            entryApplication: record.entry_application[0],
            applicationDate: record.application_date,
          };
        },
      ),
    };
    return records;
  };

  fetchRecordApprovalsRankingNames = async (
    query: FetchRecordApprovalsRankingNamesParams,
  ): Promise<{ results: string[] }> => {
    const { companyId, memberId } = query;
    const response = await this.httpAdapter.get(
      this.urls.getRecordApprovalsRankingNames({
        companyId: `${companyId}`,
        memberId: `${memberId}`,
      }),
      {
        params: query,
      },
    );

    const rankings: string[] = response.data.map(
      (data: { ranking: RecordApprovalRanking }) => data.ranking.name,
    );
    return {
      results: Array.from(new Set(rankings)).sort((a, b) => a.localeCompare(b)),
    };
  };
}
