import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { Modal } from '../../molecules/Modal';
import { Notification } from '../../molecules/Notification';
import words from '../../../constants/words';
import { Member } from '../../../domain/entities/member';
import { useMemberHooks } from '../../../hooks/member';
import { useMutation } from 'react-query';
import { Props as INotification } from '../../molecules/Notification/Notification';
import data from '../../../constants/data';

const StyledModal = styled(Modal)`
  .bx--modal-header__heading {
    white-space: pre-wrap;
  }
`;

const InfoDiv = styled.div`
  margin-top: 15px;
  margin-bottom: 43px;
`;

const InfoP = styled.p`
  margin-bottom: 16px;
`;

const DeleteNotificationErrorWrapper = styled.div`
  padding-bottom: 1px;

  .bx--inline-notification.bx--inline-notification--error {
    .bx--inline-notification__close-button {
      display: none;
    }

    .bx--inline-notification__details {
      margin-right: 8px;
    }

    .bx--inline-notification__text-wrapper {
      padding: 6px 0;
    }
  }
`;

export type Props = {
  isOpen: boolean;
  memberToDelete?: Member;
  onClose: () => void;
  onSubmit: (
    newlyDeletedMember: Member | undefined,
    toast: INotification,
  ) => void;
};

const Component = ({
  isOpen,
  memberToDelete,
  onClose,
  onSubmit,
}: Props): React.ReactElement => {
  const { useDeleteMember } = useMemberHooks();
  const { deleteMember } = useDeleteMember();

  const [inlineErrorMessage, setInlineErrorMessage] = useState('');

  const handleClose = useCallback(() => {
    setInlineErrorMessage('');
    onClose();
  }, []);

  const { mutate: deleteMemberMutation, isLoading } = useMutation(
    () => {
      if (!memberToDelete) {
        throw new Error(`'Member' not selected`);
      }
      return deleteMember({
        companyId: memberToDelete.companyId,
        memberId: memberToDelete.id,
      });
    },
    {
      onSuccess: () => {
        setInlineErrorMessage('');
        const newToast: INotification = {
          kind: 'success',
          title: words.successDeleteMember,
          subtitle: words.deleteMemberToastSuccess,
        };
        onSubmit(memberToDelete, newToast);
        handleClose();
      },
      onError: error => {
        const {
          response: {
            status: responseStatus,
            data: { error_code: responseErrorCode },
          },
        } = error as {
          response: { status: number; data: { error_code: number } };
        };

        if (responseStatus === 409) {
          setInlineErrorMessage(
            responseErrorCode === 1
              ? words.memberDeletionInlineApproverSubError
              : words.memberDeletionInlineAdminSubError,
          );
        } else {
          onSubmit(memberToDelete, {
            kind: 'error',
            title: words.error,
            subtitle: words.errorOccuredToastSub,
          });
          handleClose();
        }
      },
    },
  );

  return (
    <StyledModal
      open={isOpen}
      title={words.deleteMemberConfirmation}
      label={words.sureToDeleteMember}
      confirmButtonText={words.yesDelete}
      onConfirm={deleteMemberMutation}
      onCancel={handleClose}
      onClose={handleClose}
      confirmButtonTheme="secondary"
      confirmButtonDisabled={isLoading}>
      <InfoDiv>
        <InfoP>
          {words.name}: {memberToDelete?.name}
        </InfoP>
        <InfoP>
          {words.group}:{' '}
          {memberToDelete
            ? memberToDelete.group?.name || words.unassignedGroup
            : ''}
        </InfoP>
        <InfoP>
          {words.role}:{' '}
          {data.memberRoles.find(e => e.id === memberToDelete?.roleName)
            ?.name || ''}
        </InfoP>
      </InfoDiv>
      {inlineErrorMessage && (
        <DeleteNotificationErrorWrapper>
          <Notification
            inline={true}
            kind="error"
            title={words.memberDeletionInlineTitleError}
            subtitle={inlineErrorMessage}
          />
        </DeleteNotificationErrorWrapper>
      )}
    </StyledModal>
  );
};

export default Component;
