import { HttpAdapter } from '../../usecases/ports/HttpAdapter';
import { PaginationQuery } from '../../domain/entities/request';
import { PaginatedResponse } from '../../domain/entities/response';
import RankingRepository from '../../usecases/ports/RankingRepository';
import {
  Ranking,
  RankingFields,
  RankingPeriod,
} from '../../domain/entities/rankings';
import {
  RankingApplication,
  RankingFieldsLookup,
  RankingResult,
} from '../../domain/entities/ranking';

export default class RankingRepositoryImpl implements RankingRepository {
  httpAdapter: HttpAdapter;
  urls: {
    [key: string]: (query: { [key: string]: string | number }) => string;
  };

  constructor(
    httpAdapter: HttpAdapter,
    urls: {
      [key: string]: (query: { [key: string]: string | number }) => string;
    },
  ) {
    this.httpAdapter = httpAdapter;
    this.urls = urls;
  }

  fetch = async (
    query: PaginationQuery & { companyId: number },
  ): Promise<PaginatedResponse & { results: Ranking[] }> => {
    const { companyId, page_size } = query;
    const response = await this.httpAdapter.get(
      this.urls.getRankingList({ companyId: `${companyId}` }),
      {
        params: query,
      },
    );

    return page_size
      ? response.data
      : {
          count: response.data.length,
          next: null,
          previous: null,
          results: response.data,
        };
  };

  fetchRankingDetails = async ({
    companyId,
    rankingId,
  }: {
    companyId: number;
    rankingId: number;
  }): Promise<Ranking> => {
    const response = await this.httpAdapter.get(
      this.urls.getRankingDetails({
        companyId: `${companyId}`,
        rankingId: `${rankingId}`,
      }),
      {},
    );
    return response.data;
  };

  create = async ({
    companyId,
    name,
    fields,
    periods,
    memberIds,
    user_id,
  }: {
    companyId: number;
    name: string;
    fields: Omit<RankingFields, 'id' | 'ranking_id'>[];
    periods: RankingPeriod[];
    memberIds: number[];
    user_id: number;
  }): Promise<void> => {
    const payload = {
      name,
      ranking_fields: fields,
      periods,
      ranking_members: memberIds.map(memberId => ({
        member_id: memberId,
      })),
      user_id,
    };
    await this.httpAdapter.post(
      this.urls.createRanking({ companyId: `${companyId}` }),
      payload,
    );
  };

  update = async (
    { companyId, rankingId }: { companyId: number; rankingId: number },
    payload: Partial<Ranking & { user_id: number }>,
  ): Promise<object | null> => {
    const response = await this.httpAdapter.patch(
      this.urls.updateRanking({
        companyId: `${companyId}`,
        rankingId: `${rankingId}`,
      }),
      payload,
    );
    return response.data;
  };

  delete = async ({
    companyId,
    rankingId,
  }: {
    companyId: number;
    rankingId: number;
  }): Promise<void> => {
    await this.httpAdapter.delete(
      this.urls.deleteRanking({
        companyId: `${companyId}`,
        rankingId: `${rankingId}`,
      }),
      {},
    );
  };

  get = async (payload: {
    companyId: number;
    rankingId: number;
    appliedByMember?: number;
    isActive?: boolean;
  }): Promise<Ranking> => {
    const { companyId, rankingId, isActive } = payload;
    const query = { companyId, rankingId };
    const response = await this.httpAdapter.get(
      this.urls.getIndividualRanking(query),
      {
        params: { isActive },
      },
    );

    return response.data;
  };

  fetchRankingAppList = async (
    query: PaginationQuery & { companyId: number; memberId: number },
  ): Promise<PaginatedResponse & { results: RankingApplication[] }> => {
    const { companyId, memberId, page_size } = query;
    const response = await this.httpAdapter.get(
      this.urls.getRankingAppList({ companyId, memberId }),
      {
        params: query,
      },
    );

    return page_size
      ? response.data
      : {
          count: response.data.length,
          next: null,
          previous: null,
          results: response.data,
        };
  };

  async checkRankingNameExists(query: {
    companyId: number;
    rankingName: string;
  }): Promise<object | null> {
    const { companyId, rankingName } = query;
    const response = await this.httpAdapter.head(
      this.urls.checkRankingNameExists({
        companyId: `${companyId}`,
        rankingName: `${rankingName}`,
      }),
      {
        params: query,
      },
    );
    return response;
  }

  fetchRankingPeriod = async (query: {
    companyId: number;
    rankingId: number;
    isActive: boolean;
    isVisible: boolean;
  }): Promise<RankingPeriod[]> => {
    const { companyId, rankingId, isActive, isVisible } = query;

    const response = await this.httpAdapter.get(
      this.urls.fetchRankingPeriod({ companyId, rankingId }),
      {
        params: {
          isActive,
          isVisible,
        },
      },
    );

    return response.data;
  };

  async insertRankingLog({
    companyId,
    rankingId,
    id,
    detail_type,
  }: {
    companyId: number;
    rankingId: number;
    id: number;
    detail_type: string;
  }): Promise<object | null> {
    const payload = {
      detail_type,
      member: id,
      ranking: rankingId,
    };
    const response = await this.httpAdapter.post(
      this.urls.fetchRankingLogs({ companyId, rankingId }),
      payload,
    );
    return response;
  }

  async fetchRankingLogs(query: {
    companyId: number;
    rankingId: number;
  }): Promise<object | null> {
    const { companyId, rankingId } = query;
    const response = await this.httpAdapter.get(
      this.urls.fetchRankingLogs({ companyId, rankingId }),
      {},
    );
    const logs = response.data.map(
      (obj: {
        created_at: Date;
        detail_type: string;
        id: number;
        member_id: number;
        ranking_id: number;
        updated_at: Date;
        user_details: {
          name: string;
          photo: string;
        };
      }) => {
        return {
          id: obj.id,
          detail_type: obj.detail_type,
          member_id: obj.member_id,
          ranking_id: obj.ranking_id,
          createdAt: obj.created_at,
          updatedDate: obj.updated_at,
          user_details: {
            name: obj.user_details.name,
            photoUrl: obj.user_details.photo,
          },
        };
      },
    );
    return logs;
  }

  fetchRankingResult = async (
    query: PaginationQuery & {
      companyId: number;
      rankingId: number;
      rankingPeriodStart?: Date;
      rankingPeriodEnd?: Date;
    },
  ): Promise<PaginatedResponse & { results: RankingResult[] }> => {
    const {
      companyId,
      rankingId,
      page_size,
      page,
      rankingPeriodStart,
      rankingPeriodEnd,
    } = query;

    const response = await this.httpAdapter.get(
      this.urls.fetchRankingResult({ companyId, rankingId }),
      {
        params: {
          page_size,
          page,
          rankingPeriodStart,
          rankingPeriodEnd,
        },
      },
    );

    return response.data;
  };

  fetchRankingFieldsLookup = async (query: {
    companyId: number;
    rankingId: number;
  }): Promise<RankingFieldsLookup[]> => {
    const { companyId, rankingId } = query;

    const response = await this.httpAdapter.get(
      this.urls.fetchRankingFieldsLookup({ companyId, rankingId }),
      {
        params: {},
      },
    );

    return response.data;
  };
}
