import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ApplicationSent } from '../../templates/ApplicationSent';

const ApplicationSentPage = (): React.ReactElement => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!location.state.email) {
      navigate('/login');
    }
  }, [location.state]);

  return <ApplicationSent email={location.state.email} />;
};

export default ApplicationSentPage;
