import {
  buildFetchRecordReferences,
  fetchRecordReferencesUseCase,
} from './fetch-record-references';
import {
  buildFetchRecordApprovals,
  fetchRecordApprovalsUseCase,
} from './fetch-record-approvals';
import {
  buildFetchReferenceDetails,
  fetchReferenceDetailsUseCase,
} from './fetch-reference-details';
import RecordRepository from '../../ports/RecordRepository';
import {
  buildFetchRecordApprovalsRankingNames,
  fetchRecordApprovalsRankingNamesUseCase,
} from './fetch-record-approvals-ranking-names';

export default (dependencies: {
  recordRepo: RecordRepository;
}): {
  fetchRecordReferences: fetchRecordReferencesUseCase;
  fetchRecordApprovals: fetchRecordApprovalsUseCase;
  fetchReferenceDetails: fetchReferenceDetailsUseCase;
  fetchRecordApprovalsRankingNames: fetchRecordApprovalsRankingNamesUseCase;
} => {
  const { recordRepo } = dependencies;

  const fetchRecordReferences = buildFetchRecordReferences({ recordRepo });
  const fetchRecordApprovals = buildFetchRecordApprovals({ recordRepo });
  const fetchReferenceDetails = buildFetchReferenceDetails({ recordRepo });
  const fetchRecordApprovalsRankingNames = buildFetchRecordApprovalsRankingNames(
    { recordRepo },
  );

  return {
    fetchRecordReferences,
    fetchRecordApprovals,
    fetchReferenceDetails,
    fetchRecordApprovalsRankingNames,
  };
};
