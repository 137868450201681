import { createContext, useContext } from 'react';
import {
  EntryApplicationForm,
  CreateEntryApplication,
} from '../../domain/entities/entry';

export type EntryHooks = {
  useFetchEntry: () => {
    fetchFormFieldsApprovers: (
      companyId: number,
      memberId: number,
      rankingId: number,
    ) => Promise<EntryApplicationForm | undefined>;
  };
  useCreateEntry: () => {
    createEntryApplication(
      companyId: number,
      memberId: number,
      rankingId: number,
      payload: CreateEntryApplication,
    ): Promise<Response>;
  };
  useUpdateEntry: () => {
    updateEntryApplication(
      companyId: number,
      memberId: number,
      rankingId: number,
      entryId: number,
      payload: CreateEntryApplication,
    ): Promise<Response>;
  };
  useDeleteEntry: () => {
    deleteEntryApplication(
      companyId: number,
      memberId: number,
      rankingId: number,
      entryId: number,
    ): Promise<void>;
  };
};

export const EntryHooksContext = createContext<EntryHooks | null>(null);

export const useEntryHooks = (): EntryHooks => {
  const context = useContext(EntryHooksContext);
  if (!context) {
    throw new Error(`Context not instantiated`);
  }
  return context;
};
