import React, { useEffect } from 'react';
import words from '../../../constants/words';
import {
  AccountSettingsIcon,
  MemberManagementIcon,
  RankingIcon,
  RankingListIcon,
  RecordApplicationIcon,
  RecordApprovalIcon,
} from '../../atoms/Icons';
import { SubHeader } from '../../atoms/Subheader';
import {
  ContentHeaders,
  ContentWrapper,
  ListOptionWrapper,
  MobileContentHeaders,
  MobileContentWrapper,
  MobileListOptionWrapper,
  MobileOptionWrapper,
  OptionWrapper,
} from './elements';

export type IHomePage = {
  onClickRouteList: (routeName: string) => void;
  showManageFunctions: boolean;
};

const Component = ({
  onClickRouteList,
  showManageFunctions,
}: IHomePage): React.ReactElement => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <SubHeader title={words.homepage}></SubHeader>
      <ContentWrapper>
        <ContentHeaders>{words.basicHomepage}</ContentHeaders>
        <ListOptionWrapper>
          <OptionWrapper
            className="optionWrapper"
            onClick={() => onClickRouteList('RecordApplication')}>
            <RecordApplicationIcon />
            <span>{words.recordApplications}</span>
          </OptionWrapper>
          <OptionWrapper
            className="optionWrapper"
            onClick={() => onClickRouteList('Ranking')}>
            <RankingIcon></RankingIcon>
            <span>{words.rankings}</span>
          </OptionWrapper>
          <OptionWrapper
            className="optionWrapper"
            onClick={() => onClickRouteList('AccountSettings')}>
            <AccountSettingsIcon />
            <span>{words.accountSettings}</span>
          </OptionWrapper>
        </ListOptionWrapper>

        {showManageFunctions && (
          <>
            <ContentHeaders>{words.manageFunctions}</ContentHeaders>
            <ListOptionWrapper>
              <OptionWrapper
                className="optionWrapper"
                onClick={() => onClickRouteList('RankingList')}>
                <RankingListIcon />
                <span>{words.rankingLists}</span>
              </OptionWrapper>
              <OptionWrapper
                className="optionWrapper"
                onClick={() => onClickRouteList('MemberManagement')}>
                <MemberManagementIcon />
                <span>{words.memberManagements}</span>
              </OptionWrapper>
              <OptionWrapper
                className="optionWrapper"
                onClick={() => onClickRouteList('RecordApproval')}>
                <RecordApprovalIcon />
                <span>{words.recordApprovals}</span>
              </OptionWrapper>
            </ListOptionWrapper>
          </>
        )}
      </ContentWrapper>
      <MobileContentWrapper>
        <MobileContentHeaders>{words.basicHomepage}</MobileContentHeaders>
        <MobileListOptionWrapper>
          <MobileOptionWrapper
            className="optionWrapper"
            onClick={() => onClickRouteList('RecordApplication')}>
            <RecordApplicationIcon />
            <span>{words.recordApplications}</span>
          </MobileOptionWrapper>
          <MobileOptionWrapper
            className="optionWrapper"
            onClick={() => onClickRouteList('Ranking')}>
            <RankingIcon />
            <span>{words.rankings}</span>
          </MobileOptionWrapper>
          <MobileOptionWrapper
            className="optionWrapper"
            onClick={() => onClickRouteList('AccountSettings')}>
            <AccountSettingsIcon />
            <span>{words.accountSettings}</span>
          </MobileOptionWrapper>
        </MobileListOptionWrapper>
        {showManageFunctions && (
          <>
            <MobileContentHeaders>{words.manageFunctions}</MobileContentHeaders>
            <MobileListOptionWrapper>
              <MobileOptionWrapper
                className="optionWrapper"
                onClick={() => onClickRouteList('RankingList')}>
                <RankingListIcon />
                <span>{words.rankingLists}</span>
              </MobileOptionWrapper>
              <MobileOptionWrapper
                className="optionWrapper"
                onClick={() => onClickRouteList('MemberManagement')}>
                <MemberManagementIcon />
                <span>{words.memberManagements}</span>
              </MobileOptionWrapper>
              <MobileOptionWrapper
                className="optionWrapper"
                onClick={() => onClickRouteList('RecordApproval')}>
                <RecordApprovalIcon />
                <span>{words.recordApprovals}</span>
              </MobileOptionWrapper>
            </MobileListOptionWrapper>
          </>
        )}
      </MobileContentWrapper>
    </>
  );
};

export default Component;
