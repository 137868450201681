import React from 'react';

type Props = {
  [key: string]: string | number | Function;
};

const UnverifiedEmailInfo = (props: Props): React.ReactElement => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15C11.866 15 15 11.866 15 8C15 6.14348 14.2625 4.36301 12.9497 3.05025C11.637 1.7375 9.85652 1 8 1ZM8.498 4H7.498V9.5H8.498V4ZM7.248 11.75C7.248 11.3358 7.58378 11 7.998 11C8.41221 11 8.748 11.3358 8.748 11.75C8.748 12.1642 8.41221 12.5 7.998 12.5C7.58378 12.5 7.248 12.1642 7.248 11.75ZM2 8C2 11.3137 4.68629 14 8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8Z"
        fill="#FF714F"
      />
    </svg>
  );
};

export default UnverifiedEmailInfo;
