import { Link } from 'carbon-components-react';
import styled, { createGlobalStyle } from 'styled-components';
import { theme } from '../../../config';
import { Dropdown } from '../../molecules/Dropdown';
import { MultiSelectDropdown } from '../../organisms/MultiSelectDropdown';
import { StatusTypes } from './MemberApplicationDetails';
import { TextInput } from '../../atoms/TextInput';
import { Tooltip } from '../../atoms/Tooltip';
import { Button } from '../../atoms/Button';

export const FirstColumn = styled.div`
  width: calc(50% - 40px);
  margin: 0 20px;

  .bx--date-picker--simple > div > div > .bx--date-picker__input:disabled {
    background: ${theme.colors.disabledButton1};
    border: 1px solid ${theme.colors.disabledToggle2};
  }

  @media ${theme.breakpoints.mobile} {
    width: calc(100% - 40px);
  }
`;

export const SecondColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(50% - 40px);
  margin: 0 20px;

  @media ${theme.breakpoints.mobile} {
    flex-direction: column-reverse;
    width: calc(100% - 40px);
  }
`;

export const DetailsContainer = styled.div`
  @media ${theme.breakpoints.mobile} {
    margin-top: -20px;
  }
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: row;

  & > ${FirstColumn} > div {
    margin-bottom: 48px;
  }

  @media ${theme.breakpoints.mobile} {
    flex-direction: column;

    & > ${FirstColumn} > div {
      margin-bottom: 3px;
    }
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 20px 0 48px 0;

  & > button,
  & > div > div > button {
    flex: 1;
    margin-right: 16px;
    max-width: 141px;
    width: 141px;
    height: 46px;
    padding: 10px;
  }

  @media ${theme.breakpoints.mobile} {
    flex-direction: column;
    margin: 13px 0 0 0;

    .bx--tooltip__label {
      width: 100%;
    }

    & > button,
    & > div > div > button {
      flex: 1;
      margin: 13px 0 0 0;
      max-width: 100%;
      width: 100%;
      height: 46px;
      padding: 10px;
    }
  }
`;

export const StatusTitle = styled.text`
  color: ${theme.colors.disabled02};
  font-size: 12px;
`;

export const Status = styled.div.attrs(
  (props: { status: StatusTypes }) => props,
)`
  color: ${props =>
    props.status === 'approved' ? theme.colors.support02 : theme.colors.text01};
  font-weight: 500;
  font-size: 16px;
`;

export const StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 40px;
  margin-bottom: 34px;

  &.member-app-details-status-mobile {
    display: none;
  }

  @media ${theme.breakpoints.mobile} {
    display: none;

    &&.member-app-details-status-mobile {
      display: flex;
      margin-bottom: 25px;
    }
  }
`;

export const StyledLink = styled(Link)`
  &:hover {
    cursor: pointer;
  }
`;

export const TransactionContainer = styled.div`
  & > div:last-child {
    margin-top: 16px;
  }

  @media ${theme.breakpoints.mobile} {
    margin: 5px 0 27px 0;

    & > div {
      margin-top: 16px;
    }
  }
`;

export const TransactionTitle = styled.text`
  font-weight: 500;
  font-size: 16px;
  padding-bottom: 1.5em;
`;

export const GlobalStyle = createGlobalStyle`
  & {
    .bx--tooltip__footer.bx--tooltip__footer.bx--tooltip__footer.bx--tooltip__footer.bx--tooltip__footer > button {
      width: 117px;
    }

    .bx--tooltip.bx--tooltip--bottom.bx--tooltip--align-end {
      max-width: 256px;
    }

    .bx--tooltip.bx--tooltip--bottom.bx--tooltip--align-end .bx--tooltip__caret {
      margin-right: 65px;
    }
  }

  @media ${theme.breakpoints.mobile} {
    & {
      .bx--tooltip.bx--tooltip.bx--tooltip.bx--tooltip.bx--tooltip {
        padding: 1rem;
      }

      .bx--tooltip.bx--tooltip.bx--tooltip.bx--tooltip.bx--tooltip .bx--tooltip__footer {
        margin-top: 17px;
      }

      .bx--tooltip__footer.bx--tooltip__footer.bx--tooltip__footer.bx--tooltip__footer.bx--tooltip__footer > button {
        width: 115px;
      }
    }
  }
`;

export const MultiSelectContainer = styled.div`
  margin-top: 45px;
  margin-bottom: 50px;

  @media ${theme.breakpoints.mobile} {
    margin-top: 23px;
    margin-bottom: 23px;
  }

  span {
    color: ${theme.colors.text02};
  }
`;

export const StyledDropdown = styled(MultiSelectDropdown)`
  .css-1insrsq-control {
    border: 1px solid ${theme.colors.inputBorderColor};
    background-color: ${theme.colors.white};
  }
  .css-1rhbuit-multiValue {
    background-color: ${theme.colors.chipColor};
    color: ${theme.colors.white};
  }
  .css-12jo7m5 {
    color: ${theme.colors.white};
  }
  .css-lr9fot-singleValue {
    color: ${theme.colors.text01};
  }
  .css-109onse-indicatorSeparator {
    display: none;
  }
  .css-tlfecz-indicatorContainer {
    color: ${theme.colors.icon01};
    svg {
      stroke: ${theme.colors.white};
      stroke-width: 1px;
    }
  }
  .labelContainer > span {
    color: ${theme.colors.text01};
  }
  .css-1rhbuit-multiValue > .css-12jo7m5 > .labelContainer > span {
    color: ${theme.colors.white};
  }
`;

export const StyledDropdownSingle = styled(Dropdown)`
  margin-top: 53px;
  margin-bottom: 49px;

  .bx--list-box__field:disabled {
    border: 1px solid ${theme.colors.inputBorderColor};
    background: ${theme.colors.white};
    color: ${theme.colors.text01};
    -webkit-text-fill-color: ${theme.colors.text01};
  }

  .bx--label--disabled {
    color: ${theme.colors.text02};
  }

  .bx--dropdown--disabled .bx--list-box__menu-icon svg {
    fill: ${theme.colors.icon01};
  }

  @media ${theme.breakpoints.mobile} {
    margin-top: 23px;
    margin-bottom: 0;
  }
`;

export const StyledTextInput = styled(TextInput)<{ $isMobile?: boolean }>`
  margin-top: 20px;
  display: ${({ $isMobile }): string => ($isMobile ? 'none' : 'inherit')};

  .bx--text-input:disabled {
    border: 1px solid ${theme.colors.inputBorderColor};
    background: ${theme.colors.white};
    color: ${theme.colors.text01};
    -webkit-text-fill-color: ${theme.colors.text01};
  }

  .bx--label--disabled {
    color: ${theme.colors.text02};
  }

  @media ${theme.breakpoints.mobile} {
    margin-top: 0;
    display: ${({ $isMobile }): string => ($isMobile ? 'inherit' : 'none')};
  }
`;

export const StyledTooltip = styled(Tooltip)<{ $isMobile?: boolean }>`
  display: ${({ $isMobile }): string => ($isMobile ? 'none' : '')};

  @media ${theme.breakpoints.mobile} {
    display: ${({ $isMobile }): string => ($isMobile ? 'block' : 'none')};
  }
`;

export const StyledButton = styled(Button)<{ $hideMobile?: boolean }>`
  @media ${theme.breakpoints.mobile} {
    ${({ $hideMobile }) => ($hideMobile ? 'display: none;' : '')}
  }
`;
