import { DataTableRow } from 'carbon-components-react';
import React from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import themes from '../../../config/themes';
import words from '../../../constants/words';
import { Ranking } from '../../../domain/entities/rankings';
import { AnchorTag } from '../../atoms/AnchorTag';
import SelectedStar from '../../atoms/Icons/SelectedStar';
import { TableLoader } from '../../atoms/Loading';
import { Table } from '../Table';
import {
  CustomDataTableSkeletonContainer,
  CustomDataTableSkeleton,
} from '../../organisms/DataTable/elements';

const StyledTable = styled.div<{
  $dataLength: number;
  $isMemberTable: boolean;
}>`
  &&& {
    p {
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 16px;
      margin-top: 32px;
    }
    p.groupLabel {
      font-weight: 400;
      font-size: 13px;
      margin-top: 0px;
      margin-bottom: 0px;
      background-color: ${themes.main.colors.ui03};
      color: ${themes.main.colors.fontColor};
      padding-top: 13px;
      text-indent: 15px;
      border: 3px solid ${themes.main.colors.white};
      border-bottom: unset;
      box-shadow: 0px 0px 8px 0px rgba(236, 215, 192, 0.8);
    }
    .bx--data-table-content {
      height: 100%;
      max-height: 340px;
      background: ${({ $dataLength }) =>
        $dataLength > 5 ? themes.main.colors.ui03 : ''};
      border: 3px solid ${themes.main.colors.white};
      border-top: ${({ $isMemberTable }) =>
        $isMemberTable ? 'unset;' : '3px solid' + themes.main.colors.white};
      box-shadow: ${({ $isMemberTable }) =>
        $isMemberTable
          ? '0px 10px 15px -12px rgba(236, 215, 192, 0.8);'
          : '0px 0px 8px 0px rgba(236, 215, 192, 0.8)'};

      /* width */
      ::-webkit-scrollbar {
        width: 7px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        background: ${themes.main.colors.text03};
        margin-top: 48px;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: ${themes.main.colors.ui04};
      }
    }
    .bx--data-table tbody tr:hover td,
    .bx--data-table tbody tr:hover th {
      background: ${themes.main.colors.hoverui};
    }
    .basicInfo.bx--data-table tbody tr td:nth-child(2),
    .basicInfo.bx--data-table tbody tr th:nth-child(2) {
      padding: 0px;
      width: 2%;
      margin: 0px;
      text-align: center;
    }
    .basicInfo.bx--data-table tbody tr td:nth-child(1),
    .basicInfo.bx--data-table tbody tr th:nth-child(1) {
      width: 5%;
    }
    .bx--data-table tbody tr td:nth-child(1),
    .bx--data-table tbody tr th:nth-child(1) {
      width: 8%;
    }
    thead {
      tr {
        th {
          position: sticky;
          top: 0;
        }
      }
    }
  }
`;

export const StyledAnchorTag = styled(AnchorTag)`
  && {
    float: right;
    margin-right: 2px;
    margin-top: 6px;
    div {
      font-size: 14px;
    }
  }
`;

const basicInfoHeaders = [
  {
    key: 'id',
    header: words.rankingListHeaders.no,
  },
  {
    key: 'index',
    header: '',
  },
  {
    key: 'columnName',
    header: words.columnName,
  },
  {
    key: 'dataType',
    header: words.dataType,
  },
];

const membersHeaders = [
  {
    key: 'id',
    header: words.rankingListHeaders.no,
  },
  {
    key: 'name',
    header: words.name,
  },
  {
    key: 'group',
    header: words.group,
  },
];

const rankingPeriodHeaders = [
  {
    key: 'id',
    header: words.rankingListHeaders.no,
  },
  {
    key: 'rankingPeriodName',
    header: words.rankingPeriodName,
  },
  {
    key: 'startDate',
    header: words.startDate,
  },
  {
    key: 'endDate',
    header: words.endDate,
  },
  {
    key: 'visibility',
    header: words.visibility,
  },
];

export type IBasicInfo = {
  id: string;
  columnName: string;
  dataType: string;
  isFavorite: boolean;
};

export type Props = {
  basicInfoRow: IBasicInfo[];
  membersRow: DataTableRow[];
  rankingPeriodRow: DataTableRow[];
  isLoading?: boolean;
  isOnActiveRankingDetails?: boolean;
  rankingID?: number;
  rankingData?: Ranking;
};

const Component = ({
  basicInfoRow,
  membersRow,
  rankingPeriodRow,
  isLoading = false,
  isOnActiveRankingDetails = false,
  rankingID = 0,
  rankingData,
}: Props): React.ReactElement => {
  const navigate = useNavigate();
  const renderIndex = () => {
    return <SelectedStar onClick={() => {}} />;
  };

  const renderRowData = () => {
    const rows: {
      id: string;
      index: string | React.ReactElement;
      columnName: string;
      dataType: string;
    }[] = [];

    basicInfoRow.forEach(item => {
      const settingItem = {
        id: item.id,
        index: item.isFavorite ? renderIndex() : '',
        columnName: item.columnName,
        dataType: item.dataType,
      };
      rows.push(settingItem);
    });

    return rows;
  };

  const navigateToEdit = (path: string) => {
    navigate(path);
  };

  const handleEditBasic = (id: number) => {
    if (rankingData) {
      navigate('/edit-basicinfo', {
        state: {
          rankingId: id,
          rankingName: rankingData?.name,
          rankingFields: rankingData?.ranking_fields,
          redirectedFromRankingDetails: true,
        },
      });
    }
  };

  const renderTableLoading = (headers: { key: string; header: string }[]) => {
    return (
      <CustomDataTableSkeletonContainer>
        <CustomDataTableSkeleton
          showToolbar={false}
          showHeader={false}
          headers={headers}
          columnCount={headers.length}
          rowCount={0}
        />
        <TableLoader tableRowHeight={200} />
      </CustomDataTableSkeletonContainer>
    );
  };

  return (
    <>
      <StyledTable $dataLength={renderRowData().length} $isMemberTable={false}>
        <>
          <p>
            <b>{words.basicInfo}</b>
            {isOnActiveRankingDetails && rankingID !== 0 ? (
              <StyledAnchorTag
                onPress={() => {
                  handleEditBasic(rankingID);
                }}
                title={words.edit}
                type="edit"
              />
            ) : (
              ''
            )}
          </p>
          {isLoading ? (
            renderTableLoading(basicInfoHeaders)
          ) : (
            <Table
              className={'basicInfo'}
              headers={basicInfoHeaders}
              rows={renderRowData()}
            />
          )}
        </>
      </StyledTable>
      <StyledTable $dataLength={membersRow.length} $isMemberTable={true}>
        <>
          <p>
            <b>{words.rankingMembers}</b>
            {isOnActiveRankingDetails && rankingID !== 0 ? (
              <StyledAnchorTag
                onPress={() => {
                  navigateToEdit(`/member/${rankingID}/edit?isRedirected=true`);
                }}
                title={words.edit}
                type="edit"
              />
            ) : (
              ''
            )}
          </p>
          {isLoading ? (
            renderTableLoading(membersHeaders)
          ) : (
            <>
              <p className="groupLabel">{words.groupLabelRanking}</p>
              <Table headers={membersHeaders} rows={membersRow} />
            </>
          )}
        </>
      </StyledTable>
      <StyledTable $dataLength={rankingPeriodRow.length} $isMemberTable={false}>
        <>
          <p>
            <b>{words.rankingPeriod}</b>
            {isOnActiveRankingDetails && rankingID !== 0 ? (
              <StyledAnchorTag
                onPress={() => {
                  navigateToEdit(`/edit-period/${rankingID}?isRedirected=true`);
                }}
                title={words.edit}
                type="edit"
              />
            ) : (
              ''
            )}
          </p>
          {isLoading ? (
            renderTableLoading(rankingPeriodHeaders)
          ) : (
            <Table headers={rankingPeriodHeaders} rows={rankingPeriodRow} />
          )}
        </>
      </StyledTable>
    </>
  );
};

export default Component;
