import styled, { createGlobalStyle } from 'styled-components';
import { Link } from 'carbon-components-react';

import { theme } from '../../../config';

import { StatusTypes } from './WithdrawAppForm';
import { Tooltip } from '../../atoms/Tooltip';
import { TextInput } from '../../atoms/TextInput';
import { DatePicker } from '../../molecules/DatePicker';
import { languageUsed } from '../../../constants/words';

export const GlobalStyle = createGlobalStyle`
& {
  .bx--tooltip.bx--tooltip--shown {
    width: 256px;
  }

  .bx--tooltip__footer.bx--tooltip__footer.bx--tooltip__footer.bx--tooltip__footer.bx--tooltip__footer > button {
    width: 115px;
    height: 32px;
  }

  .bx--tooltip.bx--tooltip--bottom.bx--tooltip--align-end {
    max-width: 256px;
  }

  .bx--tooltip.bx--tooltip--bottom.bx--tooltip--align-end .bx--tooltip__caret {
    margin-right: 65px;
  }

  .star-tooltip {
    max-width: ${languageUsed === 'en' ? '120px' : '140px'};
  }
}

@media ${theme.breakpoints.mobile} {
  & {
    .bx--tooltip.bx--tooltip.bx--tooltip.bx--tooltip.bx--tooltip {
      width: 100%;
    }

    .bx--tooltip.bx--tooltip--shown.bx--tooltip.bx--tooltip--shown.bx--tooltip.bx--tooltip--shown {
      min-width: calc(100% - 40px);
      padding: 16px 25px;
    }

    .bx--tooltip.bx--tooltip.bx--tooltip.bx--tooltip.bx--tooltip .bx--tooltip__footer {
      margin-top: 17px;
    }

    .bx--tooltip__footer.bx--tooltip__footer.bx--tooltip__footer.bx--tooltip__footer.bx--tooltip__footer > button {
      width: 115px;
    }

    .flatpickr-calendar.open {
      width: calc(100% - 40px);
    }

    .flatpickr-day {
      width: calc(100% / 7);
    }
  }
}
`;

export const FirstColumn = styled.div`
  width: calc(50% - 40px);
  margin: 0 20px;

  @media ${theme.breakpoints.mobile} {
    width: calc(100% - 40px);
  }
`;

export const SecondColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(50% - 40px);
  margin: 0 20px;

  @media ${theme.breakpoints.mobile} {
    flex-direction: column-reverse;
    width: calc(100% - 40px);
  }
`;

export const SecondColumnFields = styled.div`
  && > div:first-child {
    margin-bottom: 71px;
  }

  @media ${theme.breakpoints.mobile} {
    && > div:first-child {
      margin-bottom: 30px;
    }
  }
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: row;

  & > ${FirstColumn} > div {
    margin-bottom: 48px;
  }

  @media ${theme.breakpoints.mobile} {
    flex-direction: column;

    & > ${FirstColumn} > div {
      margin-bottom: 10px;
    }
  }
`;

export const DatesContainer = styled.div`
  display: flex;
  flex-direction: row;

  & > div {
    flex: 1;
  }

  & > div:first-child {
    margin-right: 14px;
  }

  @media ${theme.breakpoints.mobile} {
    flex-direction: column;

    & > div {
      margin-bottom: 23px;
    }

    & > div:first-child {
      margin-right: 0;
      margin-bottom: 30px;
    }
  }
`;

export const NotesInput = styled(TextInput)`
  &.record-app-form-mobile {
    display: none;
  }

  @media ${theme.breakpoints.mobile} {
    &:not(.record-app-form-mobile) {
      display: none;
    }

    &.record-app-form-mobile {
      display: inherit;
    }
  }
`;

export const MultiSelectContainer = styled.div`
  min-height: 117px;
  margin-bottom: 10px;

  && .multi-select-sub-approver-exists > div:nth-last-child(2) {
    max-width: 1px;
  }

  && .css-1hb7zxy-IndicatorsContainer {
    .css-tlfecz-indicatorContainer {
      .css-tj5bde-Svg {
        fill: ${theme.colors.icon01};
        height: 17px;
        width: 17px;
        stroke: ${theme.colors.white};
        stroke-width: 1px;
      }
    }
  }

  & > div:nth-child(2) > div > div:nth-child(2) > div {
    padding: 8px 19px 8px 0;
  }

  & > div:nth-child(2) > div > div:nth-child(2) > div > svg {
    fill: ${theme.colors.icon01};
    height: 17px;
    width: 17px;
    stroke: ${theme.colors.white};
    stroke-width: 1px;
  }

  & > div:nth-child(2) > div > div:last-child div[class$='-NoOptionsMessage'] {
    color: ${theme.colors.text02};
  }

  @media ${theme.breakpoints.mobile} {
    min-height: initial;
    margin-bottom: 30px;
  }
`;

export const StyledLink = styled(Link)`
  &:hover {
    cursor: pointer;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 20px 0 48px 0;

  & > button,
  & > div > div > button {
    flex: 1;
    margin-right: 16px;
    max-width: 141px;
    width: 141px;
    height: 46px;
    padding: 10px;
  }

  @media ${theme.breakpoints.mobile} {
    flex-direction: column;
    margin: 20px 0 0 0;

    .bx--tooltip__label {
      width: 100%;
    }

    & > button,
    & > div > div > button {
      flex: 1;
      margin: 13px 0 0 0;
      max-width: 100%;
      width: 100%;
      height: 46px;
      padding: 10px;
    }
  }
`;

export const SubmitTooltip = styled(Tooltip)`
  &.record-app-form-mobile {
    display: none;
  }

  @media ${theme.breakpoints.mobile} {
    &:not(.record-app-form-mobile) {
      display: none;
    }

    &.record-app-form-mobile {
      display: inherit;
    }
  }
`;

export const MultiSelectPlaceholder = styled.div`
  height: 20px;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.16px;
  color: ${theme.colors.placeHolderText};
  margin-left: 5px;

  @media ${theme.breakpoints.mobile} {
    display: none;
  }
`;

export const StatusTitle = styled.text<{ status: StatusTypes }>`
  color: ${props =>
    props.status === 'withdraw' || props.status === 'rejected'
      ? theme.colors.text02
      : theme.colors.textLingOrange};
  font-size: 12px;
`;

export const Status = styled.text`
  font-weight: 500;
  font-size: 16px;

  &.approval-status-unapproved {
    color: ${theme.colors.ui04};
  }

  &.approval-status-approved {
    color: ${theme.colors.support02};
  }

  &.approval-status-rejected {
    color: ${theme.colors.support01};
  }

  &.approval-status-withdraw {
    color: ${theme.colors.textLingOrange};
  }
`;

export const StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 40px;
  margin-bottom: 6.4em;

  &.member-app-details-status-mobile {
    display: none;
  }

  @media ${theme.breakpoints.mobile} {
    display: none;

    &&.member-app-details-status-mobile {
      display: flex;
      margin-bottom: 25px;
    }
  }
`;

export const TransactionTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
  padding-bottom: 1.5em;
`;

export const StyledDatePicker = styled(DatePicker)`
  .bx--date-picker--single > div > div > .bx--date-picker__input:disabled {
    border: 1px solid ${theme.colors.inputBorderColor};
    background-color: ${theme.colors.white};
    color: ${theme.colors.text01};
  }
  .bx--label--disabled {
    color: ${theme.colors.text02};
  }
  .bx--date-picker__input:disabled ~ .bx--date-picker__icon {
    fill: ${theme.colors.text01};
  }
`;
