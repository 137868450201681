import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import words from '../../../constants/words';
import { theme } from '../../../config';
import ServerErrorImg from '../../../assets/images/server-error.png';
import PageNotFoundImg from '../../../assets/images/page-not-found.png';
import LinkExpiredImg from '../../../assets/images/link-expired.png';

import { Header } from '../../organisms/Header';
import { SubHeader } from '../../atoms/Subheader';
import { Button } from '../../atoms/Button';

const MainContainer = styled.div`
  background-color: ${theme.colors.uiBackground};
  height: 100vh;

  @media ${theme.breakpoints.mobile} {
    background-color: ${theme.colors.white};
  }
`;

const ErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - 48px - 92px);
`;

const ErrorContents = styled.div`
  width: 75%;
  height: 85%;
  background-color: ${theme.colors.white};
  box-shadow: 0px 0px 8px rgba(236, 215, 192, 0.8);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media ${theme.breakpoints.mobile} {
    height: 100%;
    box-shadow: none;
  }
`;

const ErrorImage = styled.img`
  margin-left: auto;
  margin-right: auto;
`;

const ErrorTitle = styled.div`
  margin-top: 19px;
  font-weight: 400;
  font-size: 28px;
  line-height: 36px;
  text-align: center;

  @media ${theme.breakpoints.mobile} {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
  }
`;

const ErrorDetails = styled.div<{ $isMobilePreWrap?: boolean }>`
  margin-top: 17px;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.16px;
  white-space: pre-wrap;

  @media ${theme.breakpoints.mobile} {
    width: 241px;
    margin-left: auto;
    margin-right: auto;
    white-space: ${props => (props.$isMobilePreWrap ? 'pre-wrap' : 'normal')};
  }
`;

const ActionButtonContainer = styled.div`
  margin: 55px auto 0 auto;
  width: fit-content;

  @media ${theme.breakpoints.mobile} {
    width: 100%;
  }
`;

const ActionButton = styled(Button)`
  width: 194px;

  @media ${theme.breakpoints.mobile} {
    width: 100%;
  }
`;

export type Props = {
  statusCode: 500 | 404 | 410;
  navigatePath?: string;
};

type ErrorConfigType = {
  subHeader: string;
  imgSrc: string;
  errorTitle: string;
  errorDetails: string;
  actionBtnTitle: string;
};

const Component = ({
  statusCode,
  navigatePath = '/',
}: Props): React.ReactElement => {
  const navigate = useNavigate();

  const errorConfigurations = {
    500: {
      subHeader: words.error500PageSubHeader,
      imgSrc: ServerErrorImg,
      errorTitle: words.error500PageTitle,
      errorDetails: words.error500PageDetails,
      actionBtnTitle: words.error500PageActionBtnTitle,
    } as ErrorConfigType,
    404: {
      subHeader: words.error404PageSubHeader,
      imgSrc: PageNotFoundImg,
      errorTitle: words.error404PageTitle,
      errorDetails: words.error404PageDetails,
      actionBtnTitle: words.error404PageActionBtnTitle,
    } as ErrorConfigType,
    410: {
      subHeader: words.errorLinkExpiredSubHeader,
      imgSrc: LinkExpiredImg,
      errorTitle: words.errorLinkExpiredTitle,
      errorDetails: words.errorLinkExpiredPageDetails,
      actionBtnTitle: words.errorLinkExpiredPageActionBtnTitle,
    } as ErrorConfigType,
  };

  const onActionButton = () => {
    navigate(navigatePath);
  };

  const onPressTitle = () => {
    navigate('/');
  };

  return (
    <MainContainer>
      <Header
        onPressTitle={onPressTitle}
        onPressNotif={() => {}}
        onPressAccount={() => {}}
        hideNotifAction
        hideAccountAction
        isLoadingProfileImage={false}
      />
      <SubHeader title={errorConfigurations[statusCode].subHeader} />
      <ErrorWrapper>
        <ErrorContents>
          <ErrorImage src={errorConfigurations[statusCode].imgSrc} />
          <ErrorTitle>{errorConfigurations[statusCode].errorTitle}</ErrorTitle>
          <ErrorDetails $isMobilePreWrap={statusCode === 500}>
            {errorConfigurations[statusCode].errorDetails}
          </ErrorDetails>
          <ActionButtonContainer>
            <ActionButton
              title={errorConfigurations[statusCode].actionBtnTitle}
              theme="primary"
              onPress={onActionButton}
            />
          </ActionButtonContainer>
        </ErrorContents>
      </ErrorWrapper>
    </MainContainer>
  );
};

export default Component;
