import { DenormalizedRow, SkeletonText } from 'carbon-components-react';
import React, { useCallback, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import words from '../../../constants/words';
import { Group } from '../../../domain/entities/group';
import { Member } from '../../../domain/entities/member';
import { Ranking } from '../../../domain/entities/rankings';
import { PaginatedResponse } from '../../../domain/entities/response';
import { useGlobalState } from '../../../hooks/global';
import { useFetchGroups } from '../../../hooks/group/hooks';
import { useFetchMembers } from '../../../hooks/member/hooks';
import { useRankingHooks } from '../../../hooks/rankings';
import { SubHeader } from '../../atoms/Subheader';
import { MemberRankingList } from '../../templates/MemberRankingList';
import { DropdownSchema } from '../../templates/MemberRankingList/types';
import { useLocation, useNavigate } from 'react-router';
import { ToastNotification } from '../../molecules/Notification';
import { TIMEOUT } from '../../../constants/timeout';
import { Props as INotification } from '../../molecules/Notification/Notification';
import { EditIcon } from '../../atoms/Icons';
import styled from 'styled-components';
import { SetRankingModal } from '../../templates/SetRankingModal';
import { AxiosError } from 'axios';
import { navigateToErrorPage } from '../../../utils/handleErrorPage';

const headers = [
  {
    header: 'No.',
    key: 'no',
  },
  {
    header: words.memberListHeaders.name,
    key: 'name',
  },
  {
    header: words.memberListHeaders.group,
    key: 'groupName',
  },
  {
    header: '',
    key: 'actions',
  },
];

export const StyledEditIcon = styled(EditIcon)`
  position: relative;
  top: 5px;
  left: 10px;
  cursor: pointer;
`;

export const StyledSkeletonText = styled(SkeletonText)`
  && {
    width: 400px;
    .bx--skeleton__text::before {
      animation: 3000ms ease-in-out skeleton infinite;
    }
  }
`;

type Props = {};

export type IRankingData = {
  rankingID: number;
  ranking_members: Member[] | number[];
};

const Component = ({}: Props): React.ReactElement => {
  const { pathname: locationPathname, search: locationSearch } = useLocation();
  const isRedirected =
    new URLSearchParams(locationSearch).get('isRedirected') === 'true';
  const { id: rankingId } = useParams();
  const {
    useCurrentUser: { currentUser },
  } = useGlobalState();
  const [memberFilters, setMemberFilters] = useState<{
    groupFilter?: Group;
  }>({
    groupFilter: undefined,
  });
  const [queryParams, setQueryParams] = useState<{
    page: number;
    pageSize: number;
  }>({ page: 1, pageSize: 10 });
  const [addClicked, setIsAddClicked] = useState<undefined | boolean>(false);
  const [selectedMultiSelect, setSelectedMultiSelect] = useState([
    { value: '', label: '' },
  ]);
  const [selectedRowsState, setSelectedRowsState] = useState<
    any[] | DenormalizedRow[]
  >([]);
  const [showRemoveDivState, setShowRemoveDivState] = useState<null | boolean>(
    null,
  );
  const [filterTableRowsBy, setFilterTableRowsBy] = useState<
    DropdownSchema | any
  >({ selectedItem: words.rankingEditMemberAll });
  const [notification, setNotification] = useState<INotification | null>(null);
  const [isEditingRankingName, setIsEditingRankingName] = useState(false);
  const [rankingName, setRankingName] = useState<string>('');

  const { useSetRankings } = useRankingHooks();

  const { getRanking, updateRanking } = useSetRankings();
  const { fetchGroups } = useFetchGroups();
  const { fetchMembers, fetchMultiSelect } = useFetchMembers();
  const navigate = useNavigate();
  const [hasChanges, setHasChanges] = useState<boolean>(false);

  const toastNotification = useCallback((toast: INotification) => {
    setNotification({
      ...toast,
      timeout: TIMEOUT,
      handleClose: () => {
        setNotification(null);
        return false;
      },
    });
  }, []);

  const { mutate: updateRankingMembers } = useMutation(
    ({ rankingID, ranking_members }: IRankingData) => {
      return updateRanking(rankingID, {
        ranking_members: ranking_members as Member[],
        name: rankingName,
      });
    },
    {
      onSuccess: () => {
        setHasChanges(false);
        toastNotification({
          kind: 'success',
          title: words.notificationTitle,
          subtitle: words.successfullyUpdatedRankingMembers,
        });
      },
      onError: () =>
        toastNotification({
          kind: 'error',
          title: words.error,
          subtitle: words.errorOccured,
        }),
    },
  );

  const {
    data: { rankingData: Ranking, groupData: Group } = {
      rankingData: ([] as unknown) as Ranking,
      groupData: ([] as unknown) as Group[],
    },
    isFetching: isLoadingMemberTable,
  } = useQuery(
    [],
    async () => {
      if (!currentUser?.companyId) {
        throw new Error(`'company id' field not set`);
      }
      const query = {
        companyId: currentUser.companyId,
      };
      const response = await getRanking({
        companyId: currentUser.companyId,
        rankingId: rankingId ? +rankingId : 0,
        isActive: true,
      });

      const groupData = await fetchGroups(query);
      let members_count = 0;
      let groupApprover: any = [];
      groupData.results.map(group => {
        members_count = members_count + group.members_count;
        groupApprover = [...groupApprover, ...group.approvers];
      });
      const groupItems: Group[] = [
        {
          id: 0,
          name: words.dropdownAll,
          members_count: members_count,
        } as Group,
      ].concat(groupData.results);

      setAddedMembers(response.ranking_members);
      setRankingName(response.name);
      return { rankingData: response, groupData: groupItems };
    },
    {
      onError: (err: AxiosError) => {
        navigateToErrorPage(navigate, err.response?.status, locationPathname);
      },
    },
  );

  const [addedMembers, setAddedMembers] = useState<Member[]>(
    Ranking?.ranking_members,
  );

  const { data: memberData, isLoading: isLoadingMembers } = useQuery(
    [memberFilters.groupFilter?.id],
    async () => {
      if (!currentUser?.companyId) {
        throw new Error(`'company id' field not set`);
      }

      const filterQuery: { [key: string]: number | string } = {};
      let memberData: PaginatedResponse & {
        results: Member[];
      };

      if (memberFilters.groupFilter?.id !== undefined) {
        if (memberFilters.groupFilter.id !== 0) {
          filterQuery.groupId = Number(memberFilters.groupFilter.id);
        }
        memberData = await fetchMembers({
          companyId: currentUser.companyId,
          ...{
            page: 1,
            page_size: memberFilters.groupFilter.members_count || 1,
          },
          ...filterQuery,
        });
      } else {
        memberData = {
          count: 0,
          previous: null,
          next: null,
          results: [],
        };
      }
      return memberData.results;
    },
    {
      onError: (err: AxiosError) => {
        navigateToErrorPage(navigate, err.response?.status, locationPathname);
      },
    },
  );

  const { data: allMemberList, isLoading: isLoadingAllMembers } = useQuery(
    ['all-member-list-edit', currentUser],
    async () => {
      if (!currentUser?.companyId) {
        throw new Error(`'company id' field not set`);
      }

      let memberData:
        | (PaginatedResponse & {
            results: Member[];
          })
        | undefined = undefined;

      memberData = await fetchMultiSelect({
        companyId: currentUser.companyId,
      });

      return memberData.results;
    },
    {
      onError: (err: AxiosError) => {
        navigateToErrorPage(navigate, err.response?.status, locationPathname);
      },
    },
  );

  const editedOptions = memberData
    ? memberData.filter(a => !addedMembers.some((b: Member) => a.id === b.id))
    : [];

  const onChangeGroupFilter = ({ selectedItem }: { selectedItem: Group }) => {
    setMemberFilters({
      ...memberFilters,
      groupFilter: selectedItem,
    });
  };

  const handleMultiSelect = (selectedRows: any) => {
    setIsAddClicked(false);
    setSelectedMultiSelect(selectedRows);
  };

  const handleSaveRankingData = () => {
    if (!rankingId) {
      throw new Error(`'Ranking id' field not set`);
    } else {
      const rankingMembersIDs: number[] = [];
      addedMembers.map(data => {
        rankingMembersIDs.push(data.id);
      });
      updateRankingMembers({
        rankingID: +rankingId,
        ranking_members: rankingMembersIDs,
      });
    }
  };

  //change redirection if coming from the Ranking Details
  const handleCancelEdit = () => {
    if (isRedirected) {
      navigate(`/ranking-details/${rankingId}`);
    } else {
      navigate(`/ranking-list`);
    }
  };

  const handleAddMembers = () => {
    if (memberData) {
      setHasChanges(true);
      const addRows = allMemberList?.filter(a =>
        selectedMultiSelect.some(b => a.id.toString() === b.value),
      );

      addRows?.forEach(row => {
        setAddedMembers((addedMembers: any) => [...addedMembers, row]);
      });
      setSelectedMultiSelect([{ value: '', label: '' }]);
      setIsAddClicked(true);
    }
  };

  const handleRemoveMembers = () => {
    setHasChanges(true);
    setAddedMembers(
      addedMembers.filter(
        (i: any) => !selectedRowsState.some(j => j.id === i.id.toString()),
      ),
    );
    setShowRemoveDivState(false);
  };

  const handleRemoveMember = (row: any) => {
    setHasChanges(true);
    setAddedMembers(
      addedMembers.filter((addRows: any) => addRows.id !== +row.id),
    );
  };

  const onChangeTableGroupFilter = (item: DropdownSchema) => {
    setFilterTableRowsBy({ selectedItem: item.selectedItem });
  };

  const selectedRows = (rows: DenormalizedRow[]) => {
    if (rows.length !== selectedRowsState.length) {
      setSelectedRowsState(rows);
      setShowRemoveDivState(rows.length < 1 ? false : true);
    }
  };

  return (
    <>
      <SubHeader
        title={
          <div>
            {!isLoadingMemberTable ? rankingName : ''}
            {!isLoadingMemberTable ? (
              <StyledEditIcon
                onClick={() => {
                  setIsEditingRankingName(true);
                }}
              />
            ) : (
              <StyledSkeletonText width="" />
            )}
          </div>
        }
      />
      <MemberRankingList
        titleHeader={words.rankingEditMembers}
        groupList={Group ? Group : []}
        isLoadingMemberTable={isLoadingMemberTable}
        selectedRows={selectedRows}
        filters={memberFilters}
        filterTableRowsBy={filterTableRowsBy}
        addedRows={addedMembers ? addedMembers : []}
        memberList={memberData ? memberData : []}
        isLoadingMembers={isLoadingMembers}
        addClicked={addClicked}
        editedOptions={editedOptions}
        editedHeader={headers}
        selectedMultiSelect={selectedMultiSelect}
        handleRemoveMembers={handleRemoveMembers}
        handleRemoveMember={handleRemoveMember}
        onChangeGroupFilter={onChangeGroupFilter}
        onChangeTableGroupFilter={onChangeTableGroupFilter}
        handleMultiSelect={handleMultiSelect}
        handleAddMembers={handleAddMembers}
        hasDataChanges={hasChanges}
        onClickSave={() => {
          handleSaveRankingData();
        }}
        onClickCancel={() => handleCancelEdit()}
        showRemoveDiv={showRemoveDivState}
        handleSetFilterTableRowsBy={() =>
          setFilterTableRowsBy({ selectedItem: words.rankingEditMemberAll })
        }
        allMemberList={allMemberList}></MemberRankingList>

      {notification ? <ToastNotification notification={notification} /> : null}
      <SetRankingModal
        isOpen={isEditingRankingName}
        isEdit
        onSubmit={ranking => {
          setHasChanges(true);
          setRankingName(ranking.rankingName);
          setIsEditingRankingName(false);
        }}
        onClose={() => {
          setIsEditingRankingName(false);
        }}
        initialValues={{ rankingName }}
      />
    </>
  );
};

export default Component;
