import React from 'react';
import styled from 'styled-components';
import words from '../../../constants/words';
import { SubHeader } from '../../atoms/Subheader';
import { GroupList } from '../../templates/GroupList';

const ChildrenWrapper = styled.div`
  padding: 40px 70px 40px 70px;
`;

type Props = {};

const Component = ({}: Props): React.ReactElement => {
  return (
    <div>
      <SubHeader title={words.groups} />
      <ChildrenWrapper>
        <React.Suspense fallback={<div />}>
          <GroupList />
        </React.Suspense>
      </ChildrenWrapper>
    </div>
  );
};

export default Component;
