import RankingRepository from '../../ports/RankingRepository';

export type checkRankingNameExistsUseCase = (payload: {
  companyId: number;
  rankingName: string;
}) => Promise<object | null>;

export const buildCheckRankingNameExists = (dependencies: {
  rankingRepo: RankingRepository;
}): checkRankingNameExistsUseCase => {
  const { rankingRepo } = dependencies;

  const checkRankingNameExists: checkRankingNameExistsUseCase = async payload =>
    rankingRepo.checkRankingNameExists(payload);

  return checkRankingNameExists;
};
