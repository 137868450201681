import { Column, Grid, Row } from 'carbon-components-react';
import React, { ChangeEvent } from 'react';
import {
  BottomWrapper,
  ButtonWrapper,
  LoadingWrapper,
  Logo,
  MainWrapper,
  Note,
  NoteWrapper,
  StyledHeader,
  StyledText,
  ToastNotifWrapper,
} from './elements';
import LogoImage from '../../../assets/images/ever-contest.png';
import words from '../../../constants/words';
import { TextInput } from '../../atoms/TextInput';
import { AnchorTag } from '../../atoms/AnchorTag';
import { SubmitLoading } from '../../molecules/SubmitLoading';
import { Button } from '../../atoms/Button';
import { Notification } from '../../molecules/Notification';

const ButtonStyle = {
  height: 48,
  padding: 0,
};

export type ResendEmailVerificationFormProps = {
  email: string;
  onChangeEmail: (event: ChangeEvent) => void;
  emailError?: string;
  onPressSubmit: () => void;
  isLoading?: boolean;
  onPressBackToLogin: () => void;
  isSubmitDisabled?: boolean;
  showErrorNotif: boolean;
  emailSentSuccess: boolean;
  onCloseNotif?: () => void;
  isCompanyEmail?: boolean;
};

const Component = ({
  email,
  onChangeEmail,
  emailError,
  onPressSubmit,
  isLoading,
  onPressBackToLogin,
  isSubmitDisabled,
  showErrorNotif,
  emailSentSuccess,
  onCloseNotif,
  isCompanyEmail,
}: ResendEmailVerificationFormProps): React.ReactElement => {
  return (
    <MainWrapper>
      <Grid>
        <Row>
          <Column>
            <Logo imageUrl={LogoImage} />
          </Column>
        </Row>
        <Row>
          <Column>
            <StyledHeader>
              {isCompanyEmail
                ? words.companyEmailVerificationLinkExpired
                : words.verificationLinkExpired}
            </StyledHeader>
            <StyledText>
              {isCompanyEmail
                ? words.enterNewCompanyEmailAddress
                : words.enterYourEmailAdd}
            </StyledText>
          </Column>
        </Row>
        <Row>
          <Column>
            <TextInput
              id={'email'}
              label={`${words.emailAddress} ${words.requiredLabel}`}
              value={email}
              onChange={onChangeEmail}
              invalid={!!emailError}
              invalidText={emailError}
              disabled={isLoading}
            />
          </Column>
        </Row>
        {showErrorNotif ? (
          <Row>
            <Column>
              <ToastNotifWrapper>
                <Notification
                  kind="error"
                  title={words.verificationLinkError}
                  subtitle={
                    isCompanyEmail
                      ? words.enterCompanyEmailAndWellSendAlink
                      : words.enterEmailAssociatedWithYourAccount
                  }
                  handleClose={() => {
                    onCloseNotif && onCloseNotif();
                    return false;
                  }}
                  inline
                />
              </ToastNotifWrapper>
            </Column>
          </Row>
        ) : null}
        {emailSentSuccess ? (
          <Row>
            <Column>
              <ToastNotifWrapper $isSuccess={true}>
                <Notification
                  kind="success"
                  title={words.verificationLinkSuccess}
                  subtitle={
                    isCompanyEmail
                      ? words.verificationLinkHasBeenSent
                      : words.newVerificationLinkSent
                  }
                  handleClose={() => {
                    onCloseNotif && onCloseNotif();
                    return false;
                  }}
                  inline
                />
              </ToastNotifWrapper>
            </Column>
          </Row>
        ) : null}
        <BottomWrapper>
          <NoteWrapper>
            <Note>{words.alreadyHaveAccount}</Note>
            <AnchorTag
              onPress={onPressBackToLogin}
              title={words.alreadyLogin}
            />
          </NoteWrapper>
          {isLoading ? (
            <LoadingWrapper>
              <SubmitLoading text={words.submitting} />
            </LoadingWrapper>
          ) : (
            <ButtonWrapper>
              <Button
                style={ButtonStyle}
                onPress={onPressSubmit}
                title={words.submit}
                disabled={isSubmitDisabled || !email}
              />
            </ButtonWrapper>
          )}
        </BottomWrapper>
      </Grid>
    </MainWrapper>
  );
};

export default Component;
