import { createContext, useContext } from 'react';
import { Company, UpdateCompany } from '../../domain/entities/company';

export type CompanyHooks = {
  useFetchCompanies: () => {
    fetchCompany: (companyId: number) => Promise<Company | undefined>;
  };
  useUpdateCompany: () => {
    updateCompany: (
      companyId: number,
      params: UpdateCompany,
    ) => Promise<Company>;
  };
  useVerifyCompanyEmail: () => {
    verifyCompanyEmail: (companyId: string, token: string) => Promise<void>;
    resendCompanyEmailVerification: (companyId: string) => Promise<void>;
  };
};

export const CompanyHooksContext = createContext<CompanyHooks | null>(null);

export const useCompanyHooks = (): CompanyHooks => {
  const context = useContext(CompanyHooksContext);
  if (!context) {
    throw new Error(`Context not instantiated`);
  }
  return context;
};
