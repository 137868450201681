import { object, string } from 'yup';
import { getErrors } from 'utils/common';
import { sphRegistrationTypeNew } from 'type/sph';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export async function validateSphRegistration(data: sphRegistrationTypeNew) {
  const schema = object().shape({
    sales_date: string().required('SPH_IS_REQUIRED_FIELD'),
    time_expect: string().required('TIME_EXPECTED_REQUIRED_FAILED'),
    time_actual: string().required('TIME_ACTUAL_REQUIRED_FAILED'),
    number_of_product: string().required('NUMBER_OF_PRODUCT_REQUIRED'),
    number_withdrawals: string().required('NUMBER_OF_WITHDRAWAL_REQUIRED'),
    number_pre_probable: string().required('NUMBER_OF_PRE_PROBABLE_REQUIRED'),
    number_tosses: string().required('NUMBER_OF_TOSSES_REQUIRED'),
  });

  const result = await schema
    .validate(data, { abortEarly: false })
    .catch(err => err);
  return getErrors(result.inner) as sphRegistrationTypeNew;
}
