import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { ErrorCodePage } from '../../components/pages/ErrorCode';
import { VerificationEmailSuccess } from '../../components/pages/VerificationEmailSuccess';

export const GlobalRoutes = (): React.ReactElement => (
  <Routes>
    <Route path="page-not-found" element={<ErrorCodePage statusCode={404} />} />
    <Route path="server-error" element={<ErrorCodePage statusCode={500} />} />
    <Route path="link-expired" element={<ErrorCodePage statusCode={410} />} />
    <Route
      path="company-email-verification/:companyId/:token"
      element={<VerificationEmailSuccess />}
    />
  </Routes>
);
