import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import { Modal } from '../../molecules/Modal';
import { TextArea } from '../../molecules/TextArea';
import words from '../../../constants/words';
import themes from '../../../config/themes';
import { theme } from '../../../config';
import { languageUsed } from '../../../constants/words';
import { MOBILE_MAX_SCREEN } from '../../../constants/breakpoints';
import { useWindowDimensions } from '../../../utils/screenDimension';

const TextAreaContainer = styled.div`
  margin-bottom: 44px;
  margin-top: 24px;
  @media ${themes.main.breakpoints.mobile} {
    margin-top: 20px;
  }
`;

const StyledModal = styled(Modal)`
  &&&& {
    .bx--modal-header__label {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 26px;
      color: ${theme.colors.text01};
    }
    .bx--modal-header__heading {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.16px;
      color: ${theme.colors.text02};
      white-space: pre-wrap;
    }
    @media ${themes.main.breakpoints.mobile} {
      .bx--modal-container {
        max-height: 475px;
      }
      .bx--modal-container .bx--modal-header__heading {
        width: ${languageUsed === 'ja' ? '110%' : '100%'};
      }
    }
  }
`;

export type Props = {
  isOpen: boolean;
  reasonValue: string;
  onClose: () => void;
  onSubmitRejectApplication: () => void;
  onChangeRejectReasonInput: (event: ChangeEvent<Element>) => void;
};

const Component = ({
  isOpen,
  reasonValue,
  onClose,
  onSubmitRejectApplication,
  onChangeRejectReasonInput,
}: Props): React.ReactElement => {
  const { width } = useWindowDimensions();
  return (
    <StyledModal
      open={isOpen}
      title={words.rejectApplication}
      label={
        width <= MOBILE_MAX_SCREEN
          ? words.rejectApplicationReasonMobile
          : words.rejectApplicationReason
      }
      onClose={onClose}
      onCancel={onClose}
      confirmButtonText={words.submitRejection}
      onConfirm={onSubmitRejectApplication}
      mobileButtonSpan={true}
      mergedWithButtons={true}>
      <TextAreaContainer>
        <TextArea
          id={'reject application'}
          labelText={words.reasonOptional}
          onChange={onChangeRejectReasonInput}
          value={reasonValue}
        />
      </TextAreaContainer>
    </StyledModal>
  );
};

export default Component;
