import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Modal } from '../../molecules/Modal';
import words from '../../../constants/words';
import { Group } from '../../../domain/entities/group';
import { useMutation } from 'react-query';
import { useGroupHooks } from '../../../hooks/group';
import { useGlobalState } from '../../../hooks/global';
import { theme } from '../../../config';

const StyledModal = styled(Modal)`
  #bx--modal-header__heading--modal-2 {
    white-space: pre-line;
  }
  .bx--modal-header__heading {
    white-space: pre-line;
  }
`;

const GroupName = styled.div`
  && {
    font-size: 13px;
    margin-top: 12px;
    margin-bottom: 27px;
    color: ${theme.colors.black};
  }
`;

export type Props = {
  groupToDelete?: Group;
  onCancel: () => void;
  onSuccessDelete?: () => void;
  onFailureDelete?: () => void;
};

const Component = ({
  groupToDelete,
  onCancel,
  onSuccessDelete,
  onFailureDelete,
}: Props): React.ReactElement => {
  const [groupName, setGroupName] = useState('');
  const { useDeleteGroup } = useGroupHooks();
  const { deleteGroup } = useDeleteGroup();
  const {
    useCurrentUser: { currentUser },
  } = useGlobalState();

  const { mutate: deleteGroupMutation, isLoading } = useMutation(
    async () => {
      if (!groupToDelete) {
        throw new Error(`'group' not selected`);
      }
      if (!currentUser?.companyId) {
        throw new Error(`'company id' not set`);
      }
      return deleteGroup({
        companyId: currentUser.companyId,
        groupId: groupToDelete.id,
      });
    },
    {
      onSuccess: () => {
        if (onSuccessDelete) {
          onSuccessDelete();
        }
      },
      onError: () => {
        if (onFailureDelete) {
          onFailureDelete();
        }
      },
    },
  );

  // so we could still show name while the modal closing animation is still happening
  useEffect(() => {
    if (groupToDelete) {
      setGroupName(groupToDelete.name);
    }
  }, [groupToDelete]);

  return (
    <StyledModal
      open={!!groupToDelete}
      title={words.deleteGroup}
      label={words.sureToDelete}
      confirmButtonText={words.yesDelete}
      onConfirm={deleteGroupMutation}
      onCancel={onCancel}
      onClose={onCancel}
      confirmButtonTheme="secondary"
      confirmButtonDisabled={isLoading}>
      <div>
        <GroupName>{`${words.groupName}：${groupName}`}</GroupName>
      </div>
    </StyledModal>
  );
};

export default Component;
