import { NavigateFunction } from 'react-router';

export const navigateToErrorPage = (
  navigate: NavigateFunction,
  statusCode?: number,
  locationPathname?: string,
): void => {
  let pathName;
  let redirectPath = undefined;

  switch (statusCode) {
    case 404:
      pathName = '/page-not-found';
      break;
    case 500:
      pathName = '/server-error';
      redirectPath = locationPathname;
      break;
    default:
      pathName = '/server-error';
      redirectPath = locationPathname;
      break;
  }

  navigate(pathName, { state: { redirectPath } });
};
