import { EntryApplicationForm } from '../../../domain/entities/entry';
import EntryRepository from '../../ports/EntryRepository';

export type fetchFormFieldsApproversUseCase = (
  companyId: number,
  memberId: number,
  rankingId: number,
) => Promise<EntryApplicationForm | undefined>;

export const buildFetchFormFieldsApprover = (dependencies: {
  entryRepo: EntryRepository;
}): fetchFormFieldsApproversUseCase => {
  const { entryRepo } = dependencies;

  const fetchFormFieldsApprovers: fetchFormFieldsApproversUseCase = async (
    companyId,
    memberId,
    rankingId,
  ) => entryRepo.fetchFormFieldsApprovers(companyId, memberId, rankingId);

  return fetchFormFieldsApprovers;
};
