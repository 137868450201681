import { GroupData } from '../../../domain/entities/group';
import GroupRepository from '../../ports/GroupRepository';

export type updateGroupUseCase = (
  payload: {
    companyId: number;
    groupId: number;
  },
  data: GroupData,
) => Promise<Response>;

export const buildUpdateGroup = (dependencies: {
  groupRepo: GroupRepository;
}): updateGroupUseCase => {
  const { groupRepo } = dependencies;

  const updateGroup: updateGroupUseCase = async (query, data) =>
    groupRepo.updateGroup(query, data);
  return updateGroup;
};
