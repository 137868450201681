import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import words from '../../../constants/words';
import { SubHeader } from '../../atoms/Subheader';
import { RecordApprovalList } from '../../templates/RecordApprovalList';
import { ToastNotification } from '../../molecules/Notification';
import { Props as INotification } from '../../molecules/Notification/Notification';
import { useLocation } from 'react-router-dom';
import { TIMEOUT } from '../../../constants/timeout';
import theme from '../../../config/themes/main';

type Props = {};
export type UpdatedRecord = {
  id: number;
  status: 'approved' | 'unapproved' | 'rejected';
};

const ChildrenWrapper = styled.div`
  padding: 40px 70px 40px 70px;
  @media ${theme.breakpoints.mobile} {
    margin-top: 10px;
    padding: 0px;
  }
`;

const Component = ({}: Props): React.ReactElement => {
  const { state: locationState } = useLocation();
  const [newlyUpdatedRecord, setNewlyUpdatedRecord] = useState<
    UpdatedRecord | undefined
  >(undefined);
  const [notification, setNotification] = useState<INotification | null>(
    locationState?.toastMessage
      ? {
          ...locationState?.toastMessage,
          timeout: TIMEOUT,
          handleClose: () => {
            setNotification(null);
            return false;
          },
        }
      : null,
  );

  useEffect(() => {
    if (locationState?.updateData) {
      const { updateData } = locationState;
      setNewlyUpdatedRecord(updateData);
    } else {
      setNewlyUpdatedRecord(undefined);
    }
  });

  return (
    <div>
      <SubHeader title={words.recordApproval} />
      <ChildrenWrapper>
        <React.Suspense fallback={<div />}>
          <RecordApprovalList newlyUpdatedRecord={newlyUpdatedRecord} />
          {notification ? (
            <ToastNotification notification={notification} />
          ) : null}
        </React.Suspense>
      </ChildrenWrapper>
    </div>
  );
};

export default Component;
