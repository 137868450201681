import React, { useState, useEffect } from 'react';
import './changePassForm.scss';
import PassImg from 'assets/icons/lock.svg';
import { memberChangePassType, memberChangePassErrorType } from 'type/member';
import HideEyeImg from 'assets/icons/hide-eye.svg';
import ShowEyeImg from 'assets/icons/show-eye.svg';

type PropType = {
  handleUpdateValues: (object: memberChangePassType) => void;
  listError: memberChangePassErrorType;
  hash: string;
};

const defaultValue = {
  old_password: '',
  password: '',
  passwordConfirm: '',
};
export default function ChangePassForm(props: PropType) {
  const { handleUpdateValues, listError, hash } = props;
  const [eyeMode, setEyeMode] = useState<boolean>(false);
  const [eyeModeCf, setEyeModeCf] = useState<boolean>(false);
  const [eyeOld, setEyeOld] = useState<boolean>(false);
  const [value, setValue] = useState<memberChangePassType>(defaultValue);

  function newValueUpdate(data: string, type: string) {
    setValue(prevState => ({ ...prevState, [type]: data.toString() }));
  }

  useEffect(() => {
    handleUpdateValues(value);
  }, [value]);

  useEffect(() => {
    newValueUpdate('', 'old_password');
    newValueUpdate('', 'password');
    newValueUpdate('', 'passwordConfirm');
  }, [hash]);

  return (
    <div className="change-pass-input">
      <table>
        <tbody>
          {/* Old Password Input */}
          <tr>
            <td>
              <img src={PassImg} />
            </td>
            <td className="pass-input">
              <label
                className={
                  value.old_password.length > 0 ? 'filled' : 'not-filled'
                }>
                現在のパスワード（必須）
              </label>
              <br />
              <input
                className="input-signup"
                type={eyeOld ? 'text' : 'password'}
                placeholder="現在のパスワード"
                value={value.old_password}
                onChange={e => newValueUpdate(e.target.value, 'old_password')}
                required
                autoComplete="new-password"
              />
              <div className="eyeIcon" onClick={() => setEyeOld(!eyeOld)}>
                <img src={eyeOld ? ShowEyeImg : HideEyeImg} />
              </div>
            </td>
          </tr>
          <tr>
            <td
              className={
                listError.old_password && listError.old_password.length > 0
                  ? 'errorMessage'
                  : 'noMessage'
              }
              colSpan={2}>
              {listError.old_password && listError.old_password.length > 0
                ? listError.old_password
                : ''}
            </td>
          </tr>
          {/* Password Input */}
          <tr>
            <td>
              <img src={PassImg} />
            </td>
            <td className="pass-input">
              <label
                className={value.password.length > 0 ? 'filled' : 'not-filled'}>
                新しいパスワード（必須）
              </label>
              <br />
              <input
                className="input-signup"
                type={eyeMode ? 'text' : 'password'}
                placeholder="新しいパスワード"
                value={value.password}
                onChange={e => newValueUpdate(e.target.value, 'password')}
                required
                autoComplete="new-password"
              />
              <div className="eyeIcon" onClick={() => setEyeMode(!eyeMode)}>
                <img src={eyeMode ? ShowEyeImg : HideEyeImg} />
              </div>
            </td>
          </tr>
          <tr>
            <td
              className={
                listError.password && listError.password.length > 0
                  ? 'errorMessage'
                  : 'noMessage'
              }
              colSpan={2}>
              {listError.password && listError.password.length > 0
                ? listError.password
                : ''}
            </td>
          </tr>
          {/* End Password Input */}
          {/* Password Confirm Input */}
          <tr>
            <td>
              <img src={PassImg} />
            </td>
            <td className="pass-input">
              <label
                className={
                  value.passwordConfirm.length > 0 ? 'filled' : 'not-filled'
                }>
                新しいパスワードの確認
              </label>
              <br />
              <input
                className="input-signup"
                type={eyeModeCf ? 'text' : 'password'}
                placeholder="新しいパスワードの確認"
                value={value.passwordConfirm}
                onChange={e =>
                  newValueUpdate(e.target.value, 'passwordConfirm')
                }
                required
                autoComplete="new-password"
              />
              <div className="eyeIcon" onClick={() => setEyeModeCf(!eyeModeCf)}>
                <img src={eyeModeCf ? ShowEyeImg : HideEyeImg} />
              </div>
            </td>
          </tr>
          <tr>
            <td
              className={
                listError.passwordConfirm &&
                listError.passwordConfirm.length > 0
                  ? 'errorMessage'
                  : 'noMessage'
              }
              colSpan={2}>
              {listError.passwordConfirm && listError.passwordConfirm.length > 0
                ? listError.passwordConfirm
                : ''}
            </td>
          </tr>
          {/* End Password Confirm Input */}
        </tbody>
      </table>
    </div>
  );
}
