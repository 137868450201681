import React from 'react';
import { TooltipDefinition as CarbonTooltip } from 'carbon-components-react';
import styled from 'styled-components';
import themes from '../../../config/themes';

export type DefProps = {
  direction?: 'top' | 'bottom';
  message?: string;
  children?: React.ReactElement;
  className?: string;
  tooltipClassName?: string;
  align?: 'start' | 'center' | 'end';
  label: string;
};

const Wrapper = styled.div`
  .bx--tooltip__label:focus {
    outline: none;
  }
  display: inherit;
  position: absolute;
`;

const Wrap = styled.div`
  white-space: pre-wrap;
`;

const Tooltip = styled(CarbonTooltip)<{ $hasChildren?: boolean }>`
  &&&&& {
    .bx--tooltip__trigger {
      border-bottom: 0;
    }
    .bx--tooltip__content {
      p {
        color: ${themes.main.colors.white};
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        letter-spacing: 0.16px;
      }
    }
  }
`;

const Label = styled.p`
  color: ${themes.main.colors.edit};
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.16px;
`;

const DefinitionTooltip = ({
  direction = 'bottom',
  message = '',
  children,
  className,
  tooltipClassName,
  align = 'start',
  label,
}: DefProps): React.ReactElement => {
  const disableTool = (e: any) => {
    e.preventDefault();
  };
  return (
    <Wrapper className={className}>
      <Tooltip
        direction={direction}
        align={align}
        tooltipText={<Wrap>{message}</Wrap>}
        onMouseDown={e => disableTool(e)}
        className={tooltipClassName}>
        <Label> {label} </Label>
        {children || null}
      </Tooltip>
    </Wrapper>
  );
};

export default DefinitionTooltip;
