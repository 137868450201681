import AuthService from '../../ports/AuthService';

export type adminSignUpUseCase = (
  companyName: string,
  groupName: string,
  representativeName: string,
  companyEmail: string,
  userEmailAddress: string,
  companyTelephone: string,
) => Promise<object | null>;

export const buildAdminSignUp = (dependencies: {
  authService: AuthService;
}): adminSignUpUseCase => {
  const { authService } = dependencies;

  const adminSignUp: adminSignUpUseCase = (
    companyName,
    groupName,
    representativeName,
    companyEmail,
    userEmailAddress,
    companyTelephone,
  ) =>
    authService.adminSignUp(
      companyName,
      groupName,
      representativeName,
      companyEmail,
      userEmailAddress,
      companyTelephone,
    );
  return adminSignUp;
};
