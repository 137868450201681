import React from 'react';
import { Tab, Tabs } from 'carbon-components-react';
import styled from 'styled-components';
import { theme } from '../../../config';

const StyledTabs = styled(Tabs)`
  // for active tab
  .bx--tabs--scrollable__nav-item--selected .bx--tabs--scrollable__nav-link {
    border-bottom: none;
    border-right: none;
    border-top: 2px solid ${theme.colors.primaryButton1};
    background-color: ${theme.colors.ui01};
    font-weight: 700;
    font-size: 13px;
    font-style: normal;
    line-height: 18px;
    letter-spacing: 0.16px;
    color: ${theme.colors.text01};
    &:focus {
      outline: 2px solid ${theme.colors.primaryButton1};
      background-color: ${theme.colors.ui01};
      border-top: none;
      border-bottom: none;
      font-weight: 700;
      font-size: 13px;
      font-style: normal;
      line-height: 18px;
      letter-spacing: 0.16px;
      color: ${theme.colors.text01};
    }
  }
  // for not active tabs
  .bx--tabs--scrollable__nav-link {
    border-right: 1px solid ${theme.colors.ui04};
    border-bottom: none;
    font-weight: normal;
    font-size: 13px;
    font-style: normal;
    line-height: 18px;
    letter-spacing: 0.16px;
    color: ${theme.colors.text02};
    &:focus {
      outline: 2px solid ${theme.colors.primaryButton1};
      background-color: ${theme.colors.ui03};
      border-top: none;
      border-bottom: none;
      font-weight: normal;
      font-size: 13px;
      font-style: normal;
      line-height: 18px;
      letter-spacing: 0.16px;
      color: ${theme.colors.text02};
    }
  }
  // for transition within tabs
  .bx--tabs--scrollable__nav-item .bx--tabs--scrollable__nav-link {
    transition: none;
  }
  // for disabled tabs
  .bx--tabs--scrollable__nav-item--disabled .bx--tabs--scrollable__nav-link {
    border-bottom: none;
    color: ${theme.colors.icon03};
    background-color: ${theme.colors.disabledButton1};
  }
  // for hovered tabs
  .bx--tabs--scrollable__nav-item:hover .bx--tabs--scrollable__nav-link {
    background-color: ${theme.colors.disabledToggle1};
    border-bottom: none;
    color: ${theme.colors.text01};
    border-right: none;
  }
`;

const StyledTab = styled(Tab)`
  &&&& {
    width: 160px;
    height: 48px;
    background-color: ${theme.colors.ui03};
    margin-left: 0px;
  }
`;

export type TabsProps = {
  id: string;
  label: string;
  element: React.ReactElement;
  disabled?: boolean;
};

export type Props = {
  tabs: TabsProps[];
  className?: string;
  onSelectionChange?: (tabIndex: number) => void;
};

const Component = ({
  tabs,
  className,
  onSelectionChange,
}: Props): React.ReactElement => {
  return (
    <StyledTabs className={className} onSelectionChange={onSelectionChange}>
      {tabs.map(tab => (
        <StyledTab
          key={tab.id}
          id={tab.id}
          label={tab.label}
          disabled={tab.disabled}>
          {tab.element}
        </StyledTab>
      ))}
    </StyledTabs>
  );
};

export default Component;
