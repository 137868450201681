import RankingRepository from '../../ports/RankingRepository';

export type fetchRankingLogsUseCase = (query: {
  companyId: number;
  rankingId: number;
}) => Promise<object | null>;

export const buildFetchRankingLogs = (dependencies: {
  rankingRepo: RankingRepository;
}): fetchRankingLogsUseCase => {
  const { rankingRepo } = dependencies;

  const fetchRankingLogs: fetchRankingLogsUseCase = async query =>
    rankingRepo.fetchRankingLogs(query);
  return fetchRankingLogs;
};
