import React, { useEffect, useState } from 'react';
import { memberType } from 'type/member';
import './typeDetailButton.scss';

type PropType = {
  user: string;
  group: string;
  company: string;
  handleChooseMode: (_mode: number) => void;
  mode: number;
};

export default function StateButton(props: PropType) {
  const { user, group, company, handleChooseMode, mode } = props;

  return (
    <div className="boxTypeBtnContainer">
      <div className="typeBtnContainer">
        <button
          className={[0, 1].includes(mode) ? 'stateBtn active' : 'stateBtn'}
          onClick={() => handleChooseMode(0)}>
          {user}
        </button>
        <button
          className={[2, 3].includes(mode) ? 'stateBtn active' : 'stateBtn'}
          onClick={() => handleChooseMode(2)}>
          {group}
        </button>
        <button
          className={[4, 5].includes(mode) ? 'stateBtn active' : 'stateBtn'}
          onClick={() => handleChooseMode(4)}>
          {company}
        </button>
      </div>
    </div>
  );
}
