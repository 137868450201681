import { GroupData } from '../../../domain/entities/group';
import GroupRepository from '../../ports/GroupRepository';

export type addGroupUseCase = (
  payload: {
    companyId: number;
  },
  data: GroupData,
) => Promise<Response>;

export const buildAddGroup = (dependencies: {
  groupRepo: GroupRepository;
}): addGroupUseCase => {
  const { groupRepo } = dependencies;

  const addGroup: addGroupUseCase = async (query, data) =>
    groupRepo.addGroup(query, data);
  return addGroup;
};
