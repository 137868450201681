import React, { useState, useCallback, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import styled, { createGlobalStyle } from 'styled-components';
import InfiniteScroll from 'react-infinite-scroller';
import { StylesConfig as ReactSelectStylesConfig } from 'react-select';
import { AxiosError } from 'axios';

import { theme } from '../../../config';
import words from '../../../constants/words';
import data from '../../../constants/data';

import { navigateToErrorPage } from '../../../utils/handleErrorPage';

import {
  RankingFieldsLookup,
  RankingResult,
} from '../../../domain/entities/ranking';

import { useGlobalState } from '../../../hooks/global';
import { useRankingHooks } from '../../../hooks/rankings';

import {
  BronzeMedal,
  GraphViewIcon,
  SilverMedal,
  TableViewIcon,
  Trophy,
} from '../../atoms/Icons';
import { Dropdown } from '../../molecules/Dropdown';
import { EmptyResultDisplay } from '../../organisms/EmptyResultDisplay';
import DefaultProfileImg from '../../../assets/images/default-profile-photo.png';
import { ViewMemberModal } from '../ViewMemberModal';
import { Loading } from '../../atoms/Loading';
import { MultiSelectDropdown } from '../../organisms/MultiSelectDropdown';
import { Ellipsis } from '../../atoms/Ellipsis';
import { RankingGraph } from '../../organisms/RankingGraph';
import { RankingGraphProps } from '../../organisms/RankingGraph/RankingGraph';
import { useWindowDimensions } from '../../../utils/screenDimension';

const Container = styled.div`
  background: ${theme.colors.ui02};
  box-shadow: 0px 0px 8px rgba(236, 215, 192, 0.8);
  padding: 2px;

  @media ${theme.breakpoints.mobile} {
    padding: 0;
    box-shadow: none;
    height: 100%;
  }
`;

const ContentWrapper = styled.div`
  min-height: 70vh;
  overflow: auto;
`;

const StyledInfiniteScroll = styled(InfiniteScroll)`
  display: flex;
  flex-direction: column;
  padding: 1em;
  gap: 0.5em;
`;

const StyledMultiSelect = styled(MultiSelectDropdown)`
  & > div:first-of-type {
    border: ${props => (!props.isError ? `1px` : `2px`)} solid
      ${props =>
        !props.isError
          ? theme.colors.inputBorderColor
          : theme.colors.errorColor};
    border-radius: 8px;
    min-height: 40px;
    cursor: pointer;
  }

  & > div:nth-of-type(2) > div > div:active {
    background-color: ${theme.colors.disabled01};
  }

  & > div:nth-of-type(2) > div > div > .labelContainer {
    padding-top: 11px;
    padding-bottom: 11px;
    margin-left: 15px;
    border-bottom: none;
    border-bottom: 1px solid ${theme.colors.ui03};
  }

  & > div:nth-of-type(2) > div > div:last-child > .labelContainer {
    border-bottom: none;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
`;

const Item = styled.div<{ top?: number }>`
  display: flex;
  gap: 3em;
  padding: 1em;
  border-radius: 8px;
  flex-direction: row;
  flex: 1;
  align-items: center;
  background-color: ${({ top }) =>
    top === 1
      ? theme.colors.firstRankingResult
      : top === 2
      ? theme.colors.secondRankingResult
      : top === 3
      ? theme.colors.thirdRankingResult
      : '#FBF7F4'};
  width: 100%;

  @media ${theme.breakpoints.mobile} {
    display: none;
  }
`;

const ItemIndex = styled.div`
  color: ${theme.colors.text02};
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  display: flex;
  gap: 0.5em;
  align-items: center;
  flex-direction: column;
`;

const ItemImage = styled.img`
  border: 1px solid ${theme.colors.listBorder};
  background-color: ${theme.colors.white};
  box-sizing: border-box;
  height: 65px;
  width: 65px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
`;

const ItemImageContainer = styled.div``;

const ItemContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2em;
  height: 100%;
  justify-content: flex-end;
  width: 80%;

  @media ${theme.breakpoints.mobile} {
    width: 100%;
  }
`;

const ItemListContent = styled.div<{ $direction?: 'row' | 'column' }>`
  display: flex;
  flex-direction: ${({ $direction }) => $direction || 'column'};

  @media ${theme.breakpoints.mobile} {
    ${({ $direction }) => ($direction === 'row' ? 'gap: 1em' : '')};
  }
`;

const ItemList = styled.div<{
  $itemCount: number;
  direction?: 'row' | 'column';
}>`
  display: flex;
  flex-direction: ${({ direction }) => direction || 'row'};

  @media ${theme.breakpoints.mobile} {
    ${({ direction }) => (direction === 'column' ? 'gap: 1em' : '')};
  }

  ${ItemListContent} {
    width: calc(
      100% / ${props => (props.$itemCount > 4 ? props.$itemCount : 4)}
    );

    @media ${theme.breakpoints.mobile} {
      width: 100%;
    }
  }
`;

const ItemName = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: ${theme.colors.text01};
  line-height: 22px;
  display: flex;
  margin-bottom: 2px;
`;

const ItemHeader = styled.div<{ $isIndex?: boolean }>`
  margin-right: 48px;
  margin-bottom: 4px;

  .ellipsis-container {
    color: ${({ $isIndex }) =>
      $isIndex ? theme.colors.primaryButton1 : theme.colors.placeHolderText};
    font-style: normal;
    font-weight: 500;
    font-size: ${({ $isIndex }) => ($isIndex ? '13px' : '12px')};
    line-height: 18px;
    letter-spacing: 0.16px;
  }

  @media ${theme.breakpoints.mobile} {
    margin-right: 0;
  }
`;

const ItemText = styled.div`
  margin-right: 48px;

  .ellipsis-container {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.16px;
    color: ${theme.colors.text01};
  }

  @media ${theme.breakpoints.mobile} {
    margin-right: 0;
    overflow: hidden;

    .ellipsis-container {
      font-size: 13px;
      line-height: 18px;
    }
  }
`;

const ContainerHeader = styled.div`
  width: 100%;
  height: 64px;
  background-color: ${theme.colors.ui01};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media ${theme.breakpoints.mobile} {
    flex-direction: column;
    align-items: center;
    height: 110px;
    margin-bottom: 4px;
    width: 100%;
  }
`;

const DropdownsWrapper = styled.div`
  display: flex;

  @media ${theme.breakpoints.mobile} {
    flex-direction: column;
    align-items: center;
    margin-bottom: 4px;
    width: 100%;
  }
`;

const DropdownWrapper = styled.div<{ marginTop?: string }>`
  width: 320px;
  height: 40px;
  margin-left: 16px;

  @media ${theme.breakpoints.mobile} {
    width: 92.19%;
    margin-left: 0px;
    margin-top: ${(props): string =>
      props.marginTop ? props.marginTop : '0px'};
  }
`;

const ViewTypeButtonWrapper = styled.div`
  display: flex;
  margin-right: 12px;

  & > * {
    margin: 0 12px;
  }
`;

const IconWrapper = styled.div`
  cursor: pointer;
`;

const MobileItem = styled.div<{ top?: number }>`
  display: none;
  flex-direction: column;
  padding: 1em;
  gap: 1em;
  border-radius: 8px;
  flex: 1;
  background-color: ${({ top }) =>
    top === 1
      ? theme.colors.firstRankingResult
      : top === 2
      ? theme.colors.secondRankingResult
      : top === 3
      ? theme.colors.thirdRankingResult
      : '#FBF7F4'};
  width: 100%;

  @media ${theme.breakpoints.mobile} {
    display: flex;
  }
`;

const MobileItemHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1em;
`;

const ShowDetails = styled.div`
  color: ${theme.colors.ui04};
  font-size: 10px;
  text-decoration: underline;
  cursor: pointer;
  width: fit-content;
`;

const MobileContentDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const LoadingContainer = styled.div`
  display: flex;
  min-height: 70vh;
  justify-content: center;
  align-items: center;
`;

const StyledEmptyResult = styled(EmptyResultDisplay)`
  & {
    @media ${theme.breakpoints.mobile} {
      height: 100%;
      padding: calc(45vh - 48px - 56px - 110px) 38px 0 38px;

      & > div:last-child {
        width: 260px;
      }
    }
  }
`;

const GlobalStyle = createGlobalStyle`
  @media ${theme.breakpoints.mobile} {
    & {
      .ellipsis-tooltip {
        text-align: left;
        width: min-content;
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        letter-spacing: 0.16px;
      }

      @media ${theme.breakpoints.mobile} {
        .copy-text-input-tooltip.copy-text-input-tooltip {
          margin-top: 6px;
          margin-left: 10px;
        }

        .copy-text-input-tooltip > .bx--tooltip__caret {
          margin-top: -7px;
        }
      }
    }
  }
`;

const RenderItemListContent = ({
  fieldName,
  fieldValue,
  itemListContentDirection,
  isIndex,
  isMobile,
}: {
  fieldName?: React.ReactNode;
  fieldValue?: React.ReactNode;
  itemListContentDirection?: 'row' | 'column';
  isIndex?: boolean;
  isMobile?: boolean;
}) => (
  <ItemListContent $direction={itemListContentDirection}>
    <ItemHeader $isIndex={isIndex}>
      <Ellipsis
        tooltipDirection={isMobile ? undefined : 'right'}
        tooltipAutoOrientation
        hasTooltip
        autoTooltip>
        {fieldName}
      </Ellipsis>
    </ItemHeader>
    <ItemText>
      <Ellipsis
        tooltipDirection={isMobile ? undefined : 'right'}
        tooltipAutoOrientation
        hasTooltip
        autoTooltip>
        {fieldValue}
      </Ellipsis>
    </ItemText>
  </ItemListContent>
);

export type DropdownSchema = {
  selectedItem: {
    id: string;
    name: string;
  };
};

export type IOptions = {
  value: string;
  label: string;
};

export type Props = {};

const Component = ({}: Props): React.ReactElement => {
  const { pathname: locationPathname } = useLocation();
  const navigate = useNavigate();
  const {
    useCurrentUser: { currentUser },
  } = useGlobalState();

  const companyId = currentUser?.companyId;

  const { useGetRankingList } = useRankingHooks();
  const {
    getRankingList,
    fetchRankingPeriod,
    fetchRankingResult,
    fetchRankingFieldsLookup,
  } = useGetRankingList();
  const { width } = useWindowDimensions();

  const [queryParams, setQueryParams] = useState<{
    page: number;
    pageSize: number;
  }>({ page: 1, pageSize: 10 });
  const [filters, setFilters] = useState({
    rankNameFilterId: '',
    periodFiltersId: '',
  });
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [selectedMemberId, setSelectedMemberId] = useState<number | undefined>(
    undefined,
  );
  const [allRankingResultData, setAllRankingResultData] = useState<
    RankingResult[]
  >([]);
  const [isShowMobileDetails, setIsShowMobileDetails] = useState({
    index: 0,
    open: false,
  });
  const [viewType, setViewType] = useState<'TABLE' | 'GRAPH'>('TABLE');
  const [showViewTypeButtons, setShowViewTypeButtons] = useState(false);
  const {
    data: { results: rankingListData } = {
      results: [],
      count: 0,
    },
  } = useQuery(
    ['getRankingList', currentUser],
    async () =>
      getRankingList({
        companyId: Number(companyId),
        isActive: true,
      }),
    {
      onError: (err: AxiosError) => {
        navigateToErrorPage(navigate, err.response?.status, locationPathname);
      },
    },
  );

  const { data: rankingFieldsLookupData } = useQuery(
    ['fetchRankingFieldsLookup', currentUser, filters.rankNameFilterId],
    () => {
      if (filters.rankNameFilterId) {
        return fetchRankingFieldsLookup({
          companyId: Number(companyId),
          rankingId: Number(filters.rankNameFilterId),
        });
      }
    },
    {
      onError: (err: AxiosError) => {
        navigateToErrorPage(navigate, err.response?.status, locationPathname);
      },
    },
  );

  const {
    data: rankingPeriodData,
    isFetching: isFetchingRankingPeriods,
  } = useQuery(
    ['fetchRankingPeriod', currentUser, filters.rankNameFilterId],
    () => {
      if (filters.rankNameFilterId) {
        return fetchRankingPeriod({
          companyId: Number(companyId),
          rankingId: Number(filters.rankNameFilterId),
          isActive: true,
          isVisible: true,
        });
      }
    },
    {
      onError: (err: AxiosError) => {
        navigateToErrorPage(navigate, err.response?.status, locationPathname);
      },
    },
  );

  const {
    data: rankingResultData,
    isFetching: isRankingResultFetching,
  } = useQuery(
    ['fetchRankingResult', currentUser, queryParams, filters.periodFiltersId],
    () => {
      const rankingPeriodDetails = rankingPeriodData?.find(
        e => e.id === Number(filters.periodFiltersId),
      );

      if (rankingPeriodDetails) {
        return fetchRankingResult({
          // page_size: queryParams.pageSize,
          // page: queryParams.page,
          companyId: Number(companyId),
          rankingId: Number(filters.rankNameFilterId),
          rankingPeriodStart: rankingPeriodDetails?.start_date,
          rankingPeriodEnd: rankingPeriodDetails?.end_date,
        });
      }
    },
    {
      onSuccess: responseData => {
        const newlyFetchedRankingResult = (responseData ||
          []) as RankingResult[];
        setAllRankingResultData([
          ...allRankingResultData,
          ...newlyFetchedRankingResult,
        ]);
      },
      onError: (err: AxiosError) => {
        navigateToErrorPage(navigate, err.response?.status, locationPathname);
      },
    },
  );

  const rankingNameFilterItems = rankingListData.map(rankingName => ({
    id: rankingName.id,
    label: rankingName.name,
  }));

  const rankingPeriodFilterItems = (rankingPeriodData?.map(rankingPeriod => ({
    value: rankingPeriod.id?.toString(),
    label: rankingPeriod.name,
  })) || []) as IOptions[];

  const fieldLookupFiltered = rankingFieldsLookupData
    ?.filter(
      e => !e.is_default && !e.is_disabled && e.name !== words.profileSetting,
    )
    .sort((a, b) => Number(a.order_id) - Number(b.order_id));

  const rankingResultsWithLookup = allRankingResultData.map(
    (rankingResultItem: RankingResult) => {
      return {
        rank: rankingResultItem.rank,
        id: rankingResultItem.id,
        member: rankingResultItem.member,
        sales_date: rankingResultItem.sales_date,
        value: rankingResultItem.value,
        is_approved: rankingResultItem.is_approved,
        fields: fieldLookupFiltered?.map((fieldLookup: RankingFieldsLookup) => {
          const fieldValues = rankingResultItem.fields.find(
            e => fieldLookup.id === e.field_lookup_id,
          );
          let userInfoValue: string | undefined = undefined;

          if (fieldLookup.data_type === 'user-info') {
            if (
              fieldLookup.member_field_lookup_id &&
              fieldLookup.name !== words.emailSetting
            ) {
              userInfoValue = rankingResultItem.member?.fields?.find(
                e => fieldLookup.member_field_lookup_id === e.field_lookup_id,
              )?.value_char;
            } else {
              switch (fieldLookup.name) {
                case words.companyId:
                  const companyId = rankingResultItem.member.company_id;
                  userInfoValue = companyId?.toString().padStart(3, '0');
                  break;
                case words.userId:
                  const userId = rankingResultItem.member.username
                    ?.substring(rankingResultItem.member.username.indexOf('A'))
                    .substring(1);
                  userInfoValue = userId?.padStart(4, '0');
                  break;
                case words.roleLoginSetting:
                  const roleName = data.memberRoles.find(
                    e => e.id === rankingResultItem.member.role,
                  );
                  userInfoValue = roleName?.name;
                  break;
                case words.emailSetting:
                  userInfoValue = rankingResultItem.member.email;
                  break;
              }
            }
          }

          return {
            field_lookup_id: fieldLookup.id,
            field_lookup_name: fieldLookup.name,
            data_type: fieldLookup.data_type,
            value_decimal: fieldValues?.value_decimal,
            value_char: fieldValues?.value_char || userInfoValue,
            is_default: fieldLookup.is_default,
            is_basis: fieldLookup.is_basis,
            is_required: fieldLookup.is_required,
            is_disabled: fieldLookup.is_disabled,
          };
        }),
      };
    },
  );

  const renderIcon = (index: number) => {
    switch (index) {
      case 1:
        return <Trophy />;
      case 2:
        return <SilverMedal />;
      case 3:
        return <BronzeMedal />;
    }
  };

  const onResetPage = () => {
    setQueryParams({ ...queryParams, page: 1 });
    setAllRankingResultData([]);
    setIsShowMobileDetails({ index: 0, open: false });
  };

  const onChangeRankNameFilter = (item: DropdownSchema) => {
    setFilters({
      rankNameFilterId: item.selectedItem.id,
      periodFiltersId: '',
    });
    onResetPage();
  };

  const onChangePeriodFilters = (item: DropdownSchema) => {
    if (item.selectedItem.id !== filters.periodFiltersId) {
      setFilters({
        ...filters,
        periodFiltersId: item.selectedItem.id,
      });
      onResetPage();
    }
  };

  const onCloseModal = () => {
    setIsOpenModal(false);
  };

  const onClickMember = (memberId?: number) => () => {
    if (
      currentUser?.roleName !== 'member' ||
      currentUser.memberId === memberId
    ) {
      setIsOpenModal(true);
      setSelectedMemberId(memberId);
    }
  };

  const onClickShowDetails = useCallback(
    (itemIndex: number) => (e: React.MouseEvent) => {
      e.stopPropagation();
      if (isShowMobileDetails.index === itemIndex) {
        setIsShowMobileDetails({
          ...isShowMobileDetails,
          open: !isShowMobileDetails.open,
        });
      } else {
        setIsShowMobileDetails({
          index: itemIndex,
          open: true,
        });
      }
    },
    [isShowMobileDetails],
  );

  const onLoadMoreRankingResult = useCallback(() => {
    if (!isRankingResultFetching)
      setQueryParams({ ...queryParams, page: queryParams.page + 1 });
  }, [isRankingResultFetching, queryParams]);

  const overrideRankingPeriodStyles: ReactSelectStylesConfig = {
    option: (styles, { isSelected }) => {
      let overrideStyles = { ...styles };

      if (isSelected) {
        overrideStyles = {
          ...styles,
          backgroundColor: theme.colors.dropDownContainerBgColor,
          color: theme.colors.text03,
          padding: 0,
          cursor: 'pointer',
        };
      }

      return overrideStyles;
    },
  };

  useEffect(() => {
    if (width <= 576) {
      return setShowViewTypeButtons(false);
    }
    setShowViewTypeButtons(true);
  }, [width]);

  return (
    <Container>
      <ContainerHeader>
        <DropdownsWrapper>
          <DropdownWrapper marginTop="12px">
            <Dropdown
              styles={{ width: '100%' }}
              id={'ranking-filter'}
              label={words.rankingListHeaders.name}
              items={rankingNameFilterItems}
              onChange={onChangeRankNameFilter}
              backgroundColor={theme.colors.white}
              dropdownProps={{
                selectedItem: rankingNameFilterItems.find(
                  e => e.id === filters.rankNameFilterId,
                ) || { id: '', label: words.rankingListHeaders.name },
              }}
            />
          </DropdownWrapper>
          <DropdownWrapper marginTop="8px">
            <StyledMultiSelect
              options={rankingPeriodFilterItems}
              onChange={selectedRow =>
                onChangePeriodFilters({
                  selectedItem: {
                    id: (selectedRow as IOptions).value,
                    name: (selectedRow as IOptions).label,
                  },
                })
              }
              isMulti={false}
              isLoading={isFetchingRankingPeriods}
              withLabel={false}
              placeholder={words.rankingPeriodHeaders.name}
              value={
                (rankingPeriodFilterItems.find(
                  e => e.value === filters.periodFiltersId,
                ) || [
                  {
                    value: '',
                    label: words.rankingPeriodHeaders.name,
                  },
                ]) as IOptions[]
              }
              noOptionsText={words.oneOrMorePeriodRequired}
              styles={overrideRankingPeriodStyles}
              isDisabled={!filters.rankNameFilterId}
            />
          </DropdownWrapper>
        </DropdownsWrapper>
        {showViewTypeButtons && (
          <ViewTypeButtonWrapper>
            <IconWrapper onClick={() => setViewType('TABLE')}>
              <TableViewIcon isActive={viewType === 'TABLE'} />
            </IconWrapper>
            <IconWrapper onClick={() => setViewType('GRAPH')}>
              <GraphViewIcon isActive={viewType === 'GRAPH'} />
            </IconWrapper>
          </ViewTypeButtonWrapper>
        )}
      </ContainerHeader>
      {isRankingResultFetching ? (
        <LoadingContainer>
          <Loading width={36} height={36} withOverlay={false} />
        </LoadingContainer>
      ) : (
        <>
          {allRankingResultData.length > 0 && viewType === 'TABLE' ? (
            <ContentWrapper>
              <StyledInfiniteScroll
                pageStart={queryParams.page}
                loadMore={onLoadMoreRankingResult}
                hasMore={!!rankingResultData?.next}>
                {rankingResultsWithLookup?.map((item, index) => {
                  const basisField = item.fields?.find(e => e.is_basis);
                  const fieldsFilteredOutBasis = item.fields?.filter(
                    e => !e.is_basis,
                  );
                  return (
                    <Content
                      onClick={onClickMember(item.member.id)}
                      key={index}>
                      <Item top={item.rank}>
                        <ItemIndex>
                          <div>{item.rank}</div>
                          <div style={{ width: '25px' }}>
                            {renderIcon(item.rank)}
                          </div>
                        </ItemIndex>
                        <ItemImageContainer>
                          <ItemImage
                            src={item.member.photo || DefaultProfileImg}
                          />
                        </ItemImageContainer>
                        <ItemContent>
                          <ItemName>{item.member.name}</ItemName>
                          <ItemList
                            $itemCount={(fieldLookupFiltered?.length || 1) + 1}>
                            <RenderItemListContent
                              fieldName={basisField?.field_lookup_name}
                              fieldValue={
                                <NumberFormat
                                  displayType="text"
                                  value={basisField?.value_decimal || 0}
                                  decimalScale={4}
                                  thousandSeparator
                                />
                              }
                              isIndex
                            />
                            <RenderItemListContent
                              fieldName={words.group}
                              fieldValue={item.member.group?.name}
                            />
                            {fieldsFilteredOutBasis?.map((field, index) => {
                              const fieldValueElement =
                                field.data_type === 'decimal' ? (
                                  <NumberFormat
                                    displayType="text"
                                    value={field.value_decimal || 0}
                                    decimalScale={4}
                                    thousandSeparator
                                  />
                                ) : (
                                  field.value_char
                                );

                              return (
                                <RenderItemListContent
                                  key={index}
                                  fieldName={field.field_lookup_name}
                                  fieldValue={fieldValueElement}
                                />
                              );
                            })}
                          </ItemList>
                        </ItemContent>
                      </Item>
                      <MobileItem top={item.rank}>
                        <MobileItemHeader>
                          <ItemIndex>
                            <div>{item.rank}</div>
                            <div style={{ width: '25px' }}>
                              {renderIcon(item.rank)}
                            </div>
                          </ItemIndex>
                          <ItemImageContainer>
                            <ItemImage
                              src={item.member.photo || DefaultProfileImg}
                            />
                          </ItemImageContainer>
                          <ItemContent>
                            <ItemName>{item.member.name}</ItemName>
                            <ItemList
                              $itemCount={
                                (fieldLookupFiltered?.length || 1) + 1
                              }>
                              <RenderItemListContent
                                fieldName={basisField?.field_lookup_name}
                                fieldValue={
                                  <NumberFormat
                                    displayType="text"
                                    value={basisField?.value_decimal || 0}
                                    decimalScale={4}
                                    thousandSeparator
                                  />
                                }
                                isIndex
                                isMobile
                              />
                            </ItemList>
                          </ItemContent>
                        </MobileItemHeader>
                        <ShowDetails onClick={onClickShowDetails(index)}>
                          {isShowMobileDetails.index === index &&
                          isShowMobileDetails.open
                            ? words.hideDetails
                            : words.showDetails}
                        </ShowDetails>
                        {isShowMobileDetails.index === index &&
                          isShowMobileDetails.open && (
                            <MobileContentDetails>
                              <ItemContent>
                                <ItemList
                                  direction="column"
                                  $itemCount={
                                    (fieldLookupFiltered?.length || 1) + 1
                                  }>
                                  <RenderItemListContent
                                    itemListContentDirection="row"
                                    fieldName={words.group}
                                    fieldValue={item.member.group?.name}
                                    isMobile
                                  />
                                  {fieldsFilteredOutBasis?.map(
                                    (field, index) => {
                                      const fieldValueElement =
                                        field.data_type === 'decimal' ? (
                                          <NumberFormat
                                            displayType="text"
                                            value={field.value_decimal || 0}
                                            decimalScale={4}
                                            thousandSeparator
                                          />
                                        ) : (
                                          field.value_char
                                        );

                                      return (
                                        <RenderItemListContent
                                          key={index}
                                          fieldName={field.field_lookup_name}
                                          fieldValue={fieldValueElement}
                                          itemListContentDirection="row"
                                          isMobile
                                        />
                                      );
                                    },
                                  )}
                                </ItemList>
                              </ItemContent>
                            </MobileContentDetails>
                          )}
                      </MobileItem>
                    </Content>
                  );
                })}
              </StyledInfiniteScroll>
            </ContentWrapper>
          ) : null}
          {allRankingResultData.length > 0 && viewType === 'GRAPH' ? (
            <div style={{ overflowX: 'auto' }}>
              <RankingGraph
                data={
                  (rankingResultsWithLookup as unknown) as RankingGraphProps
                }
              />
            </div>
          ) : null}
          {allRankingResultData.length === 0 ? (
            <StyledEmptyResult
              title={words.noFilterResultsRankingTable}
              details={words.noFilterResultsSubdescription}
            />
          ) : null}
        </>
      )}

      <ViewMemberModal
        isOpen={isOpenModal}
        onClose={onCloseModal}
        memberId={selectedMemberId}
      />
      <GlobalStyle />
    </Container>
  );
};

export default Component;
