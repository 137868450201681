import React from 'react';
import './signedUpModal.scss';
import MailSent from 'assets/icons/mail-sent.svg';
import { OrangeButton } from 'components/atoms/OrangeButton';
import words from 'constants/words';

type PropType = {
  email: string;
  display: string;
  onClick: () => void;
};

export default function SignUp(props: PropType) {
  const { email, display, onClick } = props;

  return (
    <div className="modal-signed-up" style={{ display: display }}>
      <div className="modal-content">
        <div className="modal-box">
          <img src={MailSent} />
          <p className="mail-sent-title">アカウントの登録を受付ました。</p>
          <p className="mail-sent-content">
            <span>{email}</span> に登録確認用のご案内を お送りしましたので、
            <br />
            メールの内容を確認して、Self Proアカウントの
            登録を完了してください。 <br />
            ※上記の確認作業が完了しないと、Self Proの 各機能が利用できません。
          </p>
          <div className="mail-sent-button">
            <OrangeButton text={words.backToLoginPage} onClick={onClick} />
          </div>
        </div>
      </div>
    </div>
  );
}
