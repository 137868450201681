import AuthService from '../../ports/AuthService';

export type registerUserUseCase = (
  username: string,
  email: string,
  password: string,
  password2: string,
) => Promise<object | null>;

export const buildRegisterUser = (dependencies: {
  authService: AuthService;
}): registerUserUseCase => {
  const { authService } = dependencies;

  const registerUser: registerUserUseCase = (
    username,
    email,
    password,
    password2,
  ) => authService.register(username, email, password, password2);

  return registerUser;
};
