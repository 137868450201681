import React from 'react';

type Props = {
  [key: string]: string | number;
};

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 9H5.5V12.085L4.205 10.795L3.5 11.5L6 14L8.5 11.5L7.795 10.795L6.5 12.085V9H12V8H0V9ZM3.5 2.5L4.205 3.205L5.5 1.915V5H0V6H12V5H6.5V1.915L7.795 3.205L8.5 2.5L6 0L3.5 2.5Z"
        fill="#8D8D8D"
      />
    </svg>
  );
};

export default Component;
