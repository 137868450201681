import React, { useState, useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGlobalState } from '../../../hooks/global';
import { useMutation, useQuery } from 'react-query';
import { Formik } from 'formik';
import styled from 'styled-components';
import { theme } from '../../../config';
import words from '../../../constants/words';
import { Button } from '../../atoms/Button';
import { ArrowRight, ArrowLeft, EditIcon } from '../../atoms/Icons';
import { SubHeader } from '../../atoms/Subheader';
import { SetBasicInfo } from '../../templates/SetBasicInfo';
import { AddPeriod } from '../../organisms/AddPeriod';
import { IAddPeriodProps } from '../../organisms/AddPeriod/AddPeriod';
import { CreateRanking } from '../../organisms/CreateRanking';
import { RankingColumn } from '../../templates/RankingColumnsTable/RankingColumnsTable';
import { MemberRankingList } from '../../templates/MemberRankingList';
import { DropdownSchema } from '../../templates/MemberRankingList/types';
import {
  DenormalizedRow,
  ProgressIndicator,
  ProgressStep,
} from 'carbon-components-react';
import { Member } from '../../../domain/entities/member';
import { useFetchGroups } from '../../../hooks/group/hooks';
import { useFetchMembers } from '../../../hooks/member/hooks';

import { Group } from '../../../domain/entities/group';
import { useRankingHooks } from '../../../hooks/rankings';
import {
  RankingFields,
  RankingPeriod,
} from '../../../domain/entities/rankings';
import { ToastNotification } from '../../molecules/Notification';
import { Props as INotification } from '../../molecules/Notification/Notification';
import { TIMEOUT } from '../../../constants/timeout';
import { PaginatedResponse } from '../../../domain/entities/response';
import { SetRankingModal } from '../../templates/SetRankingModal';
import { Tooltip } from '../../atoms/Tooltip';
import themes from '../../../config/themes';
import { AxiosError } from 'axios';
import { navigateToErrorPage } from '../../../utils/handleErrorPage';

const ChildrenWrapper = styled.div`
  padding: 40px 70px 40px 70px;
  width: 100%;
  @media ${theme.breakpoints.mobile} {
    padding: 20px;
    width: 100vw;
  }

  .AddPeriodTable {
    tbody tr td:nth-child(3),
    tbody tr td:nth-child(4) {
      width: 160px;
      max-width: 160px;
      @media ${themes.main.breakpoints.pc} {
        padding-left: 25px !important;
        padding-right: 25px !important;
      }
      @media ${themes.main.breakpoints.wideScreen} {
        padding-left: 40px !important;
        padding-right: 40px !important;
      }
      @media ${themes.main.breakpoints.tablet} {
        padding-left: 35px;
        padding-right: 35px;
      }
      @media ${themes.main.breakpoints.mobile} {
        padding-left: 0px;
        padding-right: 0px;
      }
    }
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 34px;
`;

const StyledButton = styled(Button)`
  width: auto;
  padding-left: 13px;
  padding-right: 61px;
  margin-left: 30px;

  &:focus {
    box-shadow: none;
  }
`;

const ButtonDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const HeaderText = styled.p`
  color: ${theme.colors.text01};
  font-size: 28px;
  line-height: 36px;
`;

const StyledEdit = styled(EditIcon)`
  margin-left: 15px;
  cursor: pointer;
`;

const ProgressStepStyled = styled(ProgressStep)<{
  $isComplete?: boolean;
}>`
  .bx--progress-label {
    font-size: 20px;
    max-width: 195px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0px;
    margin-top: 10px;
  }
  .bx--progress-label:hover,
  .bx--progress-label:focus,
  .bx--progress-label:active,
  .bx--progress-step-button:not(.bx--progress-step-button--unclickable)
    .bx--progress-label:active {
    box-shadow: none;
    color: black;
  }

  &&.bx--progress-step--disabled .bx--progress-label,
  &&.bx--progress-step--disabled .bx--progress-label:hover {
    color: ${theme.colors.black};
    cursor: not-allowed;
  }

  &&.bx--progress-step {
    width: 195px;
  }

  &&.bx--progress-step svg {
    margin: 14px 15px 0 2px;
    height: 18px;
    width: 18px;
    fill: ${({ $isComplete }) =>
      $isComplete ? theme.colors.primaryButton1 : theme.colors.black};
  }

  .bx--progress-line {
    width: 195px;
  }

  &&.bx--progress-step--current .bx--progress-line,
  &&.bx--progress-step--complete .bx--progress-line {
    background-color: ${theme.colors.primaryButton1};
  }

  &&.bx--progress-step--incomplete .bx--progress-line {
    background-color: ${theme.colors.ui03};
  }
`;

const AddFieldWrapper = styled.div<{ isDisabled: boolean }>`
  ${props =>
    props.isDisabled
      ? `.field-button, .field-button:hover {
    background-color: ${theme.colors.disabledButton1} !important;
    border-color: ${theme.colors.disabledButton2} !important;
    color: ${theme.colors.disabledButton2} !important;
    cursor: not-allowed !important;
  }
  `
      : ``}
`;

const initialValues = {
  id: 0,
  rankingPeriodName: '',
  startDate: new Date(),
  endDate: new Date(),
  visibility: false,
  disabled: false,
} as IAddPeriodProps;

const headers = [
  {
    header: 'No.',
    key: 'no',
  },
  {
    header: words.memberListHeaders.name,
    key: 'name',
  },
  {
    header: words.memberListHeaders.group,
    key: 'groupName',
  },
  {
    header: words.memberListHeaders.actions,
    key: 'actions',
  },
];

type Props = {};

const STEPS: ('basic-info' | 'member' | 'period' | 'confirm')[] = [
  'basic-info',
  'member',
  'period',
  'confirm',
];
const STEPS_INDEX: { [key: string]: 0 | 1 | 2 | 3 } = {
  'basic-info': 0,
  member: 1,
  period: 2,
  confirm: 3,
};
const INITIAL_RANKINGS: RankingColumn[] = [
  {
    id: 0,
    name: words.profilePic,
    dataType: 'user-info',
    isDefault: true,
    orderId: 0,
  },
  {
    id: -1,
    name: words.name,
    dataType: 'user-info',
    isDefault: true,
    orderId: 0,
  },
  {
    id: -2,
    name: words.group,
    dataType: 'user-info',
    isDefault: true,
    orderId: 0,
  },
];

const Component = ({}: Props): React.ReactElement => {
  const location = useLocation();
  const navigate = useNavigate();
  const { useSetRankings } = useRankingHooks();
  const { fetchMultiSelect } = useFetchMembers();
  const { fetchGroups } = useFetchGroups();
  const { createRanking } = useSetRankings();
  const {
    useCurrentUser: { currentUser },
  } = useGlobalState();
  const companyId = currentUser?.companyId;
  const [notification, setNotification] = useState<INotification | undefined>(
    undefined,
  );
  const [isNextTooltipOpen, setIsNextTooltipOpen] = useState<boolean>(false);
  const [isNextTooltipOpenEdit, setIsNextTooltipOpenEdit] = useState<boolean>(
    false,
  );
  const [rankingName, setRankingName] = useState<string>(
    location?.state?.rankingName || '',
  );
  const [isEditingRankingName, setIsEditingRankingName] = useState(false);
  const [isCreatingRanking, setIsCreatingRanking] = useState(false);

  const { mutate: createRankingMutation } = useMutation(
    ([name, fields, periods, memberIds]: [
      string,
      Omit<RankingFields, 'id' | 'ranking_id'>[],
      RankingPeriod[],
      number[],
    ]) => {
      if (!companyId) {
        throw new Error(`field 'company id' missing`);
      }
      return createRanking({ companyId, name, fields, periods, memberIds });
    },
    {
      onSuccess: () => {
        setIsCreatingRanking(false);
        navigate('/ranking-list');
      },
      onError: () => {
        setIsCreatingRanking(false);
        setNotification({
          timeout: TIMEOUT,
          kind: 'error',
          title: words.error,
          subtitle: words.errorOccured,
          handleClose: () => {
            setNotification(undefined);
            return false;
          },
        });
      },
    },
  );

  const {
    data: { results: groupData } = {
      results: [],
      count: 0,
    },
  } = useQuery(
    ['ranking-list-groups', currentUser],
    () => {
      if (!currentUser?.companyId) {
        throw new Error(`'company id' not set`);
      }
      const query = {
        companyId: currentUser.companyId,
      };
      return fetchGroups(query);
    },
    {
      refetchOnMount: true,
    },
  );

  let members_count = 0;
  let groupApprover: any = [];
  groupData.map(group => {
    members_count = members_count + group.members_count;
    groupApprover = [...groupApprover, ...group.approvers];
  });

  const groupList: Group[] = [
    {
      id: 0,
      name: words.dropdownAll,
      members_count: members_count,
      approvers: groupApprover,
    } as Group,
  ].concat(groupData);

  const [step, setStep] = useState<
    'basic-info' | 'member' | 'period' | 'confirm'
  >('basic-info');
  const [dataRanking, setDataRanking] = useState([] as IAddPeriodProps[]);
  const [newRankings, setNewRankings] = useState<RankingColumn[]>([]);
  const [isAddingRanking, setIsAddingRanking] = useState(false);
  const [isEditingRanking, setIsEditingRanking] = useState(false);
  const [prevEdited, setPrevEdited] = useState<any>(undefined);
  const [updatedRankings, setUpdatedRankings] = useState<{
    [key: number]: RankingColumn;
  }>({});
  const [deletedRankingIds, setDeletedRankingIds] = useState<number[]>([]);
  const [indexedRankingId, setIndexedRankingId] = useState<
    number | undefined
  >();
  const [filterTableRowsBy, setFilterTableRowsBy] = useState<
    DropdownSchema | any
  >({ selectedItem: words.allMemberRanking });
  const [addClicked, setIsAddClicked] = useState<undefined | boolean>(false);
  const [selectedMultiSelect, setSelectedMultiSelect] = useState([
    { value: '', label: '' },
  ]);
  const [selectedRowsState, setSelectedRowsState] = useState<
    any[] | DenormalizedRow[]
  >([]);
  const [showRemoveDivState, setShowRemoveDivState] = useState<null | boolean>(
    null,
  );
  const [addedMembers, setAddedMembers] = useState<any>([]);

  const [memberFilters, setMemberFilters] = useState<{
    groupFilter?: Group;
  }>({
    groupFilter: undefined,
  });

  const rankingColumns: RankingColumn[] = [...INITIAL_RANKINGS, ...newRankings]
    .filter(ranking => !deletedRankingIds.includes(ranking.id))
    .map(ranking => ({
      ...ranking,
      ...updatedRankings[ranking.id],
      isIndex: ranking.id === indexedRankingId,
    }));

  const toggleIsAddingRanking = useCallback(() => {
    setIsAddingRanking(!isAddingRanking);
  }, [isAddingRanking]);

  const toggleIsEditingRanking = useCallback(() => {
    setIsEditingRanking(!isEditingRanking);
  }, [isEditingRanking]);

  const createRankingFields = (ranking: any) => {
    // will work because length is always positive
    const newRankingId = -1 * (newRankings.length + INITIAL_RANKINGS.length);
    if (indexedRankingId === undefined && ranking.dataType === 'decimal') {
      setIndexedRankingId(newRankingId);
      setNewRankings([{ id: newRankingId, ...ranking }, ...newRankings]);
    } else setNewRankings([...newRankings, { id: newRankingId, ...ranking }]);
    setIsAddingRanking(false);
  };
  const updateRankingFields = (
    fieldId: number,
    ranking: RankingColumn,
    prevEdit?: RankingColumn,
    memberLookupId?: number,
  ) => {
    setUpdatedRankings({
      ...updatedRankings,
      [fieldId]: { ...ranking, memberFieldLookupId: memberLookupId },
    });
    if (indexedRankingId === undefined && ranking.dataType === 'decimal') {
      setIndexedRankingId(fieldId);
      const indexObject = newRankings.find(x => x.id === fieldId);
      if (indexObject) {
        setNewRankings([
          indexObject,
          ...newRankings.filter(item => item.id !== fieldId),
        ]);
      }
    }
    setPrevEdited(prevEdit);
    const arr = rankingColumns.filter(item => item.id !== prevEdit?.id);
    //edited the only decimal
    if (
      !arr.some(e => e.dataType === 'decimal') &&
      prevEdit &&
      prevEdit.dataType === 'decimal'
    ) {
      if (
        !(
          ranking.id === prevEdit.id &&
          ranking.dataType === 'decimal' &&
          prevEdit.dataType === 'decimal'
        )
      ) {
        setIndexedRankingId(undefined);
      }
    } else if (
      indexedRankingId === prevEdit?.id &&
      prevEdit?.dataType === 'decimal' &&
      ranking.dataType === 'user-info'
    ) {
      setIndexedRankingId(undefined);
    }
    setIsNextTooltipOpenEdit(false);
  };
  const deleteRankingFields = (fieldId: number) => {
    setDeletedRankingIds([...deletedRankingIds, fieldId]);
  };
  const handleSetIndexedRankingId = (fieldId?: number) => {
    setIndexedRankingId(fieldId);
    const indexObject = newRankings.find(x => x.id === fieldId);
    if (indexObject) {
      setNewRankings([
        indexObject,
        ...newRankings.filter(item => item.id !== fieldId),
      ]);
    }
  };

  const onChangeGroupFilter = ({ selectedItem }: { selectedItem: Group }) => {
    setMemberFilters({
      ...memberFilters,
      groupFilter: selectedItem,
    });
  };

  const onChangeTableGroupFilter = (item: DropdownSchema) => {
    setFilterTableRowsBy({ selectedItem: item.selectedItem });
  };

  const { data: memberList, isLoading: isLoadingMembers } = useQuery(
    [memberFilters.groupFilter?.id],
    async () => {
      if (!currentUser?.companyId) {
        throw new Error(`'company id' field not set`);
      }

      const filterQuery: { [key: string]: number | string } = {};
      let memberData: PaginatedResponse & {
        results: Member[];
      };

      if (memberFilters.groupFilter?.id !== undefined) {
        if (memberFilters.groupFilter.id !== 0) {
          filterQuery.groupId = Number(memberFilters.groupFilter.id);
        }
        memberData = await fetchMultiSelect({
          companyId: currentUser.companyId,
          ...filterQuery,
        });
      } else {
        memberData = { count: 0, previous: null, next: null, results: [] };
      }
      return memberData.results;
    },
    {
      onError: (err: AxiosError) => {
        navigateToErrorPage(navigate, err.response?.status, location.pathname);
      },
      refetchOnMount: true,
    },
  );

  const { data: allMemberList, isLoading: isLoadingAllMembers } = useQuery(
    [],
    async () => {
      if (!currentUser?.companyId) {
        throw new Error(`'company id' field not set`);
      }

      let memberData:
        | (PaginatedResponse & {
            results: Member[];
          })
        | undefined = undefined;

      memberData = await fetchMultiSelect({
        companyId: currentUser.companyId,
      });
      return memberData.results;
    },
    {
      onError: (err: AxiosError) => {
        navigateToErrorPage(navigate, err.response?.status, location.pathname);
      },
      refetchOnMount: true,
    },
  );

  const handleMultiSelect = (selectedRows: any) => {
    setIsAddClicked(false);
    setSelectedMultiSelect(selectedRows);
  };

  const selectedRows = (rows: DenormalizedRow[]) => {
    if (rows.length !== selectedRowsState.length) {
      setSelectedRowsState(rows);
      setShowRemoveDivState(rows.length < 1 ? false : true);
    }
  };

  const handleAddMembers = () => {
    const addRows = allMemberList?.filter(a =>
      selectedMultiSelect.some(b => a.id.toString() === b.value),
    );
    addRows?.forEach(row => {
      setAddedMembers((addedMembers: any) => [...addedMembers, row]);
    });
    setSelectedMultiSelect([{ value: '', label: '' }]);
    setIsAddClicked(true);
  };
  const handleRemoveMember = (row: any) => {
    setAddedMembers(
      addedMembers.filter((addRows: any) => addRows.name !== row.name),
    );
  };
  const handleRemoveMembers = () => {
    setAddedMembers(
      addedMembers.filter(
        (i: any) => !selectedRowsState.some(j => j.cells[1].value === i.name),
      ),
    );
    setShowRemoveDivState(false);
  };
  const editedHeader = showRemoveDivState
    ? headers.filter(function(obj) {
        return obj.key !== 'actions';
      })
    : headers;
  const editedOptions = memberList?.filter(
    a => !addedMembers.some((b: Member) => a.id === b.id),
  );

  const handleSubmitPeriod = (event: IAddPeriodProps) => {
    const checkIfEdit = dataRanking.some(e => e.id === event.id);
    if (!checkIfEdit) {
      setDataRanking(dataRanking.concat(event));
    } else {
      setDataRanking(
        dataRanking.map(item => (item.id === event.id ? (item = event) : item)),
      );
    }
  };
  const handleDeleteRanking = (id: number | null) => {
    if (id) {
      setDataRanking(prevState => prevState.filter(x => x.id !== id));
    }
  };

  const handleOnDrag = (order: any[]) => {
    const currentRankings = newRankings
      .filter(ranking => !deletedRankingIds.includes(ranking.id))
      .map(ranking => ({
        ...ranking,
        ...updatedRankings[ranking.id],
        isIndex: ranking.id === indexedRankingId,
      }));
    if (currentRankings.length > 1) {
      let c = order.map((i: any) =>
        currentRankings.find((j: any) => j.name === i.name.props.children),
      );
      if (c) {
        c = c.filter(n => n);
        setNewRankings(c as RankingColumn[]);
      }
    }
  };

  const handleSubmission = () => {
    setIsCreatingRanking(true);
    const name = rankingName;
    const rankingFields = rankingColumns.map(rankingColumn => ({
      name: rankingColumn.name,
      data_type: rankingColumn.dataType,
      is_required: true,
      is_default: !!rankingColumn.isDefault,
      is_basis: !!rankingColumn.isIndex,
      is_disabled: false,
      member_field_lookup_id: rankingColumn.memberFieldLookupId || undefined,
    }));
    const RankingPeriod = dataRanking.map(rankingColumn => ({
      name: rankingColumn.rankingPeriodName,
      start_date: rankingColumn.startDate,
      end_date: rankingColumn.endDate,
      is_visible: rankingColumn.visibility,
    }));
    const rankingMembers = addedMembers.map(
      (addedMember: any) => addedMember.id,
    );
    createRankingMutation([name, rankingFields, RankingPeriod, rankingMembers]);
  };

  window.addEventListener('beforeunload', ev => {
    ev.preventDefault();
    return ev;
  });

  useEffect(() => {
    if (
      newRankings.filter(ranking => !deletedRankingIds.includes(ranking.id))
        .length === 5 &&
      indexedRankingId === undefined
    ) {
      setIsNextTooltipOpen(true);
    }
  }, [newRankings.length, indexedRankingId]);

  useEffect(() => {
    if (
      !rankingColumns.some(e => e.dataType === 'decimal') &&
      prevEdited &&
      prevEdited.dataType === 'decimal' &&
      !isNextTooltipOpen &&
      !isNextTooltipOpenEdit
    ) {
      setIsNextTooltipOpen(true);
    }
  }, [prevEdited, rankingColumns, isNextTooltipOpen, isNextTooltipOpenEdit]);

  useEffect(() => {
    if (
      prevEdited &&
      prevEdited.id === indexedRankingId &&
      prevEdited.dataType === 'decimal' &&
      !isNextTooltipOpen &&
      !isNextTooltipOpenEdit
    ) {
      const result: any = rankingColumns.find(obj => {
        return obj.id === prevEdited.id;
      });
      if (
        !(
          result.id === prevEdited.id &&
          result.dataType === 'decimal' &&
          prevEdited.dataType === 'decimal'
        )
      ) {
        setIsNextTooltipOpen(true);
      }
    }
  }, [prevEdited, indexedRankingId, isNextTooltipOpen, isNextTooltipOpenEdit]);

  useEffect(() => {
    if (
      indexedRankingId === undefined &&
      prevEdited &&
      !isNextTooltipOpen &&
      !isNextTooltipOpenEdit
    ) {
      setIsNextTooltipOpen(true);
    }
  }, [indexedRankingId, isNextTooltipOpen, isNextTooltipOpenEdit]);
  const disableMemberNext =
    indexedRankingId === undefined ||
    (!rankingColumns.some(
      e => e.id === indexedRankingId && e.dataType === 'decimal',
    ) &&
      step === 'basic-info') ||
    ((dataRanking.length < 1 || addedMembers.length < 1) &&
      step === 'period') ||
    (addedMembers.length < 1 && step === 'member');
  return (
    <div>
      <SubHeader
        title={
          <div>
            {rankingName}
            <StyledEdit
              onClick={() => {
                setIsEditingRankingName(true);
              }}
            />
          </div>
        }
      />
      <ChildrenWrapper>
        <Row>
          {step === 'confirm' ? (
            <HeaderText>{words.confirmRanking}</HeaderText>
          ) : (
            <ProgressIndicator
              currentIndex={STEPS_INDEX[step]}
              onChange={value => {
                setStep(STEPS[value]);
              }}>
              <ProgressStepStyled $isComplete={true} label={words.basic} />
              <ProgressStepStyled
                $isComplete={STEPS_INDEX[step] > 0}
                label={words.memberRanking}
                disabled={
                  indexedRankingId === undefined ||
                  (!rankingColumns.some(
                    e => e.id === indexedRankingId && e.dataType === 'decimal',
                  ) &&
                    step === 'basic-info') ||
                  (addedMembers.length < 1 && step === 'member')
                }
              />
              <ProgressStepStyled
                $isComplete={STEPS_INDEX[step] === 2}
                label={words.period}
                disabled={
                  (indexedRankingId === undefined && step === 'basic-info') ||
                  ((dataRanking.length < 1 || addedMembers.length < 1) &&
                    step === 'period') ||
                  addedMembers.length < 1
                }
              />
            </ProgressIndicator>
          )}
          <ButtonDiv>
            {step !== 'basic-info' && (
              <StyledButton
                title={words.back}
                theme="light"
                onPress={() => {
                  const newStep = STEPS[STEPS_INDEX[step] - 1];
                  if (newStep) {
                    setStep(newStep);
                  }
                }}
                icon={ArrowLeft}
                disabled={isCreatingRanking}
              />
            )}

            <AddFieldWrapper
              isDisabled={disableMemberNext || isCreatingRanking}>
              {disableMemberNext ? (
                <Tooltip
                  message={words.shouldHaveIndexedColumn}
                  direction="bottom"
                  isOpen={isNextTooltipOpen && step === 'basic-info'}
                  onChange={(ev, { open }) => {
                    if (!open) {
                      setIsNextTooltipOpen(false);
                      setIsNextTooltipOpenEdit(true);
                    }
                  }}
                  align="end"
                  menuOffset={{ top: 10, left: 50 }}
                  triggerElement={
                    <StyledButton
                      title={
                        step === 'confirm' ? words.createRanking : words.next
                      }
                      onPress={() => setIsNextTooltipOpen(true)}
                      icon={ArrowRight}
                      className="field-button"
                    />
                  }
                />
              ) : (
                <StyledButton
                  title={step === 'confirm' ? words.createRanking : words.next}
                  onPress={() => {
                    const newStep = STEPS[STEPS_INDEX[step] + 1];
                    if (newStep) {
                      setStep(newStep);
                    } else if (step === 'confirm') {
                      handleSubmission();
                    }
                  }}
                  icon={ArrowRight}
                  className="field-button"
                />
              )}
            </AddFieldWrapper>
          </ButtonDiv>
        </Row>
        {step === 'basic-info' && (
          <SetBasicInfo
            fields={rankingColumns}
            canAddRanking={
              newRankings.filter(
                ranking => !deletedRankingIds.includes(ranking.id),
              ).length < 5
            }
            isAddingRanking={isAddingRanking}
            isEditingRanking={isEditingRanking}
            handleToggleAdd={toggleIsAddingRanking}
            handleToggleEdit={toggleIsEditingRanking}
            handleCreateRanking={createRankingFields}
            handleUpdateRanking={updateRankingFields}
            handleSetIndexRankingId={handleSetIndexedRankingId}
            handleDeleteRanking={deleteRankingFields}
            onDrag={handleOnDrag}
          />
        )}
        {step === 'member' && (
          <MemberRankingList
            onClickSave={() => {}}
            onClickCancel={() => {}}
            groupList={groupList}
            showRemoveDiv={showRemoveDivState}
            selectedRows={selectedRows}
            handleRemoveMembers={handleRemoveMembers}
            handleRemoveMember={handleRemoveMember}
            onChangeGroupFilter={onChangeGroupFilter}
            onChangeTableGroupFilter={onChangeTableGroupFilter}
            filters={memberFilters}
            filterTableRowsBy={filterTableRowsBy}
            addedRows={addedMembers}
            memberList={memberList === undefined ? [] : memberList}
            editedOptions={editedOptions === undefined ? [] : editedOptions}
            handleMultiSelect={handleMultiSelect}
            addClicked={addClicked}
            isLoadingMembers={isLoadingMembers}
            handleAddMembers={handleAddMembers}
            editedHeader={editedHeader}
            selectedMultiSelect={selectedMultiSelect}
            handleSetFilterTableRowsBy={() =>
              setFilterTableRowsBy({ selectedItem: words.allMemberRanking })
            }
            allMemberList={allMemberList}
          />
        )}
        {step === 'period' && (
          <Formik initialValues={initialValues} onSubmit={handleSubmitPeriod}>
            <AddPeriod
              data={dataRanking}
              isEmpty={dataRanking.length < 1}
              onDeleteRanking={handleDeleteRanking}
            />
          </Formik>
        )}
        {step === 'confirm' && (
          <CreateRanking
            basicInfoRow={rankingColumns.map((field: any, index) => ({
              id: (index + 1).toString(),
              columnName: field.name,
              dataType:
                field.dataType === 'user-info'
                  ? words.userInfoIndex
                  : field.dataType === 'decimal'
                  ? words.decimalIndex
                  : field.dataType,
              isFavorite: field.id === indexedRankingId,
            }))}
            membersRow={addedMembers.map((member: Member, index: number) => ({
              id: (index + 1).toString(),
              name: member.name,
              group: member.group?.name || words.unassignedGroup,
            }))}
            rankingPeriodRow={dataRanking.map((period: any, index: number) => ({
              id: (index + 1).toString(),
              rankingPeriodName: period.rankingPeriodName,
              startDate: period.startDate.toLocaleDateString('en-ZA'),
              endDate: period.endDate.toLocaleDateString('en-ZA'),
              visibility: period.visibility
                ? words.shownPeriod
                : words.hiddenPeriod,
            }))}
          />
        )}
      </ChildrenWrapper>
      {notification ? <ToastNotification notification={notification} /> : null}
      <SetRankingModal
        isOpen={isEditingRankingName}
        isEdit
        onSubmit={ranking => {
          setRankingName(ranking.rankingName);
          setIsEditingRankingName(false);
        }}
        onClose={() => {
          setIsEditingRankingName(false);
        }}
        initialValues={{ rankingName }}
      />
    </div>
  );
};

export default Component;
