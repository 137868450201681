import React, { useCallback, useState, useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import words from '../../../constants/words';

import { useAuthHooks } from '../../../hooks/auth';
import { useCompanyHooks } from '../../../hooks/company';
import { useFetchLoginFields } from '../../../hooks/field/hooks';
import { useGlobalState } from '../../../hooks/global';
import { useMemberHooks } from '../../../hooks/member';
import { AdminFirstLogin } from '../../templates/AdminFirstLogin';
import { fileToBase64 } from '../../../utils/fileBase64Converter';

type Props = {};
type ResponseVal = {
  password: string;
  emailAddress: string;
  error: boolean;
  msg: string;
};

const Component = ({}: Props): React.ReactElement => {
  const navigate = useNavigate();
  const { useCurrentUser } = useGlobalState();
  const { currentUser, setCurrentUser } = useCurrentUser;

  const { useSignUp } = useAuthHooks();
  const { useFetchMembers } = useMemberHooks();
  const { useFetchCompanies } = useCompanyHooks();
  const { fetchMember } = useFetchMembers();
  const { fetchCompany } = useFetchCompanies();
  const { fetchFields } = useFetchLoginFields();

  const { adminFirstLogin, verifyEmail } = useSignUp();

  const [passwordRes, setPasswordRes] = useState<string>('');
  const [companyEmailRes, setCompanyEmailRes] = useState<string>('');
  const [signupError, setSignupError] = useState<boolean>(false);
  const [notifErrorMessage, setNotifErrorMessage] = useState('');

  const { data: memberData, isLoading: isFetchingMember } = useQuery(
    [currentUser],
    () => {
      if (!currentUser) {
        throw new Error('current user is not logged in');
      }
      return fetchMember({
        companyId: currentUser.companyId,
        memberId: currentUser.memberId,
      });
    },
  );

  const { data: companyData, isLoading: isFetchingCompany } = useQuery(
    [currentUser],
    () => {
      if (!currentUser) {
        throw new Error('current user is not logged in');
      }
      return fetchCompany(currentUser.companyId);
    },
  );

  const { mutate: firstLoginMutation, isLoading } = useMutation(
    ([user_id, password, password2, email, photo, customFields, company_id]: [
      string,
      string,
      string,
      string,
      File[],
      { identifier: string; value: string }[],
      string,
    ]) => {
      return adminFirstLogin(
        user_id,
        password,
        password2,
        email,
        photo,
        customFields,
        company_id,
      );
    },
  );

  const { mutate: verifyEmailMutation } = useMutation(
    ([user_id, email]: [string, string]) => {
      return verifyEmail(user_id, email);
    },
    {
      onSuccess: response => {
        const res = response as ResponseVal;
        setCompanyEmailRes(res.error ? res.msg : '');
      },
    },
  );

  const onSubmitRegister = useCallback(
    (values): void => {
      const { email, password, password2, photo, customFieldValues } = values;
      setSignupError(false);
      if (photo) {
        fileToBase64(photo[0]).then(response => {
          window.localStorage.setItem(
            'photo',
            JSON.stringify({ base64: response, name: photo[0].name }),
          );
        });
      }
      const customFields = [...customFieldValues].filter(obj => !!obj.value);
      window.localStorage.setItem(
        'input_fields',
        JSON.stringify({ email, password, password2, customFields }),
      );
      if (currentUser) {
        firstLoginMutation(
          [
            `${currentUser.memberId}`,
            password,
            password2,
            email,
            photo || ([] as File[]),
            customFields,
            `${currentUser.companyId}`,
          ],
          {
            onError: error => {
              const { response } = error as any;
              if (response?.status === 400 && response?.data?.error) {
                setNotifErrorMessage(response.data.error);
                setSignupError(true);

                setTimeout(() => {
                  window.location.reload();
                }, 5000);
              }
            },
            onSuccess: response => {
              const keysToRemove = ['photo', 'input_fields'];
              for (const key of keysToRemove) {
                window.localStorage.removeItem(key);
              }
              responseHandler(response);
            },
          },
        );
      }
    },
    [currentUser],
  );

  const { data: customInputFields = [] } = useQuery([], async () => {
    if (!currentUser?.companyId) {
      throw new Error('user not logged in');
    }
    const fields = await fetchFields({ companyId: currentUser?.companyId });
    return fields
      .filter(field => field.visible)
      .map(field => ({
        identifier: `${field.id}`,
        name: field.name,
        isRequired: field.required,
      }));
  });

  const checkEmail = useCallback(
    (email: string): void => {
      setSignupError(false);
      if (currentUser) {
        verifyEmailMutation([`${currentUser.userId}`, email]);
      }
    },
    [currentUser],
  );

  const responseHandler = useCallback(
    (res: any) => {
      if (typeof res === 'object' && res && res.hasOwnProperty('errors')) {
        const resp = res['errors'] as ResponseVal;
        setCompanyEmailRes(resp.emailAddress || '');
        setPasswordRes(resp.password || '');
      } else {
        if (currentUser?.roleName === 'approver') {
          navigate('/');
          setCurrentUser({ ...currentUser, isLoggedIn: true });
        }
        navigate('/login', { state: { adminSignUp: true } });
      }
    },
    [currentUser],
  );

  const isFetchingDetails = isFetchingMember || isFetchingCompany;

  const capitalizeFirstLetter = (text: string): string => {
    return text.length ? text[0].toUpperCase() + text.slice(1) : text;
  };

  const getRoleName = (): string => {
    switch (memberData?.roleName) {
      case 'admin':
        return words.admin;
      case 'approver':
        return words.approver;
      case 'member':
        return words.applicant;
      default:
        return memberData?.roleName || '';
    }
  };

  useEffect(() => {
    !notifErrorMessage && window.addEventListener('beforeunload', promptUser);
    return () => {
      !notifErrorMessage &&
        window.removeEventListener('beforeunload', promptUser);
    };
  }, [notifErrorMessage]);

  const promptUser = (event: any) => {
    event.preventDefault();
    event.returnValue = '';
  };

  return isFetchingDetails ? (
    <></> // TODO: Replace with placeholder while loading or skeleton for first login
  ) : (
    <AdminFirstLogin
      name={memberData?.name || ''}
      companyId={memberData?.companyId.toString() || '0'}
      userId={memberData?.username.split('A')[1] || '1'}
      email={memberData?.email || ''}
      group={capitalizeFirstLetter(
        memberData?.group?.name || words.unassignedGroup,
      )}
      companyName={companyData?.name || ''}
      role={getRoleName()}
      onRegister={onSubmitRegister}
      passwordRes={passwordRes}
      emailAddressRes={companyEmailRes}
      signupError={signupError}
      checkEmail={checkEmail}
      isLoading={isLoading}
      customFields={customInputFields}
      notifErrorMessage={notifErrorMessage}
    />
  );
};

export default Component;
