import GroupRepository from '../../usecases/ports/GroupRepository';
import { HttpAdapter } from '../../usecases/ports/HttpAdapter';
import { PaginationQuery } from '../../domain/entities/request';
import { Approvers, Group, GroupData } from '../../domain/entities/group';
import { PaginatedResponse } from '../../domain/entities/response';
export default class GroupRepositoryImpl implements GroupRepository {
  httpAdapter: HttpAdapter;
  urls: { [key: string]: (query: { [key: string]: string }) => string };

  constructor(
    httpAdapter: HttpAdapter,
    urls: { [key: string]: (query: { [key: string]: string }) => string },
  ) {
    this.httpAdapter = httpAdapter;
    this.urls = urls;
  }

  find = async (
    query: PaginationQuery & { companyId: string },
  ): Promise<Group[]> => {
    const { companyId } = query;
    const response = await this.httpAdapter.get(
      this.urls.getGroups({ companyId }),
      {
        params: query,
      },
    );
    const groups = response.data;
    return groups;
  };

  fetch = async (
    query: PaginationQuery & { companyId: number },
  ): Promise<PaginatedResponse & { results: Group[] }> => {
    const { companyId, page_size } = query;
    const response = await this.httpAdapter.get(
      this.urls.getGroups({ companyId: `${companyId}` }),
      {
        params: query,
      },
    );

    return page_size
      ? response.data
      : {
          count: response.data.length,
          next: null,
          previous: null,
          results: response.data,
        };
  };

  fetchApprovers = async (query: {
    companyId: number;
  }): Promise<Approvers[]> => {
    const { companyId } = query;
    const response = await this.httpAdapter.get(
      this.urls.getApprovers({ companyId: `${companyId}` }),
      {
        params: query,
      },
    );
    return response.data;
  };

  delete = async ({
    companyId,
    groupId,
  }: {
    companyId: number;
    groupId: number;
  }): Promise<void> => {
    await this.httpAdapter.delete(
      this.urls.deleteGroup({
        companyId: `${companyId}`,
        groupId: `${groupId}`,
      }),
      {},
    );
  };

  addGroup = async (
    { companyId }: { companyId: number },
    data: GroupData,
  ): Promise<Response> => {
    const response = await this.httpAdapter.post(
      this.urls.addGroup({ companyId: `${companyId}` }),
      data,
    );
    return response;
  };

  updateGroup = async (
    { companyId, groupId }: { companyId: number; groupId: number },
    data: GroupData,
  ): Promise<Response> => {
    const response = await this.httpAdapter.patch(
      this.urls.updateGroup({
        companyId: `${companyId}`,
        groupId: `${groupId}`,
      }),
      data,
    );
    return response;
  };

  async checkDuplicateGroupName(query: {
    companyId: number;
    groupName: string;
  }): Promise<Response> {
    const { companyId, groupName } = query;
    const response = await this.httpAdapter.head(
      this.urls.checkDuplicateGroupName({
        companyId: `${companyId}`,
        groupName: `${groupName}`,
      }),
      {
        params: query,
      },
    );
    return response;
  }
}
