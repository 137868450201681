import React from 'react';
import './forgotSuccessModal.scss';
import CheckCircle from 'assets/icons/check-circle.svg';
import { OrangeButton } from 'components/atoms/OrangeButton';
import words from 'constants/words';

type PropType = {
  display: string;
  onClick?: () => void;
};

export default function ForgotSuccessModal(props: PropType) {
  const { display, onClick } = props;

  return (
    <div className="modal-forgot-sc" style={{ display: display }}>
      <div className="modal-content">
        <div className="modal-box">
          <img src={CheckCircle} />
          <p className="sc-title">{words.memberForgotNotice}</p>
          <div className="sc-button">
            <OrangeButton text="OK" onClick={onClick} />
          </div>
        </div>
      </div>
    </div>
  );
}
