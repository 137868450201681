import React from 'react';
import { Trash, BigInfo } from '../../atoms/Icons';
import { Dropdown } from '../../molecules/Dropdown';
import { DropdownSchema } from '../../templates/MemberRankingList/types';
import words from '../../../constants/words';
import {
  DataTable,
  Table,
  TableHead,
  TableRow,
  TableBody,
  DataTableRow,
  DataTableHeader,
  DataTableCustomRenderProps,
  DenormalizedRow,
  DataTableSkeletonHeader,
} from 'carbon-components-react';
import {
  CustomTableHeader,
  CustomTableSelectAll,
  CustomTableRowNP,
  NPTCustomTableSelectRow,
  CustomTableCell,
  ActionsContainer,
  RemoveText,
  NPContainer,
  RemoveDiv,
  MembersSelectedText,
  ActionsDiv,
  TrashDiv,
  ActionsText,
  RemoveText1,
  NPTableWrapper,
  ContentTable,
  CancelDiv,
  TableTextDropdownDiv,
  TableTitleText,
  DropDownTextDiv,
  StyledTable,
  NoMemberDiv,
  InfoIconDiv,
  ChooseGroupLabel,
  ChooseGroupText,
  CustomDataTableSkeletonContainer,
  CustomDataTableSkeleton,
} from './elements';
import { TableLoader } from '../../atoms/Loading';

export type PropsNotPaginatedDataTable = {
  rows: DataTableRow<string>[];
  headers: DataTableHeader<string>[];
  isSelectable?: boolean;
  isLoading?: boolean;
  className?: string;
  onSelectRows?: (selectedRows: DenormalizedRow[] | any[]) => void;
  onClickRemove: (member: DataTableRow<string>) => void;
  toggleRemoveDiv?: boolean | null;
  onRemoveMembers: () => void;
  filterTableRowsBy: { selectedItem: string };
  onChangeTableGroupFilter: (item: DropdownSchema) => void;
  filterGroupList: string[];
};

const Component = ({
  rows,
  headers,
  className,
  isSelectable = true,
  isLoading,
  onSelectRows,
  onClickRemove,
  toggleRemoveDiv,
  onRemoveMembers,
  filterTableRowsBy,
  onChangeTableGroupFilter,
  filterGroupList,
}: PropsNotPaginatedDataTable): React.ReactElement => {
  return isLoading ? (
    <CustomDataTableSkeletonContainer>
      <CustomDataTableSkeleton
        showToolbar={false}
        showHeader={false}
        headers={headers as DataTableSkeletonHeader[]}
        columnCount={headers.length}
        className={className}
        rowCount={0}
      />
      <TableLoader />
    </CustomDataTableSkeletonContainer>
  ) : (
    <NPContainer className={className}>
      <DataTable
        rows={
          toggleRemoveDiv
            ? rows
            : rows.map(member => ({
                ...member,
                actions: (
                  <ActionsContainer>
                    <RemoveText onClick={() => onClickRemove(member)}>
                      {words.delete}
                    </RemoveText>
                  </ActionsContainer>
                ),
              }))
        }
        headers={headers}>
        {({
          rows,
          headers,
          getTableProps,
          getHeaderProps,
          getRowProps,
          getSelectionProps,
          selectedRows,
          selectRow,
        }: DataTableCustomRenderProps) => {
          if (onSelectRows) {
            onSelectRows(selectedRows as DenormalizedRow[]);
          }
          return (
            <>
              {toggleRemoveDiv && (
                <RemoveDiv>
                  <MembersSelectedText>
                    {selectedRows.length > 1
                      ? selectedRows.length + words.membersSelected
                      : words.memberSelected}
                  </MembersSelectedText>
                  <ActionsDiv>
                    <TrashDiv onClick={onRemoveMembers}>
                      <RemoveText1>{words.remove}</RemoveText1>
                      <Trash height="18px" weight="15px" />
                    </TrashDiv>
                    <CancelDiv
                      onClick={() => {
                        selectedRows.forEach((x: any) => {
                          selectRow(x.id);
                        });
                      }}>
                      <ActionsText>{words.cancel}</ActionsText>
                    </CancelDiv>
                  </ActionsDiv>
                </RemoveDiv>
              )}
              <NPTableWrapper $isNotEmpty={rows.length > 0}>
                {rows.length > 0 ? (
                  <ContentTable>
                    <TableTextDropdownDiv theme={{ toggleRemoveDiv }}>
                      <TableTitleText>{words.memberInRanking}</TableTitleText>
                      <DropDownTextDiv>
                        <TableTitleText className="groupLabel">
                          {words.group}
                        </TableTitleText>
                        <Dropdown
                          styles={{
                            width: 'fit-content',
                            maxWidth: '200px',
                          }}
                          id={'group-filter'}
                          label={''}
                          items={Array.from(
                            new Set(
                              [words.rankingEditMemberAll].concat(
                                filterGroupList,
                              ),
                            ),
                          )}
                          onChange={onChangeTableGroupFilter}
                          dropdownProps={{
                            selectedItem:
                              filterGroupList.indexOf(
                                filterTableRowsBy.selectedItem,
                              ) > -1
                                ? filterTableRowsBy.selectedItem
                                : words.rankingEditMemberAll,
                          }}
                          inline
                        />
                      </DropDownTextDiv>
                    </TableTextDropdownDiv>
                    <StyledTable $dataLength={rows.length}>
                      <Table {...getTableProps()}>
                        <TableHead>
                          <TableRow>
                            {isSelectable && (
                              <CustomTableSelectAll {...getSelectionProps()} />
                            )}
                            {headers.map((header: DataTableHeader<string>) => (
                              <CustomTableHeader
                                {...getHeaderProps({ header })}
                                key={header.key}>
                                {header.header}
                              </CustomTableHeader>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rows.map(row => (
                            <CustomTableRowNP
                              {...getRowProps({ row })}
                              key={row.id}
                              onClick={() => selectRow(row.id)}
                              $isRead={selectedRows.some(
                                item => item.id === row.id,
                              )}>
                              {isSelectable && (
                                <NPTCustomTableSelectRow
                                  {...getSelectionProps({ row })}
                                />
                              )}
                              {row.cells.map(cell => (
                                <CustomTableCell key={cell.id}>
                                  {cell.value}
                                </CustomTableCell>
                              ))}
                            </CustomTableRowNP>
                          ))}
                        </TableBody>
                      </Table>
                    </StyledTable>
                  </ContentTable>
                ) : (
                  <NoMemberDiv>
                    <InfoIconDiv>
                      <BigInfo />
                    </InfoIconDiv>
                    <ChooseGroupLabel>{words.chooseGroup}</ChooseGroupLabel>
                    <ChooseGroupText>{words.chooseGroupText}</ChooseGroupText>
                  </NoMemberDiv>
                )}
              </NPTableWrapper>
            </>
          );
        }}
      </DataTable>
    </NPContainer>
  );
};

export default Component;
