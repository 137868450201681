import AuthService from '../../ports/AuthService';

export type adminVerificationUseCase = (
  pk: string,
  token: string,
) => Promise<object | null>;

export const buildAdminVerification = (dependencies: {
  authService: AuthService;
}): adminVerificationUseCase => {
  const { authService } = dependencies;

  const adminVerification: adminVerificationUseCase = (pk, token) =>
    authService.adminVerification(pk, token);

  return adminVerification;
};
