import React from 'react';

type Props = {
  [key: string]: string | number | Function;
};

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 0.25C4.16751 0.25 0.25 4.16751 0.25 9C0.25 13.8325 4.16751 17.75 9 17.75C13.8325 17.75 17.75 13.8325 17.75 9C17.75 6.67936 16.8281 4.45376 15.1872 2.81282C13.5462 1.17187 11.3206 0.25 9 0.25ZM9 3.375C10.5533 3.375 11.8125 4.6342 11.8125 6.1875C11.8125 7.7408 10.5533 9 9 9C7.4467 9 6.1875 7.7408 6.1875 6.1875C6.18584 5.44107 6.48162 4.72473 7.00942 4.19692C7.53723 3.66912 8.25357 3.37334 9 3.375ZM14 14.575C11.1607 17.1416 6.83929 17.1416 4 14.575V14.2125C3.95074 12.4315 5.3446 10.9428 7.125 10.875H10.875C12.6464 10.9461 14.0355 12.4213 14 14.1938V14.575Z"
        fill="#333333"
      />
    </svg>
  );
};

export default Component;
