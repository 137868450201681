import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { theme } from '../../../config';
import words from '../../../constants/words';
import { EntryLogActionType } from '../../../domain/entities/record';

export type EntryLogProps = {
  dateAndTime: Date;
  action: EntryLogActionType;
  reason?: string;
  memberName: string;
};

const Entry = styled.div`
  background: ${theme.colors.ui01};
  display: flex;
  flex-direction: column;
  padding-top: 1em;
  padding-bottom: 14px;
`;
const Label = styled.label`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.32px;
  color: ${theme.colors.text01};
  padding-top: 3px;
`;

const Component = ({
  dateAndTime,
  action,
  memberName,
  reason,
}: EntryLogProps): React.ReactElement => {
  const getActionName = () => {
    switch (action) {
      case 'apply':
        return words.appliedToThisRanking(memberName);
      case 'withdraw':
        return words.withdrawnApplication(memberName);
      case 'reapply':
        return words.reappliedToThisRanking(memberName);
      case 'reject':
        return words.rejectedApplication(memberName);
      case 'approve':
        return words.approvedApplication(memberName);
      default:
        break;
    }
  };

  const date = moment(dateAndTime).format('YYYY/MM/DD');
  const time = moment(dateAndTime).format('HH:mm');

  return (
    <Entry>
      <Label>{`${words.transHistDate}: ${date} @ ${time}`}</Label>
      <Label>{`${getActionName()}`}</Label>
      {reason ? <Label>{`${words.reason}: ${reason}`}</Label> : null}
    </Entry>
  );
};

export default Component;
