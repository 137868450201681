import React from 'react';
import styled from 'styled-components';
import { theme } from '../../../config';

type Props = {
  [key: string]: string | number;
};

const Wrapper = styled.div`
  width: 156px;
  height: 135px;
  @media ${theme.breakpoints.mobile} {
    width: 100px;
    height: 87px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const Component = (props: Props): React.ReactElement => {
  return (
    <Wrapper>
      <svg
        width="156"
        height="135"
        viewBox="0 0 156 135"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M66.875 100.938H11.25V16.9994L74.8294 60.9987C76.7362 62.3215 79.2638 62.3215 81.1706 60.9987L144.75 16.9994V67.5625H155.875V11.9375C155.875 5.79333 150.894 0.8125 144.75 0.8125H11.25C5.10583 0.8125 0.125 5.79333 0.125 11.9375V100.938C0.125 107.082 5.10583 112.062 11.25 112.062H66.875V100.938ZM132.513 11.9375L78 49.6513L23.4875 11.9375H132.513Z"
          fill="#42BE65"
        />
        <path
          d="M101 100.034L109.699 109L128 90"
          stroke="#42BE65"
          strokeWidth="10"
        />
        <circle
          cx="113.5"
          cy="99.5"
          r="30.5"
          stroke="#42BE65"
          strokeWidth="10"
        />
      </svg>
    </Wrapper>
  );
};

export default Component;
