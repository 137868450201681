import AuthService from '../../ports/AuthService';

export type changePasswordUseCase = (
  newPassword: string,
) => Promise<object | null>;

export const buildChangePassword = (dependencies: {
  authService: AuthService;
}): changePasswordUseCase => {
  const { authService } = dependencies;

  const changePassword: changePasswordUseCase = (newPassword: string) =>
    authService.changePassword(newPassword);

  return changePassword;
};
