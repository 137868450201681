import React, { useState, useEffect } from 'react';
import './forgotPassForm.scss';
import FullnameImg from 'assets/icons/fullname.svg';
import words from 'constants/words';
import { memberForgotType, memberForgotErrType } from 'type/member';

type PropType = {
  handleUpdateValues: (object: memberForgotType) => void;
  listError: memberForgotErrType;
};

export default function ForgotForm(props: PropType) {
  const { handleUpdateValues, listError } = props;
  const [value, setValue] = useState<memberForgotType>({
    email: '',
  });

  function newValueUpdate(data: string, type: string) {
    setValue(prevState => ({ ...prevState, [type]: data.toString() }));
  }

  useEffect(() => {
    handleUpdateValues(value);
  }, [value]);

  return (
    <div className="form-sign-up">
      <table>
        <tbody>
          <tr>
            <td>
              <img src={FullnameImg} />
            </td>
            <td>
              <label
                className={value.email.length > 0 ? 'filled' : 'not-filled'}>
                {words.memberSignUpEmailTitle}
              </label>
              <br />
              <input
                className="input-signup"
                type="email"
                placeholder="self.pro@flat-holdings.co.jp"
                value={value.email}
                onChange={e => newValueUpdate(e.target.value, 'email')}
                required
                autoComplete="new-password"
              />
            </td>
          </tr>
          <tr>
            <td
              className={
                listError.email && listError.email.length > 0
                  ? 'errorMessage'
                  : 'noMessage'
              }
              colSpan={2}>
              {listError.email && listError.email.length > 0
                ? listError.email
                : ''}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
