import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import './monthSwiper.scss';

type PropType = {
  currentMonth: string;
  handleSetMonth: (_month: number) => void;
};

export default function YearSwiper(props: PropType) {
  const { currentMonth, handleSetMonth } = props;

  return (
    <Swiper
      className="monthSwiper"
      watchSlidesProgress={true}
      slidesPerView={6}>
      <SwiperSlide>
        <span
          className={parseInt(currentMonth) === 1 ? 'active' : ''}
          onClick={() => handleSetMonth(1)}>
          1月
        </span>
      </SwiperSlide>
      <SwiperSlide>
        <span
          className={parseInt(currentMonth) === 2 ? 'active' : ''}
          onClick={() => handleSetMonth(2)}>
          2月
        </span>
      </SwiperSlide>
      <SwiperSlide>
        <span
          className={parseInt(currentMonth) === 3 ? 'active' : ''}
          onClick={() => handleSetMonth(3)}>
          3月
        </span>
      </SwiperSlide>
      <SwiperSlide>
        <span
          className={parseInt(currentMonth) === 4 ? 'active' : ''}
          onClick={() => handleSetMonth(4)}>
          4月
        </span>
      </SwiperSlide>
      <SwiperSlide>
        <span
          className={parseInt(currentMonth) === 5 ? 'active' : ''}
          onClick={() => handleSetMonth(5)}>
          5月
        </span>
      </SwiperSlide>
      <SwiperSlide>
        <span
          className={parseInt(currentMonth) === 6 ? 'active' : ''}
          onClick={() => handleSetMonth(6)}>
          6月
        </span>
      </SwiperSlide>
      <SwiperSlide>
        <span
          className={parseInt(currentMonth) === 7 ? 'active' : ''}
          onClick={() => handleSetMonth(7)}>
          7月
        </span>
      </SwiperSlide>
      <SwiperSlide>
        <span
          className={parseInt(currentMonth) === 8 ? 'active' : ''}
          onClick={() => handleSetMonth(8)}>
          8月
        </span>
      </SwiperSlide>
      <SwiperSlide>
        <span
          className={parseInt(currentMonth) === 9 ? 'active' : ''}
          onClick={() => handleSetMonth(9)}>
          9月
        </span>
      </SwiperSlide>
      <SwiperSlide>
        <span
          className={parseInt(currentMonth) === 10 ? 'active' : ''}
          onClick={() => handleSetMonth(10)}>
          10月
        </span>
      </SwiperSlide>
      <SwiperSlide>
        <span
          className={parseInt(currentMonth) === 11 ? 'active' : ''}
          onClick={() => handleSetMonth(11)}>
          11月
        </span>
      </SwiperSlide>
      <SwiperSlide>
        <span
          className={parseInt(currentMonth) === 12 ? 'active' : ''}
          onClick={() => handleSetMonth(12)}>
          12月
        </span>
      </SwiperSlide>
    </Swiper>
  );
}
