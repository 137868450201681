import MemberRepository from '../../ports/MemberRepository';
import { CreateMemberField } from '../../../domain/entities/member';

export type createMemberFieldsUseCase = (
  companyId: number,
  customFieldValues: CreateMemberField[],
  memberId: number,
) => Promise<void>;

export const buildCreateMemberFields = (dependencies: {
  memberRepo: MemberRepository;
}): createMemberFieldsUseCase => {
  const { memberRepo } = dependencies;

  const createMemberFields: createMemberFieldsUseCase = async (
    companyId,
    customFieldValues,
    memberId,
  ) => memberRepo.createMemberFields(companyId, customFieldValues, memberId);

  return createMemberFields;
};
