import React, { useEffect, useRef, useState } from 'react';
import Select, { PropsValue } from 'react-select';
import CloseButton from 'assets/icons/close-button.svg';
import './memberInGroupModal.scss';
import words from 'constants/words';
import { OrangeButton } from 'components/atoms/OrangeButton';
import AddBtn from 'assets/icons/addBtn.svg';
import { MemberItemInvite } from '../MemberItemInvite';
import AttachIcon from 'assets/icons/attach-icon.svg';
import {
  accountSettingSetCompanyErrType,
  accountSettingType,
  groupOptionType,
  listGroupOptionType,
  memberGroupType,
  memberProfileUpdate,
} from 'type/member';
import { EditAccountMemForm } from 'components/templates/EditAccountMemForm';
import DefaultAvatar from 'assets/icons/davatar.png';
import EditAvatar from 'assets/icons/edit-avatar.svg';
import authHeader from 'api/auth-header';
import axios from 'axios';
import urls from 'constants/urls';
import { Loading } from 'components/molecules/Loading';
import { extractError, getCurrentUser } from 'utils/common';

const options = [
  { value: 1, label: 'Applicant' },
  { value: 2, label: 'Approver' },
];

const defaultValue = {
  id: 0,
  name: '',
  photo: null,
  role: '',
  email: '',
  full_name: '',
  group: null,
  company: null,
  is_request_change: null,
};

const defaultSetCompanyId = {
  full_name: '',
  company_id: '',
  user_id: '',
};

type PropType = {
  isShow: boolean;
  onHideModal: () => void;
  listGroup: listGroupOptionType;
  groupChooseUser: groupOptionType;
  chooseUserData: memberGroupType | null;
  handleUpdateValues: (_data: memberProfileUpdate) => void;
  dataCurrentMemberPF: memberProfileUpdate;
  handleConfirmChange: () => void;
  handleUpdateAvatar: () => void;
};

export default function MemberInGroupModal(props: PropType) {
  const {
    isShow,
    onHideModal,
    listGroup,
    groupChooseUser,
    chooseUserData,
    handleUpdateValues,
    dataCurrentMemberPF,
    handleConfirmChange,
    handleUpdateAvatar,
  } = props;
  const [errors, setErrors] = useState<accountSettingSetCompanyErrType>(
    defaultSetCompanyId,
  );
  const [valueUser, setValueUser] = useState<memberGroupType>(defaultValue);
  const [picture, setPicture] = useState<string | undefined>(undefined);
  const inputRef = useRef<HTMLInputElement>(null);
  const [errorResponse, setErrorResponse] = useState('');
  const maxFileSize = 5 * 1024 * 1024; //5MB
  const acceptedType = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'];
  const [isShowLoading, setIsShowLoading] = useState('none');

  const handleClickChangeAvatar = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  useEffect(() => {
    if (chooseUserData && chooseUserData.photo) {
      setPicture(`${process.env.REACT_APP_S3_FRONT}${chooseUserData!.photo}`);
    }
  }, [chooseUserData]);

  function handleFileChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }

    if (fileObj.size > maxFileSize) {
      setErrorResponse(words.fileSizeAccepted);
      return;
    }

    if (!acceptedType.includes(fileObj.type)) {
      setErrorResponse(words.fileTypeNotAllowed);
      return;
    }

    setErrorResponse('');
    setIsShowLoading('block');
    if (event.target.files) {
      setPicture(URL.createObjectURL(event.target.files[0]));
      const formData = new FormData();
      formData.append('image', event.target.files[0]);
      formData.append(
        'member_id',
        chooseUserData ? chooseUserData.id.toString() : '',
      );
      axios
        .post(urls.update_member_group_avatar, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            ...authHeader(),
          },
        })
        .then(() => {
          setIsShowLoading('none');
          handleUpdateAvatar();
        })
        .catch(err => {
          if (err.response.data.errors) {
            const errorSv = extractError(err.response.data.errors);
            setErrorResponse(errorSv);
          }
          setIsShowLoading('none');
        });
    }
  }

  return (
    <div className={`invite-member-pages ${isShow ? 'show' : 'hide'}`}>
      <Loading display={isShowLoading === 'block' ? 'block' : 'none'} />
      <div className="title-modal">
        <div className="modal-title">メンバー編集</div>
        <div className="close-button" onClick={onHideModal}>
          <img src={CloseButton} />
        </div>
      </div>
      <div className="member-image">
        <div className="image-profile">
          <img
            src={
              picture && typeof picture !== 'undefined'
                ? picture
                : DefaultAvatar
            }
          />
          <input
            style={{ display: 'none' }}
            ref={inputRef}
            type="file"
            onChange={handleFileChange}
            accept="image/png, image/jpeg, image/jpg, image/gif"
          />
          <div className="member-edit" onClick={handleClickChangeAvatar}>
            <img src={EditAvatar} />
          </div>
          <p
            className="errorFromServer"
            dangerouslySetInnerHTML={{ __html: errorResponse }}></p>
        </div>
      </div>
      <div className="modal-invite-content">
        <EditAccountMemForm
          handleUpdateValues={handleUpdateValues}
          listError={errors}
          isSetCompanyId={true}
          userInfo={valueUser}
          listGroup={listGroup}
          groupChooseUser={groupChooseUser}
          chooseUserData={chooseUserData}
          dataCurrentMemberPF={dataCurrentMemberPF}
        />
      </div>
      <OrangeButton
        text="保存"
        style={{ width: '144px', height: '42px', marginBottom: '30px' }}
        onClick={handleConfirmChange}
      />
    </div>
  );
}
