import React, { useState } from 'react';
import './memberItemSelect.scss';
import DefaultAvatar from 'assets/icons/default-avatar.svg';
import { memberGroupType } from 'type/member';
import { cutString, getRoleName } from 'utils/common';

type PropType = {
  currentGroupName: string;
  groupName: string;
  member: memberGroupType;
  submitChecked: (_member_id: number, _checked: boolean) => void;
  memberSelected: number[];
  mode: number;
  current: number;
};

export default function MemberItemSelect(props: PropType) {
  const {
    currentGroupName,
    groupName,
    member,
    submitChecked,
    memberSelected,
    mode,
    current,
  } = props;
  const [checked, setChecked] = useState(false);

  function handleChecked() {
    setChecked(!checked);
    submitChecked(member.id, checked);
  }

  function getDisableMode() {
    if (mode === 1) {
      return groupName === currentGroupName ? true : false;
    } else {
      return false;
    }
  }

  return (
    <div
      className={`member-list-select-page ${
        memberSelected && memberSelected.includes(member.id) ? 'checked' : ''
      }`}>
      <div className="member-item">
        <div className="avatar">
          <img src={DefaultAvatar} />
        </div>
        <div className="member-info">
          <p className="full-name">{cutString(member.full_name, 10)}</p>
          <p className="position">{getRoleName(member.role)}</p>
        </div>
        <div className="member-group">
          <p className="group-name">{cutString(currentGroupName, 10)}</p>
        </div>
        <div className="member-option">
          {member.id !== current ? (
            <input
              type="checkbox"
              className={`input-checkbox ${getDisableMode() ? 'hidden' : ''}`}
              checked={
                memberSelected && memberSelected.includes(member.id)
                  ? true
                  : false
              }
              disabled={getDisableMode()}
              onClick={handleChecked}
            />
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
}
