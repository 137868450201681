import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useGlobalState } from '../../../hooks/global';
import { useRankingHooks } from '../../../hooks/rankings';
import { Ranking } from '../../../domain/entities/rankings';
import { SubHeader } from '../../atoms/Subheader';
import {
  StyledEditIcon,
  StyledSkeletonText,
} from '../EditRankingMember/EditRankingMember';
import {
  ButtonsDiv,
  HeaderText,
  TitleHeaderDiv,
} from '../../templates/MemberRankingList/elements';
import words, { languageUsed } from '../../../constants/words';
import { CreateRanking } from '../../organisms/CreateRanking';
import { IBasicInfo } from '../../organisms/CreateRanking/CreateRanking';
import { DataTableRow } from 'carbon-components-react';
import Moment from 'react-moment';
import styled, { createGlobalStyle } from 'styled-components';
import { Button } from '../../atoms/Button';
import { SetRankingModal } from '../../templates/SetRankingModal';
import { Tooltip } from '../../atoms/Tooltip';
import { AnchorTag } from '../../atoms/AnchorTag';
import { theme } from '../../../config';
import { AxiosError } from 'axios';
import { navigateToErrorPage } from '../../../utils/handleErrorPage';

const StyledContainer = styled.div`
  && {
    padding-left: 80px;
    padding-right: 75px;
    padding-bottom: 50px;
  }
`;

const StyledHeaderButton = styled(Button)`
  min-width: 116px;
  width: 100%;
  height: 48px;
  display: flex;
`;

const StyledTooltip = styled(Tooltip)`
  &&&&& {
    float: right;
  }
`;

const StyledAnchorTag = styled(AnchorTag)`
  &&&&& {
    div {
      color: ${theme.colors.anchorTagModal};
      @media ${theme.breakpoints.mobile} {
        padding-left: 10px;
      }
    }
  }
`;

export const GlobalStyle = createGlobalStyle`
  .tooltip {
     &&&&& {
      min-width: 256px !important;
      max-width: 256px;
     }
  }
`;

type Props = {};

const Component = ({}: Props): React.ReactElement => {
  const { pathname: locationPathname } = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { id: rankingId } = useParams();
  const {
    useCurrentUser: { currentUser },
  } = useGlobalState();
  const { useSetRankings } = useRankingHooks();
  const { getRanking, updateRanking } = useSetRankings();
  const [rankingData, setRankingData] = useState<Ranking>();
  const [basicInfoData, setBasicInfoData] = useState<IBasicInfo[]>([]);
  const [memberInfoData, setMemberInfoData] = useState<DataTableRow[]>([]);
  const [periodInfoData, setPeriodInfoData] = useState<DataTableRow[]>([]);
  const [rankingName, setRankingName] = useState<string>('');
  const [reloadState, setReloadState] = useState<boolean>(false);
  const [isInactive, setIsInactive] = useState<boolean>(false);
  const [isOpenTooltip, setIsOpenTooltip] = useState<boolean>(false);
  const [isEditingRankingName, setIsEditingRankingName] = useState<boolean>(
    false,
  );

  const { isFetching: isLoadingDataTable } = useQuery(
    [reloadState],
    async () => {
      if (!currentUser?.companyId) {
        throw new Error(`'company id' field not set`);
      }
      const response = await getRanking({
        companyId: currentUser.companyId,
        rankingId: rankingId ? +rankingId : 0,
      });

      setIsInactive(!response.is_active);

      const basiscInfoData: IBasicInfo[] = response.ranking_fields
        .filter(items => items.is_disabled !== true)
        .sort((a, b) => Number(a.order_id) - Number(b.order_id))
        .map((items, index) => {
          return {
            columnName: items.name,
            dataType:
              items.data_type === 'user-info'
                ? words.userInfoIndex
                : items.data_type === 'decimal'
                ? words.decimalIndex
                : items.data_type,
            id: (index + 1).toString(),
            isFavorite: items.is_basis,
          };
        });

      const memberData: DataTableRow[] = response.ranking_members.map(
        (items, index) => {
          return {
            id: (index + 1).toString(),
            name: items.name,
            group: items.group ? items.group?.name : words.unassignedGroup,
          };
        },
      );

      const periodData: DataTableRow[] = response.periods.map(
        (items, index) => {
          return {
            id: (index + 1).toString(),
            rankingPeriodName: items.name,
            startDate: <Moment format="YYYY/MM/DD" date={items.start_date} />,
            endDate: <Moment format="YYYY/MM/DD" date={items.end_date} />,
            visibility: items.is_visible
              ? words.shownPeriod
              : words.hiddenPeriod,
          };
        },
      );

      setPeriodInfoData(periodData);
      setMemberInfoData(memberData);
      setBasicInfoData(basiscInfoData);
      setRankingData(response);
      setRankingName(response.name);
    },
    {
      onError: (err: AxiosError) => {
        navigateToErrorPage(navigate, err.response?.status, locationPathname);
      },
    },
  );

  const { mutate: reenableRankingMutation } = useMutation(
    (id: number) => {
      return updateRanking(id, { is_active: true, name: rankingName });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
        if (rankingId) {
          setReloadState(true);
        }
      },
    },
  );

  return (
    <>
      <GlobalStyle />
      <SubHeader
        title={
          <div>
            {!isLoadingDataTable ? (
              rankingName
            ) : (
              <StyledSkeletonText width="" />
            )}
            {!isLoadingDataTable && !isInactive ? (
              <StyledEditIcon
                onClick={() => {
                  setIsEditingRankingName(true);
                }}
              />
            ) : (
              ''
            )}
          </div>
        }
      />
      <TitleHeaderDiv>
        <HeaderText>{words.rankingDetails}</HeaderText>
        {isInactive && !isLoadingDataTable && rankingId ? (
          <StyledTooltip
            message={words.confirmEnabling}
            direction={'bottom'}
            align="end"
            menuOffset={{ left: 83, top: 10 }}
            tooltipClassName="tooltip"
            isOpen={isOpenTooltip}
            triggerElement={
              <ButtonsDiv>
                <StyledHeaderButton
                  onPress={() => {
                    setIsOpenTooltip(true);
                  }}
                  theme="primary"
                  title={words.activateDisabledRanking}
                />
              </ButtonsDiv>
            }>
            <div className="bx--tooltip__footer">
              <StyledAnchorTag
                onPress={e => {
                  e.stopPropagation();
                  setIsOpenTooltip(false);
                }}
                title={words.cancel}
              />
              <Button
                onPress={e => {
                  e.stopPropagation();
                  reenableRankingMutation(+rankingId);
                }}
                style={{
                  fontSize: languageUsed === 'en' ? '16px' : '13px',
                  width: languageUsed === 'en' ? 'auto' : '117px',
                  height: languageUsed === 'en' ? 'auto' : '32px',
                }}
                title={words.disableActivateRankingDetails}
              />
            </div>
          </StyledTooltip>
        ) : (
          ''
        )}
      </TitleHeaderDiv>
      <StyledContainer>
        <CreateRanking
          basicInfoRow={basicInfoData}
          membersRow={memberInfoData}
          rankingPeriodRow={periodInfoData}
          isLoading={isLoadingDataTable}
          isOnActiveRankingDetails={!isInactive}
          rankingID={rankingId ? +rankingId : 0}
          rankingData={rankingData}
        />
      </StyledContainer>
      <SetRankingModal
        isOpen={isEditingRankingName}
        isEdit
        onSubmit={ranking => {
          setRankingName(ranking.rankingName);
          setIsEditingRankingName(false);
          if (rankingId) {
            reenableRankingMutation(+rankingId);
          }
        }}
        onClose={() => {
          setIsEditingRankingName(false);
        }}
        initialValues={{ rankingName }}
      />
    </>
  );
};

export default Component;
