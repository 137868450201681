import {
  CreateLoginFieldProps,
  LoginField,
} from '../../../domain/entities/field';
import LoginFieldRepository from '../../ports/LoginFieldsRepository';

export type createLoginFieldUseCase = (
  payload: { companyId: number },
  data: CreateLoginFieldProps,
) => Promise<LoginField>;

export const buildCreateLoginField = (dependencies: {
  loginFieldRepo: LoginFieldRepository;
}): createLoginFieldUseCase => {
  const { loginFieldRepo } = dependencies;

  const createLoginField: createLoginFieldUseCase = async (payload, query) =>
    loginFieldRepo.insert(payload, query);

  return createLoginField;
};
