import React, { useState, useCallback } from 'react';
import { ValueContainerProps, PropsValue, components } from 'react-select';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { FormikErrors, FormikTouched } from 'formik';

import words from '../../../constants/words';
import { theme } from '../../../config';

import { GroupApprover } from '../../../domain/entities/group';
import { FetchEntryFieldProps } from '../../../domain/entities/entry';

import { DatePicker } from '../../molecules/DatePicker';
import { TextInput } from '../../atoms/TextInput';
import { Button } from '../../atoms/Button';
import { Dropdown } from '../../molecules/Dropdown';
import { MultiSelectDropdown } from '../MultiSelectDropdown';
import { IOptions as MultiSelectOptions } from '../MultiSelectDropdown/MultiSelectDropdown';
import { moveIsBasisFieldAtFirstPosition } from '../../../utils/arrays';
import moment from 'moment';

import {
  GlobalStyle,
  FirstColumn,
  SecondColumn,
  SecondColumnFields,
  MainContainer,
  DatesContainer,
  NotesInput,
  ButtonsContainer,
  SubmitTooltip,
  MultiSelectContainer,
  StyledLink,
  MultiSelectPlaceholder,
} from './elements';

import { useGlobalState } from '../../../hooks/global';
import data from '../../../constants/data';

const submitTooltipTrigger = (
  setOpenApplyTooltip: (value: boolean) => void,
  isValid?: boolean,
) => (
  <Button
    title={words.apply}
    theme="primary"
    onPress={() => setOpenApplyTooltip(true)}
    disabled={!isValid}
  />
);

const submitTooltipChild = (
  setOpenApplyTooltip: (value: boolean) => void,
  handleSubmitButton: () => void,
  isLoadingCreateEntryApplication?: boolean,
) => (
  <div className="bx--tooltip__footer">
    <StyledLink onClick={() => setOpenApplyTooltip(false)}>
      {words.cancel}
    </StyledLink>
    <Button
      style={{ fontSize: '14px', lineHeight: '16px' }}
      title={words.recordAppTooltipConfirm}
      onPress={handleSubmitButton}
      disabled={isLoadingCreateEntryApplication}
    />
  </div>
);

const submitCancelTooltipChild = (
  setOpenCancellTooltip: (value: boolean) => void,
  handleCancel: () => void,
) => (
  <div className="bx--tooltip__footer">
    <StyledLink onClick={() => setOpenCancellTooltip(false)}>
      {words.cancelTooltipNo}
    </StyledLink>
    <Button
      style={{ fontSize: '14px', lineHeight: '16px' }}
      title={words.recordAppTooltipConfirm}
      onPress={handleCancel}
    />
  </div>
);

export type RecordAppFormType = {
  applicationDate: Date;
  salesDate?: Date;
  customFields: FetchEntryFieldProps[];
  mainApprover?: GroupApprover;
  subApprovers?: PropsValue<MultiSelectOptions>;
  notes?: string;
};

export type Props = RecordAppFormType & {
  approverOptions: GroupApprover[];
  onChangeSalesDate: (value: Date) => void;
  setFieldValue: (
    field: string,
    value: string | number,
    shouldValidate?: boolean,
  ) => void;
  setFieldTouched: (
    field: string,
    isTouched?: boolean | undefined,
    shouldValidate?: boolean | undefined,
  ) => void;
  onChangeMainApprover: (value: GroupApprover) => void;
  onChangeSubApprovers: (value: PropsValue<MultiSelectOptions>) => void;
  onChangesNotes: (value: string) => void;
  handleSubmit: () => void;
  handleCancel: () => void;
  errors?: FormikErrors<RecordAppFormType>;
  touched?: FormikTouched<RecordAppFormType>;
  isValid?: boolean;
  isLoadingCreateEntryApplication?: boolean;
  originalSalesDate?: Date;
};

const Component = ({
  applicationDate,
  salesDate,
  customFields = [],
  mainApprover,
  subApprovers,
  notes,
  approverOptions,
  onChangeSalesDate,
  setFieldValue,
  setFieldTouched,
  onChangeMainApprover,
  onChangeSubApprovers,
  onChangesNotes,
  handleSubmit,
  handleCancel,
  errors,
  touched,
  isValid,
  isLoadingCreateEntryApplication = false,
  originalSalesDate,
}: Props): React.ReactElement => {
  const [selectedMainApprover, setSelectedMainApprover] = useState<
    GroupApprover | undefined
  >(mainApprover);
  const [selectedSubApprovers, setSelectedSubApprrovers] = useState<
    PropsValue<MultiSelectOptions> | undefined
  >(subApprovers);
  const [openApplyTooltip, setOpenApplyTooltip] = useState(false);
  const [openCancelTooltip, setOpenCancelTooltip] = useState(false);
  const [openMobileApplyTooltip, setMobileApplyTooltip] = useState(false);
  const [openMobileCancelTooltip, setMobileCancelTooltip] = useState(false);

  const {
    useCurrentUser: { currentUser },
  } = useGlobalState();

  const handleSetFieldValueChar = (fieldIndex: number) => (
    event: React.ChangeEvent<Element>,
  ) => {
    const fieldValue = (event.target as HTMLInputElement).value;
    setFieldValue(`customFields[${fieldIndex}].value_char`, fieldValue || '');
    setFieldValue(
      `customFields[${fieldIndex}].valueForValidation`,
      fieldValue || '',
    );
  };

  const handleSetFieldValueDeci = (fieldIndex: number) => (
    values: NumberFormatValues,
  ) => {
    const { value } = values;
    setFieldValue(`customFields[${fieldIndex}].value_decimal`, value || 0);
    setFieldValue(`customFields[${fieldIndex}].valueForValidation`, value);
  };

  const handleBlur = (fieldIndex: number) => () => {
    setFieldTouched(`customFields[${fieldIndex}].valueForValidation`, true);
  };

  const handleChangeNotes = (event: React.ChangeEvent<Element>) => {
    onChangesNotes((event.target as HTMLInputElement).value);
  };

  const handleChangeMainApprover = useCallback(
    ({ selectedItem }: { selectedItem: GroupApprover }) => {
      setSelectedMainApprover(selectedItem);
      onChangeMainApprover(selectedItem);
    },
    [],
  );

  const handleChangeSubApprovers = useCallback(selectedList => {
    setSelectedSubApprrovers(selectedList);
    onChangeSubApprovers(selectedList);
  }, []);

  const handleSubmitButton = useCallback(() => {
    handleSubmit();
    setOpenApplyTooltip(false);
    setMobileApplyTooltip(false);
  }, []);

  const subApproversArray =
    (subApprovers as { value: string; label: string }[]) || [];

  const approvers = approverOptions.filter(value => {
    return !subApproversArray.find(
      subApprover => Number(subApprover.value) == value.id,
    );
  });
  const emptyOption = [
    {
      id: null,
      member: {
        id: null,
        name: words.noneSelectApprover,
      },
    },
  ];

  let mainApproverOptions;
  if (data.roleManagement.includes(String(currentUser?.roleName))) {
    mainApproverOptions = [...emptyOption, ...approvers];
  } else {
    mainApproverOptions = approvers;
  }

  const subApproverOptions: MultiSelectOptions[] = approverOptions
    .filter((value: GroupApprover) => value.id !== mainApprover?.id)
    .map((value: GroupApprover) => {
      const subApproverOption: MultiSelectOptions = {
        value: value.id.toString(),
        label: `${value.member?.name}`,
      };
      return subApproverOption;
    });

  const disabledDates = (date: Date) => {
    const dateNow = new Date();
    return date > dateNow;
  };

  const subApproversLength = (((selectedSubApprovers as unknown) as []) || [])
    .length;

  const CustomValueContainer: React.FC<ValueContainerProps> = ({
    children,
    className,
    ...props
  }) => {
    const childrenArray = React.Children.toArray(children);
    const lastElement = (childrenArray[
      childrenArray.length - 1
    ] as unknown) as { props: { value: string } };

    const shouldShowPlaceholder =
      subApproversLength > 0 && !lastElement.props.value;

    const newClassName =
      className +
      (shouldShowPlaceholder ? ' multi-select-sub-approver-exists' : '');

    return (
      <components.ValueContainer className={newClassName} {...props}>
        {children}
        {shouldShowPlaceholder && (
          <MultiSelectPlaceholder>
            {words.subApproverPlaceholder}
          </MultiSelectPlaceholder>
        )}
      </components.ValueContainer>
    );
  };

  const customeFieldsValues = customFields.map(
    ({ valueForValidation }) => valueForValidation,
  );

  const hasChanges =
    (notes === undefined || notes === '') &&
    selectedMainApprover === undefined &&
    (selectedSubApprovers === undefined || subApproversLength === 0) &&
    moment(salesDate).isSame(moment(originalSalesDate)) &&
    !customeFieldsValues.some(Boolean)
      ? false
      : true;

  const basisFirstCustomFields = moveIsBasisFieldAtFirstPosition(customFields);

  return (
    <MainContainer>
      <FirstColumn>
        <DatesContainer>
          <DatePicker
            label={words.applicationDate}
            value={applicationDate}
            datePickerType="single"
            disabled
          />
          <DatePicker
            label={words.salesDate}
            value={salesDate}
            datePickerType="single"
            datePickerProps={{
              disable: [disabledDates],
            }}
            onChange={onChangeSalesDate}
          />
        </DatesContainer>
        {basisFirstCustomFields.map((customField, index) => {
          const customFieldError = (errors?.customFields?.[
            index
          ] as FormikErrors<FetchEntryFieldProps>)?.valueForValidation;

          const customFieldTouched = (touched?.customFields?.[
            index
          ] as FormikTouched<FetchEntryFieldProps>)?.valueForValidation;

          return customField.value_decimal !== undefined ? (
            <NumberFormat
              key={index}
              id={`${customField.id}`}
              customInput={TextInput}
              value={customField.value_decimal || ''}
              label={customField.field_lookup_name}
              onValueChange={handleSetFieldValueDeci(index)}
              onBlur={handleBlur(index)}
              maxLength={255}
              invalid={!!customFieldError && customFieldTouched}
              invalidText={customFieldError}
              thousandSeparator
              hasStarIndex={index === 0}
            />
          ) : (
            <TextInput
              key={index}
              id={`${customField.id}`}
              label={customField.field_lookup_name}
              value={customField.value_char}
              onChange={handleSetFieldValueChar(index)}
              onBlur={handleBlur(index)}
              maxLength={255}
              invalid={!!customFieldError && customFieldTouched}
              invalidText={customFieldError}
            />
          );
        })}
        <NotesInput
          id="application-notes"
          label={words.notesOptional}
          value={notes}
          onChange={handleChangeNotes}
          maxLength={255}
        />
      </FirstColumn>
      <SecondColumn>
        <ButtonsContainer>
          <SubmitTooltip
            message={words.discardChangesEditBasicInfo}
            isOpen={openCancelTooltip}
            align="end"
            menuOffset={{ top: 5, left: 40 }}
            onChange={(ev, { open }) => {
              if (!open) {
                setOpenCancelTooltip(false);
              }
            }}
            triggerElement={
              <Button
                title={words.back}
                theme="light"
                onPress={() =>
                  hasChanges ? setOpenCancelTooltip(true) : handleCancel()
                }
              />
            }>
            {submitCancelTooltipChild(setOpenCancelTooltip, handleCancel)}
          </SubmitTooltip>
          <SubmitTooltip
            message={words.tooltipApplyMessage}
            isOpen={openApplyTooltip}
            onChange={(ev, { open }) => {
              if (!open) setOpenApplyTooltip(false);
            }}
            triggerElement={submitTooltipTrigger(setOpenApplyTooltip, isValid)}
            menuOffset={{ top: 5, left: 40 }}
            align="end"
            autoOrientation>
            {submitTooltipChild(
              setOpenApplyTooltip,
              handleSubmitButton,
              isLoadingCreateEntryApplication,
            )}
          </SubmitTooltip>
          <SubmitTooltip
            message={words.tooltipApplyMessage}
            className="record-app-form-mobile"
            isOpen={openMobileApplyTooltip}
            onChange={(ev, { open }) => {
              if (!open) setMobileApplyTooltip(false);
            }}
            triggerElement={submitTooltipTrigger(
              setMobileApplyTooltip,
              isValid,
            )}
            align="center"
            direction="top">
            {submitTooltipChild(
              setMobileApplyTooltip,
              handleSubmitButton,
              isLoadingCreateEntryApplication,
            )}
          </SubmitTooltip>
        </ButtonsContainer>
        <SecondColumnFields>
          <Dropdown
            id="member-group"
            label=""
            titleText={words.mainApprover}
            styles={{
              width: '100%',
            }}
            items={mainApproverOptions}
            onChange={handleChangeMainApprover}
            backgroundColor={theme.colors.white}
            dropdownProps={{
              selectedItem: selectedMainApprover,
              itemToString: (item: GroupApprover) => item.member?.name,
            }}
          />
          <MultiSelectContainer>
            <MultiSelectDropdown
              label={words.subApproversOptional}
              isMulti={true}
              defaultValue={selectedSubApprovers}
              options={subApproverOptions}
              onChange={handleChangeSubApprovers}
              placeholder={words.subApproverPlaceholder}
              components={{
                ValueContainer: CustomValueContainer,
              }}
              noOptionsText={words.noSubApproverFound}
            />
          </MultiSelectContainer>
          <NotesInput
            id="application-notes"
            className="record-app-form-mobile"
            label={words.notesOptional}
            value={notes}
            onChange={handleChangeNotes}
            maxLength={255}
          />
        </SecondColumnFields>
      </SecondColumn>
      <GlobalStyle />
    </MainContainer>
  );
};

export default Component;
