import {
  RankingFields,
  RankingPeriod,
} from '../../../domain/entities/rankings';
import RankingRepository from '../../ports/RankingRepository';

export type createRankingUseCase = (payload: {
  companyId: number;
  name: string;
  fields: Omit<RankingFields, 'id' | 'ranking_id'>[];
  periods: RankingPeriod[];
  memberIds: number[];
  user_id: number;
}) => Promise<void>;

export const buildCreateRanking = (dependencies: {
  rankingRepo: RankingRepository;
}): createRankingUseCase => {
  const { rankingRepo } = dependencies;

  const createRanking: createRankingUseCase = async payload =>
    rankingRepo.create(payload);

  return createRanking;
};
