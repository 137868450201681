import styled from 'styled-components';
import themes from '../../../config/themes';
import { Button } from '../../atoms/Button';
import { DataTableWithToolbar } from '../../organisms/DataTable';

export const StyledButton = styled(Button)`
  margin-right: 12px;
  margin-left: auto;
  width: auto;
  padding: 10px 61px 10px 13px;
  display: flex;
  &&& svg {
    height: 9px;
    width: 9px;
  }
`;

export const DataTableContainer = styled.div``;

export const DataTableWrapper = styled.div`
  box-shadow: 0px 0px 8px rgba(236, 215, 192, 0.8);
`;

export const ToolBar = styled.div`
  width: 100%;
  height: 64px;
  background-color: ${themes.main.colors.ui01};
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const DropdownWrapper = styled.div<{ marginLeft?: string }>`
  width: 14%;
  max-width: 192px;
  height: 40px;
  margin-left: ${(props): string =>
    props.marginLeft ? props.marginLeft : '13px'};

  @media ${themes.main.breakpoints.wideScreen} {
    width: 18.6%;
  }

  &.dropdown-filter-all div.bx--list-box__menu > div:first-child > div {
    color: ${themes.main.colors.text03};
  }
`;

export const StoryContainer = styled.div`
  width: 100vw;
  height: 100vh;
`;

export const ActionsContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const EditText = styled.p<{ disabled: boolean }>`
  width: 50%;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  ${(props): string =>
    props.disabled
      ? ` color: ${themes.main.colors.text03};
 `
      : ` color: ${themes.main.colors.edit};
          &:hover {
            cursor: pointer;
            text-decoration: underline;
            }`}
`;

export const DeleteText = styled.p<{ disabled: boolean }>`
  width: 100%;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  ${(props): string =>
    props.disabled
      ? ` color: ${themes.main.colors.text03};
 `
      : ` color: ${themes.main.colors.errorColor};
          &:hover {
            cursor: pointer;
            text-decoration: underline;
            }`}
`;

export const ClearFilterText = styled.p`
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: ${themes.main.colors.inverseSupport04};
  cursor: pointer;
  margin: 0 10px;
`;

export const SearchWrapper = styled.div`
  width: 20%;
  max-width: 232px;
  margin-left: 8px;

  .bx--search .bx--search-input {
    background-color: ${themes.main.colors.field01};
  }

  @media ${themes.main.breakpoints.wideScreen} {
    width: 22.45%;
  }
`;

export const StyledDatatableWithToolbar = styled(DataTableWithToolbar)`
  && {
    .bx--skeleton.bx--data-table-container {
      padding-top: 0;
    }

    .bx--skeleton.bx--data-table > tbody > tr > td:nth-child(1),
    tbody > tr > td:nth-child(2) {
      width: 7%;
    }

    tbody > tr > td:nth-child(3),
    tbody > tr > td:nth-child(4),
    tbody > tr > td:nth-child(5),
    .bx--skeleton.bx--data-table > tbody > tr > td:nth-child(2),
    .bx--skeleton.bx--data-table > tbody > tr > td:nth-child(3),
    .bx--skeleton.bx--data-table > tbody > tr > td:nth-child(4) {
      width: 23%;
    }

    td:not(.bx--table-column-checkbox) {
      cursor: pointer;
    }
  }
`;
