import { useCallback, useState } from 'react';
import { NavigateFunction } from 'react-router';
import { useGlobalState } from '../global';
import { AuthHooks } from '.';
import { useDependencies } from '..';
import words from '../../constants/words';
import { Member, UserRoles } from '../../domain/entities/member';
import {
  RANKING_DATA,
  RANKING_FORM_KEY,
  REFERENCE_DATA_KEY,
  REFERENCE_FORM_KEY,
} from '../../constants/storageKeys';

export const useLogin: AuthHooks['useLogin'] = (): {
  login: (
    userId: string,
    companyId: string,
    temporaryPassword: string,
    newPassword?: string,
    isUpdateTemporaryPassword?: boolean,
  ) => Promise<object | void>;
  verifyPassword: (
    userId: string,
    companyId: string,
    password: string,
  ) => Promise<void>;
} => {
  const {
    authInteractor,
    memberInteractor,
    loginFieldInteractor,
  } = useDependencies();
  const { signIn, resetPassword } = authInteractor;
  const { fetchMember } = memberInteractor;
  const { fetchLoginFields } = loginFieldInteractor;
  const {
    useCurrentUser: { setCurrentUser },
  } = useGlobalState();

  const getCustomFieldValues = async (companyId: string, member: Member) => {
    const customFields = await fetchLoginFields({
      companyId: Number(companyId),
    });
    const shownFields = customFields.filter(
      item => item.visible && item.required,
    );
    const fieldsWithNoValues: {
      field_lookup_id: number;
      field_name: string;
      required: boolean;
      is_default: boolean;
    }[] = [];

    const memberCustomValuesObj = member?.fields.reduce(
      (newObj: { [key: string]: any }, item: any) => {
        newObj[item.field_lookup_id] = item;
        return newObj;
      },
      {},
    );

    shownFields.forEach(item => {
      const {
        id: lookupId,
        is_default: isDefaultField,
        name: fieldName,
        required,
      } = item;

      if (isDefaultField) {
        const isEmailEmpty =
          fieldName === words.emailSetting && member.email === '';
        const isPhotoEmpty =
          fieldName === words.profileSetting && member.photoUrl === null;

        if (isEmailEmpty || isPhotoEmpty) {
          fieldsWithNoValues.push({
            field_lookup_id: lookupId,
            field_name: fieldName,
            required,
            is_default: isDefaultField,
          });
        }
      } else {
        if (memberCustomValuesObj) {
          const hasCustomValue =
            memberCustomValuesObj[lookupId] &&
            memberCustomValuesObj[lookupId].value_char;

          if (!hasCustomValue) {
            fieldsWithNoValues.push({
              field_lookup_id: lookupId,
              field_name: fieldName,
              required,
              is_default: isDefaultField,
            });
          }
        }
      }
    });

    return fieldsWithNoValues;
  };
  const login = useCallback(
    async (
      userId: string,
      companyId: string,
      temporaryPassword: string,
      newPassword?: string,
      isUpdateTemporaryPassword?: boolean,
    ) => {
      try {
        setCurrentUser();
        const response = await signIn(userId, companyId, temporaryPassword);
        setCurrentUser({
          companyId: Number(companyId),
          userId: Number(userId),
          memberId: response.data.member_id,
          name: response.data.name,
          access_token: response.data.access,
          refresh_token: response.data.refresh,
          isLoggedIn: false,
          roleName: '',
        });

        const member = await fetchMember({
          companyId: Number(companyId),
          memberId: Number(response.data.member_id),
        });

        const user = {
          companyId: Number(companyId),
          userId: Number(userId),
          memberId: response.data.member_id,
          access_token: response.data.access,
          refresh_token: response.data.refresh,
          name: response.data.name,
          isLoggedIn: !!member?.isDoneInitial,
          roleName: member?.roleName.toLowerCase() as UserRoles,
        };

        if (member && member.is_reset_password) {
          setCurrentUser();
          if (isUpdateTemporaryPassword && newPassword?.length) {
            await resetPassword(
              response.data.member_id,
              member?.email_token || '',
              newPassword,
              newPassword,
            );
            return { isSuccessResetPassword: true };
          }
          return member;
        }

        if (member?.isDoneInitial) {
          const fieldsWithNoValues = await getCustomFieldValues(
            companyId,
            member,
          );

          const hasNoEmptyValues = fieldsWithNoValues.length === 0;

          setCurrentUser({
            ...user,
            isLoggedIn: hasNoEmptyValues,
            emptyCustomFields: hasNoEmptyValues
              ? undefined
              : fieldsWithNoValues,
          });
        } else {
          setCurrentUser(user);
        }
        return member;
      } catch (error) {
        setCurrentUser();
        throw Error;
      }
    },
    [],
  );

  const verifyPassword = useCallback(
    async (userId: string, companyId: string, password: string) => {
      try {
        const response = await signIn(userId, companyId, password);
        if (!response.data?.access) {
          throw new Error('Verify Failed');
        }

        return;
      } catch (error) {
        throw new Error('Verify Failed');
      }
    },
    [],
  );

  return { login, verifyPassword };
};

export const useLogout: AuthHooks['useLogout'] = (): {
  logout: (navigate: NavigateFunction) => Promise<void>;
} => {
  const { authInteractor } = useDependencies();
  const { signOut } = authInteractor;
  const {
    useCurrentUser: { setCurrentUser },
  } = useGlobalState();
  const logout = useCallback(async (navigate: NavigateFunction) => {
    await signOut();
    window.localStorage.removeItem(RANKING_FORM_KEY);
    window.localStorage.removeItem(RANKING_DATA);
    window.localStorage.removeItem(REFERENCE_FORM_KEY);
    window.localStorage.removeItem(REFERENCE_DATA_KEY);
    navigate('/login');
    setCurrentUser();
  }, []);

  return { logout };
};

export const useSignUp: AuthHooks['useSignUp'] = (): {
  signUp: (
    username: string,
    email: string,
    password: string,
    password2: string,
  ) => Promise<object | null>;
  adminSignUp: (
    companyName: string,
    groupName: string,
    representativeName: string,
    companyEmail: string,
    userEmailAddress: string,
    companyTelephone: string,
  ) => Promise<object | null | void>;
  adminFirstLogin: (
    user_id: string,
    password: string,
    password2: string,
    email: string,
    file?: File[],
    customFields?: { identifier: string; value: string }[],
    company_id?: string,
  ) => Promise<object | null | void>;
  verifyEmail: (
    user_id: string,
    email: string,
  ) => Promise<object | null | void>;
  verifyCompanyEmail: (email: string) => Promise<object | null | void>;
  adminVerification: (
    pk: string,
    token: string,
  ) => Promise<object | null | void>;
  adminEmailVerification: (email: string) => Promise<object | null | void>;
} => {
  const { authInteractor } = useDependencies();
  const {
    register,
    adminSignUp: signUpAdmin,
    adminFirstLogin: firstLoginAdmin,
    verifyEmail: emailVerify,
    verifyCompanyEmail: companyEmailVerify,
    adminVerification: adminFirstVerification,
    adminEmailVerification: verifyAdminEmail,
  } = authInteractor;

  const signUp = useCallback(
    async (
      username: string,
      email: string,
      password: string,
      password2: string,
    ) => {
      const response = await register(username, email, password, password2);
      return response;
    },
    [],
  );

  const adminSignUp = useCallback(
    async (
      companyName: string,
      groupName: string,
      representativeName: string,
      companyEmail: string,
      userEmailAddress: string,
      companyTelephone: string,
    ) => {
      const response = await signUpAdmin(
        companyName,
        groupName,
        representativeName,
        companyEmail,
        userEmailAddress,
        companyTelephone,
      );
      return response;
    },
    [],
  );

  const adminFirstLogin = useCallback(
    async (
      user_id: string,
      password: string,
      password2: string,
      email: string,
      file?: File[],
      customFields?: { identifier: string; value: string }[],
      company_id?: string,
    ) => {
      const response = await firstLoginAdmin(
        user_id,
        password,
        password2,
        email,
        file,
        customFields,
        company_id,
      );
      return response;
    },
    [],
  );

  const verifyEmail = useCallback(async (user_id: string, email: string) => {
    const response = await emailVerify(user_id, email);
    return response;
  }, []);

  const verifyCompanyEmail = useCallback(async (email: string) => {
    const response = await companyEmailVerify(email);
    return response;
  }, []);

  const adminVerification = useCallback(async (pk: string, token: string) => {
    const admin = await adminFirstVerification(pk, token);
    return admin;
  }, []);

  const adminEmailVerification = useCallback(async (email: string) => {
    const response = await verifyAdminEmail(email);
    return response;
  }, []);

  return {
    signUp,
    adminSignUp,
    adminFirstLogin,
    verifyEmail,
    verifyCompanyEmail,
    adminVerification,
    adminEmailVerification,
  };
};

//hook for accessing localstorage
export const useLocalStorage: AuthHooks['useLocalStorage'] = (): {
  localStorage: (key: string, initialValue: string) => void;
} => {
  const localStorage = useCallback((key: string, initialValue: string) => {
    const [storedValue, setStoredValue] = useState(() => {
      try {
        const item = window.localStorage.getItem(key);
        return item ? JSON.parse(item) : initialValue;
      } catch (err) {
        console.error(err);
        return initialValue;
      }
    });

    const setValue = (value: any) => {
      try {
        const valueToStore =
          value instanceof Function ? value(storedValue) : value;
        setStoredValue(valueToStore);
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      } catch (err) {
        console.error(err);
      }
    };

    return [storedValue, setValue];
  }, []);
  return { localStorage };
};

export const useResetPassword: AuthHooks['useResetPassword'] = (): {
  forgetPassword: (
    email: string,
    email_token?: string,
  ) => Promise<object | null | void>;
  resetPassword: (
    pk: string,
    token: string,
    password: string,
    password2: string,
  ) => Promise<object | null | void>;
  changePassword: (newPassword: string) => Promise<object | null | void>;
} => {
  const { authInteractor } = useDependencies();
  const {
    forgetPassword: forgotPassword,
    resetPassword: passwordReset,
    changePassword: passwordChange,
  } = authInteractor;

  const forgetPassword = useCallback(
    async (email: string, email_token?: string) => {
      const response = await forgotPassword(email, email_token);
      return response;
    },
    [],
  );

  const resetPassword = useCallback(
    async (pk: string, token: string, password: string, password2: string) => {
      const response = await passwordReset(pk, token, password, password2);
      return response;
    },
    [],
  );

  const changePassword = useCallback(async (newPassword: string) => {
    const response = await passwordChange(newPassword);
    return response;
  }, []);

  return {
    forgetPassword,
    resetPassword,
    changePassword,
  };
};
