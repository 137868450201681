export default {
  actions: '',
  requiredMark: '（必須）',

  // Login Screen
  todoApp: 'Todo App',
  employeeNumber: '社員番号',
  password: 'パスワード',
  login: 'ログイン',
  registerHere: 'こちら',
  forgotPassword: 'パスワードをお忘れの方はこちら',
  showPassword: 'パスワードを表示',
  hidePassword: 'パスワードを非表示',
  companyId: '会社ID',
  userId: 'ユーザID',
  noAccountYet: '新規企業アカウントを作成する方は',
  everContest: 'Ever Contest',
  everContestLogin: 'Ever Contestへログインする',
  bannerLabel:
    'Increase company sales by increasing employees motivation for work.',

  //Forgot Password Screen
  forgotPassInstruction:
    ' パスワードリセット情報を受信するEメールアドレスを入力してください。',
  alreadyHaveAnAccount: 'アカウントをお持ちの方は',
  sendResetLink: '送信する',
  forgotPasswordTitle: 'パスワードをリセット',
  successSent: 'メール送付完了',
  backToLogin: 'ログイン',
  linkSent:
    'パスワードリセットのメール送付が完了しました。受領したメールをご確認ください。',
  changedPassword: 'パスワードの変更が完了しました。',
  submitting: '通信中...',
  forgotPasswordError: 'リンクエラー',
  resetPasswordLinkExpired:
    'リンクの有効期限が切れている、またはすでに使用されています。\nパスワードをリセットするには、再度メールアドレスを入力してください。',
  // Admin Registration
  register: '登録',
  confirmPassword: 'パスワード（確認）',
  emailAdd: 'メールアドレス',
  groupLabel: '所属グループ',
  roleLabel: '権限',
  passwordLabel: 'パスワード',
  passwordMin: '半角英数字６文字以上を入力してください。',
  passwordMismatch: '同じパスワードを入力してください。',
  emailNotif: 'メールで通知を受け取る',
  profilePic: 'プロフィール画像',
  optional: '(任意)',
  fileInputLabel:
    'ファイルタイプは .jpg and .pngをアップロードしてください。\nファイルサイズ上限は500kbとなります。\nプロフィール画像は、ログイン後に登録も可能です。',
  fileInputDesc: 'ファイルをドロップしてアップロードする。',
  fileErrorMsg: '500kb以下のファイルをアップロードしてください。',
  unsupportedFileFormat: 'Unsupported file format',
  welcomeMsg: (name: string): string => `ようこそ, ${name} 様`,
  descMsg: (companyName: string): string =>
    `残りの項目を入力して、${companyName} 様アカウントの本登録を完了してください。`,
  fileInputDescDefault: 'ファイルをドロップしてアップロードする。',
  accountCreated: 'アカウント作成完了',
  successfullyCreated: 'アカウントの作成が完了しました。',

  // Change Password Screen
  pleaseSetNewPassword: '新しいパスワードを設定してください',
  newPassword: '新しいパスワード',
  newPasswordConfirm: '新しいパスワード（確認）',
  change: '変更',
  changePassword: 'パスワードを変更する',
  member: 'メンバー',
  substituteRecord: '代理打刻',
  submit: '送信する',
  setupNewPassword: 'パスワードを変更する',

  // Change Temporary Password Modal
  changeTemporaryPassword: 'パスワード設定',
  permanentNewPassword: '新しいパスワード   (必須)',
  permanentNewPasswordConfirm: '新しいパスワード（確認） (必須)',
  youLoggedWithATempPassword: '新しいパスワードを設定してください。',
  submitTemporaryPassword: '保存する',

  // Home Screen
  home: 'ホーム',
  offDuty: '勤務外',
  onDuty: '勤務中',
  constructionNumber: '三機工業 20-011 マツダ中関B棟',
  constructionSubject: '防塵室新築工事ダクト工事',
  start: '勤務開始',
  modify: '修正',
  finish: '勤務終了',
  cancel: 'キャンセル',
  confirm: '確認',
  confirmAttendance: '以下の内容で出勤を打刻します。よろしいでしょうか？',
  all: '全員',
  edit: '編集',
  delete: '削除',
  move: '所属グループ変更',

  // Member Screen
  addMember: 'メンバーを追加する',
  itemsPerPage: '表示件数 :',
  editMemberButton: '編集する',
  pagination: (min: number, max: number, total: number): string =>
    `全${total}件中${min} - ${max}件を表示中`,
  pageNumber: ['全', 'ページ'],
  save: '保存する',
  modalAddMember: 'メンバー追加',
  addNewMemberInfo: 'メンバー情報を入力してください。',
  editMember: 'メンバー情報編集',
  updateMemberInfo: 'メンバー情報の編集が可能です。',
  name: '名前',
  group: 'グループ',
  role: '権限',
  emailAddress: 'メールアドレス',
  emailAddOptional: 'ユーザメールアドレス  (任意)',
  remark: '備考',
  editMemberSaveButton: '保存',
  memberListHeaders: {
    name: '名前',
    group: 'グループ',
    role: '権限',
    actions: '',
    action: 'Action',
  },
  groupFilter: 'グループ',
  groupFilterMemberRanking: 'グループ検索',
  roleFilter: '権限',
  deleteMemberConfirmation: 'メンバー削除',
  sureToDeleteMember:
    '次のメンバーを削除しますか？\n一度削除したメンバーは復元できません。',
  resetPassword: 'パスワードをリセット',
  temporaryPassword: '仮パスワード',
  searchPlaceholder: 'メンバーを選択する',
  updateMemberToastTitleSuccess: 'メンバー情報更新完了',
  updateMemberToastSubSuccess: 'メンバー情報の更新が完了しました。',
  addMemberToastTitleSuccess: 'メンバー追加完了',
  addMemberToastSubSuccess:
    '新規メンバーの追加が完了しました。仮パスワードをメンバーへ連携の上、初回ログインを行ってください。',
  successDeleteMember: 'メンバー削除完了',
  deleteMemberToastSuccess: '選択したメンバーアカウントの削除が完了しました。',
  members: 'メンバー管理',
  clearFilters: 'フィルターリセット',
  back: 'もどる',
  listing: 'メンバーリスト',
  setting: '設定',
  editMemberSubHeader: 'メンバー情報編集',
  viewMemberSubHeader: 'メンバー詳細',
  generateNewPassword: '仮パスワードを発行する',
  generateNewPasswordTooltip:
    'このメンバーのパスワードをリ\nセットしますか？リセット後は、\n発行された仮パスワードを適切な\nメンバーへ連携してください。',
  successGeneratePassword: 'パスワードリセット完了',
  tempPasswordSuccess:
    'パスワードリセットが完了し、仮パスワードが発行されました。',
  sendTempPassword: '仮パスワードを適切なユーザーへ連携してください。',
  yesGeneratePassword: '実行する',
  searchMember: 'メンバー検索',
  memberSelected: '1 メンバーを選択中',
  membersSelected: 'メンバーを選択中',
  memberSelectedSingular: 'メンバーを選択中',
  moveMemberModalTitle: '所属グループ変更',
  moveMemberModalSubtitle: '選択したメンバーの所属グループを選択してください。',
  moveMemberConfirmation: '所属グループを変更する',
  iCopiedIt: 'コピーしました',
  memberDeletionInlineTitleError: '削除エラー',
  memberDeletionInlineApproverSubError:
    '各グループには１名以上の承認者設定が必須です。本メンバーを削除する場合は、他のメンバーを承認者へ設定の上、再度削除を実行してください。',
  memberDeletionInlineAdminSubError:
    '１名以上の管理者が必須です。本メンバーを削除する場合は、他のメンバーを管理者設定の上、再度削除を実行してください。',
  unassignedGroup: '未所属',
  viewMemberDeleteButton: '削除する',
  moveMemberTitleSuccess: '所属グループ変更完了',
  moveMemberSubtitleSuccess: '選択したメンバーの所属グループが変更されました。',
  editMemberRoleLastApproverError:
    '唯一の承認者であるため、権限を変更できません。\n他の承認者を設定した上、権限を変更してください。',
  editMemberRoleLastAdminError:
    '1つ以上の管理者アカウントは必須です。\n他のアカウントを管理者へ設定したのち、変更可能です。',
  adminDeleteTooltipMessage:
    'この管理者は削除でき\nません。\n\n会社には最低1名の管\n理者が設定されている\n必要がありますので、\n新しい管理者を設定し\nてから削除してくださ\nい。',
  approverDeleteTooltipMessage:
    'このメンバーは、その\nグループの唯一の承認\n者であるため、削除す\nることはできません。\n\nこのメンバーを削除す\nるには、そのメンバー\nのグループに1人以上\nの承認者を追加してく\nださい。',
  emptyMemberListTitle: '検索結果がありません',
  emptyMemberListDetails: '検索内容を変更して再度検索してください。',

  // Roles
  admin: '管理者',
  approver: '承認者',

  // Group Screen
  addGroup: 'グループを追加する',
  number: 'No.',
  groupName: 'グループ名',
  approvers: '承認者',
  membersCount: '所属メンバー数',
  groups: 'グループ管理',
  deleteGroup: 'グループ削除',
  sureToDelete:
    '次のグループを削除しますか？\n一度削除したグループは復元できません。',
  yesDelete: '削除する',
  plusMore: (num: number): string => `+ 他${num}名`,
  groupNameAlreadyTaken:
    'このグループ名はすでに存在しています。別のグループ名を設定してください。',

  // Add Group Modal
  addGroupModal: 'グループを追加',
  addSaveButton: '保存する',
  addLabelModal: '新規グループを追加できます。',
  addInvalidTextGroupName: 'グループ名が必要です',
  addLabelInput: 'グループ名（必須）',
  addPlaceholderInput: 'グループ名を追加',
  addLabelMultiSelect: '承認者（必須）',
  addPlaceholderMultiSelect: '承認者を設定する',
  addErrorMessageMultiSelect: '承認者が必要です',
  errorMessageMultiSelect: `このメンバーは、そのグループの唯一の承認者であるため、削除することはできません。
  このメンバーを削除するには、そのメンバーのグループに1人以上の承認者を追加してください。`,

  // Edit Group Modal
  editGroup: 'グループ編集',
  editSaveButton: '保存する',
  editGroupLabel: 'グループ情報の更新。',
  editInvalidTextInput: 'グループ名が必要です',
  editLabelInput: 'グループ名（必須）',
  editPlaceholderInput: 'グループ名を追加',
  editLabelMultiSelect: '承認者（必須)',
  editPlaceholderMultiSelect: '承認者を設定する',
  editErrorMessageMultiSelect: '承認者が必要です',
  errorMessageDeleteLastGroup: `他のグループを作成するまで削除できません。`,
  labelDeleteAnchor: '削除',

  // Errors
  required: 'このフィールドは入力必須です。',
  requiredLabel: '(必須)',
  invalidCompanyIdFormat: '半角数字を入力してください。',
  invalidUserIdFormat: '半角数字を入力してください。',
  invalidPasswordFormat: 'Invalid password format.',
  passwordInvalid: 'Password is too short.',
  invalidEmailFormat: '有効なメールアドレスを入力してください。',
  loginErrorTitle: 'ログインエラー',
  loginError: 'IDまたはパスワードが誤っています。',
  changePassNotMatchError: 'パスワードが一致しません。',
  changePassCaseError:
    '小文字アルファベット・半角数字を最低１文字ずつ使ってください。',
  somethingWentWrong: 'Something went wrong. Please retry.',
  updateToastTitleError: '更新エラー',
  errorOccuredToastSub: 'エラーが発生しました。再度実行してください。',
  error500kbMax:
    'プロフィール画像のファイル容量上限を超えています。500kb以下のファイルをアップロードしてください。',
  error500PageSubHeader: '500 - サーバエラー',
  error500PageTitle: 'サーバエラー',
  error500PageDetails:
    'サーバエラーが発生しました。\n再度リロードしてください。',
  error500PageActionBtnTitle: 'リロード',
  error404PageSubHeader: '404 - エラー',
  error404PageTitle: 'ページがみつかりません',
  error404PageDetails:
    'お探しのページは、移動、削除、名称が変更された、\nまたは存在しない可能性があります。',
  error404PageActionBtnTitle: 'ホームへ',
  errorLinkExpiredSubHeader: '有効期限切れ',
  errorLinkExpiredTitle: '認証リンク有効期限切れ',
  errorLinkExpiredPageDetails:
    'メール認証リンクは期限切れです。\n認証メールの再送手続きをしてください。',
  errorLinkExpiredPageActionBtnTitle: 'ホームへ戻る',

  // Login Setting
  addField: 'ユーザ情報を追加する',
  fieldName: 'メンバー情報項目名',
  fieldSetting: '必須/任意設定',
  fieldVisibility: '表示/非表示設定',
  mandatory: '必須',
  optional2: '任意',
  shown: '表示',
  hidden: '非表示',
  default: 'デフォルト',
  deleteFieldConfirmation: 'メンバー情報項目削除',
  deleteModalSubtitle: '次のメンバー情報項目を削除しますか？',
  deleteField: '削除する',
  fieldNameAlreadyTaken: '異なる名前を入力してください。',
  fieldNameIsRequired: 'ユーザ情報項目名を入力してください。',
  fieldSuccessTitle: 'メンバー情報項目更新',
  addFieldSuccessMsg: 'メンバー情報項目が更新されました。',
  updateFieldSuccessMsg: 'メンバー情報項目が更新されました。',
  deleteFieldSuccessMsg: 'メンバー情報項目が更新されました。',
  maxFieldsReached: '追加できるユーザ情報項目の上限は5個となります。',
  roleLoginSetting: '役職',
  keep: '保存',
  emailSetting: 'ユーザメールアドレス',
  profileSetting: 'プロフィール画像',

  // Admin Sign Up
  createAnAccount: '新規会員登録',
  companyName: '会社名',
  representativeName: '担当者名',
  representativeEmailAddress: 'ユーザメールアドレス',
  companyTelephone: '代表電話番号',
  alreadyHaveAccount: 'アカウントをお持ちの方は',
  alreadyLogin: 'こちら',
  enterValidEmail: '有効なメールアドレスを入力してください。',
  onlyHalfWidthNumbers: '半角数字を入力してください。',
  validEmailAddress: '有効なメールアドレスを入力してください。',

  //Ranking List
  addMemberRanking: '選択したメンバーを追加する',
  memberInRanking: '本ランキングに所属しているメンバー',
  selectGroup: 'グループを選択する',
  selectMembers: 'メンバーを選択する',
  addMemberInfo: 'メンバーは複数グループから選択可能です。',
  confirmRanking: 'ランキング設定',
  createRanking: 'ランキングを作成する',
  saveRankingMember: '保存する',
  noGroup: '未所属',
  rankingAppListGroupApproverErrorTitle: 'エラー',
  rankingAppListApproverErrorSubtitle:
    '所属グループに他の\n承認者アカウントが存在しない\nため、申請できません。他の承認者\nを追加の上、再度申請してく\nださい。',
  rankingAppListGroupErrorSubtitle:
    'グループに所属していないため、\n成績を申請することができません。\n管理者に連絡し、グループを設定の上、\n再度実施してください。',

  //Ranking Period
  editRankingPeriod: 'ランキング期間編集',
  notificationTitlePeriod: 'ランキング期間更新完了',
  successfullyUpdatedRankingPeriods: 'ランキング期間の更新が完了\nしました。',

  // Navigation Items
  record: '成績管理',
  recordApplication: '成績申請',
  recordReference: '成績参照',
  recordApproval: '成績承認',
  ranking: 'ランキング',
  rankingTable: 'ランキング',
  graph: 'グラフ',
  manageRanking: 'ランキング管理',
  rankingList: 'ランキング一覧',
  config: '各種設定',
  groupManagement: 'グループ管理',
  memberManagement: 'メンバー管理',
  companyInfo: '会社情報管理',
  companyInformation: '会社情報',
  payment: '支払い管理',
  accountConfig: 'アカウント設定',

  // Application Sent
  successfulSignUp: '仮会員登録が完了しました。',
  sentLink: (email: string): string =>
    `仮会員登録が完了しました。${email} に本登録用のリンクを送付しましたので、受信ボックスをご確認ください。`,
  sentLink1: `仮会員登録が完了しました。`,
  sentLink2:
    ' に本登録用のリンクを送付しましたので、受信ボックスをご確認ください。',

  // User Profile
  userEmailAddress: 'ユーザメールアドレス',

  success: 'グループリスト更新',
  accountUpdated: 'アカウント設定更新',
  successfullyUpdated: 'アカウント設定が更新されました。',
  successfullyDeleted: 'グループリストの更新が完了しました。',
  successfullyAdded: 'メンバー情報項目が更新されました。',
  successfullyAddedGroup: 'グループリストが更新されました。',
  successfullyUpdatedGroup: 'グループリストの更新が完了しました。',
  error: '更新エラー',
  updateError: '更新エラー',
  errorOccured: 'エラーが発生しました。\n再度実行してください。',

  index: '表示順',
  dataType: 'データタイプ',
  columnName: 'ランキング項目名',
  userInfo: 'ユーザー情報',
  decimal: '数字',
  duplicate: '複製',
  disable: 'アーカイブ',
  activate: '再表示',
  addRankingColumn: 'ランキング項目を追加する',
  createRankingName: 'ランキング名設定',
  editRankingName: 'ランキング名編集',
  pleaseEnterRankingName: 'ランキング名を設定してください。',
  rankingName: 'ランキング名',
  rankNameUnique: '同一ランキング名は登録できません。',
  activeRanking: 'ランキング一覧',
  disabledRanking: 'アーカイブ済ランキング',
  createNewRanking: 'ランキングを作成する',
  findActiveRankings:
    'ランキングの一覧です。ランキング設定の編集やランキングのアーカイブが可能です。',
  findDisabledRankkings:
    'アーカイブ済ランキング一覧です。ランキングの復元や削除が可能です。',
  no: '番号',
  yes: 'はい',
  editBasic: '基本設定編集',
  editMembers: 'メンバー編集',
  editPeriod: '期間編集',
  editUserProfile: '編集する',

  // Ranking List
  rankingListHeaders: {
    no: 'No',
    name: 'ランキング名',
    index: '表示順',
    action: '',
  },
  recordNewApplication: '成績を申請する',
  publish: '変更適用設定',
  changeSettingNow: '変更を即時反映する',
  setChangeSchedule: '変更反映日時を指定する',
  date: '日付',
  chooseTime: '時間',
  changelogs: '変更履歴',
  activeColumns: 'ランキング項目',
  disabledColumns: 'アーカイブ済み項目',
  remove: '選択したメンバーを削除する',
  removeRankingList: '削除',

  // Record Reference
  recordReferenceListHeaders: {
    no: 'No',
    date: '申請日',
    name: 'ランキング名',
    mainApprover: '承認者',
    status: 'ステータス',
  },
  recordRefStatus: {
    unapproved: '未承認',
    approved: '承認済み',
    rejected: '差し戻し',
    withdraw: 'キャンセル済み',
  },
  dropdownAll: 'すべて',
  disableRanking: 'このランキングの期間をアーカイブしてよろしいですか？',
  deleteRanking:
    '本ランキングを削除しますか？\n一度削除したランキングは復元できません。',
  editBasicInfo: '基本情報編集',
  updatedBasicInfo: [
    '本ページは、変更予約された基本情報を表示しています。現在のランキングの基本情報を見るには',
    'こちら',
    'をクリックしてください',
  ],
  statusFilterValues: [
    {
      id: '',
      label: 'すべて',
    },
    {
      id: 'unapproved',
      label: '未承認',
    },
    {
      id: 'approved',
      label: '承認済み',
    },
    {
      id: 'rejected',
      label: '差し戻し ',
    },
    {
      id: 'withdraw',
      label: '引き戻し',
    },
  ],
  deleteRankingApplicationTitle: '申請削除',
  deleteRankingApplicationSubtitle:
    '本当にこの申請を削除しますか？\n一度削除した申請は復元できません。',
  deleteApplicationButton: '削除する',
  withdrawApplicationButton: '引き戻す',
  cancelApplicationButton: '申請をキャンセル',
  withdrawApplicationTooltipYes: '実行する',
  reapplyApplicationTooltipYes: '実行する',
  recordReferencePageHeader: '成績参照',
  recordReferenceEmptyTitle: '参照できる成績がありません',
  recordReferenceEmptyDetails:
    '成績申請を行う、成績を参照することができます。\n成績を申請した後、再度ご確認ください。',
  withdrawalCompleted: '引き戻し完了',
  referenceWithdrawSuccess: '申請の引き戻しが完了しました。',
  referenceCancelSuccess: '申請のキャンセルが完了しました。',
  reapplyCompleted: '成績申請完了',
  reapplySuccess: '成績申請が完了しました。',
  referenceDeleted: '申請書の削除完了',
  referenceDeletedSuccess: '申請書の削除が完了しました。',
  manageApplicationFilteredEmptyTitle: '参照できる成績がありません',
  manageApplicationFilteredEmptyDetails:
    '成績申請を行うと、その申請について参照することがで\nきます。',

  // Record Application
  apply: '申請する',
  recordAppTooltipConfirm: '実行する',
  applicationDate: '申請日',
  salesDate: '売上日',
  calling: '架電数',
  appointment: 'アポ数',
  totalSalesIndex: '売上',
  mainApprover: '承認者',
  subApproversOptional: '代理承認者（任意）',
  subApproverPlaceholder: '承認者を選択する。',
  notesOptional: '備考（任意）',
  onlyNumericAreAccepted: '半角数字を入力してください。',
  onlyHalfNumbersAccepted: '半角数字を入力してください。',
  details: '会社情報',
  addRecordAppToastTitleSuccess: '成績申請完了',
  addRecordAppToastSubSuccess: '成績申請が完了\nしました。',
  addRecordAppToastTitleError: '更新エラー',
  addRecordAppToastSubError: 'エラーが発生しました。\n再度実行してください。',
  rankingAppListPageHeader: '成績申請',
  rankingAppListEmptyTitle: '所属しているランキングがありません',
  rankingAppListEmptyDetails:
    '現在、所属しているランキングが存在しません。\n成績申請は、ランキングに所属後に実施可能です。',
  cancelTooltipNo: 'いいえ',

  //AddPeriod Table
  rankingPeriodName: 'ランキング期間名',
  startDate: '開始日',
  endDate: '終了日',
  visibility: '表示/非表示設定',
  addRankingPeriod: 'ランキング期間を追加する',
  deleteRankingPeriod: 'このランキングの期間を削除して\nよろしいですか？',
  savePeriod: '保存',
  deletePeriod: '削除',
  deleteTooltipRankingPeriod: '実行する',
  invalidText: '入力は必須です。',
  invalidDate: '入力は必須です。',
  deleteLastPeriod:
    '1つ以上の期間は必要となります\n削除するには、期間を追加してください。',

  // Record Approval List
  myMainApprover: '承認申請一覧',
  secondary: '代理承認申請一覧',
  applicant: '申請者',
  status: 'ステータス',
  tooltipApplyMessage: 'この成績を申請しますか？',
  mainApprovalEmpty: '承認申請がありません。',
  mainApprovalEmptyDetails: '承認申請依頼を受領したのち、操作可能です。',
  secondaryApprovalEmpty: '承認申請がありません。',
  secondaryApprovalEmptyDetails: '承認申請依頼を受領したのち、操作可能です。',
  noFilterResults: '該当検索結果なし',
  noFilterResultsDetails:
    '該当する検索結果がありません。検索項目を変更の上、\nサイド検索してください。',
  noFilterResultsDetailsMobile:
    '該当する検索結果がありません。検索項\n目を変更の上、\nサイド検索してください。',

  // Approval Status
  unapproved: '未承認',
  approved: '承認済み',
  rejected: '差し戻し',
  withdraw: '引き戻し',
  cancelApplication: '申請をキャンセル',
  viewingLatest: '現在のバージョン',

  // Reject Application Modal
  rejectApplication: '申請差し戻し',
  rejectApplicationReason:
    '本当にこの申請を差し戻しますか？\n下記に申請の差し戻し理由を記載し、申請者へ通知することができます。',
  rejectApplicationReasonMobile:
    '本当にこの申請を差し戻しますか？\n下記に申請の差し戻し理由を記載し、\n申請者へ通知することができます。',
  reasonOptional: '差し戻し理由（任意）',
  submitRejection: '差し戻す',

  // Reject/Approve Application
  reject: '差し戻す',
  approve: '承認する',
  notes: '備考',
  tooltipApproveMessage: '本当にこの申請を承認しますか？',
  unapprovedUppercase: '未承認',
  companyEmail: '会社代表メールアドレス',
  transHistDate: '日時',
  transHistAction: '行動',
  yesApprove: '承認する',
  mainApproverRequired: '承認者（必須）',
  approvalSuccess: 'ステータス更新完了',
  approvalSuccessMessage: '申請書のステータスの更新が\n完了しました。',
  approvalRejected: 'ステータス更新完了',
  approvalRejectedMessage: '申請の差し戻しが完了しました。',
  approvalError: '更新エラー',
  approvalErrorMessage: 'エラーが発生しました。\n再度実行してください。',

  reapply: '再申請する',
  tooltipWithdrawApplicationMsg: '本当にこの申請を引き戻しますか？',
  tooltipCancelApplicationMsg: '本当にこの申請をキャンセルしますか？',
  tooltipReapplyApplicationMsg: '本当にこの申請を再申請しますか？',
  basic: '基本設定',
  period: '期間設定',
  next: '次へ',

  //create ranking
  basicInfo: '基本設定',
  rankingPeriod: 'ランキング期間',
  groupLabelRanking: '本ランキングに所属しているメンバー',
  rankingMembers: 'メンバー',
  confirmDeleteColumn:
    '本ランキング項目を削除しますか？\n一度削除した項目は復元できません。',
  confirmDisableColumn: '本ランキング項目をアーカイブしますか？',
  selectIndexBeforeDisable:
    '表示順選択されているため、アーカイブできません。\n\n表示順項目設定を他のランキング項目へ変更の上、再度アーカイブしてください。',
  copied: 'コピーしました',
  memberRanking: 'メンバー設定',
  decimalIndex: '数字',
  userInfoIndex: 'ユーザー情報',
  shownPeriod: '表示',
  hiddenPeriod: '非表示',
  loadingMultiSelect: 'Loading...',

  //Change Password Modal
  currentPassword: '現在のパスワード',
  updateYourPassword: 'パスワードを変更するには、次の項目を入力してください。',
  transactionHistory: '履歴',
  reason: '理由',
  appliedToThisRanking: (name: string): string => `${name}が申請しました。`,
  withdrawnApplication: (name: string): string =>
    `${name}が申請を差し戻しました。`,
  reappliedToThisRanking: (name: string): string => `${name}が再申請しました。`,
  approvedApplication: (name: string): string => `${name}が承認しました。`,
  rejectedApplication: (name: string): string => `${name}が差し戻しました。`,

  //Member Ranking List
  chooseGroup: 'グループ未選択',
  chooseGroupText:
    'グループを選択の上、メンバーを選択して「選択したメンバーを追加する」ボタン\nをクリックする必要があります。\nまた、異なるグループから複数のメンバーを追加することもできます。',
  confirmChangeIndex: '本当にランキング表示順を変更し\nますか？',
  selectIndexBeforeDelete:
    '列を削除する前に、まず新しいインデックスを選択してください。',
  selectIndexTip: 'ランキング項目を追加した後、表示順を変更してください。',
  toEdit: '編集する',
  toUpdate: '更新する',
  deleteTooltipMsg:
    '本当にキャンセルしますか？ 実行した場合、変更内容は保存されません。',
  allMemberRanking: 'すべて',
  selectAll: 'すべて',
  noMembersAvailable: '検索できるメンバーがいません。',
  successfullyUpdatedRankingMembers: 'ランキングメンバーを更新しました。',
  rankingEditMemberAll: 'すべて',
  rankingEditMembers: 'メンバー編集',
  notificationTitle: 'メンバー更新完了',

  // Company Info
  companyEmailCopyTooltip: 'コピーしました',
  companyInfoChanged: '会社情報変更',
  companyInformationChanged: '会社情報が変更されました。',

  // Notifications
  applicationReceivedNotif: {
    from: 'さんより',
    receivedApplicationForGrades: 'への成績申請',
    receivedApplicationForGrades2: 'を受領しました。',
    receivedApplicationForGradesFull: 'への成績申請を受領しました。',
  },
  applicationGradedNotif: {
    backTo: 'への申請が',
    isApproved: 'さんに承認',
    isApproved2: 'されました。',
    isRejected: 'さんに差し',
    isRejected2: '戻されました。',
    isApprovedFull: 'さんに承認されました。',
    isRejectedFull: 'さんに差し戻されました。',
  },
  applicationRankingName: '\xa0への申請が\xa0',
  approvedNotif: '\xa0さんに承認されました。',
  rejectedNotif: '\xa0さんに差し戻されました。',
  applicantName: '\xa0さんより\xa0',
  receivedNotif: '\xa0への成績申請を受領しました。',
  today: '今日',
  yesterday: '昨日',
  pastDates: '過去申請',
  noNotifications: '通知がありません',
  noNotifLabel: '成績申請後に通知が表示されます。',
  itemsPerPageNotif: '通知表示件数:',
  paginationNotif: (min: number, max: number, total: number): string =>
    `${total}の通知のうち、${min} - ${max}を表示中`,

  // Bulk Delete Mains
  bulkDeleteMembersModalConfirmation: 'メンバー削除',
  bulkDeleteMembersModalSubtitle:
    '次のメンバーを削除しますか？ \n一度削除したメンバーは復元できません。',
  cannotDeleteMembers:
    '唯一のグループ承認者、または管理者であるため、下記のメンバーを削除することはできません。',
  bulkDeleteCheckboxLabel:
    '選択したメンバーから削除されないメンバーがいることに同意の上、削除を実行する。',
  bulkDeleteSuccess: 'メンバー削除完了',
  bulkDeleteSuccessMsg: `選択したメンバーアカウント\nの削除が完了しました。`,

  activateRanking: '有効化する',
  confirmEnabling: '本当に有効化しますか？',
  disabledActivateRankingButton: '有効化',
  disabledActivateRanking: '実行する',
  disabledDeleteRanking: '実行する',
  disableActivateRankingDetails: '実行する',
  confirmSaveRanking: '保存',
  setSaveRanking: '保存する',
  logHasUpdated: (name: string): string => `${name}が更新しました。`,
  logSeeChanges: '変更内容を見る',
  logUpdatedBy: (name: string): string => `${name}が更新しました。`,

  // Edit Basic Info
  saveEditBasicInfo: '基本情報更新',
  confirmToSave: '基本情報を更新しますか？',
  enterRankingName: 'ランキング名を編集の上、保存ボタンを押してください。',
  selectNewIndexFirst: '表示順選択されているため、アーカイブできません。',
  noDisabledColumnsTitle: 'アーカイブ済項目がありません',
  noDisabledColumnsDetials:
    'アーカイブ済のランキング項目がありません。\nランキング項目をアーカイブするとこちらに表示\nされます。',
  warning: 'ランキング項目削除        ',
  warningDeleteColumnDatails:
    'ランキング項目の削除に問題がなければ、保存ボタンをクリックして変更を保存してください。',
  maxActiveColInfo: 'ランキング項目の上限は5個となります。',
  discardChangesEditBasicInfo:
    '本当にキャンセルしますか？\n実行した場合、変更内容は保存さ\nれません。',
  cancelDiscard: 'いいえ',
  proceedDiscard: '実行する',

  // Login Form Setting Default Field Names
  companyIdDefaultField: '会社id',
  userIdDefaultField: 'ユーザid',
  nameDefaultField: '名前',
  groupDefaultField: 'グループ',
  roleDefaultField: '役職',
  rankDisplaySetting: '順位表示設定',
  archiveRanking: 'アーカイブする',
  archiveActiveRanking: '実行する',
  shouldHaveIndexedColumn:
    'データタイプが”数\n字”のランキング項目\nを1つ以上設定する必\n要があります。',
  didJustReachLimit: '追加できるユーザ情報項目の上限は5個となります。',
  rankingSave: '保存',
  confirmRankingIndex: '実行する',
  confirmRankingDisabledColumnDelete: '実行する',
  addRankingColumnDelete: '実行する',
  disableRankingColumn: '本ランキング項目をアーカイブし\nますか？',
  confirmDisableRankingColumn: '実行する',

  //Email verification link
  verificationLinkExpired: '認証メール有効期限切れ',
  emailAddressRequired: 'メールアドレス（必須）',
  enterYourEmailAdd:
    '認証メールの有効期限が切れています。\n再度、登録したメールアドレスを入力してください。',
  enterEmailAssociatedWithYourAccount:
    'メールアドレスが存在しません。\n登録したメールアドレスを入力してください。',
  newVerificationLinkSent: '受領した認証メールを確認してください。',

  logout: 'ログアウト',
  additionalInfoRequired: '追加アカウント情報',
  additionalInfoSubtitle:
    '追加のアカウント情報が必要です。 \n必要な情報を保存したのち、アカウントにリダイレクトされます。',
  customFieldRequired: '入力は必須です。',
  verificationLinkError: 'メールアドレスエラー',
  verificationLinkSuccess: 'メール送信完了',
  //RankingDetails
  rankingDetails: 'ランキング詳細',
  activateDisabledRanking: 'ランキングを有効化する',
  uniqueRankingName: '同一ランキング名は登録できません。',
  disabledColumnDeleteRanking: '実行する',
  noFilterResultsRankingTable:
    'ランキング名とランキング期間を選択してください。',
  noFilterResultsSubdescription:
    'ランキングを表示させるには、 \nランキング名とランキング期間を選択してください。',
  // Period List
  rankingPeriodHeaders: {
    no: 'No',
    name: 'ランキング期間',
    index: '表示順',
    action: '',
  },

  viewMemberModalHeader: 'メンバー詳細',
  viewMemberModalSubHeader: 'Preview of member information',
  viewMembeModalInputLabels: {
    companyId: '会社ID',
    userId: 'ユーザID',
    group: 'グループ   (必須)',
    role: '権限   (必須)',
    name: '名前   (必須)',
    emailAddress: 'メールアドレス  (必須)',
    remark: '備考',
  },
  rankingTableHeaders: {
    totalSales: '営業第三課',
    group: 'グループ',
    appointment: 'アポ数',
    calling: '架電数',
  },

  noOptions: 'No options', // no translation yet
  noSubApproverFound: '他の承認者はいません。',
  noApproverFound: '他の承認者はいません。',
  oneOrMorePeriodRequired:
    '1つ以上の期間は必要となります\n削除するには、期間を追加してください。',

  //Change reset temporary password
  notificationList: '通知',
  changedPasswordSuccessNotifTitle: 'パスワード変更完了',
  changePasswordSuccessNotifSubtitle: 'パスワードの変更が完了しました。',
  oldPasswordError: 'パスワードが誤っています。',

  cancelEditPeriodTooltip:
    '本当にキャンセルしますか？\n実行した場合、変更内容は保存されません。',
  proceedCancelEditPeriod: '実行する',
  noActiveRankingTitle: 'ランキングがありません',
  noActiveRankingDetails:
    'ランキングを作成するボタンをクリックして、\nランキングを作成してください。',
  noDisabledRankingTitle: 'アーカイブ済ランキングはありません',
  noDisabledRankingDetails:
    'ランキングをアーカイブするとこちらに表示され\nます。',

  accountInformation: 'アカウント設定',

  //Homepage
  homepage: 'ホーム',
  basicHomepage: '基本機能',
  recordApplications: '成績申請',
  rankings: 'ランキング',
  accountSettings: 'アカウント設定',
  manageFunctions: '管理機能',
  rankingLists: 'ランキング一覧',
  memberManagements: 'メンバー管理',
  recordApprovals: '成績承認',
  showDetails: 'プロフィールを表示する',
  hideDetails: 'プロフィールを隠す',

  //Star index tooltip
  rankingIndex: 'ランキング表示順',

  //account options
  accountConfigOption: 'アカウント設定',
  logoutOption: 'ログアウト',

  //Company Email verification
  verificationSentThisEmail: '認証リンクがこのメールに送信されました。',
  resend: '再送する',
  emailVerificationSuccess: 'メールアドレス認証完了',
  successfullyVerifiedEmail: 'メールアドレスの認証が完了しました。',
  backToHome: 'ホームへ',
  companyEmailVerificationLinkExpired: '認証リンク有効期限切れ',
  enterNewCompanyEmailAddress: '会社代表メールアドレスを再度登録してください。',
  enterCompanyEmailAndWellSendAlink:
    '新しい会社代表メールアドレスを入力すると、\nメールアドレスを確認するための認証リンクが送信されます。',
  verificationLinkHasBeenSent:
    '新しい認証リンクの送信が完了しました。登録されたメールをご確認ください。',
  verificationLinkSentToYourAddress:
    'メールアドレスの認証メールが送信されました。受領されたメールをご確認ください。',
  unverifiedEmailAddress: '未承認のメールアドレスです',
  noneSelectApprover: 'なし 選択',
  memberSignUpTitle: 'アカウント登録',
  memberSignUpCompanyIdTitle: '会社ID （必須）',
  memberSignUpCompanyIdPlaceholder: '会社ID',
  memberSignupCompanyIdRequired: '会社IDは必須項目です。',
  memberSignUpFullnameTitle: '氏名（必須）',
  memberSignupFullnameRequired: '氏名は必須項目です。',
  memberSignUpEmailTitle: 'メールアドレス（必須）',
  memberSignupEmailRequired: 'メールアドレスは必須項目です。',
  memberSignupEmailInvalid:
    'メールアドレスは、有効なメールアドレス形式で指定してください。',
  memberSignupPasswordRequired: 'パスワードは必須項目です。',
  memberSignupPasswordCfRequired: '新しいパスワード (確認)',
  memberPasswordMustMatch: 'パスワードが一致しません。',
  memberSignUpPasswordTitle: 'パスワード（必須）',
  memberSignUpPasswordCfTitle: '新しいパスワード (確認)（必須）',
  memberSignUpPasswordPlaceholder: 'パスワード',
  memberSignUpGoogleRegister: 'Googleで登録',
  memberSignUpAnotherMethod: 'または',
  memberSignUpSuccess: 'アカウントの登録を完了ました。',
  backToProfile: 'プロフィルへ戻る',
  memberSignInTitle: 'ログイン',
  memberForgotPassword: 'パスワードを忘れた方は',
  memberHaventAccount: 'アカウントをお持ちでない方は',
  googleButtonSignIn: 'Googleでログイン',
  memberForgotPassTitle: 'パスワードをリセット',
  memberForgotDesc:
    ' パスワードリセット情報を受信するEメールアドレスを入力してください。',
  memberForgotNotice:
    'パスワードリセットのメール送付が完了しました。 受領したメールをご確認ください。',
  memberForgotTitle: 'パスワード変更',
  send: '送信',
  backToLoginPage: 'ログインへ戻る',
  registerComplete: 'アカウントの登録が完了ました。',
  registerFail: '登録に失敗しました。\nお手数ですが、再度お試しください。',
  close: '閉じる',
  homeMenu: 'ホーム',
  rankingMenu: 'ランキング',
  calendarMenu: 'カレンダー',
  myPageMenu: 'マイページ',
  settingMenu: '設定',
  homeTitle: 'ホーム',
  memberListTitle: 'メンバーリスト',
  myPageTitle: 'マイページ',
  rankingTitle: 'ランキング',
  settingTitle: '設定',
  resetPasswordSuccessful: 'パスワードをリセットしました。',
  resetPasswordFailed:
    'パスワードのリセットに失敗しました。もう一度お試しください。',
  emailMaxRequired: 'メールアドレス 254文字まで',
  fullNameMaxRequired: 'フルネーム 255 文字まで',
  passwordMaxRequired: 'パスワードは最大 254 文字です',
  fullNameRequired: '名前  (必須)',
  fullNamePlaceholder: 'セルフ 太郎',
  accountSettingTitle: 'アカウントの設定',
  userIdRequired: 'ユーザーIDが必要です',
  minRequiredFullname: 'フルネームは 6 文字未満にすることはできません。',
  roleRequired: '役割が必要です',
  groupRequired: 'グループは必須です',
  changeRequestApproved: 'メンバー情報が承認されました  ',
  changeRequestFailed: 'エラーが発生したため、会員情報が承認されませんでした',
  changeRequestRejected: '情報の変更要求は拒否されました。',
  fileSizeAccepted: 'ファイル サイズが許容サイズを超えています。',
  fileTypeNotAllowed: 'ファイル形式は許可されていません',
  here: 'こちら',
  memberListTitlePage: 'メンバーリスト',
  no_name: 'ノーネーム',
};
